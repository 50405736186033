import axios from "axios";
import jwt_decode from "jwt-decode";
import { onBeforeMount } from "vue";
import store from "../../store/index";
import { encrypt } from "../../common/encryption"
let x = "";
let timerout = true;
export default {
    name: "myQuestion",
    props: {
        configId: String,
        usersExamId: String,
    },
    beforeRouteLeave(to, from, next) {
        console.log("Before leaving route");
        console.log("timerStarted:", this.timerStarted);
        if (this.timerStarted) {
            const confirmLeave = window.confirm(
                "By leaving this page, the answers will be submitted. Are you sure want to leave this page?"
            );
            if (confirmLeave) {
                this.alertShown = true;
                this.submit();
                next();
            } else {
                next(false); // Cancel the navigation
            }
        } else {
            next(); // Proceed with navigation
        }
    },

    async beforeMount() {

        this.Head = {
            headers: {
                Authorization: "Bearer " + store.getters.getToken,
                "Content-Type": "application/json",
            },
        };
        this.designationPrivileges = store.getters.getRolesprivilegeData;
        this.userExamId = this.usersExamId;
        await this.getExamStatusanduserType();

        if (this.TestStatus == "Initiated" && this.UserType == "Supervisor") {
            alert("User has not attended the exam yet.")
            this.$router.go(-1)
        }
        if (this.TestStatus == "Initiated" && this.UserType == "Initiator" && !this.eligible) {
            alert("User has not attended the exam yet.")
            this.$router.go(-1)
        }
        if (this.TestStatus == "Initiated") {
            console.log("Inside before attending exam")
            await this.GetAndSetData();
            await this.GetAndSetDatasss();
        }
        else if (this.TestStatus != "Initiated") {
            await this.GetAndSetData();
            await this.GetAndSetDatasss();
            await this.getSingleTestResult();
            this.closeButton = true;
            this.startbutton = false;
            this.testResult.examQuestion.forEach((x, index) => {
                //console.log("x is ", x)
                this.questionList.push(x);
                this.answerValue.push(x.enteredAnswer)
            });
            console.log("result", this.answerValue);
        }
    },
    unmounted() {
        clearInterval(x);
    },
    data() {
        return {
            isqstnspresent: true,
            fullFilename: "",
            eligible: false,
            alertShown: "",
            errorMsg: false,
            validation: false,
            visibleOn: false,
            inputType: "password",
            loadingBlock: false,
            showtimeRem: true,
            reasons: "",
            error: [false, false],
            otherReason: "",
            email: "",
            password: "",
            reasonList: [],
            showModal: false,
            UserType: "",
            TestStatus: "",
            Head: "",
            timerStarted: false,
            basrApi: `${process.env.VUE_APP_Service_URL}document/`,
            baseurl: `${process.env.VUE_APP_Service_URL}document/documentquestion/`,
            searchField: {
                name: "",
                createdat: ""
            },
            questionList: [],
            currentPage: 1,
            pageindex: 1,
            pagesize: 10,
            totalPage: 1,
            SortProperty: "question",
            SortOrder: false,
            SortOrderInt: 0,
            configDetails: "",
            time:"",
            documentDetails: {},
            answerValue: [],
            isSubmit: false,
            showAnswer: false,
            muliSaveAnswers: {
                userId: "",
                configId: "",
                docId: "",
                examDate: "",
                configPercentage: "",
                totalConfigQuestion: "",
                examQuestion: [
                    {
                        questionId: "",
                        answer: "",
                        enteredAnswer: "",
                        option: [""]
                    }
                ]
            },
            testResult: {},
            loggedInUser: {},
            userExamId: "",
            startbutton: true,
            submitbutton: false,
            timeoutfun: true,
            closeButton: true,
            newUserExamId: "",
            prevRoute: null,
            checkretest: "",
            isreset: false,
            showquestion: true,
            xam: false,
            viewanswer: true,
            comments: "",
            designationPrivileges: "",

        };
    },

    // async mounted() {

    // },

    created() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },

    watch: {
        "$store.getters.getTotalTime": function (val) {
            if (val < 60) {
                document.getElementById("countdown").style.color = "red";
            } // If the count down is finished, write some text
            if (val < 0) {
                document.getElementById("countdown").innerHTML = "Time out ";
                // Automatically submit the form
                clearInterval(x);
                this.submitTimer();
            }
        }
    },
    methods: {
        handleBeforeUnload(event) {
            if (this.timerStarted) {
                const message = "Please submit before leaving the page.";
                event.returnValue = message; // Standard for most browsers
                return message; // For some older browsers
            }
        },
        async submit() {
            await this.save();
            if (!this.alertShown) {
                if (confirm("Are you sure you want to submit the answer?")) {
                    this.submitbutton = false;
                    clearInterval(x);
                    const currentTime = document.getElementById("countdown").innerHTML;
                    console.log("time is", currentTime)
                    await axios.put(`${this.baseurl}multipleupdate`, this.muliSaveAnswers, this.Head)
                        .then((res) => {
                            console.log("multisave ", res.data);
                            this.submitTest();
                        })
                        .catch(err => console.log("error in Multisave", err));
                }
            }
            else {
                this.submitbutton = false;
                clearInterval(x);
                const currentTime = document.getElementById("countdown").innerHTML;
                console.log("time is", currentTime)
                await axios.put(`${this.baseurl}multipleupdate`, this.muliSaveAnswers, this.Head)
                    .then((res) => {
                        console.log("multisave ", res.data);
                        this.submitTest();
                    })
                    .catch(err => console.log("error in Multisave", err));
            }
        },
        async submitTimer() {
            await this.save();
            this.submitbutton = false;
            clearInterval(x);
            const currentTime = document.getElementById("countdown").innerHTML;
            console.log("time is", currentTime)
            await axios.put(`${this.baseurl}multipleupdate`, this.muliSaveAnswers, this.Head)
            //await axios.put(`${this.baseurl}multipleupdate`, this.muliSaveAnswers, this.Head)
                .then((res) => {
                    console.log("multisave ", res.data);
                    this.submitTest();
                })
                .catch(err => console.log("error in Multisave", err));

        },
        async getReasons() {
            const idtoken = store.getters.getToken;
            await axios
                .get(
                    `${this.basrApi}projectdevdocumenttemplate/getallreason`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    this.reasonList = res.data
                })
                .catch((err) => {
                    console.log("Error", err);
                });
        },
        async Closedownload() {
            this.showModal = false;
            this.reasons = [];
        },
        async ShowSignModal() {
            await this.getReasons(),
                this.showModal = true
        },
        async GetAndSetData() {
            // const idtoken = store.getters.getToken;
            // const userid = jwt_decode(store.getters.getToken);
            await axios

            .get(`${this.baseurl}getuserexam?userexamid=${this.userExamId}`, this.Head)

                //.get(this.baseurl + "getconfigbyid?ConfigId=" + this.configId, this.Head)

                .then((res) => {

                    console.log(res, "Result");

                    this.configDetails = res.data;

                })

                .catch((err) => {

                    console.log(err);

 

                });

            // console.log(userid.user_id, "/id");

        },
        async GetAndSetDatasss() {
            await axios
                .get(this.baseurl + "getconfigbyid?ConfigId=" + this.configId, this.Head)
                .then((res) => {
                    this.time = res.data;
                })
                .catch((err) => {
                    console.log(err);

                });
            console.log(this.config, "GETLIST");
        },
        async timer(hours, minutes) {

            await this.questionslist();
            if (this.isqstnspresent) {
                this.timerStarted = true;
                this.GetAndSetData();
            this.GetAndSetDatasss();
                this.startbutton = false;
                this.closeButton = false;
                this.submitbutton = true;
                const seconds = 0;
                var totalSeconds = (hours * 60 * 60) + (minutes * 60) + seconds; // Update the count down every 1 second
                if (hours <= 0 && minutes <= 0) {
                    document.getElementById("countdown").style.display = "none";
                    return;
                }
                else {
                    x = setInterval(function () {
                        totalSeconds--;
                        var hours = Math.floor(totalSeconds / 3600);
                        var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
                        var seconds = totalSeconds % 60; // Display the result in the element with id="countdown"
                        document.getElementById("countdown").innerHTML = hours + "h " + minutes + "m " + seconds + "s ";
                        store.dispatch("setTotalTime", totalSeconds);
                    }, 1000);
                }
            }
        },

        async closebutton() {

            if (store.getters.getprvspath.includes(`/myQuestion/`)) {
                this.$router.push(`/documentview/` + this.documentId + "/" + 0 )
                console.log(store.getters.getprvspath, "PATH IS................................");
            }
            else if (store.getters.getprvspath.includes(`/documentview/`)) {
                this.$router.push(`/documentview/` + this.documentId + "/" + 0 )
                console.log(store.getters.getprvspath, "second path");
            }

            else if (store.getters.getprvspath.includes(`/doclogin/`)) {
                this.$router.push(`/`)
            }
            else {
                this.$router.go(-1);
            }
        },

        async questionslist() {
            await axios.get(`${this.baseurl}getquestionnaire?examId=${this.userExamId}`, this.Head)
                .then((res) => {
                    console.log("QSTNS DATA", res.data)
                    if (res.data == "") {
                        this.isqstnspresent = false;
                        alert("No questions found! Please try later")
                        this.$router.go(-1);
                    } else {
                        console.log("questionnaire is ", res.data)
                        this.questionList = res.data;

                    }
                })
        },



        async save() {
            this.muliSaveAnswers = {
                userexamId: "",
                configPercentage: "",
                totalConfigQuestion: "",
                examQuestion: [
                    {
                        questionId: "",
                        answer: "",
                        enteredAnswer: "",
                        option: [""]
                    }
                ]
            };

            //this.muliSaveAnswers.userId = this.loggedInUser.id;
            this.muliSaveAnswers.userexamId = this.userExamId;
            //this.muliSaveAnswers.docId = this.documentId;
            this.muliSaveAnswers.configPercentage = this.configDetails.configPercentage;
            this.muliSaveAnswers.totalConfigQuestion = this.configDetails.totalConfigQuestion;
            //this.muliSaveAnswers.examDate = "";
            this.questionList.forEach((qstn, index) => {
                let question = {
                    questionId: qstn.id,
                    answer: qstn.answer,
                    enteredAnswer: this.answerValue[index],
                    option: qstn.option
                }
                this.muliSaveAnswers.examQuestion.push(question);
            });
            this.muliSaveAnswers.examQuestion.shift();
            console.log("Multisave answer is ", this.muliSaveAnswers);
        },


        async submitTest() {
            console.log(" in submit test");
            clearInterval(x);
            await axios.post(
                `${this.baseurl}submit?Id=${this.userExamId}`, {}, this.Head)
                .then((res) => {
                    console.log("submit test ", res.data);
                    this.timerStarted = false;
                    this.getExamStatusanduserType();
                    this.GetAndSetData()
                    this.GetAndSetDatasss()
                    alert("Answers submitted successfully");
                })
                .catch(err => console.log("error in submit test", err));
            if (this.timerStarted === false) {
                this.questionList = []
                await this.getSingleTestResult();
                this.testResult.examQuestion.forEach((x, index) => {
                    this.questionList.push(x);
                    this.answerValue.push(x.enteredAnswer)
                });
            }

            console.log("Question list is ", this.questionList)
        },
        async getSingleTestResult() {
            this.showquestion = false
            this.showtimeRem = false;
            await axios.get(
                `${this.baseurl}getsingletestresult?userexamid=${this.userExamId}`, this.Head)
                .then((res) => {
                    console.log("single test result", res.data);
                    this.testResult = res.data;
                    this.showAnswer = true;
                })
                .catch(err => console.log("error in submit test", err));
        },
        async getExamStatusanduserType() {
            await axios.get(
                `${this.baseurl}getstatusbyid?Configid=${this.configId}&userExamId=${this.userExamId}`, this.Head)
                .then((res) => {
                    console.log("getstatusbyid", res.data.examStatus);
                    this.TestStatus = res.data.examStatus;
                    this.UserType = res.data.userType;
                    this.eligible = res.data.eligible;
                })
                .catch(err => console.log("error in TestStatus ", err));
        },
        async DownloadReport() {
            let apiResultValue = ''
            if (this.reasons === 'Others') {
                apiResultValue = this.otherReason
                if (apiResultValue === '')
                    this.error[0] = true;
            }
            else apiResultValue = this.reasons
            if (this.password === "" || this.reasons === '') {
                if (this.password === "") {
                    this.errorMsg = true
                }
                if (this.reasons === '') {
                    this.error[1] = true;
                }
            }
            else {
                this.loadingBlock = true
                this.errorMsg = false
                this.error = [false, false]
                const idtoken = store.getters.getToken;
                const encryptedPassword = await encrypt(this.password.trim());
                await axios
                    .put(
                        `${this.basrApi}documentquestion/resultpdf?ExamId=${this.userExamId}`, {
                        "password": encryptedPassword,
                        "email": this.email,
                        "location": "India",
                        "reason": apiResultValue,
                    },
                        {
                            responseType: "blob",
                            headers: {
                                Authorization: "Bearer " + idtoken,
                                "Content-Type": "application/json",
                            },
                        },
                    )
                    .then((res) => {
                        this.validation = false;
                        alert("Signed successfully")
                        this.getExamStatusanduserType();
                        if (res.data) {
                            this.loadingBlock = false;
                            this.showpopup = false;
                            this.showModal = false;
                            // let contentdispo = res.headers["content-disposition"];
                            //let parts = contentdispo.split(/;\s/);
                            //let filenamePart = parts.find(part => part.startsWith('filename='));
                            //this.fullFilename = decodeURIComponent(filenamePart.split('=')[1].replace(/["']/g, ''));
                            //console.log(this.fullFilename);
                            //const fileData = res.data; // Assuming the response.data contains the file content
                            //this.downloadFile(fileData, this.fullFilename);
                        }
                    })
                    .catch((err) => {
                        this.loadingBlock = false
                        console.log("err", err)
                        if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.DomainValidations && err.response.data.errors.DomainValidations[0]) {
                            this.err = err.response.data.errors.DomainValidations[0];
                        }
                        else {
                            this.err = "Invalid username or password."
                        }
                        this.validation = true;
                        this.errorMsg = false;
                    });
            }


        }, passwordVisible() {
            this.visibleOn = !this.visibleOn
            if (this.visibleOn == true) {
                this.inputType = "text"
            }
            else this.inputType = "password"
        },

        downloadFile(data, fileName) {
            this.loadingBlock = false
            this.showpopup = false
            const blob = new Blob([data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = fileName
            document.body.appendChild(a);
            a.click();
            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            this.showModal = false;
        },
    },
}