<template>
  <div class="create-folder-modal">
    <div class="folder-modal-container">
      <div class="d-flex justify-content-between py-2 px-3">
        <h5 class="my-0 align-self-center ">Edit Folder</h5>
        <div class="cursor-pointer" @click="closeconfig()">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="ibox-content p-4">
        <label class="mb-0" for="designation">Enter Folder Name</label>
        <input
          type="text"
          class="form-control"
          v-model="getfolderbyids.name"
          maxlength="75"
        />
        <span class="errorClass" v-if="errors[3] == false"
          >Please enter a folder name
        </span>
        <div class="form-group pt-2">
          <!-- <label class="mb-0" for="designation">Select Designation</label>
          <Multiselect
            @deselect="getDesignationUsers()"
            @select="getDesignationUsers()"
            class="mb-2"
            name="designation"
            valueProp="id"
            trackBy="name"
            v-model="selectedDesignation"
            mode="multiple"
            placeholder="Select Designation"
            :options="designationList"
            label="displayName"
            :hideSelected="false"
            :closeOnSelect="false"
          >
            <template v-slot:option="{ option }">
              {{ option.displayName }}
            </template>
          </Multiselect> -->
          <!-- <div class="mb-2">
            <label class="mb-0" for="designation"
              >Select Users 
            </label>
            <Multiselect
              name="users"
              valueProp="emailId"
              v-model="selectedUser"
              mode="multiple"
              placeholder="Select User"
              :options="userList"
              :hideSelected="false"
              :closeOnSelect="false"
            >
              <template v-slot:option="{ option }">
                {{ option.userName }}
              </template>
            </Multiselect>
            <div v-if="errorBlock" class="error-block">
              Please add atleast one user
            </div>
          </div> -->
          <div class="mb-2">
            <label class="mb-0" for="designation">Select File Format </label>
            <Multiselect
              name="format"
              valueProp="label"
              v-model="SelectedFormat"
              mode="multiple"
              placeholder="Select Format"
              :options="FormatList"
              :hideSelected="false"
              :closeOnSelect="false"
              class="multiselect-custom-theme"
            >
              <template v-slot:option="{ option }">
                {{ option.label }}
              </template>
            </Multiselect>
          </div>
          <div class="col-md-12 row">
            <div class="col-md-2">
              <div class="form-group">
                <label><small>Prefix</small></label>
                <input
                  maxlength="1"
                  id="password"
                  name="password"
                  type="text"
                  class="form-control required"
                  v-model="prefix"
                  aria-required="true"
                  v-on:keypress="isLetter($event)"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label><small>* Major ver.</small></label>
                <input
                  maxlength="2"
                  id="password"
                  name="password"
                  type="text"
                  class="form-control required"
                  v-model="majorVerStart"
                  aria-required="true"
                  @keypress="isNumber($event,'major')"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label><small>Minor ver.</small></label>
                <input
                  maxlength="2"
                  id="password"
                  name="password"
                  type="text"
                  class="form-control required"
                  v-model="minorVerStart"
                  aria-required="true"
                  @keypress="isNumber($event,'')"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label><small>Minor Max.</small></label>
                <input
                  maxlength="2"
                  id="password"
                  name="password"
                  type="text"
                  class="form-control required"
                  v-model="minorVermax"
                  aria-required="true"
                  @keypress="isNumber($event,'')"
                  autocomplete="off"
                />
              </div>
            </div>
            <span class="errorClass" v-if="minverError == true"
            >Minor Max value must be greater than or equal to Minor version value
          </span>
           <span class="errorClass" v-if="majorverError == true"
            >Major version value must be greater than zero
          </span>
          <span class="errorClass" v-if="majorverEmptyError == true"
            >Major version start field cannot be empty
          </span>
<!-- 
            <div class="col-md-1">
              <div class="mt-4" @click="saveVersion()">
                <a href="#"><img src="../../assets/img/save_ver.png" /></a>
              </div>
            </div> -->
          </div>

          <!-- <label class="mb-0" for="designation">Permissions</label>
          <Multiselect
            name="permissions"
            valueProp="permission"
            v-model="selectedPermission"
            mode="multiple"
            placeholder="Select Permission"
            :options="permissionList"
            label="permission"
            :hideSelected="false"
            :closeOnSelect="false"
          /> -->
          <span
            class="submit_btn mt-3 px-5 float-right"
            type="button"
            @click="updatefolder()"
            >Save</span
          >
          <strong> Document version :</strong>
          {{ prefix }} {{ majorVerStart }}<span v-if ="majorVerStart && minorVerStart !=''">.</span>{{
            minorVerStart
          }}
        </div>
        <!-- <div class="input-group">
          <input type="text" placeholder="Enter Folder Name" class="form-control" v-model="foldervalues.name"
            maxlength="30" />
          <span class="input-group-append">
            <button type="button" class="btn btn-primary" @click="createfolder()">
              Add
            </button>
          </span>

        </div> -->
      </div>
    </div>
  </div>
</template>
<script src="./editfolderpopup.js"></script>
<style scoped>
.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}
.title-class1 {
  background-color: #618bd7;
  color: #fff;
}
.ibox-content {
  overflow-x: auto !important;
}
.errorClass {
  color: red;
  font-size: 13px;
}
</style>







