<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Designation Privilege List</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i class="fa fa-times cursor-pointer close" @click="onclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content px-3 py-2">
      <div class="form-group row mb-0">
        <h5 class="align-self-center col-lg-11 my-0 py-0 px-3">
          {{ currentDesignation }}
        </h5>
      </div>
    </div>
    <div class="ibox-content px-3 py-2">
      <div class="table-responsive">
        <table class="table table-striped table-bordered dataTables">
          <thead>
            <tr>
              <td class="width-2">
                <input
                  type="checkbox"
                  id="selectall"
                  @change="selectallcontrols()"
                />
              </td>
              <th>Screens</th>
              <th>Privileges</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(options, index) in screens" :key="options.id">
              <td>
                <input
                  type="checkbox"
                  :value="options.name"
                  v-model="selectedscreens"
                  :id="options.id"
                  @change="setcontrols(options.id, index, options.controls)"
                  :checked="selectedscreens.includes(options.name)"
                />
              </td>
              <td>{{ options.name }}</td>
              <td>
                <label v-for="option in options.controls" :key="option">
                  <input
                    type="checkbox"
                    :value="option"
                    :class="options.id"
                    v-model="screensds[index].controls"
                    @change="testcheck(options.id, options.name)"
                    :checked="
                      screenNames.includes(options.name) &&
                      controlName.includes(option)
                    "
                  />
                  {{ option }} &nbsp; </label
                >&nbsp;
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-end text-right mt-2"  v-if= "designationPrivileges.includes(`Designation Update Privilege`)">
        <button
          class="btn btn-primary py-2 px-5"
          type="submit"
          @click.prevent="Save"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script src="./DesignationScreenPrivileges.js"></script>
  
  <style scoped>
@import "../../assets/css/style.css";
@import "../../assets/formpage.css";
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.width-2 {
  width: 2%;
}
</style>
  