
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
export default {
  name: "designationScreenPrivileges",
  props: {
    designationId: { type: String },
    designationName: { type: String },
  },
  data() {
    return {
      currentDesignation:"",
      baseurl: process.env.VUE_APP_Service_URL,
      baseApi: process.env.VUE_APP_Service_URL + "account-core",
      selectedscreens: [],
      screensds: [],
      roles: "",
      RoleScreen: {
        role_Name: "",
      },
      screens: "",
      existingdata: [],
      screenNames: [],
      controlName: [],
      screenPrivileges: [],
      validations: [false],
      errors: [],
      Head: "",
      designationPrivileges :"",
    };
  },

  async mounted() {
    
    this.designationPrivileges = store.getters.getRolesprivilegeData;
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    await this.getDesignationName();
    await this.getallscreens();
    await this.getscreenandpriv();

  },
  methods: {
    async selectallcontrols() {
      this.selectedscreens = [];
      let selectall = document.getElementById("selectall");
      if (selectall.checked == true) {
        this.screensds = [];
        await this.screens.forEach((element) => {
          this.selectedscreens.push(element.name);
          //initialisation
          let savescreen = {
            name: "",
            controls: [],
          };
          //initialisation completed

          savescreen.name = element.name;
          savescreen.controls = element.controls;
          this.screensds.push(savescreen);
        });
      } else {
        this.screensds = [];
        await this.screens.forEach((element) => {
          document.getElementById(element.id).checked = false;
          let savescreen = {
            name: "",
            controls: [],
          };
          savescreen.name = element.name;
          this.screensds.push(savescreen);
        });
      }
    },
    async setcontrols(elementid, index, allcontrols) {
      let checkBox = document.getElementById(elementid);
      if (checkBox.checked == true) {
        this.screensds[index].controls = allcontrols;
      } else {
        this.screensds[index].controls = [];
      }
    },
    async testcheck(optid, optname) {
      let filteredAry = this.selectedscreens.filter(e => e !== optname);
      this.selectedscreens = filteredAry;
      document.getElementById(optid).checked = false;
      let isallselected = document.getElementsByClassName(optid);
      isallselected.forEach((checkBoxelement) => {
        if (checkBoxelement.checked == true) {
          this.selectedscreens.push(optname);
        }

      })
    },
    async getscreenandpriv() {
      await axios
        .get(
          `${this.baseurl}account-core/roles/getscreendesignationprivilegebyid?guid=` + this.designationId, this.Head
        )
        .then((res) => {
          this.existingdata =
            res.data == "No Privilege found" ? null : res.data;
          this.screenPrivileges = this.existingdata.screens;
          this.existingcontrols();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDesignationName() {
      await axios
        .get(
          `${this.baseurl}configuration/designation/search?id=`+ this.designationId, this.Head
        )
        .then((res) => {
          console.log("Entitiess",res.data.entities[0].displayName)
        this.currentDesignation=res.data.entities[0].displayName
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async existingcontrols() {
      await this.screenPrivileges.forEach((element) => {
        this.screensds.forEach((checkelement, index) => {
          if (element.name == checkelement.name) {
            this.selectedscreens.push(element.name);
            this.screensds[index].controls = element.controls;
          }
        });

      });
    },
    async getScreensandNames() {
      this.screenNames = [];
      this.controlName = [];
      this.screenPrivileges.filter((item) => {
        if (item.name) {
          this.screenNames.push(item.name);
        }
        item.controls.filter((subitems) => {
          this.controlName.push(subitems);
        });
      });
    },
    async getallscreens() {
      await axios
        .get(
          `${this.baseurl}account-core/roles/getallscreens`, this.Head
          //`https://localhost:5001/roles/getallscreens`, this.Head
        )
        .then((res) => {
          this.screens = res.data;
          if (res.data == "No screen found") {
            this.screens = ""
            alert("No data found")
          }
        })
        .catch((err) => {
          console.log(err);
        });
      await this.declarescreen();
    },
    async declarescreen() {
      this.screensds = [];
      await this.screens.forEach((element) => {
        //initialisation
        let savescreen = {
          name: "",
          controls: [],
        };
        //initialisation completed

        savescreen.name = element.name;
        this.screensds.push(savescreen);
      });
    },
    //save role priv

    async Save() {
      this.screenNames = [];
      this.controlName = [];
      let resultarray = [];

      await this.screensds.forEach((element) => {
        if (element.controls.length != 0) {
          resultarray.push(element);
        }
      });
      if (this.existingdata == null) {
        await axios
          .post(
            `${this.baseurl}account-core/roles/createscreendesignationprivilege`,
            {
              designationID: this.designationId,
              screens: resultarray,
            },this.Head
          )
          .then((res) => {
            console.log(res);
            alert("Screen privilege saved successfully");
            this.$router.go(-1)
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        await this.updaterolepriv(resultarray);
      }
    },
    //update
    async updaterolepriv(data) {
      await axios
        .put(
          `${this.baseurl}account-core/roles/updatescreendesignationprivilege`,
          {
            designationID: this.designationId,
            screens: data,
          },
          this.Head
        )
        .then((res) => {
          console.log(res);
          alert("Screen privilege updated successfully");
          this.$router.go(-1)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async onclosed() {
      this.$router.push('/designation');
    },
  },
};
