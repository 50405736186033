<!-- Popup library -->
<template>
  <div class="create-folder-modal">
    <div class="modal-content width-500">
      <div
        class="modal-header d-flex justify-content-between align-items-center"
      >
        <h5 class="float-left mb-0">Add Signers</h5>
        <i class="fa fa-times close cursor-pointer" @click="onclosed()"
          ><span class="sr-only">Close</span></i
        >
      </div>

      <div class="modal-body makeitshort">
        <div class="col-12 mt-2">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th class="sort_block" id="1">
                  <div class="d-flex flex-row justify-content-between">
                    Signing order
                  </div>
                </th>
                <th class="sort_block" id="1">
                  <div class="d-flex flex-row justify-content-between">
                    Bookmark/Role
                  </div>
                </th>
                <th class="sort_block" id="1">
                  <div class="d-flex flex-row justify-content-between">
                    User
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bookmarks, index) in signuserList" :key="bookmarks">
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  {{ bookmarks.bookmark }}
                </td>
                <td>
                  <select
                    id="user"
                    class="mr-3 form-select form-select-lg"
                    aria-label=".form-select-lg example"
                    :disabled="
                      (index != 0 &&
                        (signusersmodel[index - 1] == '' ||
                          signusersmodel[index - 1] == null)) ||
                      bookmarks.isSigned == true
                    "
                    v-model="signusersmodel[index]"
                  >
                    <option :value="null || '' || 'null'" selected disabled>
                      --Select a User--
                    </option>
                    <option
                      v-for="users in userlist"
                      :key="users.id"
                      :value="users.id"
                    >
                    {{  }}
                      {{ users.name }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="showButton==true" class="modal-footer d-flex justify-content-between">
        <a href="#"><small> </small></a>
        <button
          @click="ConfirmClicked()"
          type="button"
          class="btn btn-primary btn-xs px-4 mr-3"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>


<script>
/* eslint-disable */

import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";

export default {
  name: "Editegrement",
  components: {
    Multiselect,
  },
  props: {
    docId: {
      type: String,
      default: "",
    },
    version: {
      type: String,
      default: "",
    },
    versionno: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bookmarklist: [
        {
          bookmarkname: "",
        },
      ],
      loadingBlock: false,
      adobeUrl: "",
      userlist: [],
      signuserList: [],
      signaturepeople: [],
      baseapi: "https://dmsdev.datamatica.uk/v1/",
      radiobutton: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}`,
      emailId: "",
      password: "",
      reason: "",
      err: "",
      validation: false,
      showButton:false,
      signusersmodel: [],
    };
  },
  async mounted() {
    this.getDocumentdetails();
    this.getallusers();
    this.getsignusers();
    document.body.style.overflowY = "hidden";
  },
  async unmounted() {
    document.body.style.overflowY = "auto";
  },
  methods: {
    async getallusers() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.baseUrl}account-core/user/getall`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.userlist = res.data;
          console.log("userlist isssss", this.userlist);
        })
        .catch((err) => {
          console.log(err);
          this.userlist = [];
        });
    },
    async getDocumentdetails() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseUrl}document/document/getdocumentdetails?documentId=${
            this.docId
          }${this.versionno ? `&version=${this.versionno}` : ""}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("res.data.storageVersionId", res.data.latestVersion);
          // this.signVersion = res.data.latestVersion;
          if (res.data.latestVersion == this.versionno) {
            this.showButton = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getsignusers() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseUrl}document/document/getsignerlist?docId=${this.docId}&versionnum=${this.versionno}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.signuserList = res.data;
          console.log("userlist isssss", this.signuserList);
          this.signuserList.forEach((eachsignuser) => {
            this.signusersmodel.push(eachsignuser.userId);
          });
        })
        .catch((err) => {
          console.log(err);
          this.signuserList = [];
        });
    },
    async onclosed() {
      this.$emit("signatureclosemodel");
      this.emailId = "";
      this.password = "";
      this.reason = "";
    },

    async ConfirmClicked() {
      console.log("this.signuserList", this.signuserList);
      this.signusersmodel.forEach((signmodelusersid, index) => {
        console.log(
          "usersssss",
          this.userlist.find((u) => u.id === signmodelusersid)
        );
        let eachusersmodels = {};
        eachusersmodels = this.userlist.find((u) => u.id === signmodelusersid);
        // if()
        console.log("eachusersmodels", eachusersmodels);
        if (eachusersmodels != undefined) {
          this.signuserList[index].userEmail = eachusersmodels.email;
          this.signuserList[index].userId = eachusersmodels.id;
          this.signuserList[index].userName = eachusersmodels.name;
          this.signuserList[index].date = new Date();
        }

        // this.userlist.forEach((eachuserslisted)=>{
        //   if(signmodelusersid == eachuserslisted.id){
        //     console.log()
        //   }
        // })
      });
      console.log("this.signuserList", this.signuserList);
      this.loadingBlock = true;
      const idtoken = store.getters.getToken;
      const response = await axios
        .put(
          `${this.baseUrl}document/document/updatesigner`,
          {
            documentId: this.docId,
            version: this.versionno,
            signer: this.signuserList,
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Successfully updateddddd", res.data);
          alert("Signers updated successfully");
          this.onclosed();
          //window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.submit_btn {
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;

  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.position-relative img {
  width: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.headingunderline {
  text-decoration-line: underline;
}

.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}
.title-class1 {
  background-color: #618bd7;
  color: #fff;
}
.errorClass {
  color: red;
  font-size: 13px;
}
.button-class {
  float: right;
}
.modal-header-designation {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-width {
  width: min(400px, 90%);
}
.error-block {
  color: #ff0000;
}
.errmsg {
  font-size: 12px;
  color: red;
  float: left;
}
.width-500 {
  width: min(500px, 90%);
}
.loading-img {
  mix-blend-mode: lighten;
}
.makeitshort {
  max-height: 400px;
  overflow: auto;
}
</style>
