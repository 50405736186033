<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-10 col-md-10">
      <h2 class="my-0 py-0 align-self-center">Document Summary</h2>
    </div>
    <div class="col-md-2 align-self-center">
      <i class="fa fa-times close cursor-pointer" @click="closebutton()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-8">
        <div class="ibox-title style_2 d-flex justify-content-between px-3">
          <h5 class="my-0 py-0 align-self-center">{{ documentTitle }}</h5>
          <div
            v-if="
              $store.getters.getFromPage != 'DocumentList' &&
              $store.getters.getDocSign == false
            "
            class="graphbtncontainer"
          >
            <div class="graphbutton" v-if="format == 'PDF' || format == 'pdf'">
              <div class="position-relative align-self-center py-0 my-0">
                <img
                  src="../../assets/img/mergefile.png"
                  alt="merge file"
                  @click="openmergeFilePopup()"
                />
                <div class="tool-tip">Merge File</div>
              </div>
            </div>

            <div
              v-if="
                sourcePage !== 'Approval' &&
                sourcePage !== 'Share' &&
                sourcePage !== 'changeControl' &&
                type !== null
              "
              class="graphbutton"
            >
              <div class="position-relative align-self-center py-0 my-0">
                <img
                  src="../../assets/img/add_signers.png"
                  alt="create version"
                  @click="AddsignersSignModal()"
                />
                <div class="tool-tip">Add Signers</div>
              </div>
            </div>
            <!-- <div class="graphbutton">
              <div
                class="position-relative align-self-center py-0 my-0"
                v-if="
                  showQuestion == true &&
                  designationPrivileges.includes(
                    sourceRoute + ` Configure Exam`
                  )
                "
              >
                <img
                  v-if="sourcePage != 'Mail'"
                  src="../../assets/img/configure_exam.png"
                  alt="create version"
                  @click="openExam(documentid)"
                />
                <div class="tool-tip">Configure Exam</div>
              </div>
            </div> -->
            <!-- <div class="graphbutton">
              <div
                class="position-relative align-self-center py-0 my-0"
                v-if="
                  showQuestion == true &&
                  designationPrivileges.includes(
                    sourceRoute + ` Configure Exam`
                  )
                "
              >
                <img
                  v-if="sourcePage != 'Mail'"
                  src="../../assets/img/view_exam_results.png"
                  alt="create version"
                  @click="openViewResult(documentid)"
                />
                <div class="tool-tip">View Exam Result</div>
              </div>
            </div> -->

            <div v-if="sourcePage != 'Approval'" class="graphbutton">
              <div
                class="position-relative align-self-center py-0 my-0"
                v-if="
                  showQuestion == true &&
                  designationPrivileges.includes(
                    sourceRoute + ` Document Approval`
                  )
                "
              >
                <img
                  v-if="sourcePage != 'Mail'"
                  src="../../assets/img/doc_approval.png"
                  alt="create version"
                  @click="documentApproval(documentId)"
                />
                <div class="tool-tip">Approval</div>
              </div>
            </div>
            <div class="graphbutton" v-if="showButton == true">
              <div
                v-if="
                  isSignedValue == 'Sign' &&
                  sourcePage !== 'Approval' &&
                  sourcePage !== 'Share'
                "
                class="position-relative align-self-center py-0 my-0"
              >
                <!-- @click="Signdocfun()" -->
                <img
                  src="../../assets/img/sign_dms.png"
                  alt="create version"
                  @click="ShowSignModal()"
                />
                <div class="tool-tip">Sign Document</div>
              </div>
            </div>
            <!-- <div class="graphbutton" v-if="isSignedValue == 'View'">
              <div class="position-relative align-self-center py-0 my-0">
                <img
                  src="../../assets/img/signview_dms.png"
                  alt="create version"
                  @click="getSignedDocView()"
                />
                <div class="tool-tip">Signed Document</div>
              </div>
            </div> -->
            <!-- <div class="graphbutton">
              <div
                class="position-relative align-self-center py-0 my-0"
                v-if="
                  showQuestion == true &&
                  designationPrivileges.includes(sourceRoute + ` Question`)
                "
              >
                <img
                  v-if="sourcePage != 'Mail'"
                  src="../../assets/img/create_quest.png"
                  alt="create version"
                  @click="Question()"
                />
                <div class="tool-tip">Question</div>
              </div>
            </div> -->
          </div>
        </div>
        <div
          class="ibox-content"
          v-if="
            (EligbleExam && xam == false) ||
            showValue == 'Pending' ||
            showValue == 'Approved' ||
            showValue == 'Rejected'
          "
        >
          <!-- <div v-if="EligbleExam && xam == false">
            <a href="#" @click="GoToOnlineExam()">
              <strong><u>Start exam </u></strong></a
            >
          </div> -->
          <!-- Document for approval start-->
          <div
            class="row"
            v-if="
              showValue == 'Pending' &&
              designationPrivileges.includes(`Approvals Document Approve`)
            "
          >
            <div class="form-group col-md-10">
              <input
                type="text"
                placeholder="Comments"
                class="form-control w-100"
                v-model="comments"
              />
            </div>
            <div class="col-md-2">
              <button
                type="button"
                class="btn btn-link"
                data-toggle="tooltip"
                title="Approve Document"
                @click="ApprovalStatus(true)"
              >
                <img src="../../assets/img/approve.png" alt="Approve Status" />
              </button>
              <button
                type="button"
                class="btn btn-link"
                data-toggle="tooltip"
                title="Reject Document"
                @click="ApprovalStatus(false)"
              >
                <img src="../../assets/img/reject.png" alt="Approve Status" />
              </button>
            </div>
          </div>
          <div class="row" v-if="showValue == 'Approved'">
            <div class="col-md-2">
              <p class="badge badge-primary px-2 py-1 mb-0">Approved</p>
            </div>
            <div class="form-group col-md-10">
              <td>
                <strong>Comments :</strong> {{ approvaldetails.comments }}
              </td>
            </div>
          </div>
          <!-- approved document end-->
          <!-- rejected document start-->
          <div class="row" v-if="showValue == 'Rejected'">
            <div class="col-md-2">
              <p class="badge badge-danger px-2 py-1 mb-0">Rejected</p>
            </div>
            <div class="form-group col-md-10">
              <td>
                <strong>Comments :</strong> {{ approvaldetails.comments }}
              </td>
            </div>
          </div>
        </div>
        <div class="ibox-content">
          <div class="action-block d-flex justify-content-end pr-3 mb-2">
            <div class="position-relative mr-2" v-if="downloadValue">
              <img
                class="cursor-pointer"
                width="20"
                height="20"
                src="../../assets/img/doc_download.png"
                alt="Edit document"
                @click="downloadFile()"
              />
              <span class="tool-tip">Download</span>
            </div>
            <div class="position-relative" v-if="printValue">
              <img
                class="cursor-pointer"
                width="20"
                height="20"
                src="../../assets/img/doc_print.png"
                alt="Edit document"
                v-print="printObj"
              />
              <span class="tool-tip">Print</span>
            </div>
          </div>
          <div class="content-block">
            <div v-if="isLoading" class="loading-spinner">
              <div class="spinner"></div>
            </div>
            <div v-if="imgFormat.includes(format)">
              <img :src="source" alt="Italian Trulli" class="max-width" />
            </div>
            <div v-if="format == 'pdf'" class="pdfembeded">
              <div v-if="!istherefiletoview" class="loading-spinner">
                <div class="spinner"></div>
              </div>
              <vue-pdf-embed v-else :source="source" />
            </div>
            <div v-if="office.includes(format)" class="outsideframe">
              <div
                id="hiddata"
                v-bind:style="
                  officeurl == '' ? 'display:block;' : 'display:none;'
                "
              >
                <span
                  v-if="!istherefiletoview"
                  style="font-weight: 500; padding-left: 30%"
                  >Blank document - Nothing to display
                </span>
              </div>
              <iframe
                id="printDiv"
                :key="officeurl"
                :src="officeurl != null ? officeurl : ''"
                width="100%"
                height="650px"
                frameborder="0"
                Cache-Control:
                no-store
              ></iframe>
              <div class="overlay"></div>
            </div>
          </div>
        </div>
      </div>
      <!--col-lg-8 close-->
      <div class="col-lg-4">
        <div class="ibox">
          <div class="ibox-title style_2 d-flex px-3">
            <h5 class="align-self-center">Document Details</h5>
          </div>
          <div class="ibox-content">
            <table class="table table-responsive table-bottom small m-b-xs">
              <tbody>
                <tr>
                  <td class="d-flex breakWord">
                    <strong>Title :</strong>
                    <div v-if="!readMore[9999]">
                      {{ documentTitle.substring(0, 15) }}
                    </div>
                    <span
                      @click="showMore(9999)"
                      v-if="!readMore[9999]"
                      class="more cursor-pointer"
                      >More</span
                    >
                    <div v-if="readMore[9999]">{{ documentTitle }}</div>

                    <span
                      @click="showLess(9999)"
                      v-if="readMore[9999]"
                      class="less cursor-pointer"
                      >Less</span
                    >
                  </td>
                  <td><strong>Uploaded by : </strong> {{ updatedBy }}</td>
                </tr>
                <tr>
                  <td><strong>Owner :</strong> {{ owner }}</td>
                  <td><strong>Type :</strong> {{ type }}</td>
                </tr>
                <tr>
                  <td><strong>Standard :</strong> {{ standard }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="ibox">
          <div class="ibox-title style_2 d-flex justify-content-between px-3">
            <h5 class="my-0 py-0 align-self-center">Version History</h5>
            <div
              class="position-relative my-0 py-0 align-self-center"
              v-if="!checkstatusvalue.includes(showValue)"
            >
              <!--sourcePage != 'Mail' &&  removed from below condition check-->
              <img
                v-if="
                  isuserenabled === true &&
                  designationPrivileges.includes(`My Files Add Document`)
                "
                class="cursor-pointer"
                src="../../assets/img/create.png"
                alt="create version"
                width="20"
                @click="NewDocVersion()"
              />

              <div class="tool-tip">Add new version</div>
            </div>
          </div>
          <div class="ibox-content">
            <table class="table table-responsive small m-b-xs">
              <thead>
                <tr>
                  <th width="15%">Name</th>
                  <th width="15%">Version</th>
                  <th width="20%">Date</th>
                  <th width="40%">Version Changes</th>
                  <th width="10%" class="text-center">View</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(version, index) in versionList.slice(0, 30)"
                  :key="version.projectid"
                >
                  <!-- <td>{{ version.fileName }}</td> -->
                  <td class="breakWord" v-if="!readMore[index]">
                    {{ version.fileName.substring(0, 15) + ".." }}
                    <span
                      @click="showMore(index)"
                      v-if="!readMore[index]"
                      class="more"
                      >More</span
                    >
                  </td>
                  <td v-if="readMore[index]" class="breakWord">
                    {{ version.fileName }}
                    <span
                      @click="showLess(index)"
                      v-if="readMore[index]"
                      class="less"
                      >Less</span
                    >
                  </td>

                  <td>{{ version.version }}</td>
                  <td>
                    {{
                      version.createdAt ? version.createdAt.slice(0, 10) : ""
                    }}
                  </td>
                  <td>{{ version.versionMessage }}</td>
                  <td class="text-center">
                    <a
                      @click.prevent="
                        versionViewer(
                          version.versionId,
                          version.versionType,
                          version.version
                        )
                      "
                      ><i class="fa fa-eye"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="ibox"></div>
      </div>
    </div>
  </div>
  <NewDocVersion
    v-if="newdocversionPopup"
    @editdocactive="newversionuploadactivity()"
    :docId="documentid"
    :department="versionfrom"
  >
  </NewDocVersion>
  <!-- modal starts -->
  <teleport to="#app">
    <div class="add_page_modal_wrapper" v-if="signModal">
      <div class="add_page_modal__containers">
        <div
          class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
        >
          <h4 class="my-0">Add Signature</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closeSignModel()"></i>
          </a>
        </div>
        <div class="row w-100 mx-auto">
          <div class="col-lg-12 py-3">
            <input
              type="password"
              class="form-control mb-3"
              v-model="signpassword"
              placeholder="Enter Password"
              aria-label="Username"
              aria-describedby="basic-addon1"
            /><span class="errorClass" v-if="errmsgshow">
              {{ errmsg }}
            </span>
            <button
              type="button"
              class="btn btn-primary d-flex mx-auto mb-3"
              @click.prevent="Signdocfun()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- modal ends -->
  <!-- <UserAuthPopup
    v-if="signModal"
    @signatureclosemodel="closeSignModel()"
    :docId="this.documentid"
    :versionId="this.signVersion"
  >
  </UserAuthPopup> -->

  <mergefilepopup
    v-if="mergeFileModal"
    @mergetreeclosemodel="closeMergeFileModel"
    @custom-event="handleCustomEvent"
    :docId="this.mergeDocId"
    :version="this.mergeVersion"
    :fileName="this.mergeFileName"
  >
  </mergefilepopup>

  <editegrement
    v-if="addsignModal"
    @signatureclosemodel="closeAddSignModel()"
    :docId="this.documentid"
    :version="this.signVersionId"
    :versionno="versionforsigners"
    @urlReceived="handleUrlReceived"
    @showAdobeIframe="showAdobe_iframe = true"
  >
  </editegrement>
  <doc_Approval
    v-if="showDocumentApproval"
    :documentId="documentid"
    :version="latestVersion"
    :projectId="projectid"
    :dropdownName="dropdownname"
    @closeApproveModal="showDocumentApproval = false"
  >
  </doc_Approval>
  <adobe_iframe
    v-if="showAdobe_iframe"
    :iframeurl="adobeUrl"
    @iframeclosemodel="showAdobe_iframe = false"
  >
  </adobe_iframe>
  <div class="password-popup" v-if="showpopup">
    <div class="modal-width">
      <div class="modal-content">
        <div class="password-popup-header d-flex justify-content-between p-3">
          <h5 class="my-0 align-self-center">Sign Document</h5>
          <span
            type="button"
            class="align-self-center"
            @click="closePopup()"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body modal-overflow">
          <form>
            <div class="form-group mb-4">
              <h5>
                !!!On signing, this is equivalent to the wet ink signature!!!
              </h5>
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                required=""
                v-model="emailId"
              />
            </div>
            <div class="form-group mb-4 position-relative">
              <input
                placeholder="Please enter your Digi-sign password"
                class="form-control"
                :type="inputType"
                id="templatePassword"
                name="password"
                v-model="templatePassword"
              />
              <i
                class="password-visible-block cursor-pointer position-absolute"
                :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
                @click.prevent="passwordVisible()"
              ></i>
              <span v-if="validation" class="errmsg">{{ err }}</span>
              <span v-if="errorMsg" class="errmsg"
                >Please enter a password</span
              >
            </div>
            <div class="form-group mb-4">
              <label class="mb-0" for="reasons">Reason</label>
              <select
                class="form-control"
                name="account"
                v-model="reasons"
                id="reasons"
              >
                <option value="" disabled selected>-Select Reason-</option>
                <option
                  v-for="reason in reasonList"
                  :key="reason.id"
                  :value="reason.reason"
                >
                  {{ reason.reason }}
                </option>
              </select>
              <input
                class="form-control mt-2"
                v-if="reasons === 'Others'"
                maxlength="50"
                v-model="otherReason"
                type="text"
                name="reason"
              />
              <div v-if="error[1]" class="errmsg">Please enter a reason</div>
              <!-- <div v-if="error[0]" class="errmsg">Please enter a reason</div> -->
            </div>
            <div class="text-center">
              <span
                type="button"
                tabindex="0"
                class="submit_btn px-5 py-2"
                :disabled="loadingBlock"
                @click.prevent="submitTemplate(signValue)"
              >
                <span v-if="loadingBlock && !errorMsg"
                  ><img
                    src="../../assets/img/loading.webp"
                    width="20"
                    class="loading-img mr-2"
                    alt="Login buffering"
                  />
                </span>
                <span v-else>Submit</span></span
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
// import UserAuthPopup from "../UserAuthPopup/UserAuthPopup.vue";
import editegrement from "../EditEgrimentPopup/Editegrement.vue";
import mergefilepopup from "../mergefilepopup/mergefilepopup.vue";
import adobe_iframe from "../adobe_iframe/adobe_iframe.vue";
import axios from "axios";
import VuePdfEmbed from "vue-pdf-embed";
import print from "vue3-print-nb";
import store from "../../store/index";
import NewDocVersion from "../NewDocVersion/NewDocVersion.vue";
import doc_Approval from "../document_Approval_Popup/doc_Approval.vue";
import { encrypt } from "../../common/encryption";
export default {
  props: {
    documentid: String,
    version: String,
    versionType: String,
    storageId: String,
  },
  components: {
    VuePdfEmbed,
    NewDocVersion,
    editegrement,
    doc_Approval,
    adobe_iframe,
    mergefilepopup,
  },
  directives: {
    print,
  },
  data() {
    return {
      docIdentity: "",
      versionIdentity: "",
      mergeVersion: "",
      mergeDocId: "",
      mergeFileName: "",
      versionforsigners: "",
      isLoading: true,
      errmsg: "",
      errmsgshow: false,
      showAdobe_iframe: false,
      signpassword: "",
      signurl: "",
      addsignModal: false,
      signIcon: "",
      isuserenabled: false, // add new version user permission
      signFormat: ["pdf", "PDF", "DOCX", "docx", "doc", "DOC"],
      signDocId: "",
      signVersion: "",
      newversionforsigner: "",
      selectedversionid: "",
      isSigned: false,
      isDocorPdf: false,
      signModal: false,
      imgFormat: ["PNG", "png", "jpeg", "jpg"],
      readMore: [false],
      setpreviouspath: "",
      versionfrom: "",
      prevRoute: null,
      changedVersionId: "",
      loadVersion: "",
      sourcePage: "",
      newdocversionPopup: false,
      showButton: false,
      showpopup: false,
      fileName: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      documentUrl: `${process.env.VUE_APP_Service_URL}`,
      office: ["xls", "xlsx", "doc", "DOC", "docx", "DOCX"],
      format: "",
      source: "",
      officeurl: "",
      NewFile: "",
      title: "",
      showQuestion: false,
      documentTitle: "",
      latestVersion: "",
      projectid: "",
      dropdownname: "",
      fromsource: "",
      showDocumentApproval: false,
      updatedBy: "",
      uploadedBy: "",
      standard: "",
      owner: "",
      type: "",
      idtoken: "",
      readValue: "",
      downloadValue: "",
      viewValue: "",
      editValue: "",
      printValue: "",
      apiresult: "",
      docsId: "",
      shairedfolder: "",
      approvaldetails: "",
      checkstatusvalue: "Pending , Approved , Rejected",
      comments: "",
      showValue: "",
      approvalss: "",
      versionList: [],
      newVersion: "",
      isSignedValue: "",
      inputType: "password",
      signVersionId: "",
      templatePassword: "",
      visibleOn: false,
      validation: false,
      errorMsg: false,
      error: [false, false],
      err: "",
      otherReason: "",
      loadingBlock: false,
      reasonList: [],
      reasons: "",
      verType: "",
      emailId: "",
      versionForApi: "",
      printObj: {
        asyncUrl(reslove, vue) {
          setTimeout(() => {
            reslove(this.officeurl);
          }, 2000);
        },
        popTitle: "vue-iframe-print",
        extraCss: "https://www.baidu.com/,https://www.baidu.com/",
      },
      EligbleExam: false,
      configItem: "",
      configid: "",
      xam: true,
      designationPrivileges: "",
      sourceRoute: "",
      istherefiletoview: true,
      formRoute: "",
      mergeFileModal: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log("From is", from);
    if (
      from.name == undefined &&
      localStorage.getItem("currenttab") == "development"
    ) {
      store.dispatch("setDocSign", true);
    }

    next((vm) => {
      vm.prevRoute = from;
    });
  },
  async onBeforeMount() {
    this.getVersionHistory();
  },

  async mounted() {
    this.versionforsigners = this.version;
    window.addEventListener("message", this.handleIframeMessage);
    this.signDocId = this.documentid;
    this.designationPrivileges = store.getters.getRolesprivilegeData.split(",");
    this.sourcePage = await store.getters.getDocViewSource;
    console.log(this.sourcePage, "this.sourcePage");
    if (this.sourcePage == `Project` || this.sourcePage == "ProjectSummary") {
      this.sourceRoute = `Projects`;
      this.fromsource = "ProjectSummary";
      this.dropdownname = "ProjectRole";
      console.log(this.dropdownname, "PAGE NAME");
    } else if (this.sourcePage == `Department`) {
      this.sourceRoute = `Departments`;
      this.fromsource = "Department";
      this.dropdownname = "Department";
      console.log(this.dropdownname, "PAGE NAME");
    } else if (this.sourcePage == "generalFiles") {
      this.sourceRoute = `My Files`;
      this.versionfrom = "generalFiles";
      this.dropdownname = "generalFiles";
    }
    if (this.prevRoute != null) {
      if (this.prevRoute.path != "/") {
        localStorage.setItem("patvalue", this.prevRoute.path);
      }
      this.setpreviouspath = localStorage.getItem("patvalue");
      if (this.setpreviouspath != "/document_approval") {
        this.checkDoc(this.documentid, true);
        this.showQuestion = true;
      }
      console.log("this.setpreviouspath", this.setpreviouspath);
      if (this.setpreviouspath == "/department") {
        this.versionfrom = "fromdepartment";
      } else if (this.setpreviouspath == "/generalFiles") {
        this.versionfrom = "generalFiles";
      } else if (this.setpreviouspath == "/dashboard") {
        console.log(this.versionId);
        this.versionfrom = "dashboard";
      } else if (this.setpreviouspath == "/doclogin/") {
        console.log(this.versionId);
        this.versionfrom = "dashboard";
      } else {
        this.versionfrom = "fromproject";
      }
      if (this.setpreviouspath != "/documentList") {
        this.showQuestion = true;
      }
      if (this.setpreviouspath == "/generalFiles") {
        this.versionfrom = "generalFiles";
      }
    }

    this.shairedfolder = store.getters.getshaioredfolder;
    if (this.shairedfolder) {
      this.docsId = true;
      this.getArchived(this.documentid, this.docsId);
    } else {
      if (this.sourcePage == "Approval") {
        this.idtoken = store.getters.getToken;
        await this.versionDocType();
        await this.versionDocViewer();
        await this.getApprovalStatus();
        //await this.getVersionHistory();
        await this.getDocumentdetails();
        await this.getDocumentSignDetails();
      } else {
        store.dispatch("setdocumenttodepartment", true);
        this.NewFile = this.filename;
        this.idtoken = store.getters.getToken;
        await this.getDocumentdetails();
        await this.settype();
        await this.setdoc();
        await this.getApprovalStatus();
        //await this.getVersionHistory();
        await this.getDocumentSignDetails();
      }
    }

    // await this.getVersionHistory();
    await this.getIsSignedStatus();
    //await this.testcompleteduser();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != "versioncodereview" && to.name != "versionunittest") {
      store.dispatch("setDocSign", false);
      localStorage.setItem("devDocType", "");
    }
    next();
  },
  methods: {
    async refreshApis(docId, version) {
      this.mergeDocId = docId;
      this.mergeVersion = version;
      this.$router.push(`/documentview/` + docId + "/" + version);
      this.docIdentity = docId;
      this.versionIdentity = version;
      window.addEventListener("message", this.handleIframeMessage);
      await this.getVersionHistory();
      await this.versionDocType();
      await this.versionDocViewer();
      await this.getApprovalStatus();
      await this.getDocumentSignDetails();
      await this.getIsSignedStatus();
    },

    handleUrlReceived(url) {
      this.adobeUrl = url;
      this.showAdobe_iframe = true;
    },
    passwordVisible() {
      this.visibleOn = !this.visibleOn;
      if (this.visibleOn == true) {
        this.inputType = "text";
      } else this.inputType = "password";
    },
    async getReasons() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseUrl}document/projectdevdocumenttemplate/getallreason`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.reasonList = res.data;
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    handleIframeMessage(event) {
      if (event.origin == "https://secure.in1.adobesign.com") {
        // alert("signoption");
        console.log("event issss", event);
        if (event.data.includes('"type":"ESIGN"')) {
          alert("Successfully signed");
          this.showAdobe_iframe = false;
        }
      }
    },
    async submitTemplate() {
      this.validation = false;
      this.error[1] = false;
      this.loadingBlock = true;
      let newsignversionid = this.signVersion;
      if (this.setpreviouspath == "/dashboard" && this.version != "0") {
        newsignversionid = this.version;
      } else if (this.setpreviouspath == "/doclogin/" && this.version != "0") {
        newsignversionid = this.version;
      } else {
        newsignversionid = this.signVersion;
      }
      if (this.emailId == "") {
        this.err = "Please enter a valid email id";
        this.validation = true;
        this.loadingBlock = false;
        return;
      }
      if (this.templatePassword == "") {
        this.err = "Please enter your password";
        this.validation = true;
        this.loadingBlock = false;
        return;
      }
      if (this.reasons === "") {
        this.error[1] = true;
        this.loadingBlock = false;
        return;
      }

      const idtoken = store.getters.getToken;
      const encryptedPassword = await encrypt(this.templatePassword.trim());
      await axios
        .post(
          `${this.baseUrl}document/document/signdoc`,
          {
            signerEmail: this.emailId,
            versionId: "",
            version: this.version,
            documentId: this.documentid,
            password: encryptedPassword,
            reason: this.reasons,
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingBlock = false;
          console.log("2");
          this.source = `${this.baseUrl}document/document/file/${res.data.documentId}?version=${res.data.versionId}`;
          this.officeurl = `https://view.officeapps.live.com/op/embed.aspx?src=${this.source}`;
          alert("Signature added successfully");
          this.signVersionId = res.data.versionId;
          this.getDocumentdetails();
          if (this.isDocorPdf === true) {
            this.settype();
          }
          this.getIsSignedStatus();
          this.closePopup();
        })
        .catch((err) => {
          if (
            err.response.data.messages &&
            err.response.data.messages[0].includes(
              "System.IO.FileFormatException: File contains corrupted data"
            )
          ) {
            this.err =
              "Unsupported file format! (Supports only .docx format for signing)";
          } else {
            this.err = err.response.data.errors.DomainValidations[0];
          }

          this.validation = true;
          this.loadingBlock = false;
          this.password = "";
        });
    },
    async Signdocfun() {
      let newsignversionid = this.signVersion;
      if (this.setpreviouspath == "/dashboard" && this.versionId != "0") {
        newsignversionid = this.versionId;
      } else if (
        this.setpreviouspath == "/doclogin/" &&
        this.versionId != "0"
      ) {
        newsignversionid = this.versionId;
      } else {
        newsignversionid = this.signVersion;
      }
      // signpassword
      this.errmsg = "";
      this.errmsgshow = false;
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseUrl}document/document/getsigningurl?documentId=${this.documentid}&versionId=${newsignversionid}&password=${this.signpassword}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Signdocfun result is ", res);
          this.adobeUrl = res.data;
          this.showAdobe_iframe = true;
          this.closeSignModel();
        })
        .catch((err) => {
          console.log("POPUP ERROR", err);
          this.errmsg = err.response.data.errors.DomainValidations[0];
          this.errmsgshow = true;
          this.signpassword = "";
        });
    },
    closePopup() {
      this.showpopup = false;
      this.reasons = "";
      this.templatePassword = "";
      this.emailId = "";
      this.errorMsg = false;
      this.validation = false;
      this.errorMsg = false;
      this.error = [false, false];
    },
    openViewResult(id) {
      store.dispatch("setDocViewSource", this.fromsource);
      this.$router.push(`/ExamResult/` + id);
    },
    async documentApproval(documentId) {
      // this.version = this.latestVersion;
      this.documentId = documentId;
      this.showDocumentApproval = !this.showDocumentApproval;
    },
    // openExam(id) {
    //   store.dispatch("setDocViewSource", this.fromsource);
    //   this.$router.push(`/configure_exam/` + id);
    // },
    async getSignedDocView() {
      this.idtoken = store.getters.getToken;
      this.source = `${this.baseUrl}document/document/file/${this.documentid}?version=${this.signVersion}`;
      await axios
        .get(
          `${this.baseUrl}document/document/getsigneddoc?docId=${this.documentid}&version=${this.signVersion}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data != "") {
            this.istherefiletoview = true;
            let contentdispo = res.headers["content-disposition"];
            this.format = contentdispo.split(".").pop();
            console.log("fileformat", this.format);
            this.isLoading = false;

            this.officeurl = `https://view.officeapps.live.com/op/embed.aspx?src=${this.source}`;
            console.log("url is", this.officeurl);
          } else {
            this.istherefiletoview = false;
          }
        })
        .catch((err) => {
          console.log("error response is", err.response.data);
        });
    },
    async getIsSignedStatus() {
      this.idtoken = store.getters.getToken;
      //in below api version=${this.latestVersion} changes to version=${this.versionforsigners}
      await axios
        .get(
          `${this.baseUrl}document/document/getcurrentsortorder?docId=${this.documentid}&version=${this.versionforsigners}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("get document status", res.data);
          this.isSignedValue = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDocumentSignDetails() {
      let docIdentifier = "";
      let versionIdentifier = "";
      if (this.docIdentity != "") {
        console.log("Inside Merge Fun of doc view");
        docIdentifier = this.docIdentity;
        versionIdentifier = this.versionIdentity;
      } else {
        docIdentifier = this.documentid;
        versionIdentifier = this.version;
      }
      console.log("Inside getDocumentSignDetails");
      this.idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseUrl}document/document/getdocdetailsbyid?documentId=${docIdentifier}&version=${versionIdentifier}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("getDocumentSignDetails resp", res.data);
          console.log("versionType", res.data.versionType);
          console.log("signedVersion", res.data.signedVersion);
          this.mergeDocId = res.data.documentId;
          this.mergeVersion = res.data.version;
          this.mergeFileName = res.data.fileName;
          if (this.signFormat.includes(res.data.versionType)) {
            this.isDocorPdf = true;
          }
          if (res.data.signedVersion != null) {
            this.isSigned = true;
          }
        })
        .catch((err) => console.log("err in getDocumentSignDetails", err));
    },
    async closeSignModel() {
      await this.getDocumentSignDetails();
      this.signModal = false;
      this.signpassword = "";
      this.errmsg = "";
    },
    async closeAddSignModel() {
      this.getIsSignedStatus();
      this.addsignModal = false;
    },
    async handleCustomEvent(data) {
      this.source = `${this.baseUrl}document/document/file/${data.documentId}?version=${data.version}`;
      this.dcoumentid = data.documentId;
      await this.refreshApis(data.documentId, data.version);
    },
    async closeMergeFileModel() {
      this.mergeFileModal = false;
      // this.setdoc();
    },
    showMore(index) {
      this.readMore[index] = true;
      console.log("INDEX", index);
    },
    showLess(index) {
      this.readMore[index] = false;
    },
    async Question() {
      this.$router.push(`/question/` + this.documentid);
    },
    async newversionuploadactivity() {
      this.newdocversionPopup = false;

      // await this.getDocumentdetails();
      // await this.setdoc();
      await this.getVersionHistory();
      this.versionforsigners = this.newversionforsigner;
      console.log("IS LATEST VERSION BINDEDD", this.newversionforsigner);
      const id = this.documentid;
      const version = this.newversionforsigner;
      this.$router.push(`/documentview/` + id + "/" + version);
      await this.getIsSignedStatus();
      await this.getDocumentdetails();
      await this.setdoc();
    },
    async NewDocVersion() {
      if (localStorage.getItem("devDocType") == "UnitTest") {
        this.$router.push(
          `/versionunittest/${localStorage.getItem("ProjectName")}/${
            store.getters.getselectedFolder
          }/${localStorage.getItem("selectedProject")}/${localStorage.getItem(
            "unitTestId"
          )}/1`
        );
      } else if (localStorage.getItem("devDocType") == "CodeReview") {
        this.$router.push(
          `/versioncodereview/${localStorage.getItem("ProjectName")}/${
            store.getters.getselectedFolder
          }/${localStorage.getItem("selectedProject")}/${localStorage.getItem(
            "unitTestId"
          )}/1`
        );
      } else {
        this.newdocversionPopup = !this.newdocversionPopup;
      }
    },
    async getDocumentdetails(version) {
      this.readValue = "";
      this.downloadValue = "";
      this.viewValue = "";
      this.editValue = "";
      await axios
        .get(
          `${this.baseUrl}document/document/getdocumentdetails?documentId=${
            this.documentid
          }${version ? `&version=${version}` : ""}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          this.signVersion = res.data.storageVersionId;
          if (res.data.storageVersionId == null) {
            this.signVersion == this.version;
          }
          this.versionForApi = this.signVersion;
          console.log("hhhhhhhh", this.versionForApi);
          if (res.data.isSigned === true) {
            this.isDocorPdf = true;
          }
          if (this.version != this.selectedversionid) {
            this.showButton = false;
          }
          if (this.selectedversionid == res.data.latestVersion) {
            this.showButton = true;
          } else if (
            this.selectedversionid == "" &&
            this.version == res.data.latestVersion
          ) {
            this.showButton = true;
          } else {
            this.showButton = false;
          }
          (this.title = res.data.name),
            (this.documentTitle = res.data.documentTitle),
            (this.latestVersion = res.data.latestVersion),
            (this.standard = res.data.standard),
            (this.uploadedBy = res.data.uploadedBy),
            (this.owner = res.data.owner),
            (this.type = res.data.type),
            (this.updatedBy = res.data.uploadedBy);

          if (res.data.permissions != null) {
            this.isuserenabled = false;
            console.log(res.data.permissions);
            res.data.permissions.forEach((item) => {
              if (item == "Read") this.readValue = item;
              if (item == "Download") this.downloadValue = item;
              if (item == "View") this.viewValue = item;
              if (item == "Edit") this.editValue = item;
              if (item == "Print") this.printValue = item;
              if (item == "Sign") this.signIcon = item;
              if (item == "Upload") this.isuserenabled = true;
            });
          }
          if (
            this.setpreviouspath == "/dashboard" ||
            this.setpreviouspath == "/doclogin/"
          ) {
            console.log(res.data.folderId);
            store.dispatch("selectFolder", res.data.folderId);
          }
          await this.getVersionHistory();
          console.log("THe VERsion clicked", this.latestVersion);
           await this.setdoc()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async versionViewer(versionId, versionType, version) {
      console.log(
        "versionId, versionType, version",
        versionId,
        versionType,
        version
      );
      const mid = this.documentid;
      this.$router.push(`/documentview/` + mid + "/" + version);
      this.mergeVersion = version;
      this.selectedversionid = version;
      console.log("Seletced versio IDD", this.selectedversionid);
      this.versionforsigners = version;
      await this.getDocumentdetails(version);
      this.isSigned = false;
      this.isDocorPdf = false;
      this.newversionId = version;
      this.format = versionType;
      this.signVersion = this.newversionId;
      this.signDocId = this.documentid;
      await this.getDocumentSignDetails();
      await this.getIsSignedStatus();
      this.isLoading = false;
      this.source = `${this.baseUrl}document/document/file/${this.documentid}?version=${version}`;
      console.log("version veiw source", this.source);
      await axios
        .get(
          `${this.baseUrl}document/document/file/${this.documentid}?version=${version}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data != "") {
            this.istherefiletoview = true;
            this.officeurl = "";
            this.officeurl = `https://view.officeapps.live.com/op/embed.aspx?src=${this.source}`;
            console.log("this.format", this.format);
            this.ApprovalStatusRefresh(this.documentid, version);
            this.loadVersion = version;
          } else {
            this.istherefiletoview = false;
          }
        })
        .catch((err) => {
          console.log("error response is", err.response.data);
        });
    },

    async versionDocViewer() {
      let docIdentifier = "";
      let versionIdentifier = "";
      if (this.docIdentity != "") {
        docIdentifier = this.docIdentity;
        versionIdentifier = this.versionIdentity;
      } else {
        docIdentifier = this.documentid;
        versionIdentifier = this.version;
      }
      this.isLoading = false;
      this.source = `${this.baseUrl}document/document/file/${docIdentifier}?version=${versionIdentifier}`;
      console.log("versionDocViewer", this.source);
      await axios
        .get(
          (this.source = `${this.baseUrl}document/document/file/${docIdentifier}?version=${versionIdentifier}`),
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data != "") {
            this.istherefiletoview = true;
            this.officeurl = "";
            this.officeurl = `https://view.officeapps.live.com/op/embed.aspx?src=${this.source}`;
          } else {
            this.istherefiletoview = false;
          }
        })
        .catch((err) => {
          console.log("error response is", err.response.data);
        });
    },

    async ApprovalStatus(value) {
      if (value == true) {
        this.status = "Approved";
      } else {
        this.status = "Rejected";
      }
      if (this.sourcePage == "Approval" && this.loadVersion != "") {
        this.versionNum = this.loadVersion;
      } else {
        this.versionNum = this.version;
      }
      if (value == true) {
        if (confirm("Are you sure you want to approve the document?")) {
          await this.approvalsave(value);
        }
      } else {
        if (confirm("Are you sure you want to reject the document?")) {
          await this.approvalsave(value);
        }
      }
    },
    async approvalsave(value) {
      const idtoken = store.getters.getToken;
      await axios
        .put(
          `${this.documentUrl}document/document/approvereject`,
          {
            documentId: this.documentid,
            version: this.versionNum,
            approvalStatus: this.status,
            comments: this.comments,
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (value == true) {
            alert("The document approved successfully.");
          } else {
            alert("The document rejected successfully.");
          }
          window.location.reload();
          this.getApprovalStatus();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async refreshApprovalList() {
      await store.dispatch("setApprovalListRefresh", true);
      window.dispatchEvent(
        new CustomEvent("ApprovalStatusChange", {
          detail: {
            storage: store.getters.getApprovalListRefresh,
          },
        })
      );
    },
    async getApprovalStatus() {
      let docIdentifier = "";
      let versionIdentifier = "";
      if (this.docIdentity != "") {
        docIdentifier = this.docIdentity;
        versionIdentifier = this.versionIdentity;
      } else {
        docIdentifier = this.documentid;
        versionIdentifier = this.version;
      }
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseUrl}document/document/approvalbyid?documentId=${docIdentifier}&version=${versionIdentifier}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("archived folders are11 ", this.version);
          this.approvaldetails = res.data;
          this.showValue = this.approvaldetails.approvedStatus;
          console.log("approved list ", this.showValue);
        });
    },
    async ApprovalStatusRefresh(docId, version) {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseUrl}document/document/approvalbyid?documentId=${docId}&version=${version}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("approval details refreshed ", res);
          this.approvaldetails = res.data;
          this.showValue = this.approvaldetails.approvedStatus;
          console.log("approved list ", this.showValue);
        });
    },
    async checkDoc(docId, isDoc) {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.mainapi}document/folder/getarchive?Id=${docId}&doc=${isDoc}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (
            res.data ==
            "The document you are trying to access is currently archived!"
          ) {
            alert(res.data);
            this.$router.push(`/document_approval`);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    async ShowSignModal() {
      this.showpopup = true;
      this.getReasons();
    },
    async AddsignersSignModal() {
      this.addsignModal = true;
    },
    async openmergeFilePopup() {
      this.mergeFileModal = true;
    },
    async getArchived(folId, docsId) {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.mainapi}document/folder/getarchive?Id=${folId}&doc=${docsId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("archived folders are ", res);
          this.apiresult = res.data;
          if (this.apiresult != " No folder is Archived") {
            alert(this.apiresult);
          } else {
            store.dispatch("setdocumenttodepartment", true);
            this.NewFile = this.filename;
            this.idtoken = store.getters.getToken;
            this.getDocumentdetails();
            this.settype();
            this.setdoc();
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    async versionDocType() {
      let docIdentifier = "";
      let versionIdentifier = "";
      if (this.docIdentity != "") {
        docIdentifier = this.docIdentity;
        versionIdentifier = this.versionIdentity;
      } else {
        console.log("Version e");
        docIdentifier = this.documentid;
        versionIdentifier = this.version;
      }
      console.log("6");
      await axios
        .get(
          `${this.baseUrl}document/document/file/${docIdentifier}?version=${versionIdentifier}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let contentdispo = res.headers["content-disposition"];
          this.format = contentdispo.split(".").pop();
          console.log("fileformat", this.format);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    async settype() {
      console.log("Testttttt", this.source);
      await axios
        .get(
          `${this.baseUrl}document/document/getanonymous?documentId=${this.documentid}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let contentdispo = res.headers["content-disposition"];
          this.format = contentdispo.split(".").pop();
          if (this.format === "pdf") {
            this.istherefiletoview = true;
          }
          console.log("fileformat", this.format);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },

    async setdoc() {
      this.isLoading = false;
      console.log("bugggg", this.versionForApi);
      this.source = `${this.baseUrl}document/document/file/${this.documentid}?version=${this.versionForApi}`;
      console.log("Testttttt", this.source);
      console.log("data is", this.source);
      await axios
        .get(
          `${this.baseUrl}document/document/getanonymous?documentId=${this.documentid}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data != "") {
            this.istherefiletoview = true;
            this.officeurl = `https://view.officeapps.live.com/op/embed.aspx?src=${this.source}`;
            console.log("this.officeurl", this.officeurl);
          } else {
            this.istherefiletoview = false;
          }
        })
        .catch((err) => {
          console.log("error response is", err.response.data);
        });
    },

    async downloadFile() {
      console.log("Document ID binded", this.documentid);
      console.log("Verrsionn ID binded", this.selectedversionid);
      this.downloadsource = `${this.baseUrl}document/document/getanonymous?documentId=${this.documentid}&version=${this.selectedversionid}`;
      await axios
        .get(
          //`https://localhost:5001/document/getanonymous?documentId=${this.documentid}&versionId=${this.selectedversionid}`,
          `${this.baseUrl}document/document/getanonymous?documentId=${this.documentid}&version=${this.selectedversionid}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data != "") {
            var a = document.createElement("a");
            a.href = this.downloadsource;
            a.download = "FILENAME";
            a.click();
          }
        })
        .catch((err) => {
          console.log("error response is", err.response.data);
        });
    },
    async getVersionHistory() {
      let docIdentifier = "";
      let versionIdentifier = "";
      if (this.docIdentity != "") {
        docIdentifier = this.docIdentity;
        versionIdentifier = this.versionIdentity;
      } else {
        docIdentifier = this.documentid;
        versionIdentifier = this.version;
      }
      await axios
        .get(
          `${this.baseUrl}document/document/version?documentId=${docIdentifier}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.versionList = res.data;
          this.newversionforsigner = this.versionList[0].version;
          console.log("version list isssssssssssss", this.versionList);
        })
        .catch((err) => {
          console.log(err);
          this.versionList = [];
        });
    },
    async closebutton() {
      const fromPage = await store.getters.getFromPage;
      let selectfolder = store.getters.getselectedFolder;
      store.dispatch("setopenfolder", selectfolder);
      console.log(store.getters.getFromPage, "MESSAGE ");
      console.log(this.setpreviouspath, "MESSAGE 2");
      if (this.setpreviouspath.includes("project_summary")) {
        let path = localStorage.getItem("patvalue");
        console.log("Project ", path);
        this.$router.push(path);
      } else if (this.setpreviouspath == "/department") {
        this.$router.push(`/department`);
        console.log("department");
      } else if (this.setpreviouspath == "/dashboard") {
        this.$router.push(`/dashboard`);
        console.log("dashboard");
      } else if (this.setpreviouspath == "/projectList") {
        this.$router.push(`/projectList`);
        console.log("projectList");
      } else if (this.setpreviouspath == "/userlisting") {
        this.$router.push(`/userlisting`);
        console.log("userlisting");
      } else if (this.setpreviouspath == "/designation") {
        this.$router.push(`/designation`);
        console.log("designation");
      } else if (this.setpreviouspath == "/generalFiles") {
        this.$router.push(`/generalFiles`);
        console.log("generalFiles");
      } else if (
        this.setpreviouspath.includes("/versioncodereview/") ||
        this.setpreviouspath.includes("/versionunittest/")
      ) {
        let projectType = store.getters.getfileType;
        let selectedProject = store.getters.getSelectedProject;
        let fileName = store.getters.getfileName;
        this.$router.push(
          `/project_summary/${selectedProject}/${projectType}/${fileName}`
        );
      }
      if (this.setpreviouspath == "/doclogin/") {
        console.log("document");
        this.$router.push(`/dashboard`);
      }
      if (this.setpreviouspath.includes("/question")) {
        if (fromPage == "ProjectSummary") {
          let projectType = store.getters.getfileType;
          let selectedProject = store.getters.getSelectedProject;
          let fileName = store.getters.getfileName;
          this.$router.push(
            `/project_summary/${selectedProject}/${projectType}/${fileName}`
          );
        } else {
          console.log("question");
          this.$router.push(`/department`);
        }
      }
      if (this.setpreviouspath.includes("/myQuestion")) {
        if (fromPage == "ProjectSummary") {
          let projectType = store.getters.getfileType;
          let selectedProject = store.getters.getSelectedProject;
          let fileName = store.getters.getfileName;
          this.$router.push(
            `/project_summary/${selectedProject}/${projectType}/${fileName}`
          );
        } else {
          console.log("myQuestion");
          this.$router.push(`/department`);
        }
      }
      if (this.setpreviouspath.includes("/configure_exam")) {
        if (fromPage == "ProjectSummary") {
          let projectType = store.getters.getfileType;
          let selectedProject = store.getters.getSelectedProject;
          let fileName = store.getters.getfileName;
          this.$router.push(
            `/project_summary/${selectedProject}/${projectType}/${fileName}`
          );
        } else {
          this.$router.push(`/department`);
        }
      }
      if (this.setpreviouspath.includes("/ExamResult")) {
        if (fromPage == "ProjectSummary") {
          let projectType = store.getters.getfileType;
          let selectedProject = store.getters.getSelectedProject;
          let fileName = store.getters.getfileName;
          this.$router.push(
            `/project_summary/${selectedProject}/${projectType}/${fileName}`
          );
        } else {
          this.$router.push(`/department`);
        }
      }
      if (this.setpreviouspath == "/document_approval") {
        console.log("document");
        this.$router.push(`/document_approval`);
      }
      if (fromPage == "DocumentList") {
        this.$router.go(-1);
      }
      if (fromPage == "generalFiles") {
        this.$router.go(-1);
      }
      if (fromPage == "UnitTest" || fromPage == "CodeReview") {
        let projectType = store.getters.getfileType;
        let selectedProject = store.getters.getSelectedProject;
        let fileName = store.getters.getfileName;
        let selectfolder = store.getters.getselectedFolder;
        store.dispatch("setopenfolder", selectfolder);
        this.$router.push(
          `/project_summary/${selectedProject}/${projectType}/${fileName}`
        );
      }
    },
    // async testcompleteduser() {
    // if (this.prevRoute != null) {
    //           if(this.prevRoute.path !='/')
    //             {
    //               localStorage.setItem("retestvalue", store.getters.getRetest);
    //               console.log(2)
    //             }
    //         }
    //         this.checkretest = localStorage.getItem("retestvalue");
    //         console.log("checkretest", this.checkretest)
    //         if(this.checkretest=="1"){
    //           await this.checkalreadyretestattend();
    //         }

    //      if(this.isreset!= true){

    // await axios
    //   .get(
    //     `${this.baseUrl}document/documentquestion/getexamcompleteduser?documentid=${this.documentid}&configId=${this.configid}`,
    //     {
    //       headers: {
    //         Authorization: "Bearer " + this.idtoken,
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     this.xam = res.data;
    //     console.log("value is", this.xam);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // }
    // else{
    //         alert("Retest already attended.")
    //         this.$router.go(-1);
    //     }
    //},
    // async checkalreadyretestattend()
    //      {
    //         console.log(3);
    //         console.log("Head val",this.Head)
    //         await axios.put(
    //             //`https://localhost:5001/documentquestion/takingretest?docId=${this.documentid}`,{},
    //              `${this.baseUrl}document/documentquestion/takingretest?docId=${this.documentid}`,{},
    //              {
    //         headers: {
    //           Authorization: "Bearer " + this.idtoken,
    //           "Content-Type": "application/json",
    //         },
    //       }
    //             )
    //           //  `${this.baseurl}takingretest?docId=${this.documentId}`, this.Head)
    //         .then((res) => {
    //             console.log("retest reslt ", res.data)
    //             this.isreset = res.data;
    //             if(this.isreset == false ||this.isreset == null){
    //             this.xam = false
    //             }
    //             console.log("Isreset",this.isreset);
    //         })
    //      },
    // async GoToOnlineExam() {
    //   await this.testcompleteduser();
    //   if (this.xam != true) {
    //     store.dispatch("setprvspath", "/documentview/"),
    //       this.$router.push(`/myQuestion/${this.documentid}/0`);
    //   }
    // },
  },
};
</script>
<style scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.pdfembeded {
  height: 700px;
  overflow: auto;
}

.max-width {
  max-width: 100%;
}

.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.table-bottom > thead > tr > th,
.table-bottom > tbody > tr > th,
.table-bottom > tfoot > tr > th,
.table-bottom > thead > tr > td,
.table-bottom > tbody > tr > td,
.table-bottom > tfoot > tr > td {
  border-top: 0px solid #e7eaec;
  line-height: 1;
  padding: 6px;
  vertical-align: middle;
}

.table-bottom {
  width: 100%;
  margin-top: 0px !important;
  color: #212529;
  border-collapse: collapse;
}

.hiddata {
  display: none;
}

.more {
  font-size: 11px;
  color: #618bd7;
}

.less {
  font-size: 11px;
  color: #618bd7;
}

.breakWord {
  word-break: break-word;
}

.graphbtncontainer {
  display: flex;
  gap: 5px;
}

.graphbutton {
  width: fit-content;
  padding: 3px;
  cursor: pointer;
}
.add_page_modal_wrapper,
.modal__wrapper__component {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}
.add_page_modal__containers {
  width: min(400px, 90%);
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.add-block-title {
  color: #ffffff;
  background: #768dd3;
}
.errorClass {
  color: red;
  font-size: 13px;
}
.errmsg {
  font-size: 12px;
  color: red;
  float: left;
}
.password-popup {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.password-popup-header {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.password-visible-block {
  right: 11px;
  top: 10px;
}
div[aria-label="toolbar"] {
  display: none;
}

div[aria-label="Pop-out"] {
  display: none;
}
.overlay {
  position: absolute;
  height: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 97%;
  margin: auto;
}
</style>
