<!-- Popup library -->
<template>
    <div class="create-folder-modal">
      <div class="modal-content width-500">
        <div class="modal-header">
          <h5 class="float-left mb-0">Authorize user</h5>
          <button
            type="button"
            @click="onclosed()"
            class="close"
            data-dismiss="modal"
          >
            <span aria-hidden="true">×</span><span class="sr-only">Close</span>
          </button>
        </div>
        
        <div class="modal-body">
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            placeholder="Email"
            required=""
            v-model="emailId"
          />
        </div>

        <div class="form-group">
          <input
            type="password"
            class="form-control"
            placeholder="Password"
            required=""
            v-model="password"
          />
          <span v-if="validation" class="errmsg">{{ err }}</span>
        </div>
      </div>

        <div class="modal-footer d-flex justify-content-between">
          <button
            @click="ConfirmClicked()"
            type="button"
            class="btn btn-primary btn-xs px-4 mr-3"
          >
            Submit 
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  
  import axios from "axios";
  import store from "../../store/index";
  
  export default {
    name: "ImgUserAuth",
    props: {
      docId: {
        type: String,
        default: "",
      },
      versionId: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        baseapi: "https://dmsdev.datamatica.uk/v1/",
        radiobutton: "",
        baseUrl: `${process.env.VUE_APP_Service_URL}`,
        emailId: "",
        password: "",
        err: "",
        validation: false,
      };
    },
    async mounted() {
      document.body.style.overflowY="hidden"
  
      await this.getdefaultSign();
    },
    async unmounted() {
      document.body.style.overflowY="auto"
    },
    methods: {
      async onclosed() {
        this.$emit("signatureclosemodel");
        this.emailId = "";
        this.password = "";
      },
      async getdefaultSign() {
        const idtoken = store.getters.getToken;
        await axios
          .get(`${this.baseapi}account-core/user/getdefaultsign`, {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (res.data == "No default sign found") {
  
              this.radiobutton = "Initials";
            } else {
              this.radiobutton = res.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      async ConfirmClicked() {
        if (this.emailId == "") {
          this.err = "Please enter a valid email id";
          this.validation = true;
          return;
        }
        if (this.password == "") {
          this.err = "Please enter your password";
          this.validation = true;
          return;
        }
        const idtoken = store.getters.getToken;
        await axios
          .post(
            `${this.baseUrl}document/signature/signdoc?docId=${this.docId}&versionId=${this.versionId}&email=${this.emailId}&password=${this.password}&signType=${this.radiobutton}`,
            {},
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            alert("Signature added successfully");
            this.onclosed();
          })
          .catch((err) => {
            console.log("POPUP ERROR", err);
            this.err = err.response.data.errors.DomainValidations[0];
            this.validation = true;
            this.password = "";
          });
      },
    },
  };
  </script>
  <style scoped>
  .submit_btn {
    text-align: center;
    cursor: pointer;
    background-color: #7b8cea !important;
    color: rgb(255, 255, 255) !important;
    padding: 0.55rem 0.4rem;
    border-radius: 0.25rem;
    border-color: #7b8cea !important;
    transition: background-color 0.3s ease-in-out 0s !important;
  }
  .previousbtn,
  .nextbtn {
    margin: 0;
    border-radius: 0px;
    border: 0.5px solid #eeeeee;
  }
  .page-links {
    border-radius: 0px;
  }
  /*pagination styles*/
  .pagination {
    margin: 0;
  }
  .Page,
  .PaginationControl {
    border: 1px solid #e7eaec;
  }
  .Page-active {
    padding: 1rem;
  
    border: 1px solid var(--pagination-active-clr);
    border-radius: inherit;
    margin: 0;
  }
  .Page {
    padding: 1rem;
    border-radius: inherit;
    margin: 0;
  }
  .PaginationControl {
    padding: 0.42rem;
  }
  .create-block {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.25rem;
  }
  .position-relative img {
    width: 20px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .position-relative .tool-tip {
    visibility: hidden;
    width: max-content;
    background-color: #616161;
    color: #fff;
    text-align: center;
    border-radius: 0.3em;
    padding: 0.3rem 0.8rem;
    right: 0;
    top: -30px;
    font-size: 0.8rem;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    -webkit-transition: visibility 75ms ease-in;
    transition: visibility 75ms ease-in;
  }
  .position-relative:hover .tool-tip,
  .position-relative:focus-visible .tool-tip {
    visibility: visible;
    -webkit-transition: visibility 30ms ease-in;
    transition: visibility 30ms ease-in;
  }
  .headingunderline {
    text-decoration-line: underline;
  }
  
  .create-folder-modal {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 3000;
  }
  .folder-modal-container {
    background: #fff;
    width: min(40%, 90%);
    border-radius: 4px;
    position: fixed;
    overflow-x: hidden;
    pointer-events: auto;
  }
  .title-class1 {
    background-color: #618bd7;
    color: #fff;
  }
  .errorClass {
    color: red;
    font-size: 13px;
  }
  .button-class {
    float: right;
  }
  .modal-header-designation {
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  .modal-width {
    width: min(400px, 90%);
  }
  .error-block {
    color: #ff0000;
  }
  .errmsg {
    font-size: 12px;
    color: red;
    float: left;
  }
  .width-500 {
    width: min(500px, 90%);
  }
  </style>
  