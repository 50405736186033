<template>
  <div class="ibox-title style_2 d-flex justify-content-between">
    <h5>Approve Training Plan</h5>
    <div class="configure-block">
      <div class="position-relative filter-block" @click.prevent="dateBlockDisplay()">
        <img class="cursor-pointer" src="../../assets/img/filter_grey.png" alt="Filter table icon"/>
        <span class="tool-tip">Filter</span>
      </div>
    </div>
  </div>
  <div id="cardtrainingApproval">
    <div class="card-body ibox-title style_2 row py-1">
      <div class="col-lg-3 pr-0">
        <form>
          <div class="mb-1 text-left">
            <label class="col-form-label">Planned date</label>
            <div class="input-daterange input-group" id="datepicker">
              <input
                v-model="searchPlannedstartdate"
                type="date"
                class="form-control"
                name="start"
              />
              <span class="input-group-addon">to</span>
              <input
                v-model="searchPlannedenddate"
                type="date"
                class="form-control"
                name="end"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-3 pr-0">
        <form>
          <div class="mb-1 text-left">
            <label class="col-form-label">Actual date</label>
            <div class="input-daterange input-group" id="datepicker">
              <input
                v-model="searchActualstartdate"
                type="date"
                class="form-control"
                name="start"
              />
              <span class="input-group-addon">to</span>
              <input
                v-model="searchActualenddate"
                type="date"
                class="form-control"
                name="end"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-2">
        <form>
          <div class="mb-1 text-left">
            <label class="col-form-label">Status</label>
            <select
              v-model="searchStatus"
              class="form-control m-b"
              name="Status"
            >
              <option value="" disabled selected>-Select-</option>
              <option selected value="All">All</option>
              <option value="Completed">Completed</option>
              <option value="Planned">Planned</option>
            </select>
          </div>
        </form>
      </div>
      <div class="col-lg-4 text-center align-self-center">
        <span
          type="button"
          class="submit_btn mt-3 px-5 py-2 w-20"
          @click="onSearchClicked"
          >Search</span
        >
        <span
          type="button"
          class="submit_btn mt-3 px-5 py-2 w-20 ml-3"
          @click="clearValues"
          >Clear</span
        >
      </div>
    </div>
  </div>
  <div class="form-table-view-container ">
    <div class ="table-responsive">
    <table class="table table-bordered training-plan">
      <thead>
        <tr>
          <th v-for="column in columnDefs" :key="column.name">
            <span v-html="column.header"></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id">
          <td v-for="column in columnDefs" :key="column.name">
            <slot :name="`col.${column.name}`" :item="item">

                <template v-if="column.name === 'skills'">
              <div v-if="item[column.name]">
                <div v-if="!showMoreStatesSkills[index] && item[column.name].length > 150">
                  {{ truncateTextSkills(item[column.name],120) }}
                  <span class="more" @click="toggleShowMoreSkills(index)">more</span>
                </div>
                <div v-else>
                  {{ item[column.name] }}
                  <span v-if="showMoreStatesSkills[index]" class="less" @click="toggleShowMoreSkills(index)">less</span>
                </div>
              </div>
              <div v-else>&nbsp;</div>
              </template>

            <template v-else-if="column.name === 'trainingDetails'">
                <div v-if="item[column.name]">
                  <div v-if="!showMoreStates[index] && item[column.name].length > 150">
                    {{ truncateText(item[column.name], 120) }}
                    <span class="more" @click="toggleShowMore(index)">more</span>
                  </div>
                  <div v-else>
                    {{ item[column.name] }}
                    <span v-if="showMoreStates[index]" class="less" @click="toggleShowMore(index)">less</span>
                  </div>
                </div>
                <div v-else>&nbsp;</div>
              </template>



              <template v-else-if="column.name === 'plannedDateRange'">
                <div class="date-range">
                {{
                  NeededDateRange(item.plannedStartDate, item.plannedEndDate)
                }}
                </div>
              </template>

              <template v-else-if="column.name === 'actualDateRange'">
                <div class="date-range">
                {{ NeededDateRange(item.actualStartDate, item.actualEndDate) }}
                </div>
              </template>



              <template v-else>
                <template v-if="column.name === 'signature'">
                  <div v-if="item[column.name]">
                    <div>
                      <strong>Signed By</strong> :
                      <span class="signed-by">{{
                        item[column.name].signedBy
                      }}</span>
                    </div>
                    <div>
                      <strong>Name</strong> :
                      <strong>{{ item[column.name].name }}</strong>
                    </div>
                    <div>
                      <strong>Reason</strong> :
                      <strong>{{ item[column.name].reason }}</strong>
                    </div>
                    <div>
                      <strong>Date and Time</strong> :
                      <strong>{{
                        formatDateTime(item[column.name].signedDate)
                      }}</strong>
                    </div>
                    <div>
                      <span class="ref-label"
                        ><strong
                          >Ref : {{ item[column.name].referenceId }}</strong
                        ></span
                      >
                    </div>
                  </div>
                  <div v-else class="text-center ">
                    <button class="btn btn-outline btn-success px-4 py-0 " type="button" @click.prevent="openModal(item.trainingId)"><i
                        class="fa fa-check"  ></i>&nbsp;Sign</button>
                  </div>
                </template>
                <template v-else>
                  {{ item[column.name] }}
                </template>
              </template>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
    <div class="row mt-3 mx-0">
      <div class="col-md-12 mx-0 px-0">
        <div
          class="dataTables_paginate paging_simple_numbers pagination float-right"
        >
          <div class="col-md-12 d-flex justify-content-md-end px-0">
            <v-pagination
              v-model="currentPage"
              :pages="totalPages"
              :range-size="1"
              active-color="#ededed"
              @update:modelValue="loadPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <DigitalSignPopUp
    v-if="showSignBlock"
    @closePopup="showSignBlock = false"
    @signAdded="signAddedFun"
    :acknId="trainingId"
    :SigningPerson="SignPerson"
  >
  </DigitalSignPopUp>
</template>
<script src="./approveTrainingPlan"></script>
<style scoped>
.ref-label {
  font-size: 0.85em;
}

.signed-by {
  font-family: "Brush Script MT", cursive;
  font-size: 1.5em;
}

.training-table-view {
  display: inline-block;
  text-align: left;
  width: auto;
  color: gray;
  border-collapse: collapse;
  background-color: white;
  margin: 0 auto;
}

.training-table-view td,
th {
  text-align: left;
  border: 1px solid black;
  padding: 10px;
}

.icon-tableview {
  width: 12rem;
  height: 4rem;
  margin-right: 10px;
}

.heading-tableview h2 {
  text-align: center;
  flex: 1;
  font-weight: bold !important;
}

.date-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.date-search input {
  flex: 1;
  margin-right: 10px;
}

.date-search button {
  flex: 1;
  margin-right: 10px;
}

#cardtrainingApproval {
  position: relative;
  height: 0px;
  overflow: hidden;
  transition: height 0.35s ease;
}

.plan-side-popup {
  position: absolute;
  text-align: left;
  right: 56px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(100px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.plan-side-popup > div {
  cursor: pointer;
  padding: 0.5rem;
}
.plan-side-popup > div:hover {
  background-color: #e4eaf9;
}
.training-plan thead tr th {
  text-wrap: balance !important;
  vertical-align: middle;
}
.training-plan thead tr th {
  background-color: hsl(0, 0%, 100%) !important;
}
.training-plan tr td:nth-child(2),
.training-plan tr td:nth-child(3){
  max-width: 40ch;
  word-wrap: break-word;
  white-space: break-spaces;
}
.filter-block .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.filter-block:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.more {
  font-size: 11px;
  color: #618bd7;
  cursor: pointer;
}

.less {
  font-size: 11px;
  color: #618bd7;
  cursor: pointer;
}
.date-range {
  white-space: pre-line;
}

</style>