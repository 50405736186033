<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10 mb-0 mt-1">
      <h2>Online Test</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i class="fa fa-times close cursor-pointer" @click.prevent="closebutton()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight pb-0">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title style_2">
            <h5>Online Test Configuration</h5>
          </div>
          <div class="ibox-content">
            <div class="form-group row">
              <div class="col-lg-3 my-2">
                <label class="col-form-label">Test name
                  <sup><i class="fa fa-asterisk imp"></i></sup>
                </label>
                <input id="textBox" maxlength="75" type="text" autocomplete="off" class="form-control"
                  v-model="config.examName" />

                <ul class="dangeralign">
                  <li class="errorClass dangeralign" v-if="errorMessage[0] == true">
                    Test name should be atleast 3 characters.
                  </li>
                </ul>
              </div>

              <div class="col-lg-3 my-2">
                <label class="col-form-label">Total number of questions
                  <sup><i class="fa fa-asterisk imp"></i></sup>
                </label>
                <input id="textBox" maxlength="3" type="text" autocomplete="off" class="form-control"
                  v-model="config.questionNo" @keypress="isNumber($event)" />

                <ul class="dangeralign">
                  <li class="errorClass dangeralign" v-if="errorMessage[1] == true">
                    Please enter the number of questions
                  </li>
                  <li class="errorClass dangeralign" v-if="errorMessage[2] == true">
                    Please enter a value that is a multiple of 10
                  </li>
                </ul>
              </div>

              <div class="col-lg-3 my-2">
                <label class="col-form-label">Percentage required to pass <sup><i
                      class="fa fa-asterisk imp"></i></sup></label>
                <input maxlength="3" type="text" class="form-control" placeholder="" v-model="config.percentage"
                  @keypress="isNumber($event)" />
                <ul class="dangeralign">
                  <li class="errorClass dangeralign" v-if="errorMessage[3] == true">
                    Please enter the percentage
                  </li>
                  <li class="errorClass dangeralign" v-if="errorMessage[4] == true">
                    Please enter a value that is a multiple of 10
                  </li>
                  <li class="errorClass dangeralign" v-if="errorMessage[5] == true">
                    The pass percentage cannot be greater than 100%
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 my-2 d-flex flex-column">
                <label class="col-form-label d-block">Total time<sup></sup></label>
                <div style="display: flex; flex-direction: row">
                  <input type="text" class="form-control mr-1" placeholder="Hours" v-model="config.timeinHours"
                    @keypress="isNumber($event)" maxlength="2" />

                  <label class="col-form-label"><sup></sup></label>
                  <input type="text" class="form-control ml-1" placeholder="Minutes " v-model="config.timeinMins"
                    @keypress="isNumber($event)" maxlength="2" />
                  <ul class="dangeralign">
                    <li class="errorClass dangeralign" v-if="errorMessage[5] == true">
                      Enter valid time in minutes
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-3 my-2">
                <label class="col-form-label">Exam date <sup><i class="fa fa-asterisk imp"></i></sup></label>
                <input type="date" class="form-control my-md-0" :disabled="isConfigued" v-model="config.endDate" :min="toDatePicker(dateToday)" />
                <span class="d-block errorClass" v-if="validationmsgs == true">
                  Please select a date in the present or future
                </span>
                <ul class="dangeralign">
                  <li class="errorClass dangeralign" v-if="errorMessage[7] == true">
                    Please select a date
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 my-2">
                <label class="col-form-label">Supervisor <sup><i class="fa fa-asterisk imp"></i></sup></label>
                <select @change="onSuperviserChange($event)" class="form-control m-b" name="account"
                  v-model="config.supervisor">
                  <option value="" disabled selected>-Select-</option>

                  <option v-for="users in supervisorList" :key="users.userId" :value="users">
                    {{ users.name }}
                  </option>
                </select>
                <ul class="dangeralign">
                  <li class="errorClass dangeralign" style="margin-top:-15px;" v-if="errorMessage[8] == true">
                    Please select a supervisor
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 my-2 " v-if="!isConfigued">
                <label class="col-form-label">Choose Designation </label>
                <Multiselect  name="designation" valueProp="id" mode="multiple"
                  :object="true" placeholder="Select Designations" :options="designationList" :hideSelected="false"
                  :closeOnSelect="false" class="multiselect-custom-theme" @clear="designationClear()" @change="onChange($event)"
                  v-model="designation">
                  <template v-slot:option="{ option }">
                    {{ option.displayName }}
                  </template>
                </Multiselect>
              </div>
              <div class="col-lg-3 my-2" v-if="!isConfigued">
                <label class="col-form-label">Users</label>
                <Multiselect name="users"  valueProp="userId" :disabled="isuserselect"
                  mode="multiple" :object="true" placeholder="Select Users" :options="finalUserList" :hideSelected="false"
                  :closeOnSelect="false" class="multiselect-custom-theme" v-model="config.users">
                  <template v-slot:option="{ option }">
                    {{ option.name }}
                  </template>
                </Multiselect>
              </div>
              <div class="col-lg-12 my-2">
                <button class="btn btn-primary cancel_btn py-1 px-4 mx-3" @click.prevent="clearForm()">
                  Clear
                </button>
                <button class="btn btn-primary btn-sm px-3 py-1" type="submit" v="" @click="checksaveUpdate()">
                  Submit
                </button>
              </div>
              <div class="col-sm-12 text-right">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Question popup -->
  <div class="config-popup" v-if="showpopup == true">
    <div class="modal-width width-100">
      <div class="modal-content">
        <div class="config-popup-header d-flex justify-content-between p-3">
          <h5 class="my-0 align-self-center">Add Questions</h5>
          <span type="button" class="align-self-center" @click="closeQuestion()" aria-hidden="true"><i
              class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body modal-overflow">
          <div class="form-group row">
            <div class="col-lg-12 my-2">
              <label class="col-form-label">Question <sup><i class="fa fa-asterisk imp"></i></sup>
              </label>
              <input type="text" class="form-control" maxlength="250" v-model="UserDto.question" placeholder="Question" />
              <div class="errorClass" v-if="errors[0] == false">Please add a question</div>
              <div class="hr-line-dashed mb-0"></div>
            </div>

            <div class="col-lg-12" >
              <div class="optgroup mt-3">
                <input type="text" class="form-control opttext" maxlength="500" v-model="UserDto.option" placeholder="options" />
                <span class="errorClass" v-if="errors[1] == false">
                  Please add an option
                </span>
                <label class="ml-2 mt-2">
                  <input type="checkbox" v-model="checkoption" value="UserDto.option" @change="setOptionValue" />
                  Set this as an answer<sup><i class="fa fa-asterisk required"></i></sup>
                  <div class="errorClass" v-if="errors[2] == false">
                    Please select answer
                  </div>
                </label>
              </div>
              <button type="button" class="btn btn-default py-0 mt-2" @click.prevent="saveOption">
                + Add
              </button>
              <div class="hr-line-dashed mb-0"></div>
            </div>

            <div class="col-lg-12 mt-3">
              <ol class="pl-3">
                <li class="mb-2" v-for="(optionlist, index) in option" :key="index">
                  <span v-if="option.includes(UserDto.answer)" class="opt-ans"
                    :class="{ 'text-green': optionlist == UserDto.answer }">
                    {{ optionlist }}</span>
                  <span v-else class="opt-ans"> {{ optionlist }}</span>
                  <span data-role="remove" @click="deleteFromList(optionlist, index)"><i
                      class="fa fa-times ml-1"></i></span>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-sm-12 text-center mt-5">
            <button class="btn btn-white btn-sm px-3 py-1 mr-2" @click.prevent="Clear">
              Reset
            </button>
            <button v-if="showSave" class="btn btn-primary btn-sm px-3 py-1" @click.prevent="SaveButtonClicked">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End of question popup -->

  <div class="config-popup" v-if="showpopupDoc == true">
    <div class="modal-dialog width-100">
      <div class="modal-content">
        <div class="config-popup-header d-flex justify-content-between p-3">
          <h5 class="my-0 align-self-center">Add Related/Reference Documents</h5>
          <span type="button" class="align-self-center" @click="onClosedDoc()" aria-hidden="true"><i
              class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="trainer">Document Type <sup><i
                    class="fa fa-asterisk required"></i></sup></label>
              <Multiselect name="designation" valueProp="id" mode="multiple" :object="true"
                placeholder="Select Document Types" :options="doctypelist" :hideSelected="false" :closeOnSelect="false"
                class="multiselect-custom-theme" @change="DocumentList($event)" v-model="documentType">
                <template v-slot:option="{ option }">
                  {{ option.documentType }}
                </template>
              </Multiselect>
              <div class="errorClass" v-if="errors[3]==false">
                Please select a document type
              </div>
            </div>
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="trainer">Document<sup><i class="fa fa-asterisk required"></i></sup></label>
              <Multiselect name="Documents" valueProp="documentId" mode="multiple" placeholder="Select Documents"
                :options="documentList" :hideSelected="false" :closeOnSelect="false" class="multiselect-custom-theme"
                v-model="docDetails" @change="DocChangeList($event)">
                <template v-slot:option="{ option }">
                  {{ option.title }}
                </template>
              </Multiselect>
              <div class="errorClass" v-if="errors[4]==false">
                Please select a document
              </div>
            </div>
          </div>
          <div class="col-sm-12 text-center mt-5">
            <button class="btn btn-white btn-sm px-3 py-1 mr-2" @click.prevent="Clear">
              Reset
            </button>
            <button class="btn btn-primary btn-sm px-3 py-1" @click.prevent="insertDocuments()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Add user popup -->
  <div class="config-popup" v-if="showAddUserPopup == true">
    <div class="modal-dialog width-100">
      <div class="modal-content">
        <div class="config-popup-header d-flex justify-content-between p-3">
          <h5 class="my-0 align-self-center">Add User</h5>
          <span type="button" class="align-self-center" @click="closeUserpopup()" aria-hidden="true"><i
              class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="trainer">Designation</label>
              <Multiselect name="designation" valueProp="id" mode="multiple" :object="true" :options="designationList"
                :hideSelected="false" :closeOnSelect="false" @change="changeDesignation($event)"
                v-model="addUser.designations" class="multiselect-custom-theme" placeholder="Select Designations">
                <template v-slot:option="{ option }">
                  {{ option.displayName }}
                </template>
              </Multiselect>
              <div class="errorClass" v-if="addUserError[0] == true">Please select a designation</div>
            </div>
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="trainer">Users<sup><i class="fa fa-asterisk required">
                  </i></sup></label>
              <Multiselect name="users" valueProp="userId" mode="multiple" placeholder="Select Users"
                :options="exceptionUsers" :hideSelected="false" :closeOnSelect="false" class="multiselect-custom-theme"
                v-model="addUser.users">
                <template v-slot:option="{ option }">
                  {{ option.name }}
                </template>
              </Multiselect>
              <div class="errorClass" v-if="addUserError[1] == true">Please select a user</div>
            </div>
          </div>
          <div class="col-sm-12 text-center mt-5">
            <button class="btn btn-white btn-sm px-3 py-1 mr-2" @click.prevent="Clear">
              Reset
            </button>
            <button class="btn btn-primary btn-sm px-3 py-1" @click.prevent="insertUser()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Of add user popup -->
  <div class="col-lg-12 px-0">
    <div class="tabs-container" v-if="isConfigued == true">
      <ul class="nav nav-tabs" role="tablist">
        <li>
          <a class="nav-link active" data-toggle="tab" href="#tab-1" @click="SwitchTab('Employee', true, false)">
            Users</a>
        </li>
        <li>
          <a class="nav-link" data-toggle="tab" href="#tab-2"> Questions</a>
        </li>
        <li>
          <a class="nav-link" data-toggle="tab" href="#tab-3">Reference Documents</a>
        </li>
      </ul>
      <div class="tab-content">
        <div role="tabpanel" id="tab-1" class="tab-pane active">
          <div class="panel-body">
            <div class=" text-right mb-2" aria-label="Add User">
              <span class="position-relative users-table-block ">
                <img @click.prevent="openAddUserPopup()" class="cursor-pointer" src="../../assets/img/create.png"
                  alt="Add User" />
                <div class="tool-tip">Add User</div>
              </span>
            </div>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="sort_block" id="1">
                    <div class="d-flex flex-row justify-content-between">
                      Employee Name
                    </div>
                  </th>
                  <th class="sort_block" id="2">
                    <div class="d-flex flex-row justify-content-between">Test Name</div>
                  </th>
                  <th>
                    <div class="d-flex flex-row justify-content-between"> Supervisor Name </div>
                  </th>
                  <th>
                    <div class="d-flex flex-row justify-content-between">Exam Date</div>
                  </th>
                  <th class="sort_block" id="3">
                    <div class="d-flex flex-row justify-content-between">Status</div>
                  </th>
                  <th class="text-center">View</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(usrlst, index) in userExamList" :key="usrlst.Id">
                  <td> {{ usrlst.employeeName }} </td>
                  <td> {{ usrlst.testName }} </td>
                  <td> {{ usrlst.supervisorName }} </td>
                  <td> {{ filter(usrlst.dateConductedOn) }}</td>
                  <td> {{ usrlst.status }}</td>
                  <td class="text-center">
                    <i v-if="usrlst.status != 'Initiated'" class="fa fa-eye edit-delete"
                      @click="ViewTest(usrlst.configId, usrlst.examId)">
                    </i>
                  </td>
                  <td class="text-center" v-on:clickout="openUserSidePopup[index] = false">
                    <div class="cursor-pointer" @click="openUserSidePopup[index] = !openUserSidePopup[index]"><i
                        class="fa fa-ellipsis-v"></i></div>
                    <div class="side-popup" v-if="openUserSidePopup[index]">
                      <div v-if="(usrlst.status == 'Signed')"> Nothing to show</div>
                      <div v-if="!(usrlst.status == 'Signed')" @click.prevent="deleteUser(usrlst.employeeId, usrlst.examId)"> Delete</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>

        <div role="tabpanel" id="tab-2" class="tab-pane">
          <div class="panel-body table-responsive">
            <div class="text-right mb-2" aria-label="Create test">
              <span class="position-relative questions-table-block mr-3">
                <img @click.prevent="openQuestionPopup(0)" class="cursor-pointer" src="../../assets/img/create.png"
                  alt="Create Question" />
                <div class="tool-tip">Create Question</div>
              </span>
              <span class="position-relative questions-table-block cursor-pointer">
                <input class="d-none" accept=".xls,.xlsx" id="InPutFile" type="file" @change="previewFiles($event)" />
                <img src="../../assets/img/upload_files.png" alt="export_icon" @click.prevent="onFileChange()" />
                <div class="tool-tip">Upload Question</div>
              </span>
            </div>

            <table class="table table-bordered question-table">
              <thead>
                <tr>
                  <th class="text-center">#</th>
                  <th>Questions</th>
                  <th>Options</th>
                  <th>Answer</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(question, count) in questionlist" :key="count">
                  <td class="text-center">{{ count + 1 }}</td>
                  <td>{{ question.question }}</td>
                  <td>
                    <ul class="pl-3 mb-0">
                      <li v-for="(optn, index) in question.option" :key="index" class="mb-2">
                        <span class="mb-2 opt-ans">{{ optn }}</span>
                      </li>
                    </ul>
                  </td>
                  <td>{{ question.answer }}</td>
                  <td class="text-center" v-on:clickout="openSidePopup[count] = false">
                    <div class="cursor-pointer" @click="openSidePopup[count] = !openSidePopup[count]"><i
                        class="fa fa-ellipsis-v"></i></div>
                    <div class="side-popup" v-if="openSidePopup[count]">
                      <div @click.prevent="openQuestionPopup(question.id)">Edit</div>
                      <div @click.prevent="deleteQuestion(question.id)"> Delete</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-md-12 d-flex justify-content-md-end px-0">
              <v-pagination v-model="currentPage" :pages="totalPage" :range-size="1" active-color="#ededed"
                @update:modelValue="loadQuestionPage" />
            </div>
          </div>
        </div>

        <div role="tabpanel" id="tab-3" class="tab-pane">
          <div class="panel-body">
            <div class=" text-right mb-2" aria-label="Add User">
              <span class="position-relative users-table-block ">
                <img @click.prevent="AddQuestion()" class="cursor-pointer" src="../../assets/img/create.png"
                  alt="Add User" />
                <div class="tool-tip">Add Document</div>
              </span>
            </div>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Document Name</th>
                  <th>Version</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(config, index) in configDocumentList" :key="config.Id">
                  <td>{{ config.title }}</td>
                  <td>{{ config.version }}</td>
                  <td class="text-center" v-on:clickout="openUserSidePopup[index] = false">
                    <div class="cursor-pointer" @click="openUserSidePopup[index] = !openUserSidePopup[index]"><i
                        class="fa fa-ellipsis-v"></i></div>
                    <div class="side-popup" v-if="openUserSidePopup[index]">
                      <div @click.prevent="deleteDocument(config.documentId)"> Delete</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./configure_exam.js"></script>
<style scoped>
@import "../../assets/css/savepages.css";

.fa-asterisk {
  color: #e56363 !important;
  font-size: 6px;
}

.config-popup {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.config-popup-header {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.errorClass {
  color: red;
  font-size: 15px;
}

.submit_btn {
  align-self: center;
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}

.dangeralign {
  margin: 0;
  padding: 0;
}

.width-100 {
  width: min(500px, 90%);
}

.position-relative img {
  width: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.questions-table-block .tool-tip,
.users-table-block .tool-tip {
  visibility: hidden;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 26px;
  top: -16px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 2;
  transition: visibility 75ms ease-in;
}

.questions-table-block:hover .tool-tip,
.questions-table-block:focus-visible .tool-tip,
.users-table-block:hover .tool-tip,
.users-table-block:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.side-popup {
  position: absolute;
  text-align: left;
  right: 56px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(109px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, .5);
  z-index: 200;
}

.side-popup>div {
  cursor: pointer;
  padding: 0.5rem;
}

.side-popup>div:hover {
  background-color: #e4eaf9
}

.question-table tr td:nth-child(2),
.question-table tr td:nth-child(3),
.question-table tr td:nth-child(4) {
  word-wrap: break-word;
  max-width: 60ch;
  white-space: break-spaces;
}

.unvisible {
  display: none;
}

.text-green {
  color: #1ab394 !important;
}
</style>