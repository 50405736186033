/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
export default {
  props: {
    id: { type: String, default: "" },
  },
  components: {
    Multiselect
  },
  computed: {
    dateToday() {
        // Calculate the minimum allowed date (today's date) with time zone information
        const todayset = new Date();
        const today = new Date(todayset); 
        today.setDate(todayset.getDate() + 1);// Create a new Date object based on tomorrow's date
        const year = today.getUTCFullYear();
        const month = String(today.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(today.getUTCDate()).padStart(2, '0');
        const hours = String(today.getUTCHours()).padStart(2, '0');
        const minutes = String(today.getUTCMinutes()).padStart(2, '0');
        const seconds = String(today.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(today.getUTCMilliseconds()).padStart(3, '0');
        
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
      },        
  },
  data() {
    return {
      isuserselect:true,
      showpopup: false,
      showpopupDoc: false,
      prevRoute: null,
      setpreviouspath: "",
      versionfrom: "",
      forEdit: false,
      errorMessage: [false, false, false, false, false, false,false,false,false],
      baseUrl: `${process.env.VUE_APP_Service_URL}account-core/user/`,
      desurl: `${process.env.VUE_APP_Service_URL}document/folderfilepermission/`,
      baseapi: `${process.env.VUE_APP_Service_URL}document/documentquestion/`,
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/`,
      docurl: `${process.env.VUE_APP_Service_URL}document/document/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      config: {
        examName: "",
        documentId: "",
        questionNo: "",
        percentage: "",
        email: [],
        timeinHours: "",
        timeinMins: "",
        endDate: "",
        users: [],
      },
      updateConfigs: {
        documentId: "",
        questionNo: "",
        percentage: "",
        email: [],
        timeinHours: "",
        timeinMins: "",
        endDate: "",
        supervisor:[]
      },
      previousPath: "",
      dropdownheading: "",
      roleList: [],
      designationList: [],
      designation: [],
      userList: [],
      allUserList: [],
      assignuser: {
        documentId: "",
        userEmails: []
      },
      assignEmail: [],
      validationmsgs: false,
      designationPrivileges: "",
      sourceRoute: "",
      IsUpdate: false,
      retrieve: false,
      showSave: false,
      checkoption: false,
      showadd: false,
      showAnswer: false,
      duplicate: false,
      errors: [true,true, true,true,true],
      Head: {},
      baseUrlQuestion: `${process.env.VUE_APP_Service_URL}document/documentquestion/`,
      CreateUpdateCommnand: {
        configId: "",
        question: "",
        option: [],
        answer: "",
      },
      UserDto: {
        id: "",
        question: "",
        answer: "",
        option: [],
      },
      option: [],
      searchField: {
        name: "",
        createdat: ""
      },
      questionlist: [],
      currentPage: 1,
      pageindex: 1,
      pagesize: 10,
      totalPage: 1,
      SortProperty: "question",
      SortOrder: false,
      SortOrderInt: 0,
      designationPrivileges: "",
      sourcePage: "",
      sourceRoute: "",
      configId: "",
      doctypelist: "",
      documentList: "",
      documentType: [],
      configDocumentList: "",
      selectedDesignation: [],
      typeValue: [],
      docDetails: [],
      isConfigued :false,
      showAddDoc : false,
      openSidePopup:[false],
      openUserSidePopup:[false],
      supervisorList : [],
      finalUserList : [],
      userExamList :[],
      editQuestion : false,
      questionId:"",
      showAddUserPopup:false,   
      exceptionUsers :[],
      exceptionDocList :[],
      addUser:{
        designations: [],
        users: []
      },  
      addUserError :[false,false],
      xlsxFile: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  async mounted() {
   
    this.configId =this.id;
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    if(this.configId != 0){
      this.isConfigued = true;
      await this.GetAndSetData(this.configId);
      await this.ListUserExam('Employee');
      await this.configDocList();
      await this.questionslist();
    }
    await this.getSupervisors();
    await this.getalldesignation();
   
  
    this.showadd = true;
    if (this.configId != 0) {
      this.retrieve = true;
    }
    store.dispatch("setdocumenttodepartment", true);
    this.previousPath = store.getters.getDocViewSource
    this.designationPrivileges = store.getters.getRolesprivilegeData;
    if (this.prevRoute != null) {
      if (this.prevRoute.path != "/") {
        localStorage.setItem("patvalue", this.prevRoute.path);
      }
      this.setpreviouspath = localStorage.getItem("patvalue");
    }
  },

  methods: {
    toDatePicker(isoDate){
      if (isoDate !== null) {
          const date = new Date(isoDate);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
      } else {
          return null;
      }
    },
    async getalldesignation() {
      await axios
        .get(`${this.baseurl}designation/search?status=active&PageSize=10000&SortProperty=displayName`,
          this.Head)
        .then((res) => {
          this.designationList = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
        });

      if (this.previousPath == "ProjectSummary") {
        let projId = store.getters.getSelectedProject;
        await axios
          .get(
            `${this.baseurl}projectrole/getall?projectId=${projId}`,
            this.Head
          )
          .then((res) => {
            this.roleList = res.data;
          })
          .catch((err) => {
            console.log(err);
            this.roleList = []
          })
      }
    },
    async getDesignationUsers(designation) {
      const idtoken = store.getters.getToken
      await axios
        .post(`${this.desurl}designationusers?supervisor=${this.config.supervisor.userId}`, designation, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.formatUser(res.data,'users');
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getSupervisors() {
      const idtoken = store.getters.getToken
      await axios
        .post(`${this.baseapi}getexceptionusers?configId=${this.configId}&IsSupervisorList=true`, [], {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("getExceptionusers", res.data);
          this.allUserList = res.data;
          this.formatUser(this.allUserList,'supervisor')
        })
        .catch((err) => {
          console.log("Error in get supervisor users",err);
        });
    },
    async getExceptionusers(designation){
      const idtoken = store.getters.getToken
      await axios
        .post(`${this.baseapi}getexceptionusers?configId=${this.configId}&IsSupervisorList=false`, designation, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("getExceptionusers", res.data);
          this.exceptionUsers = res.data;
        })
        .catch((err) => {
          console.log("Error in get exception users",err);
        });
    },
    async insertUser(){
      await this.validateInsertUser();
      if(!this.addUserError.includes(true)){
        const idtoken = store.getters.getToken;
        await axios.put(
          //`https://localhost:5001/documentquestion/insertuser?configId=${this.configId}`,this.addUser.users,
          `${this.baseapi}insertuser?configId=${this.configId}`,this.addUser.users,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then(async ( res) => {
          console.log("insertUser", res.data);
          alert("User added succesfullly")
          await this.closeUserpopup();
          await this.ListUserExam();
          await this.getSupervisors();
        })
        .catch((err) => {
          console.log("Errors in insert user",err);
        });
      }
    },
    async deleteUser(userId,examId,index){
      if(confirm("Are you sure you want to delete this user?")){
        const idtoken = store.getters.getToken;
        await axios.put(`${this.baseapi}deleteconfiguser?configId=${this.configId}&userId=${userId}&examId=${examId}`,{},
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("delete user", res.data);
          this.openUserSidePopup = [false];
          this.ListUserExam();
          this.getSupervisors();
        })
        .catch((err) => {
          console.log("Errors in delete user",err);
        });
      }
    },
    async formatUser(users,type){
      let tempUser = {};
      let tempUserList = [];
      users.forEach((user) => {
        tempUser = {
          emailId: user.emailId,
          name: user.name,
          userId: user.userId
        }
        tempUserList.push(tempUser);
      });
      this.exceptionUsers= tempUserList;
      console.log("Supervisor final list", tempUserList)
      if(type == 'supervisor'){
        this.supervisorList = tempUserList;
      }
      else if(type == 'users'){
        this.finalUserList = tempUserList;
      }
    },
    async checksaveUpdate(){
      if(this.configId == 0){
        await this.createconfig();
      }
      else{
        await this.updateConfig();
      }
    },
    async validateInsertUser(){
      if(this.addUser.users.length == 0 ){
        this.addUserError[1] = true;
      }
      else{
        this.addUserError[1] = false;
      }
     
    },
    async validatequestion() {
      this.validationmsgs = false;
      if (this.config.endDate != null && this.configId == 0) {
        let today = new Date();
        let selected = new Date(this.config.endDate);
        selected.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        if (selected < today) {
          this.validationmsgs = true;
        }
      }
    },
    async validatefield() {
      this.errorMessage = [false, false, false, false, false, false, false, false, false];
      //test name
      if(this.config.examName.length < 3 ){
        this.errorMessage[0] = true;
      }
      // no of question
      if(this.config.questionNo == "" || this.config.questionNo == null){
        this.errorMessage[1] = true;
      }
      else{
        if (this.config.questionNo % 10 != 0 || this.config.questionNo.charAt(0) == '0' ||
          (this.config.questionNo.startsWith('0'))) {
          this.errorMessage[2] = true;
        }
      }
     //percentage
      if (this.config.percentage == "" || this.config.percentage == null) {
        this.errorMessage[3] = true;
      }
      else{
        if (this.config.percentage % 10 != 0 || this.config.percentage.charAt(0) == '0') {
          this.errorMessage[4] = true;
        }
        else if(this.config.percentage > 100){
          this.errorMessage[5] = true;
        }
      }
      if(this.config.endDate == "" || this.config.endDate == null) {
        this.errorMessage[7] = true;
      }
      if(this.config.supervisor == "" || this.config.supervisor == null) {
        this.errorMessage[8] = true;
      }
      console.log("config is.........", this.config.examName.length, this.errorMessage[0]) 
    },
    async createconfig() {
      await this.validatequestion();
      await this.validatefield();
      let timehrs = 0;
      let timemins = 0;
      if (this.config.timeinHours != "" && this.config.timeinHours != null) {
        timehrs = parseInt(this.config.timeinHours)
      }
      else { this.config.timeinHours = null }
      if (this.config.timeinMins != "" && this.config.timeinMins != null) {
        timemins = parseInt(this.config.timeinMins)
      }
      else { this.config.timeinMins = null }

      if (!((this.config.timeinHours === null || this.config.timeinHours === undefined) && (this.config.timeinMins === null || this.config.timeinMins === undefined))) {
        if ((timehrs + timemins) == 0) {
          alert('Please enter valid time');
        }
        else if (timemins < 0 || timemins > 59) {
          alert('Please enter valid time');
        }
        else {
          if (this.validationmsgs == false) {
            if (!this.errorMessage.includes(true)) {
              await this.createConfig();
            }
          }
        }
      }

      else {
        if (this.validationmsgs == false) {
          if (!this.errorMessage.includes(true)) {
            await this.createConfig();
          }
        }
      }
    },
    async updateConfig() {
      await this.validatequestion();
      await this.validatefield();
      let timehrs = 0;
      let timemins = 0;
      if (this.config.timeinHours != "" && this.config.timeinHours != null) {
        timehrs = parseInt(this.config.timeinHours)
      }
      else { this.config.timeinHours = null }
      if (this.config.timeinMins != "" && this.config.timeinMins != null) {
        timemins = parseInt(this.config.timeinMins)
      }
      else { this.config.timeinMins = null }
      if (!((this.config.timeinHours === null || this.config.timeinHours === undefined) && (this.config.timeinMins === null || this.config.timeinMins === undefined))) {
        if ((timehrs + timemins) == 0) {
          alert('Please enter valid time');
        }
        else if (timemins < 0 || timemins > 59) {
          alert('Please enter valid time');
        }
        else {
          if (!this.errorMessage.includes(true)) {
            if (this.validationmsgs == false) {
              this.updateCONfig();
            }
          }
        }
      }
      else {
        if (!this.errorMessage.includes(true)) {
          if (this.validationmsgs == false) {
            this.updateCONfig();
          }
        }
      }

    },
    async updateCONfig() {
      if (this.validationmsgs == false) {
        await axios
          //.put("https://localhost:5001/documentquestion/onlinetest?Id="+ this.config.id,
          .put(this.baseapi + "onlinetest?Id=" + this.configId,
            {
              "questionNo": this.config.questionNo,
              "percentage": this.config.percentage,
              "users": this.config.users,
              "timeinHours": this.config.timeinHours,
              "timeinMins": this.config.timeinMins,
              "endDate": this.config.endDate,
              "examName": this.config.examName,
              "supervisor": this.config.supervisor,
              "documents": this.docDetails
            },
            this.Head,
          )
          .then((res) => {
            console.log(res);
            alert("Configuration updated successfully");
            this.configDocList();
            this.onClosedDoc();
            this.questionslist();
            this.ListUserExam();
            this.GetAndSetData(this.configId);
          })
          .catch((err) => {
            console.log(err);
          });
        store.dispatch("reloadfolder", true);
      }
    },
    async questionslist() {
      await axios
        .get(
          `${this.baseUrlQuestion}search?configId=${this.configId}&pageindex=${this.pageindex}&pagesize=${this.pagesize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrderInt}`,
          this.Head
        )
        .then((res) => {
          this.questionlist = res.data.entities;
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.questionlist = [];
        })
    },
    async createConfig() {
      if (this.validationmsgs == false) {
        await axios
          //.post(`https://localhost:5001/documentquestion/onlinetestconfig`,
          .post(this.baseapi + "onlinetestconfig",
            {
              "questionNo": this.config.questionNo,
              "percentage": this.config.percentage,
              "users": this.config.users,
              "timeinHours": this.config.timeinHours,
              "timeinMins": this.config.timeinMins,
              "endDate": this.config.endDate,
              "examName": this.config.examName,
              "supervisor": this.config.supervisor,
              "documents": null
            },
            this.Head,
          )
          .then((res) => {
            this.isConfigued = true;
            this.configId = res.data;
            console.log("Id is",this.configId);
            alert("Exam configured successfully");
            this.questionslist();
            this.configDocList();
            this.ListUserExam();
            this.GetAndSetData(this.configId);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async assignUser() {
      this.assignuser.documentId = this.configId;
      this.assignuser.userEmails = this.config.email;
      await axios.post(
        `${this.baseapi}assignusers`,
        this.assignuser, this.Head
      ).then((res) => {
        console.log("User assigned", res);
      }).catch((err => console.log("error in assigning user", err)))
    },
    async GetAndSetData() {
      console.log("in fn get and set data");
      await axios
        .get(this.baseapi + "getconfigbyid?ConfigId=" + this.configId, this.Head)
        .then(async (res) => {
          this.config = res.data;
        });
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 || charCode == 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    async onSuperviserChange(event) {
      this.designation=[];
      this.config.users=[];
      this.isuserselect=false;
      this.getDesignationUsers([]);
      
    },
    designationClear(){      
      this.designation=[]
      this.finalUserList=[]
      this.config.users=[]
      
    },
    async onChange(event) {    
    
        if(this.designation!=[])
        {
          
          //this.isuserselect=true;
          this.selectedDesignation = [];
          await event.forEach((data) => {
            this.selectedDesignation.push(data.id)
          });
          this.getDesignationUsers(this.selectedDesignation);
        }
     //  else  this.isuserselect=true
      
     
    },
    async changeDesignation(event){
      let selectedDesignations = [];
      await event.forEach((data) => {
        selectedDesignations.push(data.id)
      });
      this.getExceptionusers(selectedDesignations);
    },
    async addTime() {
      let totalTime = parseInt(this.updateConfigs.TimeInHours) + parseInt(this.updateConfigs.TimeInMins) / 60;
      if (totalTime === 0 || totalTime === 0.0) {
        alert("Please enter a valid time");
      }
    },
    async DocChangeList(event) {
      console.log("on doc value change fn.",event)
      this.docDetails = [];
      event.forEach((list) => {
        this.docDetails.push({
          "documentId": list.id,
          "title": list.name,
          "version":list[0].documentVersions[0].version,//need to update latest version
          "date": list[0].createdAt
        })
      })
    },
    showOption() {
      if (this.UserDto.question == "") {
        this.errors[0] = false;
      } else {
        this.retrieve = true;
        this.errors[0] = true;
        this.showadd = false;
      }
    },
    setOptionValue() {
      if (this.checkoption != true) {
        // alert("Option not selected");
        this.UserDto.answer = "";
      } else {
        if (this.checkoption == true) {
          if (this.UserDto.option == "") {
            alert("Option not selected");
            this.checkoption = false
          } else {
            const newexists = this.option.find(
              (optionlist) => optionlist === this.UserDto.option
            );
            if (!newexists) {
              this.UserDto.answer = this.UserDto.option;
              this.showSave = false;
            } else if (newexists == this.UserDto.option) {
              alert("Option already exist");
              this.checkoption = false;
            }
          }
        }
      }
    },
    deleteFromList(optionlist, index) {
      this.option.splice(index, 1);
      if (this.UserDto.answer == optionlist) {
        this.UserDto.answer = "";
      }
      if (this.option == "") {
        this.showSave = false;
      }
      this.checkoption = false;
    },
    Clear() {
      this.UserDto.question = "";
      this.UserDto.answer = "";
      this.addUser.designations=[]
      this.addUser.users=[]
      this.UserDto.option = [];
      this.option = [];
      this.docDetails=[]
      this.documentType=[]
      this.checkoption = false;
      this.errors[2] = true;
      this.showSave = false;
    },
    async clearForm(){
      this.config = {
        examName: "",
        documentId: "",
        questionNo: "",
        percentage: "",
        email: [],
        timeinHours: "",
        timeinMins: "",
        endDate: (this.configId == 0) ? "" : this.config.endDate,
        user: "",
      }
    },
    async GetAndSetDataquestion(Id) {
      await axios
        .get(this.baseUrlQuestion + "getquestion?Id=" + Id, this.Head)
        .then((res) => {
          this.UserDto = res.data;
          this.option = this.UserDto.option;
          this.UserDto.option = "";
          this.showSave = true;
          this.showadd = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveOption() {
      let flag = true;
      if (this.UserDto.option == "" || this.UserDto.option == null) {
        alert("Please add an option");
      } else {
        const exists = this.option.find(
          (optionlist) => optionlist === this.UserDto.option
        );
        if (!exists) {
          if (flag) {
            this.option.push(this.UserDto.option);
            this.UserDto.option = [];
            this.showSave = true;
            if (this.checkoption == true) {
              this.checkoption = false;
            }
            if (this.UserDto.answer != "") {
              this.errors[2] = true;
            }
          }
        } else {
          alert("Option already exist");
          this.checkoption = false;
        }
      }
    },
    async gettypelist() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}document/document/gettypelist`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.doctypelist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.doctypelist = [];
        });
    },
    closeQuestion() {
      this.UserDto= {
        id: "",
        question: "",
        answer: "",
        option: [],
      },
      this.option = [];
      this.showpopup = false;
      this.editQuestion = false;
      this.questionslist();
    },
    async openQuestionPopup(questionId){
      this.showpopup = true;
      if(questionId != 0){
        this.editQuestion = true;
        this.questionId = questionId;
        await this.GetAndSetDataquestion(questionId);
      }
    },
    //for-doc
    AddQuestion() {
      this.showpopupDoc = true
      this.gettypelist()
    },
    async configDocList() {
      await axios
        .get(`${this.docurl}getdocbyconfig?configid=${this.configId}`,
          this.Head)
        .then((res) => {
          this.configDocumentList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async DocumentList(event) {
      this.typeValue = [];
      event.forEach((type) => {
        this.typeValue.push(type.id)
      });
      await axios
        .post(`${this.baseapi}getexceptiondocs?configId=${this.configId}`, this.typeValue,
          this.Head)
        .then((res) => {
          this.documentList = res.data;
          console.log("itssssssss", this.documentList)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async insertDocuments(){
      const idtoken = store.getters.getToken;
      if(this.documentType.length==0 || this.docDetails.length==0)
      {
        if(this.documentType.length==0){
          this.errors[3]=false
        }
        if(this.docDetails.length==0)
        {
          this.errors[4]=false
        }
      }
      else{
        console.log("Else entered")
        console.log("Type",this.documentType)
        console.log("Doc details",this.docDetails)
        this.errors[3]=true
        this.errors[4]=true
        await axios.put(`${this.baseapi}insertdocs?configId=${this.configId}`,this.docDetails,this.Head)
        .then(async (res) => {
          alert("Document added successfully");
          await this.onClosedDoc();
          this.configDocList();
        })
        .catch(err=> console.log("error in insert document", err));
      }
      
    },
    async deleteDocument(docId){
      if(confirm("Are you sure you want to delete this document?")){
        const idtoken = store.getters.getToken;
        await axios.put(`${this.baseapi}deleteconfigdoc?configId=${this.configId}&docId=${docId}`,{},this.Head)
        .then(async (res) => {
           this.configDocList();
           alert("Document deleted successfully");
           this.openUserSidePopup = [false];
        })
        .cathc(err=> console.log("error in insert document", err));
      }
     
    },
    async ViewTest(configId,usersExamId){
      this.$router.push("/myQuestion/" + configId + "/" + usersExamId);
    },
    onClosedDoc() {
      this.showpopupDoc = false;
      this.errors[3]=true
      this.errors[4]=true
      this.documentType = [];
      this.docDetails = [];
    },
    onFileChange() {
      document.getElementById("InPutFile").click();
    },
    async submitFile() {
      const idtoken = store.getters.getToken
      var formData = new FormData();
      formData.append("item", this.xlsxFile, this.xlsxFile.name);
      console.log(formData);
      if (confirm("Are you sure you want to upload the questions?")) {
        //TODO Change here
        await axios
          .post(
            this.baseUrlQuestion + "upload?ConfigId=" + this.configId,
            formData,
            {
              responseType: "blob",
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "multipart/form-data",
              },
            },
            {
              onUploadProgress: (uploadEvent) => {
                console.log("Helllo" + uploadEvent.loaded);
              },
            }
          )
          .then((response) => {
            alert("Questions uploaded successfully");
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "ImportResult";
            link.click();
            URL.revokeObjectURL(link.href);
            this.questionslist();
          })
          .catch((err, response) => {
            console.log(err);
            if (err.message == "Request failed with status code 500") {
              alert("Please upload the excel file with the specified format");
            }
            if (err == "Error: Request failed with status code 400") {
              alert("Please upload xls or xlsx file");
            }
            else if (err == "Error: Request failed with status code 504") {
              alert("Incorrect format");
            }
            else if(err="Bad File"){
              alert("Unsupported file format")
            }
            this.questionslist();
          });
      }
    },
    async previewFiles(event) {
      this.xlsxFile = event.target.files[0];
      event.target.value = null;  //This will clear the selected file from the file input field so that a new file can be selected.
      this.submitFile();
    },
    async SaveButtonClicked() {
      this.SetCommandAsync();
      this.validateQuestions();
      console.log("errors is ............", this.errors)
      if(!this.errors.includes(false)){
        if(this.editQuestion == true){
          await this.UpdateAsync();
        }
        else{
            await this.CreateAsync();
        }
      }
      
    },
    async UpdateAsync() {
      await axios
        .put(
          this.baseUrlQuestion + "update?Id=" + this.questionId,
          this.CreateUpdateCommnand,
          this.Head
        )
        .then((res) => {
          alert("Question updated successfully");
          this.closeQuestion();
        })
        .catch((err) => {
          alert(err.response.data.errors.DomainValidations[0]);
        });
    },
    async CreateAsync() {
      await axios
        .post(this.baseUrlQuestion + "create", this.CreateUpdateCommnand, this.Head)
        .then((res) => {
          alert("Question created successfully");
          this.closeQuestion();
        })
        .catch((err) => {
          alert(err.response.data.errors.DomainValidations[0]);
        });
    },
    async deleteQuestion(questionId){
      if(confirm("Are you sure you want to delete this question?")){
        await axios
          .delete(`${this.baseUrlQuestion}delete?Id=${questionId}`, this.Head)
          .then((res) => {
            alert("Question deleted successfully");
            this.questionslist();
            this.openSidePopup = [false];
          })
          .catch((err) => {
            alert("Something went wrong");
          });
      }
    },
    async validateQuestions(){
      if(this.UserDto.question == ""){
        this.errors[0] = false;
      }
      else {
        this.errors[0] = true;
      }
      if(this.option == []){
        this.errors[1] = false;
      }
      else{
        this.errors[1] = true;
      }
      if (this.UserDto.answer == "") {
        this.errors[2] = false;
      }
      else{
        this.errors[2] = true;
      }
    },
    SetCommandAsync() {
      this.CreateUpdateCommnand.configId = this.configId;
      this.CreateUpdateCommnand.question = this.UserDto.question
        .replace(/\s+/g, " ")
        .trim();
      this.CreateUpdateCommnand.answer = this.UserDto.answer
        .replace(/\s+/g, " ")
        .trim();
      this.option = this.option.map((str) =>
        str
          .split(" ")
          .filter((word) => word !== "")
          .join(" ")
      );
      this.CreateUpdateCommnand.option = this.option;
    },
    async closebutton(){
      this.$router.push("/testList")
    },
    SortSelected(sortProp, id) {
      console.log('SortPropChanged', sortProp, id);
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == true ? false : true;
      this.SortOrderInt = this.SortOrder == true ? 0 : 1;
      this.questionslist();
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.pageindex = page;
      this.questionslist();
    },
    async ListUserExam(role) {
      console.log("page number is ", this.pageindex);
      this.userEmail = store.getters.getUserEmail;
      const idtoken = store.getters.getToken;
      await axios
        .get(
          //`https://localhost:5001/documentquestion/gettestresult?configId=${this.configId}&pageindex=${this.pageindex}&pagesize=${this.pagesize}`,
          `${this.baseapi}gettestresult?configId=${this.configId}&pageindex=${this.pageindex}&pagesize=${this.pagesize}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.userExamList = res.data.entities;
          console.log("USER data ISSS",this.userExamList)
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.userExamList = [];
        });
    },
    async SwitchTab(role,fromtab,issearch){
      this.userRole = role;    
      if(fromtab){
        this.pageindex=1;
        this.currentPage =1;
      }
      if(issearch){

      }
      else{
        this.searchField.employeename="";
        this.searchField.fromdate="";
        this.searchField.todate="";
        this.searchField.testresult="";
      }             
    await this.ListUserExam(role)
    },
    filter(data) {
      if (data) {
        return moment(data).format("DD-MM-YYYY");
      }
    },
    async openAddUserPopup(){
      await this.getDesignationUsers([]);
      this.showAddUserPopup = true;

    },
    async closeUserpopup(){
      this.showAddUserPopup = false;
      this.addUserError[0]=false
      this.addUserError[1]=false
      this.addUser={
        designations: [],
        users: []
      } 
    },
    loadQuestionPage: function (page) {
      this.currentPage = page;
      this.pageindex = page;
      this.questionslist();
    },
    async userchange(){
      console.log("hi......");
    }
  }
};