<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0">Config Bookmark(Sign)</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i class="fa fa-times close cursor-pointer" @click="goBack"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group row mb-3">
          <div class="col-12 col-md-5 my-1 pr-md-1">
            <div class="form-group mb-md-0 pr-1">
              <label class="mb-0">Document Type</label>
              <input
                type="text"
                class="form-control my-md-0"
                v-model="search.documenttype"
              />
            </div>
          </div>

          <div class="col-12 col-md-2 align-self-end my-1">
            <span
              type="button"
              class="submit_btn px-5 py-2 w-100"
              @click="OnSearchButtonClicked"
              >Search</span
            >
          </div>
        </div>

        <div class="ibox-content">
          <div class="row">
            <div class="col-md-12">
              <div class="ibox-title style_2">
                <div
                  class="ibox-tools mt-1"
                  v-if="
                    designationPrivileges.includes(
                      `Config Bookmark (Sign) Create Document Type`
                    )
                  "
                >
                  <a href="#" class="mr-3 position-relative">
                    <img
                      src="../../assets/img/add.png"
                      alt="Create Document Type"
                      @click="createDoctypepopup('createDoctype')"
                    />
                    <div class="tool-tip">Create Document Type</div>
                  </a>
                </div>
              </div>
            </div>
            <div class="table-responsive col-md-12">
              <table class="table table-striped table-bordered dataTables">
                <thead>
                  <tr>
                    <th class="sort_block">Document Type</th>
                    <th>Bookmark</th>
                    <th
                      class="text-center"
                      v-if="
                        designationPrivileges.includes(
                          `Config Bookmark (Sign) Edit Document Type`
                        )
                      "
                    >
                      Edit Document Type
                    </th>
                    <th
                      class="text-center"
                      v-if="
                        designationPrivileges.includes(
                          `Config Bookmark (Sign) Edit Bookmark`
                        )
                      "
                    >
                      Edit Bookmark
                    </th>
                    <th
                      class="text-center"
                      v-if="
                        designationPrivileges.includes(
                          `Config Bookmark (Sign) Delete Document Type`
                        )
                      "
                    >
                      Delete
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="doctype in doctypelist" :key="doctype.id">
                    <td>
                      <span
                        v-if="doctypval == 'doctype' && docTypeId == doctype.id"
                      >
                        <input
                          type="text"
                          class="form-control"
                          maxlength="20"
                          v-model="doctype.documentType"
                        />
                        <span class="errorClass" v-if="errors[1] == false">
                          {{ errorMessage[1] }}</span
                        >
                      </span>
                      <span v-else> {{ doctype.documentType }}</span>
                    </td>
                    <td>
                      {{ doctype.bookmarks }}
                    </td>
                    <td
                      class="text-center"
                      v-if="
                        designationPrivileges.includes(
                          `Config Bookmark (Sign) Edit Document Type`
                        )
                      "
                    >
                      <div
                        class="mt-1"
                        v-if="doctypval == 'doctype' && docTypeId == doctype.id"
                      >
                        <img
                          class="cursor-pointer mr-2"
                          alt="save the information"
                          src="../../assets/img/table_cell_save.png"
                          @click="
                            updatebutton(doctype.id, doctype.documentType)
                          "
                        />
                        <img
                          class="cursor-pointer"
                          src="../../assets/img/table_cell_close.png"
                          alt="close the edit box"
                          @click="inlineClose()"
                        />
                      </div>
                      <div v-else>
                        <img
                          class="cursor-pointer"
                          src="../../assets/img/Edit.png"
                          alt="Edit"
                          @click.prevent="EditDocType(doctype.id, 'doctype')"
                        />
                      </div>
                    </td>
                    <td
                      class="text-center"
                      v-if="
                        designationPrivileges.includes(
                          `Config Bookmark (Sign) Edit Bookmark`
                        )
                      "
                    >
                      <img
                        class="cursor-pointer"
                        src="../../assets/img/Edit.png"
                        alt="Edit"
                        @click.prevent="openModelCreatedoctype(doctype.id)"
                      />
                      <!-- @click.prevent="GoToEditProject(project.id)" /> -->
                    </td>
                    <td
                      class="text-center"
                      v-if="
                        designationPrivileges.includes(
                          `Config Bookmark (Sign) Delete Document Type`
                        )
                      "
                    >
                      <img
                        class="cursor-pointer"
                        src="../../assets/img/delete.png"
                        alt="Delete"
                        @click.prevent="deleteDoctype(doctype.id)"
                      />
                      <!-- @click.prevent="GoToEditProject(project.id)" /> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12 d-flex justify-content-md-end px-0">
              <v-pagination
                v-model="currentPage"
                :pages="totalPage"
                :range-size="1"
                active-color="#ededed"
                @update:modelValue="loadPage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- CREATE PROJECT FOLDER POPUP -->
  <div class="create-doctype-modal" v-if="createdoctypepopup == true">
    <div class="modal-dialog doctypewidth">
      <div class="modal-content">
        <div
          class="modal-header-projectlist d-flex justify-content-bertween p-3"
        >
          <h5 class="my-0 align-self-center">Add Signature Bookmarks</h5>
          <div class="cursor-pointer" @click="closeModelCreateFolder()">
            <i class="fa fa-times"></i>
          </div>
        </div>
        <div class="modal-body tablemaxheight" id="tablemaxheight">
          <!-- <i
            class="fa fa-plus"
            @click.prevent="addbookmark()"
            aria-hidden="true"
          ></i> -->
          <div class="position-relative add-bookmark float-right py-2" v-if="designationPrivileges.includes(`Config Bookmark (Sign) Add Bookmark`)">
            <img
              class="cursor-pointer position-relative"
              src="../../assets/img/create.png"
              alt="Create Designation"
              @click="addbookmark()"
            />
            <div>
              <div class="tool-tip">Add Bookmark</div>
            </div>
          </div>
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th
                  class="sort_block"
                  @click="SortSelected('projectName', 1)"
                  id="1"
                >
                  <div class="d-flex flex-row justify-content-between">
                    Signing order
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('projectName', 1)"
                  id="1"
                >
                  <div class="d-flex flex-row justify-content-between">
                    Bookmark/Role
                  </div>
                </th>
                <th class="text-center" v-if="designationPrivileges.includes(`Config Bookmark (Sign) Edit Bookmark`)">Edit</th>
                <th class="text-center" v-if="designationPrivileges.includes(`Config Bookmark (Sign) Delete Bookmark`)">Delete</th>
                <!-- <th class="text-center" >Add</th> -->
              </tr>
            </thead>

            <tbody>
              <tr v-for="(bookmarks, index) in bookmarklist" :key="bookmarks">
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  <span v-if="retrievebox == bookmarks.id">
                    <input
                      type="text"
                      v-model="bookmarklist[index].bookmarkName"
                      class="form-control"
                    />
                  </span>
                  <span v-else>{{ bookmarklist[index].bookmarkName }}</span>
                </td>
                <td v-if="designationPrivileges.includes(`Config Bookmark (Sign) Edit Bookmark`)">
                  <div class="mt-1" v-if="retrievebox == bookmarks.id">
                    <img
                      class="cursor-pointer mr-2"
                      alt="save the information"
                      src="../../assets/img/table_cell_save.png"
                      @click="UpdateAsync(bookmarks, index)"
                    />
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/table_cell_close.png"
                      alt="close the edit box"
                      @click="closebutton()"
                    />
                  </div>
                  <!-- <div
                    v-if="
                      retrievebox != bookmarks.id"
                  >
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/Edit.png"
                      alt="Edit"
                      @click.prevent="openModelbookmark(bookmarks.id, index)"
                    />
                  </div> -->
                  <div v-else class="text-center">
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/Edit.png"
                      alt="Edit"
                      @click.prevent="openModelbookmark(bookmarks.id, index)"
                    />
                  </div>
                  <div
                    class="mt-1"
                    v-if="savebtn == true && bookmarks.id == this.bookmarkid"
                  >
                    <img
                      class="cursor-pointer mr-2"
                      alt="save the information"
                      src="../../assets/img/table_cell_save.png"
                    />
                  </div>
                </td>

                <td class="text-center" v-if="designationPrivileges.includes(`Config Bookmark (Sign) Delete Bookmark`)">
                  <img
                    class="cursor-pointer"
                    src="../../assets/img/delete.png"
                    alt="Delete"
                    @click.prevent="
                      deletebookmark(index, bookmarklist[index].id)
                    "
                  />
                </td>
                <!-- <td class="text-center">
              <i class="fa fa-plus" @click.prevent="addbookmark()" aria-hidden="true"></i>
             </td> -->
              </tr>
              <tr v-if="showInsertSpan">
                <td>{{ bookmarklist.length + 1 }}</td>
                <td>
                  <span>
                    <input
                      type="text"
                      placeholder="Enter bookmark name"
                      v-model="newBookmark"
                      class="form-control"
                    />
                  </span>
                </td>
                <td>
                  <img
                    class="cursor-pointer mr-2"
                    alt="save the information"
                    src="../../assets/img/table_cell_save.png"
                    @click="
                      insertBookmark(bookmarklist.length + 1, newBookmark)
                    "
                  />
                  <img
                    class="cursor-pointer"
                    src="../../assets/img/table_cell_close.png"
                    alt="close the edit box"
                    @click="clearBookmark()"
                  />
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <!-- <div class="form-group pt-2">
            <button
              type="button"
              class="btn btn-primary btn-xs px-4 float-right"
            >
              Save
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Popup library -->

  <div class="create-folder-modal" v-if="showDoctype == true">
    <div class="modal-dialog modal-width">
      <div class="modal-content">
        <div
          class="modal-header-designation d-flex justify-content-bertween p-3"
        >
          <h5 class="my-0 align-self-center">Create Document Type</h5>
          <span
            type="button"
            class="align-self-center"
            data-dismiss="modal"
            @click="onclosedpopup('createdoctype')"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="form-group mb-0">
            <label class="mb-0" for="designation-name"
              >Document Type Name</label
            >
            <span aria-label="mandatory field" class="error-block">*</span>
            <input
              type="text"
              id="designation-name"
              class="form-control"
              v-model="doctype.documentType"
              maxlength="75"
            />
          </div>
          <span class="errorClass" v-if="errors[0] == false"
            >{{ errorMessage[0] }}
          </span>

          <div class="form-group mb-0 py-2">
            <button
              type="button"
              @click="CreateDoctypeFunction()"
              class="btn btn-primary btn-xs px-4 float-right"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- popup ends -->
</template>
<script src="./documentTypeListing.js"></script>

<style scoped>
.submit_btn {
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;

  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.position-relative img {
  width: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.headingunderline {
  text-decoration-line: underline;
}

.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}
.title-class1 {
  background-color: #618bd7;
  color: #fff;
}
.errorClass {
  color: red;
  font-size: 13px;
}
.button-class {
  float: right;
}
.modal-header-designation {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-width {
  width: min(400px, 90%);
}
.error-block {
  color: #ff0000;
}

.tablemaxheight {
  max-height: 300px;
  overflow: auto;
}

.modal-header-projectlist {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-width {
  width: min(450px, 90%);
}
.doctypewidth {
  min-width: 700px;
}
.create-doctype-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.position-relative:is(:hover, :focus-visible) .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.add-bookmark .tool-tip {
  right: 25px;
  top: -18px;
}
</style>
