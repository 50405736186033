<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0">Projects</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-md-12">
        <div class="ibox-title style_2">
          <div class="ibox-tools mt-1" v-if="designationPrivileges.includes(`Projects Create`)">
            <a href="#" class="mr-3 position-relative"> <img src="../../assets/img/add.png" alt=""
                @click="createClicked('')">
              <div class="tool-tip">Create Project</div>
            </a>
          </div>
        </div>
        <!-- create project popup -->
        <div class="create-folder-modal" v-if="showPrj == true">
          <div class="modal-dialog modal-width">
            <div class="modal-content">
              <div class="modal-header-projectlist d-flex justify-content-bertween p-3">
                <h5 class="my-0 align-self-center">{{ popupHeading }}</h5>
                <span type="button" class="align-self-center" data-dismiss="modal" @click="onClosedPopup()"
                  aria-hidden="true"><i class="fa fa-times modal-close align-self-center"></i>
                </span>
              </div>
              <div class="modal-body modal-overflow">
                <div class="form-group mt-2 mb-0">
                  <label class="mb-0"> Project name</label> <span class="errorClass">*</span>
                  <input type="text" class="form-control" v-model="createProject.projectName" maxlength="50" />
                </div>
                <span class="errorClass" v-if="errors[0] == true"> {{ errormessage[0] }}</span>
                <div class="form-group mt-2 mb-0">
                  <label class="mb-0"> Project code</label>
                  <input type="text" class="form-control" v-model="createProject.projectCode" maxlength="20" />
                </div>
                <span class="errorClass" v-if="errors[1] == true"> {{ errormessage[1] }}</span>
                <div class="form-group mt-2 mb-0">
                  <label class="mb-0">Project type</label><span class="errorClass">*</span>
                  <select class="form-control" aria-placeholder="Project type" v-model="selectedProjectType">
                    <option v-for="projectType in projectTypes" :key="projectType.id" :value="projectType">
                      {{ projectType.projectTypeName }}</option>
                  </select>
                </div>
                <span class="errorClass" v-if="errors[2] == true">{{ errormessage[2] }}</span>
                <div class="from-group mt-2">
                  <label class="mb-0"> Description</label>
                  <textarea class="form-control mb-2" cols="30" rows="3" v-model="createProject.description"
                    maxlength="250"></textarea>
                </div>
                <span class="errorClass" v-if="errors[3] == true">{{ errormessage[3] }}</span>
                <div class="d-flex justify-content-between">
                  <div class="input-group align-self-center">
                    <label class="mb-0 mr-2">Active</label>
                    <input type="checkbox" value="true" v-model="createProject.active" />
                  </div>
                  <div class="d-flex">
                    <button type="button" class="btn btn-primary cancel_btn btn-xs px-4 mr-3" @click.prevent="clearPopUp">
                      Clear
                    </button>
                    <button type="button" class="btn btn-primary btn-xs px-4 " @click.prevent="CreateOrEdit">
                      Save
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end of create project -->
        <div class="ibox-content">
          <div class="row">
            <div v-for="project in projectlist" :key="project.Id" class="col-md-3 mb-1 mt-1">
              <div class="ibox-content  text-left" id="primaryTree"
                >
                <div class="col-sm-12 text-right"
                  @click.prevent="sidePopup(project.id, project.projectName, 'projectlist', e)">
                  <a class="position-relative"><i class="fa fa-ellipsis-v"></i></a>
                  <SidePopUp v-if="showBlock && sidePopupid == project.id" :dest="destination" :ev="event"
                    v-on:clickout="(showBlock = false)">
                    <div v-if="designationPrivileges.includes(`Projects Edit`)"
                      @click.prevent="createClicked(project.id)"> Edit</div>
                    <div v-if="designationPrivileges.includes(`Projects Archive`)"
                      @click.prevent="archiveproject(project.id)"> Archive</div>
                    <div v-if="designationPrivileges.includes(`Projects Delete`)"
                      @click.prevent="deleteProject(project.id)">Delete</div>
                      <!-- <div
                      @click.prevent="$router.push(`/documentList/${project.id}/${project.projectName}`)">Dev</div> -->
                  </SidePopUp>
                </div>
                <div @click="GoToProjectSummary(project.id, project.projectType, project.projectName)" class="cursor-pointer">
                <div class="m-b-sm">
                  <img alt="image" class="" src="../../assets/img/dms.png">
                </div>
                <h1 class="mb-0 text-proj">{{
                  project.projectName
                }}</h1>
                <p class="font-bold mb-0 text-proj"> {{
                  project.description
                }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./projectList.js"></script>
<style scoped>
.errorClass {
  color: red;
  font-size: 13px;
}

.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}

.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.title-class1 {
  background-color: #618bd7;
  color: #fff;
}

.text-proj {
  color: #676a6c !important;
  word-break: break-word;
}

.modal-header-projectlist {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-width {
  width: min(450px, 90%)
}

.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -36px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:is(:hover, :focus-visible) .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}</style>