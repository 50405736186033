/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect"
import { event } from "jquery";
export default {
    name: "externalfoldershare",
    components: {

    },
    props: {
        list: {
            type: String,
            default: "",
        },
        source:
        {
            type: String,
            default: "",
        },
        projectId: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            ispermissionarray:{},
            permissionset: [],
            dataforsave: {
                "folderId": store.getters.getselectedFolder,
                "userPermission": []
            },
            userobject:{userId:"",
            emailId:"",
            name:"",
            permissionType:"",
            permissionId:"",
            permissions:[]} ,
            multiuserobject: {},
            permissionList: ["View"],
            selectedscreens: [],
            screensds: [],
            screenNames: [],
            controlName: [],
            baseApi: `${process.env.VUE_APP_Service_URL}`,
            baseurl: `${process.env.VUE_APP_Service_URL}document/folderfilepermission/`,
            baseapi: `${process.env.VUE_APP_Service_URL}configuration`,
            userList: [],
            closemodal: false,
            selectedRole: [],
            Designation: [],
            selectedValue: [],
            folderPermissionList: [],
            permUserlist: [],
            permUser: "",
            showSave: false,
            loadingBlock:false,
            fromPage: "",
            permissions :[],
            expiryDetails : {
                userIds: [],
                folderId: "",
            },
            expiryDateInput: {
                expireDate: null
              },
        }
    },
    watch: {
        "$store.getters.getselectedFolder": function (val) {
            this.docId = store.getters.getselectedFolder
        },
    },
    async mounted() {
        this.idtoken = store.getters.getToken;
        this.fromPage = store.getters.getFromPage;
        await this.listExternalUsers();
        await this.getFolderPermissionofExternalbyId();
        },
    methods: {

        async onChange(event) {
            this.showSave = true
            this.selectedRole = event.target.value;
            await this.SelectBothRoleUsers(this.selectedRole);
            this.permissionset = [];
        },
        async listExternalUsers() {
            const idtoken = store.getters.getToken
            this.selectedValue = this.selectedRole;
            const fromPage = store.getters.getFromPage;
            if (fromPage == 'generalFiles') {
                store.dispatch("setMainDepartment", '')
            }
            await axios
                .get(`${this.baseApi}account-core/user/getlist?UserType=External&&SortProperty=FirstName`, {
                    headers: {
                        Authorization: "Bearer " + idtoken,
                        "Content-Type": "application/json",
                    },
                })
                .then(async (res) => {
                    this.userList = res.data.entities;
                    await this.getAllFolderPermission();
                     this.setusersinarray();
                    await this.getFolderPermissionofExternalbyId();

                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async folderMultiSave() {
            let projId = store.getters.getSelectedProject;
            let savedata = {
                "folderId": this.list,
                "expireDate": this.expiryDateInput.expireDate,
                "externalUserPermissions": Object.values(this.userobject)
            };
            this.loadingBlock=true            
            await axios
            .post(`${this.baseApi}document/externaluser/sharefolder`, savedata
                    , {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.loadingBlock=false
                    alert("Folder shared successfully");
                    this.close();
                    this.userListsss = res.data;
                    const expiryData = {
                        userIds: [savedata.userPermission[0].userId], 
                        folderId: savedata.folderId,
                        expireDate:this.expiryDateInput.expireDate, 
                    };
                    this.AccessExpiry(expiryData);
                })
                .catch((err) => {
                    console.log(err);
                });
            //}
        },
        async getAllFolderPermission() {
            await axios
                .get(`${this.baseurl}allfolderpermission`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.permissionList = ["View"];
                    this.setofpermissions()
                    this.permissionList.forEach((item, index) => {
                       if(item =="View" || item == "Download" ){
                            this.permissions.push(item);
                       }
                      })
                      console.log("the permissions",this.permissions)
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async AccessExpiry(expiryDetails) {
            await axios
                .post(`${this.baseApi}document/externaluser/sharefolder`, expiryDetails
                    , {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.Expired = res.data;   
                    alert("Folder shared successfully");    
                    this.close();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        
        async clearall() {
            this.selectedscreens = [];
            document.getElementById("selectall").checked = false;
            this.screensds = [];
            await this.screens.forEach((element) => {
                document.getElementById(element.id).checked = false;
                let savescreen = {
                    name: "",
                    controls: [],
                };
                savescreen.name = element.name;
                this.screensds.push(savescreen);
            });
        },
        ispermission(id){
            return true;
        },
     setusersinarray() {    
        this.userobject = {};
        console.log("this.userList",this.userList);     
                this.userList.forEach((eachusers) => {
                    console.log("each users",eachusers)
                    this.userobject[eachusers.id]={
                        userId: eachusers.id,
                        emailId: eachusers.primaryEmail,
                        name: eachusers.fullName,
                        permissions:  [],
                        permissionId: "",
                        permissionType: "ProjectRole"
                    };
                });
                console.log(this.userobject, "is not an array or is undefined");
           
        },
        async saveallfun() {
            const usersaveValues = Object.values(this.userobject);
        },
        async setcontrols(elements) {
            console.log("elements",elements)
            let checkBox = document.getElementById(elements.id);
            if (checkBox.checked == true) {
                this.userobject[elements.id].permissions = ["View"];

            } else {
                if (elements.id in this.userobject) {
                    this.userobject[elements.id].permissions = [];
                }
            }
        },
        async setpermissions(permission) {
            alert("checked"+permission);
            // let ischecked = document.getElementById(`${userid}permissions`).checked;
            let permissioncheckBox = document.getElementById("View").checked;
            alert("checked"+permissioncheckBox);
            if (permissioncheckBox == true) {
                alert("checked");
                this.userList.forEach((users) => {
                    if (this.userobject[users.id].permissions == []) {
                        this.userobject[users.id].permissions = ["View"];
                    }
                })
            } else {
                alert("not checked");
                this.userList.forEach((users) => {
                    this.userobject[users.id].permissions = [];
                })
            }
        },
        async setviewpermissions() {
            let checkBoxview = document.getElementById("View");
            if (checkBoxview.checked == true) {
                this.userList.forEach((users) => {
                    this.userobject[users.id].permissions = ["View"];
                })
            } else {
                this.userList.forEach((users) => {
                    this.userobject[users.id].permissions = [];
                    document.getElementById("selectall").checked = false;
                })
            }
        },
        async selectall() {
            let checkBoxall = document.getElementById("selectall");
            if (checkBoxall.checked == true) {
                this.userList.forEach((users) => {
                    this.userobject[users.id].permissions = ["View"];
                })
            } else {
                this.userList.forEach((users) => {
                    this.userobject[users.id].permissions = [];
                    document.getElementById("View").checked = false;
                })
            }
        },
       
        setofpermissions() {
            this.permissionList.forEach((permissions) => {
                this.permissionset.push(permissions.permission);
            })
        },
        selectuserwithpermission(userid) {
            console.log("selectuserwithpermission", this.userobject);
            console.log(`${userid}permissions`, this.userobject[userid].permissions);

            let ischecked = document.getElementById(`${userid}permissions`).checked;
            
            if(ischecked){
                this.userobject[userid].permissions = [];
                this.userobject[userid].permissions.push("View");
            }
            else{
                this.userobject[userid].permissions = [];
            }
            let checkBox = "";
            checkBox = document.getElementById(userid);
            if ((this.userobject[userid].permissions).length != 0) {
                checkBox.checked = true;
            }
        },
        retrievePermission() {
            const userObjectItems = {};
                              
            this.PermissionListExt.forEach((element, index) => {
                if (!userObjectItems[element.userId]) {
                    userObjectItems[element.userId] = {
                        userId: element.userId,
                        emailId: element.emailId,
                        name: element.name,
                        permissions: [],
                        permissionId: "",
                        permissionType: "ProjectRole",
                    };
                }
        
                element.permission.forEach((permissionElement) => {
                    if ((permissionElement == "View" || permissionElement == "Download") && index != 0) {
                        // Make sure to use the correct array for pushing permissions
                        userObjectItems[element.userId].permissions.push(permissionElement);
                    }
                });
            });
        
            console.log("User object", userObjectItems);
        },
        close() {
            this.$emit('closemodal')
            store.dispatch("setfileType", '')
        },

        async getFolderPermissionofExternalbyId() {
            await axios
                .get(`${this.baseApi}document/externaluser/getfolderpermissions?FolderId=${this.list}`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    console.log("Inside getfolderpermissions")
                    this.PermissionListExt = res.data;
                    this.retrievePermission();
                    console.log("Outside getfolderpermissions")
                    console.log("Ext user permission list",this.PermissionListExt)
                    this.PermissionListExt.forEach((item)=>{
                        this.userobject[item.userId].permissions = item.permission;
                    })
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    }
}
