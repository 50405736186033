<template>
  <div class="modal__upload__wrapper">
    <div class="modal-dialog modal-width">
      <div class="modal-content">
        <div class="modal-header-document d-flex justify-content-bertween p-3">
          <h5 class="my-0 align-self-center">New document version</h5>
          <span
            type="button"
            class="align-self-center"
            data-dismiss="modal"
            @click="onclosed('uploadDoc')"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body modal-overflow" id="scrollable">
          <div class="row">
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="document title"
                >Document Title</label
              >
              <input
                type="text"
                id="document-title"
                v-model="docuplooadfields.title"
                class="form-control"
                maxlength="75"
              />
              
            </div>
            <div class="col-12 col-md-6 pl-md-1 mt-2">
              <label class="mb-0 mr-1" for="standard">Select Standard</label>

              <select
                id="standard"
                v-model="docuplooadfields.Standard"
                class="form-select form-select-lg"
                aria-label=".form-select-lg example"
              >
                <option
                  v-for="standard in standards"
                  :key="standard.id"
                  :value="standard.id"
                >
                  {{ standard.name }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="owner">Select Owner</label>
              <select
                id="owner"
                v-model="docuplooadfields.Owner"
                class="mr-3 form-select form-select-lg"
                aria-label=".form-select-lg example"
              >
                <option
                  v-for="users in userList"
                  :key="users.id"
                  :value="users.name"
                >
                  {{ users.name }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-6 pl-md-1 mt-2">
              <label class="mb-0 mr-1" for="category">Select Category</label>
              <select
                id="category"
                v-model="docuplooadfields.Category"
                class="form-select form-select-lg"
                aria-label=".form-select-lg example"
              >
                <option
                  v-for="category in categorylist"
                  :key="category.id"
                  :value="category.category"
                >
                  {{ category.category }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="department">Department</label>
               <input
                v-if="this.department == 'fromdepartment'"
                id="department"
                type="text"
                :value="docuplooadfields.Department"
                class="form-control mr-3"
                maxlength="30"
                readonly
              />
               <!-- :disabled="this.department == 'fromdepartment'"  -->
               <select 
                  v-if="this.department != 'fromdepartment'"
                  id="department"
                  class="form-control"
                  v-model="docuplooadfields.Department"
                  
                 
                >
                  <option
                    v-for="department in departmentlist"
                    :key="department.id"
                    :value="department.name" 
                  >
                    {{ department.name }}
                  </option>
                </select>
               <!-- <input
                id="department"
                type="text"
                :value="docuplooadfields.Department"
                class="form-control mr-3"
                maxlength="30"
                v-if="this.department != 'fromdepartment'"
                  :readonly="readonly"
              />  -->
            </div>
            <div class="col-12 col-md-6 pl-md-1 mt-2">
              <label class="mb-0 mr-1" for="document type">Document Type</label>
              <!-- <span aria-label="mandatory field" class="error-block">*</span> -->
              <select
                id="document type"
                v-model="docuplooadfields.documenttype"
                class="form-select form-select-lg"
                aria-label=".form-select-lg example"
                @change="bookmarklistfun"
              >
                <option
                  v-for="doctype in doctypelist"
                  :key="doctype.id"
                  :value="doctype.id"
                >
                  {{ doctype.documentType }}
                </option>
              </select>
              <!-- <span class="d-block errorClass" v-if="validationmsgs[6] == true">
                Please select document type
              </span> -->
            </div>
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0" id="version">Version</label>
              <span aria-label="mandatory field" class="error-block">*</span>
              <input
                type="text"
                for="version"
                v-model="docuplooadfields.version"
                class="form-control"
                maxlength="10"
              />
              <span class="d-block errorClass" v-if="validationmsgs[3] == true">
                Please enter version
              </span>
            </div>
            <div class="col-12 col-md-6 pl-md-1 mt-2">
              <label class="mb-0" id="expiry-date">Expiry Date</label>
              <input
                for="expiry-date"
                type="text"
                v-model="docuplooadfields.expiarydate"
                class="form-control"
                onfocus="(type='date')"
                onblur="(type='text')"
                maxlength="30"
              />
              <span class="d-block errorClass" v-if="validationmsgs[1] == true">
                Please enter a future date
              </span>
            </div>
            <div class="col-12 mt-2">
              <label class="mb-0" id="description">Description</label>
              <textarea
                id="description"
                class="form-control"
                maxlength="250"
                v-model="docuplooadfields.description"
              ></textarea>
            </div>
            <div class="col-12 mt-2">
              <label class="mb-0" id="changesHistory">Changes history</label>
              <textarea
                id="changesHistory"
                class="form-control"
                maxlength="250"
                v-model="docuplooadfields.changesHistory"
              ></textarea>
            </div>
            <span v-if="docuplooadfields.documenttype != null || docuplooadfields.documenttype != undefined">
            <div v-if="docuplooadfields.documenttype.trim() != ''" class="col-12 mt-2 form-check">
              <input class="form-check-input" @change="createbookmarkuserarray()" v-model="addsigner" type="checkbox" value="" id="flexCheckDefault">
  <label class="form-check-label ml-4" for="flexCheckDefault">
    Do you need to add signer ?
  </label>
            </div>
          </span>
            <div class="col-12 mt-2" v-if="addsigner">
                <table class="table table-striped table-bordered dataTables">
                <thead>
                  <tr>
                    <th class="sort_block" id="1">
                      <div class="d-flex flex-row justify-content-between">
                        Signing order
                      </div>
                    </th>
                    <th class="sort_block" id="1">
                      <div class="d-flex flex-row justify-content-between">
                        Bookmark/Role
                      </div>
                    </th>
                    <th class="sort_block" id="1">
                      <div class="d-flex flex-row justify-content-between">
                        User
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(bookmarks, index) in bookmarklist"
                    :key="bookmarks"
                  >
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>
                      {{ bookmarklist[index].bookmarkName }}
                    </td>
                    <td>
                      <select
                        id="user"
                        class="mr-3 form-select form-select-lg"
                        aria-label=".form-select-lg example"
                        :disabled="index!=0 && bokkmarkusersarray[index-1].userId ==''"
                        @change="assignbookmarkuserfun($event,index)"
                      >
                      <option value="" selected disabled>--Select a User--</option>
                        <option
                          v-for="users in signuserList"
                          :value="users.id+','+users.email+','+users.name"
                          :key="users.id"
                        >
                          {{ users.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
                </table>
              </div>
            <div class="col-12 mt-2">
              <input
                accept=".xls,.xlsx,.jpg,.jpeg,.png,.pdf,.docx"
                id="InPutFile"
                type="file"
                class="form-control unvisible my-2 my-md-0"
                @change="previewFiles"
              />
              <div class="input-group">
                <div class="listfile">
                  <a v-if="removeIcon">
                    {{ this.azuredata.Document.name }}
                    <i class="fa fa-close" @click.prevent="removefile()"></i
                  ></a>
                </div>
              </div>
              <span class="errorClass" v-if="validationmsgs[2] == true">
              Please upload a file
            </span>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-2">
            <span v-if="ApprovalStatus != 'Approved' || ApprovalStatus == ''">
              <button
                type="button"
                class="btn browser-btn btn-xs px-4 float-right mr-3"
                @click="loadfile()"
              >
                Browse
              </button>
            </span>
            <button
              type="button"
              class="btn btn-primary btn-xs px-4 float-right"
              @click="submitFile()"
              :class="{'btn-disabled':loadingBlock}"
              :disabled="loadingBlock"
            >
            <span v-if="loadingBlock"><img
                src="../../assets/img/loading.webp"
                width="15"
                class="loading-img"
                alt="Login buffering"
            /></span>
            <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
/* eslint-disable */

import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import { onBeforeMount } from "@vue/runtime-core";
import jwt_decode from "jwt-decode";

export default {
  name: "NewDocVersion",
  props: {
    department: {
      type: String,
      default: "",
    },
    folderid: {
      type: String,
      default: "",
    },
    docId: {
      type: String,
      default: "",
    },
    fromPage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentuser:"",
      bokkmarkusersarray: [],
      bookmarkselectedusers: [],
      signuserList: [],
      addsigner: false,
      bookmarklist: [
        ],
      departmentlist: [],
      getlatestvermsg:"",
      docFolder:"",
      ApprovalStatus: "",
      currentVersion: "",
      removeIcon: false,
      getdocbyId: "",
      uploadedDocId: "",
      docmsg: true,
      standards: [],
      categorylist: [],
      validationmsgs: [false, false, false, false, false, false, false],
      userList: "",
      idtoken: "",
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      uploadpopupmodal: true,
      validations: [],
      doclist: [],
      azuredata: {
        DocumentId: "",
        DocumentVersionId: "",
        DocumentTypeName: "",
        DocumentTitle: "",
        DocumentDescription: "",
        Document: "",
        OrganizationId: 2,
      },
      documentVersionId: [],
      totalPage: 1,
      currentPage: 1,
      totalCount: 0,
      pageindex: 1,
      pagesize: 10,
      Document: "",
      SortProperty: "CreatedAt",
      SortOrder: 1,
      doctypelist: [],
      docuplooadfields: {
        folderid: "",
        title: "",
        description: "",
        Owner: "",
        Department: "",
        Category: "",
        Standard: "",
        documenttype: "",
        version: "",
        expiarydate: "",
        formData: "",
        changesHistory: "",
      },
      loadingBlock:false
    };
  },
  components: {
    Multiselect,
  },
  async beforeMount() {
    await this.getusers();
    await this.getcategory();
    await this.getstandard();
    await this.gettypelist();
  },
  async mounted() {
    await this.getsignusers();
    document.body.style.overflowY = "hidden";
    await this.getdocdetails();
    await this.getdepartment();
    console.log("Department is",this.department)
    
  },
  async unmounted() {
    document.body.style.overflowY = "auto";
  },
  methods: {
    async setcurentuser(data) {
		const idtoken = store.getters.getToken;
		const userid = (jwt_decode(idtoken).user_id).trim();
		const userObject = data.find(item => item.userId === userid);
    console.log("current user isssss", userObject.name);
    this.currentuser = userObject.name;
    },
     scrollToPosition(desiredPosition) {
     const container = document.getElementById("scrollable");
     container.scrollTop = desiredPosition;
    },
    async assignbookmarkuserfun(event, index) {
      let eachbookuserval = [];
      eachbookuserval = event.target.value.split(",");
      console.log(event.target.value);
      this.bokkmarkusersarray[index].userId = eachbookuserval[0];
      this.bokkmarkusersarray[index].userEmail = eachbookuserval[1];
      this.bokkmarkusersarray[index].userName = eachbookuserval[2];
      console.log("bokkmarkusersarray", this.bokkmarkusersarray);
    },
    async createbookmarkuserarray() {
      this.bokkmarkusersarray = [];
      this.bookmarklist.forEach((eachbookmark) => {
        this.bookmarkselectedusers.push("");
        let samplebookmarkuser = {
          bookmark: eachbookmark.bookmarkName,
          nullable: true,
          signOrder: eachbookmark.sortOrder,
          nullable: true,
          userId: "",
          nullable: true,
          userEmail: "",
          nullable: true,
          date: null,
          signId: "",
          nullable: true,
          reason: "",
          nullable: true,
          userName: "",
          nullable: true,
        };
        this.bokkmarkusersarray.push(samplebookmarkuser);
      });
      console.log("bokkmarkusersarray", this.bokkmarkusersarray);
    },
    async getsignusers() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}account-core/user/getall`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.signuserList = res.data;
          console.log("userlist isssss", this.signuserList);
        })
        .catch((err) => {
          console.log(err);
          this.signuserList = [];
        });
    },
    async bookmarklistfun(event){
      console.log("bookmarklist  isssss", event.target.value);
      const selectedValue = event.target.value;
      this.listbookmarkfun(selectedValue);
    },
    async listbookmarkfun(selectedValue){
      const idtoken = store.getters.getToken;
      await axios
        .get(
           `${this.mainapi}document/bookmark/getbookmarksbydoctype?id=${selectedValue}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("bookmarklist  isssss", res.data);
          this.bookmarklist = res.data;
          this.createbookmarkuserarray();
        })
        .catch((err) => {
          console.log(err);
        });
    },
     async getdepartment() {
     const idtoken = store.getters.getToken;
      const orgId = store.getters.getOrganisationID;
      await axios
        .get(`${this.baseurl}department/search?orgid=${orgId}&PageSize=10000&SortProperty=name&IsDescending=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
        
          res.data.entities.forEach((data) => {
            if (data.parentID == "" || data.parentID == null) {
              if (!this.departmentlist.includes(data)) {
                this.departmentlist.push(data)
              }
            }
          });
        })
        .catch((err) => {
            
          console.log(err.response.data.messages)
        })
    },
    async getdocdetails() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.mainapi}document/document/getnewversiondocumentitemdetails?documentId=${this.docId}`,
         // `https://localhost:5001/document/getnewversiondocumentitemdetails?documentId=${this.docId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Get doc details", res.data);
          this.getdocbyId = res.data;
          this.listbookmarkfun(res.data.documentType);
          console.log("Total count of ver",res.data.documentVersions.length)
          this.getlatestvermsg = res.data.documentVersions[res.data.documentVersions.length-1].versionMessage;
          this.currentVersion = this.getdocbyId.currentVersion;
          this.approvedOrNot();
          this.setData();
          console.log("dePP",this.department)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async setData() {
      this.getdocbyId.expiryDate != null
        ? (this.getdocbyId.expiryDate = this.getdocbyId.expiryDate.slice(0, 10))
        : this.getdocbyId.expiryDate;
      this.docFolder = this.getdocbyId.folderId;
      this.docuplooadfields.title = this.getdocbyId.documentTitle;
      this.docuplooadfields.description = this.getdocbyId.documentDescription;
      this.docuplooadfields.Owner = this.getdocbyId.owner;
      this.docuplooadfields.Department = this.getdocbyId.department;
      this.docuplooadfields.Category = this.getdocbyId.catagory;
      this.docuplooadfields.Standard = this.getdocbyId.standard;
      this.docuplooadfields.documenttype = this.getdocbyId.documentType;
      this.docuplooadfields.version = this.getdocbyId.currentVersion==null ?"":this.getdocbyId.currentVersion;
      this.docuplooadfields.expiarydate = this.getdocbyId.expiryDate;
      this.docuplooadfields.changesHistory =this.getlatestvermsg;
        
        //this.getdocbyId.documentVersions[0].versionMessage;
    },

    async addnewversionFile() {
      const idtoken = store.getters.getToken;
      if (this.azuredata.Document != "") {
        await axios
          .post(
            `${this.mainapi}document/document/update`,
            //`https://localhost:5001/document/update`,
            {
              File: this.azuredata.Document,
              DocumentId: this.docId,
              //ExpiryDate: this.docuplooadfields.expiarydate,
              VersionMessage: this.docuplooadfields.changesHistory,
              Version: this.docuplooadfields.version.trim(),
              Signers: this.bokkmarkusersarray
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.loadingBlock=false
            alert("Document saved successfully");
            this.onclosed();
          })
          .catch((err) => {
            console.log(err.data);
            alert("Version already exist");
            this.loadingBlock=false
          });
      }
    },

    async loadfile() {
      let fileload = document.getElementById("InPutFile");
      fileload.click();
    },
    async getusers() {
      console.log("this.department",this.department);
      const idtoken = store.getters.getToken;
      if(this.department == "generalFiles" || this.department == "dashboard"){
        store.dispatch("setMainDepartment", "");
      }
      if(this.department =="fromdepartment" || this.department == "generalFiles"|| this.department == "dashboard")
      {
        await axios
        .post(`${this.mainapi}document/folderfilepermission/designationusers?departmentId=`+store.getters.getMainDepartment,[], {
       // .post(`https://localhost:5001/folderfilepermission/designationusers?departmentId=`+store.getters.getMainDepartment,[], {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.userList = res.data;
          this.setcurentuser(res.data);
        })
        .catch((err) => {
          console.log(err);
          this.userList = [];
        });
      }
      else{
          await axios
           .get(`${this.baseurl}userprojectrole/getprojectusers?projectId=`+store.getters.getSelectedProject,{
        //  .get(`https://localhost:5001/userprojectrole/getprojectusers?projectId=`+store.getters.getSelectedProject,{
          headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
          },
      })
     
      .then((res) => {
          this.userList = res.data;
          this.setcurentuser(res.data);
         
        })
        .catch((err) => {
          console.log(err);
          this.userList = [];
        });
      }
      
    },
    async gettypelist() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}document/document/gettypelist`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.doctypelist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.doctypelist = [];
        });
    },
    async getstandard() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}configuration/documentstandard/search?PageSize=1000&SortProperty=name&IsDescending=false`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.standards = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.standards = [];
        });
    },
    async getcategory() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}configuration/documentcategory/search?PageSize=1000&SortProperty=category&IsDescending=false`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.categorylist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.categorylist = [];
        });
    },

    async onclosed() {
      this.$emit("editdocactive");
    },
    async validatedocument() {
      this.validationmsgs = [false, false, false, false];

      if (store.getters.getselectedFolder.trim() == "") {
        this.validationmsgs[0] = true;
      }

      if (this.azuredata.Document.length === 0) {
        this.validationmsgs[2] = true;
      }
      if (this.docuplooadfields.version.trim() === "") {
        this.validationmsgs[3] = true;
      }
      if (this.docuplooadfields.expiarydate != null) {
        if (new Date(this.docuplooadfields.expiarydate) < new Date()) {
          this.validationmsgs[1] = true;
        }
      }
      
    },
    async submitFile() {
       await this.save();
     
    },
    async save() {
      const idtoken = store.getters.getToken;
      await this.validatedocument();
      if (!this.validationmsgs.includes(true)) {
        if(this.docuplooadfields.title.trim() == ""){
              this.docuplooadfields.title = this.azuredata.Document.name;
            }
            if(this.docuplooadfields.Owner.trim() == ""){
              this.docuplooadfields.Owner = this.currentuser;
            }
        //const folderId = store.getters.getselectedFolder;
        this.loadingBlock=true
        await axios
          .put(
              `${this.mainapi}document/document/edit`,
            //`https://localhost:5001/document/edit`,
            {
              documentId: this.docId,
              documentDescription: this.docuplooadfields.description,
              folderId: this.docFolder,
              expiryDate: this.docuplooadfields.expiarydate,
              department: this.docuplooadfields.Department,
              owner: this.docuplooadfields.Owner.trim(),
              documentType: this.docuplooadfields.documenttype,
              currentVersion: this.currentVersion,
              catagory: this.docuplooadfields.Category,
              documentTitle: this.docuplooadfields.title.trim(),
              standard: this.docuplooadfields.Standard,
              versionMessage: this.getlatestvermsg,
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // console.log("submit response", response);
            // if (
            //   this.ApprovalStatus == "NotApproved" ||
            //   this.ApprovalStatus == ""
            // ) {
               this.addnewversionFile();
            // }
            if (this.docmsg) {
              // alert("Document saved successfully");
              this.docmsg = false;
            }
            // this.onclosed();
          })
          .catch((err) => {
            console.log(err.data);
          });
      }
    },
    async approvedOrNot() {
      const idtoken = store.getters.getToken;
      console.log("inside approved or not");
      await axios
        .get(
          `${this.mainapi}document/document/getdocumentapproveornot?documentId=${this.docId}&CurrentVersion=${this.currentVersion}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("approved or not status==", res.data);
          if (res.data == "NotApproved") {
            this.ApprovalStatus = "NotApproved";
          } else if (res.data == "Approved") {
            this.ApprovalStatus = "Approved";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async validateDocumentIsNull() {
      if (this.azuredata.Document != "") {
        this.validations[1] = true;
      } else {
        this.validations[1] = false;
      }
    },
    async previewFiles(event) {
      this.azuredata.Document = event.target.files[0];
      console.log("Preview", this.azuredata.Document);
      this.removeIcon = true;
    },
    async removefile() {
      this.azuredata.Document = "";
      this.removeIcon = false;
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css">
</style>
<style scoped>
.modal__upload__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.modal-overflow {
  overflow-x: hidden;
  height: 400px;
  overflow-y: auto;
}

.groupupload {
  display: flex;
}

.save_btn {
  background-color: #145faf !important;
  color: white;
}

.errorClass {
  color: red;
  font-size: 13px;
}
.unvisible {
  display: none;
}

.listfile {
  width: 90%;
}

.imp {
  color: #ff0000;
  font-size: 7px;
}
.error-block {
  color: #ff0000;
}

.modal-header-document {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.loading-img {
  mix-blend-mode: lighten;
}
.btn-disabled{
  opacity:0.5;
  cursor:disabled

}
</style>

