/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";

export default {
  data() {
    return {
      sopinitiatorList:[],
      sopList:[],
      traineepageindex: 1,
      traineepagesize: 10,
      traineetotalPage: 1,

      docurl: `${process.env.VUE_APP_Service_URL}document/projectdevdocumenttemplate/`,
      idtoken: "",
      showDesig: false,
      soptraineeList: [],

      currentPage: 1,
      traineecurrentPage: 1,
      pageindex: 1,
      pagesize: 10,
      totalPage: 1,
      SortProperty: "CreatedAt",
      //SortOrder: false,
      SortOrder: 0,
      pageindexs: 1,
      pagesizes: 10,
      totalPages: 1,
      SortPropertys: "CreatedAt",
      //SortOrder: false,
      SortOrders: 0,
      initiatortotalPage:1,
      initiatorcurrentPage: 1,
      initiatorpageindex: 1,
      initiatorpagesize: 10,
      errors: [],
      validations: [],
      disignations: {},
      update: {
        designationId: "",
        name: "",
        description: "",
        active: true,
      },

      search: {
        status: "",
        trainername: "",
        traineename: "",
        trainerid: "",
        traineeid: "",
        id: ""
      },

    };
  },

  async mounted() {

    this.designationPrivileges = store.getters.getRolesprivilegeData;
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    this.idtoken = store.getters.getToken
    this.Listtraineesopdocs();
    this.ListSOPforVerifier();
   // this.Listinitiatorsopdocs();

    this.userEmail = store.getters.getUserEmail;

  },

  methods: {
    filter(data) {
      if (data) {
        return moment(data).format("DD-MM-YYYY");
      }
    },
    async GotoTraineeUI(sopId, sopstatus) {
      if (sopstatus != "Approved") {
        let usertype = "TraineeSignature";
        this.$router.push("/sop_acknoledgment/" + sopId + "/" + usertype);
      }
      else {
        this.$router.push("/sopdocview/" + sopId);
      }
    },
    async GotoInitiatorUI(sopId, sopstatus) {
      if (sopstatus != "Approved") {
        let usertype = "InitiatorSignature";
        this.$router.push("/sop_acknoledgment/" + sopId + "/" + usertype);
      }
      else {
        this.$router.push("/sopdocview/" + sopId);
      }
    },
    async GotoVerifierUI(sopId, sopstatus) {
      if (sopstatus != "Approved") {
        let usertype = "TrainerSignature";
        this.$router.push("/sop_acknoledgment/" + sopId + "/" + usertype);
      }
      else {
        this.$router.push("/sopdocview/" + sopId);
      }
    },
    async ListSOPforVerifier() {

      let email = store.getters.getUserEmail;
      //alert(email)

      await axios.get(
        //`https://localhost:5001/projectdevdocumenttemplate/getsopacknowledgement?trainerId=${email}&PageIndex=${this.pageindexs}&PageSize=${this.pagesizes}&SortProperty=${this.SortPropertys}&SortOrder=${this.SortOrders}`,
        `${this.docurl}getsopacknowledgement?trainerId=${email}&PageIndex=${this.pageindexs}&PageSize=${this.pagesizes}&SortProperty=${this.SortPropertys}&SortOrder=${this.SortOrders}`,
        {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        }
      )

        .then((res) => {

          this.sopList = res.data.entities;

          this.totalPages = parseInt(res.data.totalCount / this.pagesizes);

          if (res.data.totalCount % this.pagesize != 0) {

            this.totalPages = this.totalPages + 1;

          }

          console.log("SOP DETAILS", this.sopList)

        })

        .catch((err) => {

          console.log(err);

          this.sopList = []

          alert("No data found!")

        })

    },


    async Listtraineesopdocs() {
      console.log("page number is ", this.traineepageindex);
      this.userEmail = store.getters.getUserEmail;

      //this.userEmail="Divya"
      await axios
        .get(
          //`https://localhost:5001/projectdevdocumenttemplate/getsopacknowledgement?id=${this.search.id}&traineeId=${this.userEmail}&PageIndex=${this.traineepageindex}&PageSize=${this.traineepagesize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}`,
          `${this.docurl}getsopacknowledgement?id=${this.search.id}&traineeId=${this.userEmail}&PageIndex=${this.traineepageindex}&PageSize=${this.traineepagesize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.soptraineeList = res.data.entities;
          console.log(this.soptraineeList);
          this.traineetotalPage = parseInt(res.data.totalCount / this.traineepagesize);
          if (res.data.totalCount % this.traineepagesize != 0) {
            this.traineetotalPage = this.traineetotalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.soptraineeList = [];
          alert("No data found!");
        });
    },
    async Listinitiatorsopdocs() {
      this.userEmail = store.getters.getUserEmail;

      await axios
        .get(
          //`https://localhost:5001/projectdevdocumenttemplate/getsopacknowledgement?id=${this.search.id}&initiatorId=${this.userEmail}&PageIndex=${this.pageindex}&PageSize=${this.pagesize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}`,
          `${this.docurl}getsopacknowledgement?id=${this.search.id}&initiatorId=${this.userEmail}&PageIndex=${this.initiatorpageindex}&PageSize=${this.initiatorpagesize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}`,

          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sopinitiatorList = res.data.entities;
          this.initiatortotalPage = parseInt(res.data.totalCount / this.initiatorpagesize);
          if (res.data.totalCount % this.initiatorpagesize != 0) {
            this.initiatortotalPage = this.initiatortotalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.sopinitiatorList = [];
          alert("No data found!");
        });
    },


    SortSelected(sortProp, id) {
      console.log("SortPropChanged", sortProp, id);
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == true ? false : true;
      this.SortOrderInt = this.SortOrder == true ? 0 : 1;
      this.Listtraineesopdocs();
    },
    loadPage: function (page) {
      this.traineecurrentPage = page;
      this.traineepageindex = page;
      this.Listtraineesopdocs();
      //  this.setfunctions();
    },
    loadPages: function (page) {
      this.currentPage = page;
      this.pageindexs = page;
      this.ListSOPforVerifier();
      //  this.setfunctions();
    },
    initiatorloadPages: function (page) {
      this.initiatorcurrentPage = page;
      this.initiatorpageindex = page;
      this.Listinitiatorsopdocs();
    },
    goBack() {
      this.$router.push(`/training`);
    }
  },
};
