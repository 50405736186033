<template>
  <div class="row wrapper border-bottom white-bg page-heading px-4">
    <div class="col-md-10 py-0 px-0 mx-0">
      <h2 class="my-0">Administration</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="text-center" v-if="showdiv">
      <h1 class="text-center">No privilege found</h1>
    </div>
    <div class="row" v-else>
      <div
        class="col-12 mb-2 mb-md-0 col-md-3"
        v-if="designationPrivileges.includes(`Administration Users View`)"
      >
        <div
          class="dashboard-widget d-flex mb-4"
          @click.prevent="gotoUrl('user')"
        >
          <div class="icons neutral-background py-3 px-2">
            <img
              src="../../assets/img/dashboard/users_dashboard.png"
              alt="Users Dashboard Tab"
            />
          </div>
          <div
            class="title d-flex justify-content-between align-items-center w-100 px-3"
          >
            <span class="text-capitalize"><b>Users</b></span>
            <img src="../../assets/img/right.png" alt="Go to the page" />
          </div>
        </div>
      </div>
      <div
        class="col-12 mb-2 mb-md-0 col-md-3"
        v-if="designationPrivileges.includes(`Administration Designation View`)"
      >
        <div
          class="dashboard-widget d-flex mb-4"
          @click.prevent="gotoUrl('des')"
        >
          <div class="icons roles-background py-3 px-2">
            <img
              src="../../assets/img/dashboard/roles_dashboard.png"
              alt="Roles Dashboard Tab"
            />
          </div>
          <div
            class="title d-flex justify-content-between align-items-center w-100 px-3"
          >
            <span class="text-capitalize"><b>Designation</b></span>
            <img src="../../assets/img/right.png" alt="Go to the page" />
          </div>
        </div>
      </div>
      <div class="col-12 mb-2 mb-md-0 col-md-3" v-if="designationPrivileges.includes(`Administration Config Bookmark (Sign) View`)">
        <div class="dashboard-widget d-flex mb-4" @click.prevent="gotoUrl('documenttype')">
          <div class="icons examevidence-background  py-3 px-2">
            <img src="../../assets/img/dashboard/exam_evidence.png" alt="document type" />
          </div>
          <div class="
            title
            d-flex
            justify-content-between  
            align-items-center                
            w-100
            px-3
          ">
            <span class="text-capitalize"><b>Config Bookmark(Sign)</b></span>
            <img src="../../assets/img/right.png" alt="Go to the page" />
          </div>
        </div>
      </div>
      <div class="col-12 mb-2 mb-md-0 col-md-3" v-if="designationPrivileges.includes(`Administration Screens View`)">
        <div class="dashboard-widget d-flex mb-4" @click.prevent="gotoUrl('screens')">
          <div class="icons examevidence-background  py-3 px-2">
            <img src="../../assets/img/dashboard/exam_evidence.png" alt="screens" />
          </div>
          <div class="
            title
            d-flex
            justify-content-between  
            align-items-center                
            w-100
            px-3
          ">
            <span class="text-capitalize"><b>Screens</b></span>
            <img src="../../assets/img/right.png" alt="Go to the page" />
          </div>
        </div>
      </div>
      <div class="col-12 mb-2 mb-md-0 col-md-3" v-if="designationPrivileges.includes(`Administration Config Document Template View`)">
        <div class="dashboard-widget d-flex mb-4" @click.prevent="gotoUrl('documenttemplate')">
          <div class="icons examevidence-background  py-3 px-2">
            <img src="../../assets/img/dashboard/exam_evidence.png" alt="document template" />
          </div>
          <div class="
            title
            d-flex
            justify-content-between  
            align-items-center                
            w-100
            px-3
          ">
            <span class="text-capitalize"><b>Config Document Template</b></span>
            <img src="../../assets/img/right.png" alt="Go to the page" />
          </div>
        </div>
      </div>
    
    <!-- <div class="row" v-if ="showdiv === false">
     
    </div> -->
  </div>
  </div>
</template>

<script src="./administration.js"></script>
<style scoped>
.menuitem {
  background-color: #c6c6c6;
  width: 100%;
  height: 48px;
  text-align: center;
  padding-top: 6%;
  margin-bottom: 1px;
}

.menuitem:hover {
  background-color: #b2c5f4;
}

.fullwidth {
  width: 95.666667%;
}

.rowdiv {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.boxtitle {
  height: 40px;
  background-color: #618bd7;
  margin: 10px;
  position: relative;
  text-align: initial;
  color: white;
  font-size: 14px;
  padding: 10px;
}

.boxcontents {
  min-height: 300px;
  background-color: white;
  height: 0px;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.maincontents {
  /* background-color: red; */
  width: 100%;
  position: inherit;
  margin-bottom: 80px;
}

.topmenubar {
  background-color: #efefef;
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  display: flex;
}

.title-bg-light {
  background-color: #d9e4fb;
}

.dashboard-widget {
  cursor: pointer;
  box-shadow: 0 0.15rem 0.15rem rgb(0 0 0 / 8%);
}

.dashboard-widget .title {
  background-color: #ffffff;
}

.primary-background {
  background-color: #dcfee4;
  height: 90px;
}

.secondary-background {
  background-color: #bdf5be;
}

.neutral-background {
  background-color: #ebf5c0;
}

.roles-background {
  background-color: #f5d6d1;
}

.sop-background {
  background-color: #dce1ff;
}

.myfiles-background {
  background-color: hsl(65, 100%, 91%);
}
.examevidence-background {
  background-color: #dce1ff;
}

.forms-block {
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 0 0.15rem 0.15rem rgb(0 0 0 / 8%);
}

.btn-form {
  border-radius: 4px;
  background-color: #618bd7;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

.btn-form i {
  font-size: 12px;
}

.btn-form:hover {
  color: #ffffff;
}

.margin-top {
  margin-top: 0.5rem;
}

.padding-block {
  padding-block: 3.2rem;
}
.training-background {
  background-color: #dbf0ff;
}
@media (min-width: 992px) {
  .margin-top {
    margin-top: auto;
  }
}
</style>