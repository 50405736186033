/* eslint-disable */
import axios from "axios";
import store from "../../store/index";

export default {
  data() {
    return {
    };
  },
  methods: {
    async loginScreen(){
        this.$router.push(`/`);
    }
  },
};
