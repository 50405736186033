<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2>Online Test</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i
        class="fa fa-times close cursor-pointer"
        @click="goBack"
      ></i>
    </div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="tabs-container">
          <ul class="nav nav-tabs" role="tablist">
            <li>
              <a
                class="nav-link active"
                data-toggle="tab"
                id="Initiator"
                @click="SwitchTab('Initiator', true, false)"
                href="#tab-1"
              >
                Initiator
              </a>
            </li>
            <li>
              <a
                class="nav-link"
                data-toggle="tab"
                @click="SwitchTab('Supervisor', true, false)"
                id="Supervisor"
                href="#tab-2"
                >Supervisor</a
              >
            </li>
            <li>
              <a
                class="nav-link"
                @click="SwitchTab('Employee', true, false)"
                data-toggle="tab"
                id="Employee"
                href="#tab-3"
                >Employee</a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div role="tabpanel" id="tab-1" class="tab-pane">
              <div class="ibox-content">
                <div class="form-group row">
                  <div class="col-lg-12 my-2">
                    <div class="form-group row mb-3">
                      <!--search filter starts here -->
                      <div class="col-12 col-md-3 my-1 pr-md-1">
                        <div class="form-group mb-md-0 pr-md-1">
                          <label class="mb-0">Employee Name</label>
                          <input
                            type="text"
                            class="form-control my-md-0"
                            v-model="searchField.employeename"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-1 my-1 pr-md-1">
                        <div class="form-group mb-md-0">
                          <label class="mb-0">Result</label>
                          <select
                            class="form-control my-md-0"
                            name="account"
                            placeholder="Search"
                            v-model="searchField.testresult"
                          >
                            <option value="Passed">Passed</option>
                            <option value="Failed">Failed</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12 col-md-2 my-1 pr-md-1">
                        <div class="form-group mb-md-0">
                          <label class="mb-0">Status</label>
                          <select
                            class="form-control my-md-0"
                            name="account"
                            placeholder="Search"
                            v-model="searchField.status"
                          >
                            <option value="Initiated">Initiated</option>
                            <option value="Completed">Completed</option>
                            <option value="Signed">Signed</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12 col-md-2 my-1 pr-md-1">
                        <div class="form-group mb-md-0">
                          <label class="mb-0"> Exam From Date </label>
                          <input
                            type="date"
                            v-model="searchField.fromdate"
                            class="form-control my-md-0"
                            :max="toDatePicker(dateToday)"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-2 my-1 pr-md-1">
                        <div class="form-group mb-md-0">
                          <label class="mb-0">To Date</label>
                          <input
                            type="date"
                            v-model="searchField.todate"
                            class="form-control my-md-0"
                          />
                          <span class="errorClass" v-if="errors[2] == true">
                            Invalid end date.
                          </span>
                          <span class="errorClass" v-if="errors[3] == true">
                            End date must be greater than start date.
                          </span>
                        </div>
                      </div>
                      <div class="col-12 col-md-2 align-self-end my-1">
                        <span
                          type="button"
                          class="submit_btn px-5 w-100"
                          @click="SwitchTab('Initiator', true, true)"
                          >Search</span
                        >
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table
                        class="table table-striped table-bordered dataTables"
                      >
                        <thead>
                          <tr>
                            <th class="sort_block" id="1">
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Employee Name
                              </div>
                            </th>

                            <th class="sort_block" id="2">
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Test Name
                              </div>
                            </th>
                            <th>
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Supervisor Name
                              </div>
                            </th>

                            <th>
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Exam Date
                              </div>
                            </th>
                            <th class="sort_block" id="3">
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Result
                              </div>
                            </th>
                            <th class="sort_block" id="3">
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Status
                              </div>
                            </th>

                            <th class="text-center">View</th>
                            <th class="text-center">Download</th>
                            <th class="text-center">Retest</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="userlist in userExamList"
                            :key="userlist.Id"
                          >
                            <td>
                              {{ userlist.employeeName }}
                            </td>

                            <td>{{ userlist.testName }}</td>
                            <td>{{ userlist.supervisorName }}</td>

                            <td>{{ filter(userlist.dateConductedOn) }}</td>
                            <td>
                              {{ userlist.result }}
                            </td>
                            <td>
                              {{ userlist.status }}
                            </td>
                            <td class="text-center">
                              <i
                                class="fa fa-eye edit-delete"
                                @click="
                                  ViewTest(userlist.configId, userlist.examId)
                                "
                              ></i>
                            </td>
                            <td class="text-center">
                              <i
                                class="fa fa-download"
                                v-if="userlist.status == 'Signed'"
                                @click="DownloadReport(userlist.examId)"
                              ></i>
                            </td>
                            <td class="text-center">
                              <i
                                class="fa fa-repeat"
                                @click="Retest(userlist.id, userlist.status)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-md-12 d-flex justify-content-md-end px-0">
                      <v-pagination
                        v-model="currentPage"
                        :pages="totalPage"
                        :range-size="1"
                        active-color="#ededed"
                        @update:modelValue="loadPage"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" id="tab-2" class="tab-pane">
              <div class="ibox-content">
                <div class="form-group row">
                  <div class="col-lg-12 my-2">
                    <div class="form-group row mb-3">
                      <!--search filter starts here -->
                      <div class="col-12 col-md-3 my-1 pr-md-1">
                        <div class="form-group mb-md-0 pr-md-1">
                          <label class="mb-0">Employee Name</label>
                          <input
                            type="text"
                            class="form-control my-md-0"
                            v-model="searchField.employeename"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-1 my-1 pr-md-1">
                        <div class="form-group mb-md-0">
                          <label class="mb-0">Result</label>
                          <select
                            class="form-control my-md-0"
                            name="account"
                            placeholder="Search"
                            v-model="searchField.testresult"
                          >
                            <option value="Passed">Passed</option>
                            <option value="Failed">Failed</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12 col-md-2 my-1 pr-md-1">
                        <div class="form-group mb-md-0">
                          <label class="mb-0">Status</label>
                          <select
                            class="form-control my-md-0"
                            name="account"
                            placeholder="Search"
                            v-model="searchField.status"
                          >
                            <option value="Initiated">Initiated</option>
                            <option value="Completed">Completed</option>
                            <option value="Signed">Signed</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12 col-md-2 my-1 pr-md-1">
                        <div class="form-group mb-md-0">
                          <label class="mb-0"> Exam From Date </label>
                          <input
                            type="date"
                            v-model="searchField.fromdate"
                            class="form-control my-md-0"
                            :max="toDatePicker(dateToday)"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-2 my-1 pr-md-1">
                        <div class="form-group mb-md-0">
                          <label class="mb-0">To Date</label>
                          <input
                            type="date"
                            v-model="searchField.todate"
                            class="form-control my-md-0"
                          />
                          <span class="errorClass" v-if="errors[2] == true">
                            Invalid end date.
                          </span>
                          <span class="errorClass" v-if="errors[3] == true">
                            End date must be greater than start date.
                          </span>
                        </div>
                      </div>
                      <div class="col-12 col-md-2 align-self-end my-1">
                        <span
                          type="button"
                          class="submit_btn px-5 w-100"
                          @click="SwitchTab('Supervisor', true, true)"
                          >Search</span
                        >
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table
                        class="table table-striped table-bordered dataTables"
                      >
                        <thead>
                          <tr>
                            <th class="sort_block" id="1">
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Employee Name
                              </div>
                            </th>

                            <th class="sort_block" id="2">
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Test Name
                              </div>
                            </th>
                            <th>
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Supervisor Name
                              </div>
                            </th>

                            <th>
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Exam Date
                              </div>
                            </th>
                            <th class="sort_block" id="3">
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Result
                              </div>
                            </th>
                            <th class="sort_block" id="3">
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Status
                              </div>
                            </th>

                            <th class="text-center">View</th>
                            <th class="text-center">Retest</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="suplst in userExamList" :key="suplst.Id">
                            <td>
                              {{ suplst.employeeName }}
                            </td>

                            <td>{{ suplst.testName }}</td>
                            <td>{{ suplst.supervisorName }}</td>

                            <td>{{ filter(suplst.dateConductedOn) }}</td>
                            <td>
                              {{ suplst.result }}
                            </td>
                            <td>
                              {{ suplst.status }}
                            </td>
                            <td class="text-center">
                              <i
                                class="fa fa-eye edit-delete"
                                @click="
                                  ViewTest(suplst.configId, suplst.examId)
                                "
                              ></i>
                            </td>
                            <td class="text-center">
                              <i
                                class="fa fa-repeat"
                                @click="examId(suplst.configId, suplst.examId)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="col-md-12 d-flex justify-content-md-end px-0">
                      <v-pagination
                        v-model="currentPage"
                        :pages="totalPage"
                        :range-size="1"
                        active-color="#ededed"
                        @update:modelValue="loadPage"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" id="tab-3" class="tab-pane">
              <div class="ibox-content">
                <div class="form-group row">
                  <div class="col-lg-12 my-2">
                    <div class="table-responsive">
                      <table
                        class="table table-striped table-bordered dataTables"
                      >
                        <thead>
                          <tr>
                            <th class="sort_block" id="1">
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Employee Name
                              </div>
                            </th>

                            <th class="sort_block" id="2">
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Test Name
                              </div>
                            </th>
                            <th>
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Supervisor Name
                              </div>
                            </th>

                            <th>
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Exam Date
                              </div>
                            </th>
                            <th class="sort_block" id="3">
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Result
                              </div>
                            </th>
                            <th class="sort_block" id="3">
                              <div
                                class="d-flex flex-row justify-content-between"
                              >
                                Status
                              </div>
                            </th>

                            <th class="text-center">View</th>
                            <th class="text-center">Retest</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="usrlst in userExamList" :key="usrlst.Id">
                            <td>
                              {{ usrlst.employeeName }}
                            </td>

                            <td>{{ usrlst.testName }}</td>
                            <td>{{ usrlst.supervisorName }}</td>

                            <td>{{ filter(usrlst.dateConductedOn) }}</td>
                            <td>
                              {{ usrlst.result }}
                            </td>
                            <td>
                              {{ usrlst.status }}
                            </td>
                            <td class="text-center">
                              <i
                                class="fa fa-eye edit-delete"
                                @click="
                                  ViewTest(usrlst.configId, usrlst.examId)
                                "
                              ></i>
                            </td>

                            <td class="text-center">
                              <i
                                class="fa fa-repeat"
                                @click="Retest(usrlst.configId, usrlst.examId)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="col-md-12 d-flex justify-content-md-end px-0">
                      <v-pagination
                        v-model="currentPage"
                        :pages="totalPage"
                        :range-size="1"
                        active-color="#ededed"
                        @update:modelValue="loadPage"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./superuserlisting.js"></script>
<style scoped>
@import "../../assets/css/savepages.css";

.fa-asterisk {
  color: #e56363 !important;
  font-size: 6px;
}

.errorClass {
  color: red;
  font-size: 15px;
}

.Image-user-Auth-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.modal-header-department {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.errmsg {
  font-size: 12px;
  color: red;
  float: left;
}

.width {
  width: min(400px, 90%);
}

.info {
  width: 14px;
  margin-left: 4px;
  height: 14px;
}

.create-user-mod {
  position: absolute;
  width: 350px;
  top: -114px;
  left: 9px;
}
</style>