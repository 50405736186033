/* eslint-disable */
import "/src/assets/style.css";
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "formdesign",
  components: {
    Multiselect,
    VPagination,
  },
  props: { id: String, },
  data() {
    const tH = 90;
    const tW = "100%";
    return {
      edittablevar: false,
      editindex: null,
      savetablemodal: false,
      tabledata: {
        tableHeader: "",
        tableContents: [],
        tableData: []
      },
      columnnamesarray: [],
      columnnames: "",
      createtablemodal: false,
      errorMsg: false,
      errorMsgNew: false,
      idtoken: "",
      baseurl: process.env.VUE_APP_Service_URL,
      fieldnamesarray: [],
      tempfieldname: "",
      newtemplate: [],
      templatename: "",
      tabletemplatelist: {
        documentType: "",
        projectName: "",
        storyTitle: "",
        storyId: "",
        storyDetailsTbl: [
          ""
        ],
        tableList: [
        ]
      },
      reloadtabletemplatelist: {
        documentType: "",
        projectName: "",
        storyTitle: "",
        storyId: "",
        storyDetailsTbl: [
          ""
        ],
        tableList: [
        ]
      },
      reldtmp: "",
      rlddoc: "",
    };
  },
  async mounted() {
    this.idtoken = store.getters.getToken
    console.log("hello");
    if (this.id != "0" && this.id != 0) {
      this.reloadtabletemplate();
    }
  },

  methods: {
    async deletetable(index) {
      if (confirm("Are you sure you want to Delete the table?")) {
        this.tabletemplatelist.tableList.splice(index, 1);
      }
    },
    async edittable(index) {
      this.edittablevar = true;
      this.editindex = index;
      this.tabledata = this.tabletemplatelist.tableList[index];
      console.log("columndata", this.tabletemplatelist.tableList[index].tableData);
      this.columnnamesarray.push(...this.tabletemplatelist.tableList[index].tableContents);
      console.log("columndata", this.columnnamesarray);
      this.createtablemodal = true;
    },
    async deleterow(subindex, outerIndex) {
      this.tabletemplatelist.tableList[subindex].tableData.splice(outerIndex, 1);
    },
    async reloadtabletemplate() {
      await axios
        .get(
          `${this.baseurl}document/projectdevdocumenttemplate/gettemplate?Id=${this.id}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {

          this.tabletemplatelist = res.data;  
          this.reldtmp = this.tabletemplatelist.documentType;
          this.rlddoc = this.tabletemplatelist.projectName;       
          console.log("template detail is ...........", this.tabletemplatelist);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async reloadSavedtabletemplate() {
      await axios
        .get(
          `${this.baseurl}document/projectdevdocumenttemplate/gettemplate?Id=${this.id}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {

          this.reloadtabletemplatelist = res.data;
          this.reldtmp = this.reloadtabletemplatelist.documentType;
          this.rlddoc = this.reloadtabletemplatelist.projectName; 
          console.log("reload template detail is ...........", this.reldtmp);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async opensavemodal() {
      this.savetablemodal = true;
      if (this.id != 0) {

        this.reloadSavedtabletemplate();

      }
    },
    async closesavemodall() {
      this.savetablemodal = false;
      this.tabletemplatelist.projectName = "";
      this.tabletemplatelist.documentType = "";
      this.errorMsg = false
      this.errorMsgNew = false
    },
    async savetabletemplate() {

      if (this.id != "0" && this.id != 0) {
        this.edittabletemplate();
      }
      else {
        this.createtabletemplate();
      }
    },
    async createtabletemplate() {
      this.tabletemplatelist.documentType = this.reldtmp;
      this.tabletemplatelist.projectName = this.rlddoc;
      if (this.reldtmp == "" || this.rlddoc == "" || this.tabletemplatelist.tableList == "") {
        if (this.reldtmp == "") {
          this.errorMsg = true
        }
        else {
          this.errorMsg = false
        }
        if (this.rlddoc == "") {
          this.errorMsgNew = true
        }
        else {
          this.errorMsgNew = false
        }
        if (this.tabletemplatelist.tableList == "") {
          alert("No table field added on this template. Please add required fields")
          this.closesavemodall()

        }       
      }
      else {
        this.errorMsgNew = false
        this.errorMsg = false
        await axios
          .post(`${this.baseurl}document/projectdevdocumenttemplate/createtemplate`, this.tabletemplatelist,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            })
          .then((res) => {
            console.log(res);
            alert("Template created successfully");
            this.$router.push(`/doctemplatecreate/${res.data}`);

            // this.id=this.TempId;
            this.savetablemodal = false;
            this.tabletemplatelist.projectName = "";
            this.tabletemplatelist.documentType = "";
            // this.reloadtabletemplate(this.TempId);
            //this.$router.push(`/doctemplate`);
          })
          .catch((err) => {
            console.log(err);
            alert("Template type already exist")
          });
      }
    },
    async edittabletemplate() {
      this.tabletemplatelist.documentType = this.reldtmp;
      this.tabletemplatelist.projectName = this.rlddoc;
      if (this.reldtmp == "" || this.rlddoc == "" || this.tabletemplatelist.tableList == "") {
        if (this.reldtmp == "") {
          this.errorMsg = true
        }
        else {
          this.errorMsg = false
        }
        if (this.rlddoc == "") {
          this.errorMsgNew = true
        }
        else {
          this.errorMsgNew = false
        }
        if (this.tabletemplatelist.tableList == "") {
          alert("No table field added on this template. Please add required fields")
          this.closesavemodall()

        }       
      }
      else {
        this.errorMsgNew = false
        this.errorMsg = false
      await axios
        .put(`${this.baseurl}document/projectdevdocumenttemplate/update?Id=${this.id}`, this.tabletemplatelist,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          console.log(res)
          alert("Template Updated successfully")
          this.reloadtabletemplate()
          this.closesavemodall()
        })
        .catch((err) => {
          console.log(err)
        })
      }
    },
    async addrows(index) {
      this.tabletemplatelist.tableList[index].tableData.push("");

      // this.tabledata.tableData.push("");
    },
    async updatetable() {
      if (this.columnnamesarray.length == 0) {
        alert("Table cannot be saved without any columns");
      }
      else {
        this.tabledata.tableContents = this.columnnamesarray;
        this.tabletemplatelist.tableList[this.editindex] = { ...this.tabledata };
        this.tabledata = {
          "tableHeader": "",
          "tableContents": [],
          "tableData": []
        }
        this.editindex = null;
        this.closemodall();
      }
    },
    async savetable() {
      if (this.columnnamesarray.length == 0) {
        alert("Table cannot be saved without any columns");
      }
      else {
        this.tabledata.tableContents = this.columnnamesarray;
        this.tabletemplatelist.tableList.push({ ...this.tabledata });
        this.tabledata = {
          "tableHeader": "",
          "tableContents": [],
          "tableData": []
        }
        this.closemodall();
      }
    },
    async addcolumnnames() {
      this.columnnamesarray.push(this.columnnames);
      this.columnnames = "";
    },
    async removeoptions(index) {
      this.columnnamesarray.splice(index, 1);
    },
    async opentablemodal() {
      this.createtablemodal = true;
      this.columnnamesarray = [];
    },
    async closemodall() {
      this.tabledata = {
        "tableHeader": "",
        "tableContents": [],
        "tableData": []
      }
      this.edittablevar = false;
      this.columnnamesarray = [];
      this.createtablemodal = false;

    }


  }
}
