/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
<template>
  <div>
    <navbar />
  </div>
</template>

<script>
/* eslint-disable */
import navbar from "./common/navbar/navbar.vue";
export default {
  name: "App",
  components: {
    navbar,
  }
};
</script>
