<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0">Organization Management</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content">
      <div class="form-group row mb-3">
        <div class="col-12 col-md-5 my-1">
          <div class="form-group mb-md-0 pr-1">
            <label class="mb-0">Name</label>
            <input
              type="text"
              class="form-control my-md-0"
              v-model="search.name"
            />
          </div>
        </div>
        <div class="col-12 col-md-5 my-1 pr-1">
          <div class="form-group mb-md-0">
            <label class="mb-0">Status</label>
            <select class="form-select" v-model="search.status">
              <option value="" selected="">All</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-2 align-self-end my-1">
          <span
            type="button"
            class="submit_btn px-5 w-100"
            @click="OnSearchButtonClicked"
            >Search</span
          >
        </div>
      </div>
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="my-0 py-0 align-self-center">Organization</h5>
        <div
          class="position-relative align-self-center"
          aria-label="Create Designation"
          tabindex="0"
        >
          <img
            class="cursor-pointer"
            src="../../assets/img/create.png"
            alt="Create Designation"
            @click="GoToManageUser(0)"
          />
          <div class="tool-tip">Create Organization</div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-bordered dataTables">
          <thead>
            <tr>
              <th class="sort_block" @click="SortSelected('orgName', 1)" id="1">
                <div class="d-flex flex-row justify-content-between">
                  Name
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>

              <th
                class="sort_block"
                @click="SortSelected('primaryEmail', 2)"
                id="2"
              >
                <div class="d-flex flex-row justify-content-between">
                  Email
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>

              <th
                class="sort_block"
                @click="SortSelected('orgWebURL', 3)"
                id="3"
              >
                <div class="d-flex flex-row justify-content-between">
                  Web URL
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>
              <th
                class="sort_block"
                @click="SortSelected('primaryContactNo', 4)"
                id="3"
              >
                <div class="d-flex flex-row justify-content-between">
                  Contact Number
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>
              <th
                class="sort_block"
                @click="SortSelected('contactName', 5)"
                id="4"
              >
                <div class="d-flex flex-row justify-content-between">
                  Contact Name
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>

              <th class="text-center">Active</th>
              <th class="text-center">Edit</th>
              <th class="text-center">Delete</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="org in orgList" :key="org.Id">
              <td>{{ org.orgName }}</td>

              <td>{{ org.primaryEmail }}</td>
              <td>{{ org.orgWebURL }}</td>
              <td>{{ org.primaryContactNo }}</td>
              <td>{{ org.contactName }}</td>
              <!-- createdAt -->
              <td class="text-center">
                <input
                  v-model="org.active"
                  type="checkbox"
                  onclick="return false"
                />
              </td>
              <td class="text-center">
                <img
                  class="cursor-pointer"
                  src="../../assets/img/Edit.png"
                  alt="Edit"
                  @click="GoToManageUser(org.id)"
                />
              </td>

              <td class="text-center">
                <img
                  class="cursor-pointer"
                  src="../../assets/img/delete.png"
                  alt="Delete"
                  @click="DeleteClicked(org.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end px-0">
        <v-pagination
          v-model="currentPage"
          :pages="totalPage"
          :range-size="1"
          active-color="#ededed"
          @update:modelValue="loadPage"
        />
      </div>
    </div>
  </div>
</template>

<script src="./orglisting.js">
</script>

<style scoped>
.close {
  margin-top: "10px";
  color: #618bd7;
}
.edit-delete {
  color: #618bd7;
  font-size: 20px !important;
}
.table-bg {
  background-color: #e0e0e0;
}
.submit_btn {
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.position-relative img {
  width: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.headingunderline {
  text-decoration-line: underline;
}

.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}
.title-class1 {
  background-color: #618bd7;
  color: #fff;
}
.errorClass {
  color: red;
  font-size: 13px;
}
.button-class {
  float: right;
}
</style>
