import axios from "axios";
import store from "../../store/index";
import moment from "moment"
import VPagination from "@hennge/vue3-pagination";
import DigitalSignPopUp from "../DigitalSignPopUp/DigitalSignPopUp.vue";
export default {
	name: "dashboard",
	components:{
		DigitalSignPopUp,
		VPagination,
	},
	data() {
		return {
			designationPrivileges: "",
			approveTrainingPlan:[],
			sopList:[],
			signersList:[],
			testResultList:[],
			showdiv: false,
			showSignBlock:false,
			trainingId: "",
			pageIndex:1,
			pageSize:10,
			currentPage:1,
			totalPages:1,
			soppageIndex:1,
			soppageSize:10,
			sopcurrentPage:1,
			soptotalPages:1,
			signerpageIndex:1,
			signerpageSize:10,
			signertotalPages:1,
			signerCurrentPage:1,
			testResultpageIndex:1,
			testResultpageSize:10,
			testResultCurrentPage:1,
			testResulttotalPages:1,
			idtoken:"",
			baseUrl: `${process.env.VUE_APP_Service_URL}`,
		}

	},
	async mounted() {
		this.idtoken = store.getters.getToken
		this.Enablemenu()		
		// await this.getTrainingPlan()
		await this.getapproveTrainingPlan()
		await this.getSignersList()
		await this.sopDetails()
		await this.getTestResult()
	},
	methods: {		
		async Enablemenu() {
			await store.dispatch("setNavbar", true);
			await store.dispatch("setSideNavbar", true);
		},
		filter(data) {
			if (data) {
				return moment(data).format("DD-MM-YYYY")
			}
		},
		// async getTrainingPlan(){
		// 	await axios.get(`${this.baseUrl}document/projectdevdocumenttemplate/sopstobesigned?IsTrainerList=true`,
		// 		{
		// 			headers: {
		// 				Authorization: "Bearer " + this.idtoken,
		// 				"Content-Type": "application/json",
		// 			},
		// 		}
		// 		)
		// 		.then((res) => {
		// 			console.log(res)
				
					
		// 		})
		// 		.catch((err) => {
		// 			console.log(err);
		// 			alert("Network issue")
		// 		})                    
		// },
		async getapproveTrainingPlan(){
			await axios.get(`${this.baseUrl}document/trainingplan/gettraininglist?Tab=Approval&status=Planned&status=&plannedstartdate=&plannedenddate=&actualstartdate=&actualenddate=&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=CreatedAt&SortOrder=1`,
				{
					headers: {
						Authorization: "Bearer " + this.idtoken,
						"Content-Type": "application/json",
					},
				}
				)
				.then((res) => {
					this.approveTrainingPlan=res.data.entities
					this.totalPages = parseInt(res.data.totalCount / this.pageSize);
					if (res.data.totalCount % this.pageSize != 0) {
					this.totalPages = this.totalPages + 1;
					}
					
				})
				.catch((err) => {
					console.log(err);
				})                    
		},
		async sopDetails(){
			await axios.get(`${this.baseUrl}document/projectdevdocumenttemplate/sopstobesigned?isTrainerList=true&pageindex=${this.soppageIndex}&pagesize=${this.soppageSize}&SortProperty=CreatedAt&SortOrder=1`,
				{
					headers: {
						Authorization: "Bearer " + this.idtoken,
						"Content-Type": "application/json",
					},
				}
				)
				.then((res) => {
					this.sopList=res.data.entities
					this.soptotalPages = parseInt(res.data.totalCount / this.soppageSize);
					if (res.data.totalCount % this.soppageSize != 0) {
					this.soptotalPages = this.soptotalPages + 1;
					}
					
				})
				.catch((err) => {
					console.log(err);
				})                    
		},
		async getSignersList(){
			await axios.get(
				//`https://localhost:5001/document/getsignerdocumentlist?pageindex=${this.signerpageIndex}&pagesize=${this.signerpageSize}&SortProperty=CreatedAt&SortOrder=1`,
				`${this.baseUrl}document/document/getsignerdocumentlist?pageindex=${this.signerpageIndex}&pagesize=${this.signerpageSize}&SortProperty=CreatedAt&SortOrder=1`,
				{
					headers: {
						Authorization: "Bearer " + this.idtoken,
						"Content-Type": "application/json",
					},
				}
				)
				.then((res) => {
					this.signersList=res.data.entities
					this.signertotalPages = parseInt(res.data.totalCount / this.signerpageSize);
					if (res.data.totalCount % this.signerpageSize != 0) {
					this.signertotalPages = this.signertotalPages + 1;
					}
					
				})
				.catch((err) => {
					console.log(err);
				})                    
		},	
		async getTestResult(){
			await axios.get(`${this.baseUrl}document/documentquestion/gettestresult?userType=Supervisor&pageIndex=${this.testResultpageIndex}&PageSize=${this.testResultpageSize}&status=Completed&SortOrder=1&SortProperty=dateConductedOn`,
			{
				headers: {
					Authorization: "Bearer " + this.idtoken,
					"Content-Type": "application/json",
				},
			}
			)
			.then((res) => {
				this.testResultList=res.data.entities
				console.log("Signer list is",this.testResultList)
				this.testResulttotalPages = parseInt(res.data.totalCount / this.testResultpageSize);
				if (res.data.totalCount % this.testResultpageSize != 0) {
				this.testResulttotalPages = this.testResulttotalPages + 1;
				}
				
			})
			.catch((err) => {
				console.log(err);
			})  
		},
		openModal(trainingId) {
			this.showSignBlock = true
			store.dispatch("setFromPage", "approveTraining");
			this.trainingId = trainingId;
			},
		async viewSop(sopId,type) {
			store.dispatch("setFromPage","dashboard")
			this.$router.push("/sop_acknoledgment/" + sopId + "/" + type);
		},
		docview(id,vernum,versionId)
		{
			this.$router.push("/documentview/"+id+ "/" +vernum);
		},
		async ViewTest(configId, usersExamId) {
			this.$router.push("/myQuestion/" + configId + "/" + usersExamId);
		},
		async loadPage(page) {
		this.currentPage = page;
		this.pageIndex = page;
		this.getapproveTrainingPlan();
		},
		async loadSopPage(page) {
		this.sopcurrentPage = page;
		this.soppageIndex = page;
		this.sopDetails();
		},
		async loadSignerList(page) {
		this.signerCurrentPage = page;
		this.signerpageIndex = page;
		this.getSignersList();
		},
		async loadTestResultPage(page) {
		this.testResultcurrentPage = page;
		this.testResultpageIndex = page;
		this.getTestResult();
		}
}
}