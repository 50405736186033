import axios from "axios";
import store from "../../store/index";
import TreeMergeFile from "../TreeMergeFile/TreeMergeFile.vue";
export default {
  name: "MoveFolder",
  props: {
    treeId: {
      type: String,
      default: "",
    },
    folderBold: {
      type: String,
      default: "",
    },
    movableFolder: {
      type: String,
      default: "",
    },
  },
  components: {
    TreeMergeFile
  },
  data() {
    return {
      selectedProject: "",
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/`,
      validations: [false, false, false],
      departmentSelected: false,
      projectSelectedval: false,
      selectedtree: "",
      folderlist: [],
      deptlist: [],
      subFolders: [],
      selectedfoldername: "",
      selectedfolderid: "",
      mainfolderbold: "",
      idtoken: "",
      maindeptbold: "",
      folder: "",
      openfolder: "",
      selectedDepartment: "",
      folderName: "",
      cloneType: "",
      openfolderlist: "",
      folderList: [],
      folderLists: [],
      projectlist: [],
      location: "",
      newFolderName: ""

    };
  },
  watch: {
    "$store.getters.getDepartment": function (val) {
      if (val) {
        this.mainfolderbold = val;
        this.maindeptbold = val;
        this.getselecteddepartments(val);
        this.getalldepartmentfolders(val);
      } else {
        this.selectedfoldername = null;
      }
    },
    "$store.getters.getselectedFolder": function (val) {
      if (val) {
        //this.getFolderversion(val);
        this.mainfolderbold = val;
        this.currentselectedfolder = val;
        this.openfolder = val;
      } else {
        console.log("Null");
      }
    },
    "$store.getters.getSelectedProject": function (val) {
      if (val) {
        this.mainprojbold = val;
        console.log("THEE PROJJ ISSSS", val);
        this.getselectedprojects(val);
        this.getRootFolder(val);
      } else {
        this.selectedfoldername = null;
      }
    },
  },
  async mounted() {
    this.openfolderlist = store.getters.getopenfolder;
    console.log("Open Folder", this.openfolderlist)
    console.log("location", store.getters.getFromPage)
    this.mainfolderbold = this.folderBold;
    this.selectedDepartment = "";
    document.body.style.overflowY = "hidden";
    this.idtoken = store.getters.getToken;
    store.dispatch("setshowMoreBlock", false);
    this.selectedDepartment = this.treeId;
    this.folder = store.getters.getselectedFolder;
    this.openfolder = this.folderBold;
    await this.getName();
    this.getproject();
    //this.getdepartment();
    // this.getselecteddepartments(this.treeId);
    this.prevPath();
    //this.getalldepartmentfolders(this.treeId);
  },
  async unmounted() {
    store.dispatch("setshowMoreBlock", true);
    document.body.style.overflowY = "auto";
  },
  methods: {
    clickedOut(e) {
      if (e.relatedTarget.id === 'primaryTree') {
        this.mainfolderbold = "";
        store.dispatch("selectFolder", "");
        this.isusercanaddfolder = true;
      }
    },
    async getselectedprojects(id) {
      if (id != "") {
        await axios
          .get(`${this.mainapi}configuration/project/get?id=${id}`, {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            this.selectedfolderid = res.data.id;
            this.selectedfoldername = res.data.projectName;
          })
          .catch((err) => {
            console.log(err.response.data.messages);
          });
      }
    },
    async getallgeneralfolders() {
      await axios
        .get(
          `${this.mainapi}document/folder/getprivilegedroots?storageType=General`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.folderlist = res.data;
        })
        .catch((err) => {
          console.log(err.response.data.messages);
        });
    },
    async getproject() {
      await axios
        .get(
          `${this.baseurl}project/listproject?SortProperty=ProjectName&SortOrder=0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.projectlist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getRootFolders() {
      await axios
        .get(this.mainapi + `document/folder/getprivilegedroots?storageType=General`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        }
        )
        .then((res) => {
          this.folderList = res.data;

        })
        .catch((err) => {
          console.log(err.response.data.messages)
        })

    },
    async getRootFolder(id) {
      await axios    
        .get(this.mainapi + `document/folder/getprivilegedroots?storageId=${id}&storageType=Project`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        }
        )
        .then((res) => {
          this.folderLists = res.data;

        })
        .catch((err) => {
          console.log(err.response.data.messages)
        })

    },
    async getName() {
      await axios
        .get(`${this.mainapi}document/folder/get?Id=${this.folder}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.folderName = res.data.name;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkSubFolders() {
      await axios
        .get(
          `${this.mainapi}document/folder/getprivilegedchildren?Id=${this.targetFolder}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          res.data.forEach((item) => {
            if (item.id == this.folder) {
              this.validations[1] = true;
            }
            if (item.name == this.folderName) {
              this.validations[2] = true;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onDeptchange(event) {
      // this.departmentSelected = true;
      this.selectedtree = "department";
      this.mainfolderbold = event.target.value;
      // (this.LocalSelectedfile = ""),
      // (this.showFolderEmpty = false),
      // (this.openedFolderName = ""),
      // (this.selectedItem = null),
      // (this.documentlist = "");
      this.getselecteddepartments(event.target.value);
      this.getalldepartmentfolders(event.target.value);
    },
    async getselecteddepartments(id) {
      if (id != "") {
        await axios
          .get(
            `${this.mainapi}configuration/department/searchbyprivilege?id=${id}&PageSize=100`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if (res.data.entities[0].name) {
              console.log("selected folders are " + res);
              this.selectedfoldername = res.data.entities[0].name;
              this.selectedfolderid = res.data.entities[0].id;
            }
          })
          .catch((err) => {
            console.log(err.response.data.messages);
          });
      }
    },
    async prevPath() {
      if (store.getters.getFromPage == "Department") {
        this.getdepartment();
        this.getalldepartmentfolders(this.treeId);
        this.getselecteddepartments(this.treeId);   
      } else if (store.getters.getFromPage == "ProjectSummary") {
        this.getRootFolder();
        this.getdepartment();    
      } else if (store.getters.getFromPage == "generalFiles") {
        this.getdepartment();
        this.getRootFolders();     
      }
    },
    async getalldepartmentfolders(id) {
      if (id == null) {
        await axios
          .get(
            `${this.mainapi}document/folder/getprivilegedroots?storageType=General`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.folderlist = res.data;
          })
          .catch((err) => {
            console.log(err.response.data.messages);
          });
      }
      else {
        await axios
          .get(
            `${this.mainapi}document/folder/getprivilegedroots?storageId=${id}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.folderlist = res.data;
          })
          .catch((err) => {
            console.log(err.response.data.messages);
          });
      }

    },
    async getSubDepartments(id) {
      await axios.get(`${this.mainapi}configuration/department/getsubdepartments?departmentid=${id}`,
        {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.subDepartment = res.data.entities;
        })
        .catch((err) => {
          console.log("error in get subdepartment...", err)
        })
    },
    async backtorootfolder(id) {
      (this.mainfolderbold = ""), (this.currentselectedfolder = "");
      this.departmentSelected = true;
      store.dispatch("selectFoldermove", id);
      this.getalldepartmentfolders(id);
    },
    async save() {

      this.targetFolder = store.getters.getselectedFoldermove;
      if (this.cloneType == "" || this.cloneType == null) {
        alert("Please choose an action to clone");
      }
      else if (this.targetFolder == "" || this.targetFolder == null && this.location != 'General') {
        alert("Please select a folder");

      }
      else {
        this.validations[1] = false;
        this.validations[2] = false;
        if (this.location === 'Department') {
          let confirmMessage = confirm(
            "Are you sure you want to move the folder?"
          );
          if (confirmMessage) {
            await axios
              .get(
                `${this.mainapi}document/folder/copy?FolderId=${this.folder}&TargetId=${this.targetFolder}&TargetType=Department&CloneType=${this.cloneType}&NewName=${this.newFolderName}`,
                {

                  headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                alert("Folder cloned successfully");
                store.dispatch("reloadfolder", true);
                this.getalldepartmentfolders(this.selectedDepartment);
                this.getselecteddepartments(this.selectedDepartment);
                this.getalldepartmentfolders(this.selectedDepartment);
                this.validations[1] = false;
                this.$emit("closemodal");
              })
              .catch((err) => {         
                if (err.response.data == "Subfolder with same name exist in targeted folder") {
                  alert("The main folder contains the same folder");
                }
                if (err.response.data == "Subfolder with new name exist in targeted folder") {
                  alert("The folder with entered name exist in targeted folder");
                }
                if (err.response.data == "Cannot move folder inside itself") {
                  alert("The target and source folder is same");
                }        
              });        
          }
        } else {
          await this.checkSubFolders();
          if (this.selectedDepartment == "All") {
            this.selectedDepartment = "";
          } else if (this.selectedDepartment == "") {
            this.validations[0] = true;
          } else if (this.folder == this.targetFolder) {
            alert("The target and source folder is same");
          } else if (this.validations[1] == true) {
            alert("The main folder contains the same folder");
          } else if (this.validations[2] == true) {
            alert("The main folder contains the folder of the same name");
          } else {
            this.validations[0] = false;
            let confirmMessage = confirm(
              "Are you sure you want to move the folder?"
            );
            if (confirmMessage) {
              console.log("Api integrated");
              await axios
                .get(
                  `${this.mainapi}document/folder/copy?FolderId=${this.folder}&TargetId=${this.targetFolder}&TargetType=Department&CloneType=${this.cloneType}&NewName=${this.newFolderName}`,
                  {
                    headers: {
                      Authorization: "Bearer " + this.idtoken,
                      "Content-Type": "application/json",
                    },
                  }
                )
                .then((res) => {
                  alert("Folder cloned successfully");
                  store.dispatch("reloadfolder", true);
                  this.getalldepartmentfolders(this.selectedDepartment);
                  this.getselecteddepartments(this.selectedDepartment);
                  this.getalldepartmentfolders(this.selectedDepartment);
                  this.targetFolder = "";
                  this.validations[1] = false;
                  this.$emit("closemodal");
                })
                .catch((err) => {
                  console.log(err.response.data.messages);
                });
            } else {
              console.log("Move failed");
            }
          }
        }
      }
    },
    async saveinGenFiles() {
      this.targetFolder = store.getters.getselectedFoldermove;
      if (this.cloneType == "" || this.cloneType == null) {
        alert("Please choose an action to clone");
      }

      else {
        this.validations[1] = false;
        this.validations[2] = false;
        if (this.location === 'General') {
          let confirmMessage = confirm(
            "Are you sure you want to move the folder?"
          );
          if (confirmMessage) {
            console.log("Api integrated");
            await axios
              .get(
                `${this.mainapi}document/folder/copy?FolderId=${this.folder}&TargetId=${this.targetFolder}&TargetType=Folder&CloneType=${this.cloneType}&NewName=${this.newFolderName}`,
                {
                  headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                alert("Folder cloned successfully");
                store.dispatch("reloadfolder", true);
                this.getalldepartmentfolders(this.selectedDepartment);
                this.getselecteddepartments(this.selectedDepartment);
                this.getalldepartmentfolders(this.selectedDepartment);
                this.validations[1] = false;
                this.targetFolder = "";
                this.$emit("closemodal");
              })
              .catch((err) => {              
                if (err.response.data == "Subfolder with same name exist in targeted folder") {
                  alert("The main folder contains the same folder");
                }
                if (err.response.data == "Subfolder with new name exist in targeted folder") {
                  alert("The folder with entered name exist in targeted folder");
                }
                if (err.response.data == "Cannot move folder inside itself") {
                  alert("The target and source folder is same");
                }              
              });       
          }
        } else {
          await this.checkSubFolders();
          if (this.selectedDepartment == "All") {
            this.selectedDepartment = "";
          } else if (this.selectedDepartment == "") {
            this.validations[0] = true;
          } else if (this.folder == this.targetFolder) {
            alert("The target and source folder is same");
          } else if (this.validations[1] == true) {
            alert("The main folder contains the same folder");
          } else if (this.validations[2] == true) {
            alert("The main folder contains the folder of the same name");
          }
        }
      }
    },
    async saveInProject() {
      this.targetFolder = store.getters.getselectedFoldermove;
      if (this.cloneType == "" || this.cloneType == null) {
        alert("Please choose an action to clone");
      }
      if (this.targetFolder == "" || this.targetFolder == null && this.location != 'General') {
        alert("Please select a folder");
      }
      else if (this.cloneType == "" || this.cloneType == null) {
        alert("Please choose an action to clone");
      } else {
        //alert("folder is " + this.targetFolder)
        this.validations[1] = false;
        this.validations[2] = false;
        if (this.location === 'Projects') {
          let confirmMessage = confirm(
            "Are you sure you want to move the folder?"
          );
          if (confirmMessage) {
            console.log("Api integrated");
            await axios
              .get(
                `${this.mainapi}document/folder/copy?FolderId=${this.folder}&TargetId=${this.targetFolder}&TargetType=Project&CloneType=${this.cloneType}&NewName=${this.newFolderName}`,
                {
                  headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                alert("Folder cloned successfully");
                store.dispatch("reloadfolder", true);
                this.getalldepartmentfolders(this.selectedDepartment);
                this.getselecteddepartments(this.selectedDepartment);
                this.getalldepartmentfolders(this.selectedDepartment);
                this.validations[1] = false;
                this.targetFolder = "";
                this.$emit("closemodal");
              })
              .catch((err) => {
                if (err.response.data == "Subfolder with same name exist in targeted folder") {
                  alert("The main folder contains the same folder");
                }
                if (err.response.data == "Subfolder with new name exist in targeted folder") {
                  alert("The folder with entered name exist in targeted folder");
                }
                if (err.response.data == "Cannot move folder inside itself") {
                  alert("The target and source folder is same");
                }            
              });
            // alert(11);
          }
        } else {
          await this.checkSubFolders();
          if (this.selectedDepartment == "All") {
            this.selectedDepartment = "";
          } else if (this.selectedDepartment == "") {
            this.validations[0] = true;
          } else if (this.folder == this.targetFolder) {
            alert("The target and source folder is same");
          } else if (this.validations[1] == true) {
            alert("The main folder contains the same folder");
          } else if (this.validations[2] == true) {
            alert("The main folder contains the folder of the same name");
          }
        }
      }
    },
    onSelection(event) {
      this.selectedtree = "";
      if (this.location === 'Department') {
        this.getdepartment()
      }
      if (this.location === 'Projects') {
        this.getproject()
      }
      if (this.location === 'General') {
        this.getallgeneralfolders()
      }
      if (this.location != 'Projects') {
        this.selectedProject = "";
      }

      this.selectedDepartment = "";
      this.folderlist = "";
      this.selectedfolderid = "";
      this.selectedfoldername = "";
    },
    async getdepartment() {
      const orgId = store.getters.getOrganisationID;
      await axios
        .get(
          `${this.baseurl}department/searchbyprivilege?orgid=${orgId}&PageSize=10000`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.deptlist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onProjectchange(event) {
      this.selectedtree = "project";
      this.mainfolderbold = event.target.value;
      this.getselectedprojects(event.target.value);
      this.getRootFolder(event.target.value);
    },
    close() {
      console.log("Clicked")
      this.$emit("closemodal");
    },
  },
};
