/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect"
export default {
    name: "File_FolderPopup",
    components: {
        Multiselect

    },
    props: {

        list: {
            type: String,
            default: "",
        },
        source:
        {
            type: String,
            default: "",
        },
        projectId: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            roleList: [],
            permissionset: [],
            dataforsave: {
                "folderId": store.getters.getselectedFolder,
                "userPermission": []
            },
            userobject: {},
            multiuserobject: {},
            permissionList: [],
            selectedscreens: [],
            screensds: [],
            screenNames: [],
            controlName: [],
            baseApi: `${process.env.VUE_APP_Service_URL}`,
            baseurl: `${process.env.VUE_APP_Service_URL}document/folderfilepermission/`,
            baseapi: `${process.env.VUE_APP_Service_URL}configuration`,
            userList: [],
            // designationList: [],
            closemodal: false,
            selectedRole: [],
            Designation: [],
            selectedValue: [],
            folderPermissionList: [],
            permUserlist: [],
            permUser: "",
            showSave: false,
        }
    },
    watch: {
        "$store.getters.getselectedFolder": function (val) {
            this.docId = store.getters.getselectedFolder
        },
    },
    async mounted() {
        this.idtoken = store.getters.getToken
        await this.ListRoles();
    },
    methods: {
        async ListRoles() {
            this.projId = await store.getters.getSelectedProject,
                console.log("SourceType1", this.source)
            await axios
                .get(
                    `${this.baseApi}configuration/projectrole/getall?projectId=${this.projId}`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    this.roleList = res.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.roleList = []
                })
        },
        // async getalldesignation() {
        //     console.log("Sourcetype",this.source)
        //     await axios
        //         .get(`${this.baseapi}search?PageSize=10000&SortProperty=displayName`,
        //             {
        //                 headers: {
        //                     Authorization: "Bearer " + this.idtoken,
        //                     "Content-Type": "application/json",
        //                 },
        //             }
        //         )
        //         .then((res) => {
        //             this.designationList = res.data.entities;
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //         });
        // },
        async onChange(event) {
            this.showSave = true
            this.selectedRole = event;
            if (event.length != 0) {
                await this.getProjectRoleUsers(this.selectedRole);
                this.permissionset = [];
            }
            else {
                this.userList = []
            }
        },

        async folderSingleSave(id) {
            let savedata = {
                "folderId": this.list,
                "userPermission": this.userobject[id]
            };
            await axios
                .post(`${this.baseurl}singlesavefolder`, savedata
                    , {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.userListsss = res.data;
                    alert("Permission added successfully");
                    // this.close();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async fileSingleSave(id) {
            let savedata = {
                "documentId": this.list,
                "userPermission": this.userobject[id]
            };
            await axios
                .post(`${this.baseurl}singlesavefile`, savedata
                    , {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.userListsss = res.data;
                    alert("Permission added successfully");
                    //this.close();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async createPermission(id) {
            if (this.source == "File") {
                await this.fileSingleSave(id);
            }
            else {
                await this.folderSingleSave(id);

            }

        },

        async folderMultiSave() {
            let savedata = {
                "folderId": this.list,
                "userPermission": Object.values(this.userobject)
            };
            console.log("Multi folder save", savedata)
            if (savedata.userPermission.length != 0) {
                await axios
                    .post(`${this.baseurl}multiplesavefolder`, savedata
                        , {
                            headers: {
                                Authorization: "Bearer " + this.idtoken,
                                "Content-Type": "application/json",
                            },
                        })
                    .then((res) => {
                        this.userListsss = res.data;
                        alert("Permission added successfully");
                        this.close();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            else {
                this.close();
            }

        },
        // async compareAndSave(existingData, saveData) {
        //     const updatedUsers = [];

        //     // Loop through each user in saveData
        //     for (const user of saveData.userPermission) {
        //       // Find the matching user in existingData
        //       const existingUser = existingData.find(
        //         (u) => u.emailId === user.emailId && u.userId === u.userId
        //       );

        //       // If the user doesn't exist in existingData, check if the permission array is empty and skip to the next user
        //       if (!existingUser) {
        //         if (user.permission.length === 0) {
        //           continue;
        //         } else {
        //           // If the permission array is not empty, add the user to updatedUsers
        //           updatedUsers.push(user);
        //         }
        //       } else {
        //         // Compare the permissions
        //         const hasChanges = JSON.stringify(existingUser.permission) !== JSON.stringify(user.permission);

        //         // If there are changes, add the user data to updatedUsers
        //         if (hasChanges) {
        //           updatedUsers.push({
        //             emailId: user.emailId,
        //             userId: user.userId,
        //             permission: user.permission,
        //           });
        //         }
        //       }
        //     }
        //     console.log("updatedUsers",updatedUsers)
        //     return updatedUsers;
        //   },
        async fileMultiSave() {
            let savedata = {
                "documentId": this.list,
                "userPermission": Object.values(this.userobject)
            };
            console.log("Multi file save", savedata)
            if (savedata.userPermission.length != 0) {
                await axios
                    .post(`${this.baseurl}multiplesavefile`, savedata
                        , {
                            headers: {
                                Authorization: "Bearer " + this.idtoken,
                                "Content-Type": "application/json",
                            },
                        })
                    .then((res) => {
                        this.userListsss = res.data;
                        alert("Permission added successfully");
                        this.close();
                    })
                    .catch((err) => {
                        console.log(err);
                    });

            }

        },

        async createMultiPermission() {
            if (this.source == "File") {
                await this.fileMultiSave();
            }
            else {
                await this.folderMultiSave();
            }

        },
        async getProjectRoleUsers(value) {
            this.selectedValue = this.selectedRole;
            await axios
                .post(`${this.baseapi}/userprojectrole/getusersbyrole?projectId=${this.projId}&folderId=${this.list}`, this.selectedValue, {
                    headers: {
                        Authorization: "Bearer " + this.idtoken,
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    this.userList = res.data;
                    this.getallpermissions();
                    this.setusersinarray();
                    this.getpermissionbyId();

                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async clearall() {
            this.selectedscreens = [];
            document.getElementById("selectall").checked = false;
            this.screensds = [];
            await this.screens.forEach((element) => {
                document.getElementById(element.id).checked = false;
                let savescreen = {
                    name: "",
                    controls: [],
                };
                savescreen.name = element.name;
                this.screensds.push(savescreen);
            });
        },
        async setusersinarray() {
            this.userobject = [];
            this.userList.forEach((eachusers) => {
                let userinformations = {
                    "permissionType": "ProjectRole",
                    "permissionId": eachusers.roleId,
                    "userId": eachusers.userId,
                    "emailId": eachusers.emailId,
                    "name": eachusers.userName,
                    "permission": []
                };
                this.userobject[eachusers.userId] = userinformations;

            });
        },
        async saveallfun() {
            const usersaveValues = Object.values(this.userobject);
        },
        async setcontrols(elements) {
            let checkBox = document.getElementById(elements.userId);
            if (checkBox.checked == true) {
                this.userobject[elements.userId].permission = this.permissionset;
                console.log("permmm", this.permissionset)

            } else {
                if (elements.userId in this.userobject) {
                    this.userobject[elements.userId].permission = [];
                }
            }
        },
        async setpermissions(permission) {
            let permissioncheckBox = document.getElementById(permission);
            if (permissioncheckBox.checked == true) {
                this.userList.forEach((users) => {
                    if (!this.userobject[users.userId].permission.includes(permission)) {
                        this.userobject[users.userId].permission.push(permission);
                    }
                })
            } else {
                this.userList.forEach((users) => {
                    if (this.userobject[users.userId].permission.includes(permission)) {
                        var index = this.userobject[users.userId].permission.indexOf(permission);
                        if (index !== -1) {
                            this.userobject[users.userId].permission.splice(index, 1);
                        }
                    }
                })
            }
        },
        async selectall() {
            let checkBoxall = document.getElementById("selectall");
            if (checkBoxall.checked == true) {
                this.userList.forEach((users) => {
                    this.userobject[users.userId].permission = this.permissionset;
                })
            } else {
                this.userList.forEach((users) => {
                    this.userobject[users.userId].permission = [];
                })
            }
        },

        async getAllFolderPermission() {
            await axios
                .get(`${this.baseurl}allfolderpermission`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.permissionList = res.data;

                    this.setofpermissions();
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async getAllFilePermission() {
            await axios
                .get(`${this.baseurl}allfilepermission`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.permissionList = res.data;

                    this.setofpermissions();
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async getallpermissions() {
            if (this.source == "File") {
                await this.getAllFilePermission();
            }
            else {
                await this.getAllFolderPermission();
            }

        },

        setofpermissions() {
            this.permissionList.forEach((permissions) => {
                this.permissionset.push(permissions.permission);
            })
        },
        selectuserwithpermission(userid) {
            let checkBox = "";
            checkBox = document.getElementById(userid);
            if ((this.userobject[userid].permission).length != 0) {
                checkBox.checked = true;
            }
        },
        async getFolderPermissionbyId() {
            await axios
                .post(`${this.baseurl}getfolderpermissions?FolderId=${this.list}`, this.selectedValue,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.folderPermissionList = res.data;
                    this.retrievePermission();
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async getFilePermissionbyId() {
            await axios
                .post(`${this.baseurl}getfilepermission?documentId=${this.list}`, this.selectedValue,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.folderPermissionList = res.data;
                    this.retrievePermission();
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async getpermissionbyId() {
            if (this.source == "File") {
                await this.getFilePermissionbyId();
            }
            else {
                await this.getFolderPermissionbyId();
            }

        },

        async retrievePermission() {
            await this.folderPermissionList.forEach((element) => {
                this.userobject[element.userId].permission = element.permission;
            });
        },

        close() {
            this.$emit('closemodal')
            store.dispatch("setfileType", '')
        }
    }
}
