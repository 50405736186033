import axios from "axios";
import store from "../../store/index";

export default {
	name: "administration",
	data() {
		return {
			designationPrivileges: "",
			showdiv: false
		};

	},
	mounted() {
		localStorage.setItem("currenttab", '');
		this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
		this.Enablemenu();
		console.log("from dashboard", this.designationPrivileges);
		if (this.designationPrivileges == 'null,' || this.designationPrivileges == null || this.designationPrivileges === '') {
			this.showdiv = true
		}
		else {
			this.showdiv = false
		}
	},
	methods: {
		async Enablemenu() {
			await store.dispatch("setNavbar", true);
			await store.dispatch("setSideNavbar", true);
		},
		gotoUrl(value) {
			if (value == 'projects') {
				this.$router.push("./projectList")
			}
			if(value == 'user')			
			{
				this.$router.push("./userlisting")
			}
			if(value == 'des')			
			{
				this.$router.push("./designation")
			}
			if(value == 'documenttype')			
			{
				this.$router.push("./documenttypelist")
			}
			if(value == 'documenttemplate')			
			{
				this.$router.push("./doctemplate")
			}
			if(value == 'screens')			
			{
				this.$router.push("./screen")
			}

		},
		
    }
}