<template>
  <!-- <div>
        <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="my-0 py-0 align-self-center">Trainee SOPs</h5>
       </div>
    
  </div> -->
  <!--OLd dta ends -->
    <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2>Training Acknowledgement</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i
        class="fa fa-times close cursor-pointer"
        @click="goBack"
      ></i>
    </div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="tabs-container">
          <ul class="nav nav-tabs" role="tablist">
            <li>
              <a class="nav-link active" data-toggle="tab" href="#tab-1">
                My Training
              </a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" href="#tab-2">Verification</a>
            </li>
            <li>
              <a class="nav-link" @click="Listinitiatorsopdocs()" data-toggle="tab" href="#tab-3">Assign Training</a>
            </li>
          </ul>
          <div class="tab-content">
            <div role="tabpanel" id="tab-1" class="tab-pane active">
              <div class="ibox-content">
                <div class="form-group row">
                  <div class="col-lg-12 my-2">
                    
                    <div class="table-responsive">
        <table class="table table-striped table-bordered dataTables">
          <thead>
            <tr>
              <th
                class="sort_block"
                id="1"
              >
                <div class="d-flex flex-row justify-content-between">
                  Training Name
                  <!-- <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span> -->
                </div>
              </th>

              <th
                class="sort_block"
                id="2"
              >
                <div class="d-flex flex-row justify-content-between">
                  Trainer Name
                  
                </div>
              </th>
              <th>

                                <div class="d-flex flex-row justify-content-between">

                                    Start Date

                                </div>

                            </th>

                            <th>

                                <div class="d-flex flex-row justify-content-between">

                                    End Date

                                </div>

                            </th>
              <th
                class="sort_block"
                id="3"
              >
                <div class="d-flex flex-row justify-content-between">
                  Status
                 
                </div>
              </th>

              <th class="text-center">View</th>
             
            </tr>
          </thead>

          <tbody>
            <tr v-for="doc in soptraineeList" :key="doc.Id">
              <td>
                {{ doc.trainingName}}
              </td>

              <td>{{ doc.trainerName }}</td>
              <td>{{ filter(doc.actualStartDate) }}</td>
              
              <td>{{ filter(doc.actualEndDate) }}</td>
              
              <td>
                {{doc.status}}
              </td>
              <td class="text-center">
                <i class="fa fa-eye edit-delete" @click="GotoTraineeUI(doc.id,doc.status)"></i>
              </td>
            </tr>
          </tbody>
        </table>
                    </div>
                    <div class="col-md-12 d-flex justify-content-md-end px-0">
        <v-pagination
          v-model="traineecurrentPage"
          :pages="traineetotalPage"
          :range-size="1"
          active-color="#ededed"
          @update:modelValue="loadPage"
        />
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
            <div role="tabpanel" id="tab-2" class="tab-pane"> 
             
                <div class="ibox-content">
                  <div class="form-group row">
                  <div class="col-lg-12 my-2">
                 <div class="table-responsive">

                <table class="table table-striped table-bordered dataTables">

                    <thead>

                        <tr>

 

                            <th class="sort_block" id="1">

                                <div class="d-flex flex-row justify-content-between">

                                    Training Name

                                    <!-- <span>

                                        <i class="fa fa-long-arrow-up"></i>

                                        <i class="fa fa-long-arrow-down"></i>

                                    </span> -->

                                </div>

                            </th>

                            <th>

                                <div class="d-flex flex-row justify-content-between">

                                    Trainee Name

                                </div>

                            </th>

                            <th>

                                <div class="d-flex flex-row justify-content-between">

                                    Start Date

                                </div>

                            </th>

                            <th>

                                <div class="d-flex flex-row justify-content-between">

                                    End Date

                                </div>

                            </th>

                            <th>

                                <div class="d-flex flex-row justify-content-between">

                                    Status

                                </div>

                            </th>

                            <th class="text-center">View</th>

                        </tr>

                    </thead>

                    <tbody>

                        <tr v-for="sop in sopList" :key="sop.Id">

                            <td>

 

                                {{ sop.trainingName }}

                            </td>

                            <td>

                                {{ sop.traineeName }}

                            </td>

                            <td>

                                {{ filter(sop.actualStartDate) }}

                            </td>

                            <td>

                                {{ filter(sop.actualEndDate) }}

                            </td>

                            <td>

                                {{ sop.status }}

                            </td>

                            <td class="text-center">

                                <i class="fa fa-eye edit-delete"  @click="GotoVerifierUI(sop.id,sop.status)"></i>

                            </td>

                        </tr>

                    </tbody>

                </table>

            </div>

            <div class="col-md-12 d-flex justify-content-md-end px-0">

                <v-pagination v-model="currentPage" :pages="totalPages" :range-size="1" active-color="#ededed"

                    @update:modelValue="loadPages" />
            </div> </div>
            </div>
                </div>
            
            </div> 
            <div role="tabpanel" id="tab-3" class="tab-pane">
              <div class="ibox-content">
                <div class="form-group row">
                  <div class="col-lg-12 my-2">
                    
                    <div class="table-responsive">
           <table class="table table-striped table-bordered dataTables">
           <thead>
            <tr>
              <th
                class="sort_block"
                id="1"
              >
                <div class="d-flex flex-row justify-content-between">
                  Training Name
                  <!-- <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span> -->
                </div>
              </th>
              <th>
              <div class="d-flex flex-row justify-content-between">

                                    Trainee Name

                                </div>
              </th>
              <th
                class="sort_block"
                id="2"
              >
                <div class="d-flex flex-row justify-content-between">
                  Trainer Name
                  
                </div>
              </th>
              <th>

                                <div class="d-flex flex-row justify-content-between">

                                    Start Date

                                </div>

                            </th>

                            <th>

                                <div class="d-flex flex-row justify-content-between">

                                    End Date

                                </div>

                            </th>
              <th
                class="sort_block"
                id="3"
              >
                <div class="d-flex flex-row justify-content-between">
                  Status
                 
                </div>
              </th>

              <th class="text-center">View</th>
             
            </tr>
          </thead>

          <tbody>
            <tr v-for="idoc in sopinitiatorList" :key="idoc.Id">
              <td>
                {{ idoc.trainingName}}
              </td>
              <td>{{ idoc.traineeName }}</td>
              <td>{{ idoc.trainerName }}</td>
              <td>{{ filter(idoc.actualStartDate) }}</td>
              
              <td>{{ filter(idoc.actualEndDate) }}</td>
              
              <td>
                {{idoc.status}}
              </td>
              <td class="text-center">
                <i class="fa fa-eye edit-delete" @click="GotoInitiatorUI(idoc.id,idoc.status)"></i>
              </td>
            </tr>
          </tbody>
        </table>
                    </div>
                    <div class="col-md-12 d-flex justify-content-md-end px-0">

<v-pagination v-model="initiatorcurrentPage" :pages="initiatortotalPage" :range-size="1" active-color="#ededed"

    @update:modelValue="initiatorloadPages" />
</div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./soplibrary.js"></script>
<style scoped>
@import "../../assets/css/savepages.css";

.fa-asterisk {
  color: #e56363 !important;
  font-size: 6px;
}

.errorClass {
  color: red;
  font-size: 15px;
}

.Image-user-Auth-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.modal-header-department {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.errmsg {
  font-size: 12px;
  color: red;
  float: left;
}

.width {
  width: min(400px, 90%);
}

.info {
  width: 14px;
  margin-left: 4px;
  height: 14px;
}

.create-user-mod {
  position: absolute;
  width: 350px;
  top: -114px;
  left: 9px;
}
</style>