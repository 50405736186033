<template>
    <div class="side-block py-2" id="side-block">
        <slot>
        </slot>
    </div>
</template>
<script src="./side_pop_up.js">

</script>
<style>
.side-block:empty {
    display: none;
}

.side-block {
    display: none;
    position: fixed;
    text-align: left;
    border-radius: 5px;
    border: 1px solid #e7eaec;
    background-color: rgba(255, 255, 255, 1);
    width: min(150px, 90%);
    -moz-box-shadow: 3px 3px 3px rgba(160, 160, 160, .5);
    -webkit-box-shadow: 3px 3px 3px rgba(160, 160, 160, .5);
    box-shadow: 3px 3px 3px rgba(160, 160, 160, .5);
    z-index: 200;
}

.side-block>div {
    cursor: pointer;
    padding: 0.5rem;
}

.side-block>div:hover {
    background-color: #e4eaf9
}
</style>