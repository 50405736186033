<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-0 col-12 col-md-10">
      <h2 class="my-0 py-0">Create Organization</h2>
    </div>
    <div class="col-2 col-md-2">
      <i class="fa fa-times cursor-pointer close" @click="onclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-3 px-3 py-2">
      <div class="ibox-body p-3">
        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-form-label"
              >Organization Name <sup><i class="fa fa-asterisk imp"></i></sup
            ></label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter organization name"
              maxlength="100"
              v-model="UserDto.orgName"
            />
            <span class="errormsg" v-if="errors[0] == false"
              >Enter a valid organization name</span
            >
          </div>
          <div class="col-md-4">
            <label class="col-form-label"> Type </label>
            <div>
              <select
                class="form-select"
                name="account"
                v-model="UserDto.orgType"
              >
                <option selected value="">Select</option>
                >
                <option value="Public">Public</option>
                <option value="Private">Private</option>
                <option value="limited by guarantee">
                  limited by guarantee
                </option>
                <option value="Unlimited (either public or private)">
                  Unlimited (either public or private)
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <label class="col-sm-12 col-form-label">
              Registration Number
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter registration number "
              maxlength="30"
              v-model="UserDto.orgRegNum"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-form-label">Code </label
            ><input
              type="text"
              class="form-control"
              placeholder="Enter organization code "
              maxlength="30"
              v-model="UserDto.orgCode"
            />
          </div>
          <div class="col-md-4">
            <label class="col-form-label"
              >Primary Email ID
              <sup><i class="fa fa-asterisk imp"></i></sup> </label
            ><input
              type="text"
              class="form-control"
              placeholder="Enter your primary email id"
              maxlength="100"
              v-model="UserDto.primaryEmail"
            />
            <span class="errormsg" v-if="errors[1] == false">
              Enter valid email id</span
            >
          </div>
          <div class="col-md-4">
            <label class="col-form-label">Secondary Email ID </label
            ><input
              type="text"
              class="form-control"
              placeholder="Enter your secondary email id"
              maxlength="30"
              v-model="UserDto.secondaryEmail"
            />
            <span class="errormsg" v-if="errors[3] == false"
              >Enter valid email id
            </span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-form-label">Main Contact Designation </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your designation"
              maxlength="30"
              v-model="UserDto.mainContactDesg"
            />
          </div>
          <div class="col-md-4">
            <label class="col-form-label"
              >Primary Contact Number
              <!-- <sup><i class="fa fa-asterisk imp"></i></sup>  --> </label
            ><input
              type="tel"
              class="form-control"
              placeholder="Enter your contact number"
              v-model="UserDto.primaryContactNo"
              maxlength="10"
              @keypress="isNumber($event)"
            />
          </div>
          <div class="col-md-4">
            <label class="col-form-label">Secondary Contact Number </label
            ><input
              type="text"
              class="form-control"
              placeholder="Enter your secondary contact number"
              v-model="UserDto.secondaryContactNo"
              maxlength="10"
              @keypress="isNumber($event)"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-12 col-form-label">Address </label>
          <div class="col-sm-4">
            <input
              type="text"
              placeholder="Floor / Apartment / House Number"
              maxlength="30"
              class="form-control"
              v-model="UserDto.addressFloor"
            />
          </div>
          <div class="col-sm-4">
            <input
              type="text"
              placeholder="Street Name / Locality"
              maxlength="30"
              class="form-control"
              v-model="UserDto.addressStreet"
            />
          </div>
          <div class="col-sm-4">
            <input
              type="text"
              placeholder="City"
              maxlength="30"
              class="form-control"
              v-model="UserDto.city"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-12 col-form-label"></label>
          <div class="col-sm-4">
            <input
              type="text"
              placeholder="State"
              maxlength="30"
              class="form-control"
              v-model="UserDto.states"
              @keypress="isLetter($event)"
            />
          </div>
          <div class="col-sm-4">
            <input
              type="text"
              placeholder="Country"
              maxlength="30"
              class="form-control"
              v-model="UserDto.country"
              @keypress="isLetter($event)"
            />
          </div>
          <div class="col-sm-4">
            <input
              type="text"
              placeholder="Postal Code"
              maxlength="6"
              class="form-control"
              v-model="UserDto.postalCode"
              @keypress="isNumber($event)"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-form-label"
              >Website URL<sup><i class="fa fa-asterisk imp"></i></sup>
            </label>
            <input
              type="url"
              class="form-control"
              placeholder="Enter organization website url"
              maxlength="30"
              v-model="UserDto.orgWebURL"
            />
            <span class="errormsg" v-if="errors[2] == false"
              >Enter valid website URL
            </span>
          </div>
          <div class="col-md-4">
            <label class="col-form-label">Contact Name </label
            ><input
              type="text"
              class="form-control"
              placeholder="Enter contact name"
              maxlength="30"
              v-model="UserDto.contactName"
              @keypress="isLetter($event)"
            />
          </div>
          <div class="col-md-4">
            <label class="col-form-label">Logo </label>
            <div class="input-group">
              <input
                accept=".jpg,.jpeg,.png,"
                id="InPutFile"
                type="file"
                class="form-control my-2 my-md-0"
                @change="previewFiles"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6 my-1 d-flex">
            <div class="input-group date">
              <div class="i-checks aligncheckbox">
                <label class="mr-2">Active</label>
                <input type="checkbox" value="" v-model="UserDto.active" />
              </div>
            </div>
          </div>
          <div class="col-md-6 my-1 d-flex justify-content-md-end">
            <button
              :disabled="IsUpdate"
              class="btn btn-primary cancel_btn py-2 px-5 mx-3"
              @click.prevent="Clear"
            >
              Clear
            </button>
            <button
              class="btn btn-primary py-2 px-5"
              type="submit"
              @click.prevent="SaveButtonClicked"
            >
              Save
            </button>
          </div>
        </div>
      </div>      
    </div>
  </div>
</template>
<script src="./create_organization.js"></script>
<style scoped>
.submit_btn {
  align-self: center;
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
.errorClass {
  color: red;
  font-size: 13px;
}
.fa-asterisk {
  color: #ff0000;
}
</style>