<template>
  <div class="modal__upload__wrapper">
    <div class="modal-dialog modal-dialog-width">
      <div class="modal-content">
        <div class="modal-header-document d-flex justify-content-bertween p-3">
          <h5 class="my-0 align-self-center">Add document</h5>
          <span
            type="button"
            class="align-self-center"
            data-dismiss="modal"
            @click="onclosed('uploadDoc')"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body modal-overflow" id="scrollable">
          <div class="row">
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="document title"
                >Document Title</label
              >
              <input
              placeholder="Enter document title"
                type="text"
                id="document-title"
                v-model="docTitle"
                class="form-control"
                maxlength="75"
              />
              
            </div>
            <div class="col-12 col-md-6 pl-md-1 mt-2">
              <label class="mb-0 mr-1" for="standard">Select Standard</label>

              <select
              
                id="standard"
                v-model="docuplooadfields.Standard"
                class="form-select form-select-lg"
                aria-label=".form-select-lg example"
              ><option disabled selected value="">Select a standard</option>
                <option
                  v-for="standard in standards"
                  :key="standard.id"
                  :value="standard.id"
                >
                  {{ standard.name }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="owner">Select Owner</label>
              <select
                id="owner"
                v-model="docuplooadfields.Owner"
                class="mr-3 form-select form-select-lg"
                aria-label=".form-select-lg example"
              ><option disabled selected value="">Select an owner</option>
                <option
                  v-for="users in userList"
                  :key="users.id"
                  :value="users.name"
                >
                  {{ users.name }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-6 pl-md-1 mt-2">
              <label class="mb-0 mr-1" for="category">Select Category</label>
              <select
                id="category"
                v-model="docuplooadfields.Category"
                class="form-select form-select-lg"
                aria-label=".form-select-lg example"
              ><option disabled selected value="">Select a category</option>
                <option
                  v-for="category in categorylist"
                  :key="category.id"
                  :value="category.category"
                >
                  {{ category.category }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-6 pr-md-1 mt-2" >
                <label class="mb-0 mr-1" for="department">Department</label>
                <select
                  id="department"
                  class="form-control"
                  v-model="docuplooadfields.department"
                >
                <option disabled selected value="">Select a department</option>
                  <option
                    v-for="department in departmentlist"
                    :key="department.id"
                    :value="department.name"
                  >
                    {{ department.name }}
                  </option>
                </select>
            </div>
            <div
              class="col-12 col-md-6 mt-2 pl-md-1"
            >
              <label class="mb-0 mr-1" for="document type">Document Type</label>
              <!-- <span aria-label="mandatory field" class="error-block">*</span> -->
              <select
                id="document type"
                v-model="docuplooadfields.documenttype"
                class="form-select form-select-lg"
                aria-label=".form-select-lg example"
                @change="bookmarklistfun"
              ><option disabled selected value="">Select a type</option>
                <option
                  v-for="doctype in doctypelist"
                  :key="doctype.id"
                  :value="doctype.id"
                >
                  {{ doctype.documentType }}
                </option>
              </select>
              <!-- <span class="d-block errorClass" v-if="validationmsgs[6] == true">
                Please select document type
              </span> -->
            </div>
            <div
              class="col-12 col-md-6 mt-2 pr-md-1"
            >
              <label class="mb-0" id="version">Version</label>
              <input
              placeholder="Enter version"
                type="text"
                for="version"
                v-model="this.docversion"
                class="form-control"
                maxlength="10"
              />
            </div>
            <div
              class="col-12 col-md-6 mt-2 pl-md-1"
            >
              <label class="mb-0" id="expiry-date">Expiry Date</label>
              <input
              placeholder="Select a date "
                for="expiry-date"
                type="text"
                v-model="docuplooadfields.expiarydate"
                class="form-control"
                onfocus="(type='date')"
                onblur="(type='text')"
                maxlength="30"
              />
              <span class="d-block errorClass" v-if="validationmsgs[1] == true">
                Please enter a future date
              </span>
            </div>
            <div class="col-12 mt-2">
              <label class="mb-0" id="description">Description</label>
              <textarea
              placeholder="Enter description"
                id="description"
                class="form-control"
                v-model="docuplooadfields.description"
                maxlength="250"
              ></textarea>
            </div>
            <div class="col-12 mt-2">
              <label class="mb-0" id="changesHistory">Changes history</label>
              <textarea
              placeholder="Enter changes history"
                id="changesHistory"
                class="form-control"
                v-model="docuplooadfields.changesHistory"
                maxlength="250"
              ></textarea>
            </div>
            <div
              v-if="docuplooadfields.documenttype.trim() != ''"
              class="col-12 mt-2 form-check"
            >
              <input
                class="form-check-input"
                v-model="addsigner"
                type="checkbox"
                @change="createbookmarkuserarray()"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label ml-4" for="flexCheckDefault">
                Do you need to add signer ?
              </label>
            </div>
            <div class="col-12 mt-2" v-if="addsigner">
              <table class="table table-striped table-bordered dataTables">
                <thead>
                  <tr>
                    <th class="sort_block" id="1">
                      <div class="d-flex flex-row justify-content-between">
                        Signing order
                      </div>
                    </th>
                    <th class="sort_block" id="1">
                      <div class="d-flex flex-row justify-content-between">
                        Bookmark/Role
                      </div>
                    </th>
                    <th class="sort_block" id="1">
                      <div class="d-flex flex-row justify-content-between">
                        User
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(bookmarks, index) in bookmarklist"
                    :key="bookmarks"
                  >
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>
                      {{ bookmarklist[index].bookmarkName }}
                    </td>
                    <td>
                      <select
                        id="user"
                        class="mr-3 form-select form-select-lg"
                        aria-label=".form-select-lg example"
                        :disabled="
                          index != 0 &&
                          bokkmarkusersarray[index - 1].userId == ''
                        "
                        @change="assignbookmarkuserfun($event, index)"
                      >
                        <option value="" selected disabled>
                          --Select a User--
                        </option>
                        <option
                          v-for="users in signuserList"
                          :value="
                            users.id + ',' + users.email + ',' + users.name
                          "
                          :key="users.id"
                        >
                          {{ users.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 mt-2">
              <input
                accept=".xls,.xlsx,.jpg,.jpeg,.png,.pdf,.docx,.doc"
                id="InPutFile"
                type="file"
                class="form-control unvisible my-2 my-md-0"
                @change="previewFiles"
                multiple
              />
              <div
                class="input-group"
                v-for="(eachfiles, index) in list"
                :key="eachfiles"
              >
                <div class="listfile breakWord">
                  <a>
                    {{ eachfiles.name }}
                    <i
                      class="fa fa-close"
                      @click.prevent="removefile(index)"
                    ></i
                  ></a>
                </div>
              </div>
              <span class="errorClass" v-if="validationmsgs[2] == true">
                Please upload a file
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-2">
            <button
              type="button"
              class="btn browser-btn btn-xs px-4 float-right mr-3"
              @click="loadfile()"
            >
              Browse
            </button>
            <button
              type="button"
              class="btn btn-primary btn-xs px-4 float-right"
              @click="submitFile()"
              :class="{'btn-disabled':loadingBlock}"
              :disabled="loadingBlock"
            >
            <span v-if="loadingBlock"><img
                src="../../assets/img/loading.webp"
                width="15"
                class="loading-img"
                alt="Login buffering"
            /></span>
            <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import { onBeforeMount } from "@vue/runtime-core";
import jwt_decode from "jwt-decode";

export default {
  name: "DocumentPopup",
  props: {
    department: {
      type: String,
      default: "",
    },
    folderid: {
      type: String,
      default: "",
    },
    versionbyfolder: {
      type: String,
      default: "",
    },
    fromPage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentuser:"",
      docTitle:"",
      addsigner: false,
      bookmarklist: [],
      signuserList: [],
      list: [],
      lastversion: "",
      docversion: "",
      alluserList: [],
      designationList: [],
      selectedDesignation: [],
      selectedUser: [],
      selectedPermission: [],
      permissionList: [],
      UserpermissionList: [],
      newUserList: [],
      uploadedDocId: "",
      docmsg: true,
      standards: [],
      categorylist: [],
      validationmsgs: [false, false, false, false, false, false, false],
      userList: "",
      idtoken: "",
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/`,
      baseapi: `${process.env.VUE_APP_Service_URL}document/folderfilepermission/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      values: {
        Approver: [],
        storageId: "",
        documentDescription: "",
      },
      approverlist: "",
      uploadpopupmodal: true,
      validations: [],
      doclist: [],
      azuredata: {
        DocumentId: "",
        DocumentVersionId: "",
        DocumentTypeName: "",
        DocumentTitle: "",
        DocumentDescription: "",
        Document: [],
        OrganizationId: 2,
        Approver: [],
      },
      SearchData: {
        DocName: "",
        FolderId: "",
        DocsearchName: "",
        CreatedDate: "",
        UploadedBy: "",
        DocumentTypeId: "",
        pageNo: 1,
        pagesize: 10,
      },
      documentVersionId: [],
      approveList: [],
      totalPage: 1,
      currentPage: 1,
      totalCount: 0,
      pageindex: 1,
      pagesize: 10,
      Document: "",
      SortProperty: "CreatedAt",
      SortOrder: 1,
      doctypelist: [],
      docuplooadfields: {
        folderid: "",
        title: "",
        description: "",
        Owner: "",
        department: "",
        Category: "",
        Standard: "",
        documenttype: "",
        version: "",
        expiarydate: "",
        formData: "",
        changesHistory: "",
      },
      bokkmarkusersarray: [],
      bookmarkselectedusers: [],
      departmentlist :[],
      loadingBlock:false
    };
  },
  components: {
    Multiselect,
  },
  async beforeMount() {
    if(this.fromPage == "project"){
      await this.getprojectusers();
    }
    else{
      await this.getusers();
    }
    await this.getcategory();
    await this.getstandard();
    await this.gettypelist();
  },

  async mounted() {
    await this.getsignusers();
    await this.getfilepermissions();
    await this.getalldesignation();
    await this.getDesignationUsers();
    await this.getdepartment();
    document.body.style.overflowY = "hidden";
    if (this.editorupdate == "edit") {
      this.calldetails();
    }
    this.lastversion = this.versionbyfolder;
    this.docversion = this.versionbyfolder;
    this.docuplooadfields.department = this.department;
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    //this.getreviewers();
  },
  async unmounted() {
    document.body.style.overflowY = "auto";
  },
  methods: {
    async setcurentuser(data) {
		const idtoken = store.getters.getToken;
		const userid = (jwt_decode(idtoken).user_id).trim();
		const userObject = data.find(item => item.userId === userid);
    console.log("current user isssss", userObject.name);
    this.currentuser = userObject.name;
    },
    async assignbookmarkuserfun(event, index) {
      let eachbookuserval = [];
      eachbookuserval = event.target.value.split(",");
      console.log(event.target.value);
      this.bokkmarkusersarray[index].userId = eachbookuserval[0];
      this.bokkmarkusersarray[index].userEmail = eachbookuserval[1];
      this.bokkmarkusersarray[index].userName = eachbookuserval[2];
      console.log("bokkmarkusersarray", this.bokkmarkusersarray);
    },
    async bookmarklistfun(event) {
      const idtoken = store.getters.getToken;
      console.log("bookmarklist  isssss", event.target.value);
      const selectedValue = event.target.value;
      await axios
        .get(
          `${this.mainapi}document/bookmark/getbookmarksbydoctype?id=${selectedValue}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("bookmarklist  isssss", res.data);
          this.bookmarklist = res.data;
          this.createbookmarkuserarray();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async createbookmarkuserarray() {
      this.bokkmarkusersarray = [];
      this.bookmarklist.forEach((eachbookmark) => {
        this.bookmarkselectedusers.push("");
        let samplebookmarkuser = {
          bookmark: eachbookmark.bookmarkName,
          nullable: true,
          signOrder: eachbookmark.sortOrder,
          nullable: true,
          userId: "",
          nullable: true,
          userEmail: "",
          nullable: true,
          date: null,
          signId: "",
          nullable: true,
          reason: "",
          nullable: true,
          userName: "",
          nullable: true,
        };
        this.bokkmarkusersarray.push(samplebookmarkuser);
      });
      console.log("bokkmarkusersarray", this.bokkmarkusersarray);
    },
    async loadfile() {
      let fileload = document.getElementById("InPutFile");
      fileload.click();
    },
    async getprojectusers() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseurl}userprojectrole/getprojectusers?projectId=` +
            store.getters.getSelectedProject,
          {
            //.get(`https://localhost:5001/userprojectrole/getprojectusers?projectId=`+store.getters.getSelectedProject,{
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )

        //   .get(`${this.baseurl}account-core/user/getall`, {
        .then((res) => {
          this.userList = res.data;
          this.setcurentuser(res.data);
        })
        .catch((err) => {
          console.log(err);
          this.userList = [];
        });
    },
    async getusers() {
      const idtoken = store.getters.getToken;
      if (this.fromPage === "generalFiles") {
        store.dispatch("setMainDepartment", "");
      }
      await axios
        .post(
          `${this.baseapi}designationusers?departmentId=` +
            store.getters.getMainDepartment,
          [],
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.userList = res.data;
          console.log("userlist isssss", this.userList);
          this.setcurentuser(res.data);
        })
        .catch((err) => {
          console.log(err);
          this.userList = [];
        });
    },
    async getsignusers() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}account-core/user/getall`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.signuserList = res.data;
          console.log("userlist isssss", this.signuserList);
        })
        .catch((err) => {
          console.log(err);
          this.signuserList = [];
        });
    },
    async gettypelist() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}document/document/gettypelist?SortProperty=documentType`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.doctypelist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.doctypelist = [];
        });
    },
    async getstandard() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.mainapi}configuration/documentstandard/search?PageSize=1000&SortProperty=name&IsDescending=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.standards = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.standards = [];
        });
    },
    async getcategory() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.mainapi}configuration/documentcategory/search?PageSize=1000&SortProperty=category&IsDescending=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.categorylist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.categorylist = [];
        });
    },

    async onclosed() {
      this.$emit("uploadlibactive");
      this.ApproversName = [];
    },

    async validatedocument() {
      this.validationmsgs = [false, false, false];

      if (store.getters.getselectedFolder.trim() == "") {
        this.validationmsgs[0] = true;
      }

      if (this.azuredata.Document.length === 0) {
        this.validationmsgs[2] = true;
      }
      if (this.docuplooadfields.expiarydate != null) {
        if (new Date(this.docuplooadfields.expiarydate) < new Date()) {
          this.validationmsgs[1] = true;
        }
      }
      
    },
    async submitFile() {
      const atLeastOneUserIdNotNull = this.bokkmarkusersarray.some(item => item.userId !== "");
      if(!atLeastOneUserIdNotNull){
        this.bokkmarkusersarray = [];
      }
      let StorageLocation="";
      if(this.fromPage == "department"){
        StorageLocation = "Department";
      }
      else if(this.fromPage == "project"){
        StorageLocation = "Project";
      }
      else{
        StorageLocation = "General";
      }
      let vervalue = this.docversion.toString();
      const idtoken = store.getters.getToken;
      await this.validatedocument();
      if (!this.validationmsgs.includes(true)) {
        const folderId = store.getters.getselectedFolder;
        this.list.forEach(async (eachfiles) => {
          if (eachfiles.size == 0) {
            alert("Blank documents cannot be uploaded");
          } else {
            if(this.docTitle.trim() == ""){
              this.docuplooadfields.title = eachfiles.name;
            }
            if(this.docTitle.trim() != ""){
              this.docuplooadfields.title = this.docTitle;
            }
            if(this.docuplooadfields.Owner.trim() == ""){
              this.docuplooadfields.Owner = this.currentuser;
            }
            if (vervalue.trim() == "") {
              vervalue = "1";
             }
            this.loadingBlock=true
            await axios
              .post(
                // `${this.mainapi}document/document/upload`,
                `${this.mainapi}document/document/upload`,
                {
                  DocumentDescription: this.docuplooadfields.description,
                  FolderId: store.getters.getselectedFolder.trim(),
                  ExpiryDate: this.docuplooadfields.expiarydate,
                  Department: this.docuplooadfields.department.trim(),
                  Owner: this.docuplooadfields.Owner.trim(),
                  DocumentType: this.docuplooadfields.documenttype.trim(),
                  File: eachfiles,
                  // Version: this.docuplooadfields.version.trim(),
                  Version: vervalue.trim(),
                  CurrentVersion: this.lastversion,
                  Catagory: this.docuplooadfields.Category.trim(),
                  DocumentTitle: this.docuplooadfields.title.trim(),
                  Standard: this.docuplooadfields.Standard,
                  VersionMessage: this.docuplooadfields.changesHistory,
                  Signers: this.bokkmarkusersarray,
                  IsSignConfig: this.addsigner,
                  StorageLocation: StorageLocation
                },
                {
                  headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "multipart/form-data",
                  },
                }
              )

              .then((response) => {
                if (this.docmsg) {
                  this.loadingBlock=false
                  alert("Documents saved successfully");
                  this.docmsg = false;
                  this.$emit("reloadDocs");
                  this.$emit("closemodal");
                }

                console.log("retreieved data", response);
                this.uploadedDocId = response.data;
                console.log("uploadedDocId is......", this.uploadedDocId);
                if (this.selectedUser.length == 0) {
                } else {
                  this.saveFilePermission();
                }
                this.onclosed();
              })
              .catch((err) => {
                console.log(err.data);
              });
          }
        });
      }
    },
    async getDesignationUsers() {
      const idtoken = store.getters.getToken;
      await axios
        .post(`${this.baseapi}designationusers`, this.selectedDesignation, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.alluserList = res.data;
          console.log("Reached ", this.selectedUser);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getdepartment() {
      const idtoken = store.getters.getToken;
      const orgId = store.getters.getOrganisationID;
      await axios
        .get(`${this.baseurl}department/search?orgid=${orgId}&PageSize=10000&SortProperty=name&IsDescending=false`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("data isssssssssssss", res.data);
          res.data.entities.forEach((data) => {
            if (data.parentID == "" || data.parentID == null) {
              if (!this.departmentlist.includes(data)) {
                this.departmentlist.push(data)
              }
            }
          });
        })
        .catch((err) => {
          console.log(err.response.data.messages)
        })
    },
    async getallUsers() {
      await axios
        .post(`${this.baseurl}designationusers`, this.selectedDesignation)
        .then((res) => {
          this.alluserList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async saveFilePermission() {
      this.selectedUser.forEach((subitem) => {
        this.newUserList.push({
          userId: "string",
          emailId: subitem,
          name: "string",
          permission: this.selectedPermission,
        });
      });
      await axios
        .post(`${this.baseapi}savefilepermission`, {
          documentId: this.uploadedDocId,
          userPermission: this.newUserList,
        })
        .then((res) => {
          console("File permissions saved successfully", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getfilepermissions() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.baseapi}allfilepermission`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.permissionList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getalldesignation() {
      await axios
        .get(`${this.baseurl}designation/search`)
        .then((res) => {
          this.designationList = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async validateDocumentIsNull() {
      if (this.list.length == 0) {
        this.validations[1] = true;
      } else {
        this.validations[1] = false;
      }
    },
    async previewFiles(event) {
      this.azuredata.Document = [];
      this.azuredata.Document.push(event.target.files);
      this.list = [...this.list, ...this.azuredata.Document[0]];
      console.log(this.list);
      // this.azuredata.Document.push(event.target.files[0]);
      // console.log(this.azuredata.Document);
    },
    async removefile(index) {
      this.list.splice(index, 1);
      // this.azuredata.Document.pop()
      // this.azuredata.Document.splice(index, 1);
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.modal__upload__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.modal-dialog-width {
  max-width: 700px !important;
}
.modal-overflow {
  overflow-x: hidden;
  height: 400px;
  overflow-y: auto;
}

.groupupload {
  display: flex;
}

.save_btn {
  background-color: #145faf !important;
  color: white;
}

.errorClass {
  color: red;
  font-size: 13px;
}
.unvisible {
  display: none;
}

.listfile {
  width: 90%;
}

.imp {
  color: #ff0000;
  font-size: 7px;
}
.error-block {
  color: #ff0000;
}

.modal-header-document {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.breakWord {
  word-break: break-word;
}
.loading-img {
  mix-blend-mode: lighten;
}
.btn-disabled{
  opacity:0.5;
  cursor:disabled

}
</style>
