/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import SidePopUp from "../side_pop_up/side_pop_up.vue"
import userconfig from "../userconfig/userconfig"
import Multiselect from "@vueform/multiselect"
import TreeFolder from "../TreeFolder/TreeFolder.vue"
import fileFormatPopUp from "../fileformatpopup/fileformatpopup.vue"
import folderEditPopUp from "../editfolderpopup/editfolderpopup.vue"
import PermissionPopUp from "../permission_popup/permission_popup.vue"
import ProjectPermission from "../project_permission/project_permission.vue"
import DocumentPopup from "../DocumentPopup/DocumentPopup.vue";
// import doc_Approval from "../document_Approval_Popup/doc_Approval.vue"
import FileFolderProjectPopup from "../FileFolderProjectPopup/FileFolderProjectPopup.vue"
import DocEditProjectPopup from "../DocEditPopup/DocEditPopup.vue"
import NewDocVersion from "../NewDocVersion/NewDocVersion.vue"
import foldersendpopup from "../foldersend_popup/foldersend_popup.vue"
import fileSharePopup from "../fileSharePopup/fileSharePopup.vue"
import filesendpopup from "../filesend_popup/filesend_popup.vue";
import foldershare_popup from "../foldershare_popup/foldershare_popup.vue";
import documentlist from "../documentList/documentList.vue";
import { onBeforeMount } from "vue";

export default {
  name: "project_summary",
  props: {
    id: { type: String, default: "" },
    projectType: { type: String, default: "" },
    projectName: { type: String, default: "" }
  },
  components: {
    DocumentPopup,
    NewDocVersion,
    fileSharePopup,
    DocEditProjectPopup,
    FileFolderProjectPopup,
    folderEditPopUp,
    fileFormatPopUp,
    SidePopUp,
    userconfig,
    PermissionPopUp,
    ProjectPermission,
    //doc_Approval,
    foldersendpopup,
    filesendpopup,
    Multiselect,
    TreeFolder,
    foldershare_popup,
    documentlist
  },
  data() {
    return {
      versionid: "",
      openfolderlist: "",
      destination:"",
      arrayIndex: 0,
      isuserenabled: true,// add doc privilege
      isusercanaddfolder: false,
      userpermissions: [],
      minverError: false,
      newdocversionPopup: false,
      folderversion: "",
      editDocPopup: false,
      sourceType: "",
      showSharePopup: false,
      showProjectPermPopup: false,
      showDocumentApproval: false,
      fromPage: "",
      baseApi: `${process.env.VUE_APP_Service_URL}`,
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/projectrole/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      docurl: `${process.env.VUE_APP_Service_URL}document/document/`,
      Head: "",
      idtoken: "",
      docId: "",
      showDesig: false,
      VersionConfig: [],
      SelectedFormat: [],
      FormatList: [],
      roleList: [],
      rolesList: [],
      searchField: "",
      currentPage: 1,
      pageindex: 1,
      pagesize: 10,
      totalPage: 1,
      SortProperty: "",
      SortOrder: false,
      SortOrderInt: 0,
      errors: [],
      validations: [],
      documentlist: [],
      ItemID: "",
      showBlock: false,
      showProjectBlock: false,
      showOrg: false,
      showProject: false,
      showModal: false,
      pop: false,
      showDocBlock: false,
      selectedfolderid: "",
      selectedfoldername: "",
      update: {
        roleId: "",
        roleName: "",
      },
      values: {
        name: [],
        projectId: "",
        projectType: "",
        addToTemplate: true,
        names: []
      },
      valuestemp: {
        name: [],
      },
      tempName: [],
      updates: {
        name: "",
        projectRoleId: "",
      },
      search: {
        createdby: "",
        status: "",
        name: "",
      },
      documentVersion: {
        prefix: "",
        majorVerStart: "",
        minorVerStart: "",
        minorVermax: "",
      },
      prefix: "",
      majorVerStart: "",
      minorVerStart: "",
      minorVermax: "",
      deleteOrglist: [],
      showPopup: false,
      showSuggestion: false,
      showProjectSharePopup: false,
      errors: [],
      addRoles: {
        names: [],
        addToTemplate: false,
        projectId: "",
        projectType: "",

        createfolderpopup: true,
      },
      //folder
      createfolderpopup: false,
      createFolder: {
        folderName: "",
        role: [],
        user: [],
        permissions: [],
        fileConfiguration: [],
        versionConfiguration: [],
      },
      selectedUser: '',
      mainfolderbold: "",
      folderList: [],
      userlist: [],
      userType: "",
      role: "",
      permissionList: [],
      folderErrors: [false],
      folderErrorMessages: ["Please enter a folder name"],
      selectedFolder: "",
      version: "",
      documentId: "",
      //end Folder
      //dropdownname: "ProjectRole",
      desigFolderPrivileges: "",
      designationPrivileges: [],
      desigUserPrivileges: "",
      folderfordocument: '',
      prevRoute: null,
      sendtoValue: "",
      editValue: "",
      permissionsValue: "",
      selectedTab:"document",
      shareValue: "",
      deleteValue: "",
      archiveValue: "",
      anyuserpermissions: false,
      readMore: [false],
      readTitleMore: [false],

    };
  },
  watch: {
    "$store.getters.getselectedFolder": function (val) {
      this.getFolderversion(val);
      this.showProject = true;
      this.mainfolderbold = val;
      this.folderversion = this.folderversion;
      this.folderfordocument = val;

      this.docId = store.getters.getselectedFolder
      this.getdocuments(store.getters.getselectedFolder)
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  // async onBeforeMount(){
  //   console.log("privillege dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", await store.getters.getRolesprivilegeData);
  //   this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
   
  // },
  async mounted() {
    this.idtoken = store.getters.getToken;
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    console.log("token",this.Head)
    this.documentlist = [];
    this.isusercanaddfolder = true,
    this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
    this.openfolderlist = store.getters.getopenfolder;
    //await this.setFolderPrivileges();
    await this.setuserPrivileges();
    localStorage.setItem('ProjectName', this.projectName);
    store.dispatch("setSelectedProject", this.id)
    store.dispatch("setFromPage", "ProjectSummary");
    //store.dispatch("selectFolder", "");
    let folderfordocview = store.getters.getselectedFolder;
    let val = store.getters.getselectedFolder;
    await this.getFolderversion(val);
    let currenttab = localStorage.getItem("currenttab");
    if (this.userpermissions != null) {

      if (!this.userpermissions.includes("Upload")) {

        this.isuserenabled = false;
      }
    }
    if (store.getters.getprvspath != '/doclogin/') {
      store.dispatch("selectFolder", "");
    }
    if (this.prevRoute != null) {
      if (this.prevRoute.path != "/") {
        localStorage.setItem("patvalue", this.prevRoute.path);
      }
      if (this.prevRoute.path == "/doclogin/") {
        this.checkDoc();
      }
      if (this.prevRoute.path.includes("createunittest")
        || this.prevRoute.path.includes("createcodereview") || this.prevRoute.path.includes("editunittest") || this.prevRoute.path.includes("editcodereview") || this.prevRoute.path.includes("versioncodereview") || this.prevRoute.path.includes("versionunittest")) {
        document.querySelector('.nav-link.active').classList.remove('active');
        document.querySelector('.tab-pane.active').classList.remove('active');
        document.getElementById('development').classList.add('active');
        document.getElementById('tab-3').classList.add('active');
      }
      if (this.prevRoute.path.includes("documentview")) {
        if (currenttab == 'development') {
          document.querySelector('.nav-link.active').classList.remove('active');
          document.querySelector('.tab-pane.active').classList.remove('active');
          document.getElementById('development').classList.add('active');
          document.getElementById('tab-3').classList.add('active');
        }
        else {
          document.querySelector('.nav-link.active').classList.remove('active');
          document.querySelector('.tab-pane.active').classList.remove('active');
          document.getElementById('document').classList.add('active');
          document.getElementById('tab-1').classList.add('active');
        }
      }
    }
    if (store.getters.getdocumenttodepartment == true || store.getters.getprvspath == '/doclogin/') {
      this.setfirstdept(folderfordocview)
    }
    this.ListRoles();
    this.getrolecount()
    this.getRootFolders();
  },
  async unmounted() {
    store.dispatch("setopenfolder", "");
    if (store.getters.getdocumenttodepartment == true) {
      store.dispatch("setdocumenttodepartment", false)
    }
  },
  methods: {
    async cleardocs(type) {
      this.selectedTab=type
      store.dispatch("selectFolder", "");
    },
    async cls() {
      let val = store.getters.getselectedFolder
      await this.getFolderversion(val);
      this.showProject = true;
      this.mainfolderbold = val;
      this.folderversion = this.folderversion;
      this.folderfordocument = val;
      this.docId = store.getters.getselectedFolder
      this.getdocuments(store.getters.getselectedFolder)
      this.showProjectBlock = true;

    },
    async newversionuploadactivity() {
      this.newdocversionPopup = false;
      this.getdocuments(this.folderfordocument);
    },
    async NewDocVersion() {
      this.newdocversionPopup = !this.newdocversionPopup
      this.showBlock = !this.showBlock
    },
    setfirstdept(folder) {
      console.log("Folder", folder)
      store.dispatch("selectFolder", folder);
      this.mainfolderbold = folder;
      this.folderfordocument = folder;
      this.currentselectedfolder = folder;
      this.getdocuments(folder);
    },
    isNumber: function (evt) {

      evt = (evt) ? evt : window.event;

      var charCode = (evt.which) ? evt.which : evt.keyCode;

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 || charCode == 46) {

        evt.preventDefault();;

      } else {

        return true;

      }

    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    },
    viewDoc(id, version) {
      if(this.designationPrivileges.includes(`Projects View Document`))
      {
      localStorage.setItem("currenttab", "document");
      store.dispatch("setDocViewSource", "Project")
      this.$router.push(`/documentview/` + id + "/" + version)
      }

    },
    openModelCreateFolder() {
      this.prefix = "";
      this.majorVerStart = "";
      this.minorVerStart = "";
      this.minorVermax = "";
      this.createfolderpopup = true;
      this.getallFormat();
      this.SelectedFormat = [];
      this.errors[3] = true;
      this.versionConfiguration = [];
      this.minverError = false;
    },
    closedocmodal() {
      this.showProjectBlock = false;
      this.getdocuments(this.docId);
    },
    closeModelCreateFolder() {
      this.createFolder.folderName = "";
      this.folderErrors[0] = false;
      this.createfolderpopup = false;
      this.SelectedFormat = [];
    },
    sidePopup(id, name, type, e) {
      e = window.event
      this.event = e
      this.destination=type
      this.popupid = id
      this.showBlock = !this.showBlock
      store.dispatch("setselectedid", id)
      store.dispatch("setfileType", type)
      store.dispatch("setfileName", name)
    },
    async getDocumentdetails(id) {
      this.sendtoValue = "";
      this.editValue = "";
      this.permissionsValue = "";
      this.shareValue = "";
      this.deleteValue = "";
      this.archiveValue = "";
      await axios
        .get(
          `${this.docurl}getdocumentdetails?documentId=${id}`,
          //`https://localhost:5001/document/getdocumentdetails?documentId=${this.documentid}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let doc = res.data;
          if (doc.permissions != null) {
            this.anyuserpermissions = false;

            res.data.permissions.forEach((item) => {
              if (item == "Clone") this.sendtoValue = item;
              if (item == "Permissions") this.permissionsValue = item;
              if (item == "Share") this.shareValue = item;
              if (item == "Edit" && doc.isSigned == false) this.editValue = item;
              if (item == "Delete" && doc.isSigned == false) this.deleteValue = item;
              if (item == "Archive") this.archiveValue = item;
            });
            if (
              !this.sendtoValue &&
              !this.permissionsValue &&
              !this.shareValue &&
              !this.editValue &&
              !this.deleteValue &&
              !this.archiveValue
            ) {
              this.anyuserpermissions = true;
            }
          }
          else {
            this.anyuserpermissions = true;
          }
          // this.getVersionHistory();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sidedocPopup(index, id, name, type, e) {
      this.getDocumentdetails(id);
      e = window.event
      this.event = e
      this.popupid = id
      this.arrayIndex = index
      this.showDocBlock = !this.showDocBlock
      store.dispatch("setselectedid", id)
      store.dispatch("setfileType", type)
      store.dispatch("setfileName", name)
    },
    trimtext(value) {
      value = value.replace(
        /^\s+|\s+$/gm,
        ""
      );
      return value
    },
    saveVersion() {
      if (this.documentVersion.majorVerStart == "" | this.documentVersion.majorVerStart == null) {
        alert("Major version is mandatory");
      }
      else {
        this.VersionConfig = []
        this.VersionConfig.push(this.documentVersion);

      }
    },
    async createfolder() {
      console.log("token",this.Head)
      this.validateFolder();
      this.minverError = false;
      if (this.minorVermax != "" && parseInt(this.minorVerStart) > this.minorVermax) {
        this.minverError = true;
      }
      else {
        if (!this.folderErrors.includes(true)) {
          await axios
            .post(this.baseApi + `document/folder/create`,
              //.post(`https://localhost:5001/folder/create`,
              {
                name: this.createFolder.folderName,
                parentId: store.getters.getselectedFolder,
                storageId: this.projectid,
                storageType: "Project",
                fileConfiguration: this.SelectedFormat,
                "versionConfiguration": [
                  {
                    "prefix": this.prefix,
                    "majorVerStart": this.majorVerStart,
                    "minorVerStart": this.minorVerStart,
                    "minorVermax": this.minorVermax
                  }
                ]
              },
              this.Head
            )
            .then((res) => {
              console.log(res);
              alert("Folder created successfully.");
              this.closeModelCreateFolder();
              this.getRootFolders();
              document.getElementById(store.getters.getselectedFolder + 'doc').click();
              document.getElementById(store.getters.getselectedFolder + 'doc').click();
            })
            .catch((err) => {
              console.log(err.response.data.detail)
              if (err.response.data.detail == "Folder name already present in the parent folder.") {
                alert("Folder name already exist.");


              }
              else if (err.response.data.detail == "Major version start field cannot be empty.") {
                alert("Major version start field cannot be empty.");
              }
              else {
                if (err.response.data.detail == "Folder name already present in the parent department.") {
                  alert("Folder name already exist.");
                }
              }
            });
        }
      }
    },
    async getFolderversion(folderid) {

      await axios
        .get(
          //`https://localhost:5001/folder/getfolderversion?Id=${folderid}`,
          `${this.mainapi}document/folder/getfolderversion?Id=${folderid}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          this.folderversion = res.data;
          if (this.folderversion.includes(':')) {
            this.folderversion = (this.folderversion).replace(':', '.');
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //get user privileged folder controls
      await axios
        .get(`${this.mainapi}document/folderfilepermission/getuserfolderpermission?FolderId=${folderid}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
        .then(async (res) => {
          this.isuserenabled = false;
          this.userpermissions = res.data != null ? res.data : null;
          if (this.userpermissions != null) {
            if (this.userpermissions.includes("Upload")) {
              this.isuserenabled = true;
            }
            if (this.userpermissions.includes("Add")) {
              this.isusercanaddfolder = true;
            }
            else {
              this.isusercanaddfolder = false;
            }
          }
          else {
            this.isusercanaddfolder = false;
          }
          // this.ancestors = res.data.ancestorIds;         
        })
        .catch((err) => {
          console.log(err);
        });

    },
    async DocEditPopup(docid, versionid) {
      this.versionid = versionid;
      this.editDocPopup = !this.editDocPopup
      this.showDocBlock = false;

    },
    async uploadeditactivity() {
      this.editDocPopup = false;
      this.getdocuments(this.folderfordocument);
    },
    async ShareDocument() {
      this.showProjectSharePopup = !this.showProjectSharePopup
      this.sourceType = "File"
    },
    async FilePermissionPopup() {
      this.showProjectPermPopup = !this.showProjectPermPopup
      this.sourceType = "File"
      this.showDocBlock = false;
    },
    // async documentApproval(documentId, version) {
    //   this.version = version;
    //   this.documentId = documentId;
    //   this.showDocumentApproval = !this.showDocumentApproval;
    //   this.showDocBlock = false;
    // },
    async getRootFolders() {
      this.projectid=this.id;
      await axios
        //.get(`https://localhost:5001/folder/getroots?storageId=${this.projectid}`, this.Head
        .get(this.baseApi + `document/folder/getprivilegedroots?storageId=${this.projectid}&storageType=Project`, this.Head
        )
        .then((res) => {
          this.folderList = res.data;
          console.log("project folder list is ", res);

        })
        .catch((err) => {
          console.log(err.response.data.messages)
        })

    },
    async userList() {
      this.projectid = this.id;
      console.log("Project id", this.id)
      await axios
        .get(
          this.baseApi + `account-core/user/getlist?UserType=${this.userType}&Designation=${this.role}`, this.Head
        )
        .then((res) => {
          this.userlist = res.data;
          console.log("user list isssssssssssss", this.userlist)
        })
        .catch((err) => {
          console.log(err);
          this.userList = [];
        })
    },
    async OnSearchButtonClicked() {
      this.pageindex = 1;
      this.currentPage = 1;
      this.searchField = this.search.name;
      this.ListOrgs()
    },
    async getdocuments(id) {
      this.fromPage = "ProjectSummary";
      console.log("Id isSS00", id)
      this.documentlist = []
      await axios
        .get(
          // `https://localhost:5001/folder/getprivilegedcontent?Id=${id}`,
          `${this.baseApi}document/folder/getprivilegedcontent?Id=${id}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.documentlist = res.data;
          console.log("Docs", this.documentlist);
        })
        .catch((err) => {
          console.log(err);
          this.documentlist = []
        });
    },
    async DeleteDocument(id) {
      this.showDocBlock = false;
      console.log("Document Id.........", id)
      const documentId = id
      if (confirm("Are you sure you want to delete this document?")) {
        await axios.delete(this.docurl + "delete?documentId=" + documentId,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            console.log(res)
            alert("Document deleted successfully")
          })
          .catch((err) => {
            console.log(err);
            this.documentlist = []
            alert("Somthing Went Wrong")
          })
        this.pageindex = 1;
        this.currentPage = 1;
        this.getdocuments(store.getters.getselectedFolder);

      }
    },
    async sharefolder() {
      this.showSharePopup = !showSharePopup;
      this.sourceType = file;
      console.log("it works")
    },
    async ArchievedDocument(id) {
      this.showDocBlock = false;
      console.log("Document Id.........", this.idtoken)
      const documentId = id;

      if (
        confirm("Are you sure you want to archive this document ?")
      ) {
        //Api call  
        await axios
          .put(`${this.mainapi}document/document/archive?id=${documentId}`, {}, {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log(res)
            alert("Document archived successfully")
            //tree refresh
          })
          .catch((err) => {
            console.log(err.message)
          })
        this.pageindex = 1;
        this.currentPage = 1;
        this.getdocuments(store.getters.getselectedFolder);
      }
    },
    async getallProjectFolders(id) {
      console.log("in get project folders");
      await axios
        // .get(`https://localhost:5001/folder/getroots?storageId=${id}`, this.Head
        .get(`${this.baseApi}document/folder/getprivilegedroots?storageId=${id}`, this.Head
        )
        .then((res) => {
          this.folderList = res.data;
          console.log("project folder list is ", res);

        })
        .catch((err) => {
          console.log(err.response.data.messages)
        })

    },
    validateFolder() {
      this.createFolder.folderName = this.trimtext(this.createFolder.folderName);
      if (this.createFolder.folderName == "") {
        this.folderErrors[0] = true;
        this.folderErrorMessages[0] = "Please enter a folder name";
      }
      else if (this.createFolder.folderName.length > 75) {
        this.folderErrors[0] = true;
        this.folderErrorMessages[0] = "Maximum number of characher is 75";
      }
      else {
        this.folderErrors[0] = false;
      }
    },
    async backtorootfolder() {
      store.dispatch("selectFolder", "");
      this.mainfolderbold = "",
        this.currentselectedfolder = "";
      this.isusercanaddfolder = true,
        this.getRootFolders();
    },

    // end of create folder

    async CreateRole(value) {
      document.body.style.overflowY = "hidden"
      if (value == "createRole") {
        this.showPopup = !this.showPopup;

      }
      this.errors[0] = true;
      this.edits = true;
      this.create = true;
      this.edits = false;
      this.values.name = "";
    },

    openModelrole() {
      document.body.style.overflowY = "hidden"
      this.showPopup = true;
      this.pop = true;
      this.create = true;
      this.edits = false;
      this.values.names = [];
    },
    editModelRole(id, name) {

      this.values.name = name;
      this.values.id = id;
      console.log("ROLE ID and NAME", id, name);
      this.showPopup = true;
      this.pop = false;
      this.create = true;
      this.edits = false;
    },
    async closemodal() {
      document.body.style.overflowY = "auto"
      this.showPopup = false;
      this.errors[0] = true;
      this.values.name = "";
    },
    openRoleList() {
      this.showSuggestion = true;
      this.ListExistingRoles();
    },
    async closeRoleList() {
      this.showSuggestion = false;
      this.values.name = [];
      this.valuestemp = []; (index)
    },
    deleteFromList(Roles, index) {
      this.rolesList.splice(index, 1);
    },
    SaveRolesTemp() {
      let flag = true;

      if (this.valuestemp.name == "" || this.valuestemp.name == null) {
        alert("Please enter a role name");
      }
      else {
        this.rolesList.forEach((item) => {
          if (this.valuestemp.name.toLowerCase() == item.toLowerCase()) {
            alert("role name already exist in the template");
            flag = false;

          }
        })
        if (flag) {
          this.rolesList.push(this.valuestemp.name.trim());
          console.log("manually added roles", this.rolesList)
          this.valuestemp.name = "";

        }
      }
    },
    async checkDoc() {
      const idtoken = store.getters.getToken;
      this.selectedfolder = store.getters.getselectedFolder
      console.log("FOLDER ID ISSSSSSSSSSSSSSSSSSSSSSSSSS", store.getters.getselectedFolder)
      await axios
        .get(
          `${this.mainapi}document/folder/getarchive?Id=${this.selectedfolder}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("RESSS", res);
          if (res.data == "The folder you are trying to access is currently archived!") {
            alert(res.data);
            this.$router.push(`/dashboard`);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    async edit() {
      this.projectRoleId = this.values.id;
      console.log("Project Role Id", this.projectRoleId)
      const roleId = this.values.id;
      console.log("ROLE ID IS", roleId);
      this.values.name = this.values.name.trim();
      console.log("POPOPOPOPOP", this.values.name);
      this.updates.name = this.values.name;
      this.updates.projectRoleId = this.projectRoleId;
      if (this.values.name == "" || this.values.name == null) {
        alert("Enter a valid role name");
      }
      else {
        await axios
          .put(
            `${this.baseurl}update`,
            this.updates,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("result", res)
            this.ListRoles();
            alert("Updated successfully");

            this.closemodal();
            this.ListRoles();
          })
          .catch((err) => {
            console.log(err);
            alert(err.response.data.errors.DomainValidations[0])
          });
      }
    },
    async deleteRole(Id) {
      const ProjectroleId = Id;
      var confirmed = confirm("Are you sure you want to delete this role?");
      if (confirmed == true) {
        console.log("delete", ProjectroleId);
        await axios
          .delete(`${this.baseurl}delete?projectRoleId=` + ProjectroleId,
            this.Head)
          .then((res) => {
            alert("Deleted successfully");
            console.log(res);
            this.ListRoles();
            this.getrolecount();
          })
          .catch((err) => {
            console.log(err)
            console.log(err.response.data.detail);
            alert(err.response.data.detail);
          });
      }
    },
    async ListRoles() {
      console.log("afafafafafaffaadafafadfa");
      this.projectid = this.id;
      console.log("Project id", this.id)
      await axios
        .get(
          `${this.baseApi}configuration/projectrole/getall?projectId=${this.projectid}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.roleList = res.data;
          console.log("role list isssssssssssss", this.roleList)
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.roleList = []
          alert("No data found!")
        })
    },
    async createRole() {
      await this.validateIsNull();
      //this.id= this.values.projectId;
      this.values.name = this.values.name.trim();
      this.values.projectId = this.id;
      console.log("id issssssssssssssssssss", this.id);
      this.values.projectType = this.projectType;
      this.allRoles = this.values;
      console.log("ALL ROLE LIST", this.allRoles);
      this.rolesList.forEach((item) => {
        console.log("ITEM", item);
        console.log("Names", this.allRoles.names);
        this.allRoles.names.forEach((subitem) => {
          if (subitem == item) {
            return
          }
          else
            this.allRoles.names.push(item)
        })
      })
      this.allRoles.names.push(this.values.name)
      await this.validateIsNull();

      console.log("eeeeeeee")
      this.projectid = this.id;
      if (this.errors[0]) {
        await axios
          .post(`${this.baseurl}createmany`, this.allRoles, this.Head)
          .then((res) => {
            console.log(res);
            alert("Role created successfully");
            this.showDesig = false;
            document.body.style.overflowY = "auto"
            this.closemodal();
            this.ListRoles();
            this.getrolecount();
          })
          .catch((err) => {
            console.log(err);
            alert("Role name already exists");
            this.values.names=[];
          });
      }
    },
    async ListExistingRoles() {
      this.type = this.projectType;
      console.log("Project Type is", this.projectType);
      await axios
        .get(
          `${this.baseApi}configuration/projectrole/getallwithtype?type=${this.type}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.rolesList = res.data;
          console.log("roles list isssssssssssss oneee", this.rolesList)
        })
        .catch((err) => {
          console.log(err);
          this.rolesList = []
          alert("No data found!")
        })
    },
    async AddListingRoles() {
      this.showSuggestion = false;
      this.values.projectId = this.id;
      console.log("id issssssssssssssssssss", this.id);
      this.values.projectType = this.projectType;
      this.tempName = this.rolesList;

      console.log("role name for all save", this.tempName)
      this.values.names = this.tempName;
      console.log("role namesssssssssssssssssssssssssssssssssssssssssss", this.values.name);
      this.projectid = this.id;
      if (this.values.name == "" || this.values.name == null) {
        console.log("eeeeeeee")
        await axios
          .post(`${this.baseApi}configuration/projectrole/createmany`, this.values, this.Head)
          .then((res) => {
            console.log(res);
            alert("Roles added successfully");
            this.showDesig = false;
            document.body.style.overflowY = "auto"
            this.closemodal();
            this.ListRoles();
            this.getrolecount();
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data.messages[0]);
            if (err.response.data.messages[0].includes(" Role With Same Name Exist")) {
              alert("Role name already exists");
            }
          });
      }
      else {
        this.createRole();

      }
    },
    async getrolecount() {
      this.projectid = this.id;
      await axios
        .get(
          `${this.baseurl}getrolecount?projectId=${this.projectid}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.count = res.data;
          console.log("role count issss", this.count)
        })
        .catch((err) => {
          console.log(err);
          this.count = []
          alert("No data found!")
        })
    },
    async validateIsNull() {

      if (this.values.name == "") {
        this.validations[0] = false;
        this.errors = this.validations;
      } else if (this.values.name.trim("") == "") {
        this.validations[0] = false;
        this.errors = this.validations;
      } else {
        console.log("vvvvvvvv2")
        this.validations[0] = true;
        this.errors = this.validations;
      }
    },
    async getallFormat() {
      await axios
        .get(`${this.mainapi}document/documentformat/search`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          this.FormatList = res.data.entities[0].values;
          console.log("Format List", this.FormatList)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //   async setFolderPrivileges(){
    //     // Folder PopUp Privileges
    //     let allFolderPriv = {
    //         "Projects Create Folder":"Add",
    //         "Projects Edit Folder" : "Edit",
    //         "Projects Move Folder" :"Move",
    //         "Projects Folder Permissions" :"Permissions",
    //         "Projects File Format" :"File Format",
    //         "Projects Share Folder" :"Share",
    //         "Projects Delete Folder" :"Delete",
    //         "Projects Archive Folder":"Archive"
    //     }

    //     const privilegeArray = this.designationPrivileges.split(',').map((s) => s.trim());
    //     const keys = Object.keys(allFolderPriv);

    //     // Return Privileged Folder controlls.
    //     this.desigFolderPrivileges = keys
    //     .filter((key) => privilegeArray.includes(key))
    //     .map((key) => allFolderPriv[key]).join(',');
    // },
    async setuserPrivileges() {
      let allFolderPriv = {
        "Projects Edit User": "Edit",
        "Projects Delete User": "Delete",
        "Projects Create User": "Create User"
      }

      const privilegeArray = this.designationPrivileges.map((s) => s.trim());
      const keys = Object.keys(allFolderPriv);
      // Return Privileged Folder controlls.
      this.desigUserPrivileges = keys
        .filter((key) => privilegeArray.includes(key))
        .map((key) => allFolderPriv[key]).join(',');
    },
    showMore(index) {
      this.readMore[index] = true;
      console.log("INDEX", index);
    },
    showLess(index) {
      this.readMore[index] = false;
    },
    showTitleMore(index) {
      this.readTitleMore[index] = true;
      console.log("INDEX", index);
    },
    showTitleLess(index) {
      this.readTitleMore[index] = false;
    },
  },
};