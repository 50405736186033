/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import VPagination from "@hennge/vue3-pagination";
import DigitalSignPopUp from "../DigitalSignPopUp/DigitalSignPopUp.vue";
export default {
  name: 'approveTrainingPlan',
  props: {
    refreshFlag: Boolean // Define a prop to trigger refresh
  },
  watch: {
    refreshFlag(newVal) {
      if (newVal) {
        this.refreshContent();
      }
    }
  },
  components: {
    VPagination,
    DigitalSignPopUp
  },
  data() {
    return {
      showMoreStatesSkills: {},
      showMoreStates: {},
      searchActualstartdate: "",
      searchActualenddate: "",
      searchPlannedstartdate: "",
      searchPlannedenddate: "",
      searchStatus: "All",
      trainingId: "",
      baseurl: `${process.env.VUE_APP_Service_URL}`,
      fromDate: "",
      toDate: "",
      idtoken: "",
      totalPages: 1,
      pageIndex: 1,
      pageSize: 10,
      currentPage: 1,
      sortProperty: "createdAt",
      sortorder: 1,
      tableview_heading: "Training Plan and Record for",
      username: "username",
      data: [],
      showSidePopup: [false],
      showDateBlock: false,
      showSignBlock: false,
      columnDefs: [
        {
          name: "userName",
          header: "Employee"
        },
        {
          name: "skills",
          header:
            "Required Knowledge Skills Abilities <br><small>(Managers populate the plan for an individual)</small>"
        },
        {
          name: "trainingDetails",
          header: "Training Details",
        },
        {
          name: "trainingMethod",
          header:
            "Training Activities Identified <br><small>(indicate method of training)</small>",
        },
        {
          name: "plannedDateRange",
          header: "Planned Training Date <br><small>(DD-MMM-YYYY)</small>",
        },
        {
          name: "actualDateRange",
          header: "Actual Training Date <br><small>(DD-MMM-YYYY)</small>",
        },
        { name: "trainedBy", header: "Trained by" },
        {
          name: "signature",
          header: "Training Completed Sign & Date <br><small>(Trainer or Line Manager)</small>",
        },
      ],
    }
  },
  async mounted() {
    this.idtoken = store.getters.getToken;
    this.getTrainingList();
  },
  methods: {
    refreshContent() {
      console.log("Refreshed approveTraining")
      this.getTrainingList();
    },
    truncateTextSkills(text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    },
    toggleShowMoreSkills(index) {
      this.showMoreStatesSkills = {
        ...this.showMoreStatesSkills,
        [index]: !this.showMoreStatesSkills[index]
      };
    },
    truncateText(text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    },
    toggleShowMore(index) {
      this.showMoreStates = {
        ...this.showMoreStates,
        [index]: !this.showMoreStates[index]
      };
    },
    async onSearchClicked() {
      this.currentPage = 1;
      this.pageIndex = 1;
      await this.getTrainingList();
    },
    async clearValues() {

      this.searchActualstartdate = "";
      this.searchActualenddate = "";
      this.searchPlannedstartdate = "";
      this.searchPlannedenddate = "";
      this.searchStatus = "All";
      await this.onSearchClicked()
    },
    dateBlockDisplay() {

      const cardBody = document.getElementById("cardtrainingApproval")
      this.showDateBlock = !this.showDateBlock
      console.log("Value", this.showDateBlock)
      if (this.showDateBlock == true) {
        cardBody.style.height = '94px'
      }
      else {
        cardBody.style.height = '0px'
      }
    },
    formatDateTime(dateTimeString) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const dateTime = new Date(dateTimeString);
      const day = dateTime.getDate().toString().padStart(2, '0');
      const month = months[dateTime.getMonth()];
      const year = dateTime.getFullYear();
      const hours = dateTime.getHours().toString().padStart(2, '0');
      const minutes = dateTime.getMinutes().toString().padStart(2, '0');

      return `${day}${month}${year} | ${hours}:${minutes}`;
    },
    
    NeededDateRange(startDate, endDate) {
      if (startDate === null || endDate === null) {
        return ''; // Return an empty string if either start or end date is null
      }

      const start = new Date(startDate);
      const end = new Date(endDate);

      const options = { day: '2-digit', month: 'short', year: 'numeric' };

      if (start.getTime() === end.getTime()) {
        return this.formatDate(start, options).toUpperCase(); // Return single date
      } else {
        const formattedStart = this.formatDate(start, options);
        const formattedEnd = this.formatDate(end, options);
        return `${formattedStart.toUpperCase()}\nto\n${formattedEnd.toUpperCase()}`;
      }
    },

    formatDate(date, options) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    openModal(trainingId) {
      this.showSignBlock = true
      store.dispatch("setFromPage", "approveTraining");
      this.trainingId = trainingId;
    },

    async signAddedFun(result) {
      const signedIntial = result;
      if (signedIntial != "") {
        await this.getTrainingList();
      }
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageIndex = page;
      this.getTrainingList();
    },
    async getTrainingList() {
      await axios
        .get(
          `${this.baseurl}document/trainingplan/gettraininglist?Tab=Approval&status=${this.searchStatus}&plannedstartdate=${this.searchPlannedstartdate}&plannedenddate=${this.searchPlannedenddate}&actualstartdate=${this.searchActualstartdate}&actualenddate=${this.searchActualenddate}&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=CreatedAt&SortOrder=1`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.totalPages = parseInt(res.data.totalCount / this.pageSize);
          this.data = res.data.entities;
          if (res.data.totalCount % this.pageSize != 0) {
            this.totalPages = this.totalPages + 1;
          }
        }).catch((err) => {
          console.error(err);
          this.data = [];
        })
    },
  }

};