import { createStore } from 'vuex'

import LoginModule from './modules/LoginModule';
import FolderModule from './modules/FolderModule';
import timermodule from './modules/timermodule';
import rolePrivilegeModule  from './modules/rolePrivilegeModule';

const store = createStore({
	modules: {
		LoginModule,
		FolderModule,
		timermodule,
		rolePrivilegeModule
	},
});

export default store;
