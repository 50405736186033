<template>
  <div class="permission-block-container">
    <div class="modal-dialog modal-width">
      <div class="modal-content">
        <div class="modal-header-docs d-flex justify-content-bertween p-3">
          <h5 class="my-0 align-self-center">Add Documents</h5>
          <span
            type="button"
            class="align-self-center"
            data-dismiss="modal"
            @click.prevent="close()"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div
          class="modal-body overflow-y-auto border-0 px-4"
          id="permissionBlock"
        >
          <div class="form-group">
            <div class="row">
              <div class="col-12 col-md-6 mb-2 pr-md-1">
                <label class="mb-0 mr-1" for="document title"
                  >Document Title</label
                ><span aria-label="mandatory field" class="error-block">*</span>
                <input
                placeholder="Enter document title"
                  type="text"
                  id="document title"
                  class="form-control"
                  v-model="docuplooadfields.title"
                  maxlength="30"
                />
                <span class="error-block" v-if="validations[0]"
                  >Please enter a title</span
                >
              </div>
              <div class="col-12 col-md-6 mb-2 pl-md-1">
                <label class="mb-0 mr-1" for="owner">Owner</label
                ><span aria-label="mandatory field" class="error-block">*</span>
                <select
                  id="owner"
                  class="form-control"
                  v-model="docuplooadfields.Owner"
                >
                  <option
                    v-for="users in userList"
                    :key="users.id"
                    :value="users.name"
                  >
                    {{ users.name }}
                  </option>
                </select>
                <span class="error-block" v-if="validations[1]"
                  >Please select an owner</span
                >
              </div>
              <div class="col-12 mb-2">
                <div class="d-flex flex-column">
                  <label class="mb-0" for="document description"
                    >Document Description</label
                  >
                  <textarea
                    id="document description"
                    class="form-control"
                    v-model="docuplooadfields.description"
                    rows="3"
                    cols="9"
                    maxlength="250"
                  ></textarea>
                </div>
              </div>

              <div class="col-12 col-md-6 mb-2 pr-md-1">
                <label class="mb-0" for="department">Department</label>
                <select
                  id="department"
                  class="form-control"
                  v-model="docuplooadfields.department"
                >
                  <option
                    v-for="department in departmentlist"
                    :key="department.id"
                    :value="department.name"
                  >
                    {{ department.name }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-6 mb-2 pl-md-1">
                <label class="mb-0" for="category">Category</label>
                <select
                  id="category"
                  class="form-control"
                  v-model="docuplooadfields.Category"
                >
                  <option
                    v-for="category in categorylist"
                    :key="category.id"
                    :value="category.category"
                  >
                    {{ category.category }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-6 mb-2 pr-md-1">
                <label class="mb-0" for="standar">Standard</label>
                <select
                  id="standard"
                  class="form-control"
                  v-model="docuplooadfields.Standard"
                >
                  <option
                    v-for="standard in standards"
                    :key="standard.id"
                    :value="standard.id"
                  >
                    {{ standard.name }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-6 mb-2 pl-md-1">
                <label class="mb-0 mr-1" for="document type">Type</label
                >
                <!-- <span aria-label="mandatory field" class="error-block">*</span> -->
                <select
                  id="document type"
                  class="form-control"
                  v-model="docuplooadfields.documenttype"
                  @change="bookmarklistfun"
                >
                  <option
                    v-for="doctype in doctypelist"
                    :key="doctype.id"
                    :value="doctype.id"
                  >
                    {{ doctype.documentType }}
                  </option>
                </select>
                <!-- <span class="error-block" v-if="validations[2]"
                  >Please select a document type</span
                > -->
              </div>
              <div class="col-12 col-md-6 mb-2 pr-md-1">
                <label class="mb-0 mr-1" for="version">Version</label
                ><span aria-label="mandatory field" class="error-block">*</span>
                <input
                placeholder="Enter version"
                  type="text"
                  id="version"
                  class="form-control"
                  v-model="this.docversion"
                  maxlength="10"
                />
                <span class="error-block" v-if="validations[3]"
                  >Please select a version</span
                >
              </div>
              <div class="col-12 col-md-6 mb-2 pl-md-1">
                <label class="mb-0 mr-1" for="expiry date">Expiry Date</label>
                <input
                placeholder="Select expiry date"
                  type="text"
                  id="expiry date"
                  v-model="docuplooadfields.expiry"
                  class="form-control"
                  onfocus="(type='date')"
                  onblur="(type='text')"
                  maxlength="30"
                />
                <span class="error-block" v-if="validations[5]">
                  Please enter a future date</span
                >
              </div>
              <div class="col-12 mt-2">
                <label class="mb-0" id="changesHistory">Changes history</label>
                <textarea
                  id="changesHistory"
                  class="form-control"
                  v-model="docuplooadfields.changesHistory"
                  maxlength="250"
                ></textarea>
              </div>
              <div v-if="docuplooadfields.documenttype.trim() != ''" class="col-12 mt-2 form-check">
              <input class="form-check-input" @change="createbookmarkuserarray()" v-model="addsigner" type="checkbox" value="" id="flexCheckDefault">
  <label class="form-check-label ml-4" for="flexCheckDefault">
    Do you need to add signer ?
  </label>
            </div>
            <div class="col-12 mt-2" v-if="addsigner">
                <table class="table table-striped table-bordered dataTables">
                <thead>
                  <tr>
                    <th class="sort_block" id="1">
                      <div class="d-flex flex-row justify-content-between">
                        Signing order
                      </div>
                    </th>
                    <th class="sort_block" id="1">
                      <div class="d-flex flex-row justify-content-between">
                        Bookmark/Role
                      </div>
                    </th>
                    <th class="sort_block" id="1">
                      <div class="d-flex flex-row justify-content-between">
                        User
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(bookmarks, index) in bookmarklist"
                    :key="bookmarks"
                  >
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>
                      {{ bookmarklist[index].bookmarkName }}
                    </td>
                    <td>
                      <select
                        id="user"
                        class="mr-3 form-select form-select-lg"
                        aria-label=".form-select-lg example"
                        :disabled="index!=0 && bokkmarkusersarray[index-1].userId ==''"
                        @change="assignbookmarkuserfun($event,index)"
                      >
                      <option value="" selected disabled
                        >--Select a User--</option>
                        <option
                          v-for="users in signuserList"
                          :value="users.id+','+users.email+','+users.name"
                          :key="users.id"
                        >
                          {{ users.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
                </table>
              </div>
              <input
                accept=".xls,.xlsx,.jpg,.jpeg,.png,.pdf,.docx,.doc"
                id="selectedFile"
                type="file"
                class="form-control d-none my-2 my-md-0"
                @change="previewFiles"
                multiple
              />
              <div class="col-12 setoverflow justify-content-end">
                <div class="d-flex flex-column">
                  <div
                    class="input-group mt-2"
                    v-for="(eachfiles, index) in list"
                    :key="eachfiles"
                  >
                    <div class="d-flex justify-content-between">
                      <span class="breakWord">{{ eachfiles.name }}</span>
                      <i
                        class="fa fa-close cursor-pointer"
                        @click.prevent="removefile(index)"
                      ></i>
                    </div>
                  </div>
                </div>
                <!-- <div class="error-block" v-if="validations[4] == true"> -->
                  <div class="error-block" v-if="validations[4]">
                  Please upload a file
                </div>
              </div>
              <div class="col-12 d-flex justify-content-end mt-3">
                <button
                  type="button"
                  class="btn browser-btn btn-xs px-4 float-right mr-3"
                  @click="loadfile()"
                >
                  Browse
                </button>
                <button
                  type="button"
                  class="btn btn-primary btn-xs px-4 float-right"
                  @click="submitFile()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./project_permission.js"></script>
<style scoped>
.permission-block-container {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  z-index: 3000;
}

.permission-block {
  background: #fff;
  width: min(500px, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}

.title-class1 {
  background-color: #618bd7;
  color: #fff;
}

.error-block {
  color: #ff0000;
}

.overflow-y-auto {
  height: 500px;
  overflow-y: scroll;
}

.setoverflow {
  overflow-y: auto;
}
.modal-header-docs {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.breakWord {
  word-break: break-word;
}
</style>

  