/* eslint-disable */
import axios from "axios";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import store from "../../store/index";
import SidePopUp from "../side_pop_up/side_pop_up.vue"
import { Alert } from "bootstrap";

export default {
    name: "projectList",
    components: {
        VPagination,
        SidePopUp,
    },
    data() {
        return {
            baseapi: process.env.VUE_APP_Service_URL,
            baseUrl: `${process.env.VUE_APP_Service_URL}configuration/project/`,
            selectedprojectname: "",
            currentPageNumber: 1,
            projectlist: "",
            proName: "",
            proType: "",

            showPrj: false,
            showBlock: false,
            sidePopupid: "",
            projectId: "",
            createProject: {
                projectName: "",
                projectCode: "",
                projectType: "",
                projectTypeId: "",
                description: "",
                active: true,
                lock: false
            },
            projectTypes: "",
            idtoken: "",
            selectedProjectType: {
                CreatedAt: "",
                CreatedUserEmail: "",
                CreatedUserId: "",
                CreatedUserName: "",
                LastModifiedAt: "",
                LastModifiedUserEmail: "",
                LastModifiedUserId: "",
                LastModifiedUserName: "",

                active: true,
                id: "",
                projectTypeName: ""
            },
            popupHeading:"CreateProject",
            errors: [false, false, false, false],
            errormessage: [],
            defaultErrorMessage: [
                "Please enter project name",
                "Please enter project code",
                "Please enter project type",
                "Please enter project description",
            ],


            head: "",
            designationPrivileges :"",
            destination:""
        };
    },
    async mounted() {
        this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
        console.log("Designation privileges are:", this.designationPrivileges)
        this.idtoken = store.getters.getToken;
        this.head = {
            headers: {
                Authorization: "Bearer " + store.getters.getToken,
                "Content-Type": "application/json",
            },
        };

        this.errormessage = [
            "Please enter project name",
            "Please enter project code",
            "Please enter project type",
            "Please enter project description",
        ];
        await this.getsearchdata();
        await this.getProjectTypes();

    },
    methods: {
        validate() {
            this.createProject.projectName = this.trimtext(this.createProject.projectName);
            this.createProject.projectCode = this.trimtext(this.createProject.projectCode);
            this.validateNull(this.createProject.projectName, 0);
            if (this.errors[0] == false) {
                this.validateLength(this.createProject.projectName, 0, 3, 50);
            }
            if(this.createProject.projectCode != null && this.createProject.projectCode !=''){
                this.validateLength(this.createProject.projectCode, 1, 3, 20)
            }
           
            if (this.createProject.projectType == null || this.createProject.projectType == "") {
                this.errors[2] = true;
                this.errormessage[2] = "Please select project type"
            }
            else {
                this.errors[2] = false;
            }
            this.validateLength(this.createProject.description, 3, 0, 250);
        },
        validateNull(value, errorindex) {
            let defaultErrorMessage = [
                "Please enter project name",
                "Please enter project code",
                "Please enter project type",
                "Please enter project description",
            ];
            console.log(value.trim(), "trim fun ", value)
            if (value.trim() == "" || value.trim() == null) {
                this.errors[errorindex] = true;
                this.errormessage[errorindex] = defaultErrorMessage[errorindex];
            }
            else {
                this.errors[errorindex] = false;
            }

        },
        validateLength(value, errorindex, min, max) {
            console.log("validate length", value)
            let defaultErrorMessage = [
                "Please enter project name",
                "Please enter project code",
                "Please enter project type",
                "Please enter project description",
            ];

            if (value.trim().length < min) {
                this.errors[errorindex] = true;
                this.errormessage[errorindex] = "Minimum number of character is " + min;
            }
            else if (value.trim().length > max) {
                this.errors[errorindex] = true;
                this.errormessage[errorindex] = "Maximum number of character is " + max;
            }
            else {
                this.errors[errorindex] = false;
                this.errormessage[errorindex] = defaultErrorMessage[errorindex];
            }
        },
        async GoToProjectSummary(id, proType, projectName) {
            //this.proType = this.projectlist.projectType;
            store.dispatch("setfileType",proType)
            store.dispatch("setfileName",projectName)
            console.log("id", id, proType);
            this.$router.push("./project_summary/" + id + "/" + proType + "/" + projectName)
        },
        sidePopup(id, name, type,e) {
            e=window.event
            this.event=e
            this.sidePopupid = id
            this.destination=type
            this.showBlock = !this.showBlock
            store.dispatch("setselectedid", id)
            store.dispatch("setfileType", type)
            store.dispatch("setfileName", name)
        },
        async createClicked(id) {
            this.projectId = id;
            if (id != null && id != "") {
                this.popupHeading = "Update project"
                await axios.get(this.baseUrl + `get?id=${id}`, this.head)
                    .then(res => {
                        this.createProject = res.data;
                        this.projectTypes.forEach(projectType => {
                            if (projectType.id == this.createProject.projectTypeId) {
                                this.selectedProjectType = projectType;
                            }
                        });
                    })
                    .catch(err => { console.log("get by id project error", err) })
            }
            else{
                this.popupHeading = "Create project"
            }
            console.log("in fn");

            this.showPrj = true;
        },
        async editproject() {
            this.createProject.projectType = this.selectedProjectType.projectTypeName;
            this.createProject.projectTypeId = this.selectedProjectType.id;
            this.validate();
            if (!this.errors.includes(true)) {
                await axios.put(this.baseUrl + `update?Id=` + this.projectId, this.createProject, this.head
                )
                    .then(res => {
                        console.log("update result", res.data)
                        if(res.data == "A project with same project name already exists."){
                            alert("A project with same project name already exists.");
                        }
                        else if(res.data == "A project with same project code already exists."){
                            alert("A project with same project code already exists.");
                        }
                        else{
                        this.getsearchdata();
                        alert("Project details updated successfully.")
                        this.clearPopUp();
                        this.showPrj = false;
                        }

                    })
                    .catch(err => {

                        console.log("error in updating project", err.response.data.detail);
                        if (err.response.data.errors.DomainValidations[0].includes("ProjectName is already taken")) {
                            alert("A project with same project name already exists.");
                        }
                        else {
                            alert(err.response.data.detail);
                        }


                    })
            }
        },
        async CreateOrEdit() {
            console.log("in create or edit", this.projectId);

            if (this.projectId != null && this.projectId != '') {
                await this.editproject();
            }
            else {
                await this.saveProject();
            }

        },
        onClosedPopup() {
            this.showPrj = false;
            this.errors = [false, false];
            this.clearPopUp();
        },
        clearPopUp() {
            this.createProject = {
                projectName: "",
                projectCode: "",
                projectType: "",
                projectTypeId: "",
                description: "",
                active: true,
                lock: true
            }
            this.selectedProjectType = "";
        },
        async saveProject() {

            this.createProject.projectType = this.selectedProjectType.projectTypeName;
            this.createProject.projectTypeId = this.selectedProjectType.id;
            this.validate();
            if (!this.errors.includes(true)) {
                this.createProject.lock = false;
                await axios.post(this.baseUrl + `create`, this.createProject, this.head
                )
                    .then(res => {

                        console.log("project created successfully", res);
                        this.getsearchdata();
                        alert("Project created successfully.")
                        this.clearPopUp();
                        this.showPrj = false;

                    })
                    .catch(err => {

                        console.log("error in creating project", err.response.data.detail);
                        if (err.response.data.errors.DomainValidations[0].includes("ProjectName is already taken")) {
                            alert("A project with same project name already exists.");
                        }
                        else {
                            alert(err.response.data.detail);
                        }


                    })
            }
        },
        async getProjectTypes() {
            await axios.get(this.baseapi + `configuration/projecttype/listprojecttype`, this.head

            )
                .then(res => {
                    console.log("project types are ", res);
                    this.projectTypes = res.data.entities;
                })
                .catch(err => {
                    console.log("error while getting project types", err);
                })
        },
        trimtext(value) {
            value = value.replace(
                /^\s+|\s+$/gm,
                ""
            );
            return value
        },
        async getsearchdata() {
            console.log(this.head);
            await axios
                .get(
                    this.baseUrl + `listproject?SortProperty=ProjectName&SortOrder=0`, this.head
                )
                .then((res) => {
                    console.log(" get project", res);
                    this.projectlist = res.data.entities;

                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async deleteProject(id) {
            let idtoken = store.getters.getToken;
            if (confirm("Are you sure you want to delete this project?")) {
                console.log("header :", idtoken);
                await axios.delete(this.baseUrl + `delete`, {
                    data: { "project_Id": id },
                    headers: {
                        "Authorization": "Bearer " + idtoken,
                        "Content-Type": "application/json",
                    },
                }
                )
                    .then(res => {
                        console.log("delete project", res);
                        alert("Project deleted successfully");
                    })
                    .catch(err => {
                        console.log("error in deleting project", err);
                    })
            }
            await this.getsearchdata();
        },
        async archiveproject(id) {
            let idtoken = store.getters.getToken;
            if (confirm("Are you sure you want to archive this project?")) {
                await axios.put(this.baseUrl + `archiveproject?id=` + id, {},
                    {
                        headers: {
                            "Authorization": "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                    .then(res => {
                        console.log("Archive project", res);
                        alert("Project Archived successfully");
                    }
                    )
                    .catch(err => {
                        console.log("error in archiving project", err);
                    })
            }
            await this.getsearchdata();
        }
    }
}