import axios from "axios";
import store from "../../store/index";
import SidePopUp from "../side_pop_up/side_pop_up.vue";
import Multiselect from "@vueform/multiselect"
export default {
  name: "fileFormatPopUp",
  components: {
    SidePopUp,
    Multiselect
  },
  props: {
    filetype:{
      type:String,
      default:""
    },
    blocknames:{
      type:String,
      default:""
    }
  },
  data() {
    return {
     idtoken:"",
      errorBlock:false,
      userName:"",
      user:"",
      foldervalues: {
        name: "",
        fileConfiguration:[]
    },
    SelectedFormat:[],
    foldertestlist:[],
      newUserList:[],
      newList:[],
      FormatList:[],
      permissionlist:[],
      List:[],
      newPerm:[],
      baseFileurl:``,
      baseurl: `${process.env.VUE_APP_Service_URL}document/folderfilepermission/`,
      baseapi: `${process.env.VUE_APP_Service_URL}configuration/designation/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      userList: [],
      designationList: [],
      selectedDesignation: [],
      selectedUser: [],
      selectedPermission: [],
      permissionList: [],
      UserpermissionList: [],
      getfolderbyids:{}
    }
  },
  async mounted() {   
    this.idtoken = store.getters.getToken
    document.body.style.overflowY = "hidden"
    await this.getallFormat();
    await this.getbyfolderid();
    //await this.getExistingData();
    console.log("Folder NAME is",this.blocknames);
  },
  unmounted() {
    document.body.style.overflowY = "auto"
  },
  methods: {
    async getallFormat() {
        await axios
            .get(`${this.mainapi}document/documentformat/search`,
            {
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              })
            .then((res) => {
                this.FormatList = res.data.entities[0].values;
                console.log("Format List",this.FormatList)
            })
            .catch((err) => {
                console.log(err);
            });
    },
    closeconfig(){
        this.$emit('closemodal')
        store.dispatch("setfileType",'')
      },
      async updatefolder() {
        const folderId = store.getters.getselectedid
        console.log("added format",this.SelectedFormat);
        let folderflag = true;
        // if (this.foldervalues.name == null || this.foldervalues.name == "") {
        //     this.errors[3] = false;
        // }
        // else {
            this.foldertestlist.forEach((testdata) => {
                if (testdata.name == this.foldervalues.name) {
                    folderflag = false;
                }
            });
            await axios
                .put(`${this.mainapi}document/folder/update?Id=${folderId}`,
                //.post(`https://localhost:5001/folder/create`,
                    {
                        "name": this.blocknames,
                        "storageId": "string",
                        "storageType": "string",
                        "fileConfiguration": this.SelectedFormat,
                        "versionConfiguration":  [{
                          "prefix": "",
                          "majorVerStart": "",
                          "minorVerStart": "",
                          "minorVermax": ""
                        }]
                    },
                    {
                        headers: {
                          Authorization: "Bearer " + this.idtoken,
                          "Content-Type": "application/json",
                        },
                      }
                )
                .then((res) => {
                    console.log("file configuration",this.SelectedFormat);
                    alert("Format added successfully");
                    this.close();
                    console.log(res);
                    this.createdFolderId = res.data;
                    this.showOrg = false;
                    document.body.style.overflowY = "auto"
                    this.createfolderpopup = false;
                })
                .catch((err) => {
                    // if (err.response.messages[0].includes("Folder name already present")) {
                    //     alert("folder name already exists");
                    // }
                });
            store.dispatch("reloadfolder", true);
            this.foldervalues.name = "";
            this.selectedDesignation = [];
            this.selectedPermission = [];
              this.SelectedFormat = [];
            this.selectedUser = [];
        //}
    },
    async getuserpermission() {
        const folderId = store.getters.getselectedid
        if(this.filetype=='folder'){
          await axios
          .post(`${this.baseurl}getfolderpermissions?FolderId=${folderId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            this.newUserList = res.data;         
          })
          .catch((err) => {
            console.log(err);
          });
        }
    },
    // async getExistingData() {
    //     //await this.getDesignationUsers();
    //     await this.getuserpermission();
    //     if (this.newUserList != null) {
    //       this.newUserList.forEach((item)=>{
    //         this.SelectedFormat.push(item.fileConfiguration)
    //         this.selectedPermission=item.permission
    //       })
    //     }
    //     console.log("Select users are",this.selectedUser)
    //   },
    //  
    // }
    async getbyfolderid() {
        const folderId = store.getters.getselectedid
        await axios
          .get(`${this.mainapi}document/folder/get?Id=` + folderId,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
          
          )
          
          .then((res) => {
            this.getfolderbyids = res.data;
            this.SelectedFormat = this.getfolderbyids.fileConfiguration;
            console.log("get by id",this.getfolderbyids);
          })
          .catch((err) => {
            console.log(err);
          });
      },
    close(){
            this.$emit('closemodal')
            store.dispatch("setfileType",'')
          }
}
  }
