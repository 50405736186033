<template>
  <div class="middle-box d-flex align-items-center text-center loginscreen animated fadeInDown">
    <div>
      <h3>
        Create password for : {{ UserDto.firstName }} {{ UserDto.lastName }}
      </h3>
      <p>{{ UserDto.primaryEmail }}</p>
      <form class="m-t" role="form" action="index.html">
        <div class="form-group position-relative">
          <input
            :type="inputType"
            class="form-control"
            placeholder="Password"
            v-model="newPassword"
          />
          <i
          class="password-visible-block cursor-pointer position-absolute"
          :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
          @click.prevent="passwordVisible('normal')"
        ></i>
        </div>
        <!-- <span class="errorClass" v-if="errors[0] == true">Invalid password </span> -->
        <span class="errorClass" v-if="errors[0] == true"
          >This password is not allowed. Please create a valid password
        </span>
        <div class="form-group position-relative">
          <input
            :type="confirmInputType"
            class="form-control"
            placeholder="Confirm password"
            v-model="confirmPassword"
          />
          <i
          class="password-visible-block cursor-pointer position-absolute"
          :class="[confirmVisibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
          @click.prevent="passwordVisible('confirm')"
        ></i>
        </div>
        <span class="errorClass" v-if="errors[1] == true">
          Password mismatch {{ errorMessage2 }}
        </span>
        <button
          type="submit"
          class="btn btn-primary block full-width m-b"
          @click.prevent="OnSaveButtonClicked"
        >
          Submit
        </button>

        <p class="m-t text-left">
          <small>
            <br /><span class="errorClass"> *</span> The number of characters in
            the password must be in between 8 to 20. <br /><span
              class="errorClass"
            >
              *</span
            >
            Password must be a combination of uppercase,lowercase, numbers and
            special characters(!@#$%^&*). <br /><span class="errorClass">
              *</span
            >
            Null or white space is not allowed.</small
          >
        </p>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import router from "@/router";
import { onBeforeMount } from "@vue/runtime-core";
import { encryptUser } from "../../common/encryption"
export default {
  name: "confirmUserInfo",
  props: { token: String },
  data() {
    return {
      errors: [false, false],
      baseapi: process.env.VUE_APP_Service_URL,
      baseUrl: `${process.env.VUE_APP_Service_URL}account-core/user/`,
      //  baseUrl: `https://d1ef-2401-4900-647c-9ad6-95d-f693-6e2b-9b4d.in.ngrok.io/user/`,
      newPassword: "",
      confirmPassword: "",
      inputType:"password",
      confirmInputType:"password",
      confirmVisibleOn:false,
      visibleOn:false,
      UserDto: {
        id: "",
        firstName: "",
        lastName: "",
        primaryEmail: "",
        secondaryEmail: "",
        username: "",
        userType: "",
        designation: "",
        country: "",
        dateOfBirth: "",
        phoneNumber: "",
        addressLineOne: "",
        addressLineTwo: "",
        active: true,
        lock: true,
        ID:"",
        urlstring:"",
        slitstring:"",
        eachdata:{}
      },
      UserUpdateDto: {
        password: "",
        token: "",
      },
    };
  },
  async beforeMount() {
    store.dispatch("setAuthorize", false);
    store.dispatch("setNavbar", false);
  },
  async mounted() {
    await this.validateToken();
    const pageWrapper = document.getElementById("page-wrapper");
    pageWrapper.style.width = "100%";
    await this.GetAndSetData(this.token);
  },
  methods: {
    async passwordVisible(type) {
      if(type==='normal')
      {
        this.visibleOn = !this.visibleOn
      }
      else{
        this.confirmVisibleOn = !this.confirmVisibleOn
      }      
      if (this.confirmVisibleOn == true) {
        this.confirmInputType = "text";
      } else this.confirmInputType = "password";
      if (this.visibleOn == true) {
        this.inputType = "text";
      } else this.inputType = "password";
    },
    async GetAndSetData(tokenValue) {
      await axios
        .get(this.baseUrl + "gettokenstatus?token=" + tokenValue +"&type=user")
        .then((res) => {
          console.log("Bind data is", res.data);
          this.UserDto = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async OnSaveButtonClicked() {
      this.validate();
      if (!this.errors.includes(true)) {
        const encryptedPassword = await encryptUser(this.newPassword.trim());
        this.UserUpdateDto.token = this.token;
        this.UserUpdateDto.password = encryptedPassword;
        this.errors[1] = false;
        await axios
          .post(this.baseUrl + "verifyuser", this.UserUpdateDto)

          .then((res) => {
            console.log(res);
            alert("The password has been successfully created");
            store.dispatch("setAuthorize", false);
            store.dispatch("setNavbar", false);
            localStorage.clear();
            store.dispatch("setToken", null);
            this.$router.push(`/`);
          })
          .catch((err) => {
            if (
              err.response.data.errors.DomainValidations[0].includes(
                "This Password Is Not Allowed"
              )
            ) {
              this.errors[0] = true;
            }

            console.log(err);
            
            if (
              err.response.data.errors.DomainValidations[0].includes("Password")
            ) {
              this.errors[0] = true;
            } else {
              this.errors[0] = false;
            }
            if (err.response.data.errors.DomainValidations[0].includes("Invalid Link")) {
                            console.log("REACHED IF")
                            alert("Password already assigned");
                        }
          });
      }
    },
    validate() {
      this.validateNull();
      //this.passwordFormat();
      this.passwordMatch();
    },
    validateNull() {
      if (this.newPassword == "" || this.newPassword == null) {
        this.errors[0] = true;
      } else {
        this.errors[0] = false;
      }
    },
    passwordMatch() {
      if (this.newPassword.trim() != this.confirmPassword.trim()) {
        this.errors[1] = true;
      } else {
        this.errors[1] = false;
      }
    },
    async validateToken(){
      console.log("ID issss",this.token)
          await axios.get(this.baseUrl + `gettokenstatus?token=${this.token}` +"&type=user",
           {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            })
             .then(res =>{
                    console.log("Get roles ",res.data)
                    this.roles = res.data
                  }
                     )
             .catch((err) => {
            console.log(err);
            if (err.response.data.errors.DomainValidations[0].includes("Not found")) {
                  console.log("REACHED IF")
                 
                    this.$router.push("/already_verified")
                  
                  
              }
          });

        },
  },
};
</script>

<style scoped>
.errorClass {
  color: red;
  font-size: 15px;
}
.submit_btn {
  align-self: center;
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
</style>
