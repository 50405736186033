<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="pl-md-10 col-10 col-md-10">
            <h2 class="my-0">Projects</h2>
        </div>
    </div>
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-12 col-md-3 pr-0">
                <div class="block-header py-2 text-center">
                    <h3 class="my-0 py-0">Projects</h3>
                </div>
                <div class="block-content py-4">
                    <ul class="mx-0 pr-5 py-3">
                        <li v-for="item in projectlist" :key="item.id">{{item.projectName}}</li>
                    </ul>
                    <div class="d-flex justify-content-center">
                        <button class="py-2 px-4" @click.prevent="createfolderModal()">Create Folder</button>
                        <input type="file" id="fileLoader" name="files" title="Load File" accept=".xls,.xlsx,.jpg,.jpeg,.png,.pdf" @change="previewFiles" />
                        <button class="py-2 px-4" @click.prevent="UploadDocumentModal()">Upload Files</button>
                    </div>
                    
                </div>
            </div>
            <div class="col-12 col-md-9">
                <div class="block-header py-2 d-flex justify-content-between px-3">
                    <h3 class="my-0 py-0">EIDSA</h3>
                    <div class="right-menu-block d-flex">
                        <div class="mr-3" @click.prevent="downloadfile()">
                            <img class="mr-1" src="../../assets/img/download.png" alt="Download file" width="15">
                            <span class="">Download</span>                            
                        </div>
                        <div class="mr-3" @click.prevent="deletefolder()">
                            <img class="mr-1" src="../../assets/img/delete.png" alt="Delete file" width="15">
                            <span class="">Delete</span>                            
                        </div>
                        <div>
                            <img class="mr-1" src="../../assets/img/move.png" alt="Move file" width="15">
                            <span class="">Move</span>
                        </div>
                    </div>
                </div>
                <div class="block-content p-3">
                    <div class="row">
                        <div v-for="files in filelist" :key="files" class="col-12 col-md-3 file-block mb-2">
                        <div v-if="!files.type">
                           <label class="d-block mb-0" for="image-box">
                                <div class="file-image text-center">                                    
                                    <input class="float-right m-1" type="checkbox" v-model="selection" :value="files" :id="files.id">  
                                    <img class="mt-3" src="../../assets/img/folder_large.png" alt="folder">                                                                      
                                </div>
                            </label>
                            <div class="file-description d-flex justify-content-between py-2 px-3">
                                <div class="file-details">
                                    <h4 class="my-0 py-0">{{files.name}}</h4>
                                    <span>{{files.date}}</span>
                                </div>
                                <div class="share cursor-pointer">
                                    <img src="../../assets/img/share.png" alt="Share your file" @click.prevent="openfolder(files.id)">
                                </div>
                                <div class="more cursor-pointer">
                                    <img src="../../assets/img/more.png" alt="More options">
                                </div>
                            </div>
                        </div>
                        <div v-if="files.type == 'pdf'" >
                            <label class="d-block mb-0" for="image-box2">
                                <div class="file-image text-center">                                    
                                    <input class="float-right m-1" type="checkbox" v-model="selection" :value="files" :id="files.id">   
                                    <img class="mt-3" src="../../assets/img/pdf_large.png" alt="folder">                                 
                                </div>
                            </label>
                            <div class="file-description d-flex justify-content-between py-2 px-3">
                                <div class="file-details">
                                    <h4 class="my-0 py-0">{{files.name}}</h4>
                                    <span>{{files.date}}</span>
                                </div>
                                <div class="share cursor-pointer">
                                    <img src="../../assets/img/share.png" alt="Share your file">
                                </div>
                                <div class="more cursor-pointer">
                                    <img src="../../assets/img/more.png" alt="More options">
                                </div>
                            </div>
                        </div>
                        <div v-if="files.type == 'mp4'">
                            <label class="d-block mb-0" for="image-box3">
                                <div class="file-image text-center">                                    
                                    <input class="float-right m-1" type="checkbox" v-model="selection" :value="files" :id="files.id">         
                                    <img class="mt-3" src="../../assets/img/video_large.png" alt="folder">                           
                                </div>
                            </label>
                            <div class="file-description d-flex justify-content-between py-2 px-3">
                                <div class="file-details">
                                    <h4 class="my-0 py-0">{{files.name}}</h4>
                                    <span>{{files.date}}</span>
                                </div>
                                <div class="share cursor-pointer">
                                    <img src="../../assets/img/share.png" alt="Share your file">
                                </div>
                                <div class="more cursor-pointer">
                                    <img src="../../assets/img/more.png" alt="More options">
                                </div>
                            </div>
                        </div>
                        <div v-if="files.type == 'docx' || files.type == 'doc' || files.type == 'rtf'">
                            <label class="d-block mb-0" for="image-box4">
                                <div class="file-image text-center">                                    
                                    <input class="float-right m-1" type="checkbox" v-model="selection" :value="files" :id="files.id">      
                                    <img class="mt-3" src="../../assets/img/word_large.png" alt="folder">                              
                                </div>
                            </label>
                            <div class="file-description d-flex justify-content-between py-2 px-3">
                                <div class="file-details">
                                    <h4 class="my-0 py-0">{{files.name}}</h4>
                                    <span>{{files.date}}</span>
                                </div>
                                <div class="share cursor-pointer">
                                    <img src="../../assets/img/share.png" alt="Share your file">
                                </div>
                                <div class="more cursor-pointer">
                                    <img src="../../assets/img/more.png" alt="More options">
                                </div>
                            </div>
                        </div>
                        <div v-if="files.type == 'xlsx' || files.type == 'xls'">
                            <label class="d-block mb-0" for="image-box4">
                                <div class="file-image text-center">                                    
                                    <input class="float-right m-1" type="checkbox" v-model="selection" :value="files" :id="files.id">      
                                    <img class="mt-3" src="../../assets/img/excel_large.png" alt="folder">                              
                                </div>
                            </label>
                            <div class="file-description d-flex justify-content-between py-2 px-3">
                                <div class="file-details">
                                    <h4 class="my-0 py-0">{{files.name}}</h4>
                                    <span>{{files.date}}</span>
                                </div>
                                <div class="share cursor-pointer">
                                    <img src="../../assets/img/share.png" alt="Share your file">
                                </div>
                                <div class="more cursor-pointer">
                                    <img src="../../assets/img/more.png" alt="More options">
                                </div>
                            </div>
                        </div>
                        <div v-if="files.type == 'png' || files.type == 'jpej' || files.type == 'jpj'">
                            <label class="d-block mb-0" for="image-box4">
                                <div class="file-image text-center">                                    
                                    <input class="float-right m-1" type="checkbox" v-model="selection" :value="files" :id="files.id">      
                                    <img class="mt-3" src="../../assets/img/excel_large.png" alt="folder">                              
                                </div>
                            </label>
                            <div class="file-description d-flex justify-content-between py-2 px-3">
                                <div class="file-details">
                                    <h4 class="my-0 py-0">{{files.name}}</h4>
                                    <span>{{files.date}}</span>
                                </div>
                                <div class="share cursor-pointer">
                                    <img src="../../assets/img/share.png" alt="Share your file">
                                </div>
                                <div class="more cursor-pointer">
                                    <img src="../../assets/img/more.png" alt="More options">
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>     
  <!-- folder create modal starts -->
        <!-- modal starts -->
      <div v-if="modeldisplay" class="modal__wrapperNew">
        <div class="modal__approver__container">
          <div class="titleclass d-flex justify-content-between py-2 px-3">
            <h4 class="my-0">Create Folder</h4>
            <div class="cursor-pointer" @click.prevent="ClosefolderModal()">
              <i class="fa fa-times"></i>
            </div>
          </div>
          <!-- Popup -->
          <div class="modal__content p-4 animated fadeIn">
            <div
              class="chat-form"
            >
              <form role="form">
                <div class="form-group">
                  <input
                    type="text"
                    v-model="createfoldername"
                    class="form-control"
                    placeholder="Comment"
                  />
                </div>
                 <div class="d-flex my-2 mb-md-0">
              <div class="assignbtncontainer">
                <button
                  class="btn btn-primary px-5 mx-1"
                  @click.prevent="createfolder()"
                >
                 upload
                </button>
              </div>
            </div>
             
              </form>
            </div>

        </div>
      </div>
      </div>
      <!--  popup-->
  <!-- folder create modal ends -->   
    </div>
    
</template>

<script src="./projects.js"> 
</script>

<style scoped>
@import './modal.css';
  .Page-active{
    padding:1rem;
    border:1px solid #6d57ce !important;
    border-radius: inherit;
    margin:0;

  }
.paginationpadding{
  padding-bottom: 10px;
  padding-right: 10px;
}
.pageContainer{
  margin-top: 30px;
}
.seperator{
  font-size: 23px !important;
}
.linkstyleleft{
font-weight: bold;
color: #d42ca2 !important;
font-size: 16px !important;
float: left;
}
.linkcontent{
  width:50%;
}
.errorborder{
  border-width: 2px !important;
border-color: red !important;
border-style: double;
}
.radioerror{
  margin-left: 16% !important;
}
.maximumwidth{
  inline-size: 150px;
overflow-wrap: break-word;
max-width: 150px;
}
/* .titleclass{
background-color:#6198fc;
}
.titlestyle {
    background-color: ());
} */
.hiddenvisibility{
  visibility: hidden;
}
.maincontainer{
  height: 100px;
      width: 100%;
      z-index: -2;
      position: absolute;
      background-color: blue;
      overflow: hidden;
}
.maincontainertwo{
      width: 100%;
      z-index: -1;
      position: absolute;
      background-color: white;
}
.bottommargin{
  margin-bottom: 25px;
}
.modal-container-upload {
  background: #fff;
  width: min(50%,90%);
  border-radius: 4px;
  position: fixed;
  transition: all .3s ease-in-out;
  height:auto !important;
  margin-top:200px;
}
.modal-container-view{
  background: #fff;
  width: min(50%,90%);
  border-radius: 4px;
  position: fixed;
  transition: all .3s ease-in-out;
  height:auto !important;
  margin-top:200px;

}
.modal__approver__container {
  width: min(38%, 80%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-x: hidden;
}
.modal__wrapperNew {
  position: fixed;
  background: rgb(0 0 0 / 18%);
  height: 100%;
  width: 100%;
  inset: 0;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: cubic-bezier;
  overflow: hidden;
}
.active {
    opacity: 1;
    pointer-events: auto;
    position: absolute;
}
#fileLoader
{
    display:none;
}
</style>