<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-2 col-12 col-md-10">
      <h2 class="my-0 py-0">Manage Designation</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i
        class="fa fa-times close cursor-pointer"
        @click="goBack"
      ></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
      <div class="ibox-content">
        <div class="form-group row mb-3">
          <div class="col-12 col-md-5 my-1 pr-md-1">
            <div class="form-group mb-md-0 pr-1">
              <label class="mb-0">Designation Name</label>
              <input
                type="text"
                class="form-control my-md-0"
                v-model="search.name"
              />
            </div>
          </div>
          <div class="col-12 col-md-5 my-1 pr-md-1">
            <div class="form-group mb-md-0">
              <label class="mb-0">Status</label>
              <select class="form-select" v-model="search.status">
                <option value="All" selected="">All</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-2 align-self-end my-1">
            <span
              type="button"
              class="submit_btn px-5 py-2 w-100"
              @click="OnSearchButtonClicked"
              >Search</span
            >
          </div>
        </div>
        <div
          class="ibox-title style_2 d-flex justify-content-between"
        >
          <h5 class="my-0 py-0 align-self-center">Designation</h5>
          <div
            v-if= "designationPrivileges.includes(`Designation Create Designation`)"
            class="position-relative align-self-center"
            aria-label="Create Designation"
            tabindex="0"
          >
            <img
              class="cursor-pointer"
              src="../../assets/img/create.png"
              alt="Create Designation"
              @click="createDesigfunc('createDesig')"
            />
            <div class="tool-tip">Create Designation</div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>

                <th class="sort_block" @click="SortSelected('name', 1)" id="1">
                  <div class="d-flex flex-row justify-content-between">
                    Designation
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th>
                  <div class="d-flex flex-row justify-content-between">
                    Description
                  </div>
                </th>
                <th class="text-center" v-if= "designationPrivileges.includes(`Designation View Privilege`)">Privilege</th>
                <th class="text-center">Active</th>
                <th class="text-center" v-if= "designationPrivileges.includes(`Designation Edit Designation`)">Edit</th>
                <th class="text-center" v-if= "designationPrivileges.includes(`Designation Delete Designation`)">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="designation in designationList" :key="designation.Id">
                <td>
                  <span
                    v-if="!retrievebox && designationId == designation.id"
                  >
                    <input
                      type="text"
                      class="form-control"
                      maxlength="50"
                      v-model="update.name"  
                    />
                  </span>
                  <span v-else>{{ designation.displayName }}</span>
                </td>
                <td width="40%">
                  <div
                    v-if="!retrievebox && designationId == designation.id"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="update.description"
                      maxlength="250"
                    />
                </div>
                  <div v-else class="description-block">{{ designation.description }}</div>
                </td>
                <td class="text-center" v-if= "designationPrivileges.includes(`Designation View Privilege`)"> 
                  <img
                      class="cursor-pointer"
                      src="../../assets/img/designation_privilege.png"
                      alt="Edit"
                      @click="AddDesignationPrivilege(designation.id,designation.displayName)"
                    />
                </td>
                <td class="text-center">
                    <div class="position-relative mt-2" v-if="!retrievebox && designationId == designation.id">
                      <input v-model="update.active" type="checkbox" />
                    </div>
                    <div class="position-relative" v-else>
                      <input
                        v-model="designation.active"
                        type="checkbox"
                        onclick="return false"
                      />
                    </div>
                </td>
                <td class="text-center" v-if= "designationPrivileges.includes(`Designation Edit Designation`)">
                  <div class="mt-1" v-if="!retrievebox && designationId == designation.id">
                    <img
                      class="cursor-pointer mr-2"  
                      alt="save the information"
                      src="../../assets/img/table_cell_save.png"
                      @click="
                        save(
                          designation.id,
                          update.name,
                          update.description,
                          update.active
                        )
                      "
                    >
                    <img 
                      class="cursor-pointer"
                      src="../../assets/img/table_cell_close.png"
                      alt="close the edit box"
                      @click="closebutton()"
                    >
                    </div>
                  <div v-else>
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/Edit.png"
                      alt="Edit"
                      @click="openEdit(designation.id)"
                    />
                  </div>
                </td>

                <td class="text-center" v-if= "designationPrivileges.includes(`Designation Delete Designation`)">
                  <div class="mt-1" v-if="!retrievebox && designationId == designation.id">
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/delete.png"
                      alt="Delete"
                      @click="deleteDesg(designation.id)"
                    />
                    </div>
                  <div v-else>
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/delete.png"
                      alt="Delete"
                      @click="deleteDesg(designation.id)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 d-flex justify-content-md-end px-0">
          <v-pagination
            v-model="currentPage"
            :pages="totalPage"
            :range-size="1"
            active-color="#ededed"
            @update:modelValue="loadPage"
          />
        </div>
      </div>
    </div>
  <!-- Popup library -->

  <div class="create-folder-modal" v-if="showDesig == true">
    <div class="modal-dialog modal-width">
      <div class="modal-content">
        <div class="modal-header-designation d-flex justify-content-bertween p-3" >
          <h5 class="my-0 align-self-center">Create Designation</h5>
          <span type="button" class="align-self-center" data-dismiss="modal"  @click="onclosed('createDesig')"
            aria-hidden="true"><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body">
        <div class="form-group mb-0">
          <label class="mb-0" for="designation-name">Designation Name</label>
          <span aria-label="mandatory field"
            class="error-block">*</span>
          <input
            type="text"
            id="designation-name"
            class="form-control"
            v-model="values.name"
            maxlength="75"
          />
        </div>
        <span class="errorClass" v-if="errors[0] == false"
          >Please enter designation name
        </span>
        <div class="form-group py-2">
          <label class="mb-0" for="description">Description</label>
          <textarea
            id="description"
            class="form-control"
            v-model="values.description"
            maxlength="250"
          ></textarea>
        </div>
        <div class="form-group d-flex justify-content-between mb-0">
          <div class="input-group">
            <label for="active-modal" class="mr-2 mb-0 align-self-center"
              >Active</label>
            <input type="checkbox" :checked="values.active == true" id="active-modal" v-model="values.active" />
          </div>
          <button type="button" @click="createDesig()" class="btn btn-primary btn-xs px-4 float-right ">Add</button>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- popup ends -->
</template>

<script src="./designation.js">
</script>

<style scoped>

.submit_btn {
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;

  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.position-relative img {
  width: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.headingunderline {
  text-decoration-line: underline;
}

.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}
.title-class1 {
  background-color: #618bd7;
  color: #fff;
}
.errorClass {
  color: red;
  font-size: 13px;
}
.button-class {
  float: right;
}
.modal-header-designation{
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-width{
  width:min(400px,90%)
}
.error-block{
color:#ff0000;
}
.description-block{
  text-wrap:wrap
}
</style>
