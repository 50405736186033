<template>
  <div class="create-user-modal1">
    <div class="modal-dialog">
      <div class="modal-content animated bounceInTop">
        <div class="modal-header-user d-flex justify-content-bertween approve">
          <h5 class="float-left mb-0">Approval</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="close()"
          >   
            <span aria-hidden="true">×</span><span class="sr-only">Close</span>
          </button>
        </div>
        <div class="modal-body">
          <label class="col-form-label">Comments</label>
          <textarea
            class="form-control message-input"
            name="message"
            placeholder="Enter your comments here"
            v-model="comments"
          ></textarea>
        </div>
        <div class="modal-footer-new text-center">
          <button
            type="button"
            class="btn btn-green-new text-white btn-xs px-4 mr-3"
            @click="ApprovalStatus(true)"
          >
            Approve
          </button>
          <button
            type="button"
            class="btn btn-danger-new btn-xs px-4 mr-3"
            @click="ApprovalStatus(false)"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";

export default {
  name: "ApproveRejectPopUp",
  props: {
    docId: {
      type: String,
      default: "",
    },
    version: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      status: "",
      comments: "",
      getbyId: "",
      documentUrl: `${process.env.VUE_APP_Service_URL}`,
    };
  },
  components: {},

  async mounted() {},
  methods: {
    close() {
      this.$emit("closemodal");
    },
    async ApprovalStatus(value) {
      
      if (value == true) {
        this.status = "Approved";
      } else {
        this.status = "Rejected";
      }
      if (value == true) {
            if(confirm("Are you sure you want to approve the document?"))
            {
              await this.approvalsave(value);
            }
          } else {
            if(confirm("Are you sure you want to reject the document?"))
            {
              await this.approvalsave(value);
            }
          }
      
    },
    
     async approvalsave(value){
      const idtoken = store.getters.getToken;
        await axios
        .put(
          `${this.documentUrl}document/document/approvereject`,
          {
            documentId: this.docId,
            version: this.version,
            approvalStatus: this.status,
            comments: this.comments,
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.refreshApprovalList();
          if (value == true) {
            alert("The document approved successfully.");
          } else {
            alert("The document rejected successfully.");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async refreshApprovalList() {
      await store.dispatch("setApprovalListRefresh", true);
      window.dispatchEvent(
        new CustomEvent("ApprovalStatusChange", {
          detail: {
            storage: store.getters.getApprovalListRefresh,
          },
        })
      );
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/style.css";
@import "../../assets/css/custom.css";
.create-user-modal1 {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
  align-items: flex-start !important;
}
.modal1 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-width {
  width: min(400px, 90%);
}

.btn-primary1 {
  color: #fff;
  background-color: #145faf;
  /* border-color: #1ab394; */
  border: none;
}

.modal-content1 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 150%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-header-user {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.close-user {
  cursor: pointer;
}

.create-user-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.modal-content2 {
  position: relative;
  display: flex;
  flex-direction: column;
  /* width: 200%; */
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.ibox-content-project {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 8px 20px 8px;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid;
  border-width: 1px;
}

.project-list1 table tr td {
  border-top: none;
  border-bottom: 1px solid #e7eaec;
  vertical-align: middle;
}

.scrollable-block {
  overflow-y: auto;
  height: 450px;
}

.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.btn-green-new {
  color: #fff;
  background-color: #1ab394;
  /* border-color: #1ab394; */
  border: none;
}
.btn-danger-new {
  color: #fff;
  background-color: #ed5565;
  border: none;
}
.modal-footer-new {
  margin-top: 0;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  /* border-bottom-left-radius: .3rem; */
}
.approve {
  padding: 10px 15px;
}
</style>