/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import TreeFolder from "../TreeFolder/TreeFolder.vue"
import fileshareTreeFolder from "../fileshareTreeFolder/fileshareTreeFolder.vue"
import Multiselect from "@vueform/multiselect";
import SidePopUp from "../side_pop_up/side_pop_up.vue"
import ProjectPermission from "../project_permission/project_permission.vue"
import FileFolderProjectPopup from "../FileFolderProjectPopup/FileFolderProjectPopup.vue"
import DocEditProjectPopup from "../DocEditPopup/DocEditPopup.vue"
import fileSharePopup from "../fileSharePopup/fileSharePopup.vue"
import DocumentPopup from "../DocumentPopup/DocumentPopup.vue";
import folderEditPopUp from "../editfolderpopup/editfolderpopup.vue"
import File_FolderPopup from "../File_FolderPopup/File_FolderPopup.vue"
export default {
  name: "generalFiles",
  components:{
    fileshareTreeFolder,
    TreeFolder,
    Multiselect,
    SidePopUp,
    FileFolderProjectPopup,
    ProjectPermission,
    DocEditProjectPopup,
    fileSharePopup,
    DocumentPopup,
    folderEditPopUp,
    File_FolderPopup
  },
  data() {
    return {
        versionid:"",
        baseApi: `${process.env.VUE_APP_Service_URL}`,
        docurl: `${process.env.VUE_APP_Service_URL}document/document/`,
        url:`${process.env.VUE_APP_Service_URL}document/folder/`,
        projectid:"",
        Head:"",
        docId:"",
        folderList:[],
        documentlist:[],
        SelectedFormat: [],
        errors: [],
        userpermissions:[],
        FormatList:[],
        folderErrors: [false],
        folderErrorMessages: ["Please enter a folder name"],
        createFolder: {
            folderName: "",
            role: [],
            user: [],
            permissions: [],
            fileConfiguration: [],
            versionConfiguration: [],
          },
        mainfolderbold: "",
        openfolderlist: "",
        sourceType:"",
        createfolderpopup: false,
        showDocBlock:false,
        showProjectBlock:false,
        anyuserpermissions:false,
        showProjectPermPopup:false,
        isusercanaddfolder:false,
        isuserenabled:false,
        editDocPopup:false,
        showProjectSharePopup:false,
        showPermPopup:false,
        docupload:false,
        popupid:"",
        sendtoValue : "",
        editValue : "",
        permissionsValue : "",
        shareValue : "",
        deleteValue : "",
        archiveValue : "",
        idtoken:"",
        prefix:"",
        majorVerStart:"",
        minorVerStart:""
    }
  },
  async beforeUnmount() {
    this.documentlist = []
  },
  beforeRouteLeave(to, from, next) {
    // Log the next route path before leaving the current route
    console.log('Next Route Path:', to.path);
    if(!to.path.includes("/documentview/")){
      store.dispatch("setDepartment", "");
      store.dispatch("selectFolder", "");
    }
    next();
  },
  async mounted() {
    await store.dispatch("setSideNavbar", false);
    store.dispatch("selectFolder", "");
    this.openfolderlist = store.getters.getopenfolder;
    this.Head = {
        headers: {
          Authorization: "Bearer " + store.getters.getToken,    
          "Content-Type": "application/json",
        },
      };    
    await this.getRootFolders()
    store.dispatch("setFromPage", "generalFiles")
    store.dispatch("selectFoldermove",'')
    this.isusercanaddfolder = true
  },
  watch: {
    "$store.getters.getselectedFolder": function (val) {
      if(val!=""){
      this.mainfolderbold = val;
      this.docId = store.getters.getselectedFolder
      this.getFolderUserpermission(store.getters.getselectedFolder)
      this.getdocuments(store.getters.getselectedFolder)
      }
    },
  },
  methods:{
    clickedOut(e){
      if(e.relatedTarget.id==='primaryTree')
      {
        this.mainfolderbold = "";
        store.dispatch("selectFolder", "");
        this.isusercanaddfolder = true;
      }
    },
    async getRootFolders() {
        await axios
        .get(this.baseApi + `document/externaluser/getsharedfolders`, this.Head
        )
        .then((res) => {
        this.folderList = res.data;

        })
        .catch((err) => {
        console.log(err.response.data.messages)
        })

    },
    async getdocuments(id) {
        this.fromPage = "ProjectSummary";
        this.documentlist = []
        await axios
          .get(
            `${this.baseApi}document/folder/getprivilegedcontent?Id=${id}`,
            this.Head
          )
          .then((res) => {
            this.documentlist = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    async uploadeditactivity() {
      this.editDocPopup = false;
      this.getdocuments(store.getters.getselectedFolder);
    },
    async getallFormat() {
        await axios
            .get(`${this.baseApi}document/documentformat/search`,
            this.Head)
            .then((res) => {
            this.FormatList = res.data.entities[0].values;
            })
            .catch((err) => {
            console.log(err);
            });
    },
    async getDocumentdetails(id) {
        this.sendtoValue = "";
        this.editValue = "";
        this.permissionsValue = "";
        this.shareValue = "";
        this.deleteValue = "";
        this.archiveValue = "";
        await axios
          .get(
            `${this.docurl}getdocumentdetails?documentId=${id}`,
            this.Head
          )
          .then((res) => {
               
            if (res.data.permissions != null) {
                this.anyuserpermissions=false;
                
              res.data.permissions.forEach((item) => {
                if (item == "Clone") this.sendtoValue = item;
                if (item == "Permissions") this.permissionsValue = item;
                if (item == "Share") this.shareValue = item;
                if (item == "Edit") this.editValue = item;
                if (item == "Delete") this.deleteValue = item;
                if (item == "Archive") this.archiveValue = item;
              });
              if (
                !this.sendtoValue &&
                !this.permissionsValue &&
                !this.shareValue &&
                !this.editValue &&
                !this.deleteValue &&
                !this.archiveValue
              ) {
                this.anyuserpermissions=true;
              }
            }
            else{
                this.anyuserpermissions=true;
            }
            // this.getVersionHistory();
          })
          .catch((err) => {
            console.log(err);
          });
      },
      async FilePermissionPopup() {
        this.showPermPopup = !this.showPermPopup
        this.sourceType = "File"
        this.showDocBlock = false;
      },
      async getFolderUserpermission(folderid)
      {
      //get user privileged folder controls
      await axios
      .get(`${this.baseApi}document/folderfilepermission/getuserfolderpermission?FolderId=${folderid}`,
        this.Head
      )
      .then(async (res) => {
      this.isuserenabled = false;      
      this.userpermissions  = res.data != null? res.data : null;
      if(this.userpermissions !=null){
          if(this.userpermissions.includes("Upload"))
          {
            this.isuserenabled = true;
          }
          if(this.userpermissions.includes("Add"))
          {
            this.isusercanaddfolder= true;
          }
          else{
              console.log(89)
            this.isusercanaddfolder= false;
          }
      }
       else{
           this.isusercanaddfolder= false;
       }        
      })
      .catch((err) => {
       console.log(err);
      });
      },
    async createfolder() {
        this.validateFolder();
        this.minverError = false;
        if (this.minorVermax != "" && parseInt(this.minorVerStart) > this.minorVermax) {
          this.minverError = true;
        }
        else {
          if (!this.folderErrors.includes(true)) {
            await axios
              .post(this.baseApi + `document/folder/create`,
                {
                  name: this.createFolder.folderName,
                  parentId: store.getters.getselectedFolder,
                  storageId: this.projectid,
                  storageType: "General",
                  fileConfiguration: this.SelectedFormat,
                  "versionConfiguration": [
                    {
                      "prefix": this.prefix,
                      "majorVerStart": this.majorVerStart,
                      "minorVerStart": this.minorVerStart,
                      "minorVermax": this.minorVermax
                    }
                  ]
                },
                this.Head
              )
              .then((res) => {

                alert("Folder created successfully.");
                this.closeModelCreateFolder();
                this.getRootFolders();
                document.getElementById(store.getters.getselectedFolder + 'doc').click();
                document.getElementById(store.getters.getselectedFolder + 'doc').click();
              })
              .catch((err) => {
                console.log(err.response.data.detail)
                if (err.response.data.detail == "Folder name already present in the parent folder.") {
                  alert("Folder name already exist.");
  
  
                }
                else if(err.response.data.detail == "Major version start field cannot be empty."){
                  alert("Major version start field cannot be empty.");
                }
                else {
                  if(err.response.data.detail == "Folder name already present in the parent department.")
                  
                  {
                    alert("Folder name already exist.");
                  }
                }
              });
          }
        }
      },
    viewDoc(id,version) {
        store.dispatch("setDocViewSource", "generalFiles")
        this.$router.push(`/externalDocumentView/` + id + "/" + version )
        
    },
    closeModelCreateFolder() {
        this.createFolder.folderName = "";
        this.folderErrors[0] = false;
        this.createfolderpopup = false;
        this.SelectedFormat = [];
    },
    async sharefolder() {
        this.showSharePopup = !showSharePopup;
        this.sourceType = file;
        console.log("it works")
      },
    async cls() {
        let val = store.getters.getselectedFolder
        this.docupload = true;
        this.mainfolderbold = val;
        this.folderversion = this.folderversion;
        this.folderfordocument = val;
        this.docId = store.getters.getselectedFolder
        this.getdocuments(store.getters.getselectedFolder)
        this.showProjectBlock = true;
        await this.getFolderversion(val);
  
    },
    sidedocPopup(index, id, name, type, e) {
        this.getDocumentdetails(id);
        e = window.event
        this.event = e
        this.popupid = id
        this.arrayIndex = index
        this.showDocBlock = !this.showDocBlock
        store.dispatch("setselectedid", id)
        store.dispatch("setfileType", type)
        store.dispatch("setfileName", name)
      },
    trimtext(value) {
        value = value.replace(
            /^\s+|\s+$/gm,
            ""
        );
        return value
    },
    openModelCreateFolder() {
        this.prefix = "";
        this.majorVerStart = "";
        this.minorVerStart = "";
        this.minorVermax = "";
        this.createfolderpopup = true;
        this.getallFormat();
        this.SelectedFormat = [];
        this.errors[3] = true;
        this.versionConfiguration = [];
        this.minverError = false;
      },
   
      validateFolder() {
        this.createFolder.folderName = this.trimtext(this.createFolder.folderName);
        if (this.createFolder.folderName == "") {
          this.folderErrors[0] = true;
          this.folderErrorMessages[0] = "Please enter a folder name";
        }
        else if (this.createFolder.folderName.length > 75) {
          this.folderErrors[0] = true;
          this.folderErrorMessages[0] = "Maximum number of characher is 75";
        }
        else {
          this.folderErrors[0] = false;
        }
      },
      async DocEditPopup(docid, versionid) {
        this.versionid = versionid;
        this.editDocPopup = !this.editDocPopup
        this.showDocBlock = false;  
      },
      async ShareDocument() {
        this.showProjectSharePopup = !this.showProjectSharePopup
        this.sourceType = "file"
      },
      async DeleteDocument(id) {
        this.showDocBlock = false;
        const documentId = id
        if (confirm("Are you sure you want to delete this document?")) {
          await axios.delete(this.docurl + "delete?documentId=" + documentId,
            this.Head
          )
            .then((res) => {
              console.log(res)
              alert("Document deleted successfully")
            })
            .catch((err) => {
              console.log(err);
              this.documentlist = []
              alert("Somthing Went Wrong")
            })
          this.pageindex = 1;
          this.currentPage = 1;
          this.getdocuments(store.getters.getselectedFolder);
        }
      },
      async ArchievedDocument(id) {
        this.showDocBlock = false;
        const documentId = id;  
        if (
          confirm("Are you sure you want to archive this document ?")
        ) {
          await axios
            .put(`${this.baseApi}document/document/archive?id=${documentId}`, {},  this.Head)
            .then((res) => {
              alert("Document archived successfully")
            })
            .catch((err) => {
              console.log(err.message)
            })
          this.pageindex = 1;
          this.currentPage = 1;
          this.getdocuments(store.getters.getselectedFolder);
        }
       
      },
      async uploadmethodactivity() {
        this.docupload = false;
        this.getdocuments(this.folderfordocument);
    },
    async getFolderversion(folderid) {

      await axios
          .get(
              // `https://localhost:5001/folder/getfolderversion?Id=${folderid}`,
              `${this.url}getfolderversion?Id=${folderid}`,
              this.Head)
          .then((res) => {
            
              console.log("VERSIONN issss", typeof(res.data))
              this.folderversion = res.data;
              if(this.folderversion.includes(':'))
              {
                  this.folderversion = (this.folderversion).replace(':','.');
              }
              console.log("CONVRT VERSIONN issss", this.folderversion)
          })
          .catch((err) => {
              console.log(err);
          });
          this.getFolderUserpermission(folderid) 
  },
  isLetter(e) {
    let char = String.fromCharCode(e.keyCode); // Get the character
    if (/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
    else e.preventDefault(); // If not match, don't add to input text
    },
    isNumber: function (evt,vtype) {
                
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      const getmaj = vtype;
      if(getmaj =='major'){
          if(this.majorVerStart.length==0 && charCode ==48){
              evt.preventDefault();
          }
      }
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 || charCode == 46) {
          evt.preventDefault();
      } else {
          return true;
      }

    },
}
}