/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
export default {
	name: "login",
	data() {
		return {
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      idtoken:"",
      selection: [],
			createfoldername:"",
			modeldisplay: false,
			currentproject:"",
			projectlist: [],
			filelist: []
		};
	},
	async mounted() {
    this.idtoken = store.getters.getToken
		await this.getprojects();
		await this.getfolders();
	 },
    methods: {
		UploadDocumentModal(){
			document.getElementById("fileLoader").click();
		  },
		  createfolderModal(){
			this.modeldisplay = true;
		  },
		  ClosefolderModal(){
			this.modeldisplay = false;
		  },
      async downloadfile(){
        this.selection.forEach(async (iddata)=>{
          if(iddata.type){
        var a = document.createElement("a");
      a.href = `${this.mainapi}document/document/get?documentId=${iddata.id}`;
      a.download = iddata.name;
      a.click();
          }
        });
      },
      async deletefolder(){
        this.selection.forEach(async (iddata)=>{
          if(!iddata.type){

        await axios
                  .delete(`${this.mainapi}document/folder/delete?Id=${iddata.id}`,
                  {
                    headers: {
                      Authorization: "Bearer " + this.idtoken,
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                }
                else{
                  await axios
                  .delete(`${this.mainapi}document/document/delete?documentId=${iddata.id}`,
                  {
                    headers: {
                      Authorization: "Bearer " + this.idtoken,
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                }
                });
                
                if(this.currentproject == null || this.currentproject == ""){
                  this.getfolders();
                }
                else{
                this.openfolder(this.currentproject);
                }
      },
		  async previewFiles(event) {
			this.Document = event.target.files[0];
			console.log("upload file is", this.Document);

	var formData = new FormData();
    formData.append("file", this.Document);


			await axios
      .put(`${this.mainapi}document/document/upload?FolderId=${this.currentproject}`, formData, 
      {
        headers: {
          Authorization: "Bearer " + this.idtoken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("retreieved data", response);
		this.openfolder(this.currentproject);
      })
      .catch((err) => {
        console.log("error", err);
      });
			},
		async createfolder(){
			await axios
        .post(
          `${this.mainapi}document/folder/create`,
          {
            name: this.createfoldername,
  			parentId: this.currentproject
          },
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
            console.log(res);
            alert("Folder Created Successfully");
            this.ClosefolderModal();
			this.openfolder(this.currentproject);
        })
        .catch((err) => {

          console.log(err);
        });
		},
		async openfolder(id){
			await axios
			.get(
				`${this.mainapi}document/folder/getcontent?Id=${id}`,
        {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        }
			)
			.then((res) => {
				this.currentproject = id;
				this.filelist = (res.data.folders).concat(res.data.documents);
			})
			.catch((err) => {
				console.log(err);
			});

		},
		async getfolders(){
			await axios
                .get(
                    `${this.mainapi}document/folder/getchildren`,
                    {
                      headers: {
                        Authorization: "Bearer " + this.idtoken,
                        "Content-Type": "application/json",
                      },
                    }
                )
                .then((res) => {
                    this.filelist = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });

		},
		async getprojects(){
			await axios
                .get(
                    `${this.mainapi}configuration/project/getall`,
                    {
                      headers: {
                        Authorization: "Bearer " + this.idtoken,
                        "Content-Type": "application/json",
                      },
                    }
                )
                .then((res) => {
                    this.projectlist = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
		}
    }
}