import axios from "axios";
import store from "../../store/index";
import { encrypt } from "../../common/encryption"
export default {
  name: "DigitalSignPopUp",
  props: {
    acknId: {
      type: String,
      default: "",
    },
    SigningPerson: {
      type: String,
      default: "",
    },
    Source:{
      type: String,
      default: "",
    },
    objectData:{
      type:Object,
      default:{}
     },
     numberType:{
      type:Number,
      default:0
     }
  },
  data() {
    return {
      FromPage:"",
      loadingBlock: false,
      baseControlApi: `${process.env.VUE_APP_Service_URL}`,
      baseapi: `${process.env.VUE_APP_Service_URL}document/trainingplan/`,
      baseurl: `${process.env.VUE_APP_Service_URL}document/projectdevdocumenttemplate/`,
      values:[{Reason:'',IsSigned:false},{Reason:'',IsSigned:false},{Reason:'',IsSigned:false}],
      idtoken: "",
      head: "",
      showsign: false,
      sendUpdate:false,
      password: "",
      emailId: "",
      reasons:"",
      location:"",
      locationList:[],
      reasonList:[],
      error:[false.false],
      sampleVar: "signAdded",
      err: "Invalid username or password.",
      errMsg:false,
      visibleOn:false,
      inputType:"password",
      validation: false,
      otherReason:""
    }
  },
  mounted(){
    this.FromPage= store.getters.getFromPage
    this.getLocation()
    this.getReasons()
  },
  methods: {
    closePopup(){
      store.dispatch("setFromPage", "");
      this.$emit("closePopup");
      this.reason=""
      this.password=""
      this.emailId=""
      this.errMsg = false
      this.error=[false,false]
    },
    selectItem(option) {    
      this.reasons = option;
      this.isListVisible = false;
    },
    async getLocation(){
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseurl}getalllocation`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.locationList=res.data
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    async getReasons(){
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseurl}getallreason`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.reasonList=res.data
        })
        .catch((err) => {
          console.log("Error",err);
        });
    },
    async ConfirmClicked() {  
      let apiResultValue=''   
      this.validation = false
      this.error[1]=false
      if(this.reasons==='Others'){         
          apiResultValue=this.otherReason
          if(apiResultValue==='')
            this.error[0]=true;
      }
      else apiResultValue=this.reasons
      if(this.reasons==='' || this.password===''){
        if(this.reasons==='')
        {
          this.error[1]=true;
        }
        if(this.password==='')
        {
          this.errMsg=true;
        }
       
      }
    else{
      this.error=[false,false]
      this.errMsg=false      
      this.loadingBlock = true;
      const idtoken = store.getters.getToken;     
      const encryptedPassword = await encrypt(this.password.trim());
      if(this.FromPage=="approveTraining"){
        await axios
        .get(
          `${this.baseapi}signtraining?TrainingId=${this.acknId}&email=${this.emailId}&password=${encryptedPassword}&reason=${apiResultValue}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.validation = false;
          this.$emit("signAdded", res.data);
          this.loadingBlock = false;
          alert("Signed successfully")
          this.closePopup()
        })
        .catch((err) => {
          this.loadingBlock = false;
          this.validation=false
          if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.DomainValidations && err.response.data.errors.DomainValidations[0])
          {
            this.err = err.response.data.errors.DomainValidations[0];
          }
          else {
            this.err = "Invalid username or password."
          }
          this.validation = true;
          this.password = "";
        }); 
      }
      else if(this.Source==='controlRecords'){
        this.objectData.Signers[this.numberType].Reason=this.reasons
        this.objectData.Signers[this.numberType].IsSigned=true
        if(this.objectData.Signers[this.numberType].UserEmail!=this.emailId)
        {
          this.validation=true
          this.loadingBlock=false
          this.err="Email id differs from logged-in user's email id"
          
        }
        else{
          const encryptedDocPassword = await encrypt(this.password.trim()); 
          await axios
          .post(
            `${this.baseControlApi}document/signature/userauthorize`,
           {
            "password": encryptedDocPassword
           },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("res",res.data)
            this.sendUpdate=true
          })
          .catch((err) => {
            this.loadingBlock = false;
            this.validation=false
            if (err.response.data.errors && err.response.data.errors.DomainValidations[0])
            {
              this.err = "Invalid password";
            }
            this.validation = true;
            this.password = "";
          }); 
          if(this.sendUpdate==true)
          {        
            
          await axios
          .put(
            `${this.baseControlApi}document/projectdevdocumenttemplate/updatechangecontrol`,
            this.objectData,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.loadingBlock = false;
            alert("Document signed successfully")
            if(this.objectData.Signers[2].IsSigned==true){
              this.$emit("getData")
            }
            this.$emit("Signed")          
            this.closePopup()
          })
          .catch((err) => {
            this.loadingBlock = false;
            this.validation=false
            if (err.response.data.errors)
            {
              this.err = "Invalid input";
            }
            else {
              this.err = "Invalid username or password."
            }
            this.validation = true;
            this.password = "";
          }); 
          }
        }
       
      }
      else{
        await axios
        .get(
          `${this.baseurl}signsopdocuments?AcknoweldgementId=${this.acknId}&SigningPerson=${this.SigningPerson}&email=${this.emailId}&password=${encryptedPassword}&reason=${apiResultValue}&location='India'`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.validation = false;
          this.$emit("signAdded", res.data);
          this.loadingBlock = false;
        })
        .catch((err) => {
         
          this.loadingBlock = false;
          if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.DomainValidations && err.response.data.errors.DomainValidations[0])
          {
            this.err = err.response.data.errors.DomainValidations[0];
          }
          else {
            this.err = "Invalid username or password."
          }
          this.validation = true;
          this.password = "";
        });
      }
      
    }
      
    },
    passwordVisible(){
      this.visibleOn=!this.visibleOn
        if(this.visibleOn==true){
          this.inputType="text"
        }
        else this.inputType="password"
        
    }
  }
}