<template>
  <span>
 
    <ul v-for="list in list" :for="list.name" :key="list.name">
      <!-- {{ folderOpenIcon[list.id] }} -->
      <li class="d-flex justify-content-between">
        <div class="d-flex">
          <i v-if="foldertree[list.id]" class="fs-4 mr-1 cursor-pointer"
            :class="[folderswitch[list.id] ? 'fa fa-caret-down' : 'fa fa-caret-right']" :aria-expanded="folderswitch[list.id]"
            @click.prevent="getfolder(list.id, false);"></i>
          <i v-else class="fa fa-caret-right text-white mr-1 opacity-0" aria-hidden="true"></i>
          <input type="checkbox" :id="list.id + treeid" @click.prevent="getfolder(list.id, false);" />
          <label :for="list.id + treeid" :class="
            selectedboldfolder == list.id? 'tree_label tree_label_bold' : 'tree_label'
          " class="tree_label">
           {{ list.name }}</label>
        </div>
        <div v-if="$store.getters.getshowMoreBlock" class="px-2 more-option-icon cursor-pointer"
          v-on:clickout="showBlock = false"
          @click.prevent="selectitem(list.id); sidePopup(list.id, list.name, 'folder', e,SourcePage)">
          <i class="fa fa-ellipsis-v mr-3"></i>
        </div>
      </li>
      <SidePopUp v-if="showBlock && popupid == list.id" :blockid="popupid" :ev="event"
      :dest="destination" 
      v-on:clickout="showBlock = false">
        <div  v-if="this.anyuserpermissions" style="pointer-events: none;" >Nothing to show</div>
        <!-- <div  v-if="this.userpermissions.includes('Add')" >Add</div> -->
        <div  v-if="this.userpermissions.includes('Edit')"  @click="$store.dispatch('seteditvalue', true)">Edit</div>
        <div  v-if="this.userpermissions.includes('Clone') && $store.getters.getFromPage != 'DocumentList'"   @click="move(list.id)">Clone</div>
        <div  v-if="this.userpermissions.includes('Permissions')"   @click="FilePermissionPopup(list.id)">Permissions</div>
        <!-- <div  v-if="this.userpermissions.includes('File Type')"   @click="$store.dispatch('setformatvalue', true)">File Format</div>// File format popup is disabled as per req. -->
        <!-- <div  v-if="this.userpermissions.includes('Share')"   @click="getfolder(list.id, true)">Share</div> -->
        <div v-if="this.userpermissions.includes('Share')" @click="shareFolder(list.id)">Share</div>
        <div  v-if="this.userpermissions.includes('Delete')"   @click="deleteFolder(list.id, list.ancestorIds, true)">Delete</div>
        <div  v-if="this.userpermissions.includes('Archive')"   @click="archiveFolder(list.id, list.ancestorIds)">Archive</div>
        <div v-if="SourcePage == 'projectlist'&& this.userpermissions.includes('Share')" @click.prevent="showExternalSharePopup=true,showBlock=true">Share - External User</div>
        <div v-if="this.userpermissions.includes('Download')" @click="downloadFolder(list.id)">Download</div>
        <div v-if="this.userpermissions.includes('Upload')" @click.prevent="openSub(e)">
          Create <span class="fa fa-angle-right"></span>
        </div>
       
      </SidePopUp>  
      <subPopup v-if="showsubPopup && popupid == list.id"  :dest="destination" :ev="event" v-on:clickout="showsubPopup = false" :blockid="popupid">
        <div @click.prevent="$router.push(`/controlRecords/${list.id}/0`)">
          Control Form
        </div>
      </subPopup>      
      <TreeFolder v-if="folderswitch[list.id]" projectId="" :list="foldertreelist[list.id]" :boldfolder="boldfolder"
        :openfolderlist="openfolder" :SourcePage="destination" patid="" :treeid="treeid" clasification="folderlists"
        @changePerm="close()">
      </TreeFolder>
    </ul>
  </span>
  <teleport to="#app" >
  <File_FolderPopup
    v-if="showPermPopup"
    @closemodal="close()"
    :list="popupid"
    :source="sourceType"
  >
  </File_FolderPopup>
  </teleport>
  <teleport to="#app" >
  <FileFolderProjectPopup
    v-if="showProjectPermPopup"
    @closemodal="closeProjectPopup()"
    :list="popupid"
    :source="sourceType"
    :projectId="currentProjectId"
  >
  </FileFolderProjectPopup>
</teleport>
<teleport to="#app">
  <externalfoldershare
    v-if="showExternalSharePopup == true"
    @closemodal="closeExternalShareFolderPopup()"
    :list="popupid"
    :source="sourceType"
    :projectId="currentProjectId"
    
  >
  </externalfoldershare>
  <!-- <clonePopup
    v-if="clonePopups == true"
    @closemodal="closeClonePopUp()"
    :showBlocks="clonePopups"
    :dest="destination"
    :list="popupid"
    :source="sourceType"
    :projectId="currentProjectId"    
  > 
  </clonePopup>-->
</teleport>
  <teleport to="#app">
  <foldershare_popup
    v-if="showSharePopup"
    @closemodal="closeFolderPopup()"
    :list="popupid"
    :source="sourceType"
    :projectId="currentProjectId"
    
  >
  </foldershare_popup>
</teleport>
  <teleport to="#app">
    <MoveFolder v-if="moveBlock" :treeId="$store.getters.getDepartment" :folderBold="$store.getters.getselectedFolder"
      :movableFolder="movedFolder" @closemodal="reloadFoldersProject" >
    </MoveFolder>
     <MoveProjectFolder v-if="moveprojBlock" :treeId="projectId" :folderBold="$store.getters.getselectedFolder"
      :movableFolder="movedFolder" @closemodal="moveprojBlock = false">
    </MoveProjectFolder>
  </teleport>
  
</template>

<script src="./TreeFolder.js">
</script>

<style  scoped>
@import "./tree.css";

.tree_label_bold {
  font-weight: bold;
}

.more-option-icon {
  display: none;
}

span ul li:hover,
span ul li:focus-visible,
span ul li:hover .tree_label {
  background-color: #e4eaf9;
}

span ul li:hover .more-option-icon {
  display: block;
}

.folder_tree_label {
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 5px 0 5px 1rem;
  position: relative;
}

.folder_tree_label:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.folder_tree_label:before {
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  margin: 0 0.5em 0 -2em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  content: url("../../assets/img/folder-close.png");
  text-align: center;
  line-height: 0.8em;
}

.myStyle {
  color: blue;
}

.innertemplate {
  display: block;
}

.bold-font {
  font-weight: bold;
}

.vtl.vtl-drag-disabled {
  background-color: #d0cfcf;
}

.vtl.vtl-disabled {
  background-color: #d0cfcf;
}

.icon:hover {
  cursor: pointer;
}

.muted {
  color: gray;
  font-size: 80%;
}

.hideandseek {
  display: none !important;
}

.popupbtn {
  color: black;
  float: right;
}

.oneline {
  display: flex;
  justify-content: space-between;
}
.opacity-0{
  opacity:0;
}
</style>
