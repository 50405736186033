/* eslint-disable */
import axios from "axios";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
	name: "projectlist",
    components: {
        VPagination,
    },
	data() {
		return {
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      selectedprojectname: "",
      totalLists: 0,
      currentPageNumber: 1,
			projectlist: "",
			
		};
	},
    async mounted() {
      store.dispatch("setDepartment", "");
      store.dispatch("selectFolder", "");
       await this.getsearchdata();
    },
    methods: {
        async deleteproject(id){
            if (confirm("Are you sure you want to delete this project?")) {
                await axios
                  .delete(`${this.mainapi}configuration/project/delete`,
                  {data: {
                    project_Id: id
                  }},
                   {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    console.log(res);
                    alert("Project deleted successfully");
                    this.getsearchdata();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }

        },
       async createproject(id) {
            this.$router.push(`/createproject/${id}`);
          },
        async getsearchdata(){
            await axios
                .get(
                    `${this.mainapi}configuration/project/getlist?ProjectName=${this.selectedprojectname}&pageindex=${this.currentPageNumber}&pagesize=10`,
                    {
                        headers: {
                            "Content-Type": "application/json",

                        },
                    }
                )
                .then((res) => {
                    console.log(" get patients", res);
                    this.projectlist = res.data.entities;
                    this.totalLists = Math.ceil(res.data.totalCount / 10);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
}