/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import store from "../../store/index";
import SidePopUp from "../side_pop_up/side_pop_up.vue";
import PermissionPopUp from "../permission_popup/permission_popup.vue";
import foldersendpopup from "../foldersend_popup/foldersend_popup.vue";
import File_FolderPopup from "../File_FolderPopup/File_FolderPopup.vue";
import FileFolderProjectPopup from "../FileFolderProjectPopup/FileFolderProjectPopup.vue";
import fileFormatPopUp from "../fileformatpopup/fileformatpopup.vue";
import MoveFolder from "../move_folder/move_folder.vue";
import MoveProjectFolder from "../move_projectfolder/move_projectfolder.vue";
import foldershare_popup from "../foldershare_popup/foldershare_popup.vue";
import externalfoldershare from "../externalfoldershare_popup/externalfoldershare.vue";
import subPopup from "../subPopup/subPopup.vue";
import "jquery";
// import moment from "moment";
// import { ref } from 'vue'
export default {
  components: {
    externalfoldershare,
    foldershare_popup,
    FileFolderProjectPopup,
    fileFormatPopUp,
    SidePopUp,
    PermissionPopUp,
    foldersendpopup,
    File_FolderPopup,
    MoveFolder,
    MoveProjectFolder,
    subPopup,
  },
  props: {
    projectId: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => [],
    },
    boldfolder: {
      type: String,
      default: "",
    },
    patid: {
      type: String,
      default: "",
    },
    openfolderlist: {
      type: String,
      default: "",
    },
    treeid: {
      type: String,
      default: "doc",
    },
    SourcePage: {
      type: String,
      default: "",
    },
    desigFolderPrivilege: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      anyuserpermissions: false,
      currentProjectId: "",
      sourceType: "",
      showBlock: false,
      showPermBlock: false,
      moveBlock: false,
      moveprojBlock: false,
      showsubPopup: false,
      popupid: "",
      folderswitch: {},
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      patientid: "",
      listdata: [],
      firstclassname: this.patid,
      folderbyid: "",
      showDetails: false,
      arrayfolders: [],
      deptBlockname: "",
      departments: {},
      foldertreelist: {},
      currentselectedfolder: "",
      showPermPopup: false,
      showProjectPermPopup: false,
      showSharePopup: false,
      showExternalSharePopup: false,
      clonePopups: false,
      leftValue: 0,
      topValue: 0,
      movedFolder: "",
      openfolder: "",
      folderOpenIcon: [false],
      ancestors: [],
      folderList: {},
      userpermissions: [],
      destination: "",
      selectedboldfolder: "",
    };
  },
  watch: {
    "$store.getters.getselectedFolder": function (val) {
      this.currentselectedfolder = val;
      if (
        this.SourcePage === "generalFiles" &&
        this.currentselectedfolder === ""
      ) {
        console.log("Entered" + store.getters.getselectedFolder);
        if (store.getters.getselectedFolder != "") {
          this.getfolder(store.getters.getselectedFoldermove, false);
        }
      }
    },
    "$store.getters.getfolderreload": async function (val) {
      if (store.getters.geteditfolderid != "") {
        this.currentselectedfolder = val;
        //  await this.reloadallfolders();
        await this.getfolderslastancestor(store.getters.geteditfolderid);
      }
    },
    boldfolder(newVal, oldVal) {
      this.selectedboldfolder = newVal;
      this.currentselectedfolder = "";
      // Your custom logic here
      // if(newVal == ""){
      //   store.dispatch("selectFolder", "");
      // }
    },
  },
  async mounted() {
    // console.clear();
    this.selectedboldfolder = this.boldfolder;
    this.openfolder = this.openfolderlist;
    if (this.SourcePage == "project" || this.SourcePage == "projectlist") {
      this.destination = this.SourcePage;
      if (store.getters.getopenfolder != "") {
        this.openfolder = store.getters.getopenfolder;
      }
    } else if (this.SourcePage == "generalFiles") {
      if (store.getters.getopenfolder != "") {
        this.openfolder = store.getters.getopenfolder;
      }
    }
    this.idtoken = store.getters.getToken;
    this.currentselectedfolder = this.boldfolder;
    this.getpartments(this.openfolder);
  },
  computed: {
    foldertree() {
      this.list.map((item) => {
        this.checkforfolders(item.id);
      });
      return this.folderList;
    },
  },
  methods: {
    openSub(e) {
      e = window.event;
      this.event = e;
      this.showsubPopup = true;
      this.showBlock = true;
    },

    close() {
      document.body.style.overflow = "auto";
      this.showPermPopup = false;
      this.$emit("changePerm");
    },
    closeProjectPopup() {
      this.showProjectPermPopup = false;
      document.body.style.overflow = "auto";
      this.$emit("changePerm");
    },
    move(id) {
      if (this.SourcePage == "project") {
        this.moveprojBlock = !this.moveprojBlock;
        this.movedFolder = id;
      } else {
        this.moveBlock = !this.moveBlock;
        this.movedFolder = id;
      }
    },
    async getpartments(folderid) {
      await axios
        .get(`${this.mainapi}document/folder/get?Id=${folderid}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then(async (res) => {
          this.ancestors = res.data.ancestorIds;
        })
        .catch((err) => {
          console.log(err);
        });
      this.setfolderstruct(folderid);
    },
    async setfolderstruct(folderid) {
      await this.ancestors.forEach((eachid) => {
        this.getancestorfolder(eachid);
      });
      this.getancestorfolder(folderid);
    },
    sidePopup(id, name, type, e) {
      this.getFolderUserpermission(id);
      //alert(id +"@@"+name+"##"+type+"$$"+e)
      e = window.event;
      this.event = e;
      this.popupid = id;
      this.showBlock = !this.showBlock;
      store.dispatch("setselectedid", id);
      store.dispatch("setfileType", type);
      store.dispatch("setfileName", name);
    },
    async reloadallfolders() {
      console.log("reload all folder ........................................");
      await this.list.forEach((element) => {
        if (this.folderswitch[element.id]) {
          console.log(element.id, element.name);
          this.refreshfolder(element.id, element.name);
        }
      });
    },
    reloadFoldersProject() {
      this.moveBlock = false;
      this.reloadallfolders();
      this.$emit("reloadFolders");
    },
    async getFolderUserpermission(id) {
      this.userpermissions = [];
      await axios
        .get(
          `${this.mainapi}document/folderfilepermission/getuserfolderpermission?FolderId=${id}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          this.userpermissions =
            res.data != null ? res.data : [];
          if (this.userpermissions.length == 0) {
            this.anyuserpermissions = true;
          } else {
            if (
              !this.userpermissions.includes("Clone") &&
              !this.userpermissions.includes("Permissions") &&
              !this.userpermissions.includes("Share") &&
              !this.userpermissions.includes("Edit") &&
              !this.userpermissions.includes("Delete") &&
              !this.userpermissions.includes("Archive")&&
              !this.userpermissions.includes("Download")
            ) {
              this.anyuserpermissions = true;
            } else {
              this.anyuserpermissions = false;
            }
          }
          // if(this.userpermissions !=null){
          //   if(this.userpermissions.includes("View"))
          //   {
          //     store.dispatch("setdocaddpermission", true);
          //   }
          // }
          // this.ancestors = res.data.ancestorIds;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async FilePermissionPopup() {
      document.body.style.overflowY = "hidden";
      const fromPage = await store.getters.getFromPage;
      this.sourceType = "Folder";
      if (fromPage == "Department" || fromPage == "generalFiles") {
        if (fromPage === "generalFiles") {
          store.dispatch("setMainDepartment", "");
          this.sourceType = "generalFiles";
        }
        this.showPermPopup = !this.showPermPopup;
      } else if (fromPage == "ProjectSummary" || fromPage == "DocumentList") {
        this.currentProjectId = this.projectId;
        this.showProjectPermPopup = !this.showProjectPermPopup;
      }
    },
    closeFolderPopup() {
      this.showSharePopup = false;
      document.body.style.overflowY = "auto";
    },
    closeExternalShareFolderPopup() {
      this.showExternalSharePopup = false;
      document.body.style.overflowY = "auto";
    },
    closeClonePopUp() {
      this.clonePopups = false;
      document.body.style.overflowY = "auto";
    },
    async shareFolder() {
      document.body.style.overflowY = "hidden";
      const fromPage = await store.getters.getFromPage;
      this.sourceType = "Folder";
      if (fromPage == "Department") {
        this.showSharePopup = !this.showSharePopup;
        this.currentProjectId = store.getters.getDepartment;
      } else if (fromPage == "ProjectSummary" || fromPage == "DocumentList") {
        this.currentProjectId = this.projectId;
        this.showSharePopup = !this.showSharePopup;
      } else if (fromPage == "generalFiles") {
        this.showSharePopup = !this.showSharePopup;
        this.currentProjectId = "";
      }
    },
    async ExternalshareFolder() {
      document.body.style.overflowY = "hidden";
      const fromPage = await store.getters.getFromPage;
      this.sourceType = "Folder";
      if (fromPage == "Department") {
        this.showSharePopup = !this.showSharePopup;
        this.currentProjectId = store.getters.getDepartment;
      }
      if (fromPage == "ProjectSummary" || fromPage == "DocumentList") {
        this.currentProjectId = this.projectId;
        this.showExternalSharePopup = !this.showExternalSharePopup;
      } else if (fromPage == "generalFiles") {
        this.showSharePopup = !this.showSharePopup;
        this.currentProjectId = "";
      }
    },

    async refreshfolder(id, name) {
      console.log("refresh folders", id, name);
      this.foldertreelist[id] = [];

      await axios
        .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${id}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.foldertreelist[id] = res.data;
          console.log("find me", this.foldertreelist);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    async deleteFolder(id, ancestorIds) {
      const folderId = id;
      if (confirm("Are you sure you want to delete this folder ?")) {
        if (
          confirm(
            "All the folders and documents under this folder will be deleted ?"
          )
        ) {
          await axios
            .delete(`${this.mainapi}document/folder/delete?Id=${id}`, {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              this.deletetest = res.data;
              const checkNull = ancestorIds;
              if (checkNull.length === 0) {
                store.dispatch("selectFolder", "");
                //refresh documents
                this.RefreshDocument();
              } else {
                const lastAncestor = ancestorIds.pop();
                console.log("lastAncestor.........", lastAncestor);
                store.dispatch("selectFolder", lastAncestor);
              }
              alert("Folder deleted successfully");
              this.list.forEach((element) => {
                if (element.id == folderId) {
                  let index = this.list.indexOf(element);
                  this.list.splice(index, 1);
                }
              });
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      }
    },
    async selectitem(id) {
      store.dispatch("selectFolder", id);
    },

    async getancestorfolder(id) {
      if (id == this.openfolder && this.openfolder != null) {
        store.dispatch("selectFolder", id);
      }

      // store.dispatch("selectFolder", id);
      if (this.folderswitch[id]) {
        this.folderswitch[id] = !this.folderswitch[id];
      } else {
        this.folderswitch[id] = true;
      }

      await axios
        .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${id}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.foldertreelist[id] = res.data;
          console.log("find me", this.foldertreelist);
        })
        .catch((err) => {
          console.log(err.message);
        });

      this.reloadallfolders();
    },
    async checkforfolders(id) {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${id}`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.length != 0) {
            this.folderList[id] = true;
          } else this.folderList[id] = false;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    async getfolder(id, sendpopup) {
      store.dispatch("setopenfolder", "");
      this.folderOpenIcon[id] = !this.folderOpenIcon[id];
      this.openfolder = null;
      // alert("id" + id +"and name " + name);
      // if(this.$route.name!="generalFiles"){
      // store.dispatch("selectFolder", id);
      // }
      store.dispatch("selectFolder", id);
      store.dispatch("selectFoldermove", id);
      if (this.folderswitch[id]) {
        this.folderswitch[id] = !this.folderswitch[id];
      } else {
        this.folderswitch[id] = true;
      }
      // alert(id);
      // store.dispatch("selectFolder", id);
      this.foldertreelist[id] = [];

      await axios
        .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${id}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.foldertreelist[id] = res.data;
        })
        .catch((err) => {
          console.log(err.message);
        });
      if (sendpopup) {
        store.dispatch("setsendfolder", true);
      }
      //get user privileged folder controls
      this.getFolderUserpermission(id);
    },

    async RefreshDocument() {
      await store.dispatch("setDocumentRefresh", true);
      window.dispatchEvent(
        new CustomEvent("folderArchived", {
          detail: {
            storage: store.getters.getDocumentRefresh,
          },
        })
      );
    },
    async archiveFolder(id, ancestorIds) {
      const folderId = id;
      if (confirm("Are you sure you want to archive this folder?")) {
        if (
          confirm(
            "By archiving the folder, the selected folder, subfolder(s), and the documents inside the folder(s) will be archived."
          )
        ) {
          //Api call
          await axios
            .put(
              `${this.mainapi}document/folder/archive?id=${folderId}`,
              {},
              {
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              console.log(res);
              //tree refresh
              const checkNull = ancestorIds;
              if (checkNull.length === 0) {
                store.dispatch("selectFolder", "");
                //refresh documents
                this.RefreshDocument();
              } else {
                const lastAncestor = ancestorIds.pop();
                console.log("lastAncestor.........", lastAncestor);
                store.dispatch("selectFolder", lastAncestor);
              }
              alert("Folder archived successfully");
              this.list.forEach((element) => {
                if (element.id == folderId) {
                  let index = this.list.indexOf(element);
                  this.list.splice(index, 1);
                }
              });
            }) 
            .catch((err) => {
              console.log(err.message);
            });
        }
      }
    },

    async downloadFolder(id) {
      const folderId = id;
      console.log("Folder ID:", folderId);
    
      if (confirm("Are you sure you want to download this folder?")) {
        try {
          const response = await axios.get(
            `${this.mainapi}document/folder/export?exportfolderId=${folderId}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
              },
              responseType: "blob",
            }
          );
    
          
          const contentDisposition = response.headers["content-disposition"];
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : "folder.zip";
    
         
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
    
          
          link.click();
    
          
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error occurred during download:", error);
        
        }
      }
    },
    

    async getfolderslastancestor(id) {
      await axios
        .get(`${this.mainapi}document/folder/get?Id=${id}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          let editedfolderdetails = [];
          editedfolderdetails = res.data.ancestorIds;
          let lastancestor =
            editedfolderdetails[editedfolderdetails.length - 1];
          document.getElementById(lastancestor + "doc").click();
          document.getElementById(lastancestor + "doc").click();
          document.getElementById(res.data.id + "doc").click();
          console.log("VERSIONN issss", res.data.id);
          store.dispatch("seteditfolderid", "");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
