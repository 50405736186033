<template>
  <div class="permission-block-container">
    <div class="share-modal-dialog">
      <div class="modal-content">
        <div class="share-modal-header d-flex justify-content-between p-3">
          <h4 class="my-0">Share File</h4>
          <div class="cursor-pointer" @click="close()">
            <i class="fa fa-times"></i>
          </div>
        </div>
        <div class="ibox-content overflow-y border-0 px-4" id="permissionBlock">
          <div class="form-group pt-2">
            <label class="mb-0" for="designation">Select Role</label>
            <Multiselect @click="getDesignationUsers" class="multiselect-custom-theme mb-2" name="designation"
              valueProp="id" trackBy="name" v-model="selectedDesignation" mode="multiple" placeholder="Select Role"
              :options="designationList" label="roleName" :hideSelected="false" :closeOnSelect="false">
              <template v-slot:option="{ option }">
                {{ option.displayName }}
              </template>
            </Multiselect>
            <label class="mb-0" for="designation">Select User <sup><i class="fa fa-asterisk required"></i></sup></label>
            <Multiselect @select="addUser()" class="multiselect-custom-theme mb-2" name="users" valueProp="emailId"
              v-model="selectedUser" mode="multiple" placeholder="Select User" :options="userList" label="userName"
              :hideSelected="false" :closeOnSelect="false">
              <template v-slot:option="{ option }">
                {{ option.name }}
              </template>
            </Multiselect>
            <span class="d-block errorClass" v-if="validationmsgs[0]">
              Please select users
            </span>
            <label class="mb-0" for="designation">Select Permissions <sup><i class="fa fa-asterisk required"></i></sup></label>
            <Multiselect class="multiselect-custom-theme mb-2" name="users" valueProp="permission"
              v-model="folderpermissions" mode="multiple" placeholder="Select Permissions" :options="permissionList"
              label="userName" :hideSelected="false" :closeOnSelect="false">
              <template v-slot:option="{ option }">
                {{ option.permission }}
              </template>
            </Multiselect>
            <span class="d-block errorClass" v-if="validationmsgs[1]">
              Please select permissions
            </span>
            <div class="w-100">
              <span class="submit_btn btndiv mt-3 px-5" type="button" @click.prevent="senddocument()">Share</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./filesend_popup.js"></script>
<style scoped>

.fa-asterisk {
    color: #e56363 !important;
    font-size: 6px;
}
.permission-block-container {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.permission-block {
  background: #fff;
  width: min(500px, 90%);
  border-radius: 4px;
  position: fixed;
  overflow: hidden;
  pointer-events: auto;
}
.share-modal-header {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.overflow-y {
  overflow-y: scroll
}
.share-modal-dialog{
  width: min(500px,90%);
}

.btndiv {
  float: right;
}

.errorClass {
  color: red;
  font-size: 13px;
}
</style>
