<template>
  <div
    class="create-user-modal1 d-flex justify-content-center align-items-center"
  >
    <div class="folder-modal-width">
      <div class="modal-content animated bounceInTop">
        <div class="modal-header-move d-flex justify-content-between p-3">
          <h5 class="my-0 align-self-center">Permissions</h5>
          <span
            type="button"
            class="align-self-center"
            data-dismiss="modal"
            @click.prevent="close()"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body">
          <label class="">Choose Designation</label>
          <div class="w-50">
          <Multiselect
            name="designation"
            valueProp="id"
            mode="multiple"
            placeholder="Select Designations"
            :options="designationList"
            :hideSelected="false"
            :closeOnSelect="false"
            class="multiselect-custom-theme"
            @change="onChange($event)"
            v-model="designation"
          >
            <template v-slot:option="{ option }">
              {{ option.displayName }}
            </template>
          </Multiselect>
          </div>
          <h5 class="tag-title">Users</h5>
          <div class="height-scroll">
            <table class="table fixed-header fixed-column-table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      id="selectall"
                      @change="selectall()"
                    />
                    Name
                  </th>
                  <th
                    v-for="permissions in permissionList"
                    :key="permissions.id"
                  >
                    <input
                      type="checkbox"
                      :id="permissions.permission"
                      @change="setpermissions(permissions.permission)"
                    />
                    {{ permissions.permission }}
                  </th>
                  <th class="text-center">Save</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="options in userList" :key="options.id">
                  <td>
                    <input
                      type="checkbox"
                      :value="options.userName"
                      :id="options.userId"
                      :checked="
                        userobject[options.userId].permission.length != 0
                      "
                      @change="setcontrols(options)"
                    />
                    {{ options.name }}
                  </td>
                  <td v-for="option in permissionList" :key="option.id">
                    <input
                      type="checkbox"
                      :value="option.permission"
                      :class="options.id"
                      v-model="userobject[options.userId].permission"
                      @change="selectuserwithpermission(options.userId)"
                    />
                    {{ option.permission }}
                  </td>
                  <td class="text-center">
                    <a href="#">
                      <img
                        src="../../assets/img/save_color.png"
                        class="cursor-pointer"
                        alt="create"
                        @click="createPermission(options.userId)"
                    /></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <label v-if="showSave">
            *Unsaved changes will be lost if switching designations.</label
          >
          <button
            v-if="showSave"
            type="button"
            class="btn btn-primary btn-xs px-4 mr-3"
            @click="createMultiPermission()"
          >
            Save
          </button>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>
<script src="./File_FolderPopup.js">
</script>
<style scoped>
@import "../../assets/css/style.css";
@import "../../assets/css/custom.css";
.create-user-modal1 {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  overflow-y: hidden;
  z-index: 3000;
}
.folder-modal-width {
  width: min(800px, 90%);
}
.modal-header-move {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.tree input {
  position: inherit !important;
}
.height-scroll {
  height: 250px;
  overflow-y: auto;
}
.fixed-header th {
  top: 0px;
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
}
.fixed-column-table th:first-child {
  position: sticky;
  z-index: 2;
  background-color: #f2f2f2;
  left: -1px;
}

.fixed-column-table td:first-child {
  position: sticky;
  z-index: 1;
  background-color: #f2f2f2;
  left: -1px;
}
</style>