<template>
  <span v-if="selectedlocation == 'projects'">
    <ul v-for="list in list" :for="list.projectName" :key="list.projectName">
      <li class="d-flex justify-content-between">
        <input
          type="checkbox"
          :id="'move' + list.id"
          @change="getallprojectfolders(list.id, list.projectName)"
        />
        <label
          :for="'move' + list.id"
          :class="
            folderbold == list.id ? 'tree_label tree_label_bold' : 'tree_label'
          "
          class="tree_label"
        >
          {{ list.projectName }}</label
        >
      </li>
     
      <TreeMergeFile
        v-if="folderswitch[list.id]"
        projectId=""
        :list="foldertreelist[list.id]"
        :boldfolder="folderbold"
        patid=""
        clasification="folderlists"        
        @documentListNotEmpty="handleDocumentListNotEmpty"
      >
      </TreeMergeFile>
    </ul>
    </span>
    <span v-else>
    <ul v-for="list in list" :for="list.name" :key="list.name">
      <li class="d-flex justify-content-between">
        <input
          type="checkbox"
          :id="'move' + list.id"
          @change="getdeptfolder(list.id, list.name,list.type)"
        />
        <label
          :for="'move' + list.id"
          :class="
            folderbold == list.id ? 'tree_label tree_label_bold' : 'tree_label'
          "
          class="tree_label"
        >
          {{ list.name }}</label
        >
      </li>
      <TreeMergeFile
        v-if="folderswitch[list.id]"
        projectId=""
        :list="foldertreelist[list.id]"
        :boldfolder="folderbold"
        patid=""
        :selectedlocation="selectedlocation"
        clasification="folderlists"
        @documentListNotEmpty="handleDocumentListNotEmpty"
      >
      </TreeMergeFile>
    </ul>
  </span>
</template>

<script src="./TreeMergeFile.js">
</script>

<style  scoped>
@import "./tree.css";

.tree_label_bold {
  font-weight: bold;
}

.more-option-icon {
  display: none;
}

span ul li:hover,
span ul li:focus-visible,
span ul li:hover .tree_label {
  background-color: #e4eaf9;
}

span ul li:hover .more-option-icon {
  display: block;
}

.folder_tree_label {
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 5px 0 5px 1rem;
  position: relative;
}

.folder_tree_label:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.folder_tree_label:before {
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  margin: 0 0.5em 0 -2em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  content: url("../../assets/img/folder-close.png");
  text-align: center;
  line-height: 0.8em;
}

.myStyle {
  color: blue;
}

.innertemplate {
  display: block;
}

.icon:hover {
  cursor: pointer;
}

.muted {
  color: gray;
  font-size: 80%;
}

.hideandseek {
  display: none !important;
}
.popupbtn {
  color: black;
  float: right;
}
.oneline {
  display: flex;
  justify-content: space-between;
}
</style>
