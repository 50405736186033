import axios from "axios";
import { data } from "jquery";
import store from "../../store/index";

export default {
    name: "doc_Approval",
    props: {
        documentId: { type: String, default: "" },
        version: { type: String, default: "" },
        projectId: { type: String, default: "" },
        dropdownName: { type: String, default: "" },
    },
    components: {

    },
    data() {
        return {
            baseApi: `${process.env.VUE_APP_Service_URL}`,
            baseurl: `${process.env.VUE_APP_Service_URL}`,
            idtoken: "",
            designationList: [],
            designationLists: [],
            designation: "",
            approvalType: "",
            userList: [],
            isRequired: [false],
            isSelectedUser: [false],
            isdDisabled: [false],
            approvals: {
                documentId: this.documentId,
                version: this.version,
                approveList: [{
                    approvalType: "",
                    approvalTypeID: "",
                    approvedByEmail: "",
                    isRequired: this.isRequired,
                    comments: ""
                }]
            },
            singleApproval: {
                documentId: this.documentId,
                version: this.version,
                approveList: {
                    approvalType: "",
                    approvalTypeID: "",
                    approvedByEmail: "",
                    isRequired: true,
                    comments: ""
                }
            },
            approver: {},
            userobject: [],
            getApproverList: {},
            popUpHeading: "",
            roleList: [],
            approversEmails: [],
            userEmails: [],
            removeUser: [],
            showSave: false,


        };
    },
    async mounted() {
        console.log("DocumentiD and version ", this.version);
        console.log("dropdownName is", this.dropdownName);
        if (this.dropdownName == "generalFiles") {
            this.popUpHeading = "Choose Designation"
        }
        if (this.dropdownName == "Department") {
            this.popUpHeading = "Choose Designation"
        }
        else if (this.dropdownName == "ProjectRole") {
            this.popUpHeading = "Choose Role"
        }
        this.idtoken = store.getters.getToken;
        this.getalldesignation();
        this.approvals.approveList = [];
    },
    methods: {
        close() {
            this.$emit('closeApproveModal');
        },
        async getalldesignation() {
            if (this.dropdownName == "generalFiles") {
                await axios
                    .get(`${this.baseApi}configuration/designation/search?status=active&PageSize=10000&SortProperty=displayName`,
                        {
                            headers: {
                                Authorization: "Bearer " + this.idtoken,
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .then((res) => {
                        this.designationLists = res.data.entities;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else if (this.dropdownName == "Department") {
                await axios
                    .get(`${this.baseApi}configuration/designation/search?status=active&PageSize=10000&SortProperty=displayName`,
                        {
                            headers: {
                                Authorization: "Bearer " + this.idtoken,
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .then((res) => {
                        this.designationList = res.data.entities;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            else if (this.dropdownName == "ProjectRole") {
                console.log("in project role get fn ")
                this.projId = await store.getters.getSelectedProject,
                    await axios
                        .get(
                            `${this.baseApi}configuration/projectrole/getall?projectId=${this.projId}`,
                            {
                                headers: {
                                    Authorization: "Bearer " + this.idtoken,
                                    "Content-Type": "application/json",
                                },
                            }
                        )
                        .then((res) => {
                            this.roleList = res.data;
                        })
                        .catch((err) => {
                            console.log(err);
                            this.roleList = []
                        })
            }
        },
        async onChange(event) {
            this.showSave = true;
            document.getElementById("selectall").checked = false;
            this.userobject = [];
            this.approvals.approveList = [];
            this.isSelectedUser = [false];
            let selectedDesignation = event.target.value;
            await this.getDesignationUsers(selectedDesignation);
        },
        async getDesignationUsers(designation) {
            this.designation = designation;

            if (this.dropdownName == "ProjectRole") {
                this.approvalType = "ProjectRole";
                await axios
                    .post(`${this.baseurl}configuration/userprojectrole/getusersbyrole?projectId=${store.getters.getSelectedProject}`,[designation], {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        console.log("designated user list is ", res)
                        this.userList = res.data;
                        this.isRequired = [];
                        this.userList.forEach((user, index) => {
                            this.isRequired[index] = false;
                        });


                        this.getApprovers();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else if (this.dropdownName == "generalFiles") {
                this.approvalType = "GeneralFiles";
                await axios
                    .post(`${this.baseurl}document/folderfilepermission/designationusers?`, [designation], {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        console.log("user list for department is", res.data)
                        this.userList = res.data;
                        this.isRequired = [];
                        this.userList.forEach((user, index) => {
                            this.isRequired[index] = false;
                        });
                        this.getApprovers();

                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            else if (this.dropdownName == "Department") {
                this.approvalType = "Department";
                await axios
                    .post(`${this.baseurl}document/folderfilepermission/designationusers?departmentId=` + store.getters.getMainDepartment, [designation], {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        console.log("user list for department is", res.data)
                        this.userList = res.data;
                        this.isRequired = [];
                        this.userList.forEach((user, index) => {
                            this.isRequired[index] = false;
                        });
                        this.getApprovers();

                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            console.log("IS REQUIRED........", this.isRequired)
        },
        async singleSaveApprover(user, index) {
            if (this.isdDisabled[index] == true) {
                console.log("disabled");
            }
            else {
                if (this.removeUser.some(data => data === user.emailId) && this.isSelectedUser[index] == false) {
                    console.log("IN REMOVE APPROVER");
                    await axios.put(
                        `${this.baseApi}document/document/removeapprover?documentId=${this.documentId}&versionNumber=${this.version}`, [user.emailId],
                        //`https://localhost:5001/document/removeapprover?documentId=${this.documentId}&versionNumber=${this.version}`,[user.emailId],
                        {
                            headers: {
                                Authorization: "Bearer " + this.idtoken,
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then(res => {
                            console.log("removed approver", res)
                            alert("approver updated");
                        })
                        .catch(err => {
                            console.log("error in remove approver", err);
                        })
                }
                else {
                    this.singleApproval = {
                        documentId: this.documentId,
                        version: this.version,
                        approveList: {
                            approvalType: this.approvalType,
                            approvalTypeID: this.designation,
                            approvedByEmail: user.emailId,
                            isRequired: this.isRequired[index],
                            comments: ""
                        }
                    }
                    console.log("single save approver", this.singleApproval);
                    await axios.post(`${this.baseApi}document/document/singlesaveapprover`, this.singleApproval, {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                        .then(res => {
                            console.log("single save result", res);
                            alert("Updated successfully");
                            //this.close();
                        })
                        .catch(err => {
                            console.log("error in single approve", err);
                        })
                }

            }
        },
        async multipleSaveApprover() {
            console.log("multiple save is req values", this.isRequired);


            this.approvals.approveList.forEach((item, index) => {
                this.userList.forEach((user, i) => {
                    if (item.approvedByEmail == user.emailId) {
                        item.isRequired = this.isRequired[i];
                    }
                })
            })
            console.log("multiple save approver is required", this.approvals.approveList)
            if (this.removeUser.length != 0) {
                await axios.put(
                    //`https://localhost:5001/document/removeapprover?documentId=${this.documentId}&versionNumber=${this.version}`,this.removeUser,
                    `${this.baseApi}document/document/removeapprover?documentId=${this.documentId}&versionNumber=${this.version}`, this.removeUser,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                    .then(res => {
                        console.log("removed approver", res)
                    })
                    .catch(err => {
                        console.log("error in remove approver", err);
                    })
            }
            console.log("approvals for save", this.approvals)

            console.log("removing duplicate form usr list", this.approvals.approveList);
            await axios.post(
                //`https://localhost:5001/document/multiplesaveapprover`
                `${this.baseApi}document/document/multiplesaveapprover`
                , this.approvals,
                {
                    headers: {
                        Authorization: "Bearer " + this.idtoken,
                        "Content-Type": "application/json",
                    },
                })
                .then(res => {

                    console.log("multiple save result", res);
                    alert("Updated successfully");
                    this.close();
                })
                .catch(err => {
                    console.log("error in multiple approve", err);
                })
        },
        async addApprover(user, index) {
            if (this.isSelectedUser[index] == true) {
                let approver = {
                    approvalType: this.approvalType,
                    approvalTypeID: this.designation,
                    approvedByEmail: user.emailId,
                    isRequired: this.isRequired[index],
                    comments: ""
                }
                console.log("multiple approver manual", approver);
                this.approvals.approveList.push(approver);
                console.log("approvals", this.approvals)


                if (this.removeUser.some(data => data === user.emailId)) {
                    this.removeUser.splice(this.removeUser.indexOf(user.emailId), 1);
                    console.log("removed list", this.removeUser);
                }


            }
            else {
                this.removeUser.push(this.userList[index].emailId);
                let removeUser = this.userList[index].emailId;
                this.approvals.approveList.forEach((approver) => {
                    if (approver.approvedByEmail == removeUser) {
                        console.log("remove approver", this.approvals.approveList.indexOf(approver));
                        this.approvals.approveList.splice(this.approvals.approveList.indexOf(approver), 1);
                    }
                })
                console.log("UserList", this.userList);
                console.log("Approval List", this.approvals.approveList);
                console.log("approve slice list", this.approvals.approveList)

            }
        },
        async addRequiredApprover(user, index) {
            if (this.isRequired[index] == true) {
                this.isSelectedUser[index] = true;
                console.log("in addRequiredApprover function ifff.")
                if (!this.approvals.approveList.some(data => data.approvedByEmail === user.emailId)) {
                    let approver = {
                        approvalType: this.approvalType,
                        approvalTypeID: this.designation,
                        approvedByEmail: user.emailId,
                        isRequired: this.isRequired[index],
                        comments: ""
                    }
                    this.approvals.approveList.push(approver);
                }

                if (this.removeUser.some(data => data === user.emailId)) {
                    this.removeUser.splice(this.removeUser.indexOf(user.emailId), 1);
                    console.log("removed list", this.removeUser);
                }
            }
            // else{
            //     console.log("in addRequiredApprover function elseee.")
            //     this.removeUser.push(this.userList[index].emailId);
            //     let removeUser = this.userList[index].emailId;
            //     this.approvals.approveList.forEach((approver) => {
            //         if(approver.approvedByEmail == removeUser){
            //             console.log("remove approver" , this.approvals.approveList.indexOf(approver));
            //             this.approvals.approveList.splice(this.approvals.approveList.indexOf(approver), 1);
            //         }
            //     })
            // }
        },
        async getApprovers() {
            console.log
            await axios.get(
                `${this.baseApi}document/document/getapprovers?documentId=${this.documentId}&versionNumber=${this.version}&approvalTypeID=${this.designation}`,
                {
                    headers: {
                        Authorization: "Bearer " + this.idtoken,
                        "Content-Type": "application/json",
                    },
                }
            )
                .then(res => {
                    this.getApproverList = res.data;
                    this.approversEmails = [];
                    this.getApproverList.forEach((approver, index) => {
                        this.approversEmails.push(approver.approvedByEmail);
                    })
                    this.pushApprover();
                    this.disableUser();
                    console.log("Get Approvers", res.data);
                })
                .catch(err => {
                    console.log("error in multiple approve", err);
                })
        },
        pushApprover() {
            if (this.getApproverList.length != 0) {
                this.getApproverList.forEach((approver, index) => {
                    this.approvals.approveList.push(approver);
                })
            }
            console.log("Push approver", this.approvals, this.isRequired)
        },
        disableUser() {
            this.userList.forEach((user, index) => {
                this.getApproverList.forEach((approver, i) => {
                    if (user.emailId == approver.approvedByEmail) {
                        this.isRequired[index] = approver.isRequired;
                        this.isSelectedUser[index] = true;
                        if (approver.approvedStatus == "Approved" || approver.approvedStatus == "Rejected") {
                            this.isdDisabled[index] = true;
                        }
                    }
                })
            })
            console.log("Disable user", this.isdDisabled);
        },
        selectall() {
            this.removeUser = [];
            this.approvals.approveList = [];
            let checkBoxall = document.getElementById("selectall");
            if (checkBoxall.checked == true) {
                console.log("checkusers", this.userList);
                this.userList.forEach((users, index) => {
                    if (this.isdDisabled[index] == true) {
                        this.isdDisabled[index] = true
                    }
                    else {
                        this.userobject[users.userId] = true;
                        this.approvals.approveList.push({
                            approvalType: this.approvalType,
                            approvalTypeID: this.designation,
                            approvedByEmail: users.emailId,
                            //isRequired: this.isRequired[index],
                            comments: ""
                        })
                    }
                })

            }
            else {

                this.userobject = [];
                this.approvals.approveList = [];
                console.log("checkusers", this.userList);
                this.userList.forEach((user, index) => {
                    if (this.isdDisabled[index] == true) {
                        this.isdDisabled[index] = true
                    }
                    else {
                        this.userobject[user.userId] = false
                        this.isRequired[index] = false

                        this.removeUser.push(user.emailId);
                    }
                })
                console.log("remove whole list ", this.removeUser);

            }
        },

    },
};