/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
export default {
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      idtoken: "",
      doctemplatearray:[],
      currentPage:1,
      totalPage:0,
      designationPrivileges :"",
      
    };
  },

  async mounted() {
    this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
    this.idtoken = store.getters.getToken
    this.listdoctemplates();
  },
  methods: {
    async listdoctemplates(){
      await axios
      .get(
          `${this.baseurl}document/projectdevdocumenttemplate/getalltemplates`,
          {
              headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
              },
          })
      .then((res) => {
        
          this.doctemplatearray = res.data;

      })
      .catch((err) => {
          console.log(err);
      });
    },
    createdoctemplate() {
      this.$router.push(`/doctemplatecreate/0`);
    },
    edittemplate(id){
      this.$router.push(`/doctemplatecreate/${id}`);
    },
    async deletetemplate(id){
      if (confirm("Are you sure you want to delete this Template?")) {
        await axios.delete( `${this.baseurl}document/projectdevdocumenttemplate/deletetemplate?Id=${id}`,
            {
                headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => {
                console.log(res)
                alert("Template deleted successfully")
                
            })
            .catch((err) => {
                console.log(err);
            })
            this.listdoctemplates();
            
    }
    },
    goBack() {
      this.$router.push(`/administration`);
    }

  },
};