<template>
  <div class="create-folder-modal">
    <div class="modal-content width-500">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <h5 class="mb-0">Sign Document</h5>
        <button
          type="button"
          @click="closePopup()"
          class="close"
          data-dismiss="modal"
        >
        <span><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group mb-4">
          <h5 v-if="Source!='controlRecords'">!!!On signing, this is equivalent to the wet ink signature!!!</h5>
          <label class="mb-0" for="email" >Email</label>
          <input
            type="email"
            id="email"
            class="form-control"
            placeholder="Email"
            required=""
            v-model="emailId"
          />
        </div>
        <div class="form-group position-relative mb-4">
          <label class="mb-0" for="password">Password</label>
          <input
            :type="inputType"
            id="password"
            class="form-control"
            placeholder="Digi-sign password"
            required=""
            v-model="password"
          />
          <i class="password-visible-block cursor-pointer position-absolute"
            :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
            @click.prevent="passwordVisible()"></i>
          <div v-if="validation" class="errmsg">{{ err }}</div>
          <div v-if="errMsg" class="errmsg">Please enter a password</div>
        </div>
        <!-- <div class="form-group mb-4">
          <label class="mb-0" for="location">Location</label>
          <select
            class="form-control"
            name="account"
            v-model="location"
            id="location"
          >
            <option value="" disabled selected>-Select Location-</option>
            <option
               v-for="location in locationList"
              :key="location.id"
              :value="location.location"
            >
              {{ location.location}}
            </option>
          </select>
          <span v-if="error[0]" class="errmsg">Please enter a location</span> 
        </div> -->
        <div class="form-group mb-4">
          <label class="mb-0" for="reasons">Reason</label>
            <select
              class="form-control"
              name="account"
              v-model="reasons"
              id="reasons"
            >
            <option value="" disabled selected>-Select Reason-</option>
            <option
               v-for="reason in reasonList"
              :key="reason.id"
              :value="reason.reason"
            >
            {{reason.reason}}
            </option>
          </select>
            <input class="form-control mt-2" v-if="reasons==='Others'" v-model="otherReason" maxlength="50" type="text" name="reason">
            <div v-if="error[1]" class="errmsg">Please enter a reason</div>   
            <div v-if="error[0]" class="errmsg">Please enter a reason</div>   
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <a href="#"><small> </small></a>
        <button
          type="button"
          :disabled="loadingBlock"
          class="btn btn-primary btn-xs px-4 mr-3"
          @click="ConfirmClicked()"
        >
          <span v-if="loadingBlock"
            ><img
              src="../../assets/img/loading.webp"
              width="20"
              class="loading-img"
              alt="Waiting for login"
          /></span>
          <span v-else>Submit</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script src="./DigitalSignPopUp.js"></script>

<style scoped>
.submit_btn {
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;

  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.position-relative img {
  width: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.headingunderline {
  text-decoration-line: underline;
}

.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}
.title-class1 {
  background-color: #618bd7;
  color: #fff;
}
.errorClass {
  color: red;
  font-size: 13px;
}
.button-class {
  float: right;
}
.modal-header-designation {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-width {
  width: min(400px, 90%);
}
.error-block {
  color: #ff0000;
}
.errmsg {
  font-size: 12px;
  color: red;
}
.width-500 {
  width: min(500px, 90%);
}
.loading-img {
  mix-blend-mode: lighten;
}
 
  .password-visible-block {
    right: 11px;
    top: 29px
}

/* Disable password visibility icon in Microsoft Edge */
input[type="password"]::-ms-reveal {
    display: none;
}
</style>