
<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
      <h2>Profile</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="tabs-container">
          <ul class="nav nav-tabs" role="tablist">
            <li>
              <a class="nav-link active" data-toggle="tab" href="#tab-1">
                Profile
              </a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" href="#tab-2"
                >Change Password</a
              >
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" href="#tab-3"
                >Create Digi Sign</a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div role="tabpanel" id="tab-1" class="tab-pane active">
              <div class="ibox-content">
                <div class="form-group row">
                  <div class="col-lg-8 my-2">
                    <h4 class="mb-0">Basic Information</h4>
                    <hr class="mt-1 mb-2" />
                    <table class="table">
                      <tbody>
                        <tr width>
                          <td
                            style="width: 30%"
                            class="project-title no-borders"
                          >
                            Name
                          </td>
                          <td style="width: 70%" class="no-borders">
                            <strong>
                              {{ profileDetails.firstName }}
                              {{ profileDetails.lastName }}</strong
                            >
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="width: 30%"
                            class="project-title no-borders"
                          >
                            Primary email
                          </td>
                          <td style="width: 70%" class="no-borders">
                            <strong>{{ profileDetails.primaryEmail }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="width: 30%"
                            class="project-title no-borders"
                          >
                            Secondary email
                          </td>
                          <td style="width: 70%" class="no-borders">
                            <strong>{{ profileDetails.secondaryemail }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="width: 30%"
                            class="project-title no-borders"
                          >
                            User Type
                          </td>
                          <td style="width: 70%" class="no-borders">
                            <strong> {{ profileDetails.userType }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="width: 30%"
                            class="project-title no-borders"
                          >
                            Designation
                          </td>
                          <td style="width: 70%" class="no-borders">
                            <strong>{{ profileDetails.designation }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <h4 class="mb-0 mt-3">Contact Information</h4>
                    <hr class="mt-1 mb-2" />
                    <table class="table">
                      <tbody>
                        <tr>
                          <td
                            style="width: 30%"
                            class="project-title no-borders"
                          >
                            Date of Birth
                          </td>
                          <td style="width: 70%" class="no-borders">
                            <strong>{{ profileDetails.dateOfBirth }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="width: 30%"
                            class="project-title no-borders"
                          >
                            Phone Number
                          </td>
                          <td style="width: 70%" class="no-borders">
                            <strong>{{ profileDetails.phoneNumber }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="width: 30%"
                            class="project-title no-borders"
                          >
                            Country
                          </td>
                          <td style="width: 70%" class="no-borders">
                            <strong>{{ profileDetails.country }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="width: 30%"
                            class="project-title no-borders"
                          >
                            Address line 1
                          </td>
                          <td style="width: 70%" class="no-borders">
                            <strong>{{ profileDetails.addressLineOne }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="width: 30%"
                            class="project-title no-borders"
                          >
                            Address line 2
                          </td>
                          <td style="width: 70%" class="no-borders">
                            <strong>{{ profileDetails.addressLineTwo }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-lg-4 my-2">
                    <h4 class="mb-0">Assigned Projects</h4>
                    <hr class="mt-1 mb-2" />
                    <table class="table table-bordered">
                      <thead>
                        <th>Project</th>
                        <th>Role</th>
                      </thead>
                      <tbody>
                        <tr
                          v-for="version in profileDetails.assignedProjects"
                          :key="version.id"
                        >
                          <td>{{ version.projectName }}</td>
                          <td>{{ version.roleName }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" id="tab-2" class="tab-pane">
              <div class="panel-body">
                <div class="ibox-title style_2">
                  <h5>Change Password</h5>
                </div>
                <div class="ibox-content">
                  <div class="row">
                    <form class="col-lg-6">
                      <div class="form-group row">
                        <div class="col-lg-12">
                          <input
                            :type="currentinputType"
                            placeholder="Current password"
                            class="form-control"
                            maxlength="20"
                            v-model="currentPassword"
                            autocomplete="nope"
                          />
                          <i
                            class="password-visible-block cursor-pointer position-absolute"
                            :class="[
                              currentvisibleOn ? 'fa fa-eye' : 'fa fa-eye-slash',
                            ]"
                            @click.prevent="passwordVisible('current')"
                          ></i>
                          <span class="errorClass" v-if="currentvalidation[0]"
                            >Please enter current password !</span
                          >
                          <span class="errorClass">{{ errr }}</span>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-12">
                          <input
                            :type="newinputType"
                            placeholder="New password"
                            class="form-control"
                            maxlength="20"
                            autocomplete="off"
                            v-model="newPassword"
                          />
                          <i
                            class="password-visible-block cursor-pointer position-absolute"
                            :class="[
                              newvisibleOn ? 'fa fa-eye' : 'fa fa-eye-slash',
                            ]"
                            @click.prevent="passwordVisible('new')"
                          ></i>
                          <span class="errormsg" v-if="validations[6]"
                            >Please enter a new password !</span
                          >
                          <span class="errormsg" v-if="validations[8]"
                            >Passwords must contain at least eight characters,
                            including uppercase, lowercase letters, special
                            characters and numbers.</span
                          >
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-lg-12">
                          <input
                            :type="inputType"
                            placeholder="Confirm new password"
                            class="form-control"
                            maxlength="20"
                            autocomplete="off"
                            v-model="confirmPassword"
                          />
                          <i
                            class="password-visible-block cursor-pointer position-absolute"
                            :class="[
                              visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash',
                            ]"
                            @click.prevent="passwordVisible('confirm')"
                          ></i>
                          <span class="errormsg" v-if="validations[7]"
                            >Please confirm new password !</span
                          >
                          <span class="errormsg" v-if="validations[9]"
                            >Passwords must contain at least eight characters,
                            including uppercase, lowercase letters, special
                            characters and numbers.</span
                          >
                          <span class="errormsg" v-if="validations[10]"
                            >Password and Confirm password mismatch !</span
                          >
                        </div>
                      </div>

                      <div class="d-flex justify-content-end">
                        <button
                          @click.prevent="checkpasswords()"
                          class="btn btn-primary py-1 px-3 btn-xs"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                    <div class="col-lg-6">
                      <ul>
                        <li class="text-danger">
                          Password must contain 8 or more characters in length.
                        </li>
                        <li class="text-danger">
                          Password must contain 1 or more uppercase & lowercase characters
                          (A-Z).
                        </li>
                        <li class="text-danger">
                          Password must contain 1 or more numeric characters
                          (0-9).
                        </li>
                        <li class="text-danger">
                          Password must contain 1 or more special characters
                          (#*$_).
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" id="tab-3" class="tab-pane">
              <div class="panel-body">
                <div class="ibox-title style_2">
                  <h5>Digital Signature Settings</h5>
                </div>
                <div class="ibox-content">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="ibox-content mb-2 mx-0 row pt-0">
                        <form class="col-lg-12">
                          <dl class="row mb-0">
                            <div class="col-sm-6 text-sm-left my-2">
                              User ID :
                              <strong> {{ profileDetails.userName }} </strong>
                            </div>
                            <div class="col-sm-6 text-sm-left my-2">
                              Email :
                              <strong>
                                {{ profileDetails.primaryEmail }}</strong
                              >
                            </div>
                          </dl>
                          <div class="form-group row">
                            <div class="col-lg-4 mt-1" v-if="isForgot">
                              <label class="col-form-label"
                                >Username<sup></sup
                              ></label>
                              <input
                                disabled
                                type="text"
                                placeholder=""
                                class="form-control"
                                v-model="profileDetails.primaryEmail"
                              />
                            </div>
                            <div class="col-lg-4 mt-1" v-if="isForgot">
                              <label class="col-form-label"
                                >Login Password<sup></sup
                              ></label>
                              <input
                                :type="loginPassword"
                                placeholder="Please enter your login password"
                                class="form-control"
                                v-model="loginpass"
                                autocomplete="new-password"
                              />

                              <i
                                class="password-visible-digi-block cursor-pointer position-absolute"
                                :class="[
                                  loginvisiblityOn ? 'fa fa-eye' : 'fa fa-eye-slash',
                                ]"
                                @click.prevent="passwordSignVisibility('login')"
                              ></i>
                              <span class="errorminClass" v-if="loginerr">
                                {{ loginerrmsg }}</span
                              >
                            </div>
                            <div
                              class="col-lg-6 align-self-end mt-4"
                              v-if="isForgot"
                            >
                              <button
                                class="btn btn-primary px-3 btn-xs"
                                @click.prevent="cancelForgotsign()"
                              >
                                Cancel
                              </button>
                              <button
                                :disabled="loadingBlock"
                                class="btn btn-primary px-3 btn-xs ml-3"
                                @click.prevent="ForgotSign()"
                              >
                                <span v-if="loadingBlock"
                                  ><img
                                    src="../../assets/img/loading.webp"
                                    width="15"
                                    class="loading-img"
                                    alt="Waiting for login"
                                /></span>
                                <span v-else>Confirm</span>
                              </button>
                            </div>

                            <div class="col-lg-3 mt-1" v-if="isUpdate">
                              <label class="col-form-label"
                                >Current password<sup></sup
                              ></label>
                              <input
                                :type="currentsignPassword"
                                placeholder=""
                                class="form-control"
                                v-model="oldPassword"
                                maxlength="20"
                              />
                              <i
                                class="password-visible-digi-block cursor-pointer position-absolute"
                                :class="[
                                  currentsignvisiblityOn ? 'fa fa-eye' : 'fa fa-eye-slash',
                                ]"
                                @click.prevent="passwordSignVisibility('current')"
                              ></i>
                              <span class="errorClass" v-if="signErrors[2]">
                                {{ errorMessages[2] }}</span
                              >
                              <span class="errorClass" v-if="errors[0] == false"
                                >Please enter current password
                              </span>
                            </div>
                            <div
                              class="col-lg-3 mt-1"
                              v-if="signStatus == 'No Signature' || isUpdate"
                            >
                              <label class="col-form-label"
                                >New password<sup></sup>
                                <img
                                  src="../../assets/img/info.svg"
                                  alt="info icon"
                                  class="info"
                                  @click="openInfo()"
                                  v-on:clickout="showPopup = false"
                                />
                                <div
                                  class="create-user-mod"
                                  v-if="showPopup == true"
                                  v-on:clickout="showPopup = false"
                                >
                                  <div class="modal-dialog">
                                    <div class="modal-content">
                                      <div
                                        class="modal-header-projects d-flex justify-content-bertween p-3"
                                      >
                                        <div>
                                          The password must be at least 8
                                          characters long and include at least
                                          one uppercase letter, lower case
                                          letter,numeric character, and one
                                          special character.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </label>
                              <input
                                :type="newsignPassword"
                                placeholder=""
                                class="form-control"
                                v-model="signPassword"
                                id="password-text"
                                maxlength="16"
                                @keyup="isGood(signPassword)"
                              />
                              <i
                                class="password-visible-digi-block cursor-pointer position-absolute"
                                :class="[
                                  newsignvisiblityOn ? 'fa fa-eye' : 'fa fa-eye-slash',
                                ]"
                                @click.prevent="passwordSignVisibility('new')"
                              ></i>
                              <div id="passwordStrength"></div>
                              <span class="errorClass" v-if="signErrors[0]">
                                {{ errorMessages[0] }}</span
                              >
                            </div>
                            <div
                              class="col-lg-3 mt-1"
                              v-if="signStatus == 'No Signature' || isUpdate"
                            >
                              <label class="col-form-label"
                                >Confirm password<sup></sup
                              ></label>
                              <input
                                :type="inputingType"
                                placeholder=""
                                class="form-control"
                                v-model="confirmSignPassword"
                                maxlength="16"
                              />
                              <i
                                class="password-visible-digi-block cursor-pointer position-absolute"
                                :class="[
                                  visiblityOn ? 'fa fa-eye' : 'fa fa-eye-slash',
                                ]"
                                @click.prevent="passwordSignVisibility('confirm')"
                              ></i>
                              <span class="errorClass" v-if="signErrors[1]">
                                {{ errorMessages[1] }}</span
                              >
                            </div>

                            <div class="col-lg-6 align-self-end mb-1">
                              <button
                                v-if="isUpdate"
                                class="btn btn-primary px-3 btn-xs"
                                @click.prevent="cancelUpdatesign()"
                              >
                                Cancel
                              </button>
                              <button
                                v-if="isUpdate"
                                class="btn btn-primary px-3 btn-xs ml-3"
                                @click.prevent="UpdateSign()"
                              >
                                Update
                              </button>
                            </div>

                            <div
                              class="col-lg-4 mt-1"
                              style="padding-top: 40px"
                            >
                              <button
                                v-if="signStatus == 'No Signature'"
                                class="btn btn-primary px-3 btn-xs"
                                @click.prevent="createSign()"
                              >
                                Save
                              </button>
                            </div>
                          </div>

                          <div class="float-left" v-if="!isForgot">
                            <button
                              v-if="
                                signStatus == 'Sign Exist' && isUpdate == false
                              "
                              class="btn btn-primary px-3 btn-xs"
                              @click.prevent="isUpdateClicked()"
                            >
                              Update digi-sign password
                            </button>
                          </div>
                          <div class="float-left" v-if="!isUpdate">
                            <button
                              v-if="
                                signStatus == 'Sign Exist' && isForgot == false
                              "
                              class="btn btn-primary ml-3 px-3 btn-xs"
                              @click.prevent="isForgotClicked()"
                            >
                              Forgot digi-sign password ?
                            </button>
                          </div>
                        </form>
                        <div
                          class="col-lg-6"
                          v-if="signStatus == 'No Signature' || isUpdate"
                        >
                          <!-- <ul>
                            <li class="text-danger">
                              Password must contain 8 or more characters in
                              length.
                            </li>
                            <li class="text-danger">
                              Password must contain 1 or more uppercase
                              characters (A-Z).
                            </li>
                            <li class="text-danger">
                              Password must contain 1 or more numeric characters
                              (0-9).
                            </li>
                            <li class="text-danger">
                              Password must contain 1 or more special characters
                                (#*$_).
                              </li>
                            </ul> -->
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12" v-if="signStatus == 'Sign Exist'">
                      <div class="ibox-content mb-2 mx-0 row">
                        <!-- <div class="col-lg-4">
                          <div
                            class="ibox-content py-2"
                            style="min-height: 140px"
                          >
                            <div class="image-preview-container">
                              <div class="preview">
                                <img
                                  src="../../assets/img/verified-signed.png"
                                  alt="verified icon"
                                />
                              </div>
                            </div>
                            <h5>
                              Signed by :
                              <strong>{{ profileDetails.primaryEmail }}</strong>
                            </h5>
                            <span class="text-muted small float-left"
                              >Date : <i class="fa fa-clock-o"></i> 2:10 pm -
                              14.03.2023</span
                            >
                            <div class="d-flex justify-content-end">
                              <label>
                                <input
                                  type="checkbox"
                                  class="i-checks"
                                  :checked="defaultSign == 'VerifiedIcon'"
                                  @click.prevent="
                                    setDefaultSign('VerifiedIcon')
                                  "
                                />
                              </label>
                              </div>
                            </div>
                          </div> -->
                        <div class="col-lg-6">
                          <div
                            class="ibox-content py-2"
                            style="min-height: 140px"
                          >
                            <h1 style="font-family: 'Brush Script MT'">
                              {{
                                profileDetails.firstName +
                                " " +
                                profileDetails.lastName
                              }}
                            </h1>
                            <h5>
                              Signed by :
                              <strong>{{ profileDetails.primaryEmail }}</strong>
                            </h5>
                            <span class="text-muted small float-left"
                              >Date : <i class="fa fa-clock-o"></i>
                              {{ createdTime }} - {{ createdDate }}</span
                            >
                            <div class="d-flex justify-content-end">
                              <label class="mb-0">
                                <input
                                  type="checkbox"
                                  class="i-checks"
                                  :checked="defaultSign == 'Cursive'"
                                  onclick="return false"
                                  @click.prevent="setDefaultSign('Cursive')"
                                />
                              </label>
                              <!-- <button class="btn btn-primary save_btn py-1 px-3 btn-xs ml-2"> Save </button> -->
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div
                            class="ibox-content py-2"
                            style="min-height: 140px"
                          >
                            <a class="d-flex justify-content-end">
                              <div  class="position-relative">
                                <img
                                v-if="!IsIntialEdit"
                                width="22"
                                src="../../assets/img/edit_sign.svg"
                                @click.prevent="makeEditable()"
                                alt="verified icon"
                              />
                              <div class="tool-tip">Edit Initials</div>
                              </div>
                              <div  class="position-relative">
                                <img
                                v-if="IsIntialEdit"
                                src="../../assets/img/cancel_sign.svg"
                                @click.prevent="closeEditable()"
                                width="22"
                                alt="verified icon"
                                class="ml-1"
                              />
                              <div class="tool-tip">Cancel</div>
                              </div>
                             
                              <div  class="position-relative">
                                <img
                                v-if="IsIntialEdit"
                                width="22"
                                src="../../assets/img/save_sign.svg"
                                @click.prevent="updateIntial()"
                                class="ml-1"
                              />
                              <div class="tool-tip">Save</div>
                              </div>
                            </a>
                            <div class="image-preview-container">
                              <div class="preview">
                                <h1
                                  v-if="!IsIntialEdit"
                                  class="mb-0"
                                  id="editable"
                                  @click.prevent="makeEditable()"
                                >
                                  {{ initial }}
                                </h1>
                                <input
                                  v-if="IsIntialEdit"
                                  type="text"
                                  v-model="newIntial"
                                  class="form-control"
                                  @input="
                                    newIntial =
                                      $event.target.value.toUpperCase()
                                  "
                                  maxlength="2"
                                />
                                <span class="errorClass" v-if="intialError">
                                  Invalid intial</span
                                >
                              </div>
                            </div>
                            <h5>
                              Signed by :
                              <strong>{{ profileDetails.primaryEmail }}</strong>
                            </h5>
                            <span class="text-muted small float-left"
                              >Date : <i class="fa fa-clock-o"></i>
                              {{ lastModifiedTime }} -
                              {{ lastModifiedDate }}</span
                            >
                            <div class="d-flex justify-content-end">
                              <label class="mb-0">
                                <input
                                  type="checkbox"
                                  class="i-checks"
                                  :checked="defaultSign == 'Initials'"
                                  onclick="return false"
                                  @click.prevent="setDefaultSign('Initials')"
                                />
                              </label>
                              <!-- <button class="btn btn-primary save_btn py-1 px-3 btn-xs ml-2"> Save </button> -->
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-lg-4">
                          <div
                            class="ibox-content py-2"
                            style="min-height: 140px"
                          >
                            <a class="float-right">
                              <img 
                                v-if="signUpload && !isImgSignEdit"
                                src="../../assets/img/edit_sign.svg" 
                                @click.prevent="imgSignEdit()"
                                alt="verified icon" 
                                class="ml-1">
                              <img
                                v-if="isImgSignEdit"
                                src="../../assets/img/cancel_sign.svg"
                                @click.prevent="closeImgEdit()"
                                  alt="verified icon"
                                  class="ml-1"
                              /> -->
                        <!--// already commented starts -->
                        <!-- <img 
                                v-if="isImgSignEdit"
                                src="../../assets/img/save_sign.svg"
                                  @click.prevent="updateIntial()"
                                class="ml-1"
                               /> -->
                        <!--// already commented ends -->
                        <!-- </a>
                            
                            <div class="image-preview-container">
                              <div class="preview">
                                <img
                                  id="preview-selected-image"
                                  style="max-width: 150px; max-height: 100px"
                                />
                              </div>
                              <input
                                v-if="!signUpload || isImgSignEdit"
                                type="file"
                                id="file-upload"
                                accept="image/png"
                                ref="fileInput"
                                @change="chooseFileClicked"
                              />
                              <label for="file-upload" v-if="!signUpload"
                                ><small
                                  >Signature size is 200 pixels height x 50
                                  pixels width</small
                              ></label
                              >
                            </div>
                            <img 
                              v-if="showSignImg && !isImgSignEdit"
                              :src="sourceSample" 
                              alt="Image" 
                              width="200" 
                              height="50" 
                              class="max-width" />
                            <h5  v-if=" !showSignImg && signUpload && !isImgSignEdit"> 
                                <a href="#" @click.prevent="showSignImage()"> Show Sign Image</a>
                            </h5>
                            <h5>
                              Signed by :
                              <strong>{{ profileDetails.primaryEmail }}</strong>
                            </h5>
                            <span class="text-muted small float-left"
                              >Date : <i class="fa fa-clock-o"></i> 2:10 pm -
                              14.03.2023</span
                            >
                            <div class="d-flex justify-content-end">
                              <label class="mb-0">
                                <input
                                  v-if="signUpload"
                                  type="checkbox"
                                  class="i-checks"
                                  :checked="defaultSign == 'SignatureImage'"
                                  @click.prevent="
                                    setDefaultSign('SignatureImage')
                                  "
                                />
                              </label>
                              </div> 
                            </div>
                          </div>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Image-user-Auth-modal" v-if="signModal">
    <div class="width">
      <div class="modal-content">
        <div
          class="modal-header-department d-flex justify-content-bertween p-3"
        >
          <h5 class="my-0 align-self-center">Authorize user</h5>
          <span
            type="button"
            class="align-self-center"
            data-dismiss="modal"
            @click="onclosed()"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              required=""
              v-model="emailId"
            />
          </div>

          <div class="form-group">
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              required=""
              v-model="password"
            />
            <span v-if="validation" class="errmsg">{{ err }}</span>
          </div>
          <div class="modal-footer d-flex justify-content-end">
            <button
              @click="submitClicked()"
              type="button"
              class="btn btn-primary btn-xs px-4"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./profile_settings.js"></script>
<style scoped>
@import "../../assets/css/savepages.css";

.fa-asterisk {
  color: #e56363 !important;
  font-size: 6px;
}

.errorClass {
  color: red;
  font-size: 15px;
}
.errorminClass {
  color: red;
  font-size: 13px;
}
.Image-user-Auth-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.modal-header-department {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.errmsg {
  font-size: 12px;
  color: red;
  float: left;
}

.width {
  width: min(400px, 90%);
}

.info {
  width: 14px;
  margin-left: 4px;
  height: 14px;
}

.create-user-mod {
  position: absolute;
  width: 350px;
  top: -114px;
  left: 9px;
}
.password-visible-block {
  right: 28px;
  top: 11px;
}
.password-visible-digi-block {
  right: 28px;
  top: 43px;
}
.loading-img {
  mix-blend-mode: lighten;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
</style>
