<template>
  <div class="ibox-title style_2 d-flex justify-content-between px-3">
    <h5 class="align-self-center">Users</h5>
    <div
      class="cursor-pointer position-relative align-self-center"
      v-if="this.designationPrivileges.includes(`Projects Create User`)"
    >
      <img
        src="../../assets/img/create.png"
        alt="Create Project User"
        @click="GoToManageUser()"
        width="20"
        height="20"
      />
      <div class="tool-tip">Create User</div>
    </div>
  </div>
  <div class="ibox-content-project scrollable-block">
    <div class="project-list1">
      <table class="table table-hover">
        <tbody>
          <tr v-for="user in userlist" :key="user.Id">
            <td>
              {{ user.firstName+"  "+user.lastName }}
            </td>
            <td>
              {{ user.role }}
            </td>
            <td class="text-center">
              <span class="position-relative cursor-pointer"
                ><i
                  class="fa fa-ellipsis-v cursor-pointer"
                  @click="sidePopup(user.id, 'users', e)"
                ></i>
              </span>
            
              <SidePopUp
                v-if="showBlock && popupid == user.id"
                :blockid="popupid"
                :dest="destination"
                :ev="event"
                v-on:clickout="showBlock = false"
              >
                <div
                  v-if="this.designationPrivileges.includes(`Projects Edit User`)"
                  @click="GoToManageUseredit(user.id)"
                >
                  Edit
                </div>
                <div
                  v-if="this.designationPrivileges.includes(`Projects Delete User`)"
                  @click="userDelete(user.id)"
                >
                  Delete
                </div>
              </SidePopUp>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- </div> -->
  <div class="create-user-modal" v-if="showPopuop">
    <div class="modal-dialog modal-width">
      <div class="modal-content">
        <div class="modal-header-user d-flex justify-content-bertween p-3">
          <h5 class="my-0 align-self-center">Add Users</h5>
          <span
            type="button"
            class="align-self-center"
            data-dismiss="modal"
            @click.prevent="ClosePopup()"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body">
          <label for="role" class="col-form-label pb-0"
            >Choose Role <sup><i class="fa fa-asterisk required"></i></sup
          ></label>
          <select
            class="form-control"
            id="role"
            name="role"
            v-model="UpdateCommand.roleId"
            @change="onChange($event)"
          >
            <option value="" disabled selected>-Select-</option>
            <option
              v-for="role in userprojlist"
              :key="role.id"
              :value="role.id"
            >
              {{ role.name }}
            </option>
          </select>
          <span class="errorClass" v-if="errors[0] == false"
            >Select a Role</span
          >
          <label class="col-form-label pb-0"
            >Choose User <sup><i class="fa fa-asterisk required"></i></sup
          ></label>
          <Multiselect
            v-model="UpdateCommand.userId"
            mode="multiple"
            valueProp="id"
            placeholder="Select User"
            label="name"
            :options="unAllocUserlist"
            :hideSelected="false"
            :closeOnSelect="false"
            class="multiselect-custom-theme"
          >
          </Multiselect>
          <div class="d-flex justify-content-end mt-2">
            <button
              type="button"
              class="btn btn-primary btn-xs px-4"
              @click.prevent="CreateAsync"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="create-user-modal" v-if="showPopuopedit">
    <div class="modal-dialog">
      <div class="modal-content2">
        <div class="modal-header-user">
          <button
            type="button"
            class="close cursor-pointer alignclosebtn"
            @click="ClosePopup"
          >
            <span aria-hidden="true">×</span><span class="sr-only">Close</span>
          </button>
          <!-- <h5 class="my-0 py-0 align-self-end">Edit Users</h5> -->
        </div>
        <div class="modal-body">
          <label for="role" class="col-form-label mb-0">Choose Role</label>
          <select
            class="form-control"
            name="role"
            id="role"
            v-model="CreateCommand.roleId"
          >
            <option
              v-for="role in userprojlist"
              :key="role.id"
              :value="role.id"
            >
              {{ role.name }}
            </option>
          </select>
          <!-- <label class="col-form-label">Choose User</label> -->
          <!-- <input 
            type="text"
            class="form-control"
            v-model="users.name"
            valueProp="id"
            label="name"
            maxlength="30"
            :options="unAllocUserlist"
            disabled
          /> -->
          <!-- <Multiselect
            v-model="CreateCommand.userId"
            mode="multiple"
            valueProp="id"
            label="name"
            :options="unAllocUserlist"
            :hideSelected="false"
            :closeOnSelect="false"
            class="multiselect-theme"
          >
          </Multiselect> -->
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary1"
            @click.prevent="UpdateAsync"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import SidePopUp from "../side_pop_up/side_pop_up.vue";
import Multiselect from "@vueform/multiselect";
export default {
  name: "userconfig",
  props: {
    projid: {
      type: String,
      default: "",
    },
    desigUserPrivileges: {
      type: String,
      default: "",
    },
  },
  components: {
    SidePopUp,
    Multiselect,
  },
  data() {
    return {
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/userprojectrole/`,
      baseapi: `${process.env.VUE_APP_Service_URL}configuration/projectrole/`,
      idtoken: "",
      userlist: [],
      userprojlist: [],
      errors: [],
      validations: [],
      showPopuop: false,
      showBlock: false,
      unAllocUserlist: [],
      popupid: "",
      roleId: "",
      rolesId: "",
      userId: "",
      showPopuopedit: false,
      UpdateCommandid: [],
      CreateCommand: {
        id: "",
        projectId: "",
        roleId: "",
        userId: [],
      },
      UpdateCommand: {
        projectId: "",
        roleId: "",
        userId: [],
      },
      destination: "",
      designationPrivileges : [],
    };
  },

  async mounted() {
    this.idtoken = store.getters.getToken;
    this.ListOrgs();
    this.projectUser();
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
  },

  methods: {
    async GoToManageUser() {
      this.unAllocUserlist = [];
      this.UpdateCommand.userId = [];
      this.UpdateCommand.roleId = "";
      this.showPopuop = true;
      this.projectUser();
    },
    async GoToManageUseredit(value) {
      this.unAllocUserlist = [];
      this.projectUser();
      this.CreateCommand.userId = [];
      this.UpdateCommand.userId = [];
      this.UpdateCommand.roleId = "";
      this.showBlock = false;
      this.showPopuopedit = true;
      await this.getandSetData(value);
      this.CreateCommand.roleId = this.UpdateCommandid.roleId;
      this.CreateCommand.userId.push(this.UpdateCommandid.userId);
      await this.ListOrgspopup(
        this.UpdateCommandid.roleId,
        this.UpdateCommandid.userId
      );
      await this.ListOrgs();
      this.UnallocatedUser(this.CreateCommand.roleId);
    },
    async getandSetData(userRoleId) {
      await axios
        .get(`${this.baseurl}getbyid?id=` + userRoleId,this.Head)
        .then((res) => {
          this.UpdateCommandid = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async onChange(event) {
      this.roleId = event.target.value;
      await this.UnallocatedUser();
    },
    // async onChange1(event) {
    //   this.roleId = event.target.value;
    //   await this.UnallocatedUser(this.roleId);
    // },
    async userDelete(id) {
      this.showBlock = false;
      const roleId = id;
      var confirmed = confirm("Are you sure you want to delete this user?");
      if (confirmed == true) {
        await axios
          .delete(
            `${this.baseurl}delete?userprojectroleid=`+roleId,this.Head
          )
          .then((res) => {
            alert("Deleted successfully");
            console.log(res);
            this.ListOrgs();
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data.detail);
            alert(err.response.data.detail);
          });
      }
    },
    async projectUser() {
      this.projectroleId = this.projid;
      await axios
        .get(`${this.baseapi}getall?projectId=${this.projectroleId}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.userprojlist = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async ListOrgs() {
      this.projectId = this.projid;
      await axios
        .get(`${this.baseurl}getpaginatedlist?ProjectId=${this.projectId}`, {
          // .get(`https://localhost:5001/userprojectrole/getpaginatedlist?ProjectId=${this.projectId}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.userlist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async ListOrgspopup() {
      this.projectId = this.projid;
      this.userId = this.UpdateCommandid.userId;
      this.rolesId = this.UpdateCommandid.userId;
      await axios
        .get(
          `${this.baseurl}getpaginatedlist?ProjectId=${this.projectId}&RoleId=${this.rolesId}&UserId=${this.userId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.userlistnew = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ClosePopup() {
      this.showPopuop = false;
      this.showPopuopedit = false;
      this.CreateCommand.roleId = "";
      this.CreateCommand.userId = [];
    },
    async UnallocatedUser(user) {
      this.projectId = this.projid;
      await axios
        .get(
          `${this.baseurl}getunallocatedusersforupdate?projectId=${this.projectId}&roleId=${user}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.unAllocUserlist = res.data;
          // this.roleId="";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async UpdateAsync() {
      if (this.CreateCommand.roleId == "" || this.CreateCommand.userId == "") {
        if (this.CreateCommand.roleId == "") {
          alert("Please select a role");
        }
        if (this.CreateCommand.userId == "") {
          alert("Please select a user");
        }
      } else {
        this.CreateCommand.projectId = this.projid;
        this.CreateCommand.userId.push(this.UpdateCommandid.userId);
        this.CreateCommand.id = this.UpdateCommandid.id;
        await axios
          .put(
            this.baseurl + "updateusersbyrole",
            this.CreateCommand,
            // .put(
            //   `https://localhost:5001/userprojectrole/updateusersbyrole`,
            //   this.CreateCommand,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            alert("User updated successfully");
            this.showPopuopedit = false;
            this.ListOrgs();
          })
          .catch((err) => {
            console.log(err);
            alert("User name already exist");
          });
      }
    },
    async CreateAsync() {
      if (this.UpdateCommand.roleId == "" || this.UpdateCommand.userId == "") {
        if (this.UpdateCommand.roleId == "") {
          alert("Please select a role");
        }
        if (this.UpdateCommand.userId == "") {
          alert("Please select a user");
        }
      } else {
        this.UpdateCommand.projectId = this.projid;
        await axios
          .post(this.baseurl + "create", this.UpdateCommand, {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log(res);
            alert("User added successfully");
            this.ListOrgs();
            this.showPopuop = false;
          })
          .catch((err) => {
            console.log(err);
            alert("User name already exist");
          });
      }
    },
    sidePopup(id, type, e) {
      e = window.event;
      this.event = e;
      this.destination = type;
      this.popupid = id;
      store.dispatch("setselectedid", id);
      this.showBlock = !this.showBlock;
    },
    // async setUserPrivileges(){
    //         let allPriv = {
    //             "Projects Edit User":"Edit",
    //             "Projects Delete User" : "Delete",
    //         }

    //         const privilegeArray = this.designationPrivileges.split(',').map((s) => s.trim());
    //         const keys = Object.keys(allFolderPriv);
    //         this.desigUserPrivileges = keys
    //         .filter((key) => privilegeArray.includes(key))
    //         .map((key) => allPriv[key]).join(',');
    //     }
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
@import "../../assets/css/style.css";
@import "../../assets/css/custom.css";
.fa-asterisk {
  color: #e56363 !important;
  font-size: 6px;
}
.modal1 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-width {
  width: min(400px, 90%);
}
.btn-primary1 {
  color: #fff;
  background-color: #145faf;
  /* border-color: #1ab394; */
  border: none;
}
.modal-content1 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 150%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-header-user {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.close-user {
  cursor: pointer;
}
.create-user-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.alignclosebtn {
  margin: 6px;
}
.modal-content2 {
  position: relative;
  display: flex;
  flex-direction: column;
  /* width: 200%; */
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.ibox-content-project {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 8px 20px 8px;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid;
  border-width: 1px;
}
.project-list1 table tr td {
  border-top: none;
  border-bottom: 1px solid #e7eaec;
  vertical-align: middle;
}
.scrollable-block {
  overflow-y: auto;
  height: 450px;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
</style>