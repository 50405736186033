<template>
  <div class="create-user-modal1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content animated bounceInTop">
        <div
          class="modal-header1 d-flex justify-content-between align-items-center"
        >
          <h5 class="">Document Approval</h5>
          <i class="fa fa-times close cursor-pointer" @click="close()"
            ><span class="sr-only">Close</span></i
          >
        </div>
        <div class="modal-body">
          <label class=""> {{ popUpHeading }} </label>
          <select
            class="form-control m-b w-50"
            name="account"
            @change="onChange($event)"
            v-model="designation"
          >
            <option value="" disabled selected>-Select-</option>
            <option
              :v-if="dropdownName == 'generalFiles'"
              v-for="role in designationLists"
              :key="role.id"
              :value="role.id"
            >
              {{ role.displayName }}
            </option>
            <option
              :v-if="dropdownName == 'Department'"
              v-for="role in designationList"
              :key="role.id"
              :value="role.id"
            >
              {{ role.displayName }}
            </option>

            <option
              :v-if="dropdownName == 'ProjectRole'"
              v-for="role in roleList"
              :key="role.id"
              :value="role.id"
            >
              {{ role.name }}
            </option>
          </select>
          <div class="height-scroll">
            <table class="table fixed-header">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      id="selectall"
                      @change="selectall()"
                    />
                    Users
                  </th>
                  <th></th>
                  <th class="text-center">Save</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, index) in userList" :key="user.id">
                  <td width="50%">
                    <input
                      class=""
                      type="checkbox"
                      :value="user.userName"
                      :id="user.userId"
                      v-model="isSelectedUser[index]"
                      @change="addApprover(user, index)"
                      :checked="userobject[user.userId]"
                      :disabled="isdDisabled[index]"
                    />
                    <span v-if="dropdownName == `generalFiles`">{{
                      user.name
                    }}</span>
                    <span v-if="dropdownName == 'Department'">{{
                      user.name
                    }}</span>
                    <span v-if="dropdownName == 'ProjectRole'">{{
                      user.userName
                    }}</span>
                  </td>
                  <td width="30%">
                    <input
                      class=""
                      type="checkbox"
                      id=""
                      v-model="isRequired[index]"
                      :disabled="isdDisabled[index]"
                      @change="addRequiredApprover(user, index)"
                    />
                    Required
                  </td>
                  <td width="20%" class="text-center">
                    <a :disabled="isdDisabled[index]"
                      ><img
                        class="fa fa-save edit-delete"
                        src="../../assets/img/save_color.png"
                        @click.prevent="singleSaveApprover(user, index)"
                        :disabled="isdDisabled[index]"
                    /></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <label v-if="showSave">
            *Unsaved changes will be lost if switching designations.</label
          >

          <button
            v-if="showSave"
            type="button"
            class="btn btn-primary btn-xs px-4 mr-3"
            @click="multipleSaveApprover()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./doc_Approval.js"></script>

<style scoped>
.create-user-modal1 {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
  align-items: flex-start !important;
}
.modal-header1 {
  padding: 10px 29px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}
.tree input {
  position: inherit !important;
}
.height-scroll {
  height: 250px;
  overflow-y: auto;
}
.fixed-header th {
  top: 0px;
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
}
</style>
