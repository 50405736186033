/* eslint-disable */
const state = () => ({
totaltime:0,
});

const getters = {
    getTotalTime(state: { totaltime: any }) {
        return state.totaltime;
        
   },
};

const actions = {
    async setTotalTime({ commit }: any, data: any) {
		commit("setTotalTime", data);
	},
};

const mutations = {
    setTotalTime(state: { totaltime: any }, timevar: any) {
		state.totaltime = timevar;
	},

};

export default {
	state,
    getters,
    actions,
    mutations
};