<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12">
      <h2>DMS</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox-title style_2">
          <h5>Document Approval List</h5>
          <div class="ibox-tools mt-1">
            <a href="#" class="mr-1" data-toggle="modal" data-target="#myModal">
              <img src="img/icons/add.png" alt=""
            /></a>
          </div>
        </div>
        <div class="ibox-content">
          <div class="row mb-4">
            <div class="col-md-4">
              <div class="form-group">
                <label class="col-form-label pb-0" for="product_name"
                  >Document Title</label
                >
                <input
                  type="text"
                  placeholder=""
                  class="form-control"
                  v-model="searchField.documentTitle"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group" id="data_1">
                <label class="col-form-label pb-0">Uploaded Date</label>
                <div class="input-group date">
                  <input
                    type="date"
                    max="9999-12-31"
                    class="form-control"
                    v-model="searchField.createdAt"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label class="col-form-label pb-0" for="product_name"
                  >Approval Status</label
                >
                <select
                  class="form-control m-b"
                  name="account"
                  v-model="searchField.status"
                >
                  <option>Approved</option>
                  <option>Pending</option>
                  <option>Rejected</option>
                </select>
              </div>
            </div>

            <div class="col-12 col-md-2 align-self-end mb-3">
          <span
            type="button"
            class="submit_btn px-5 py-2 w-100"
            @click="OnSearchButtonClicked"
            >Search</span
          >
        </div>
          </div>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th
                  width="25%"
                  @click="SortSelected('documentTitle', 1)"
                  id="1"
                >
                  <div class="d-flex flex-row justify-content-between">
                    Document Title
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  width="15%"
                  @click="SortSelected('createdUserName', 2)"
                  id="2"
                >
                  <div class="d-flex flex-row justify-content-between">
                    Uploaded By
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  width="10%"
                  class="text-center"
                  @click="SortSelected('CreatedAt', 3)"
                  id="3"
                >
                  <div
                    class="d-flex flex-row justify-content-between text-center"
                  >
                    Uploaded Date
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  width="10%"
                  class="text-center"
                  @click="SortSelected('version', 4)"
                  id="4"
                >
                  <div
                    class="d-flex flex-row justify-content-between text-center"
                  >
                    Version
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  width="11%"
                  @click="SortSelected('approvedStatus', 5)"
                  id="5"
                >
                  <div class="d-flex flex-row justify-content-between">
                    Approval Status
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  width="10%"
                  class="text-center"
                  @click="SortSelected('assignedByUsername', 6)"
                  id="6"
                >
                  <div class="d-flex flex-row justify-content-between">
                    Assigned By
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  width="10%"
                  class="text-center"
                  @click="SortSelected('assignedDateTime', 7)"
                  id="7"
                >
                  <div class="d-flex flex-row justify-content-between">
                    Assigned Date
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th width="5%" class="text-center">Status</th>
                <th v-if= "designationPrivileges.includes(`Approvals Document View`)"  width="5%" class="text-center">View</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="approval in approvalList" :key="approval.Id">
                <td>
                  {{ approval.documentTitle }}
                </td>
                <td>
                  {{ approval.createdUserName }}
                </td>
                <td class="text-center">
                  {{
                    approval.createdAt ? approval.createdAt.slice(0, 10) : ""
                  }}
                </td>
                <td class="text-center">{{ approval.version }}</td>
                <td class="text-center">
                  <span
                    v-if="approval.approvedStatus == 'Pending'"
                    @click="ApproveReject(approval.id, approval.version)"
                  >
                    <a href="#"
                      ><img
                        src="../../assets/img/approval_status.png"
                        alt="approval_status" /></a
                  ></span>
                  <span v-if="approval.approvedStatus != 'Pending'">{{
                    approval.approvedStatus
                  }}</span>
                </td>
                <td>
                  {{ approval.assignedByUsername }}
                </td>
                <td>
                  {{
                    approval.assignedDateTime
                      ? approval.assignedDateTime.slice(0, 10)
                      : ""
                  }}
                </td>
                <td class="text-center">
                  <span class="cursor-pointer"
                    @click="
                      ApproveStatus(
                        approval.documentTitle,
                        approval.id,
                        approval.version
                      )
                    "
                  >
                    <span v-if="approval.docApprovalStatus == 'Approved'"
                      ><img
                        src="../../assets/img/approved.png"
                        alt="approval_status"
                    /></span>
                    <span v-if="approval.docApprovalStatus == 'Pending'"
                      ><img
                        src="../../assets/img/pending.png"
                        alt="approval_status"
                    /></span>
                    <span v-if="approval.docApprovalStatus == 'Rejected'"
                      ><img
                        src="../../assets/img/rejected.png"
                        alt="approval_status" /></span
                  ></span>
                </td>
                <td class="text-center" v-if= "designationPrivileges.includes(`Approvals Document View`)">
                  <a
                    @click.prevent="
                      approvalScreen(approval.id, approval.version,approval.versionId)
                    "
                    ><i class="fa fa-eye edit-delete"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="col-md-12 d-flex justify-content-md-end px-0">
            <v-pagination
              v-model="currentPage"
              :pages="totalPage"
              :range-size="1"
              active-color="#ededed"
              @update:modelValue="loadPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <ApproveRejectPopUp
    v-if="showpop && designationPrivileges.includes(`Approvals Document Approve`)"
    @closemodal="showpop = false"
    :docId="this.docId"
    :version="this.versionNo"
  >
  </ApproveRejectPopUp>
  <ApprovalStatusPopUp
    v-if="showstatuspop"
    @closemodal="showstatuspop = false"
    :fileName="this.fileName"
    :docId="this.docId"
    :version="this.versionNo"
  >
  </ApprovalStatusPopUp>
</template>
<script src="./document_approval.js"></script>
<style scoped>
.badge-grn {
  background-color: #1ab394;
  color: #ffffff;
}
.badge-warning {
  background-color: #f8ac59;
  color: #ffffff;
}
.badge-danger {
  background-color: #ed5565;
  color: #ffffff;
}
</style>