<template>
  <!-- tree starts -->
  <!-- {{list}} -->

<span >
                    <ul
                      v-for="list in list"
                      :for="list.name"
                      :key="list.name"
                    >                    
                      <li>
                        <i v-if="subdept[list.id]" class="fs-4 mr-1 cursor-pointer" :class="[departmentswitch[list.id] ?'fa fa-caret-down':'fa fa-caret-right']" 
                        @click.prevent="getDeptId(list.id, list.name)" :aria-expanded="departmentswitch[list.id]"></i>
                        <i v-else class="fa fa-caret-right text-white mr-1" aria-hidden="true"></i>
                        <input
                          type="checkbox"
                          :id="list.id"
                          @change="                            
                             getDeptId(list.id)
                          "
                        />
                        <label :for="list.id" 
                        :class="bolddept ==list.id? 'tree_label tree_label_bold':'tree_label'">
                          {{list.name}}</label>
                      </li>
                       <TreeBrowser
                        v-if="departmentswitch[list.id] || setofopendepartments.includes(list.id)"
                  :list="subdeptlist[list.id]"
                  clasification = "folderlists"
                  :bolddept="bolddept"
                  :opendepartmentlist="opendeptmnt"
                  patid=""
                >
                </TreeBrowser>
                    </ul>
</span>
</template>

<script src="./TreeBrowser.js"></script>

<style  scoped>
@import "./tree.css";

.folder_tree_label{
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 5px 0 5px 1rem;
  position:relative;
}
.folder_tree_label:hover{
  background-color:#f0f0f0;
  cursor:pointer;
}
.folder_tree_label:before{
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  margin: 0 0.5em 0 -2em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  content: url("../../assets/img/folder-close.png");
  text-align: center;
  line-height: .8em;
}
.myStyle {
  color: blue;
}
.innertemplate {
  display: block;
}
.bold-font {
  font-weight:bold;
}

.vtl.vtl-drag-disabled {
  background-color: #d0cfcf;
}
.vtl.vtl-disabled {
  background-color: #d0cfcf;
}
  .icon:hover {
      cursor: pointer;
  }
  .muted {
    color: gray;
    font-size: 80%;
  }
  .hideandseek {
  display: none !important;
}
.tree_label_bold{
    font-weight: bold;
}
</style>
