/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
export default {
    components: { Multiselect },
    data() {
        return {
            baseurl: `${process.env.VUE_APP_Service_URL}account-core/roles/`,
            idtoken: "",
            showDesig: false,
            roleList: "",
            roleName:"",
            roleDesc:"",
            retrievebox: true,
            roleId: "",
            index:0,
            update: {
                roleId: "",
                roleName: "",
                description: "",
                rolePrivilages:[],
                displayName:"",
                active: true,
            },
            values: {
                roleName: "",
                rolePrivilages: [],
                description: "",
                status: true,
            },
            search: {
                createdby: "",
                status: "",
                name: "",
            },
            currentPage: 1,
            pageindex: 1,
            pagesize: 10,
            totalPage: 1,
            SortProperty: "roleName",
            SortOrder: false,
            errors: [],
            validations: [],
            privilegeList:"",
            editableItem:[]
        };
    },

    async mounted() {
        this.idtoken = store.getters.getToken
        this.ListRoles();
        this.getPrivilege();
    },

    methods: {

        async OnSearchButtonClicked() {
            this.pageindex = 1;
            this.currentPage = 1;
            this.searchFilter();
        },
        updatename(value){      
            this.roleName=value;
            console.log("Name",this.roleName)
          },
          updatedesign(value){
            this.roleDesc=value;
            console.log("Desc",this.roleDesc)
          },
        async closebutton() {
            this.update={}
            this.retrievebox = true;
        },
        async getPrivilege() {
            await axios
                .get(`${this.baseurl}getallprivilage`,
                {

                })
                .then((res) => {
                    this.privilegeList=res.data;
                    console.log("privvvv",this.privilegeList);
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err)
                });
        },

        async deleteRole(Id) {
            const roleId = Id;
            var confirmed = confirm("Are you sure you want to delete this role?");
            if (confirmed == true) {
                console.log("delete",roleId);
                await axios
                    .delete(`${this.baseurl}delete`,
                    {data:{
                        role_Id: roleId
                      }},
                        {
                            // headers: {
                            //     Authorization: "Bearer " + this.idtoken,
                            //     "Content-Type": "application/json",
                            // },
                        })
                    .then((res) => {
                        alert("Deleted successfully");
                        console.log(res);
                        this.ListRoles();
                    })
                    .catch((err) => {
                        console.log(err)
                        console.log(err.response.data.detail);
                        alert(err.response.data.detail);
                    });
            }
        },


        async edit(Id,privilage) {
            this.retrievebox = false;
            this.update.roleId = Id;
            this.update.roleName = this.trim(this.update.roleName)
            this.update.description = this.trim(this.update.description)
            this.update.rolePrivilages=privilage;
            if (this.update.roleName == "" || this.update.roleName == null) {
                alert("Enter a valid role name");
            }
            else {
                await axios
                    .put(
                        `${this.baseurl}update`,
                        this.update,
                        {
                            // headers: {
                            //     Authorization: "Bearer " + this.idtoken,
                            //     "Content-Type": "application/json",
                            // },
                        }
                    )
                    .then((res) => {
                        console.log("result", res)
                        this.ListRoles();
                        alert("Updated successfully");
                        this.closebutton();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        async openEdit(Id) {
            this.retrievebox = false;
            this.roleId = Id;
            await axios
            .get(
              `${this.baseurl}getbyid?guid=${this.roleId}`,
              {
                // headers: {
                //   Authorization: "Bearer " + this.idtoken,
                //   "Content-Type": "application/json",
                // },
              }
            )
            .then((res) => {
                console.log("editableItem",res.data)
              this.editableItem = res.data;
              console.log("editableItem",this.editableItem)
              this.update.roleId = this.editableItem.Id;
              this.update.roleName = this.editableItem.roleName;            
              this.update.description = this.editableItem.description;
              this.update.rolePrivilages=this.editableItem.rolePrivilage;
              this.update.active = this.editableItem.status;
              
          })
          .catch((err) => {
            console.log(err);
          })
        },
        async setfunctions() {
            if (this.search.status != '' || this.search.name != '') {
                this.searchFilter()
            }
            else this.ListRoles()
        },
        async ListRoles() {
            await axios
                .get(
                    `${this.baseurl}search?PageNumber=${this.pageindex}&PageSize=${this.pagesize}&SortProperty=${this.SortProperty}&IsDescending=${this.SortOrder}`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    this.roleList = res.data.data;
                    console.log("Roles", this.roleList)
                    this.totalPage = parseInt(res.data.totalCount / this.pagesize);
                    if (res.data.totalCount % this.pagesize != 0) {
                        this.totalPage = this.totalPage + 1;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.roleList = []
                    alert("No data found!")
                })
        },
        async searchFilter() {
            await axios
                .get(
                    `${this.baseurl}search?PageNumber=${this.pageindex}&PageSize=${this.pagesize}&rolename=${this.search.name}&status=${this.search.status}&SortProperty=${this.SortProperty}&IsDescending=${this.SortOrder}`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    this.roleList = []
                    this.roleList = res.data.data;
                    this.totalPage = parseInt(res.data.totalCount / this.pagesize);
                    if (res.data.totalCount % this.pagesize != 0) {
                        this.totalPage = this.totalPage + 1;
                    }
                    console.log("roleList is", this.roleList);
                    console.log(res.data.entities);
                })
                .catch((err) => {
                    console.log(err)
                    this.roleList = []
                    alert("No data found!")
                })
        },
        SortSelected(sortProp, id) {
            console.log('SortPropChanged');
            const selectedElement = document.getElementById(id)
            const selectedElementID = document.getElementById(id).id
            if (sortProp != null && id == selectedElementID) {
                this.sortDesc = !this.sortDesc
                if (this.SortOrder == 0) {
                    if (selectedElement.classList.contains("sortAsc")) {
                        selectedElement.classList.remove("sortAsc")
                        selectedElement.classList.add("sortDesc")
                    }
                    else selectedElement.classList.add("sortDesc")
                }
                else {
                    if (selectedElement.classList.contains("sortDesc")) {
                        selectedElement.classList.remove("sortDesc")
                        selectedElement.classList.add("sortAsc")
                    }
                    else selectedElement.classList.add("sortAsc")
                }
            }
            this.pageindex = 1;
            this.currentPage = 1;
            this.SortProperty = sortProp;
            this.SortOrder = this.SortOrder == true ? false : true;
            this.ListRoles();
        },
        async createRolesfunc(value) {
            document.body.style.overflowY = "hidden"
            this.values.roleName="" 
            this.values.description=""
            this.values.rolePrivilages=[]
            this.errors=[]
            if (value == "createRole") {
                this.showDesig = !this.showDesig;
            }
        },
        async onclosed(value) {
            document.body.style.overflowY = "auto"
            if (value == "createRole") {
                this.showDesig = false;
            }
        },
        // async clear() {
        //     this.values.roleName = "";
        //     this.values.description = "";
        //     this.values.status = false;
        // },
        async createRole() {
            await this.validateIsNull();
            this.values.roleName=this.trim(this.values.roleName)
      this.values.description=this.trim(this.values.description)
            console.log("eeeeeeee")
            if (this.errors[0]) {
                console.log("eeeeeeee")
                await axios
                    .post(`${this.baseurl}createrole`, this.values,
                        {
                            // headers: {
                            //     Authorization: "Bearer " + this.idtoken,
                            //     "Content-Type": "application/json",
                            // },
                        })
                    .then((res) => {
                        console.log(res);
                        alert("Role created successfully");
                        this.showDesig = false;
                        document.body.style.overflowY = "auto"
                       // this.clear();
                        this.ListRoles();
                    })
                    .catch((err) => {
                        console.log(err);
                        console.log(err.response.data.errors.RoleName);
                        if(err.response.data.errors.RoleName){

                        alert(err.response.data.errors.RoleName);
                        }
                        if(this.values.rolePrivilages=="")
                        {
                            alert(err.response.data.errors.RolePrivilages
                                ); 
                        }
                    });
            }
        },
        async validateIsNull() {

            if (this.values.roleName == "") {
                this.validations[0] = false;
                this.errors = this.validations;
            } else if (this.values.roleName.trim("") == "") {
                this.validations[0] = false;
                this.errors = this.validations;
            } else {
                console.log("vvvvvvvv2")
                this.validations[0] = true;
                this.errors = this.validations;
            }
        },
        trim(value) {
            value = value.replace(
                /^\s+|\s+$/gm,
                ""
            );
            return value
          },
        // async createFolderfunc(value) {
        //     document.body.style.overflowY = "hidden"
        //     if (value == "createRole") {
        //         this.showDesig = !this.showDesig;
        //     } else this.uploadpopupmodal = true;
        // },


        loadPage: function (page) {
            this.currentPage = page;
            this.pageindex = page;
            this.setfunctions();
        },
    },
};