<template>
    <div class="d-grid">
    <div class="text-center animated fadeInDown">
        <h2 class="user"><strong>User already verified</strong></h2>
        <div class="error-desc">
            Please sign in using the original email address and password <br /> you registered and verified
            under.<br />
            <a href="index.html" class="btn btn-primary m-t">Continue</a>
        </div>
    </div>
</div>
</template>
<style scoped>
.d-grid{
    display: grid;
    place-items: center;
    height:100vh
}
.user{
    color: #7B8CEA;
}
</style>
<script src="./already_verified.js"></script>