<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0">Manage Project</h2>
    </div>
   <div class="col-2 col-md-2 align-self-center">
        <i class="fa fa-times cursor-pointer close" @click="onclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content p-3">
      <div class="form-group row">
   
        <div class="col-md-12 mb-1">
          <label class="col-form-label col-md-12 px-0">Project Name</label>
          <div class="input-group date">
            <input
              type="text"
              class="form-control address"
              v-model="projectname"
            />
          </div>
        </div>
        <div class="col-md-12 mb-1">
          <label class="col-form-label col-md-12 px-0">Description</label>
          <div class="input-group date">
             <textarea class="form-control" aria-label="With textarea" v-model="description"></textarea>
          </div>
        </div>
        <div class="col-md-6 my-1 d-flex">
          <div class="input-group date">
            <div class="i-checks aligncheckbox">
              <label class="mr-2">Active</label>
              <input type="checkbox" value="" v-model="active"  />
            </div>
          </div>
          <!-- <div class="input-group date">
            <div class="i-checks aligncheckbox">
              <label class="mr-2">Lock</label><input type="checkbox" value="" v-model="locked" />
            </div>
          </div> -->
        </div>
        <div class="col-md-6 my-1 d-flex justify-content-md-end">
          <button
            class="btn btn-primary cancel_btn py-2 px-5 mx-3"
            @click.prevent="clear"
          >
            Clear
          </button>
          <button
            class="btn btn-primary py-2 px-5"
            type="submit"
            @click.prevent="saveoredit"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./createProject.js"></script>
<style scoped>
@import "../../assets/css/savepages.css";
.wrapper-content {
  width: 100%;
}
textarea {
  width: 100%;
}
.imp {
  color: #ff0000;
  font-size: 7px;
}

.col-form-label {
  font-size: 14px;
}
.address {
  width: 100%;
}

</style>