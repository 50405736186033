<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-0 col-12 col-md-10">
      <h2 class="my-0 py-0">Manage Role</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
      <div class="ibox-content">
        <div class="form-group row mb-3">
          <div class="col-12 col-md-5 my-1">
            <div class="form-group mb-md-0 pr-1">
              <label class="mb-0">Name</label>
              <input
                type="text"
                class="form-control my-md-0"
                v-model="search.name"
              />
            </div>
          </div>
          <div class="col-12 col-md-5 my-1 pr-1">
            <div class="form-group mb-md-0">
              <label class="mb-0">Status</label>
              <select class="form-select" v-model="search.status">
                <option value="ALL" selected="">All</option>
                <option value="ACTIVE">Active</option>
                <option value="INACTIVE">Inactive</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-2 align-self-end my-1">
            <span
              type="button"
              class="submit_btn px-5 w-100"
              @click="OnSearchButtonClicked"
              >Search</span
            >
          </div>
        </div>
        <div
          class="ibox-title style_2 d-flex justify-content-between"
        >
          <h5 class="my-0 py-0 align-self-center">Roles</h5>
          <div
            class="position-relative align-self-center"
            aria-label="Create Role"
            tabindex="0"
          >
            <img
              class="cursor-pointer"
              src="../../assets/img/create.png"
              alt="Create Role"
              @click="createRolesfunc('createRole')"
            />
            <div class="tool-tip">Create Role</div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>

                <th width="30%" class="sort_block" @click="SortSelected('roleName', 1)" id="1">
                  <div class="d-flex flex-row justify-content-between">
                    Roles
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th width="30%">
                  <div class="d-flex flex-row justify-content-between">
                    Description
                  </div>
                </th>
                <th width="22%">
                    Privilege
                  </th>
                <th class="text-center">Active</th>
                <th class="text-center">Edit</th>
                <th class="text-center">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="role in roleList" :key="role.Id">
                <td >
                  <span
                    v-if="!retrievebox && roleId == role.id"
                  >
                    <input
                      type="text"
                      class="form-control"
                      maxlength="20"
                      v-model="update.roleName"  
                    />
                  </span>
                  <span v-else >{{ role.displayRoleName }}</span>
                </td>
                <td >
                  <span
                    v-if="!retrievebox && roleId == role.id"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="update.description"  
                      maxlength="90"
                    />
                  </span>
                  <span v-else >{{ role.description }}</span>
                </td>
                 <td>
                  <span v-if="!retrievebox && roleId == role.id">
                    <Multiselect
                      v-model="update.rolePrivilages"
                      mode="multiple"
                      placeholder="Select privileges"
                      :options="privilegeList"
                      valueProp="id"
                      label="name"
                      :hideSelected="false"
                      :closeOnSelect="false"
                      class="multiselect-custom-theme"
                      trackBy="name"
                    >
                      <template v-slot:option="{ option }">
                        {{ option.name }}
                      </template>
                    </Multiselect>
                  </span>
                  <span v-else> <span v-for="(privilege,index) in role.privilageNames" :key="privilege"> 
                    <span v-if="index == role.privilageNames.length - 1">
                  {{ privilege }}&nbsp;</span>
                  <span v-else>
                  {{ privilege }},&nbsp;</span></span> </span>
                </td>
                <td class="text-center">
                    <div class="position-relative mt-2" v-if="!retrievebox && roleId == role.id">
                      <input v-model="update.active" type="checkbox" />
                    </div>
                    <div class="position-relative" v-else>
                      <input
                        v-model="role.status"
                        type="checkbox"
                        onclick="return false"
                      />
                    </div>
                </td>
                <td class="text-center">
                  <div class="mt-1" v-if="!retrievebox && roleId == role.id">
                    <img
                      class="cursor-pointer mr-2"  
                      alt="save the information"
                      src="../../assets/img/table_cell_save.png"
                      @click="
                         edit(
                          role.id,
                          update.roleName,
                          update.description,
                          update.status,
                          update.rolePrivilages
                        )
                      "
                    >
                    <img 
                      class="cursor-pointer"
                      src="../../assets/img/table_cell_close.png"
                      alt="close the edit box"
                      @click="closebutton()"
                    >
                    </div>
                  <div v-else>
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/Edit.png"
                      alt="Edit"
                      @click="openEdit(role.id)"
                    />
                  </div>
                </td>

                <td class="text-center">
                  <div class="mt-1" v-if="!retrievebox && roleId == role.id">
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/delete.png"
                      alt="Delete"
                      @click="deleteRole(role.id)"
                    />
                    </div>
                  <div v-else>
                    <img
                      class="cursor-pointer"
                      src="../../assets/img/delete.png"
                      alt="Delete"
                      @click="deleteRole(role.id)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 d-flex justify-content-md-end px-0">
          <v-pagination
            v-model="currentPage"
            :pages="totalPage"
            :range-size="1"
            active-color="#ededed"
            @update:modelValue="loadPage"
          />
        </div>
      </div>
    </div>
  <!-- Popup library -->

 <div class="create-folder-modal" v-if="showDesig == true">
    <div class="folder-modal-container">
      <div class="title-class1 d-flex justify-content-between py-2 px-3">
        <h4 class="my-0 py-0 align-self-end">Create Role</h4>
        <div class="cursor-pointer" @click="onclosed('createRole')">
          <i class="fa fa-times align-self-end"></i>
        </div>
      </div>
      <div class="ibox-content p-4">
        <div class="form-group row">
          <div class="col-md-6 mb-1">
            <label class="col-form-label"
              >Role<sup><i class="fa fa-asterisk imp"></i></sup
            ></label>
            <input
              type="text"
              placeholder="Enter role name"
              class="form-control"
              v-model="values.roleName"
              maxlength="30"
            />
            <span class="errorClass" v-if="errors[0] == false"
            >please enter role name
          </span>
          </div>
          <div class="col-md-6 mb-1">
            <label class="col-form-label"
              >Privilege<sup><i class="fa fa-asterisk imp"></i></sup
            ></label>
            <Multiselect
              v-model="values.rolePrivilages"
              mode="multiple"
              
              :options="privilegeList"
              valueProp="id"
              label="name"
              :hideSelected="false"
              :closeOnSelect="false"
              class="multiselect-custom-theme"
              trackBy="name"
            >
              <template v-slot:option="{ option }">
                {{ option.name }}
              </template>
            </Multiselect>
          </div>
        </div>
        <div class="form-group py-2">
          <textarea
            class="form-control"
            v-model="values.description"
            placeholder="Description"
          ></textarea>
        </div>
        <div class="form-group d-flex justify-content-between">
          <div class="input-group">
            <label for="active" class="mr-2 mb-0 align-self-center ml-2"
              >Active</label
            >
            <input type="checkbox" value="" v-model="values.status" />
          </div>
          <button
            type="button"
            class="btn btn-primary text-right"
            @click="createRole()"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- popup ends -->
</template>

<script src="./roles.js">
</script>
<style scoped>

.submit_btn {
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.position-relative img {
  width: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.headingunderline {
  text-decoration-line: underline;
}

.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}
.title-class1 {
  background-color: #618bd7;
  color: #fff;
}
.errorClass {
  color: red;
  font-size: 13px;
}
.button-class {
  float: right;
}
</style>
