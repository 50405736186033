<template>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="pl-md-10 col-10 col-md-10 px-0">
        <h2 class="my-0 py-0">Edit Code Review Document</h2>
      </div>
      <div class="col-2 col-md-2 px-0 align-self-center">
        <i class="fa fa-times close cursor-pointer" @click="onClosed()"></i>
      </div>
    </div>
    <div class="wrapper wrapper-content">
      <template v-if="!loadingForm">
          <div class="row">
              <div class="col-12 col-md-3 mb-2" ><div class="input-skeleton position-relative" :class="{ 'loading': !loadingForm }"><input class="form-control"  type="text" name="" value="" disabled></div></div>
              <div class="col-12 col-md-3 mb-2"><div class="input-skeleton position-relative" :class="{ 'loading': !loadingForm }"><input class="form-control" type="text"  name="" value="" disabled></div></div>
              <div class="col-12 col-md-3 mb-2"><div class="input-skeleton position-relative" :class="{ 'loading': !loadingForm }"><input class="form-control" type="text"  name="" value="" disabled></div></div>
              <div class="col-12 col-md-3 mb-2"><div class="input-skeleton position-relative" :class="{ 'loading': !loadingForm }"><input class="form-control" type="text"  name="" value="" disabled></div></div>
              <div class="col-12 col-md-3 mb-2"><div class="input-skeleton position-relative" :class="{ 'loading': !loadingForm }"><input class="form-control" type="text"  name="" value="" disabled></div></div>
              <div class="col-12 col-md-3 mb-2"><div class="input-skeleton position-relative" :class="{ 'loading': !loadingForm }"><input class="form-control" type="text"  name="" value="" disabled></div></div>
              <div class="col-12 col-md-3 mb-2"><div class="input-skeleton position-relative" :class="{ 'loading': !loadingForm }"><input class="form-control" type="text"  name="" value="" disabled></div></div>
              <div class="col-12 col-md-3 mb-2"><div class="input-skeleton position-relative" :class="{ 'loading': !loadingForm }"><input class="form-control" type="text"  name="" value="" disabled></div></div>
              <div class="col-12 col-md-3 mb-2"><div class="input-skeleton position-relative" :class="{ 'loading': !loadingForm }"><input class="form-control" type="text"  name="" value="" disabled></div></div>
              <div class="col-12 col-md-3 mb-2" ><div class="input-skeleton position-relative" :class="{ 'loading': !loadingForm }"><input class="form-control"  type="text" name="" value="" disabled></div></div>
              <div class="col-12 col-md-3 mb-2"><div class="input-skeleton position-relative" :class="{ 'loading': !loadingForm }"><input class="form-control"  type="text" name="" value="" disabled></div></div>
              <div class="col-12 col-md-3 mb-2"><div class="input-skeleton position-relative" :class="{ 'loading': !loadingForm }"><input class="form-control"  type="text" name="" value="" disabled></div></div>
          </div>
         </template>
    <template v-else>
      <form class="row">
              <div  v-if="templateElements.projectName" class="col-12 col-md-3">
                  <label class="mb-0" for="projectName">Project</label><span class="errorMsg">*</span>
                  <input disabled class="form-control" type="text" ref="projectName" v-model="projectName" />
                  <div class="errorMsg" v-if="showValidations[0] && projectName === ''">Please
                              enter a field</div>
              </div>
              <div v-if="templateElements.storyTitle" class="col-12 col-md-3">
                  <label class="mb-0" for="title">Title</label><span class="errorMsg">*</span>
                  <input class="form-control" id="title" maxlength="250" type="text" ref="storyTitle" v-model="storyTitle" name="title" />
                  <div class="errorMsg" v-if="showValidations[0] && storyTitle === ''">Please
                              enter a field</div>
              </div>
              <template v-for="(fields, index) in storyDetails" :key="index" >
                  <div v-if="fields != 'Remarks'" class="col-12 col-md-3">
                      <div class="form-group" v-if="fields === 'Story Id'">
                          <label class="mb-0" :for="fields">{{ fields }}</label><span class="errorMsg">*</span>
                          <input class="form-control" ref="" v-model="storyId" maxlength="30" :id="fields" type="text" :name="fields" />
                          <div class="errorMsg" v-if="showValidations[index] && storyId === ''">Please
                              enter a field</div>
                      </div>
                      <div class="form-group" v-if="fields === 'Module Lead'">
                          <label class="mb-0" :for="fields">{{ fields }}</label><span class="errorMsg">*</span>
                          <select class="form-control" name="account" ref="" v-model="moduleLead">
                          <option disabled selected>Select</option>
                          <option v-for="user in userList" :key="user.id" :value="user.name">
                              {{ user.name}}
                          </option>
                      </select>
                      <div class="errorMsg" v-if="showValidations[index] && moduleLead === ''">Please
                          enter a field</div>
                      </div>
                      <div class="form-group" v-if="fields === 'Module Name'">
                          <label class="mb-0" :for="fields">{{ fields }}</label><span class="errorMsg">*</span>
                          <input class="form-control" maxlength="100" ref="" v-model="moduleName" :id="fields" type="text" :name="fields" />
                          <div class="errorMsg" v-if="showValidations[index] && moduleName === ''">Please
                              enter a field</div>
                      </div>
                      <div class="form-group" v-if="fields === 'Developer Name'">
                          <label class="mb-0" :for="fields">{{ fields }}</label><span class="errorMsg">*</span>
                          <select class="form-control" name="account" ref="" v-model="developerName">
                              <option disabled selected>Select</option>
                              <option v-for="user in userList" :key="user.id" :value="user.name">
                                  {{ user.name }}
                              </option>
                          </select>
                          <div class="errorMsg" v-if="showValidations[index] && developerName === ''">Please
                              enter a field</div>
                      </div>              
                      <div class="form-group" v-if="fields === 'Code Reviewer Name'">
                          <label class="mb-0" :for="fields">{{ fields }}</label><span class="errorMsg">*</span>
                          <select class="form-control" name="account" ref="" v-model="codeReviewerName">
                              <option value="" disabled selected>Select Code Reviewer</option>
                              <option v-for="user in userList" :key="user.id" :value="user.name">
                                  {{ user.name }}
                              </option>
                          </select>
                          <div class="errorMsg" v-if="showValidations[index] && codeReviewerName === ''">Please
                              enter a field</div>
                      </div>
                      <div class="form-group" v-if="fields === 'Development Start Date'">
                          <label class="mb-0" :for="fields">{{ fields }}</label>
                          <input class="form-control" ref="" v-model="developmentStartDate" :id="fields" type="date"
                              :name="fields" />
                              <div class="errorMsg" v-if="devdateerror">End date should not be less than start date </div>
                      </div>
                      <div class="form-group" v-if="fields === 'Development End Date'">
                          <label class="mb-0" :for="fields">{{ fields }}</label>
                          <input class="form-control" ref="" v-model="developmentEndDate" :id="fields" type="date" :name="fields" />                         
                      </div>
                      <div class="form-group" v-if="fields === 'Code Review Start Date'">
                          <label class="mb-0" :for="fields">{{ fields }}</label>
                          <input class="form-control" ref="" v-model="codeReviewStartDate" :id="fields" type="date" :name="fields" />
                              <div class="errorMsg" v-if="codereviewdateerror">End date should not be less than start date </div>
                      </div>
                      <div class="form-group" v-if="fields === 'Code Review End Date'">
                          <label class="mb-0" :for="fields">{{ fields }}</label>
                          <input class="form-control" ref="" v-model="codeReviewEndDate" :id="fields" type="date" :name="fields" />
                      </div>
                      <div class="form-group" v-if="fields === 'Hours Taken'">
                          <label class="mb-0" :for="fields">{{ fields }}</label>
                          <input class="form-control" ref="" v-model="hoursTaken" :id="fields" type="number" max="999" maxlength="100"  @keypress="isNumber($event, 'major')" @keydown="preventInput" min="0" :name="fields" />
                      </div>
                      <div class="form-group" v-if="fields === 'Page Link'">
                          <label class="mb-0" :for="fields">{{ fields }}</label>
                          <input class="form-control"  ref="" maxlength="150" v-model="pageLink" :id="fields"
                              type="text" :name="fields" />
                      </div>
                  </div>
                  <div class="form-group col-12 col-md-9" v-else>
                      <label class="mb-0" :for="fields" v-if="fields === 'Remarks'">{{ fields }}</label>
                      <textarea class="form-control height-40" ref="" maxlength="250" v-model="remarks" :name="fields" id="remarks" cols="12" rows="2"></textarea>
                  </div>
              </template>
          </form>
      <div v-for="(table, index) in temptableList" :key="index">
        <template v-if="table.tableHeader != 'Signature Table'">               
                  <div class="d-flex justify-content-between mb-2" :class="{'exception-header p-2': table.tableHeader === 'Exception/ErrorReport' }">
                      <h3 class="my-0">{{ table.tableHeader}}
                      </h3>
                      <div class="add-row-block position-relative cursor-pointer" v-if="table.tableHeader != 'Check List' && table.tableHeader != 'Exception/ErrorReport'" role="button" tabindex="0" @keyup.enter="addRow(index,undefined)" @click.prevent="addRow(index,undefined)">
                          <img  src="../../assets/img/create.png" alt="Add row" >
                          <div class="tool-tip">Add Row</div>         
                      </div>
                      <div v-else-if="table.tableHeader == 'Check List'" class="add-row-block position-relative cursor-pointer" role="button" tabindex="0" @keyup.enter="addRow(index,table.tableData[0])" @click.prevent="addRow(index,undefined)">
                          <img  src="../../assets/img/create.png" alt="Add row" >
                          <div class="tool-tip">Add Row</div>
                      </div>
                      <div v-else class="add-row-block position-relative cursor-pointer" role="button" tabindex="0" @keyup.enter="addRow(index,table.tableData[0])" @click.prevent="addRow(index,undefined)">
                          <img  src="../../assets/img/add_row.png" alt="Add row" >
                          <div class="tool-tip">Add Row</div>
                      </div>
                      
                  </div>
                  <table class="table table-bordered" :id="index">
                      <thead class="thead-grey">
                          <th v-for="(elements, elemenKey) in table.tableContents" :key="elemenKey">
                              <span scope="col">{{ elements }}</span><span class="errorMsg" v-if="elements==='Title' || elements==='Acceptance Criteria' || elements==='Answer' || elements==='Result'">
                              *</span>
                          </th>
                          <th class="text-center">Actions</th>
                      </thead>
                      <tbody>
                          <tr v-for="(columns, columnindex) in tableRows.tableColumns[index]" :key="columnindex">
                              <td class="align-middle" v-for="(data, dataindex) in columns" :key="dataindex">
                                <div v-if="table.tableHeader==='Acceptance Criteria' && dataindex === 1">
                                      <div>
                                          <label class="mr-2">
                                          <input type="radio" v-model="tableList[index].tableContents[dataindex].tableData[columnindex]" :name="'acceptancecriteria'+columnindex" value="Pass">
                                          Pass</label>
                                          <label>
                                          <input type="radio" v-model="tableList[index].tableContents[dataindex].tableData[columnindex]" :name="'acceptancecriteria'+columnindex" value="Fail">
                                          Fail
                                          </label> 
                                      </div>                                   
                                      <div v-if="validationList.length != 0 && validationList[index].tableContents[dataindex].tableData[columnindex] === ''">
                                          <span class="errorMsg"
                                        >Please
                                          enter a field
                                      </span>
                                      </div>                               
                                  </div>
                                  <div v-else-if="table.tableHeader === 'Check List' && dataindex === 0 && 
                                  checklisttabledata.includes(tableList[index].tableContents[dataindex].tableData[columnindex])">
                                      {{ tableList[index].tableContents[dataindex].tableData[columnindex] }}
                                  </div>
                                  <div v-else-if="table.tableHeader === 'Exception/ErrorReport'">
                                      <div v-if="dataindex===2">
                                          <select
                                              class="form-control"
                                              name="Level"
                                              v-model="tableList[index].tableContents[dataindex].tableData[columnindex]"
                                          >
                                              <option value="" selected>-Select-</option>
                                              <option value="Critical">Critical</option>
                                              <option value="High">High</option>
                                              <option value="Medium">Medium</option>
                                              <option value="Low">Low</option>
                                          </select> 
                                      </div>
                                      <div v-else>
                                          <input class="form-control"  maxlength="250"  type="text" v-model="tableList[index].tableContents[dataindex].tableData[columnindex]">                                       
                                      </div>                                  
                                  </div>
                                  <div v-else>
                                      <div v-if="table.tableHeader==='Check List' && dataindex === 1">
                                          <label class="mr-2">
                                          <input type="radio" v-model="tableList[index].tableContents[dataindex].tableData[columnindex]" :name="'comments/suggestions'+columnindex" value="yes">
                                         Yes</label>
                                         <label>
                                          <input type="radio" v-model="tableList[index].tableContents[dataindex].tableData[columnindex]" :name="'comments/suggestions'+columnindex" value="no">
                                          No</label>
                                      </div>
                                      <div v-else-if="table.tableHeader==='Check List' && dataindex === 3"> <input ref="" class="form-control" type="number" @input="numofbugsinput($event,index,dataindex,columnindex)"  v-model="tableList[index].tableContents[dataindex].tableData[columnindex]"/> </div>
                                      <div v-else>
                                      <input ref="" v-model="tableList[index].tableContents[dataindex].tableData[columnindex]
                                          " class="form-control" type="text" maxlength="500"  v-if="dataindex < columns.length" />
                                      </div>
                                      <span v-if="validationList.length != 0 && (table.tableHeader==='Check List'|| table.tableHeader==='Acceptance Criteria')">
                                          <span v-if="dataindex!=2 && dataindex!=3">
                                              <span class="errorMsg"
                                                  v-if="validationList[index].tableContents[dataindex].tableData[columnindex] === ''">Please
                                                  enter a field
                                          </span>
                                          </span>
                                      </span>
                                      <span v-else-if="validationList.length != 0 && table.tableHeader!='Check List' && table.tableHeader!='Exception/ErrorReport'"> <span class="errorMsg"
                                                  v-if="validationList[index].tableContents[dataindex].tableData[columnindex] === ''">Please
                                                  enter a field
                                          </span></span>
                                      
                                  </div>
                              </td>
                              <td class="align-middle">
                                  <div class="d-flex justify-content-around">
                                      <img class="cursor-pointer" src="../../assets/img/delete.png" alt="Delete"
                                      @keyup.enter="deleteRow(index, columnindex)"
                                          @click.prevent="deleteRow(index, columnindex)"  />
                                  </div>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </template>
      </div>
      <div class="col-12 text-center my-2">
              <span type="button" tabindex="0" class="submit_btn px-5 py-2 mr-2" @click="showPopupvalid('Submit')">Submit &amp; Sign</span>
              <span type="button" tabindex="0" class="submit_btn px-5 py-2" @click="showPopupvalid('Save')">      <span v-if="loadingSaveBlock && !errorMsg"
                  ><img
                      src="../../assets/img/loading.webp"
                      width="20"
                      class="loading-img mr-2"
                      alt="Login buffering"
                  />
                  </span>Save</span>
          </div>
    </template>
   
      <div class="password-popup" v-if="showpopup">
        <div class="modal-width">
          <div class="modal-content">
            <div class="password-popup-header d-flex justify-content-between p-3">
              <h5 class="my-0 align-self-center">Sign Document</h5>
              <span
                type="button"
                class="align-self-center"
                @click="closePopup()"
                aria-hidden="true"
                ><i class="fa fa-times modal-close align-self-center"></i>
              </span>
            </div>
            <div class="modal-body modal-overflow">
              <div class="form-group mb-4">
                <h5>
                  !!!On signing, this is equivalent to the wet ink signature!!!
                </h5>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  required=""
                  v-model="emailId"
                />
              </div>
              <form>
                <div class="form-group position-relative mb-4">
                  <input
                    placeholder="Please enter your Digi-sign password"
                    class="form-control"
                    :type="inputType"
                    id="templatePassword"
                    name="password"
                    ref=""
                    v-model="templatePassword"
                  />
                  <i
                    class="password-visible-block cursor-pointer position-absolute"
                    :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
                    @click.prevent="passwordVisible()"
                  ></i>
                  <span v-if="validation" class="errmsg">{{ err }}</span>
                  <span v-if="errorMsg" class="errmsg">Please enter a password</span>
                </div>
                <!-- <div class="form-group mb-4">
                  <label class="mb-0" for="location">Location</label>
                  <select
                      class="form-control"
                      name="account"
                      v-model="location"
                      id="location"
                  >
                      <option value="" disabled selected>-Select Location-</option>
                      <option
                      v-for="location in locationList"
                      :key="location.id"
                      :value="location.location"
                      >
                      {{ location.location}}
                      </option>
                  </select>
                  <span v-if="error[0]" class="errmsg">Please enter a location</span> 
                  </div> -->
                  <div class="form-group mb-4">
                    <label class="mb-0" for="reasons">Reason</label>
                    <select
                        class="form-control"
                        name="account"
                        v-model="reasons"
                        id="reasons"
                        >
                        <option value="" disabled selected>-Select Reason-</option>
                        <option
                        v-for="reason in reasonList"
                        :key="reason.id"
                        :value="reason.reason"
                        >
                        {{reason.reason}}
                        </option>
                    </select>
                    <input class="form-control mt-2" v-if="reasons==='Others'" v-model="otherReason" type="text" maxlength="50" name="reason">
                    <div v-if="error[1]" class="errmsg">Please enter a reason</div>   
                    <div v-if="error[0]" class="errmsg">Please enter a reason</div>   
                </div>
                <div class="text-center">
                  <span
                    type="button"
                    tabindex="0"
                    class="submit_btn px-5 py-2"
                    :disabled="loadingBlock"
                    @click.prevent="submitTemplate()"
                  >
                    <span v-if="loadingBlock && !errorMsg"
                      ><img
                        src="../../assets/img/loading.webp"
                        width="20"
                        class="loading-img mr-2"
                        alt="Login buffering"
                      />
                    </span>
                    <span v-else>Submit</span></span
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script src="./editCodeReview.js"></script>
  <style scoped>
  .thead-grey {
      background-color: hsl(0, 8%, 95%);
  }
  
  .add-row-button {
      background-color: hsl(0, 8%, 95%);
      color: hsl(219, 60%, 61%);
  }
  
  .add-row-button:focus,
  .add-row-button:active {
      border: 1px solid black;
  }
  
  .exception-header {
      background-color: hsl(7, 84%, 68%);
      color: hsl(0, 0%, 95%);
  }
  .errmsg {
    font-size: 12px;
    color: red;
    float: left;
  }
  .password-popup {
      position: fixed;
      inset: 0;
      background: rgb(0 0 0 / 18%);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      z-index: 3000;
  }
  
  .password-popup-header {
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid #dee2e6;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
  }
  
  .password-visible-block {
      right: 11px;
      top: 10px
  }
  
  .errorMsg {
      color: hsl(0, 100%, 50%)
  }
  
  .border-red {
      border-color: hsl(0, 100%, 50%)
  }
  
  .modal-width {
      width: min(400px, 90%);
  }
  
  .table-side-block {
      text-align: center;
      border-radius: 5px;
      border: 1px solid #e7eaec;
      background-color: rgba(255, 255, 255, 1);
      width: min(150px, 90%);
      -moz-box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
      -webkit-box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
      box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
      z-index: 200;
  }
  
  .table-side-block>div {
      padding: 0.5rem;
  }
  
  .table-side-block>div:hover {
      background-color: #e4eaf9;
  }
  .loading-img {
    mix-blend-mode: lighten;
  }
  .submit_btn[disabled="true"]{
      opacity:0.5;
      cursor: not-allowed;
  }
  
  .add-row-block .tool-tip {
    visibility: hidden;
    width: max-content;
    background-color: #616161;
    color: #fff;
    text-align: center;
    border-radius: 0.3em;
    padding: 0.3rem 0.8rem;
    right: 0;
    top: -30px;
    font-size: 0.8rem;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    -webkit-transition: visibility 75ms ease-in;
    transition: visibility 75ms ease-in;
  }
  .add-row-block:hover .tool-tip,
  .add-row-block:focus-visible .tool-tip {
    visibility: visible;
    -webkit-transition: visibility 30ms ease-in;
    transition: visibility 30ms ease-in;
  }
  .add-row-block img{
      width:20px;
  }
  .height-40{
      height:34px
  }
  /* Disable password visibility icon in Microsoft Edge */
  input[type="password"]::-ms-reveal {
      display: none;
  }
  .input-skeleton:before {
      content:"";
      position: absolute;
      inset:0;
      z-index:1;
      background: linear-gradient(270deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
      background-size: 200% 100%;
      animation: loading 1.5s linear infinite;
  }
  
  
  @keyframes loading {
    0% {
      background-position: 200% 0; /* Start from the right */
    }
    100% {
      background-position: -200% 0; /* Move to the left */
    }
  }
  </style>