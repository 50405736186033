<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h3 class="my-0 py-0">User Project Role Map </h3>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-3 px-3 py-2">
      <div class="form-group row mb-1">
       <div class="col-12 col-md-3 my-1 pr-1">
          <div class="form-group mb-md-0 w-100 px-1 pl-md-0">
            <!-- <label class="mb-0">{{ $t("navigations.site code") }}</label> -->
            <label class="mb-0">Role</label>
              
            <select
              class="form-select my-md-2 my-md-0"
              name="role"
              placeholder="Role Name"
              v-model="searchData.roleId"
            >
              <option value="All">All</option>
              <option v-for="role in roles" :key="role.id" :value="role.id" >{{role.roleName}}</option>
              <!--<option>Archived</option>-->
            </select>
          </div>
          </div>
          <div class="col-12 col-md-3 my-1 pr-1">
          <div class="form-group mb-md-0 w-100 px-1">
            <label class="mb-0">Project</label>
              
            <select
              class="form-select my-md-2 my-md-0"
              name="project"
              placeholder="Project Name"
              v-model="searchData.projectId"
            >
              <option value="All">All</option>
               <option v-for="project in projects" :key="project.id" :value="project.id" >{{project.projectName}}</option>
              <!--<option>Archived</option>-->
            </select>
          </div>
          </div>
          <div class="col-12 col-md-3 my-1 pr-1">
          <div class="form-group mb-md-0 w-100 px-1">
             <label class="mb-0">Users</label>
              
            <select
              class="form-select my-md-2 my-md-0"
              name="user"
              placeholder="Users Name"
              v-model="searchData.userId"
            >
              <option value="All">All</option>
              <option v-for="user in users" :key="user.id" :value="user.id" >{{user.name}}</option>
           
            </select>
          </div>
          </div>
          <div class="col-12 col-md-3 align-self-end my-1">
            <span
            type="button"
            class="submit_btn px-5 w-100"
            @click="SearchButtonClicked()"
            >
            Search
            </span>
          </div>
        </div>
      </div>
       <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between table-bg mb-1">
        <h5 class="my-0 py-0 align-self-center">User Project Role List</h5>
        <router-link to="/allocateusers/0">
        <div
          class="position-relative align-self-center"
          aria-label="Allocate user"
          tabindex="0"
        >
          <img
            class="cursor-pointer"
            src="../../assets/img/create.png"
            alt="Allocate new User"
           
          />
          <div class="tool-tip">Allocate new User</div>
        </div></router-link>
      </div>
      <div class="ibox-content">
        <div class="table-responsive mt-4">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th class="sort_block" @click="SortSelected('username', 1)" id="1">
                   <div class="d-flex flex-row justify-content-between">
                 User
                 <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('project', 2)" id="2">
                   <div class="d-flex flex-row justify-content-between">
                 Project
                 <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('role', 3)" id="3">
                   <div class="d-flex flex-row justify-content-between">
                 Role
                 <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
         
                <th
                  class="text-center"
                
                >
                Edit
                </th>
         
                <th
                  class="text-center"
                 
                >
                 Delete
                </th>
         
              </tr>
            </thead>
            <tbody>
              <tr v-for="projectuser in this.projectUsersLists" :key="projectuser.id">
                <td>{{ projectuser.username }}</td>
                <td>{{ projectuser.project }}</td>
                <td>{{ projectuser.role }}</td>
                <td
                  class="text-center">
                  <img
                      class="cursor-pointer"
                      src="../../assets/img/Edit.png"
                      alt="Edit"
                      @click="editProjectUser(projectuser.id)"
                    />
                </td>
                <td
                  class="text-center">
                      <img
                        class="cursor-pointer"
                        src="../../assets/img/delete.png"
                        alt="Delete"
                        @click="deleteProjectUser(projectuser.id)"
                      />
                    
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!------ pagination -------->
        <div class="row mt-3 mx-0">
          <div class="col-md-12 mx-0 px-0">
            <div
              class="
                dataTables_paginate
                paging_simple_numbers
                pagination
                float-right
              "
            >
              <div class="col-md-12 d-flex justify-content-md-end px-0">
                <v-pagination
                  v-model="currentPage"
                  :pages="totalPages"
                  :range-size="1"
                  active-color="#ededed"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- pagin -->
      </div>
      <!-- Audit trail details modal starts -->
     
      <!--  popup -->
    </div>
    </div>
   
 
  
  <!--history modal starts -->

  <!--  popup -->
</template>
<script>
/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
//import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "projectusers",
  components: {
    VPagination,
  },
  data() {
    return {
      idtoken: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}`,
      //baseapi: process.env.VUE_APP_Service_URL,
      
      projectUsersLists: [],     
      totalPages: 1,
      pageIndex: 1,
      pageSize: 10,
      currentPage: 1,
      
      sortProperty: "createdAt",
      sortorder: 1,

      searchData: {
        roleId: "",
        projectId: "",
        userId: "",
        
      },
    
   
    };
  },
  async mounted() {
  this.idtoken = store.getters.getToken;
    await this.getRoles();
    await this.getProjects();
    await this.getUsers();

    await this.search();
    
    
  },

  methods: {
      async deleteProjectUser(id) {
      //const idtoken = store.getters.getIdToken;
      if (
        confirm("Are you sure you want to release this user from project?")
      ) {
        await axios
          .delete(`${this.baseUrl}configuration/userprojectrole/delete`,
          {
              data: {"userProjectRoleMap_Id":id},
               
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            
          })
          .then((res) => {
           // const idtoken = store.getters.getIdToken;
            console.log("Patient ID:" + id);
            console.log("response :" + res.data);
           alert( "User released from the project");
           this.search();
          });
      }
    },
        async editProjectUser(id) {
        console.log(id);
        this.$router.push(`/allocateusers/${id}`);
        },
        async getProjects(){
          await axios.get(`${this.baseUrl}configuration/project/getall`,
          {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            })
          //(`https://localhost:5001/project/getall`)
             .then(res =>{
                     console.log("Get projects ",res.data)
                    this.projects = res.data} )
            .catch(err => console.log(err));
        },
        async getRoles(){
          await axios.get(`${this.baseUrl}account-core/roles/getallroles`,
          {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            })
          //(`https://localhost:5001/roles/getallroles`)
             .then(res =>{
                     console.log("Get roles ",res.data)
                    this.roles = res.data} )
            .catch(err => console.log(err));
        },
        async getUsers(){
          await axios.get(`${this.baseUrl}account-core/user/getall`,
          {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            })
          //(`https://localhost:5001/user/getall`)
             .then(res =>{
                     console.log("Get users ",res.data)
                    this.users = res.data} )
            .catch(err => console.log(err));
        },
     SortSelected(sortProp, id) {
      console.log('SortPropChanged');
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      console.log('selDATA is',selectedElementID);
      if (sortProp != null && id == selectedElementID) {
        //this.sortDesc = !this.sortDesc
        if (this.sortorder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageIndex = 1;
      this.currentPage = 1;
      this.sortProperty = sortProp;
      this.sortorder = this.sortorder == 0 ? 1 : 0;
      this.search();
    },

    async SearchButtonClicked() {
      this.pageIndex = 1;
      this.currentPage = 1;
      this.search();
    },

    async search() {
      if (this.searchData.roleId == "All") {
        this.searchData.roleId = "";
      }
      if (this.searchData.projectId == "All") {
        this.searchData.projectId = "";
      }
      if (this.searchData.userId == "All") {
        this.searchData.userId = "";
      }
     
      await axios
        .get(
         // `${this.baseUrl}listpatient?SiteId=${this.siteId}&Studyid=${currentstudy}&SubjectId=${this.subjectid}&Status=${this.searchData.status}&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=${this.sortProperty}&SortOrder=${this.sortorder}&api-version=1.0`,
          `${this.baseUrl}configuration/userprojectrole/getpaginatedlist?UserId=${this.searchData.userId}&RoleId=${this.searchData.roleId}&ProjectId=${this.searchData.projectId}&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=${this.sortProperty}&SortOrder=${this.sortorder}&api-version=1.0`,
               {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            } )
        .then((res) => {
          this.totalPages = parseInt(res.data.totalCount / this.pageSize);
          this.projectUsersLists = res.data.entities;
          console.log("List data is",res.data);
        //  this.siteCode = this.patientLists.subjectId;
        
          console.log("Patient list is", this.projectUsersLists);
          if (res.data.totalCount % this.pageSize != 0) {
            this.totalPages = this.totalPages + 1;
          }
          console.log(res);
        });
    },
  

    async loadPage(page) {
      this.currentPage = page;
      this.pageIndex = page;
      this.search();
    },

    
   

  },
};
</script>
<style scoped>
@import "../../assets/css/style.css";
/* @import "../../assets/formpage.css"; */
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.form-control {
  padding: 0.2rem 0.75rem !important;
}
.form-select {
  border-radius: 0 !important;
  padding: 0.2rem 0.75rem !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
.position-relative img {
  width: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
/* .modal__container22 {
  width: 49%;
  max-width: 30%;
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
  overflow-x:hidden;
} */
.modal__container22 {
  width: 33%;
  max-width: 30%;
  position: fixed;
  height: 20% !important;
  margin-top: -550px !important;
  margin-left: 409px !important;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
}
.modal__container23 {
  width: 66%;
  max-width: 62%;
  position: fixed;
  height: -14% !important;
  margin-top: -683px !important;
  margin-left: 140px !important;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
}
.scrollable3 {
  overflow-y: scroll;
  height: 250px;
}
.audit__details__modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.audit__details__modal__container {
  width: min(90%, 365px);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}
.audit__details__modal__title,
.audit__history__modal__title {
  background-color: var(--pop-up-background);
  color: #ffffff;
}
.heightBig {
  overflow-y: scroll;
  height: 250px;
}
.audit__history__modal__contents {
  overflow-y: scroll;
  height: 404px;
}
.audit__history__modal__wrapper {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 4000;
}
.audit__history__modal__container {
  width: min(45%, 90%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}
.headingunderline {
  text-decoration-line: underline;
}
.submit_btn {
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.4rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
.table-bg {
  background-color: #e0e0e0;
}
</style>
