/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
export default {
  name: "ProjectPermission",
  components: {
    Multiselect,
  },
  props: {
    documentId: {
      type: String,
      default: "",
    },
    blockname: {
      type: String,
    },
    versionbyfolder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      addsigner: false,
      bookmarklist: [],
      signuserList: [],
      bokkmarkusersarray: [],
      bookmarkselectedusers: [],
      docmsg: true,
      errorBlock: false,
      lastversion: "",
      showModal: false,
      idtoken: "",
      userName: "",
      user: "",
      baseurl: `${process.env.VUE_APP_Service_URL}`,
      baseapi: `${process.env.VUE_APP_Service_URL}configuration/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      userList: [],
      categorylist: [],
      doctypelist: [],
      departmentlist: [],
      list: [],
      uploadedStatus: [],
      standards: [],
      validations: [false, false, false, false, false, false],
      azuredata: {
        DocumentId: "",
        DocumentVersionId: "",
        DocumentTypeName: "",
        DocumentTitle: "",
        DocumentDescription: "",
        Document: [],
        OrganizationId: 2,
        Approver: [],
      },
      docuplooadfields: {
        folderid: "",
        title: "",
        description: "",
        Owner: "",
        department: "",
        Category: "",
        Standard: "",
        documenttype: "",
        version: "",
        expiry: "",
        formData: "",
        changesHistory: "",
      },
    };
  },
  async mounted() {
    await this.getsignusers();
    document.body.style.overflowY = "hidden";
    this.idtoken = store.getters.getToken;
    this.lastversion = this.versionbyfolder;
    this.docversion = this.versionbyfolder;
    await this.gettypelist();
    await this.getstandard();
    await this.getcategory();
    await this.getusers();
    await this.getdepartment();
  },
  async unmounted() {
    document.body.style.overflowY = "auto";
  },
  methods: {
    async bookmarklistfun(event) {
      const idtoken = store.getters.getToken;
      console.log("bookmarklist  isssss", event.target.value);
      const selectedValue = event.target.value;
      await axios
        .get(
          `${this.mainapi}document/bookmark/getbookmarksbydoctype?id=${selectedValue}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("bookmarklist  isssss", res.data);
          this.bookmarklist = res.data;
          this.createbookmarkuserarray();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async createbookmarkuserarray() {
      this.bokkmarkusersarray = [];
      this.bookmarklist.forEach((eachbookmark) => {
        this.bookmarkselectedusers.push("");
        let samplebookmarkuser = {
          bookmark: eachbookmark.bookmarkName,
          nullable: true,
          signOrder: eachbookmark.sortOrder,
          nullable: true,
          userId: "",
          nullable: true,
          userEmail: "",
          nullable: true,
          date: null,
          signId: "",
          nullable: true,
          reason: "",
          nullable: true,
          userName: "",
          nullable: true,
        };
        this.bokkmarkusersarray.push(samplebookmarkuser);
      });
      console.log("bokkmarkusersarray", this.bokkmarkusersarray);
    },
    async assignbookmarkuserfun(event, index) {
      let eachbookuserval = [];
      eachbookuserval = event.target.value.split(",");
      console.log(event.target.value);
      this.bokkmarkusersarray[index].userId = eachbookuserval[0];
      this.bokkmarkusersarray[index].userEmail = eachbookuserval[1];
      this.bokkmarkusersarray[index].userName = eachbookuserval[2];
      console.log("bokkmarkusersarray", this.bokkmarkusersarray);
    },
    async getsignusers() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}account-core/user/getall`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.signuserList = res.data;
          console.log("userlist isssss", this.signuserList);
        })
        .catch((err) => {
          console.log(err);
          this.signuserList = [];
        });
    },
    async loadfile() {
      let fileload = document.getElementById("selectedFile");
      fileload.click();
    },
    async previewFiles(event) {
      this.azuredata.Document = [];
      this.azuredata.Document.push(event.target.files);
      this.list = [...this.list, ...this.azuredata.Document[0]];
      console.log(this.list);
    },
    async removefile(index) {
      this.list.splice(index, 1);
    },
    // Get apis
    async gettypelist() {
      await axios
        .get(
          `${this.baseurl}document/document/gettypelist?SortProperty=DocumentType&SortOrder=0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.doctypelist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.doctypelist = [];
        });
    },
    async getstandard() {
      await axios
        .get(
          `${this.baseapi}documentstandard/search?PageSize=1000&SortProperty=name&IsDescending=false`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.standards = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.standards = [];
        });
    },
    async getcategory() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.mainapi}configuration/documentcategory/search?PageSize=1000&SortProperty=category&IsDescending=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.categorylist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.categorylist = [];
        });
    },
    async getusers() {
      await axios
        .get(
          `${this.baseapi}userprojectrole/getprojectusers?projectId=` +
            store.getters.getSelectedProject,
          {
            //.get(`https://localhost:5001/userprojectrole/getprojectusers?projectId=`+store.getters.getSelectedProject,{
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )

        //   .get(`${this.baseurl}account-core/user/getall`, {
        .then((res) => {
          this.userList = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.userList = [];
        });
    },
    async getdepartment() {
      const orgId = store.getters.getOrganisationID;
      await axios
        .get(
          `${this.baseapi}department/search?orgid=${orgId}&PageSize=10000&SortProperty=name&IsDescending=false`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          res.data.entities.forEach((data) => {
            if (data.parentID == "" || data.parentID == null) {
              if (!this.departmentlist.includes(data)) {
                this.departmentlist.push(data);
              }
            }
          });
        })
        .catch((err) => {
          console.log(err.response.data.messages);
        });
    },
    // Validations
    async validatedocument() {
      this.validations = [false, false, false, false, false, false];
      if (this.docuplooadfields.title.trim() == "") {
        this.validations[0] = true;
      }
      if (this.docuplooadfields.Owner.trim() == "") {
        this.validations[1] = true;
      }
      // if (this.docuplooadfields.documenttype.trim() == "") {
      //   this.validations[2] = true;
      // }

      // if (this.docuplooadfields.version.trim() == "") {
      //   this.validations[3] = true;
      // }
      if (this.list.length == 0) {
        this.validations[4] = true;
      }
      if (new Date(this.docuplooadfields.expiry) < new Date()) {
        this.validations[5] = true;
      }
      let verval = this.docversion.toString();

      if (verval.trim() == "") {
        this.validations[3] = true;
      }
    },
    // Api Integration
    async submitFile() {
      let vervalue = this.docversion.toString();
      this.uploadedStatus = [];
      await this.validatedocument();
      if (!this.validations.includes(true)) {
        this.list.forEach(async (item) => {
          if (item.size == 0) {
            alert("Blank documents cannot be uploaded");
          } else {
            await axios
              .post(
                `${this.baseurl}document/document/upload`,
                {
                  DocumentDescription: this.docuplooadfields.description,
                  FolderId: this.documentId,
                  ExpiryDate: this.docuplooadfields.expiry.trim(),
                  Department: this.docuplooadfields.department.trim(),
                  Owner: this.docuplooadfields.Owner.trim(),
                  DocumentType: this.docuplooadfields.documenttype.trim(),
                  File: item,
                  Version: vervalue.trim(),
                  Catagory: this.docuplooadfields.Category.trim(),
                  DocumentTitle: this.docuplooadfields.title.trim(),
                  Standard: this.docuplooadfields.Standard,
                  VersionMessage: this.docuplooadfields.changesHistory,
                  Signers: this.bokkmarkusersarray,
                  IsSignConfig: this.addsigner,
                  CurrentVersion: this.lastversion,
                  StorageLocation:"Project"
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((res) => {
                if (res.status == 200) {
                  //this.uploadedStatus.push(true)
                  if (this.docmsg) {
                    alert("Documents saved successfully");
                    this.docmsg = false;
                    this.$emit("reloadDocs");
                    this.$emit("closemodal");
                  }
                }
              })
              .catch((err) => {
                console.log(err.data);
              });
          }
        });
        // if(!this.uploadedStatus.includes(false))
        // {
        //   alert("Document added successfully")
        //   this.$emit("reloadDocs")
        //   this.$emit("closemodal")
        // }
      }
    },
    close() {
      this.$emit("closemodal");
    },
  },
};
