<template>
  <div class="modal__upload__wrapper">
    <div class="modal-dialog modal-width">
      <div class="modal-content">
        <div class="modal-header-document d-flex justify-content-bertween p-3">
          <h5 class="my-0 align-self-center">Edit document</h5>
          <span
            type="button"
            class="align-self-center"
            data-dismiss="modal"
            @click="onclosed('uploadDoc')"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body modal-overflow">
          <div class="row">
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="document title">Document Title</label
              >
              <input
                type="text"
                id="document-title"
                v-model="docuplooadfields.title"
                class="form-control"
                maxlength="75"
              />
            </div>
            <div class="col-12 col-md-6 pl-md-1 mt-2">
              <label class="mb-0 mr-1" for="standard">Select Standard</label>

              <select
                id="standard"
                v-model="docuplooadfields.Standard"
                class="form-select form-select-lg"
                aria-label=".form-select-lg example"
              >
                <option
                  v-for="standard in standards"
                  :key="standard.id"
                  :value="standard.id"
                >
                  {{ standard.name }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="owner">Select Owner</label>
              <select
                id="owner"
                v-model="docuplooadfields.Owner"
                class="mr-3 form-select form-select-lg"
                aria-label=".form-select-lg example"
              >
                <option v-for="users in userList" :key="users.id" :value="users.name">
                  {{ users.name }}
                </option>
              </select>
              
            </div>
            <div class="col-12 col-md-6 pl-md-1 mt-2">
              <label class="mb-0 mr-1" for="category">Select Category</label>
              <select
                id="category"
                v-model="docuplooadfields.Category"
                class="form-select form-select-lg"
                aria-label=".form-select-lg example"
              >
                <option
                  v-for="category in categorylist"
                  :key="category.id"
                  :value="category.category"
                >
                  {{ category.category }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="department">Department</label>
              <input
                id="department"
                type="text"
                :value="docuplooadfields.Department"
                class="form-control mr-3"
                maxlength="75"
                readonly
              />
            </div>
            <div class="col-12 col-md-6 pl-md-1 mt-2">
              <label class="mb-0 mr-1" for="document type">Document Type</label>
              <!-- <span aria-label="mandatory field" class="error-block">*</span> -->
              <select
                @change="onDocumentTypeChange"
                id="document type"
                v-model="docuplooadfields.documenttype"
                class="form-select form-select-lg"
                aria-label=".form-select-lg example"
                :disabled="this.getdocbyId.haveSigners"
              >
                <option
                  v-for="doctype in doctypelist"
                  :key="doctype.id"
                  :value="doctype.id"
                >
                  {{ doctype.documentType }}
                </option>
              </select>
              <!-- <span class="d-block errorClass" v-if="validationmsgs[6] == true">
                Please select document type
              </span> -->
            </div>
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0" id="version">Version</label>
              <input
                type="text"
                for="version"
                v-model="docuplooadfields.version"
                class="form-control"
                maxlength="10"
                readonly
              />
            </div>
            <div class="col-12 col-md-6 pl-md-1 mt-2">
              <label class="mb-0" id="expiry-date">Expiry Date</label>
              <input
                for="expiry-date"
                type="text"
                v-model="docuplooadfields.expiarydate"
                class="form-control"
                onfocus="(type='date')"
                onblur="(type='text')"
                maxlength="30"
              />
              <span class="d-block errorClass" v-if="validationmsgs[1] == true">
                Please enter a future date
              </span>
            </div>
            <div class="col-12 mt-2">
              <label class="mb-0" id="description">Description</label>
              <textarea
                id="description"
                class="form-control"
                v-model="docuplooadfields.description"
                maxlength="250"
              ></textarea>
            </div>
            <div class="col-12 mt-2">
              <label class="mb-0" id="changesHistory">Changes history</label>
              <textarea
                id="changesHistory"
                class="form-control"
                v-model="docuplooadfields.changesHistory"
                maxlength="250"
              ></textarea>
            </div>
            <div v-if="showCheckbox" class="col-12 mt-2 form-check">
              <input
                class="form-check-input"
                v-model="addsigner"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label ml-4" for="flexCheckDefault">
                Do you need to add signer ?
              </label>
            </div>
            <div v-if="addsigner" class="col-12 mt-2">
              <table class="table table-striped table-bordered dataTables">
                <thead>
                  <tr>
                    <th class="sort_block" id="1">
                      <div class="d-flex flex-row justify-content-between">
                        Signing order
                      </div>
                    </th>
                    <th class="sort_block" id="1">
                      <div class="d-flex flex-row justify-content-between">
                        Bookmark/Role
                      </div>
                    </th>
                    <th class="sort_block" id="1">
                      <div class="d-flex flex-row justify-content-between">User</div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="alreadySigned">
                  <tr v-for="(bookmarks, index) in signuserList" :key="bookmarks">
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>
                      {{ bookmarks.bookmark }}
                    </td>
                    <td>
                      <select
                        id="user"
                        class="mr-3 form-select form-select-lg"
                        aria-label=".form-select-lg example"
                        :disabled="
                          (index != 0 &&
                            (signusersmodel[index - 1] == '' ||
                              signusersmodel[index - 1] == null)) ||
                          bookmarks.isSigned == true
                        "
                        v-model="signusersmodel[index]"
                      >
                        <option :value="null || ''" selected disabled>--Select a User--</option>
                        <option
                          v-for="users in userSignList"
                          :key="users.id"
                          :value="users.id"
                        >
                          {{ users.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="!alreadySigned">
                  <tr v-for="(bookmarks, index) in bookmarklist" :key="bookmarks">
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>
                      {{ bookmarklist[index].bookmarkName }}
                    </td>
                    <td>
                      <select
                        id="user"
                        class="mr-3 form-select form-select-lg"
                        aria-label=".form-select-lg example"
                        :disabled="
                          index != 0 && bokkmarkusersarray[index - 1].userId == ''
                        "
                        @change="assignbookmarkuserfun($event, index)"
                      >
                        <option :value="null ||''"  selected disabled>--Select a User--</option>
                        <option
                          v-for="users in userSignList"
                          :value="users.id + ',' + users.email + ',' + users.name"
                          :key="users.id"
                        >
                          {{ users.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 mt-2">
              <input
                accept=".xls,.xlsx,.jpg,.jpeg,.png,.pdf,.docx"
                id="InPutFile"
                type="file"
                class="form-control unvisible my-2 my-md-0"
                @change="previewFiles"
              />
              <div class="input-group breakWord">
                <div class="listfile">
                  <a v-if="removeIcon">
                    {{ this.azuredata.Document.name }}
                    <i class="fa fa-close" @click.prevent="removefile()"></i
                  ></a>
                </div>
              </div>
              <!-- <span class="errorClass" v-if="validationmsgs[3] == true">
              Please upload a file
            </span> -->
            </div>
          </div>
          <div class="d-flex justify-content-end mt-2">
            <span v-if="ApprovalStatus != 'Approved' || ApprovalStatus == ''">
              <button
                type="button"
                class="btn browser-btn btn-xs px-4 float-right mr-3"
                @click="loadfile()"
                v-if="!getdocbyId.haveSigners"
              >
                Browse
              </button>
            </span>
            <button
              type="button"
              class="btn btn-primary btn-xs px-4 float-right"
              @click="submitFile()"
              :class="{ 'btn-disabled': loadingBlock }"
              :disabled="loadingBlock"
            >
              <span v-if="loadingBlock"
                ><img
                  src="../../assets/img/loading.webp"
                  width="15"
                  class="loading-img"
                  alt="Login buffering"
              /></span>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import { onBeforeMount } from "@vue/runtime-core";
import jwt_decode from "jwt-decode";

export default {
  name: "DocEditPopup",
  props: {
    department: {
      type: String,
      default: "",
    },
    folderid: {
      type: String,
      default: "",
    },
    docId: {
      type: String,
      default: "",
    },
    versionid: {
      type: String,
      default: "",
    },
    source: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentuser:"",
      newUsers: false,
      userSignList: [],
      editSignList: [],
      userList: [],
      bookmarkselectedusers: [],
      bokkmarkusersarray: [],
      bookmarklist: [],
      showCheckbox: false,
      alreadySigned: false,
      addsigner: false,
      signusersmodel: [],
      signuserList: [
        {
          bookmark: "QA",
          signOrder: "1",
          userId: null,
          userEmail: null,
          userName: null,
          date: null,
          signId: null,
          reason: null,
          isSigned: false,
        },
      ],
      latestver: "",
      getlatestvermsg: "",
      docFolder: "",
      ApprovalStatus: "",
      currentVersion: "",
      removeIcon: false,
      getdocbyId: "",
      uploadedDocId: "",
      docmsg: true,
      standards: [],
      categorylist: [],
      validationmsgs: [false, false, false, false, false, false, false],
      idtoken: "",
      baseapi: `${process.env.VUE_APP_Service_URL}configuration/`,
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      uploadpopupmodal: true,
      validations: [],
      doclist: [],
      azuredata: {
        DocumentId: "",
        DocumentVersionId: "",
        DocumentTypeName: "",
        DocumentTitle: "",
        DocumentDescription: "",
        Document: "",
        OrganizationId: 2,
      },
      documentVersionId: [],
      totalPage: 1,
      currentPage: 1,
      totalCount: 0,
      pageindex: 1,
      pagesize: 10,
      Document: "",
      SortProperty: "CreatedAt",
      SortOrder: 1,
      doctypelist: [],
      docuplooadfields: {
        folderid: "",
        title: "",
        description: "",
        Owner: "",
        Department: "",
        Category: "",
        Standard: "",
        documenttype: "",
        version: "",
        expiarydate: "",
        formData: "",
        changesHistory: "",
      },
      loadingBlock: false,
    };
  },
  components: {
    Multiselect,
  },
  async beforeMount() {
    await this.getsignusers();
    await this.getusersListforSign();
    await this.getusers();
    await this.getcategory();
    await this.getstandard();
    await this.gettypelist();
  },
  async mounted() {
    document.body.style.overflowY = "hidden";
    await this.getdocdetails();
    this.existingDocType = this.getdocbyId.documentType;
    this.selectedDocType = this.getdocbyId.documentType;
  },
  async unmounted() {
    document.body.style.overflowY = "auto";
  },
  methods: {
    async setcurentuser(data) {
		const idtoken = store.getters.getToken;
		const userid = (jwt_decode(idtoken).user_id).trim();
		const userObject = data.find(item => item.userId === userid);
    console.log("current user isssss", userObject.name);
    this.currentuser = userObject.name;
    },
    async getusersListforSign() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}account-core/user/getall`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.userSignList = res.data;
          console.log("userlist isssss", this.userSignList);
        })
        .catch((err) => {
          console.log(err);
          this.userSignList = [];
        });
    },
    async assignbookmarkuserfun(event, index) {
      let eachbookuserval = [];
      eachbookuserval = event.target.value.split(",");
      console.log("eachbookuserval", eachbookuserval);
      this.bokkmarkusersarray[index].userId = eachbookuserval[0];
      this.bokkmarkusersarray[index].userEmail = eachbookuserval[1];
      this.bokkmarkusersarray[index].userName = eachbookuserval[2];
      console.log("bokkmarkusersarray", this.bokkmarkusersarray);
    },
    async bookmarklistfun(doctype) {
      console.log("DocType  isssss", doctype);
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}document/bookmark/getbookmarksbydoctype?id=${doctype}`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.length === 0) {
            this.addsigner = false;
            this.bookmarklist = [];
            this.signuserList = [];
            this.alreadySigned = false;
            this.showCheckbox = false;
          } else {
            this.alreadySigned = false;
            if (this.selectedDocType == this.existingDocType) {
              this.newUsers = false;
            } else {
              this.newUsers = true;
            }
            this.showCheckbox = true;
            console.log("bookmarklist  isssss", res.data);
            this.bookmarklist = res.data;
            this.createbookmarkuserarray();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async createbookmarkuserarray() {
      this.bokkmarkusersarray = [];
      this.bookmarklist.forEach((eachbookmark) => {
        this.bookmarkselectedusers.push("");
        let samplebookmarkuser = {
          bookmark: eachbookmark.bookmarkName,
          signOrder: eachbookmark.sortOrder.toString(),
          userId: "",
          userEmail: "",
          date: null,
          signId: "",
          reason: "",
          userName: "",
        };
        this.bokkmarkusersarray.push(samplebookmarkuser);
      });
      console.log("bokkmarkusersarray", this.bokkmarkusersarray);
    },
    async getdocdetails() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.mainapi}document/document/getdocumentitemdetails?documentId=${this.docId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Get doc details", res.data);
          this.getdocbyId = res.data;
          this.currentVersion = this.getdocbyId.currentVersion;
          this.getlatestvermsg =
            res.data.documentVersions[
              res.data.documentVersions.length - 1
            ].versionMessage;
          this.latestver =
            res.data.documentVersions[res.data.documentVersions.length - 1].version;
          this.approvedOrNot();
          this.setData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async setData() {
      this.getdocbyId.expiryDate != null
        ? (this.getdocbyId.expiryDate = this.getdocbyId.expiryDate.slice(0, 10))
        : this.getdocbyId.expiryDate;
      this.docFolder = this.getdocbyId.folderId;
      this.docuplooadfields.title = this.getdocbyId.documentTitle;
      this.docuplooadfields.description = this.getdocbyId.documentDescription;
      this.docuplooadfields.Owner = this.getdocbyId.owner;
      this.docuplooadfields.Department = this.getdocbyId.department;
      this.docuplooadfields.Category = this.getdocbyId.catagory;
      this.docuplooadfields.Standard = this.getdocbyId.standard;
      this.docuplooadfields.documenttype = this.getdocbyId.documentType;
      this.docuplooadfields.version = this.latestver;
      this.docuplooadfields.expiarydate = this.getdocbyId.expiryDate;
      this.docuplooadfields.changesHistory = this.getlatestvermsg;
      // this.getdocbyId.documentVersions[0].versionMessage;
      console.log("Owner is", this.docuplooadfields.Owner);
    },

    async replaceFile() {
      const idtoken = store.getters.getToken;
      if (this.azuredata.Document != "") {
        if (this.docuplooadfields.version.trim() == "") {
              this.docuplooadfields.version = "1";
             }
        await axios
          .post(
            `${this.mainapi}document/document/updatefile`,
            {
              File: this.azuredata.Document,
              DocumentId: this.docId,
              ExpiryDate: this.docuplooadfields.expiarydate,
              VersionMessage: this.docuplooadfields.changesHistory,
              Version: this.docuplooadfields.version.trim(),
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            console.log("replace response", response);
          })
          .catch((err) => {
            console.log(err.data);
          });
      }
    },
    async onDocumentTypeChange(event) {
      this.selectedDocType = event.target.value;
      console.log("Inside onDocumentTypeChange", event.target.value);
      if (this.selectedDocType == this.existingDocType) {
        console.log("this.selectedDocType == this.existingDocType");
        await this.getsignusers();
      } else {
        this.bookmarklistfun(this.selectedDocType);
      }
    },
    async loadfile() {
      let fileload = document.getElementById("InPutFile");
      fileload.click();
    },
    async getusers() {
      const idtoken = store.getters.getToken;
      if (this.source == "generalFiles") {
        await axios
          .post(
            `${this.mainapi}document/folderfilepermission/designationusers?departmentId=`,
            [],
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.userList = res.data;
            this.setcurentuser(res.data);
          })
          .catch((err) => {
            console.log(err);
            this.userList = [];
          });
      } else if (this.source == "department") {
        await axios
          .post(
            `${this.mainapi}document/folderfilepermission/designationusers?departmentId=` +
              store.getters.getMainDepartment,
            [],
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.userList = res.data;
            this.setcurentuser(res.data);
            console.log("USRLISTT", this.userList);
          })
          .catch((err) => {
            console.log("USRLISTT", err);
            this.userList = [];
          });
      } else {
        await axios
          .get(
            `${this.baseapi}userprojectrole/getprojectusers?projectId=` +
              store.getters.getSelectedProject,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.userList = res.data;
            this.setcurentuser(res.data);
          })
          .catch((err) => {
            console.log(err);
            this.userList = [];
          });
      }
    },

    async gettypelist() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}document/document/gettypelist`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.doctypelist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.doctypelist = [];
        });
    },
    async getstandard() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.mainapi}configuration/documentstandard/search?PageSize=1000&SortProperty=name&IsDescending=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.standards = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.standards = [];
        });
    },
    async getcategory() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.mainapi}configuration/documentcategory/search?PageSize=1000&SortProperty=category&IsDescending=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.categorylist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.categorylist = [];
        });
    },

    async getsignusers() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.mainapi}document/document/getsignerlist?docId=${this.docId}&versionnum=${this.versionid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.length !== 0) {
            (this.newUsers = false),
              (this.alreadySigned = true),
              (this.showCheckbox = true),
              (this.signuserList = res.data);
            console.log("userlist isssss", this.signuserList);
            this.signuserList.forEach((eachsignuser) => {
              this.signusersmodel.push(eachsignuser.userId);
            });
            if (this.signuserList.some((item) => item["userName"] != "")) {
              this.addsigner = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          (this.alreadySigned = false),
            (this.addsigner = false),
            (this.signuserList = []);
        });
    },

    async onclosed() {
      this.$emit("editdocactive");
    },

    async validatedocument() {
      this.validationmsgs = [false, false];

if (store.getters.getselectedFolder.trim() == "") {
  this.validationmsgs[0] = true;
}

if (this.docuplooadfields.expiarydate != null) {
  if (new Date(this.docuplooadfields.expiarydate) < new Date()) {
    this.validationmsgs[1] = true;
  }
}
    },
    async submitFile() {
      if (this.azuredata.Document != "") {
        if (confirm("Are you sure you want to replace the existing file?")) {
          await this.save();
        } else {
          console.log("Return");
          return;
        }
      } else {
        await this.save();
      }
    },

    async mapData() {
      if (this.newUsers) {
        this.editSignList = this.bokkmarkusersarray;
      } else {
        this.signusersmodel.forEach((signmodelusersid, index) => {
          let eachusersmodels = {};
          eachusersmodels = this.userSignList.find((u) => u.id === signmodelusersid);
          if (eachusersmodels != undefined) {
            this.signuserList[index].userEmail = this.addsigner!= true ? null : eachusersmodels.email;
            this.signuserList[index].userId = this.addsigner!= true ? null : eachusersmodels.id; 
            this.signuserList[index].userName = this.addsigner!= true ? null : eachusersmodels.name;
            this.signuserList[index].date = null;
          }
        });
        this.editSignList = this.signuserList;
      }
    },

    async save() {
      await this.mapData();
      const idtoken = store.getters.getToken;
      await this.validatedocument();
      if (!this.validationmsgs.includes(true)) {
        if (this.azuredata.Document != "") {
        if(this.docuplooadfields.title.trim() == ""){
              this.docuplooadfields.title = this.azuredata.Document.name;
            }
            if(this.docuplooadfields.Owner.trim() == ""){
              this.docuplooadfields.Owner = this.currentuser;
            }
            if (this.docuplooadfields.version.trim() == "") {
              this.docuplooadfields.version = "1";
             }
            }
        this.loadingBlock = true;
        await this.mapData();
        await axios
          .put(
            `${this.mainapi}document/document/edit`,
            {
              documentId: this.docId,
              documentDescription: this.docuplooadfields.description,
              folderId: this.docFolder,
              expiryDate: this.docuplooadfields.expiarydate,
              department: this.docuplooadfields.Department
                ? this.docuplooadfields.Department.trim()
                : this.docuplooadfields.Department,
              owner: this.docuplooadfields.Owner.trim(),
              documentType: this.docuplooadfields.documenttype,
              currentVersion: this.currentVersion,
              catagory: this.docuplooadfields.Category,
              documentTitle: this.docuplooadfields.title.trim(),
              standard: this.docuplooadfields.Standard,
              versionMessage: this.docuplooadfields.changesHistory,
              signers: this.editSignList,
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (this.ApprovalStatus == "NotApproved" || this.ApprovalStatus == "") {
              this.replaceFile();
            }
            if (this.docmsg) {
              this.loadingBlock = false;
              alert("Document updated successfully");

              this.docmsg = false;
            }
            this.onclosed();
          })
          .catch((err) => {
            this.loadingBlock = false;
            console.log(err.data);
          });
      }
    },
    async approvedOrNot() {
      const idtoken = store.getters.getToken;
      console.log("inside approved or not");
      await axios
        .get(
          `${this.mainapi}document/document/getdocumentapproveornot?documentId=${this.docId}&CurrentVersion=${this.currentVersion}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("approved or not status==", res.data);
          if (res.data == "NotApproved") {
            this.ApprovalStatus = "NotApproved";
          } else if (res.data == "Approved") {
            this.ApprovalStatus = "Approved";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async validateDocumentIsNull() {
      if (this.azuredata.Document != "") {
        this.validations[1] = true;
      } else {
        this.validations[1] = false;
      }
    },
    async previewFiles(event) {
      this.azuredata.Document = event.target.files[0];
      console.log("Preview", this.azuredata.Document);
      this.removeIcon = true;
    },
    async removefile() {
      this.azuredata.Document = "";
      this.removeIcon = false;
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.modal__upload__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.modal-overflow {
  overflow-x: hidden;
  height: 400px;
  overflow-y: auto;
}

.groupupload {
  display: flex;
}

.save_btn {
  background-color: #145faf !important;
  color: white;
}

.errorClass {
  color: red;
  font-size: 13px;
}
.unvisible {
  display: none;
}

.listfile {
  width: 90%;
}

.imp {
  color: #ff0000;
  font-size: 7px;
}
.error-block {
  color: #ff0000;
}

.modal-header-document {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.breakWord {
  word-break: break-word;
}
.form-select:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.loading-img {
  mix-blend-mode: lighten;
}
.btn-disabled {
  opacity: 0.5;
  cursor: disabled;
}
</style>
