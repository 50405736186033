<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0">Approve/Reject Test Result</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content">
      <div class="form-group row mb-3">
        <div class="col-12 col-md-5 my-1 pr-md-1">
          <div class="form-group mb-md-0 pr-md-1">
            <label class="mb-0">Name</label>
            <input
              type="text"
              class="form-control my-md-0"
              v-model="searchField.name"
            />
          </div>
        </div>
        <div class="col-12 col-md-5 my-1 pr-md-1">
          <div class="form-group mb-md-0">
            <label class="mb-0">Result</label>
            <select
              type="select"
              class="form-control my-md-0"
              v-model="searchField.result"
            >
              <option selected value="">Select</option>
              >
              <option value="Passed">Passed</option>
              <option value="Failed">Failed</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-2 align-self-end my-1">
          <span
            type="button"
            class="submit_btn px-5 py-2 w-100"
            @click="OnSearchButtonClicked"
            >Search</span
          >
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-bordered dataTables">
          <thead>
            <tr>
              <th
                class="sort_block"
                @click="SortSelected('userName', 1)"
                id="1"
              >
                <div class="d-flex flex-row justify-content-between">
                  Name
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>
              <th
                class="sort_block"
                @click="SortSelected('questionNumber', 2)"
                id="2"
              >
                <div class="d-flex flex-row justify-content-between">
                  Total Questions
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>
              <th
                class="sort_block"
                @click="SortSelected('configPercentage', 3)"
                id="3"
              >
                <div class="d-flex flex-row justify-content-between">
                  Pass Grade
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>
              <th class="sort_block" @click="SortSelected('mark', 4)" id="4">
                <div class="d-flex flex-row justify-content-between">
                  Score
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>
              <th class="sort_block" @click="SortSelected('result', 5)" id="5">
                <div class="d-flex flex-row justify-content-between">
                  Result
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>
              <th
                class="sort_block"
                @click="SortSelected('examDate', 6)"
                id="6"
              >
                <div class="d-flex flex-row justify-content-between">
                  Exam Date
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>
              <th class="text-center" v-if= "designationPrivileges.includes(`Test Approvals View Answer`)" > View Answer </th>
              <th class="text-center" v-if= "designationPrivileges.includes(`Test Approvals Approve Results`)" > Approve Result</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="mark in markList" :key="mark.id">
              <td>
                {{ mark.userName }}
              </td>
              <td>
                {{ mark.questionNumber }}
              </td>
              <td>
                {{ mark.configPercentage }}
              </td>
              <td>{{ mark.mark }}</td>
              <td>{{ mark.result }}</td>
              <td>{{ mark.examDate.slice(0, 10) }}</td>
              <!-- createdAt -->
              <td class="text-center" v-if= "designationPrivileges.includes(`Test Approvals View Answer`)">
                <a
                  @click.prevent="
                    view(mark.userExamId, mark.docId, mark.approvedStatus)
                  "
                  ><i class="fa fa-eye edit-delete"></i>
                </a>
              </td>
              <td class="text-center" v-if= "designationPrivileges.includes(`Test Approvals Approve Results`)">
                <a
                  href="#"
                  class="mb-1"
                  data-toggle="modal"
                  data-target="#assignUser1"
                  ><img
                    v-if="
                      mark.approvedStatus == 'Pending' ||
                      mark.approvedStatus == null
                    "
                    src="../../assets/img/approve_test.png"
                    alt="Delete"
                    @click="approve(mark.userExamId)"
                  />
                </a>
                <span
                  v-if="
                    mark.approvedStatus == 'Approved' ||
                    mark.approvedStatus == 'Rejected'
                  "
                >
                  {{ mark.approvedStatus }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end px-0">
        <v-pagination
          v-model="currentPage"
          :pages="totalPage"
          :range-size="1"
          active-color="#ededed"
          @update:modelValue="loadPage"
        />
      </div>
    </div>
  </div>
  <div v-if="showApprove" class="modal__wrapperexam">
    <div class="modal__approver__container">
      <div
        class="modal-header-projects d-flex justify-content-between py-2 px-3"
      >
        <h4 class="my-0">Approve Result</h4>
        <div class="cursor-pointer" @click.prevent="ClosefolderModal()">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="modal-body">
        <div class="chat-form border-bottom">
          <form role="form">
            <div class="form-group">
              <textarea
                class="form-control"
                placeholder="Message"
                v-model="comments"
              ></textarea>
            </div>
            <div class="text-right mb-2">
              <button
                type="button"
                class="btn btn-primary btn-xs px-4 mr-3"
                @click="ApprovalStatus('Approved')"
              >
                Approve
              </button>
              <button
                type="button"
                class="btn btn-danger btn-xs px-4 float-right"
                @click="ApprovalStatus('Rejected')"
              >
                Reject
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showReport" class="modal__wrapperexam">
    <div class="modal__approver__container">
      <div
        class="modal-header-projects d-flex justify-content-between py-2 px-3"
      >
        <h4 class="my-0">Download Document</h4>
        <div class="cursor-pointer" @click.prevent="Closedownload()">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="modal-body">
        <div class="chat-form border-bottom">
          <form role="form">
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                placeholder="Digi sign password"
                v-model="password"
              />
            </div>
            <div class="text-right mb-2">
              <button
                type="button"
                class="btn btn-primary btn-xs px-4 mr-3"
                @click="DownloadReport2()"
              >
                Download
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";

export default {
  data() {
    return {
      currentuserexamID: "",
      baseurl: `${process.env.VUE_APP_Service_URL}document/documentquestion/`,
      userUrl: `${process.env.VUE_APP_Service_URL}account-core/user/`,
      departmentUrl: `${process.env.VUE_APP_Service_URL}configuration/department/`,
      idtoken: "",
      showDesig: false,
      markList: "",
      showApprove: false,
      showAddApprove: false,
      showReport: false,
      examId: "",
      comments: "",
      userList: [],
      searchField: {
        name: "",
        result: "",
      },
      currentPage: 1,
      pageindex: 1,
      pagesize: 10,
      totalPage: 1,
      SortProperty: "examDate",
      SortOrder: false,
      SortOrderInt: 1,
      search: {
        createdby: "",
        status: "",
        name: "",
      },
      designationPrivileges :"",
    };
  },

  async mounted() {
    this.designationPrivileges = store.getters.getRolesprivilegeData;
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    this.idtoken = store.getters.getToken;
    console.log(this.idtoken);
    this.ListUsers();
  },

  methods: {
    async OnSearchButtonClicked() {
      this.pageindex = 1;
      this.currentPage = 1;
      this.ListUsers();
    },
    async view(userxamid, docid, approvlstatus) {
      store.dispatch("setprvspath","/approvertestresult"),
      store.dispatch("setTestApprovalstatus", approvlstatus);
      this.$router.push("/myQuestion/" + docid + "/" + userxamid);
    },
    approve(userexamId) {
      console.log("Usrxmid", userexamId);
      this.currentuserexamID = userexamId;
      this.showApprove = true;
      console.log("hiii", this.showApprove);
    },
    CloseApproverModal() {
      this.showAddApprove = false;
    },
    AssignedUser() {
      this.showAddApprove = true;
    },
    async DownloadReport2() {
      try {
        const response = await fetch(
          `https://dmsdev.datamatica.uk/v1/document/documentquestion/resultpdf?examId=${this.examId}&password=${this.password}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
            },
          }
        );

        
        var x = response.headers.get("Content-Disposition");
        var xx = x.split("filename=")[1];
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        let filename = xx.split(";")[0];
        const name = filename.replace(/"/g, '');
        link.setAttribute("download",name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch {
        alert("Please enter correct credentials.");
      }
      this.showReport = false;
    },

    async ApprovalStatus(value) {
      if (value == "Approved") {
        if (confirm("Are you sure you want to approve the test result?")) {
          await this.approveOrReject(value);
        }
      } else {
        if (confirm("Are you sure you want to reject the test result?")) {
          await this.approveOrReject(value);
        }
      }
    },
    ClosefolderModal() {
      this.showApprove = false;
    },
    async ListApproverUsers() {
      await axios
        .get(`${this.userUrl}getlist`, this.Head)
        .then((res) => {
          this.userList = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.userList = [];
          alert("No data found!");
        });
    },
    Closedownload() {
      this.showReport = false;
    },
    async approveOrReject(approvalStatus) {
      await axios
        .put(
          `${this.baseurl}approverejecttestresult`,
          {
            documentId: this.currentuserexamID,
            approvalStatus: approvalStatus,
            comments: this.comments,
          },
          this.Head
        )
        .then((res) => {
          this.disignations = res.data.entities;
          this.showApprove = false;
          this.comments = "";
          this.ListUsers();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async GoToManageUser(id) {
      this.$router.push("./manageuser/" + id);
    },
    async ListUsers() {
      await axios
        .get(
          `${this.baseurl}getapprovertestresultlist?name=${this.searchField.name}&status=${this.searchField.result}&PageIndex=${this.pageindex}&PageSize=${this.pagesize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrderInt}`,
          this.Head
        )
        .then((res) => {
          this.markList = res.data.entities;
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.markList = [];
          alert("No data found!");
        });
    },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged", sortProp, id);
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == true ? false : true;
      this.SortOrderInt = this.SortOrder == true ? 0 : 1;
      this.ListUsers();
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.pageindex = page;
      this.ListUsers();
    },
  },
};
</script>

<style scoped>
@import "./modal.css";
.submit_btn {
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
.modal-header-projects {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal__wrapperexam {
  position: fixed;
  background: rgb(0 0 0 / 18%);
  height: 100%;
  width: 100%;
  inset: 0;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: cubic-bezier;
  overflow: hidden;
}
.modal__approver__container {
  width: min(38%, 80%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-x: hidden;
}
.pagination {
  margin: 0;
}
.Page-active {
  padding: 1rem;
  border: 1px solid #ececec;
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.cursor-pointer {
  cursor: pointer;
}

</style>