/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import store from "../../store/index";
import PermissionPopUp from "../permission_popup/permission_popup.vue"
export default {
  name: "SidePopUp",
  components: {
    PermissionPopUp
  },
  props: {
    tab: {
      type: String,
      default: ""
    },
    blockid: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    blocktopPosition: {
      type: Number,
      default: 0
    },
    blockleftPosition: {
      type: Number,
      default: 0
    },
    dest: {
      type: String,
      default: ""
    },
    ev: {
      type: Event
    },
    index:{
      type: Number,
      default:0
    }
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log("DEst is",this.dest)
    let div=""
    let popUp=document.getElementById("side-block")
    if(this.dest == 'projectlist' ||this.dest == 'users' || this.dest=='projectRole')
    {
      popUp.style.position="absolute"
      if(this.dest == 'projectlist' || this.dest == 'users' || this.dest=='roles')
      {
        popUp.style.display="block"
        popUp.style.right="0"
      }  
 
     
    }
    else if (this.dest != 'docs'  && this.dest != 'file'  && this.dest != 'projectlist') 
    {
      div= document.getElementById("primaryTree")
      const rect = div.getBoundingClientRect();
      const scrollTop = div.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = div.pageXOffset || document.documentElement.scrollLeft;
      const x = this.ev.clientX - rect.left - scrollLeft;
      const y = this.ev.clientY - rect.top - scrollTop;    
      if (this.dest != 'docs' && this.dest != 'file' && this.dest != 'project') 
      {
        popUp.style.display="block"
        popUp.style.right=parseFloat(x-30)+"px"
        popUp.style.top=y+"px"
      }
      
      else{     
        if(this.index>=6){
          popUp.style.display="block"
          popUp.style.bottom="0px"
          popUp.style.right="50px"
        }
        else{
          popUp.style.display="block"
          popUp.style.right="53px"
        }
       
      } 
    }
    else{
      div= document.getElementById("primaryDocumentTree")
      const rect = div.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      const x = this.ev.clientX - rect.left - scrollLeft;
      const y = this.ev.clientY - rect.top - scrollTop;
      if (this.dest == 'project'){
        popUp.style.display="block"
        popUp.style.right=x+"px"
      }
      else if (this.dest != 'docs' && this.dest != 'file' && this.dest != 'project') 
      {
        popUp.style.display="block"
        popUp.style.right=x+"px"
        popUp.style.top=y+"px"
      }
    } 
  },
  updated(){
    let div=""
    let popUp=document.getElementById("side-block")
      div= document.getElementById("primaryTree")
      let docDiv= document.getElementById("primaryDocumentTree")
      const rect = div.getBoundingClientRect();
      const scrollTop = div.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = div.pageXOffset || document.documentElement.scrollLeft;
      const x = this.ev.clientX - rect.left - scrollLeft;
      const y = this.ev.clientY - rect.top - scrollTop;
      if (this.dest == 'project'){
        if(popUp.querySelectorAll("div").length>3)
        {
          popUp.style.display="block"
          popUp.style.top=y+"px"        
        }
        popUp.style.right=parseFloat(x-30)+"px"         
      }
      if(this.dest == 'docs')
      {
        if(popUp.children.length==1)
        {
          docDiv.style.position="relative"
          popUp.style.position="absolute"  
          
          if(this.tab=='generalFiles' || this.tab=='department')
          {          
            popUp.style.right="70px"
            popUp.style.display="block"
          }
           else{
            popUp.style.right="-146px"
            popUp.style.display="block"
           }          
        }
        else{
          if(this.tab=='projectDoc')
          {
            docDiv.style.position="relative"
            popUp.style.position="absolute"  
            popUp.style.display="block"
            if( docDiv.scrollWidth > docDiv.clientWidth) {
              popUp.style.right="-10px"
            }
            else popUp.style.right="70px"
          }
          else{ popUp.style.display="block"
            popUp.style.top=y+"px"       
            popUp.style.right="80px"
          }
        }
      }
  },

  methods: {


  }
}