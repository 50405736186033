import axios from "axios";
import store from "../../store/index";
import { ref } from "vue";
import { useRouter } from "vue-router";
import ImgUserAuth from "../ImgUserAuth/ImgUserAuth.vue"
import { encryptUser } from "../../common/encryption"
// var webAuth = new auth0.WebAuth({
//   domain: process.env.VUE_APP_Domain,
//   clientID: process.env.VUE_APP_Client_ID,
//   scope: "openid profile email offline_access",
//   responseType: "token id_token",
//   useRefreshTokens: true,
// });

export default {
  setup() {
    const inputType = ref("password")
    const visibleOn = ref(false);
    let header = ref({});
    let profile = ref({});
    let validations = ref([
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
    let router = useRouter();
    let errors = ref([]);
    let currentPassword = ref("");
    let confirmPassword = ref("");
    let newPassword = ref("");
    let newvisibleOn = ref("")
    const newinputType = ref("password")
    const currentinputType = ref("password")

    let currentvisibleOn = ref("")
    let errr = ref("")
    let baseUrl = ref(`${process.env.VUE_APP_Service_URL}account-core/user/`)
    let authResult = ref("")
    async function setprofile(profiledta, header) {
      header.value = header;
      profile.value = profiledta;
      console.log(profiledta);
    }
    async function passwordVisible(type) {
      if (type === 'new') {
        newvisibleOn.value = !newvisibleOn.value
        if (newvisibleOn.value == true) {
          newinputType.value = "text"
        }
        else newinputType.value = "password"
      }
      if (type === 'current') {
        currentvisibleOn.value = !currentvisibleOn.value
        if (currentvisibleOn.value == true) {
          currentinputType.value = "text"
        }
        else currentinputType.value = "password"
      }
      if (type === 'confirm') {
        visibleOn.value = !visibleOn.value
        if (visibleOn.value == true) {
          inputType.value = "text"
        }
        else inputType.value = "password"
      }
    }
    async function executepassword(authResult) {
      if (profile.value.dateOfBirth === "") {
        profile.value.dateOfBirth = null;
      }
      const idtoken = authResult.idToken;
      const encryptedPassword = await encryptUser(newPassword.value.trim());
      await axios
        .put(
          `${process.env.VUE_APP_Service_URL}account-core/user/` + "updateuserpassword",
          {
            password: encryptedPassword,
            email: profile.value.primaryEmail,
            userId: profile.value.id,
          },

          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          alert("Password updated successfully");
          router.go();
        })
        .catch((err) => {
          console.log(err);
          alert(
            "This password has previously been used, Please use a new password"
          );
        });
    }
    async function clear() {
      newPassword = "";
      confirmPassword = "";
      currentPassword = "";
    }

    async function login(useremail, currentpass, newpass, confirmedpass) {
      newPassword.value = newpass;
      confirmPassword.value = confirmedpass;
      currentPassword.value = currentpass;
      const encryptedCurrentpass = await encryptUser(currentpass);
      // return webAuth.client.login(
      //   {
      //     realm: "Username-Password-Authentication",
      //     username: useremail,
      //     password: encryptedCurrentpass,
      //   },
      //   async function (err, authResult) {
      //     if (err) {
      //       console.log(err.description);
      //       if (
      //         err.description ==
      //         "Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it."
      //       ) {
      //          alert(err.description);
      //       } else if (err.description == "Wrong email or password.") {
      //          alert("Invalid current password");
      //       }
      //     } else {
      //       console.log("correct password", authResult);
      //       await ChangePasswordClick(authResult);
      //     }
      //   }
      // );

      await axios
        .post(
          `${baseUrl.value}userauthorize`, {
          "userEmail": useremail,
          "password": encryptedCurrentpass
        },
        )
        .then((res) => {
          authResult.value = res.data
          errr.value = ""
          ChangePasswordClick(authResult.value);
        })
        .catch((err) => {
          if (err && err.response.data) {
            if (err.response.data == "Invalid email or password.") {
              errr.value = "Invalid current password"
              
            }
          }
        })

    }
    async function ChangePasswordClick(authResult) {
      await validateallpassword();
      if (validations.value.includes(true)) {
        errors.value = validations.value;
      } else {
        executepassword(authResult);
      }
    }
    async function validateallpassword() {
      Validatepassword(newPassword.value.trim());
      Validateconfirmpassword(confirmPassword.value.trim());
      Validatematchpasswords(newPassword.value.trim(), confirmPassword.value.trim());
    }
    async function Validatepassword(passwd) {
      let reNum = /[0-9]/;
      let reLow = /[a-z]/;
      let reUpp = /[A-Z]/;
      let spcl = /(?=.*[!@#$%^&*])/;
      if (passwd == "" || passwd == "" || passwd == null) {
        validations.value[6] = true;
        validations.value[8] = true;
      } else if (
        passwd.length < 8 ||
        !reLow.test(passwd) ||
        !reNum.test(passwd) ||
        !spcl.test(passwd) ||
        !reUpp.test(passwd)
      ) {
        validations.value[8] = true;
        validations.value[6] = false;
      } else {
        validations.value[6] = false;
        validations.value[8] = false;
      }
    }
    async function Validateconfirmpassword(passwd) {
      let reNum = /[0-9]/;
      let reLow = /[a-z]/;
      let reUpp = /[A-Z]/;
      let spcl = /(?=.*[!@#$%^&*])/;
      if (passwd == "" || passwd == "" || passwd == null) {
        validations.value[7] = true;
        validations.value[9] = true;
      } else if (
        passwd.length < 8 ||
        !reLow.test(passwd) ||
        !reNum.test(passwd) ||
        !spcl.test(passwd) ||
        !reUpp.test(passwd)
      ) {
        validations.value[9] = true;
        validations.value[7] = false;
      } else {
        validations.value[7] = false;
        validations.value[9] = false;
      }
    }
    async function Validatematchpasswords(passwd, confirmpaswd) {
      if (
        validations.value[6] == false &&
        validations.value[8] == false &&
        validations.value[7] == false &&
        validations.value[9] == false
      ) {
        console.log("validate paswds");
        if (passwd == confirmpaswd) {
          validations.value[10] = false;
          console.log(" paswds ok");
        } else {
          validations.value[10] = true;
          console.log("paswds mismatch");
        }
      } else {
        console.log("final condtn paswds");
        validations.value[10] = false;
      }
    }

    return {
      passwordVisible,
      baseUrl,
      login,
      ChangePasswordClick,
      validateallpassword,
      validations,
      errr,
      Validatematchpasswords,
      Validateconfirmpassword,
      errors,
      currentPassword,
      confirmPassword,
      newPassword,
      newinputType,
      currentinputType,
      setprofile,
      newvisibleOn,
      currentvisibleOn,
      profile,
      header,
      executepassword,
      clear,
      visibleOn,
      inputType,
    };
  },
  name: "profile_settings",
  props: {},
  components: {
    ImgUserAuth,
  },
  data() {
    return {
      loadingBlock: false,
      loginpass: "",
      loginerrmsg: "",
      loginerr: false,
      visiblityOn: false,
      currentsignvisiblityOn: false,
      newsignvisiblityOn: false,
      loginvisiblityOn: false,
      confirminputiType: "password",
      inputingType: "password",
      currentsignPassword: "password",
      newsignPassword: "password",
      loginPassword: "password",
      baseApi: `${process.env.VUE_APP_Service_URL}`,
      baseUrl: `${process.env.VUE_APP_Service_URL}account-core/user/`,
      profileDetails: {
        id: "",
        firstName: "",
        userName: "",
        lastName: "",
        primaryEmail: "",
        secondaryemail: "",
        userType: "",
        designation: "",
        projectName: "",
        roleName: "",
        assignedProjects: [
          {
            projectName: "",
            roleName: "",
          },
        ],
        designationId: "",
        country: "",
        dateOfBirth: "",
        phoneNumber: "",
        addressLineOne: "",
        addressLineTwo: "",
      },
      idtoken: "",
      Head: {},
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      currentvalidation: [false],
      errr: [],
      typeField: "text",

      signStatus: "",
      signPassword: "",
      confirmSignPassword: "",
      oldPassword: "",
      errorMessages: [false, false, false],
      defaultSign: "",
      initial: "",
      initialcreateddate: '2023-03-31T04:29:43.304Z',
      initialmodifieddate: '2023-03-31T04:29:43.304Z',
      isForgot: false,
      isUpdate: false,
      IsIntialEdit: false,
      newIntial: "",
      intialError: false,
      uploadedImage: "",
      signModal: false,
      imgValidation: false,
      showsave: false,
      document: [],
      emailId: "",
      password: "",
      err: "",
      validation: false,
      file: "",
      buttonAction: "",
      sourceSample: "",
      showSignImg: false,
      signUpload: false,
      isImgSignEdit: false,
      signErrors: [false, false, false],
      errors: [],
      showPopup: false,
    };
  },
  computed: {
    createdDateObj() {
      return new Date(this.initialcreateddate);
    },
    lastModifiedDateObj() {
      return new Date(this.initialmodifieddate);
    },
    createdDate() {
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      return this.createdDateObj.toLocaleDateString('en-GB', options).toUpperCase().replace(/\s/g, '');
    },
    lastModifiedDate() {
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      return this.lastModifiedDateObj.toLocaleDateString('en-GB', options).toUpperCase().replace(/\s/g, '');
    },
    createdTime() {
      const options = { hour: 'numeric', minute: 'numeric', hour12: true, second: 'numeric' };
      return this.createdDateObj.toLocaleTimeString('en-US', options).replace(/:\d{2}\s/, ' ');
      // return this.createdDateObj.toLocaleTimeString();
    },
    lastModifiedTime() {
      const options = { hour: 'numeric', minute: 'numeric', hour12: true, second: 'numeric' };
      return this.lastModifiedDateObj.toLocaleTimeString('en-US', options).replace(/:\d{2}\s/, ' ');
    }
  },

  async mounted() {
    this.IsUpdate = false;
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    this.GetAndSetData();
    await this.getsignstatus();
    await this.getDefaultSign();
    await this.getInitials();
    await this.IsSignImageUploaded();
    this.idtoken = store.getters.getToken;
    // let element=document.getElementsByClassName("confirm-password")
    // element.removeAttribute('autocomplete');
  },
  methods: {
    passwordSignVisibility(type) {
      if (type === 'new') {
        this.newsignvisiblityOn = !this.newsignvisiblityOn
        if (this.newsignvisiblityOn == true) {
          this.newsignPassword = "text"
        }
        else this.newsignPassword = "password"
      }
      if (type === 'confirm') {
        this.visiblityOn = !this.visiblityOn
        if (this.visiblityOn == true) {
          this.inputingType = "text"
        }
        else this.inputingType = "password"
      }
      if (type === 'current') {
        this.currentsignvisiblityOn = !this.currentsignvisiblityOn
        if (this.currentsignvisiblityOn == true) {
          this.currentsignPassword = "text"
        }
        else this.currentsignPassword = "password"
      }
      if (type === 'login') {
        this.loginvisiblityOn = !this.loginvisiblityOn
        if (this.loginvisiblityOn == true) {
          this.loginPassword = "text"
        }
        else this.loginPassword = "password"
      }

    },
    handleType(event) {
      if (event.type === 'focus') {
        this.typeField = 'text'
      } else {
        this.typeField = 'password'
      }
    },

    async openInfo() {
      document.body.style.overflowY = "hidden"
      this.showPopup = !this.showPopup;
    },
    async closemodal() {
      document.body.style.overflowY = "auto"
      this.showPopup = false;
    },
    async GetAndSetData() {
      console.log(this.Head, "token");
      console.log("ID TOKEN", this.Head);
      await axios
        // .get(
        //   `https://localhost:5001/user/getprofile`,
        //   this.Head
        // )
        .get(this.baseUrl + "getprofile", this.Head)
        .then((res) => {
          this.profileDetails = res.data;
          console.log(this.profileDetails.id, "USER ID");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkpasswords() {
      if (this.currentPassword == "" || this.currentPassword == null) {
        console.log("No password");
        this.currentvalidation[0] = true;
        if (this.currentvalidation.includes(true)) {
          //this.errr = this.currentvalidation;
        }
      } else {
        this.currentvalidation[0] = false;
        this.setprofile(this.profileDetails, this.Head);
        this.login(
          this.profileDetails.primaryEmail,
          this.currentPassword,
          this.newPassword,
          this.confirmPassword
        );
      }
    },

    // --- Digital signature setting ---

    async getsignstatus() {
      await axios.get(this.baseUrl + "getsignstatus", this.Head)
        .then((res) => {
          this.signStatus = res.data;
          console.log("sign status is : ", res.data)
        })
        .catch(err =>
          console.log("Error in getting sign status ", err));

    },
    async IsSignImageUploaded() {
      await axios.get(`${this.baseApi}document/signature/isimagesignuploaded?email=${this.profileDetails.primaryEmail}`, this.Head)
        .then((res) => {
          console.log("Is image sign uploaded : ", res.data);
          if (res.data == `Sign image uploaded`) {
            this.signUpload = true;
          }
          else {
            this.signUpload = false;
          }
        })
        .catch(err =>
          console.log("Error in IsSingUploaded", err)
        );
    },
    async createSign() {
      await this.validatePasswordField();
      console.log("signErrors.......", this.signErrors)
      if (!this.signErrors.includes(true) && confirm("Are you sure want to save this password for your Digital Signature?")) {
        const encryptedPassword = await encryptUser(this.signPassword.trim())
        await axios.get(this.baseUrl + "createsign?password=" + encryptedPassword, this.Head)
          .then((res) => {
            alert("Digi-sign password created successfully")
            console.log("Digitalsign created succesfully", res)
            this.getsignstatus();
            this.getDefaultSign();
            this.getInitials();
          })
          .catch(err => console.log("Error in createSign", err)
          );
      }
    },
    async UpdateSign() {
      if (this.oldPassword == null || this.oldPassword.trim() == "") {
        this.errors[0] = false;
        return;
      } else {
        this.errors[0] = true;
        await this.validatePasswordField();
        const encryptedPassword = await encryptUser(this.signPassword.trim());
        const encryptNewPassword = await encryptUser(this.oldPassword.trim());
        if (!this.signErrors.includes(true) && confirm("Are you sure you want to update password?")) {
          await axios.get(`${this.baseUrl}updatesign?newPassword=${encryptedPassword}&oldPassword=${encryptNewPassword}`, this.Head)
            .then((res) => {
              alert("Digi-sign password updated successfully")
              console.log("password updated succesfully", res)
              this.cancelUpdatesign();
            })
            .catch(err => {
              if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.DomainValidations && err.response.data.errors.DomainValidations[0]) {
                alert(err.response.data.errors.DomainValidations[0])
              }
              else {
                console.log("REACHED ELSE")
                alert("Current password is incorrect");

              }
            }
            );
        }

      }
    },
    async isForgotClicked() {
      this.isForgot = true;
    },
    async isUpdateClicked() {
      this.errors[0] = true;
      this.errors[1] = true;
      this.isUpdate = true;
      this.oldPassword = "";
      this.signPassword = "";
      this.confirmSignPassword = "";
    },
    async cancelForgotsign() {
      this.isForgot = false;
      this.loginerr = false
      this.loginerrmsg = ""
      this.loginpass = ""
    },
    async ForgotSign() {
      if (this.loginpass == "" || this.loginpass == "" || this.loginpass == null) {
        this.loginerr = true
        this.loginerrmsg = "Password is required"
      } else {
        this.loadingBlock = true;
        this.loginerr = false
        this.loginerrmsg = ""
        const encryptedCurrentpass = await encryptUser(this.loginpass);
        await axios
          .post(
            `${this.baseUrl}checkreset`, {
            "userEmail": this.profileDetails.primaryEmail,
            "password": encryptedCurrentpass
          }, this.Head
          )
          .then((res) => {
            alert("An email has been sent to you with a password reset link")
            this.loadingBlock = false;
            this.isForgot = false;
            this.loginpass = ""
          })
          .catch((err) => {
            this.loadingBlock = false;
            console.log(err)
            if (err && err.response.data) {
              if (err.response.data == "Invalid email or password.") {
                this.loginerr = true
                this.loginerrmsg = "Invalid password"
                this.loginpass = ""
              }
            }
          })
      }
    },

    async cancelUpdatesign() {
      this.loadingBlock = false;
      this.signErrors = [false, false, false]
      // this.errorMessages = "";
      this.isUpdate = false;
      this.oldPassword = "";
      this.signPassword = "";
      this.confirmSignPassword = "";
      this.errorMessages = ""
      this.signErrors = [false, false, false]
    },
    async setDefaultSign(defaultSign) {
      await axios.put(`${this.baseUrl}setdefaultsign`, { "defaultSign": defaultSign }, this.Head)
        .then((res) => {
          console.log("Set default sign", res)
          this.getDefaultSign();
        })
        .catch(err =>
          console.log("Error in setdefaultsign", err)
        );
    },
    async getDefaultSign() {
      await axios.get(`${this.baseUrl}getdefaultsign`, this.Head)
        .then((res) => {
          console.log("getdefaultsign", res.data)
          this.defaultSign = res.data;
        })
        .catch(err =>
          console.log("Error in getdefaultsign", err)
        );
    },
    async showSignImage() {
      await this.ShowSignModal("ShowSignImage");
    },
    async getSignImage(email, password) {
      await axios.get(`${this.baseApi}document/signature/getsign?email=${email}&password=${password}`, this.Head)
        .then((res) => {
          console.log("get sign image", res.data);
          this.showSignImg = true;
          this.sourceSample = `${this.baseApi}document/signature/getsign?email=${email}&password=${password}`;
        })
        .catch(err => {
          alert(err.response.data.detail);
          console.log("Error in getinitials", err);
        });
    },
    async getInitials() {
      await axios.get(`${this.baseApi}document/signature/getinitials`, this.Head)
        //await axios.get(`https://localhost:5001/signature/getinitials`, this.Head)
        .then((res) => {
          console.log("getinitials", res.data);
          this.initial = res.data.initials;
          this.initialcreateddate = res.data.createdDate;
          this.initialmodifieddate = res.data.lastModifiedDate;

        })
        .catch(err =>
          console.log("Error in getinitials", err)
        );
    },
    async updateIntial() {
      await this.validateIntial();
      if (this.intialError == false) {
        await this.ShowSignModal(`Initial`)
      }
    },
    async saveInitials(email, password) {
      await axios.put(`${this.baseApi}document/signature/saveinitials`,
        //await axios.put(`https://localhost:5001/signature/saveinitials`,
        {
          "email": email,
          "password": password,
          "initials": this.newIntial.toUpperCase()
        }, this.Head)
        .then((res) => {
          console.log("saveinitials", res)
          this.getInitials();
        })
        .catch(err => {
          alert(err.response.data.detail);
          console.log("Error in saveinitials", err.response.data.detail);
        });
      this.closeEditable();
    },
    async makeEditable() {
      this.newIntial = this.initial;
      this.IsIntialEdit = true;
    },
    async closeEditable() {
      this.IsIntialEdit = false;
      this.intialError = false;
    },
    async imgSignEdit() {
      this.isImgSignEdit = true;
    },
    async closeImgEdit() {
      this.isImgSignEdit = false;
    },
    async uploadSignature(email, password, file) {
      await axios.post(`${this.baseApi}document/signature/uploadsign?email=${email}&password=${password}`, { image: file },
        {
          headers: {
            Authorization: "Bearer " + store.getters.getToken,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("uploadSignature", res.data);
          this.IsSignImageUploaded();
          this.getSignImage(this.emailId, this.password);
          this.isImgSignEdit = false;
          this.onclosed();
          this.closeImgEdit();
        })
        .catch(err => {
          console.log("Error in uploadSignature", err.response.data.detail)
          if (err.response.data.detail == `Email id entered differs from the logged-in user's email id`) {
            this.err = `Email id entered differs from the logged-in user's email id`;
            this.validation = true;
          }
          else if (err.response.data.detail == `Invalid password`) {
            this.err = `Invalid password`;
            this.validation = true;
          }
          //alert(err.response.data.detail);
        });
    },
    async chooseFileClicked(event) {
      this.file = event.target.files[0];
      await this.validateImage(this.file);
      if (this.imgValidation == false) {
        await this.ShowSignModal("Image");
      }
      else {
        this.file = null;
        this.$refs.fileInput.value = '';
      }
    },
    async ShowSignModal(value) {
      this.buttonAction = value;
      this.signModal = true;
    },
    async closeSignModel() {
      //await this.getDocumentSignDetails();
      this.signModal = false;
    },

    // --- Image-Aut-PopUp ---
    async onclosed() {
      this.signModal = false;
      this.buttonAction = "";
      this.emailId = "";
      this.password = "";
      this.validation = false;
      this.err = "";
      this.file = null;
      this.$refs.fileInput.value = '';
    },
    async submitClicked() {
      if (this.emailId == "") {
        this.err = "Please enter a valid email id";
        this.validation = true;
        return;
      }
      else if (this.emailId != this.profileDetails.primaryEmail) {
        this.err = "Email id entered differs from the logged-in user`s email id";
        this.validation = true;
        return;
      }
      else {
        this.err = "";
        this.validation = false;
      }
      if (this.password == "") {
        this.err = "Please enter your password";
        this.validation = true;
        return;
      }
      else {
        this.err = "";
        this.validation = false;
      }
      if (this.buttonAction == "Image") {
        await this.uploadSignature(this.emailId, this.password, this.file);
      }
      else if (this.buttonAction == "Initial") {
        await this.saveInitials(this.emailId, this.password);
        await this.onclosed();
      }
      else if (this.buttonAction == "ShowSignImage") {
        await this.getSignImage(this.emailId, this.password);
        await this.onclosed();
      }
    },

    // --- Validations ---
    async validatePasswordField() {
      this.signPassword = this.trimtext(this.signPassword.trim());
      this.confirmSignPassword = this.trimtext(this.confirmSignPassword.trim());
      this.validatePassword(this.signPassword.trim(), 0);
      this.validatePassword(this.confirmSignPassword.trim(), 1);
      if (this.signErrors[0] == false) {
        this.comparePasswords(this.signPassword.trim(), this.confirmSignPassword.trim());
      }
    },

    async comparePasswords(password, conformPassword) {

      if (password != conformPassword) {
        this.signErrors[1] = true;
        this.errorMessages[1] = "Passwords do not match ";
      }
      else {
        this.signErrors[1] = false;
        //this.errorMessages[1] = "Passwords do not match";
      }
    },
    async validatePassword(value, index) {
      const validationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
      value = this.trimtext(value);
      if (value.match(validationRegex)) {
        this.signErrors[index] = false;
      } else {
        this.signErrors[index] = true;
        this.errorMessages[index] = "The password must contain at least 8 characters, one uppercase,one lowecase ,one numeric and one special character; if left empty, please amend.";
      }
    },
    async validateIntial() {
      const regex = /^[A-Z]+$/;
      if (this.trimtext(this.newIntial).match(regex)) {
        console.log("Noo Issueeee??", this.newIntial)
        this.intialError = false;
      }
      else {
        this.intialError = true;
        console.log("Issueeee??", this.newIntial)
      }
    },
    trimtext(value) {
      value = value.replace(/^\s+|\s+$/gm, "");
      return value;
    },

    // image validations
    async validateImage(file) {
      console.log("in validateImage ", file.type);
      this.imgValidation = false;
      if (!file) {
        this.imgValidation = true;
        return;
      }
      else if (!file.type.startsWith("image/")) {
        this.imgValidation = true;
        alert('Only image files are allowed.');
        return;
      }
      else if (file.size > 50 * 1024) {
        this.imgValidation = true;
        alert('The file size exceeds the maximum limit of 50 KB.');
        return;
      }
      else {
        await this.uploadImage(file);
      }
      console.log("Exiting validate image")
    },
    async uploadImage(file) {
      const reader = new FileReader();
      const loadImage = (file) => {
        return new Promise((resolve, reject) => {
          reader.onload = () => {
            const image = new Image();
            image.onload = () => {
              if (image.width !== 200 || image.height !== 50) {
                reject('The image dimensions must be 200px x 50px.');
                return;
              }
              resolve(image);
            };
            image.src = reader.result;
          };
          reader.readAsDataURL(file);
        });
      };
      try {
        const image = await loadImage(file);
        this.showsave = true;
        this.uploadedImage = image;
      } catch (error) {
        this.imgValidation = true;
        console.log(error);
        alert(error);
      }
    },
    isGood(password) {
      console.log("is good");
      var password_strength = document.getElementById("passwordStrength");
      //TextBox left blank.
      if (password.length == 0) {
        password_strength.innerHTML = "";
        return;
      }

      //Regular Expressions.
      var regex = [];
      regex.push("[A-Z]"); //Uppercase Alphabet.
      regex.push("[a-z]"); //Lowercase Alphabet.
      regex.push("[0-9]"); //Digit.
      regex.push("[$@$!%*#?&]"); //Special Character.

      var passed = 0;

      //Validate for each Regular Expression.
      for (var i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
          passed++;
        }
      }
      //Display status.
      var strength = "";
      switch (passed) {
        case 0:
        case 1:
        case 2:
          strength = "<div class='progress-bar bg-danger' style='width: 100%; height: 15px;' >Weak</div>";
          break;
        case 3:
          strength = "<div class='progress-bar bg-warning' style='width: 100%; height: 15px;'>Medium</div>";
          break;
        case 4:
          strength = "<div class='progress-bar bg-success' style='width: 100%; height: 15px;'>Strong</div>";
          break;
      }
      password_strength.innerHTML = strength;
    }
  },
};
