<template>
  <div class="container">
    <!-- <div class="upload">
      <input id="file-upload" type="file" @change="onFileChange()" />
    </div>
    <div>
      <input id="userID" type="text" v-model="userId" />
    </div>
    <div>
      <input type="submit" value="Submit" @click="uploaddoc()" />
    </div> -->
    <h1>Not Implemented ....</h1>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store/index";
export default {
  name: "verifyDocument",
  props: { documentid: String },
  data() {
    return {
      file: null,
      userId: "",
      mainapi: `${process.env.VUE_APP_Service_URL}`,
    };
  },
  methods: {
    onFileChange(event) {
      this.file = event.target.files[0];
    },
    async uploaddoc() {
      let data = new FormData();
      data.append("file", this.file);

      let head = {
        headers: {
          Authorization: "Bearer " + store.getters.getToken,
          "Content-Type": "multipart/form-data",
        },
      };
      await axios
        .post(
          `${this.mainapi}document/signature/verify?userid=${this.userId}&docId=${this.documentid}`,
          data,
          head
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>


<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.file-name {
  margin-top: 10px;
}
</style>