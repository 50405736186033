<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="pl-0 col-12 col-12 col-md-10">
            <h2 class="my-0 py-0">IT Critical Systems Change Control Record</h2>
        </div>
        <div class="col-2 col-md-2 align-self-center">
            <i class="fa fa-times close cursor-pointer" @click.prevent="closebutton()"></i>
        </div>
    </div>
    <div class="wrapper wrapper-content pt-0">
        <div class="row mx-0">
            <div class="col-12 col-md-12 mt-2">
                <div class="form-group row">
                    <div class="col-12 col-md-12 mt-2"> <h3 class="header-color my-0" >Changes</h3>
                        <hr class="hr-block mb-0">
                    </div>
                    <div class="col-12 col-md-3 my-2 ">
                        <div class="d-flex">
                        <label class="mb-0 col-6 px-0">Change Number<span class="errorMsg px-2">*</span></label>                        
                        <div class="col-6 pl-0">
                        <input type="text"   @input="handleInput($event)" v-model="changeNumber" class="form-control" placeholder="" :disabled="presentBlock!='createBlock'"/>
                        <div class="errorMsg" v-if="showValidations && changeNumber === ''">Please
                            enter a field</div>
                            </div>
                        </div>
                        
                      
                    </div>
                    <div class="col-12 col-md-3 my-2">
                        <div class="d-flex">
                        <label class="mb-0 col-4 px-0">Risk Level<span class="errorMsg px-2">*</span></label>                        
                        <div class="col-8 px-0">                           
                        <input type="text" @input="handleInput($event)" maxlength="8" v-model="riskLevel" class="form-control" placeholder="" :disabled="presentBlock!='createBlock'"/>
                        <div class="errorMsg" v-if="showValidations && riskLevel === ''">Please
                            enter a field</div>
                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <div class="d-flex">
                        <label class="mb-0 col-2 px-0">File Name<span class="errorMsg px-2">*</span></label>                        
                        <div class="col-10 px-0">
                        <input type="text" @input="handleInput($event)" maxlength="300" v-model="fileName" class="form-control" placeholder="" :disabled="presentBlock!='createBlock'"/>
                        <div class="errorMsg" v-if="showValidations && fileName === ''">Please
                            enter a field</div>
                            </div>
                            </div>
                    </div>
                    <div class="col-12 col-md-12 mb-0">
                        <div class="dashed-line mb-2">
                        </div>
                    </div> 
                    <div class="col-12 my-2">
                    <div class="row mx-0">          
                        <div class="col-2 px-0">                            
                            <label class="mb-0  px-0">System(s) Affected<span class="errorMsg px-1">*</span></label>                       
                        </div>    
                        <div class="col-10 px-0">                       
                        <input type="text" @input="handleInput($event)" v-model="systemsAffected" class="form-control" maxlength="300" placeholder="" :disabled="presentBlock!='createBlock'"/>
                        <div class="errorMsg" v-if="showValidations && systemsAffected === ''">Please
                            enter a field</div>
                        </div>
                        
                        </div>
                    </div>
                    <div class="col-12 my-2">
                    <div class="row mx-0">  
                    <div class="col-2 px-0">                    
                        <label class=" mb-0 px-0">Reason for Change<span class="errorMsg px-1">*</span></label>
                        </div>
                        
                        <div class="col-10 px-0">                       
                        <textarea class="form-control height-40" maxlength="500" name="ReasonChange" id="ReasonChange"
                            v-model="changeReason" cols="12" rows="2" :disabled="presentBlock!='createBlock'"></textarea>
                        <div class="errorMsg" v-if="showValidations && changeReason === ''">Please
                            enter a field</div>
                        </div>
                        </div>
                    </div>
                    <div class="col-12 my-2">
                    <div class="row mx-0">  
                    <div class="col-2 px-0">
                        <label class="mb-0 px-0">Description of Change <span class="errorMsg px-1">*</span></label>
                    </div>                    
                    <div class="col-10 px-0">
                        <textarea class="form-control height-40"  maxlength="500" name="DescChange" id="DescChange"
                            v-model="changeDescription" cols="12" rows="2" :disabled="presentBlock!='createBlock'"></textarea>
                        <div class="errorMsg" v-if="showValidations && changeDescription === ''">Please
                            enter a field</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 my-2">
                       <div class="row mx-0">
                       <div class="col-2 px-0">
                        <label class="mb-0 px-0">Other Systems Affected</label>
                       </div>                      
                       <div class="col-10 px-0">                       
                        <textarea class="form-control height-40" @input="handleInput($event)"  maxlength="500" name="DescChange" id="DescChange"
                            v-model="othersystem" cols="12" rows="2" :disabled="presentBlock!='createBlock'"></textarea>
                            </div>                             
                       </div>
                    </div>
                    <div class="col-12 my-2">
                       <div class="row mx-0">
                       <div class="col-2 px-0">
                       <label class="mb-0 px-0">Testing Required</label>
                       
                        </div>
                        <div class="col-10 px-0">                     
                        <textarea class="form-control height-40" @input="handleInput($event)" maxlength="500" name="DescChange" id="DescChange"
                            v-model="testingRequired" cols="12" rows="2" :disabled="presentBlock!='createBlock'"></textarea>                          
                        </div>
                            </div>
                    </div>
                    <div class="col-12 my-2">
                       <div class="row mx-0">
                       <div class="col-2 px-0">
                       <label class="mb-0 px-0">Rollback Plan</label>
                        
                    </div>
                        <div class="col-10 px-0">                          
                        <textarea class="form-control height-40" @input="handleInput($event)" maxlength="500" name="rollBack" id="rollBack"
                            v-model="rollbackPlan" cols="12" rows="2" :disabled="presentBlock!='createBlock'"></textarea>
                            </div>                              
                        </div>
                    </div>                   
                    <div class="col-12 col-md-12 my-2" v-if="presentBlock==='RequestedBySign'  || presentBlock==='createBlock' || presentBlock==='ReviewedBySign' || presentBlock==='CompletedBySign'">
                        <div class="row" :class="{'mb-0': presentBlock==='RequestedBySign','py-3': presentBlock=== 'createBlock'}">
                        <div class="col-12 col-md-12" :class="{'mb-2': presentBlock=== 'createBlock'}"> <h3 class="header-color my-0" >Authorization</h3>
                             <hr class="hr-block">
                        </div>
                        <!-- Requested Block Starts Here -->
                        <div class="col-12 col-md-3" v-if="presentBlock==='RequestedBySign'  || presentBlock==='createBlock' || presentBlock==='ReviewedBySign' || presentBlock==='CompletedBySign'">
                        <div class="d-flex">                       
                            <label class="col-5 mb-0 px-0">Requested By<span class="errorMsg px-1" v-if="presentBlock==='createBlock'">*</span></label>
                            <div class="col-7 px-0">                          
                            <select class="form-control" @input="handleInput($event)" v-model="requestedBy" @change="selectEmail(requestedBy,'requested')" :disabled="presentBlock!='createBlock'">
                                <option value="" disabled selected>Select User</option>
                                <option v-for="user in userList" :key="user.id" :value="user.fullName">
                                    {{ user.fullName }}
                                </option>
                            </select>
                            <div class="errorMsg" v-if="showValidations && requestedBy===''">Please enter a field</div>     
                        </div>                           
                        </div>
                        </div>
                        <div class="col-12 col-md-3" v-if="presentBlock==='RequestedBySign'  || presentBlock==='createBlock' || presentBlock==='ReviewedBySign' || presentBlock==='CompletedBySign'">
                        <div class="d-flex">
                            <label class="mb-0 col-3 px-0" for="">Job Title <span>:</span></label>                           
                            <div class="col-9">                   
                            {{ requestedJobTitle }}                            
                            </div>
                        </div>
                        </div>
                        <div class="col-12 col-md-3" v-if="presentBlock==='RequestedBySign' || presentBlock==='ReviewedBySign' || presentBlock==='CompletedBySign'">
                        <div class="d-flex">                        
                            <label class="col-5 px-0 mb-0" for="">Date Requested<span class="errorMsg px-1" v-if="showSign[getValueNumber]==false">*</span></label>                            
                            <div class="col-7 px-0">                            
                            <input type="date" :min="minDate" v-model="requestedDate" v-if="presentBlock==='RequestedBySign' && showSign[0]!=true" class="form-control">
                            <div v-if="presentBlock==='ReviewedBySign' || presentBlock==='CompletedBySign' || showSign[0]==true"> {{ formatDate(requestedDate)}}                             
                            </div>
                            <div class="errorMsg" v-if="showValidations && (requestedDate=='' ||requestedDate==null)">Please enter a field</div>                                 
                        </div>                               
                        </div>
                        </div>   
                        <!-- <div class="col-8 mt-2">
                            
                        </div>   -->
                        <div class="col-12 col-md-3" v-if="signedDoc[0]==true || presentBlock==='ReviewedBySign' || presentBlock==='CompletedBySign' || showSign[getValueNumber]==true ">
                        <div class="sign-block row mx-0">
                            <div class="col-4">
                                <span>Signed By </span>
                            </div>
                            <div class="col-8">
                                :<span class="signed-by">&nbsp;{{ controlData.signers[0].userName }}</span>
                            </div>
                            <div class="col-4">
                                <span>Name </span>
                            </div>
                            <div class="col-8">
                                <span>:&nbsp;{{ controlData.signers[0].userName }}</span>
                            </div>
                            <div class="col-4">
                                <span>Reason </span>
                            </div>
                            <div class="col-8">
                                <span>:&nbsp;{{ controlData.signers[0].reason }}</span>
                            </div>
                            <div class="col-4">
                                <span>Date and Time </span>
                            </div>
                            <div class="col-8">
                                <span>:&nbsp;{{  filter(controlData.signers[0].date) }}</span>
                            </div>
                            <div class="col-4">
                                <span>Ref </span>
                            </div>
                            <div class="col-8">
                                <span class="ref-label">:&nbsp;{{ controlData.signers[0].signId }}</span>
                            </div>
                        </div>
                            
                        </div>
                    </div>
                </div>
                <!-- Requested Block Ends Here -->
                <!-- Reviewed Block Starts Here -->
                <div class="col-12 col-md-12 mb-0" v-if="presentBlock==='createBlock' || presentBlock==='ReviewedBySign' || presentBlock==='CompletedBySign'">
                    <div class="dashed-line mb-2">
                    </div>
                </div> 
                    <div class="col-12 col-md-12 my-2" v-if="presentBlock==='ReviewedBySign' || presentBlock==='createBlock' || presentBlock==='CompletedBySign'">
                        <div class="row" :class="{'pt-0': presentBlock==='ReviewedBySign','py-3': presentBlock==='createBlock'}">
                        <div class="col-12 col-md-3">   
                        <div class="d-flex">                        
                        <label class="mb-0 col-5 px-0">Reviewed By <span class="errorMsg px-1" v-if="presentBlock==='createBlock'">*</span></label>
                       
                        <div class="col-7 px-0">                        
                        <select class="form-control" :disabled="presentBlock!='createBlock'" v-model="reviewer" @change="selectEmail(reviewer,'reviewed')">
                            <option value="" disabled selected>Select User</option>
                            <option v-for="user in userList" :key="user.id" :value="user.fullName">
                                {{ user.fullName }}
                            </option>
                        </select>
                        <div class="errorMsg" v-if="showValidations && reviewer===''">Please enter a field</div>            
                    </div>               
                    </div> 
                        </div>
                        <div class="col-12 col-md-9">
                        <div class="d-flex">
                        <label class="mb-0 col-1 px-0" for="">Job Title <span>:</span></label>                       
                        <div class="col-11 px-0">     
                        {{ reviewerJobTitle }}                   
                                           
                    </div>
                        </div>
                        </div>
                        <div class="col-12 col-md-3 mt-2" v-if="presentBlock==='ReviewedBySign' || presentBlock==='CompletedBySign'">
                        <div class="d-flex">                       
                            <label class="col-5 px-0 mb-0" for="">Date Reviewed<span class="errorMsg px-1" v-if="showSign[getValueNumber]==false">*</span></label>                            
                            <div class="col-7 px-0">                            
                            <input type="date" :min="minDate" v-if="presentBlock==='ReviewedBySign' && showSign[1]!=true" class="form-control" v-model="dateReviewed">
                            <div v-if="presentBlock==='CompletedBySign' || showSign[1]==true">
                                {{ formatDate(dateReviewed)}} 
                            </div>
                             <div class="errorMsg" v-if="showValidations && (dateReviewed === ''|| dateReviewed==null)">Please
                            enter a field</div>                                     
                            </div>
                        </div>
                        </div>
                        <div class="col-12 col-md-3 my-2" v-if="presentBlock==='ReviewedBySign' || presentBlock==='CompletedBySign'">
                        <div class="d-flex">                      
                            <label class="col-4 px-0 mb-0" for="">Action Date<span class="errorMsg px-1" v-if="showSign[getValueNumber]==false">*</span></label>
                            <div class="col-8 px-0">                            
                            <input type="date" v-if="presentBlock==='ReviewedBySign'" :min="minDate" class="form-control" :disabled="presentBlock==='CompletedBySign' || showSign[getValueNumber]==true" v-model="actionDate">
                            <div v-if="presentBlock==='CompletedBySign'"> {{ formatDate(requestedDate)}}                             
                            </div>
                            <div class="errorMsg" v-if="showValidations && (actionDate === ''|| actionDate==null)">Please
                            enter a field</div>    
                        </div>                                
                        </div>
                        </div> 
                        <div class="col-12 col-md-3 my-2" v-if="presentBlock==='ReviewedBySign' || presentBlock==='CompletedBySign'">
                            <label class="mb-0">Is the change control record approved?</label>
                            <span class="errorMsg px-1" v-if="showSign[getValueNumber]==false">*</span>
                            <div>
                                <span class="mx-2">
                                    <input type="radio" :disabled="presentBlock==='CompletedBySign' || showSign[getValueNumber]==true" v-model="approval" id="approvalYes" name="opt" value="Yes">
                                    <label for="approvalYes" class="ml-2 mb-0">Yes</label>
                                </span>
                                <span class="mx-2">
                                    <input type="radio" :disabled="presentBlock==='CompletedBySign' || showSign[getValueNumber]==true" v-model="approval" id="approvalNo" name="opt" value="No">
                                    <label for="approvalNo" class="ml-2 mb-0">No</label>
                                </span>
                                <div class="errorMsg" v-if="showValidations && (approval === '' || approval==null)">Please
                            enter a field</div>
                    
                            </div>                        
                        </div>
                        <div class="col-12 col-md-3" v-if="signedDoc[1]==true || showSign[getValueNumber]==true || presentBlock==='CompletedBySign' || finalSignCompleted==true">
                        <div class="sign-block row mx-0">
                            <div class="col-4">
                                <span>Signed By </span>
                            </div>
                            <div class="col-8">
                                :<span class="signed-by">&nbsp;{{ controlData.signers[1].userName }}</span>
                            </div>
                            <div class="col-4">
                                <span>Name </span>
                            </div>
                            <div class="col-8">
                                <span>:&nbsp;{{ controlData.signers[1].userName }}</span>
                            </div>
                            <div class="col-4">
                                <span>Reason </span>
                            </div>
                            <div class="col-8">
                                <span>:&nbsp;{{ controlData.signers[1].reason }}</span>
                            </div>
                            <div class="col-4">
                                <span>Date and Time </span>
                            </div>
                            <div class="col-8">
                                <span>:&nbsp;{{  filter(controlData.signers[1].date) }}</span>
                            </div>
                            <div class="col-4">
                                <span>Ref </span>
                            </div>
                            <div class="col-8">
                                <span class="ref-label">:&nbsp;{{ controlData.signers[1].signId }}</span>
                            </div>
                        </div>
                            
                        </div>      
                        <div class="col-12 col-md-12 my-2" v-if="approval==='No' || presentBlock==='CompletedBySign'">
                        <div class="d-flex">
                            <label class="col-1 mb-0 px-0">Reason Refused</label>  
                            <div class="col-11 ml-3 pl-0">
                                <textarea class="form-control height-40" :disabled="presentBlock==='CompletedBySign' || showSign[getValueNumber]==true" v-model="reasonRefused" name="ReasonChange" id="ReasonChange"
                                maxlength="500"
                                cols="12" rows="2"></textarea>
                            </div>                      
                           
                        </div>
                     
                        </div>
                        </div>           
                              
                    </div>
                    <!-- Reviewed Block Ends Here -->
                    <!-- Completed Block Starts Here -->
                    <div class="col-12 col-md-12 my-0" v-if="presentBlock==='createBlock' || presentBlock==='CompletedBySign'">
                        <div class="py-0 row">
                            <div class="col-12 col-md-12 mb-2"> <h3 class="header-color my-0" >Completion</h3>
                             <hr class="hr-block">
                            </div>
                            <div class="col-12 col-md-3">
                            <div class="d-flex">                           
                                <label class="mb-0 col-5 px-0">Completed By<span class="errorMsg px-2" v-if="presentBlock==='createBlock'">*</span></label>
                                <div class="col-7 px-0">                              
                                <select class="form-control" :disabled="presentBlock!='createBlock'" v-model="completedBy"  @change="selectEmail(completedBy,'completed')">
                                    <option value="" disabled selected>Select User</option>
                                    <option v-for="user in userList" :key="user.id" :value="user.fullName">
                                        {{ user.fullName }}
                                    </option>
                                </select>
                                <div class="errorMsg" v-if="showValidations && completedBy===''">Please
                                    enter a field</div>     
                                </div>         
                                </div>                   
                                </div>                        
                            
                            <div class="col-12 col-md-3">
                            <div class="d-flex">                            
                            <label class="mb-0 col-3 px-0" for="">Job Title <span>:</span></label>                           
                            <div class="col-9 px-0">   
                                {{ completedJobTitle }}                          
                           
                            </div>                                
                            </div>
                            </div>
                            <div class="col-12 col-md-3" v-if="presentBlock==='CompletedBySign'">
                            <div class="d-flex">                            
                            <label class="mb-0 col-5 px-0" for="">Date Completed</label>
                            <span class="errorMsg px-1" v-if="showSign[getValueNumber]==false">*</span>
                            <div class="col-7 px-0">                            
                            <input type="date" :min="minDate" v-if="finalSignCompleted!=true" class="form-control" v-model="dateCompleted" >
                            <div v-else>
                                {{ formatDate(dateCompleted) }}
                            </div>
                            <div class="errorMsg" v-if="showValidations && (dateCompleted == ''||dateCompleted==null)">Please
                                    enter a field</div>
                            </div>                  
                        </div>          
                           
                        </div>
                        <div class="col-12 col-md-3" v-if="finalSignCompleted==true || this.signedDoc[2]==true || this.showSign[getValueNumber]==true">
                        <div class="sign-block row mx-0">
                            <div class="col-4">
                                <span>Signed By </span>
                            </div>
                            <div class="col-8">
                                :<span class="signed-by">&nbsp;{{ controlData.signers[2].userName }}</span>
                            </div>
                            <div class="col-4">
                                <span>Name </span>
                            </div>
                            <div class="col-8">
                                <span>:&nbsp;{{ controlData.signers[2].userName }}</span>
                            </div>
                            <div class="col-4">
                                <span>Reason </span>
                            </div>
                            <div class="col-8">
                                <span>:&nbsp;{{ controlData.signers[2].reason }}</span>
                            </div>
                            <div class="col-4">
                                <span>Date and Time </span>
                            </div>
                            <div class="col-8">
                                <span>:&nbsp;{{  filter(controlData.signers[2].date) }}</span>
                            </div>
                            <div class="col-4">
                                <span>Ref </span>
                            </div>
                            <div class="col-8">
                                <span class="ref-label">:&nbsp;{{ controlData.signers[1].signId }}</span>
                            </div>
                        </div>
                            
                        </div> 
                            <div class="col-12 col-md-12 my-2" v-if="presentBlock==='CompletedBySign'">
                            <div class="d-flex">
                                <label class="col-1 mb-0 px-0">Notes</label>
                                <div class="col-11 ml-3 pl-0">
                                    <textarea class="form-control height-40" :disabled="finalSignCompleted==true" maxlength="500" name="CompletionNote" id="CompletionNote"
                                    v-model="notes" cols="12" rows="2"></textarea>
                                </div>
                                
                            </div>                                
                            </div>                
                                                    
                        </div>
                    </div>
                     <!-- Completed Block Starts Here -->
                    <div class="col-12 col-md-12 my-2" v-if="showValidations && (requestedBy && reviewer && completedBy)">
                        <div class="errorMsg" v-if="requestedBy == reviewer ">Requested by and reviewed by fields cannot be same.</div>
                        <div class="errorMsg" v-if="requestedBy == completedBy ">Requested by and completed by fields cannot be same.</div>
                        <div class="errorMsg" v-if="reviewer == completedBy ">Reviewed by and completed by fields cannot be same.</div>                    
                    </div>
                    <div class="col-12 col-md-12 my-2 text-center" v-if="showSign[getValueNumber]==false">
                        <button class="btn btn-primary btn-sm px-3 py-1" v-if="presentBlock==='createBlock'" type="submit" @click.prevent="checkValidations()"> 
                            <span >Save</span>
                        </button>
                        <div
                            class="align-self-center py-0 my-0"
                            v-else
                        >
                        <span class="sign-doc-block position-relative cursor-pointer">
                            <!-- @click="Signdocfun()" -->
                            <img 
                            src="../../assets/img/sign_dms.png"
                            alt="create version"
                            @click.prevent="checkValidations()"
                            />
                            <span class="tool-tip">Sign Document</span>
                        </span>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <DigitalSignPopUp
    v-if="showSignBlock==true"
    :objectData="postData"
    @closePopup="showSignBlock = false"
    @Signed="getData(),signedDoc[getValueNumber]=true"
    @getData="finalSign()"
    Source="controlRecords"
    :numberType="getValueNumber"
  >
  </DigitalSignPopUp>
</template>
<script src="./controlRecords"></script>
<style>
.hr-block{
    border-color: hsl(219, 60%, 61%); 
    margin-top:5px;
}
.dashed-line{
    border-top: 1px dashed hsl(219, 60%, 61%);
    color: #ffffff;
    background-color: #ffffff;
    height: 1px;
}
.header-color{
   color: hsl(219, 60%, 61%)
}
.errorMsg {
    color: hsl(0, 100%, 50%)
}
.bordered-layout
{
    border:1px solid #e7eaec
}
.loading-img {
  mix-blend-mode: lighten;
}
.filename-popup {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 3000;
}
.filename-popup-header {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-width {
      width: min(400px, 90%);
  }
  .sign-block{
    border:1px solid hsl(0deg 0% 72.44%)
  }
  .sign-block span{
    font-weight:bold
  }
  .signed-by
  {
    font-family: Brush Script MT,cursive;
    font-size: 1.5em;
  }
  .ref-label
  {
    font-size: .85em;
  }
  .sign-doc-block .tool-tip {
    visibility: hidden;
    width: -moz-max-content;
    width: max-content;
    background-color: #616161;
    color: #fff;
    text-align: center;
    border-radius: 0.3em;
    padding: 0.3rem 0.8rem;
    left: 30px;
    top: -18px;
    font-size: 0.8rem;
    position: absolute;
    z-index: 1;
    transition: visibility 75ms ease-in;
}

.sign-doc-block:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
</style>
