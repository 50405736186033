/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import store from "../../store/index";
import PermissionPopUp from "../permission_popup/permission_popup.vue"
import { textChangeRangeIsUnchanged } from "typescript";
export default {
  name: "SubsidePopup",
  components: {
    PermissionPopUp
  },
  props: {
    blockid: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    blocktopPosition: {
      type: Number,
      default: 0
    },
    blockleftPosition: {
      type: Number,
      default: 0
    },
    dest: {
      type: String,
      default: ""
    },
    ev: {
      type: Event
    },
    index:{
      type: Number,
      default:0
    }
  },
  data() {
    return {
      div:""
    }
  },
  mounted() {
    let div=""
    let sideDiv=document.getElementById("side-block")
    let popUp=document.getElementById("sub-side-block")
   
    // Get the height of the div using offsetHeight
    var divHeight = sideDiv.offsetHeight;
    if(this.dest == 'projectlist' ||this.dest == 'users' || this.dest=='projectRole')
    {
      div= document.getElementById("primaryTree")
      const rect = div.getBoundingClientRect();
      popUp.style.position="absolute"
      const scrollTop = div.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = div.pageXOffset || document.documentElement.scrollLeft;
      const x = this.ev.clientX - rect.left - scrollLeft;
      const y = this.ev.clientY - rect.top - scrollTop; 
      if(this.dest == 'projectlist' || this.dest == 'users' || this.dest=="DocumentList")
      {
        popUp.style.right="0"
        popUp.style.marginTop=divHeight+"px"       
      }   
     
    }
    else if (this.dest != 'docs'  && this.dest != 'file'  && this.dest != 'projectlist') 
    {
      div= document.getElementById("primaryTree")
      const rect = div.getBoundingClientRect();
      const scrollTop = div.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = div.pageXOffset || document.documentElement.scrollLeft;
      const x = this.ev.clientX - rect.left - scrollLeft;
      const y = this.ev.clientY - rect.top - scrollTop;    
      if (this.dest != 'docs' && this.dest != 'file' && this.dest != 'project') 
      {
        popUp.style.right=parseFloat(x-30)+"px"
        popUp.style.top=y+divHeight+"px"
      }
      
      else{     
        if(this.index>=6){
          popUp.style.bottom="0px"
          popUp.style.right="50px"
        }
        else{
          
          popUp.style.right="53px"
        }
       
      } 
    }
    else{
      div= document.getElementById("primaryDocumentTree")
      const rect = div.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      const x = this.ev.clientX - rect.left - scrollLeft;
      const y = this.ev.clientY - rect.top - scrollTop;
      if (this.dest == 'project'){
        popUp.style.right=x+"px"
      }
      else if (this.dest != 'docs' && this.dest != 'file' && this.dest != 'project') 
      {
        popUp.style.right=x+"px"
        popUp.style.top=y+divHeight+"px"
      }
      
      else{     
          if(popUp.querySelectorAll("div").length==1)
        
            popUp.style.top=y+divHeight+"px"       
            popUp.style.right="80px"
          }
         

      
    } 
  },
  
  methods: {


  }
}