/* eslint-disable */
import axios from "axios";
import store from "../../store/index";

export default {
    props: {
        documentid: String,
        versionId: String,
    },

    data() {
        return {
            baseurl: `${process.env.VUE_APP_Service_URL}document/documentquestion/`,
            idtoken: "",
            searchField: {
                name: "",
                createdat: ""
            },
            questionlist: [],
            currentPage: 1,
            pageindex: 1,
            pagesize: 10,
            totalPage: 1,
            SortProperty: "question",
            SortOrder: false,
            SortOrderInt: 0,
            designationPrivileges :"",
            sourcePage: "",
            sourceRoute: "",
        };
    },

    async mounted() {
        this.designationPrivileges = store.getters.getRolesprivilegeData;
        this.idtoken = store.getters.getToken;
        this.Head = {
            headers: {
                Authorization: "Bearer " + store.getters.getToken,
                "Content-Type": "application/json",
            },
        };
        this.sourcePage = await store.getters.getDocViewSource;
        if (this.sourcePage == `Project` || this.sourcePage == 'ProjectSummary') {
          this.sourceRoute = `Projects`;
        } else if (this.sourcePage == `Department`) {
          this.sourceRoute = `Departments`;
        }
        if(this.designationPrivileges.includes(this.sourceRoute+` Question`)){
        }
        else{
            this.$router.push(`/noprivilege`);
        }
        await this.questionslist();
    },

    methods: {
        onFileChange() {
            document.getElementById("InPutFile").click();
          },
          async previewFiles(event) {
            this.xlsxFile = event.target.files[0];
            console.log("type is",this.xlsxFile);
            event.target.value = null;  //This will clear the selected file from the file input field so that a new file can be selected.
            this.submitFile();
          },
          async submitFile() {
            var formData = new FormData();
            formData.append("item", this.xlsxFile, this.xlsxFile.name);
            console.log(formData);
            if (confirm("Are you sure you want to upload the questions?")) {         
            //TODO Change here
            await axios
              .post(
                this.baseurl + "upload?DocumentId=" + this.documentid,           
                formData,
                {
                  responseType: "blob",
                  headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "multipart/form-data",
                  },
                },
                {
                  onUploadProgress: (uploadEvent) => {
                    console.log("Helllo" + uploadEvent.loaded);
                  },
                }
              )
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = "Results";
                link.click();
                URL.revokeObjectURL(link.href);
                alert("Questions uploaded successfully");
                //window.location.reload();
                this.questionslist();
              })
              .catch((err, response) => {               
                console.log(err);
                if(err.message == "Request failed with status code 500")
                {
                    alert("Please upload the excel file with the specified format");
                }
                if(err == "Error: Request failed with status code 400"){
                  alert("Please upload xls or xlsx file");
                }
                else if(err == "Error: Request failed with status code 504"){
                  alert("Incorrect format");
                }
                
                // alert("Bad Request");
                //window.location.reload();
                this.questionslist();
              });
            }
          },
        async OnSearchButtonClicked() {
            this.pageindex = 1;
            this.currentPage = 1;
            this.questionslist();
        },
        async DeleteClicked(id) {
            if (confirm("Are you sure you want to delete this question?")) {
                await axios.delete(this.baseurl + "delete?Id=" + id,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                    .then((res) => {
                        alert("Question Deleted")
                    })
                    .catch((err) => {
                        console.log(err);
                        this.questionlist = []
                        alert("Somthing Went Wrong")
                    })
                this.pageindex = 1;
                this.currentPage = 1;
                this.questionslist();

            }
        },
        async questionslist() {
            console.log("in search questionnnnnnnnnnnnnn", this.idtoken);
            await axios
                .get(
                    `${this.baseurl}search?docId=${this.documentid}&versionId=${this.versionId}&question=${this.searchField.name}&uploadeddate=${this.searchField.createdat}&pageindex=${this.pageindex}&pagesize=${this.pagesize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrderInt}`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    this.questionlist = res.data.entities;
                    this.totalPage = parseInt(res.data.totalCount / this.pagesize);
                    if (res.data.totalCount % this.pagesize != 0) {
                        this.totalPage = this.totalPage + 1;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.questionlist = []
                    alert("No data found!")
                })
        },
        async onclosed() {
            this.$router.push(`/documentview/` + this.documentid + "/" + 0 )
          },
        async GoToManageUser(id) {
            this.$router.push("/CreateQuestion/" + this.documentid + "/" + id)
        },
        SortSelected(sortProp, id) {
            console.log('SortPropChanged', sortProp, id);
            const selectedElement = document.getElementById(id)
            const selectedElementID = document.getElementById(id).id
            if (sortProp != null && id == selectedElementID) {
                this.sortDesc = !this.sortDesc
                if (this.SortOrder == 0) {
                    if (selectedElement.classList.contains("sortAsc")) {
                        selectedElement.classList.remove("sortAsc")
                        selectedElement.classList.add("sortDesc")
                    }
                    else selectedElement.classList.add("sortDesc")
                }
                else {
                    if (selectedElement.classList.contains("sortDesc")) {
                        selectedElement.classList.remove("sortDesc")
                        selectedElement.classList.add("sortAsc")
                    }
                    else selectedElement.classList.add("sortAsc")
                }
            }
            this.pageindex = 1;
            this.currentPage = 1;
            this.SortProperty = sortProp;
            this.SortOrder = this.SortOrder == true ? false : true;
            this.SortOrderInt = this.SortOrder == true ? 0 : 1;
            this.questionslist();
        },
        loadPage: function (page) {
            this.currentPage = page;
            this.pageindex = page;
            this.questionslist();
        },

    },
};