
<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0">Screens</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i
        class="fa fa-times close cursor-pointer"
        @click="goBack"
      ></i>
    </div>
  </div>
  <div>
    <div class="wrapper wrapper-content">
      <div class="ibox-body p-2">
        <div class="row">
          <div class="col-md-6">
            <div
              class="ibox-title style_2 d-flex justify-content-between align-items-center"
            >
              <h5 class="my-0">Screens</h5>
              <div
                v-if="designationPrivileges.includes(`Screens Create Screen`)"
                class="position-relative align-self-center"
                aria-label="Add screen"
                tabindex="0"
              >
                <img
                  class="cursor-pointer"
                  src="../../assets/img/create.png"
                  alt="Add screen"
                  @click="openModel(false)"
                />
                <div class="tool-tip">Add Screen</div>
              </div>
            </div>
            <div class="ibox-content p-3">
              <div class="list-group scrollable">
                <a
                  class="list-group-item"
                  v-for="item in screen"
                  :key="item.id"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  @click="startEditMethod(item.id)"
                >
                  {{ item.name }}
                  <i
                    class="fa fa-angle-right float-right"
                    @click.prevent="startEditMethod(item.id)"
                  >
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal starts -->
  <div class="modal__wrapperscreen" v-if="openModal">
    <div class="modal__approver__container">
      <div
        class="modal-header-projects d-flex justify-content-between py-2 px-3"
      >
        <h4 class="my-0" v-if="HeadingChange">Add Screen</h4>
        <h4 class="my-0" v-else>Edit Screen</h4>
        <div class="cursor-pointer" @click.prevent="closemodall()">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="ibox-content">
        <!-- test -->

        <div class="ibox-content p-3">
          <div class="row">
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                v-model="screenName"
                placeholder="Screen name"
                maxlength="50"
              />
              <span class="errormsg" v-if="errors[0]"
                >Enter a valid screen name</span
              >
            </div>

            <div class="col-lg-1 my-1">
              <button
              v-if="isEdit ? designationPrivileges.includes('Screens Edit Screen') : designationPrivileges.includes('Screens Create Screen')"
                class="btn-circle my-1"
                type="button"
                @click.prevent="postScreen"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <div class="col-lg-3 my-2" v-if="showDelete && designationPrivileges.includes(`Screens Delete Screen`)">
              <img
                class="editicon"
                src="../../assets/img/delete.png"
                @click="deleteScreen(item, modalscreenbyid.id)"
              />
            </div>
          </div>
        </div>
        <div class="ibox-title2 mt-2" v-if="retrieve && designationPrivileges.includes(`Screens Create Controls`)">
          <h4>Add Controls</h4>
        </div>
        <div class="ibox-content p-3" v-if="retrieve && designationPrivileges.includes(`Screens Create Controls`)">
          <div class="row">
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                v-model="controledit"
                placeholder="Control name"
                maxlength="50"
              />
              <span class="errormsg" v-if="errors[1]"
                >Enter a valid control name</span
              >
            </div>

            <div class="col-lg-1 my-1">
              <button
                class="btn-circle"
                type="button"
                @click.prevent="createControls()"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="list-group scrollable mt-3" v-if="controls.length != 0">
          <table class="table">
            <thead>
              <tr>
                <th>Controls</th>
                <th v-if="designationPrivileges.includes(`Screens Edit Controls`)">Edit</th>
                <th v-if="designationPrivileges.includes(`Screens Delete Controls`)">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in modalscreenbyid.controls" :key="item">
                <td v-if="item != control">{{ item }}</td>
                <td v-if="item == control">
                  <input
                    type="text"
                    class="form-control"
                    v-model="controlName"
                    placeholder=""
                    maxlength="30"
                  />
                </td>
                <td v-if="item != control && designationPrivileges.includes(`Screens Edit Controls`)">
                  <img
                    class="editicon"
                    src="../../assets/img/Edit.png"
                    @click="updateControllers(item, newvalue, screenbyid.id)"
                  />
                </td>
                <td v-if="item == control">
                  <i
                    class="fa fa-save edit-delete1 my-1"
                    @click="savebutton(0)"
                  >
                  </i>
                  <i class="fa fa-times close my-1" @click="closebutton()"> </i>
                </td>
                <td v-if=" designationPrivileges.includes(`Screens Delete Controls`)">
                  <img
                    class="editicon"
                    src="../../assets/img/delete.png"
                    @click="deletePrivilege(item, modalscreenbyid.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- test -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import axios from "axios";
import store from "../../store/index";
export default {
  name: "screen",
  data() {
    return {
      baseurl: `${process.env.VUE_APP_Service_URL}account-core/roles/`,
      updateValue: {
        screenId: "",
        name: "",
        controls: [],
      },
      openModal: false,
      isEdit: true,
      errors: [false, false],
      screenidbyname: "",
      selectedid: "",
      controledit: "",
      modalscreenbyid: "",
      screenName: "",
      screen: "",
      screenstatus: false,
      screenid: "",
      screenbyid: "",
      controlName: "",
      controls: [],
      screenList: [],
      screens: [],
      deletebody: {
        screenId: "",
        control: "",
        myModel: false,
      },
      Head: "",
      edit: true,
      control: "",
      retrieve: true,
      showDelete: true,
      HeadingChange: true,
      designationPrivileges :"",
    };
  },
  async mounted() {
    this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    this.getallscreen();
  },
  methods: {
    async startEditMethod(id) {
      this.openModel(true);
      this.retrieve = true;
      this.showDelete = true;
      this.HeadingChange = false;
      await this.getcontrollers(id, "false");
      this.screenName = this.screenbyid.name;
      this.screenList = this.screenbyid.controls;
      this.controls = this.screenbyid.controls;
      this.modalscreenbyid = this.screenbyid;
      this.screenidbyname = id;
      this.updateValue.screenId = id;
      this.updateValue.name = this.screenName;
      this.updateValue.controls = this.screenbyid.controls;
    },
    async closebutton() {
      console.log("CLOSE MODEL");
      this.control = "";
      this.controlName = "";
    },
    async getallscreen() {
      await axios
        .get(`${this.baseurl}getallscreens`, this.Head)
        .then((res) => {
          this.screen = res.data;
          if (this.screen == "No screen found") {
            this.screen = "";
            alert("No data found");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getcontrollers(id, modal) {
      this.selectedid = id;
      await axios
        .get(`${this.baseurl}getscreenbyid?guid=${id}`, this.Head)
        .then((res) => {
          if (modal == "false") {
            this.screenbyid = res.data;
          } else {
            this.modalscreenbyid = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async postScreen() {
      this.screenName = this.screenName.replace(/ +/g, " ").trim();
      this.validateScreenName(this.screenName);
      if (!this.errors[0]) {
        if (!this.isEdit) {
          await axios
            .post(
              `${this.baseurl}screen`,
              {
                name: this.screenName,
                controls: [],
              },
              this.Head
            )
            .then((response) => {
              alert("Screen added successfully");
              this.retrieve = true;
              this.showDelete = false;
              this.HeadingChange = true;
              this.getScreens();
              this.getallscreen();
              this.controlId = response.data;
            })
            .catch((err) => {
              console.log(err);
              alert("Screen name already exist");
              this.screenName = "";
            });
          await this.getidbyname();
         // this.isEdit = !this.isEdit;
          this.openModal = !this.openModal;
        } else {
          this.updateValue.name = this.screenName;
         console.log("updatevalue",this.updateValue)
          await axios
            .put(`${this.baseurl}updatescreen`, this.updateValue, this.Head)
            .then((response) => {
              console.log(response);
              alert("Screen updated successfully");
            })
            .catch((err) => {
              console.log(err);
              alert("Screen already exist");
              this.screenName = this.screenbyid.name;
            });
        }
      }
    },

    validateScreenName(screenName) {
      if (screenName == "") {
        this.errors[0] = true;
      } else {
        this.errors[0] = false;
      }
    },
    validateControlName(controledit) {
      if (controledit == "") {
        this.errors[1] = true;
      } else {
        this.errors[1] = false;
      }
    },

    async closemodall() {
      this.openModal = !this.openModal;
      this.modalscreenbyid = "";
      this.screenName = "";
      this.control = "";
      this.screenList = [];
      this.controls = [];
      this.errors[0] = false;
      this.errors[1] = false;
      this.getallscreen();
    },

    async getScreens() {
      await axios
        .get(`${this.baseurl}getallscreens`, this.Head)
        .then((response) => {
          this.screens = response.data;

          this.screens.filter((item) => {
            if (item.name == this.screenName) {
              this.screenList.push(item);
              this.guid = item.id;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async createControls() {
      this.controledit = this.controledit.replace(/ +/g, " ").trim();
      this.validateControlName(this.controledit);
      this.controlName = this.controledit;
      if (!this.errors[1]) {
        await axios
          .post(
            `${this.baseurl}createcontrol`,
            {
              screenId: this.screenidbyname,
              control: this.controlName,
            },
            this.Head
          )
          .then(async (response) => {
            console.log(response);
            this.controls.push(this.controlName);
            alert("Control added successfully");
            await this.getcontrollers(this.screenidbyname, "true");
          })
          .catch((err) => {
            console.log(err);
            alert("Control name already exist");
          });
        this.controlName = "";
        this.controledit = "";
      }
    },
    async getidbyname() {
      await axios
        .get(
          `${this.baseurl}searchscreen?screenName=${this.screenName}`,
          this.Head
        )
        .then((response) => {
          this.screenidbyname = response.data[0].id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async deletePrivilege(control, id) {
      this.deletebody.screenId = id;
      this.deletebody.control = control;
      if (confirm("Are you sure you want to delete this control?")) {
        await axios
          .delete(
            `${this.baseurl}deletecontrol`,
            {
              data: {
                screenId: id,
                control: control,
              },
              
              headers: {
              Authorization: "Bearer " + store.getters.getToken,
              "Content-Type": "application/json",
              },
            }
           
          )
          .then((response) => {
            console.log(response);
            this.getcontrollers(id, "true");
            this.screenstatus;
            this.refreshSelectedById(id);
          })
          .catch((err) => {
            console.log(err);
          });
        this.control = "";
      }
    },
    async updateControllers(item, id) {
      this.controlName = item;
      this.edit = false;
      this.control = item;
      this.screenid = id;
    },
    //----------
    async refreshSelectedById(id) {
      this.selectedid = id;
      await axios
        .get(`${this.baseurl}getscreenbyid?guid=${id}`, this.Head)
        .then((res) => {
          this.screenbyid = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async savebutton(id) {
      id = id == 0 ? this.screenidbyname : id;
      await axios
        .put(
          `${this.baseurl}updatecontrol`,
          {
            screenId: id,
            oldControl: this.control,
            newControl: this.controlName,
          },
          this.Head
        )
        .then(async (response) => {
          console.log(response);
          alert("Control updated successfully");
          await this.getcontrollers(id, "true");
        })
        .catch((err) => {
          console.log(err);
          alert("Control name already exist");
        });
      this.control = "";
      this.controlName = "";
    },
    async deleteScreen(control, id) {
      this.deletebody.screenId = id;
      if (confirm("Are you sure you want to delete this screen?")) {
        await axios
          .delete(
            `${this.baseurl}deletescreen`,
            {
              data: {
                screen_Id: id,
              },
              headers: {
              Authorization: "Bearer " + store.getters.getToken,
              "Content-Type": "application/json",
              },
            }
            
          )
          .then((response) => {
            console.log(response);
            this.getcontrollers(id, "true");
            this.screenstatus;
            this.refreshSelectedById(id);
          })
          .catch((err) => {
            console.log(err);
          });

        alert("Screen deleted successfully");
        this.closemodall();
      }
    },
    async openModel(isedit) {
      this.screenName = "";
      this.openModal = !this.openModal;
      this.retrieve = false;
      this.showDelete = false;
      this.HeadingChange = true;
      this.isEdit = isedit;
    },
    goBack() {
      this.$router.push(`/administration`);
    }
  },
};
</script>

<style scoped>
/* Create a two-column layout */
@import "../../assets/css/style.css";
@import "../../assets/formpage.css";
@import "./modal.css";
.errormsg {
  color: red;
}
.scrollable {
  overflow-y: scroll;
  height: 250px;
}
.modal__approver__container {
  width: min(38%, 80%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-x: hidden;
}
.modal__wrapperscreen {
  position: fixed;
  background: rgb(0 0 0 / 18%);
  height: 100%;
  width: 100%;
  inset: 0;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: cubic-bezier;
  overflow: hidden;
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item {
  background-color: inherit;
  border: 1px solid #e7eaec;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  position: relative;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.btn-circle {
  color: #ffffff;
  padding: 3px;
  width: 22px;
  height: 22px;
  border-radius: 15px;
  text-align: center;
  font-size: 10px;
  border: 0;
  background-color: #618bd7;
  line-height: 1.428571429;
}
.edit-delete1 {
  cursor: pointer !important;
  font-size: 18px;
  color: #145faf;
}
.position-relative img {
  width: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
</style>