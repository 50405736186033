<template>
  <!-- <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0 align-self-center">
        Unit Test/Code Review Documents
      </h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i
        class="fa fa-times close cursor-pointer"
        @click="$router.push(`/projectList`)"
      ></i>
    </div>
  </div> -->
  <div class="wrapper wrapper-content px-4">
    <div class="row">
      <!-- PROJECT FOLDER -->
      <div class="col-12 col-md-6 px-md-1">
        <div class="ibox mb-2">
          <div class="ibox-title style_2 d-flex justify-content-between px-3">
            <h5 class="align-self-center">{{ projectsName }}</h5>
            <div
              class="position-relative align-self-center"
              v-if="
                designationPrivileges.includes(`Projects Create Folder`) &&
                (isusercanaddfolder === null || isusercanaddfolder === true)
              "
            >
              <img
                class="cursor-pointer"
                src="../../assets/img/create.png"
                alt="create department"
                width="20"
                @click="openModelCreateFolder()"
              />
              <div class="tool-tip">Create Folder</div>
            </div>
          </div>
          <div class="ibox-content scrollable-dept" id="primaryTree">
            <!-- Folder contents -->
            <ul class="tree my-1 pl-0">
              <li>
                <input
                  type="checkbox"
                  :id="selectedfolderid"
                  @change="backtorootfolder(selectedfolderid)"
                />
                <label
                  :class="
                    mainfolderbold == ''
                      ? 'tree_labels tree_label_bold'
                      : 'tree_labels'
                  "
                  @click="backtorootfolder(selectedfolderid)"
                  >Development
                </label>
                <TreeFolder
                  :projectId="projectId"
                  :list="folderList"
                  :boldfolder="mainfolderbold"
                  :openfolderlist="openfolderlist"
                  patid=""
                  :SourcePage="'projectlist'"
                >
                </TreeFolder>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- END OF PROJECT FOLDER -->
      <div class="col-12 col-md-6 px-md-0">
        <div class="ibody" v-if="$store.getters.getselectedFolder">
          <div
            class="ibox-title style_2 d-flex justify-content-between pt-1 pb-0"
          >
            <h5 class="py-0 align-self-center">Documents</h5>
            <div class="align-right mt-1" v-if=" designationPrivileges.includes(`Projects Add Unit Test/Code Review`)">
              <!-- <label class=""> Add Documents </label> -->
              <select
                class="form-control"
                name="account"
                v-model="docValue"
                @change="GoToDocPage()"
              >
                <option value="" disabled selected>-Create New-</option>
                <option value="Unit Test">Unit Test</option>
                <option value="Code Review">Code Review</option>
              </select>
            </div>
          </div>
          <div class="ibox-content scrollable-block" id="primaryDocumentTree">
            <table v-if="documentlist" class="table table-bordered">
              <thead>
                <th>Story Id</th>
                <th>Name</th>
                <th>Date</th>
                <th>Document Name</th>
                <th class="text-center" v-if=" designationPrivileges.includes(`Projects View Document`)">View</th>
                <th class="text-center">Actions</th>
              </thead>
              <tbody>
                <tr
                  v-for="(documents, index) in documentlist"
                  :key="documents.id"
                >
                  <td><span> {{ documents.storyId }}</span></td>
                  <td>
                    <span> {{ documents.conductedBy }}</span>
                  </td>
                  <td><span> {{ filter(documents.endDate) }}</span></td>
                  <td><span> {{ documents.documentName }}</span></td>
                  <td class="text-center" v-if=" designationPrivileges.includes(`Projects View Document`)" >
                    <span :class="{'cursor-pointer':documents.isSigned===true,
                    'disabled-view':documents.isSigned!=true}" @click.prevent="viewDoc(documents.documentId,documents.isSigned,documents.unitTestId,documents.docType)"><img src="../../assets/img/view.png" alt="View document"/></span>
                  </td>
                  <td class="text-center" width="40">
                    <div
                      class="more-option-icon cursor-pointer px-2"
                      v-on:clickout="showDocBlock = false"
                      @click.prevent="
                        sidedocPopup(
                          index,
                          documents.unitTestId,
                          documents.name,
                          'file',
                          event
                        )
                      "
                    >
                      <i class="fa fa-ellipsis-v"> </i>
                    </div>
                  </td>
                  <SidePopUp
                    v-if="showDocBlock && popupid == documents.unitTestId"
                    :index="arrayIndex"
                    :blockid="popupid"
                    :ev="event"
                    :dest="'projectlist'"
                    v-on:clickout="showDocBlock = false"
                  >
                    <div 
                      v-if="documents.isSigned!=true" 
                      @click.prevent="gotoEditPage(documents.unitTestId,documents.docType,false)">Edit</div>
                    <div 
                      v-if="documents.isSigned!=true" 
                      @click.prevent="deleteDoc(documents.unitTestId)">Delete</div>
                    <div 
                      @click.prevent="gotoEditPage(documents.unitTestId,documents.docType,true)">New Version</div>
                  </SidePopUp>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- POPUP -->

      <!-- CREATE PROJECT FOLDER POPUP -->
      <div class="create-folder-modalnew" v-if="createfolderpopup == true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div
              class="modal-header-projectsnew d-flex justify-content-bertween p-3"
            >
              <h5 class="my-0 align-self-center">Create Folder</h5>
              <div class="cursor-pointer" @click="closeModelCreateFolder()">
                <i class="fa fa-times"></i>
              </div>
            </div>
            <div class="modal-body">
              <label class="mb-0" for="designation"
                >Folder Name <sup><i class="fa fa-asterisk required"></i></sup
              ></label>
              <input
              placeholder="Enter folder name"
                type="text"
                class="form-control"
                v-model="createFolder.folderName"
                maxlength="75"
              />
              <span class="errorClass" v-if="folderErrors[0] == true"
                >{{ folderErrorMessages[0] }}
              </span>
              <label class="col-form-label"
                >Document version configuration</label
              >
              <div class="col-md-12 row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label><small>Prefix</small></label>
                    <input
                      maxlength="1"
                      id="password"
                      name="password"
                      type="text"
                      class="form-control required"
                      v-model="prefix"
                      aria-required="true"
                      v-on:keypress="isLetter($event)"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label><small>* Major ver.</small></label>
                    <input
                      maxlength="2"
                      id="password"
                      name="password"
                      type="text"
                      class="form-control required"
                      v-model="majorVerStart"
                      aria-required="true"
                      @keypress="isNumber($event)"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label><small>Minor ver.</small></label>
                    <input
                      maxlength="2"
                      id="password"
                      name="password"
                      type="text"
                      class="form-control required"
                      v-model="minorVerStart"
                      aria-required="true"
                      @keypress="isNumber($event)"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label><small>Minor Max.</small></label>
                    <input
                      maxlength="2"
                      id="password"
                      name="password"
                      type="text"
                      class="form-control required"
                      v-model="minorVermax"
                      @keypress="isNumber($event)"
                      autocomplete="off"
                      aria-required="true"
                    />
                  </div>
                </div>

                <span class="errorClass" v-if="minverError == true"
                  >Minor Max value must be greater than or equal to Minor
                  version value
                </span>
              </div>

              <div class="form-group pt-2">
                <button
                  type="button"
                  @click.prevent="createfolder()"
                  class="btn btn-primary btn-xs px-4 float-right"
                >
                  Save
                </button>
                <strong> Document version :</strong>
                {{ prefix }} {{ majorVerStart
                }}<span v-if="majorVerStart && minorVerStart != ''">.</span
                >{{ minorVerStart }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <fileFormatPopUp
        v-if="$store.getters.getformatvalue"
        :filetype="$store.getters.getfileType"
        :blocknames="$store.getters.getfileName"
        @closemodal="$store.dispatch('setformatvalue', false)"
      >
      </fileFormatPopUp>
      <foldersendpopup
        v-if="$store.getters.getsendfolder"
        :filetype="$store.getters.getfileType"
        :blockname="$store.getters.getfileName"
        @closemodal="$store.dispatch('setsendfile', false)"
      >
      </foldersendpopup>
      <filesendpopup
        v-if="$store.getters.getsendfile"
        :filetype="$store.getters.getfileType"
        :blockname="$store.getters.getfileName"
        :folderid="$store.getters.getselectedFolder"
        :department="$store.getters.getDepartment"
        :docid="selecteddocid"
        @closemodal="$store.dispatch('setsendfile', false)"
      >
      </filesendpopup>
      <folderEditPopUp
        v-if="$store.getters.geteditvalue"
        :filetype="$store.getters.getfileType"
        :blocknamess="$store.getters.getfileName"
        @getFolder="getRootFolders(this.folderList)"
        @closemodal="$store.dispatch('seteditvalue', false)"
      >
      </folderEditPopUp>
      <!-- END OF CREATE FOLDER POPUP -->

      <!-- END OF POPUP -->
    </div>
  </div>
  <FileFolderProjectPopup
    v-if="showProjectPermPopup"
    @closemodal="showProjectPermPopup = false"
    :list="popupid"
    :source="sourceType"
    :projectId="id"
  >
  </FileFolderProjectPopup>

  <fileSharePopup
    v-if="showProjectSharePopup"
    @closemodal="showProjectSharePopup = false"
    :list="popupid"
    :source="sourceType"
    :projectId="id"
  >
  </fileSharePopup>
  <!-- Doc Edit Popup -->
  <DocEditProjectPopup
    v-if="editDocPopup"
    @closemodal="editDocPopup = false"
    :folderId="docId"
    :documentId="popupid"
    @reloadDocs="$forceUpdate(getdocuments(docId))"
  >
  </DocEditProjectPopup>
  <!-- ends -->
  <NewDocVersion
    v-if="newdocversionPopup"
    @editdocactive="newversionuploadactivity()"
    :department="projectName"
    :folderid="$store.getters.getselectedFolder"
    :docId="popupid"
  >
  </NewDocVersion>
  <foldershare_popup v-if="showSharePopup" @closemodal="showSharePopup = false">
  </foldershare_popup>
</template>
<script src="./documentList.js"></script>
<style scoped>
.modal-header1 {
  border-bottom: 1px solid #dee2e6;
}
.fa-asterisk {
  color: #e56363 !important;
  font-size: 6px;
}
.img {
  vertical-align: middle;
  border-style: none;
}

.modal-header-projectsnew {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.errorClass {
  color: red;
  font-size: 13px;
}

.create-folder-modalnew {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.scrollable-dept {
  overflow-y: auto;
  height: 450px;
}
.title-class1 {
  background-color: #618bd7;
  color: #fff;
}

.ibox-content {
  overflow-x: auto !important;
}

.max-height {
  max-height: 500px;
  overflow-y: auto;
}
.tree_label_bold {
  font-weight: bold;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.modal-body td {
  font-size: 12px !important;
}
.table.role tbody tr td {
  vertical-align: middle;
  height: 30px;
}

.scrollable-block {
  overflow-y: auto;
  height: 450px;
}

.fordiv {
  margin-top: 2px;
}
.project-list table tr td {
  border-top: none;
  border-bottom: 1px solid #e7eaec;
  vertical-align: middle;
}
.more-option-icon:hover {
  background-color: #ededed;
}

.modal-close {
  font-size: 15px;
}
.modal-width {
  width: min(400px, 90%);
}

.disabled-view{
  opacity:0.7
}
</style>