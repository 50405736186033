/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import store from "../../store/index";
import SidePopUp from "../side_pop_up/side_pop_up.vue";
import Multiselect from "@vueform/multiselect"
export default {
  name: "foldersendpopup",
  components: {
    SidePopUp,
    Multiselect
  },
  props: {
    filetype: {
      type: String,
      default: ""
    },
    blockname: {
      type: String,
    },
    folderid: {
      type: String,
      default: ""
    },
    department: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      validationmsgs: [false, false],
      folderpermissions: [],
      userfilesendlist: [],
      userName: "",
      newUserList: [],
      newUserListtest: [],
      baseFileurl: ``,
      baseurl: `${process.env.VUE_APP_Service_URL}document/folderfilepermission/`,
      baseapi: `${process.env.VUE_APP_Service_URL}configuration/designation/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      userList: [],
      designationList: [],
      selectedDesignation: [],
      selectedUser: [],
      selectedPermission: [],
      permissionList: [],
      UserpermissionList: [],
      userPermissiontest: [
        {
          userId: "string",
          emailId: "",
          name: "string",
          permission: [
          ]
        },
      ],
      userPermission: [
        {
          userId: "string",
          emailId: "",
          name: "string",
          permission: [
          ]
        },
      ],
      newuserslist: [],
      desgId: "",
    }
  },
  async mounted() {
    console.log("Name is", this.blockname)
    document.body.style.overflowY = "hidden";
    this.idtoken = store.getters.getToken;
    this.getDesignationUsers();
    await this.getalldesignation();
    await this.getfolderpermissions();
  },
  unmounted() {
    document.body.style.overflowY = "auto"
  },
  methods: {

    addUser() {
      this.selectedUser.forEach((eachuser) => {
        this.userList.forEach((sortuserlist) => {
          if (sortuserlist.emailId == eachuser) {
            this.desgId = this.selectedDesignation
            let userdataobj = {
              userId: sortuserlist.userId,
              emailId: sortuserlist.emailId,
              name: sortuserlist.userName,
              permission: this.folderpermissions
            }
            if (!this.newuserslist.includes(userdataobj)) {

              this.newuserslist.push(userdataobj);
            }
          }
        })


      })

    },
    async validatedocument() {
      this.validationmsgs = [false, false];
      if (this.selectedUser.length == 0) {
        this.validationmsgs[0] = true;
      }
      if (this.folderpermissions.length == 0) {
        this.validationmsgs[1] = true;
      }
    },
    async sendfile() {
      this.addUser();
      this.validatedocument();
      let idtoken = store.getters.getToken;
      if (!this.validationmsgs.includes(true)) {
        await axios
          .post(`${this.mainapi}document/folder/share`,
            {
              folderId: store.getters.getselectedFolder,
              type: this.filetype,
              department: [
                // "HR Manager"
                store.getters.getDepartment
              ],
              userPermission: this.newuserslist
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }

          )
          .then((res) => {
            this.userList = res.data;
            console.log("userList......", this.userList);
            if (this.userList != null) {
              this.selectedUser = [];
              this.selectedPermission = [];
            }
            store.dispatch('setsendfolder', false);
            alert("Folder shared successfully");
          })
          .catch((err) => {
            console.log(err);
            alert("Failed to send folder");
          });
      }


    },
    async getalldesignation() {
      let idtoken = store.getters.getToken;
      console.log("inside getalldesignation")
      await axios
        .get(`${this.baseapi}search?PageSize=1000`,
        {
          headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
          },
      })
        .then((res) => {
          this.designationList = res.data.entities;
          console.log("designationList......", this.designationList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDesignationUsers() {
      const idtoken = store.getters.getToken;
      this.userList = [];
      console.log("inside getDesignationUsers")
      await axios
        .post(`${this.baseurl}designationusers`, this.selectedDesignation,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
        },
        })
        .then((res) => {
          this.userList = res.data;
          console.log("userList......", this.userList);
          if (this.userList != null) {
            this.selectedUser = [];
            this.selectedPermission = [];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getallUsers() {
      let idtoken = store.getters.getToken;
      console.log("inside getallUsers")
      await axios
        .post(`${this.baseurl}designationusers`, this.selectedDesignation,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
        },
        })
        .then((res) => {
          this.userList = res.data;
          console.log("userList......", this.userList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getfolderpermissions() {
      let idtoken = store.getters.getToken;
      console.log("inside folder permission")
      await axios
        .get(`${this.baseurl}allfolderpermission`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
        }
        })
        .then((res) => {
          this.permissionList = res.data;
          console.log("permissionList......", this.permissionList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getfilepermissions() {
      let idtoken = store.getters.getToken;
      console.log("inside file permission")
      await axios
        .get(`${this.baseurl}allfilepermission`, 
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
        }
        })
        .then((res) => {
          this.permissionList = res.data;
          console.log("permissionList......", this.permissionList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async SavePermission(id,type) {
      let idtoken = store.getters.getToken;
      console.log("in Save")
      console.log("selectedUser", this.selectedUser)
      console.log("selectedPermission", this.selectedPermission)
      if (type == 'folder') {
        console.log("selected folderid", id)
          await axios
            .post(`${this.baseurl}savefolderpermission`,
              {
                folderId: id,
                emailId: this.selectedUser,
                permission: this.selectedPermission
              },
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
              }
              }
            )
            .then((res) => {
              alert("Folder permissions saved successfully")
              console.log("Save Result", res);
            })
            .catch((err) => {
              console.log(err);
            });
      }
      else {
        console.log("selected fileid", id)
          await axios
            .post(`${this.baseurl}savefilepermission`,
              {
                documentId: id,
                emailId: this.selectedUser,
                permission: this.selectedPermission
              },
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
              }
              }
            )
            .then((res) => {
              alert("File permissions saved successfully")
              console.log("Save Result", res);
            })
            .catch((err) => {
              console.log(err);
            });
      }
    },
    async getuserpermission() {
      let idtoken = store.getters.getToken;
      console.log("inside getuserpermission")
      const folderId = store.getters.getselectedid
      if (this.filetype == 'folder') {
        console.log("entered folder")
        await axios
        .post(`${this.baseurl}getfolderpermissions?FolderId=${folderId}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
        }
        })
        .then((res) => {
          this.UserpermissionList = res.data;
          console.log("UserpermissionList......", this.UserpermissionList);
        })
        .catch((err) => {
          console.log(err);
        });
      }
      else {
        await axios
        .post(`${this.baseurl}getfilepermission?FolderId=${folderId}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
        }
        })
          .then((res) => {
            this.UserpermissionList = res.data;
            console.log("UserpermissionList......", this.UserpermissionList);
          })
          .catch((err) => {
            console.log(err);
          });
      }

    },

    async getExistingData() {
      await this.getDesignationUsers();
      await this.getuserpermission();
      if (this.UserpermissionList != null) {
        this.selectedUser = this.UserpermissionList.emailId
        this.selectedPermission = this.UserpermissionList.permission
      }
    },
    close() {
      store.dispatch('setsendfolder', false);
    }
  }
}
