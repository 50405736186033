/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import store from "../../store/index";
import SidePopUp from "../side_pop_up/side_pop_up.vue"
import PermissionPopUp from "../permission_popup/permission_popup.vue"
import foldersendpopup from "../foldersend_popup/foldersend_popup.vue"
import File_FolderPopup from "../File_FolderPopup/File_FolderPopup.vue"
import FileFolderProjectPopup from "../FileFolderProjectPopup/FileFolderProjectPopup.vue"
import fileFormatPopUp from "../fileformatpopup/fileformatpopup.vue"
import MoveFolder from "../move_folder/move_folder.vue"
// import moment from "moment";
// import { ref } from 'vue'
export default {
  components: {
    FileFolderProjectPopup,
    fileFormatPopUp,
    SidePopUp,
    PermissionPopUp,
    foldersendpopup,
    File_FolderPopup,
    MoveFolder
  },
  props: {
    projectId: {
      type: String,
      default: "",
    },
    idprop: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => [],
    },
    boldfolder: {
      type: String,
      default: "",
    },
    patid: {
      type: String,
      default: "",
    },
    openfolderlist: {
      type: String,
      default: "",
    },
    treeid:{
      type:String,
      default:"doc"
    }
  },
  data() {
    return {
      folderbold:"",
      openfolder:"",
      currentProjectId: "",
      sourceType: "",
      showBlock: false,
      showPermBlock: false,
      moveBlock:false,
      popupid: "",
      folderswitch: {},
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      patientid: "",
      listdata: [],
      firstclassname: this.patid,
      folderbyid: "",
      showDetails: false,
      arrayfolders: [],
      deptBlockname: "",
      departments: {},
      foldertreelist: {},
      currentselectedfolder: "",
      showPermPopup: false,
      showProjectPermPopup: false,
      leftValue: 0,
      topValue: 0,
      movedFolder:""
    };
  },
  watch: {
    "$store.getters.getselectedFolder": function (val) {
      this.currentselectedfolder = val;
      console.log("Selected folder val", val, this.boldfolder)
    },
    "$store.getters.getfolderreload": function (val) {
      console.log("reload", val)
      this.currentselectedfolder = val;
      this.reloadallfolders();
    },
    "$store.getters.getselectedFoldermove": function (val) {
      this.currentselectedfolder = val;
      this.folderbold=val
      this.reloadallfolders();
    },
  },
  async mounted() {
    this.idtoken = store.getters.getToken;
    this.currentselectedfolder = this.folderbold;
    store.dispatch("selectFoldermove","");
  },
  methods: {
    move(id){
      this.moveBlock=!this.moveBlock
      this.movedFolder=id
    },
    async reloadallfolders() {
      await this.list.forEach(element => {
        if (this.folderswitch[element.id]) {
          this.refreshfolder(element.id, element.name)
        }
      });
    },
    async refreshfolder(id, name) {
      console.log("in refresh folder")
      this.foldertreelist[name] = [];
      await axios
        .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${id}`, {

          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },

        })
        .then((res) => {
          this.foldertreelist[name] = res.data;
        })
        .catch((err) => {
          console.log(err.message)
        })
    },
    async getfolder(id, name) {
      if (this.folderswitch[id]) {
        this.folderswitch[id] = !this.folderswitch[id];
      }
      else {
        this.folderswitch[id] = true;
      }
      this.foldertreelist[id]=[]
      console.log("Folder switchid",this.folderswitch[id])
      await axios
        .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${id}`, {

          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },

        })
        .then((res) => {
          this.foldertreelist[id] = res.data;          
        })
        .catch((err) => {
          console.log(err.message)
        })
        if (name) {
          store.dispatch("selectFoldermove",id);
         
        }
      
    this.reloadallfolders();
    },

    async RefreshDocument() {
      await store.dispatch("setDocumentRefresh", true);
      window.dispatchEvent(
        new CustomEvent("folderArchived", {
          detail: {
            storage: store.getters.getDocumentRefresh,
          },
        })
      );
    }
  },
};