/* eslint-disable */
import auth0 from "auth0-js";
import jwt_decode from "jwt-decode";
// import store from "../../store/index";
import { useStore } from "vuex";
import { watch, ref, onMounted, onUnmounted} from 'vue'
import { useRouter } from "vue-router";
import axios from "axios";
import { mounted } from "../../common/encryption";
import { encryptUser } from "../../common/encryption"
 

var webAuth = new auth0.WebAuth({
	domain: process.env.VUE_APP_Domain,
	clientID: process.env.VUE_APP_Client_ID,
	scope: "openid profile email offline_access",
	responseType: "token id_token",
	useRefreshTokens: true,
  });

export default {
	name: "login",
	setup() {
		const store = useStore();
		const router = useRouter();
		const username = ref("");
		const password = ref("");
		const errr = ref("");
		const docAssigndeUser=ref("");
		const urlstring=ref("")
		let slitstring = ref([]);
		const errorBlock = ref(false)
		const authResult=ref("");
		let usertype = ref("");
		const currentYear =ref(new Date().getFullYear())
		const inputType=ref("password")
		const visibleOn=ref(false);
		let baseurl = ref(process.env.VUE_APP_Service_URL);
		let eachdata = ref({});
	onMounted(async()=>{
		let pagewrapper = document.querySelector("#page-wrapper");
		pagewrapper.style.width = "100%";
		pagewrapper.style.paddingLeft= "0px";
		pagewrapper.style.paddingRight = "0px";
		await store.dispatch("setNavbar", false);
		await store.dispatch("setAuthorize",false)
	})
	onUnmounted(async()=>{
		let pagewrapper = document.querySelector("#page-wrapper");
		pagewrapper.style.width = "";
		pagewrapper.style.paddingLeft= "15px";
		pagewrapper.style.paddingRight = "15px";
		await store.dispatch("setNavbar", true);
		await store.dispatch("setAuthorize",true);
	})
	async function passwordVisible()
	{
		visibleOn.value=!visibleOn.value
		if(visibleOn.value==true){
			inputType.value="text"
		}
		else inputType.value="password"
	} 
	async function Enablemenu() {
		await store.dispatch("setNavbar", true);
	}	
	
	async function login() {
		errorBlock.value=false
		const encryptedPassword = await encryptUser( password.value.trim());
		await axios
        .post(
          `${baseurl.value}account-core/user/userauthorize`,{
            "userEmail": username.value,
            "password": encryptedPassword
          },
        )
        .then((res) => {
            authResult.value =res.data
			store.dispatch("setUserEmail",username.value.toLowerCase());
			store.dispatch("setToken",authResult.value.idToken)
			var decoded = jwt_decode(authResult.value.idToken);
			store.dispatch("setOrganizationId",decoded.organization_id)				
			 setuserrole(authResult.value);
			
        }) 
        .catch((err) => {	 
          if(err.response.data.errors){
            if (err.response.data.errors.UserName && err.response.data.errors.UserName.length !== 0) {
              errr.value = "The username field is required";
            } else if (err.response.data.errors.UserEmail && err.response.data.errors.UserEmail.length !== 0) {
              errr.value = "The email field is required";
            } else if (err.response.data.errors.Password && err.response.data.errors.Password.length !== 0) {
              errr.value = "The password field is required";
            
			}}
			else{
            if(err.response.data === "The username is not associated with this account.")
            errr.value = "The username is not associated with this account."
            else if (err.response.data === "Invalid email or password."){
				errorBlock.value=true
            }
            else if(err.response.data== "Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it.")
            {
              alert("Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it.")
            }
            else if (err.response.data == "Your password has been expired. Please reset your password.") {
                      alert("Your password has been expired. Please reset your password.")
                      router.push(`/ResetPassword/${username.value}/reset/Webapp`);
                    }
          }
        });
	  }	 	
	  async function setuserrole(authresult) {
		console.log(authresult);
		const idtoken = authresult.idToken;
		const userid = (jwt_decode(idtoken).sub).trim();
		await axios
		//.get(`https://localhost:5001/user/getuserwithauthid?guid=`+userid, {
		.get(`${baseurl.value}account-core/user/getuserwithauthid?guid=`+userid, {
			headers: {
			  Authorization: "Bearer " + idtoken,
			  "Content-Type": "application/json",
			},
		  })
		  .then(async (res) => {
			store.dispatch("setroles", res.data.designation);
			usertype.value = res.data.userType;
			await getrollprevilage(res.data.designation, authresult);
			if(usertype.value == "Internal"){
				router.push(`/dashboard`);
				await store.dispatch("setIsexternaluser", "False")
				// router.push(`/shairedfolder`);
			 }
			 else{
				// router.push(`/generalFiles`);
				router.push(`/sharedfolder`);
				await store.dispatch("setIsexternaluser", "True")
			 }
		  })
		  .catch((err) => {
			console.log(err);
		  });
	  } 
	  async function externalorinternal(id, authresult){
		const idtoken = authresult.idToken;
		await axios
			.get(`${baseurl.value}account-core/user/get?id=` + id, {
				headers: {
					Authorization: "Bearer " + idtoken,
					"Content-Type": "application/json",
				},
			})
			.then(async (res) => {
				console.log("userid is",res.data);
				usertype.value = res.data.userType;
			})
			.catch((err) => {
				console.log(err);
			});
	}
	  async function getrollprevilage(designation, authresult) {
		const idtoken = authresult.idToken;
		// let array = (store.getters.getRolesData).split(',');
		
		  console.log("rolldata is");
		 // console.log(eachrole);
		  await axios
			.get(`${baseurl.value}account-core/roles/getscreendesignationprivilegebyid?guid=${designation}`, {
			  headers: {
				Authorization: "Bearer " + idtoken,
				"Content-Type": "application/json",
			  },
			})
			.then(async (res) => {
			  if (res.data != "No Privilege found") {
				// store.dispatch("setrolesprivileges", res.data.screens);
				await setrolesprivileges(res.data.screens, authresult);
			  }
			  else {
				//await gotopages(authresult);
			  }
			})
			.catch((err) => {
			  console.log(err);
			});
		
  
	  }  
	  async function setrolesprivileges(data, authresult) {
		let privilegevalues = [];
		await (data).forEach((screen) => {
		  for (let j = 0; j < screen.controls.length; j++) {
			if (!privilegevalues.includes(screen.name + " " + screen.controls[j])) {
			  privilegevalues.push(screen.name + " " + screen.controls[j]);
			}
		  }
		});
		let userprivilegestring = privilegevalues.join();
		await store.dispatch("setrolesprivileges", `test test,${userprivilegestring}`);
		//await gotopages(authresult);
	  }
	//   async function gotopages(authresult) {
	// 	console.log("inside gotopages function");
	// 	const idtoken = authresult.idToken;
	// 	const userid = jwt_decode(idtoken).sub;
	// 	await axios
	// 	  .get(`${baseurl.value}/account-core/settings/getdashboard?UserID=${userid}`, {
	// 		headers: {
	// 		  Authorization: "Bearer " + idtoken,
	// 		  "Content-Type": "application/json",
	// 		},
	// 	  })
	// 	  .then((res) => {
	// 		console.log("default dashboard is ");
	// 		console.log(res.data.defaultDashBoard);
	// 		store.dispatch("loginuser", authresult);
	// 		if (res.data.defaultDashBoard && typeof res.data.defaultDashBoard != "undefined") {
	// 		  store.dispatch("setdefaultdashboard", res.data.defaultDashBoard);
	// 		  router.push(`/${res.data.defaultDashBoard}`);
	// 		}
	// 		else {
	// 		  let roles = store.getters.getRolesData;
	// 		if(roles[0] == "bfc0a935-0441-405b-92a0-08d9af2e7771")
	// 		  {
	// 		store.dispatch("setdefaultdashboard", "nursesummary");
	// 		router.push(`/nursesummary`);
	// 	  }
	// 	  else{
	// 		store.dispatch("setdefaultdashboard", "home");
	// 		router.push(`/home`);
	// 	  }
	// 		}
  
	// 	  })
	// 	  .catch((err) => {
	// 		console.log(err);
	// 		let roles = store.getters.getRolesData;
	// 	 if(roles[0] == "bfc0a935-0441-405b-92a0-08d9af2e7771")
	// 		  {
	// 		store.dispatch("setdefaultdashboard", "nursesummary");
	// 		router.push(`/nursesummary`);
	// 	  }
	// 	  else{
	// 		store.dispatch("setdefaultdashboard", "home");
	// 		router.push(`/home`);
	// 	  }
	// 	  });
	//   }
	  return {
		username,
		password,
		errorBlock,
		errr,
		visibleOn,
		inputType,
		authResult,
		currentYear,
		Enablemenu,
		login,
		passwordVisible,
		
	};

},

	
}