/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import router from "@/router";
import moment from "moment";
export default {
  props: {
    id: { type: String, default: "" },
  },
  components: {
    Multiselect
  },
  data() {
    return {
      showpopup: false,
      showpopupDoc: false,
      prevRoute: null,
      setpreviouspath: "",
      versionfrom: "",
      forEdit: false,
      errorMessage: [false, false, false, false, false, false],
      baseUrl: `${process.env.VUE_APP_Service_URL}account-core/user/`,
      desurl: `${process.env.VUE_APP_Service_URL}document/folderfilepermission/`,
      baseapi: `${process.env.VUE_APP_Service_URL}document/documentquestion/`,
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/`,
      docurl: `${process.env.VUE_APP_Service_URL}document/document/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      config: {
        testName: "",
        documentId: "",
        questionNo: "",
        percentage: "",
        email: [],
        timeinHours: "",
        timeinMins: "",
        endDate: "",
        user: "",
        supervisor:{},
      },
      updateConfigs: {
        documentId: "",
        questionNo: "",
        percentage: "",
        email: [],
        timeinHours: "",
        timeinMins: "",
        endDate: "",
        supervisor:[]
      },
      previousPath: "",
      dropdownheading: "",
      roleList: [],
      designationList: [],
      designation: [],
      userList: [],
      allUserList: [],
      assignuser: {
        documentId: "",
        userEmails: []
      },
      assignEmail: [],
      validationmsgs: false,
      designationPrivileges: "",
      sourceRoute: "",
      SaveButtonText: "",
      IsUpdate: false,
      retrieve: false,
      showSave: false,
      checkoption: false,
      showadd: false,
      showAnswer: false,
      duplicate: false,
      errors: [true, { option: [] }, true],
      Head: {},
      baseUrlQuestion: `${process.env.VUE_APP_Service_URL}document/documentquestion/`,
      CreateUpdateCommnand: {
        configId: "",
        question: "",
        option: [],
        answer: "",
      },
      UserDto: {
        id: "",
        question: "",
        answer: "",
        option: [],
      },
      option: [],
      searchField: {
        name: "",
        createdat: ""
      },
      questionlist: [],
      currentPage: 1,
      pageindex: 1,
      pagesize: 10,
      totalPage: 1,
      SortProperty: "question",
      SortOrder: false,
      SortOrderInt: 0,
      designationPrivileges: "",
      sourcePage: "",
      sourceRoute: "",
      configId: "",
      doctypelist: "",
      documentList: "",
      documentType: [],
      configDocumentList: "",
      selectedDesignation: [],
      typeValue: [],
      docDetails: [],
      isConfigued :false,
      showAddDoc : false,
      supervisorList : [],
      finalUserList : [],
      isEditable: false,
      userExamList:[],
      searchField: {
        employeename: "",
        testresult: "",
        fromdate:"",
        todate:""
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  async mounted() {
    // to be changed..
    this.ListUserExam('Employee')
    this.configId =this.id;
    if(this.id != 0){
      this.isConfigued = true;
    }
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    this.SaveButtonText = "Save";
    this.IsUpdate = false;
    this.showadd = true;
    if (this.id != 0) {
      console.log("in update ")
      await this.GetAndSetData(this.id);
      this.SaveButtonText = "Update";
      this.IsUpdate = true;
      this.retrieve = true;
    }
    store.dispatch("setdocumenttodepartment", true);
    this.previousPath = store.getters.getDocViewSource
    this.designationPrivileges = store.getters.getRolesprivilegeData;
    this.getAllUsers();
    console.log("path is ", store.getters.getDocViewSource);
    this.getalldesignation();
    if (this.prevRoute != null) {
      if (this.prevRoute.path != "/") {
        localStorage.setItem("patvalue", this.prevRoute.path);
      }
      this.setpreviouspath = localStorage.getItem("patvalue");
    }

    this.GetAndSetData();
    this.configDocList();
  },

  methods: {
    async ViewTest(configId,usersExamId){
      this.$router.push("/myQuestion/" + configId + "/" + usersExamId);
    },
    disableEditing() {
        this.isEditable = false;
      },
    async getalldesignation() {
      await axios
        .get(`${this.baseurl}designation/search?status=active&PageSize=10000&SortProperty=displayName`,
          this.Head)
        .then((res) => {
          this.designationList = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
        });

      if (this.previousPath == "ProjectSummary") {
        console.log("in project role get fn ")
        let projId = store.getters.getSelectedProject;
        console.log("in project role get fn ", projId, this.baseApi)
        await axios
          .get(
            `${this.baseurl}projectrole/getall?projectId=${projId}`,
            this.Head
          )
          .then((res) => {
            this.roleList = res.data;
          })
          .catch((err) => {
            console.log(err);
            this.roleList = []
          })
      }
    },
    async getDesignationUsers(designation) {
      const idtoken = store.getters.getToken
      await axios
        .post(`${this.desurl}designationusers`, designation, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.userList = res.data;
          this.formatUser(this.allUserList,'users');
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAllUsers() {
      await axios
        .get(`${this.baseUrl}getlist`, this.Head
        ).then((res) => {
          console.log("user list is", res.data.entities)
          this.allUserList = res.data.entities;
          this.formatUser(this.allUserList,'supervisor');
        }).catch((err) => {
          console.log(err);
        });
    },
    async formatUser(users,type){
      let tempUser = {}
      users.forEach((user) => {
        tempUser = {
          emailId: user.primaryEmail,
          name: user.firstName + " " + user.lastName,
          userId: user.id
        }
        if(type == 'supervisor'){
          this.supervisorList.push(tempUser);
        }
        else if(type == 'users'){
          this.finalUserList.push(tempUser);
        }
      });
      console.log("Supervisor final list", this.finalUserList)
    },
    async checksaveUpdate() {
      console.log("In checksaveUpdate method.")
      if (this.configId == null) {
        
        console.log("Email is................", this.config.email);
        let newArray = []
        let newObj = {
          emailId: "",
          name: "",
          userId: ""
        }
        this.config.email.filter((item, index) => {
          newArray[index] = newObj
          newArray[index].emailId = item.emailId
          newArray[index].name = item.name
          newArray[index].userId = item.userId
        })
        this.config.email = newArray
        console.log("Obj is", newArray)
        console.log("In createconfig method.")
        await this.createconfig()

      }
      else {
        console.log("In updateConfig method.")
        await this.updateConfig()
      }
    },
    async validatequestion() {
      this.validationmsgs = false;


      if (this.config.endDate != null) {
        let today = new Date();
        let selected = new Date(this.config.endDate);
        selected.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        if (selected < today) {
          this.validationmsgs = true;
        }
      }
    },
    async validatefield() {
      this.errorMessage = [false, false, false, false, false, false];

      if (this.config.questionNo == "" || this.config.questionNo == null) {
        this.errorMessage[0] = true;
      }
      if (this.config.percentage == "" || this.config.percentage == null) {
        this.errorMessage[1] = true;
      }
      if (this.config.questionNo % 10 != 0 || this.config.questionNo.charAt(0) == '0' ||
        (this.config.questionNo.startsWith('0'))) {
        this.errorMessage[2] = true;
      }
      if (this.config.percentage % 10 != 0 || this.config.percentage.charAt(0) == '0') {
        this.errorMessage[3] = true;
      }
      if (this.config.percentage > 100) {
        this.errorMessage[4] = true;
      }
    },
    async createconfig() {

      await this.validatequestion();
      await this.validatefield();
      let timehrs = 0;
      let timemins = 0;
      if (this.config.timeinHours != "" && this.config.timeinHours != null) {
        timehrs = parseInt(this.config.timeinHours)
      }
      else { this.config.timeinHours = null }
      if (this.config.timeinMins != "" && this.config.timeinMins != null) {
        timemins = parseInt(this.config.timeinMins)
      }
      else { this.config.timeinMins = null }

      //if (this.config.timeinHours < 1 && this.config.timeinMins < 1 )
      if (!((this.config.timeinHours === null || this.config.timeinHours === undefined) && (this.config.timeinMins === null || this.config.timeinMins === undefined))) {
        if ((timehrs + timemins) == 0) {
          console.log(timemins, "ADDITION ONE")
          console.log(timehrs, "ADDITION one")
          console.log(this.config.timeinHours, "ADD")
          console.log(this.config.timeinMins, "AD")
          alert('Please enter valid time');

        }
        else if (timemins < 0 || timemins > 59) {
          alert('Please enter valid time');
        }
        else {

          await this.createConfig();
        }
      }

      else {
        if (this.validationmsgs == false) {
          if (!this.errorMessage.includes(true)) {
            await this.createConfig();
          }
        }
      }

    },
    async questionslist() {
        
      await axios
        .get(
          `${this.baseUrlQuestion}search?configId=${this.id}&pageindex=${this.pageindex}&pagesize=${this.pagesize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrderInt}`,
          this.Head
        )
        .then((res) => {
          this.questionlist = res.data.entities;
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.questionlist = []
          alert("No data found!")
        })
    },
    async createConfig() {
      console.log("In Sub method Create config");
      if (this.validationmsgs == false) {
        await axios
          .post(this.baseapi + "onlinetestconfig",
            {
              "questionNo": this.config.questionNo,
              "percentage": this.config.percentage,
              "email": this.config.email,
              "supervisor": this.config.supervisor,
              "timeinHours": this.config.timeinHours,
              "timeinMins": this.config.timeinMins,
              "endDate": this.config.endDate,
              "examName": this.config.testName,
              "documents": null
            },
            this.Head,
          )
          .then((res) => {
            this.isConfigued = true;
            this.configId = res.data
            console.log(res);
            alert("Exam configured successfully");
            this.assignUser();
            this.questionslist();
            this.configDocList();
            this.GetAndSetData(this.configId);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async assignUser() {
      this.assignuser.documentId = this.id;
      this.assignuser.userEmails = this.config.email;
      console.log(" assign user is ", this.assignuser);
      console.log("users are", this.config.email);
      await axios.post(
        `${this.baseapi}assignusers`,
        this.assignuser, this.Head
      ).then((res) => {
        console.log("User assigned", res);

      }).catch((err => console.log("error in assigning user", err)))
    },
    async GetAndSetData() {
      await axios
        .get(this.baseapi + "getconfigbyid?ConfigId=" + this.configId, this.Head)
        .then((res) => {
          this.config = res.data;
          this.assignEmail = res.data.email;

        })
        .catch((err) => {
          console.log(err);
        });
      console.log(this.Config, "GETLIST");
    },
    async updateConfig() {
      this.assignEmail = this.assignEmail || [];
      this.assignEmail.forEach(email => {
        if (!this.config.email.some(data => data === email)) {
          this.config.email.push(email);
        }
      });

      // this.updateConfigs.documentId = this.id;
      this.updateConfigs.questionNo = this.config.questionNo;
      this.updateConfigs.percentage = this.config.percentage;
      this.updateConfigs.email = this.config.email;
      this.updateConfigs.timeinHours = this.config.timeinHours;
      this.updateConfigs.timeinMins = this.config.timeinMins;
      this.updateConfigs.endDate = this.config.endDate;
      await this.validatequestion();
      await this.validatefield();

      let timehrs = 0;
      let timemins = 0;
      if (this.config.timeinHours != "" && this.config.timeinHours != null) {
        timehrs = parseInt(this.config.timeinHours)
      }
      else { this.config.timeinHours = null }
      if (this.config.timeinMins != "" && this.config.timeinMins != null) {
        timemins = parseInt(this.config.timeinMins)
      }
      else { this.config.timeinMins = null }
      if (!((this.config.timeinHours === null || this.config.timeinHours === undefined) && (this.config.timeinMins === null || this.config.timeinMins === undefined))) {
        if ((timehrs + timemins) == 0) {
          alert('Please enter valid time');
        }
        else if (timemins < 0 || timemins > 59) {
          alert('Please enter valid time');
        }
        else {
          this.updateCONfig();
        }
      }
      else {
        if (!this.errorMessage.includes(true)) {
          if (this.validationmsgs == false) {
            this.updateCONfig();
          }
        }
      }

    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 || charCode == 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    async onChange(event) {
      console.log("event", event)
      console.log("select", this.selectedDesignation)
      this.config.email = [];
      this.selectedDesignation = [];
      await event.forEach((data) => {
        console.log("data", data)
        this.selectedDesignation.push(data.id)

      });
      console.log("selectedDesignation", this.selectedDesignation)
      this.getDesignationUsers(this.selectedDesignation);
    },
    async addTime() {
      let totalTime = parseInt(this.updateConfigs.TimeInHours) + parseInt(this.updateConfigs.TimeInMins) / 60;
      if (totalTime === 0 || totalTime === 0.0) {
        alert("Please enter a valid time");
      }
    },
    async DocChangeList(event) {
      console.log("on doc value change fn.",event)
      this.docDetails = [];
      event.forEach((list) => {
        this.docDetails.push({
          "documentId": list.id,
          "title": list.name,
          "version":list[0].documentVersions[0].version,//need to update latest version
          "date": list[0].createdAt
        })
      })
    },
    async updateCONfig() {
      console.log("Config.emai; is ", this.updateConfigs.email,this.config)
      if (this.validationmsgs == false) {
        await axios
          //.put("https://localhost:5001/documentquestion/onlinetest?Id="+ this.config.id,
          .put(this.baseapi + "onlinetest?Id=" + this.configId,

            {
              "questionNo": this.updateConfigs.questionNo,
              "percentage": this.updateConfigs.percentage,
              "email": this.updateConfigs.email,
              "timeinHours": this.updateConfigs.timeinHours,
              "timeinMins": this.updateConfigs.timeinMins,
              "endDate": this.updateConfigs.endDate,
              "examName": this.updateConfigs.examName,
              "supervisor": this.config.supervisor,
              "documents": this.docDetails
            },
            this.Head,
          )
          .then((res) => {
            console.log(res);
            alert("Configuration updated successfully");
            this.configDocList();
            this.onClosedDoc();
          })
          .catch((err) => {
            console.log(err);
          });
        store.dispatch("reloadfolder", true);
      }
    },
    showOption() {
      if (this.UserDto.question == "") {
        this.errors[0] = false;
      } else {
        this.retrieve = true;
        this.errors[0] = true;
        this.showadd = false;
      }
    },
    setOptionValue() {
      if (this.checkoption != true) {
        // alert("Option not selected");
        this.UserDto.answer = "";
      } else {
        if (this.checkoption == true) {
          if (this.UserDto.option == "") {
            alert("Option not selected");
            this.checkoption = false
          } else {
            const newexists = this.option.find(
              (optionlist) => optionlist === this.UserDto.option
            );
            if (!newexists) {
              this.UserDto.answer = this.UserDto.option;
              this.showSave = false;
            } else if (newexists == this.UserDto.option) {
              alert("Option already exist");
              this.checkoption = false;
            }
          }
        }
      }
    },
    deleteFromList(optionlist, index) {
      this.option.splice(index, 1);
      if (this.UserDto.answer == optionlist) {
        this.UserDto.answer = "";
      }
      if (this.option == "") {
        this.showSave = false;
      }
      this.checkoption = false;
    },
    Clear() {
      this.UserDto.question = "";
      this.UserDto.answer = "";
      this.UserDto.option = [];
      this.option = [];
      this.checkoption = false;
      this.errors[2] = true;
      this.retrieve = false;
      this.showSave = false;
    },
    async GetAndSetDataquestion(Id) {
      await axios
        .get(this.baseUrlQuestion + "getquestion?Id=" + Id, this.Head)
        .then((res) => {
          this.UserDto = res.data;
          this.option = this.UserDto.option;
          this.UserDto.option = "";
          this.showSave = true;
          this.showadd = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveOption() {
      let flag = true;
      if (this.UserDto.option == "" || this.UserDto.option == null) {
        alert("Please add an option");
      } else {
        const exists = this.option.find(
          (optionlist) => optionlist === this.UserDto.option
        );
        if (!exists) {
          if (flag) {
            this.option.push(this.UserDto.option);
            this.UserDto.option = [];
            this.showSave = true;
            if (this.checkoption == true) {
              this.checkoption = false;
            }
            if (this.UserDto.answer != "") {
              this.errors[2] = true;
            }
          }
        } else {
          alert("Option already exist");
          this.checkoption = false;
        }
      }
    },
    async gettypelist() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}document/document/gettypelist`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.doctypelist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.doctypelist = [];
        });
    },
    onClosed() {
      this.showpopup = false;
      this.questionslist();
    },
    async SaveDocuments() {

    },
    AddQuestion() {
      this.showpopupDoc = true
      this.gettypelist()
    },
    async configDocList() {
      await axios
        .get(`${this.docurl}getdocbyconfig?configid=${this.configId}`,
          this.Head)
        .then((res) => {
          this.configDocumentList = res.data;
          console.log("yyyyyyyy", this.configDocumentList)
        })
        .catch((err) => {
          console.log(err);
        });

    },
    async DocumentList(event) {
      event.forEach((type) => {
        console.log("iddd", type.id)
        this.typeValue.push(type.id)
      });
      await axios
        .post(`${this.docurl}getdocdetailsbytype`, this.typeValue,
          this.Head)
        .then((res) => {
          this.documentList = res.data;
          console.log("itssssssss", this.documentList)
        })
        .catch((err) => {
          console.log(err);
        });

    },
    onClosedDoc() {
      this.showpopupDoc = false;
      this.documentType = [];
      this.docDetails = [];
    },
    onFileChange() {
      document.getElementById("InPutFile").click();
    },
    async submitFile() {
      const idtoken = store.getters.getToken
      var formData = new FormData();
      formData.append("item", this.xlsxFile, this.xlsxFile.name);
      console.log(formData);
      if (confirm("Are you sure you want to upload the questions?")) {
        //TODO Change here
        await axios
          .post(
            this.baseUrlQuestion + "upload?ConfigId=" + this.configId,
            formData,
            {
              responseType: "blob",
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "multipart/form-data",
              },
            },
            {
              onUploadProgress: (uploadEvent) => {
                console.log("Helllo" + uploadEvent.loaded);
              },
            }
          )
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "Results";
            link.click();
            URL.revokeObjectURL(link.href);
            alert("Questions uploaded successfully");
            //window.location.reload();
            this.questionslist();

          })
          .catch((err, response) => {
            console.log(err);
            if (err.message == "Request failed with status code 500") {
              alert("Please upload the excel file with the specified format");
            }
            if (err == "Error: Request failed with status code 400") {
              alert("Please upload xls or xlsx file");
            }
            else if (err == "Error: Request failed with status code 504") {
              alert("Incorrect format");
            }
            this.questionslist();
          });
      }
    },
    async previewFiles(event) {
      console.log("hellooo")
      this.xlsxFile = event.target.files[0];
      console.log("type is", this.xlsxFile);
      event.target.value = null;  //This will clear the selected file from the file input field so that a new file can be selected.
      this.submitFile();
    },
    async SaveButtonClicked() {
      if (this.UserDto.answer == "") {
        this.errors[2] = false;
      } else {
        this.SetCommandAsync();
        if (this.id != 0) {
          if (this.UserDto.option != "") {
            alert("Option not added");
          } else await this.UpdateAsync();
        } else {
          if (this.UserDto.option != "") {
            alert("Option not added");
          } else await this.CreateAsync();
        }
      }
    },
    async UpdateAsync() {
      await axios
        .put(
          this.baseUrlQuestion + "update?Id=" + this.id,
          this.CreateUpdateCommnand,
          this.Head
        )
        .then((res) => {
          alert("Question updated successfully");
          this.$router.go(-1);
        })
        .catch((err) => {
          alert(err.response.data.errors.DomainValidations[0]);
        });
    },
    async CreateAsync() {
      await axios
        .post(this.baseUrlQuestion + "create", this.CreateUpdateCommnand, this.Head)
        .then((res) => {
          alert("Question created successfully");
          this.onClosed();
        })
        .catch((err) => {
          alert(err.response.data.errors.DomainValidations[0]);
        });
    },
    SetCommandAsync() {
      this.CreateUpdateCommnand.configId = this.configId;
      this.CreateUpdateCommnand.question = this.UserDto.question
        .replace(/\s+/g, " ")
        .trim();
      this.CreateUpdateCommnand.answer = this.UserDto.answer
        .replace(/\s+/g, " ")
        .trim();
      this.option = this.option.map((str) =>
        str
          .split(" ")
          .filter((word) => word !== "")
          .join(" ")
      );

      this.CreateUpdateCommnand.option = this.option;
    },
    SortSelected(sortProp, id) {
      console.log('SortPropChanged', sortProp, id);
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == true ? false : true;
      this.SortOrderInt = this.SortOrder == true ? 0 : 1;
      this.questionslist();
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.pageindex = page;
      this.questionslist();
      this.SwitchTab(this.userRole,this.fromtab,false);
    },
    closebutton(){
        router.push(`/testList`);
    },
    async ListUserExam(role) {
        console.log("page number is ", this.pageindex);
        this.userEmail = store.getters.getUserEmail;
        const idtoken = store.getters.getToken;
        await axios
          .get(
            `${this.baseapi}gettestresult?configId=${this.id}&pageindex=${this.pageindex}&pagesize=${this.pagesize}`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.userExamList = res.data.entities;
            console.log("USER data ISSS",this.userExamList)
            this.totalPage = parseInt(res.data.totalCount / this.pagesize);
            if (res.data.totalCount % this.pagesize != 0) {
              this.totalPage = this.totalPage + 1;
            }
          })
          .catch((err) => {
            console.log(err);
            this.userExamList = [];
          });
      },
      async SwitchTab(role,fromtab,issearch){
        this.userRole = role;    
        if(fromtab){
         
          this.pageindex=1;
          this.currentPage =1;
        }
        if(issearch){
  
        }
        else{
          this.searchField.employeename="";
          this.searchField.fromdate="";
          this.searchField.todate="";
          this.searchField.testresult="";
        }      
             
          await this.ListUserExam(role)
        
        
        
       
      },
      filter(data) {
        if (data) {
          return moment(data).format("DD-MM-YYYY");
        }
      },
      loadQuestionPage: function (page) {
        this.currentPage = page;
        this.pageindex = page;
        this.questionslist();
      },
  }
};