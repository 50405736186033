/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import { isForInStatement } from "typescript";

export default {
  components: {
    Multiselect,
  },
  computed: {
    minDate() {
      // Calculate the minimum allowed date (today's date)
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },
  data() {
    return {
      baseurl: `${process.env.VUE_APP_Service_URL}account-core/user/`,
      designationUrl: `${process.env.VUE_APP_Service_URL}configuration/designation/`,
      departmentUrl: `${process.env.VUE_APP_Service_URL}configuration/department/`,
      documentUrl: `${process.env.VUE_APP_Service_URL}document/projectdevdocumenttemplate/`,
      idtoken: "",
      showDesig: false,
      userList: "",
      searchField: {
        name: "",
        Designation: "",
        Department: "",
      },
      modtrainerLists:[],
      departmentlist: [],
      retrievebox: true,
      designationId: "",
      currentPage: 1,
      pageindex: 1,
      pagesize: 10,
      totalPage: 1,
      SortProperty: "firstName",
      SortOrder: false,
      SortOrderInt: 0,
      errors: [],
      validations: [],
      disignations: {},
      update: {
        designationId: "",
        name: "",
        description: "",
        active: true,
      },
      values: {
        name: "",
        description: "",
        active: true,
      },
      search: {
        createdby: "",
        status: "",
        name: "",
      },
      designationPrivileges: "",
      showPopup: false,
      userDetails: "",
      user: "",
      sopDetails: "",
      assignsop: {
        traineeName: "",
        traineeUserID: "",
        traineeJobTitle: "",
        acknowmentFileName: "",
        storageID: "",
        version: "",
        plannedStartDate: "",
        plannedEndDate: "",
        asignedBy: "",
        asignedByName: "",
        asignedDate: null,
        trainerID: "",
        trainerName: "",
        soP_Details: [],
        status: "",
        actualStartDate: null,
        actualEndDate: null,
        traineeSignature: {
          signedBy: "",
          initals: "",
          signedDate: null,
          userID: "",
          documentHash: "",
          signatureHash: "",
        },
        isConfirmed: false,
        trainerSignature: {
          signedBy: "",
          initals: "",
          signedDate: null,
          userID: "",
          documentHash: "",
          signatureHash: "",
        },
        trainerComments: "",
      },
      sopDetails: [],
      primaryEmail: "",
      trainerDetails: {},
      traineeDetails: {},
      traineralert: {
        show: false, 
        message: '',
      },
      traineealert: {
        show: false, 
        message: '',
      },
      datealert: {
        show: false, 
        message: '',
      },
      enddatealert:{
        show: false, 
        message: '',
      },
      trainerLists:"",
    };
  },

  async mounted() {
    this.getsopdetails();
    this.designationPrivileges = store.getters.getRolesprivilegeData.split(",");
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    this.idtoken = store.getters.getToken;
    this.ListUsers();
    await this.GetAndSetDesignation();
    await this.departmentslist();
    this.trainerList()
  },

  methods: {
    async trainer() {
      this.assignsop.trainerName =
        this.trainerDetails.firstName + " " + this.trainerDetails.lastName;
      this.assignsop.trainerID = this.trainerDetails.primaryEmail;
    },
    async trainee() {
      this.assignsop.traineeName =
        this.userList.firstName + " " + this.userList.lastName;
      this.assignsop.traineeUserID = this.userList.primaryEmail;
    },
    handleSelection(selectedItems) {
      console.log("selected items", selectedItems);
      // this.assignsop.soP_Details = selectedItems.map(selectedItem => {
      //   const sopDetail = this.sopDetails.find(detail => detail.title === selectedItem.title);
      //   return {
      //     title: selectedItem.title,
      //     version: sopDetail ? sopDetail.version : "",
      //     date: sopDetail ? sopDetail.date : ""
      //   };
      // });
    },
    customLabel(option) {
      return option;
    },
    async OnSearchButtonClicked() {
      this.pageindex = 1;
      this.currentPage = 1;
      //this.searchField = this.search.name;
      this.ListUsers();
    },
    async DeleteClicked(id) {
      if (confirm("Are you sure you want to delete this user?")) {
        await axios
          .delete(this.baseurl + "delete?id=" + id, {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            alert("User Deleted");
          })
          .catch((err) => {
            console.log(err);
            this.userList = [];
            alert("Somthing Went Wrong");
          });
        this.pageindex = 1;
        this.currentPage = 1;
        this.ListUsers();
      }
    },
    async GetAndSetDesignation() {
      await axios
        .get(
          this.designationUrl + "search?PageSize=100&SortProperty=name",
          this.Head
        )
        .then((res) => {
          this.disignations = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async departmentslist() {
      await axios
        .get(
          this.departmentUrl + "search?PageSize=10000&SortProperty=name",
          this.Head
        )
        .then((res) => {
          res.data.entities.forEach((data) => {
            if (data.parentID == "" || data.parentID == null) {
              if (!this.departmentlist.includes(data)) {
                this.departmentlist.push(data);
              }
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async GoToManageUser(id) {
      this.$router.push("./manageuser/" + id);
    },
    async ListUsers() {
      console.log("page number is ", this.pageindex);
      if (this.searchField.Designation == "All") {
        this.searchField.Designation = "";
      }
      if (this.searchField.Department == "All") {
        this.searchField.Department = "";
      }
      this.Designation = this.searchField.Designation;
      this.Department = this.searchField.Department;
      await axios
        .get(
          `${this.baseurl}getlist?PageIndex=${this.pageindex}&PageSize=${this.pagesize}&Name=${this.searchField.name}&Designation=${this.Designation}&Department=${this.Department}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrderInt}&UserType=All`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.userList = res.data.entities;
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.userList = [];
          alert("No data found!");
        });
    },
    onFileChange() {
      document.getElementById("InPutFile").click();
    },
    async previewFiles(event) {
      this.xlsxFile = event.target.files[0];
      console.log("type is", this.xlsxFile);
      this.submitFile();
    },
    async submitFile() {
      var formData = new FormData();
      formData.append("item", this.xlsxFile, this.xlsxFile.name);
      console.log(formData);
      if (confirm("Are you sure you want to upload user?")) {
        //TODO Change here
        await axios
          .post(
            this.baseurl + "upload",
            // `https://localhost:5001/user/upload`,
            formData,
            {
              responseType: "blob",
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "multipart/form-data",
              },
            },
            {
              onUploadProgress: (uploadEvent) => {
                console.log("Helllo" + uploadEvent.loaded);
              },
            }
          )

          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "Results";
            link.click();
            URL.revokeObjectURL(link.href);
            alert("File uploaded successfully");
            window.location.reload();
            this.ListUsers();
          })
          .catch((err, response) => {
            console.log(err);
            if (err.message == "Request failed with status code 500") {
              alert("Please upload the excel file with the specified format");
            }
            if (err == "Error: Request failed with status code 400") {
              alert("Please upload xls or xlsx file");
            } else if (err == "Error: Request failed with status code 504") {
              alert("Incorrect format");
            }

            // alert("Bad Request");
            //window.location.reload();
            this.ListUsers();
          });
      }
    },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged", sortProp, id);
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == true ? false : true;
      this.SortOrderInt = this.SortOrder == true ? 0 : 1;
      this.ListUsers();
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.pageindex = page;
      this.ListUsers();
    },
    closeModel() {
      this.showPopup = false;
      this.trainerDetails = "";
      this.assignsop.plannedEndDate = "";
      this.assignsop.plannedStartDate = "";
      this.assignsop.soP_Details = [];
      this.assignsop.trainerName=""
      this.traineralert.show = false;
      this.traineealert.show = false;
      this.datealert.show = false;
      this.enddatealert.show = false;
    },
    openModelrole(user, email,trainers) {
      this.assignsop.traineeName = user.firstName + " " + user.lastName;
      this.assignsop.traineeUserID = user.primaryEmail;
      this.user = user;
       let traineeemail = user.primaryEmail;
       this.modtrainerLists = this.trainerLists;
          this.modtrainerLists = this.modtrainerLists.filter((trainer) => {
            return trainer.primaryEmail !== traineeemail;
          });
      this.trainers= trainers
      this.primaryEmail = email;
      this.showPopup = true;
      this.assignsop.traineeJobTitle = user.designation;

      console.log(this.user, "userdetails");
    },
    async getsopdetails() {
      let Id = store.getters.getToken;
      await axios
        .get(this.documentUrl + "getsopdetails", {
          headers: {
            Authorization: "Bearer " + Id,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.sopDetails = res.data;
          console.log("sop details", this.sopDetails);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async AssignSop() {
      this.validatetrainer();
      this.validateStartdates();
      this.validateEnddates();
      this.validatetrainee();
      this.assignsop.status = "Initiated";
      this.assignsop.soP_Details = this.assignsop.soP_Details.map(item => ({
        title: item.title,
        version: item.version,
        date: item.date,
      }));
       if(this.traineealert.show == false && this.traineralert.show == false && this.datealert.show == false && this.enddatealert.show == false)
       {
        if (this.assignsop.plannedStartDate > this.assignsop.plannedEndDate) {
          alert("End date must be greater than start date.");
        }
        else{
          var formattedDate = this.assignsop.plannedEndDate.split('-').reverse().join('-');
          this.assignsop.acknowmentFileName =
          "ITF-502-GL[1].00 Training Acknowledgment Form for SOP_" +
          this.assignsop.traineeName +
          "_" +
          formattedDate;
        await axios
          .post(this.documentUrl + "createsopacknowledgement", this.assignsop, this.Head)
          //.post(`https://localhost:5001/projectdevdocumenttemplate/createsopacknowledgement`, this.assignsop, this.Head)
          .then((res) => {
            alert("Invited successfully");
            this.closeModel();
          })
          .catch((err) => {
          });
        }
       
      }
    },
    async trainerList() {
      await axios
        .get(
          `${this.baseurl}getlist?PageIndex=${this.pageindex}&PageSize=1000&Name=${this.searchField.name}&Designation=${this.Designation}&Department=${this.Department}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrderInt}&UserType=All`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.trainerLists = res.data.entities;
          
          console.log("USER", this.trainerLists);

        })
        .catch((err) => {
          console.log(err);
          this.trainerLists = [];
          alert("No data found!");
        });
    },
    validatetrainer() {      
      if (this.assignsop.trainerName =="" || null) { // Check if trainerID is empty or null
        this.traineralert.show = true; // Show the error message
        this.traineralert.message = "Trainer name is mandatory"; // Set the error message
      }
      else{
        this.traineralert.show = false;
      }
    },
    validatetrainee() {
      if(this.assignsop.soP_Details.length===0)
      {
        this.traineealert.show = true; 
        this.traineealert.message = "Select at least one SOP document"; 
      }
      else{
        this.traineealert.show = false; 
      }
    },
    validateStartdates() {
      if (this.assignsop.plannedStartDate == "" ||(null) ) 
      {
        this.datealert.show = true; 
        this.datealert.message = "Start date is mandatory"; 
      }
      else{
        this.datealert.show = false; 
      }
    },
    validateEnddates() {
      if (this.assignsop.plannedEndDate == "" || (null) ) 
      {
        console.log( "hi")
        this.enddatealert.show = true; 
        this.enddatealert.message = "End date is mandatory"; 
      }
      else{
        this.enddatealert.show = false; 
      }
    },
    goBack() {
      this.$router.push(`/administration`);
    }
  },
};
