/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import TreeBrowser from "../Tree/TreeBrowser.vue";
import TreeFolder from "../TreeFolder/TreeFolder.vue";
import DocumentPopup from "../DocumentPopup/DocumentPopup.vue";
import SidePopUp from "../side_pop_up/side_pop_up.vue"
import PermissionPopUp from "../permission_popup/permission_popup.vue"
import folderEditPopUp from "../editfolderpopup/editfolderpopup.vue"
import fileFormatPopUp from "../fileformatpopup/fileformatpopup.vue"
import fileSharePopup from "../fileSharePopup/fileSharePopup.vue";
import foldersendpopup from "../foldersend_popup/foldersend_popup.vue"
import { ref, computed, onMounted, watch } from "vue";
import Multiselect from "@vueform/multiselect";
import File_FolderPopup from "../File_FolderPopup/File_FolderPopup.vue"
import DocEditPopup from "../DocEditPopup/DocEditPopup.vue"
import NewDocVersion from "../NewDocVersion/NewDocVersion.vue"
import doc_Approval from "../document_Approval_Popup/doc_Approval.vue"

export default {
    components: {
        //VPagination
        TreeBrowser,
        TreeFolder,
        DocumentPopup,
        SidePopUp,
        PermissionPopUp,
        fileFormatPopUp,
        folderEditPopUp,
        Multiselect,
        fileSharePopup,
        foldersendpopup,
        File_FolderPopup,
        DocEditPopup,
        NewDocVersion,
        doc_Approval,
    },
    data() {
        return {
            docversion:"",
            signVersionId:"",
            minverError:false,
            majorverError:false,
            isuserenabled:false,// add doc privilege
            isusercanaddfolder:false,
            arrayIndex:0,
            userpermissions:[],
            versionfrom:"fromdepartment",
            folderversion:"",
            sourceType: "",
            editDocPopup: false,
            newdocversionPopup: false,
            openfolderlist: "",
            showPermPopup: false,
            shaireddept: "",
            shairedfolder: "",
            VersionConfig: [],
            userList: [],
            FormatList: [],
            designationList: [],
            selectedDesignation: [],
            selectedUser: [],
            selectedPermission: [],
            SelectedFormat: [],
            permissionList: [],
            UserpermissionList: [],
            newFormatList: [],
            newUserList: [],
            createdFolderId: "",
            idtoken: "",
            mainfolderbold: "",
            maindeptbold: "",
            folderfordocument: '',
            foldertestlist: [],
            departmentswitch: "",
            maindepartments: [],
            currentancesterids: [],
            subdeptlist: {},
            bolddept: '',
            createfolderpopup: false,
            foldervalues: {
                name: "",
                fileConfiguration: [],
                versionConfiguration: [],
            },
            deporfolder: '',
            organization: "Datamatica",
            docupload: false,
            folderlist: [],
            currentdepartment: "",
            org: [],
            deptt: [],
            name: [],
            deptlist: {},
            sublist: [],
            visitobj: {},
            baseapi: `${process.env.VUE_APP_Service_URL}document/folderfilepermission/`,
            baseurl: `${process.env.VUE_APP_Service_URL}configuration/`,
            docurl: `${process.env.VUE_APP_Service_URL}document/document/`,
            mainapi: `${process.env.VUE_APP_Service_URL}`,
            setId: "",
            showOrg: false,
            popup: false,
            department: false,
            dept: false,
            orgdetails: false,
            Oname: false,
            divname: false,
            deptdetails: false,
            deptBlock: false,
            orgbyid: "",
            selectedID: "",
            deptBlockname: "",
            list: {},
            values: {
                OrgName: "",
                editOrgname: "",
                organizationId: "",
            },
            deptvalues: {
                DeptName: "",
                editDeptName: "",
                name: "",
                orgId: ""
            },
            documentVersion: {
                prefix: "",
                majorVerStart: "",
                minorVerStart: "",
                minorVermax: "",
            },
            prefix: "",
            majorVerStart: "",
            minorVerStart: "",
            minorVermax: "",
            validations: [],
            errors: [],
            OrgData: true,
            DeptData: true,
            retrieve: true,
            recive: true,
            retrieveorg: false,
            recivedep: false,
            buttonretrieve: false,
            buttonrecive: false,
           // showBlock: false,
            popupid: "",
            orglist: [],
            deleteOrglist: [],
            deleteDeptlist: [],
            flags: [],
            showBlock: false,
            selecteddeptname: "",
            firstdeptlist: [],
            selectedfoldername: "",
            selectedfolderid: "",
            currentselectedfolder: "",
            createval: "",
            Document: "",
            currentselecteddepartment: '',
            docuplooadfields: {
                folderid: "",
                title: "",
                description: "",
                Owner: "",
                Department: "",
                Category: "",
                Standard: "",
                documenttype: "",
                version: "",
                expiarydate: "",
                formData: "",
            },
            documentlist: '',
            filesendpopup: false,
            selecteddocid: "",
            docsId: false,
            showDocumentApproval: false,
            documentId: "",
            version: "",
            projectid: "",
            dropdownname: 'Department',
            archivedFolder: false,
            idSet:[],
            designationPrivileges :"",
            desigFolderPrivileges :"",
            sendtoValue : "",
            editValue : "",
            permissionsValue : "",
            shareValue : "",
            deleteValue : "",
            archiveValue : "",
            anyuserpermissions:false,
        };
    },
    watch: {
        "$store.getters.getDepartment": function (val) {

            if (val) {
                this.maindeptbold = val;
                this.currentdepartment = val;
                this.folderfordocument = null;
                this.currentselecteddepartment = val;
                this.getalltestfolders(val);
                this.getselecteddepartments(val);
                this.getalldepartmentfolders(val);
            }
            else {
                this.currentdepartment = null;
                this.currentselecteddepartment = null;
                this.selectedfoldername = null;
            }
            this.documentlist = [];
        },
        "$store.getters.getselectedFolder": function (val) {
            
            if(val == null || val == ""){
                // alert(1);
                this.documentlist = [];
            }
            if (this.archivedFolder) {
                this.getFolderversion(val);
                this.mainfolderbold = val;
                this.folderfordocument = val;
                this.currentselectedfolder = "";
                this.folderversion = this.folderversion;
                store.dispatch("selectFolder", "");
                this.archivedFolder = false;

            } else {
                this.getFolderversion(val);
                this.mainfolderbold = val;
                this.folderfordocument = val;
                this.currentselectedfolder = val;
                this.folderversion = this.folderversion;
                this.getdocuments(val);

            }
        },
    },
    beforeRouteEnter(to, from, next) {
       
        next((vm) => {
            
          vm.prevRoute = from;
        });
      },
    async mounted() {
        
        this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
        this.isusercanaddfolder = true;
        let doctodept = store.getters.getdocumenttodepartment;
        let deptfordocview = store.getters.getDepartment;
        let folderfordocview = store.getters.getselectedFolder;
        
        window.addEventListener("folderArchived", (event) => {
            console.log("folderArchived == " + event.detail.storage);
            if (event.detail.storage == true) {
                this.documentlist = [];
            }
        });
        this.shairedfolder = store.getters.getshaioredfolder;
        if(store.getters.getshaioreddept ==null || store.getters.getshaioreddept == ""){
            this.shaireddept = store.getters.getDepartment;
        }
        else{
            this.shaireddept = store.getters.getshaioreddept;
        }
        this.bolddept = this.shaireddept;
        store.dispatch("setFromPage", "Department");
        store.dispatch("selectFolder", "");
        store.dispatch("setDepartment", 0);
        store.dispatch("selectFoldermove","")
        this.idtoken = store.getters.getToken
        if (this.prevRoute != null) { 
           if (this.prevRoute.path != "/") 
           {
             localStorage.setItem("patvalue", this.prevRoute.path); 
          }
           if (this.prevRoute.path == "/doclogin/") 
           { 
            this.checkDoc();
           } 
          }
        if (this.shairedfolder != "") {
            this.setfirstdept(this.shaireddept, this.shairedfolder);
        }
        if (doctodept) {
            this.setfirstdept(deptfordocview, folderfordocview);
        }
        this.getfirstDeptById();
        const id = store.getters.getOrgId;
        store.dispatch("setshaioreddept", 0);

        store.dispatch("setdocumenttodepartment", false);
       // store.dispatch("setMainDepartment", this.maindeptbold);
    },
    async unmounted() {
        store.dispatch("setopenfolder", "");
      },
    methods: {        
        async getFolderversion(folderid) {

            await axios
                .get(
                    // `https://localhost:5001/folder/getfolderversion?Id=${folderid}`,
                    `${this.mainapi}document/folder/getfolderversion?Id=${folderid}`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                  
                    console.log("VERSIONN issss", typeof(res.data))
                    this.folderversion = res.data;
                    if(this.folderversion.includes(':'))
                    {
                        this.folderversion = (this.folderversion).replace(':','.');
                    }
                    console.log("CONVRT VERSIONN issss", this.folderversion)
                })
                .catch((err) => {
                    console.log(err);
                });
                this.getFolderUserpermission(folderid) 
        },
        async checkDoc() {
            const idtoken = store.getters.getToken;
            this.selectedfolder = store.getters.getselectedFolder
            console.log("FOLDER ID ISSSSSSSSSSSSSSSSSSSSSSSSSS",store.getters.getselectedFolder)
            await axios
              .get(
                `${this.mainapi}document/folder/getarchive?Id=${this.selectedfolder}`,
                {
                  headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                console.log("RESSS",res);
                if (res.data == "The folder you are trying to access is currently archived!")
                {
                  alert(res.data);
                  this.$router.push(`/document_approval`);
                }
              })
              .catch((err) => {
                console.log(err.response.data);
              });
          },
        async getFolderUserpermission(folderid)
        {
        //get user privileged folder controls
        await axios
        .get(`${this.mainapi}document/folderfilepermission/getuserfolderpermission?FolderId=${folderid}`,
        {
         headers: {
             Authorization: "Bearer " + this.idtoken,
             "Content-Type": "application/json",
         },
        })
        .then(async (res) => {
        this.isuserenabled = false;      
        this.userpermissions  = res.data != null? res.data : null;
        if(this.userpermissions !=null){
            if(this.userpermissions.includes("Upload"))
            {
              this.isuserenabled = true;
            }
            if(this.userpermissions.includes("Add"))
            {
              this.isusercanaddfolder= true;
            }
            else{
                console.log(89)
              this.isusercanaddfolder= false;
            }
        }
         else{
             this.isusercanaddfolder= false;
         }        
        })
        .catch((err) => {
         console.log(err);
        });
        },
        setfirstdept(department, folder) {

            this.openfolderlist = folder;
            this.opendepartmentlist = department;
            store.dispatch("setDepartment", department);

            let recieveddept = store.getters.getDepartment;
            this.maindeptbold = recieveddept;
            this.currentdepartment = recieveddept;
            this.currentselecteddepartment = recieveddept;
            this.getalltestfolders(recieveddept);
            this.getselecteddepartments(recieveddept);
            this.getalldepartmentfolders(recieveddept);
            let receivedfolder = store.getters.getselectedFolder;
            this.mainfolderbold = receivedfolder;
            this.folderfordocument = receivedfolder;
            this.currentselectedfolder = receivedfolder;
            if (this.shairedfolder) {
                this.docsId = false
                this.getArchived(this.shairedfolder, this.docsId)
            }
            this.getdocuments(receivedfolder);
        },
        // openExam(id) {
        //     confirm( "hi",this.dropdownname)
        //     store.dispatch("setDocViewSource", "Department")
        //     this.$router.push(`/configure_exam/` + id )
        // },
        openDocs(id,version) {
          
            if(this.designationPrivileges.includes(`Departments View Document`)){
                store.dispatch("setDocViewSource", "Department")
                this.$router.push(`/documentview/` + id + "/" + version)
            }
                
        },
        // openViewResult(id) {
        //     store.dispatch("setDocViewSource", "Department")
        //     this.$router.push(`/ExamResult/` + id)
        // },
        setfilesendpopup(docid) {
            //alert(docid); commented
            store.dispatch('setsendfile', true);
            this.selecteddocid = docid;
            this.showBlock = false

        },
        async FilePermissionPopup() {
            // this.showBlock = false
            this.showPermPopup = !this.showPermPopup
            this.sourceType = "File"
        },
        async DocEditPopup(docid,docversion) {
            this.docversion = docversion;
            this.editDocPopup = !this.editDocPopup
            this.showBlock = false
        },
        async NewDocVersion() {
            this.newdocversionPopup = !this.newdocversionPopup
            this.showBlock = false
        },
       
        isNumber: function (evt,vtype) {
            
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            const getmaj = vtype;
            if(getmaj =='major'){
                if(this.majorVerStart.length==0 && charCode ==48){
                    evt.preventDefault();
                }
            }
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 || charCode == 46) {
                evt.preventDefault();
            } else {
                return true;
            }

        },
        isLetter(e) {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if (/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        },
        async getfolderpermissions() {
            await axios
                .get(`${this.baseapi}allfolderpermission`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.permissionList = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async getalldesignation() {
            await axios
                .get(`${this.baseurl}designation/search?status=active&PageSize=10000&SortProperty=displayName`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.designationList = res.data.entities;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async backtorootfolder(id) {
            store.dispatch("selectFolder", "");
            this.mainfolderbold = "",
                this.currentselectedfolder = "";
                this.isusercanaddfolder=true,
            this.getalldepartmentfolders(id);
        },
        sidePopup(index,id, name, type, e) {
            this.getDocumentdetails(id);
            e = window.event; //  e = window.event || e;
            this.event = e
            this.popupid = id
            this.arrayIndex=index
            this.showBlock = !this.showBlock
            store.dispatch("setselectedid", id)
            store.dispatch("setfileType", type)
            store.dispatch("setfileName", name)
        },
        async getDocumentdetails(id) {
            this.sendtoValue = "";
            this.editValue = "";
            this.permissionsValue = "";
            this.shareValue = "";
            this.deleteValue = "";
            this.archiveValue = "";
            await axios
              .get(
                `${this.docurl}getdocumentdetails?documentId=${id}`,
                //`https://localhost:5001/document/getdocumentdetails?documentId=${this.documentid}`,
                {
                  headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                this.signVersionId = res.data.storageVersionId;
                if (res.data.permissions != null) {
                    this.anyuserpermissions=false;
                    
                  console.log(res.data.permissions);
                  res.data.permissions.forEach((item) => {
                    if (item == "Clone") this.sendtoValue = item;
                    if (item == "Permissions") this.permissionsValue = item;
                    if (item == "Share") this.shareValue = item;
                    if (item == "Edit") this.editValue = item;
                    if (item == "Delete") this.deleteValue = item;
                    if (item == "Archive") this.archiveValue = item;
                  });
                  if (
                    !this.sendtoValue &&
                    !this.permissionsValue &&
                    !this.shareValue &&
                    !this.editValue &&
                    !this.deleteValue &&
                    !this.archiveValue
                  ) {
                    this.anyuserpermissions=true;
                  }
                }
                else{
                    this.anyuserpermissions=true;
                }
                // this.getVersionHistory();
              })
              .catch((err) => {
                console.log(err);
              });
          },
        // saveVersion() {
        //     if (this.documentVersion.majorVerStart == "" | this.documentVersion.majorVerStart == null) {
        //         alert("Major version is mandatory")
        //     }
        //     else {
        //         this.VersionConfig = []
        //         this.VersionConfig.push(this.documentVersion);
        //         console.log("Version configuration are", this.VersionConfig);

        //     }

        // openExam(id) {
        //     store.dispatch("setDocViewSource", "Department")
        //     this.$router.push(`/configure_exam/` + id )
        // },

        // async documentApproval(documentId, version) {
        //     alert(version)
        //     this.version = version;
        //     this.documentId = documentId;
        //     this.showDocumentApproval = !this.showDocumentApproval;
        //     this.showBlock = false
        // },
        
        async getdocuments(id) {
            await axios
                .get(
                    `${this.mainapi}document/folder/getprivilegedcontent?Id=${id}`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    this.documentlist = res.data;
                    console.log("Document list is", this.documentlist);
                })
                .catch((err) => {
                    console.log(err);
                });

        },
        async createfolderfunc() {
            document.body.style.overflowY = "hidden"
            this.prefix = "";
            this.majorVerStart = "";
            this.minorVerStart = "";
            this.minorVermax = "";
            this.foldervalues.name = "";
            this.foldervalues.fileConfiguration = [];
            this.selectedDesignation = [];
            this.selectedPermission = [];
            this.SelectedFormat = [];
            this.selectedUser = [];
            await this.getfolderpermissions();
            await this.getalldesignation();
            await this.getDesignationUsers();
            await this.getallFormat();
            this.createfolderpopup = true;
            this.errors[3] = true;
            this.versionConfiguration = [];
        },
        async onclosedfolderpopup() {
            this.errors[3] = true;
            this.createfolderpopup = false;
            this.foldervalues.name = "";
            this.foldervalues.fileConfiguration = [];
            this.selectedDesignation = [];
            this.selectedPermission = [];
            this.SelectedFormat = [];
            this.selectedUser = [];
            this.minverError = false;
            this.majorverError=false;
        },
        async uploadmethodactivity() {
            this.docupload = false;
            this.getdocuments(this.folderfordocument);
        },
        async uploadeditactivity() {
            this.editDocPopup = false;
            this.getdocuments(this.folderfordocument);
        },
        async newversionuploadactivity() {
            this.newdocversionPopup = false;
            this.getdocuments(this.folderfordocument);
        },
        async getselecteddepartments(id) {
            // alert("hello depid"+id)
            const idtoken = store.getters.getToken;
            if (id != "") {
                await axios
                    .get(
                        `${this.mainapi}configuration/department/search?id=${id}&PageSize=100`,
                    {
                        headers: {
                          Authorization: "Bearer " + idtoken,
                          "Content-Type": "application/json",
                        },
                      })
                    .then((res) => {
                        if (res.data.entities[0].name) {

                            console.log("selected folderss are 1" + res);
                            this.selectedfoldername = res.data.entities[0].name;
                            this.selectedfolderid = res.data.entities[0].id;
                            this.currentancesterids = res.data.entities[0].ancestorIds;
                        }
                    })
                    .catch((err) => {
                        console.log(err.response.data.messages)
                    })
            }

        },
        async getArchived(folId, docsId) {
            await axios
                .get(`${this.mainapi}document/folder/getarchive?Id=${folId}&doc=${docsId}`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    console.log("archived folders are ", res);
                    this.apiresult = res.data;
                    if (this.apiresult != " No folder is Archived") {
                        var message = confirm(this.apiresult)
                        if (message == true) {
                            console.log("folders true ");
                            this.currentselectedfolder = "";
                            this.archivedFolder = true;
                        }
                        else {
                            console.log("folders false ");
                        }
                    }
                    this.apiresult = ""
                })
                .catch((err) => {
                    console.log(err.response.data.messages)
                })
        },
        async DeleteDocument(id) {
            console.log("Document Id.........", id)
            const documentId = id
            if (confirm("Are you sure you want to delete this document?")) {
                this.showBlock=false
                await axios.delete(this.docurl + "delete?documentId=" + documentId,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                    .then((res) => {
                        console.log(res)
                        alert("Document deleted successfully")
                        
                    })
                    .catch((err) => {
                        console.log(err);
                        this.documentlist = []
                        alert("Somthing Went Wrong")
                    })
                    
                this.pageindex = 1;
                this.currentPage = 1;
                this.getdocuments(this.folderfordocument);
            }
        },
        async ArchievedDocument(id) {
            this.showBlock = false;
            console.log("tokennnnnnnnnnnnnn.........", this.idtoken)
            const documentId = id
            if (
                confirm("Are you sure you want to archive this document ?")
            ) {
                //Api call  
                await axios
                    .put(`${this.mainapi}document/document/archive?id=${documentId}`, {}, {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        console.log(res)
                        alert("Document archived successfully")
                        this.showBlock = false
                        //tree refresh
                    })
                    .catch((err) => {
                        console.log(err.message)
                    })
                this.pageindex = 1;
                this.currentPage = 1;
                this.getdocuments(this.folderfordocument);
            }
        },

        async getselectedfolders(id) {
            
            await axios// changed getfiles to getprivilegedcontent
                .get(`${this.mainapi}document/folder/getfiles?Id=${id}`, {
                },
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    if (res.data.name) {

                        this.selectedfoldername = res.data.name;
                        this.selectedfolderid = res.data.id;
                    }

                })
                .catch((err) => {
                    console.log(err.response.data.messages)
                })

        },
        async createdoc() {
            let val = store.getters.getselectedFolder
            await this.getFolderversion(val);

            this.docupload = true;
        },
        async ondocclosed() {
            this.docupload = false;
        },
        async createFolderfunc(value) {
            document.body.style.overflowY = "hidden"
            if (value == "createOrg") {
                this.showOrg = !this.showOrg;
            } else this.uploadpopupmodal = true;
        },
        async uploaddoc() {

            console.log(this.formData);
            await axios
                .post(`${this.mainapi}document/document/upload`, 
                {
                    "Files": this.formData,
                    "FolderId": this.docuplooadfields.folderid,
                    "DocumentTitle": this.docuplooadfields.title,
                    "DocumentDescription": this.docuplooadfields.description,
                    "Owner": this.docuplooadfields.Owner,
                    "Department": this.docuplooadfields.Department,
                    "Catagory": this.docuplooadfields.Category,
                    "Standard": this.docuplooadfields.Standard,
                    "Standard": this.docuplooadfields.documenttype,
                    "Version": this.docuplooadfields.version,
                    "ExpiryDate": this.docuplooadfields.expiarydate
                }, {
                    headers: {
                        Authorization: "Bearer " + this.idtoken,
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    console.log(res);
                    alert("Organization created successfully");

                })
                .catch((err) => {
                    console.log(err);
                    alert("Organization name already exist");
                });
        },
        UploadDocumentModal() {
            document.getElementById("fileLoader").click();
        },
        async closebutton() {

            this.errors[1] = true;
            this.errors[3] = true;
            if (this.orgdetails) {

                this.retrieve = true;
                this.retrieveorg = false;
                this.recivedep = false;
                this.recive = false;
            }
            else {

                this.recive = true;
                this.recivedep = false;
            }
            this.buttonrecive = false;
            this.buttonretrieve = true;


        },
        async onclosed(value) {
            document.body.style.overflowY = "auto"
            if (value == "createOrg") {
                this.validations[2] = true;
                this.errors = this.validations;
                this.showOrg = false;
            }
        },
        async createOrgfunc(value) {
            document.body.style.overflowY = "hidden"
            if (value == "createOrg") {
                this.showOrg = !this.showOrg;
                this.popup = true;
                this.department = true;
                this.dept = false;
                this.values.OrgName = "";
            }

        },
        async createDeptfunc(value, septorfolder) {
            document.body.style.overflowY = "hidden"
            this.showOrg = !this.showOrg;
            this.deporfolder = septorfolder;
            this.popup = false;
            this.dept = true;
            this.department = false;
            this.errors[0] = true;
            this.deptvalues.name = "";
        },
        async validateIsNull() {

            if (this.values.OrgName == "") {
                this.validations[0] = false;
                this.errors = this.validations;
            } else if (this.values.OrgName.trim("") == "") {
                this.validations[0] = false;
                this.errors = this.validations;
            } else {

                this.validations[0] = true;
                this.errors = this.validations;
            }
        },

        async validateNameIsNull() {
            if (this.values.editOrgname == "") {
                this.validations[1] = false;
                this.errors = this.validations;
            } else {
                this.validations[1] = true;
                this.errors = this.validations;
            }
        },
        async validateIsDeptNullCreate() {

            if (this.deptvalues.name == "") {
                this.validations[2] = false;
                this.errors = this.validations;
            } else {

                this.validations[2] = true;
                this.errors = this.validations;
            }
        },
        async validateIsDeptNull() {
            if (this.values.editDeptName == "") {
                this.validations[3] = false;
            }
            else {
                this.validations[3] = true;
                this.errors = this.validations;
            }
        },

        async createfolder() {
            console.log("minorVerStart",this.minorVerStart);
            console.log("minorVerMax",this.minorVermax);
            this.minverError= false;
            this.majorverError=false;
            let folderflag = true;
            if (this.foldervalues.name == null || this.foldervalues.name == "") {
                this.errors[3] = false;
            }
            else if(this.minorVermax !="" && parseInt(this.minorVerStart) > this.minorVermax)
            {
                this.minverError = true;
            }
            else if(this.majorVerStart=="0")
            {
                this.majorverError=true;
            }
            else {
                this.foldertestlist.forEach((testdata) => {
                    if (testdata.name == this.foldervalues.name) {
                        folderflag = false;
                    }
                });
               
                await axios
                    .post(`${this.mainapi}document/folder/create`,
                        {
                            "name": this.foldervalues.name,
                            "parentId": this.currentselectedfolder,
                            "storageId": this.currentdepartment,
                            "storageType": "Department",
                            "fileConfiguration": this.SelectedFormat,
                            "versionConfiguration": [
                                {
                                    "prefix": this.prefix,
                                    "majorVerStart": this.majorVerStart,
                                    "minorVerStart": this.minorVerStart,
                                    "minorVermax": this.minorVermax
                                }
                            ]
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + this.idtoken,
                                "Content-Type": "application/json",
                            },
                        }
                    )
                    .then((res) => {
                        console.log(res);
                        this.createdFolderId = res.data;
                        this.createFolderPermission();

                        alert("Folder created successfully");
                        console.log("document version array issssssssssssss", this.documentVersion)
                        this.showOrg = false;
                        document.body.style.overflowY = "auto"
                        this.createfolderpopup = false;

                        this.getalldepartmentfolders(this.currentdepartment);
                        document.getElementById(this.currentselectedfolder + 'doc').click();
                        document.getElementById(this.currentselectedfolder + 'doc').click();
                        this.foldervalues.name = "";
                    })
                    .catch((err) => {

                        if (err.response.data.detail == "Folder name already present in the parent department.") {
                            console.log("REACHED IF")
                            alert("Folder name already exist.");
                        }
                        else {
                            console.log("REACHED ELSE")
                            alert(err.response.data.detail);

                        }
                    });
                store.dispatch("reloadfolder", true);
               
                this.selectedDesignation = [];
                this.selectedPermission = [];
                this.SelectedFormat = [];
                this.selectedUser = [];
                this.VersionConfig = [];
            }
        },
        async createFolderPermission() {
            this.newUserList = []
            this.selectedUser.forEach((subitem) => {
                this.newUserList.push({
                    userId: "string",
                    emailId: subitem,
                    name: "string",
                    permission: this.selectedPermission
                });
            })

            if (this.selectedUser.length == 0) {
            
            }
            else {
                await this.saveFolderPermission();
            }
        },

        // async addFormat() {
        //     this.newFormatList = []
        //     this.SelectedFormat.forEach((subitem) => {
        //         this.newFormatList.push({
        //             fileConfiguration: this.SelectedFormat
        //         });
        //     })
        //     console.log("selectedUser.........", this.SelectedFormat)
        //     if (this.SelectedFormat.length == 0) {

        //     }
        //     else {
        //         await this.createfolder();
        //     }
        // },




        async saveFolderPermission() {
            await axios
                .post(`${this.baseapi}savefolderpermission`,
                    {
                        "folderId": this.createdFolderId,
                        "userPermission": this.newUserList
                    }
                )
                .then((res) => {
                    console.log("Save Result", res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async getDesignationUsers() {
            console.log("all users from designation");
            await axios
                .post(`${this.baseapi}designationusers`, this.selectedDesignation)
                .then((res) => {
                    this.userList = res.data;
                    console.log("Reached ", this.selectedUser)
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async getallFormat() {
            await axios
                .get(`${this.mainapi}document/documentformat/search`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.FormatList = res.data.entities[0].values;
                    console.log("Format List", this.FormatList)
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async createDept() {
            let deptflag = true;

            if (this.currentancesterids == null) {
                this.currentancesterids = [];
            }
            await this.firstdeptlist.forEach((dept) => {
                if (dept.name == this.deptvalues.name) {
                    deptflag = false;
                }

            });

            this.deptvalues.name = this.deptvalues.name.trim();
            await this.validateIsDeptNullCreate();

            if (this.errors[2]) {
                console.log("deptNameErrorCheck")
                const orgId = store.getters.getOrganisationID;
                this.deptvalues.orgId = orgId;
                let ancesters = this.currentancesterids.push(this.selectedfolderid);
                if (this.currentdepartment == null) {
                    this.currentancesterids = [];
                }
                console.log(ancesters);

                await axios
                    .post(`${this.baseurl}department/create`,
                        {
                            "name": this.deptvalues.name,
                            "orgId": orgId,
                            "ancestorIds": this.currentancesterids,
                            "parentID": this.currentdepartment
                        }, {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                    .then(async (res) => {
                        console.log(res);
                        alert("Department created successfully");
                        this.showOrg = false;
                        document.body.style.overflowY = "auto"
                        await this.getfirstDeptById();


                    })
                    .catch((err) => {
                        if (err.response.data.messages[0].includes("Department name already") || err.response.data.messages[0].includes("Object reference not set to an instance")) {
                            alert("Department name already exists");
                        }
                    });
                await this.restoresubdepartments(this.currentdepartment);
                document.getElementById(store.getters.getDepartment).click();


            }
            this.deptvalues.name = "";


        },
        async getDeptById(id) {
            const idtoken = store.getters.getToken;
            await axios
                .get(`${this.baseurl}department/searchbyprivilege?orgid=${id}`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    let datalist = res.data.entities;
                    datalist.forEach((data) => {
                        this.name.push(data.name)
                    });
                    this.deptlist = datalist
                    this.getSubDeptId(this.org[0].id)

                })
                .catch((err) => {
                    console.log(err.response.data.messages)
                })

        },
        async previewFiles(event) {
            this.Document = event.target.files[0];

            var formData = new FormData();
            formData.append("file", this.Document);
            console.log(formData);
        },
        async getalltestfolders(id) {

            await axios

                .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${id}`, {

                    headers: {
                        Authorization: "Bearer " + this.idtoken,
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    this.foldertestlist = res.data;

                })
                .catch((err) => {
                    console.log(err.response.data.messages)
                })

        },
        async getalldepartmentfolders(id) {

            const idtoken = store.getters.getToken
            await axios
                .get(`${this.mainapi}document/folder/getprivilegedroots?storageId=${id}`, {
                    headers: {
                        Authorization: "Bearer " + this.idtoken,
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    this.folderlist = res.data;
                })
                .catch((err) => {
                    console.log(err.response.data.messages)
                })

        },

        async getallfolders(id) {

            await axios
                .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${id}`, {

                    headers: {
                        Authorization: "Bearer " + this.idtoken,
                        "Content-Type": "application/json",
                    },

                })
                .then((res) => {
                    this.folderlist = res.data;
                    console.log("folder are",this.folderlist);

                })
                .catch((err) => {
                    console.log(err.response.data.messages)
                })

        },
        async getfoldersById(id, name, index) {
            this.bolddept = name;
            this.getallfolders(id);
            this.selectedfoldername = name;
            this.selectedfolderid = id;
            this.deptlist[name] = [];

            await axios
                .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${id}`, {

                    headers: {
                        Authorization: "Bearer " + this.idtoken,
                        "Content-Type": "application/json",
                    },

                })
                .then((res) => {
                    let datalist = res.data;
                    datalist.forEach((data) => {
                        this.name.push(data.name)
                    });
                    this.deptlist[name] = datalist
                    this.getSubDeptId(this.org[0].id)

                })
                .catch((err) => {
                    console.log(err.response.data.messages)
                })

        },
        async getfirstDeptById() {
            this.maindepartments = [];
            console.log('department  id is');
            const orgId = store.getters.getOrganisationID;
            const idtoken = store.getters.getToken;
            await axios
                .get(
                    // `https://localhost:5001/department/searchbyprivilege?orgid=${orgId}&PageSize=5400`,
                    `${this.baseurl}department/searchbyprivilege?orgid=${orgId}&PageSize=80000`,
                {
                    headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",
                    },
                  })
                .then((res) => {
                    res.data.entities.map((item)=>{
                        this.getallsubdepartments(item.id)
                    })
                    this.maindepartments = res.data.entities;
                    
                })
                .catch((err) => {
                    console.log(err.response.data.messages)
                })
        },
        async restoresubdepartments(id) {
            this.folderfordocument = null;
            this.departmentswitch = id;
            this.currentdepartment = id;
            this.currentselecteddepartment = id;
            this.getselecteddepartments(id);
            this.getalldepartmentfolders(id);
            this.getdocuments(id);
            this.bolddept = id;
            store.dispatch("setDepartment", id);
            this.subdeptlist[id] = [];
            this.firstdeptlist.forEach((data) => {
                if (data.parentID == id) {
                    this.subdeptlist[id].push(data);
                }
            });
        },
        async getsubdepartments(id, deptname, index) {
           // this.isusercanaddfolder=true
            store.dispatch("selectFolder", "");
            if (id == this.currentselecteddepartment) {
                store.dispatch("setDepartment", "");
                store.dispatch("setMainDepartment", "");
                this.bolddept = "";
                this.departmentswitch = "";
            }
            else {
                this.folderfordocument = null;
                this.departmentswitch = id;
                this.currentdepartment = id;
                this.currentselecteddepartment = id;
                this.getselecteddepartments(id);
                this.getalldepartmentfolders(id);
                this.getdocuments(id);
                this.getallsubdepartments(id);
                this.bolddept = id;
                store.dispatch("setDepartment", id);
                store.dispatch("setMainDepartment", id);
                // this.subdeptlist[id] = [];
                // this.firstdeptlist.forEach((data) => {
                //     if (data.parentID == id) {
                //         this.subdeptlist[id].push(data);
                //     }
                // });
                console.log("vld");
                console.log(this.subdeptlist[id]);
            }
        },
        async getallsubdepartments(id){
            const idtoken = store.getters.getToken;
            await axios
                .get(
                //   `https://localhost:5001/department/getsubdepartments?departmentid=${id}&PageSize=2000`, {
                  `${this.baseurl}department/getsubdepartments?departmentid=${id}&PageSize=2000`, {
                    headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                  this.subdeptlist[id] = res.data.entities;
                   
                })
                .catch((err) => {
                    console.log("ERROR",err.response.data.messages)
                })
        },
        async getDeptfolderId(id, deptname, index) {
            this.deptvalues.DeptName = deptname;
            if (this.deptBlock == false) {
                this.deptBlock = !this.deptBlock
            }
            this.list = {}
            if (this.deptvalues.orgId == this.org[0].id) {
                this.deptvalues.orgId = id
            }
            else {
                if (this.deptvalues.orgId == id) {
                    this.deptvalues.orgId = ""
                }
                else
                    this.deptvalues.orgId = id
            }
            this.getSubDeptId(id, index)
            await axios
                .get(`${this.baseurl}/folder/getprivilegedroots?storageId=${id}`, {

                    headers: {
                        Authorization: "Bearer " + this.idtoken,
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    this.list = res.data.entities[0];
                    this.deptBlockname = this.list.name

                })
                .catch((err) => {
                    console.log(err.message)
                })
        },
        async getDeptId(id, deptname, index) {

            this.deptvalues.DeptName = deptname;
            if (this.deptBlock == false) {
                this.deptBlock = !this.deptBlock
            }

            this.list = {}
            if (this.deptvalues.orgId == this.org[0].id) {
                this.deptvalues.orgId = id
            }
            else {
                if (this.deptvalues.orgId == id) {
                    this.deptvalues.orgId = ""
                }
                else
                    this.deptvalues.orgId = id
            }

            this.getSubDeptId(id, index)
            const idtoken = store.getters.getToken;
            await axios
                .get(`${this.baseurl}department/searchbyprivilege?id=${id}`,
                {
                    headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",
                    },
                  })
                .then((res) => {
                    this.list = res.data.entities[0];
                    this.deptBlockname = this.list.name
                })
                .catch((err) => {
                    console.log(err.message)
                })
        },
        async getSubDeptId(id, index) {
            const idtoken = store.getters.getToken;
            this.sublist[index] = {}
            await axios
                .get(`${this.baseurl}department/searchbyprivilege?id=${id}`,
                {
                    headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",
                    },
                  })
                .then((res) => {
                    this.sublist[index] = res.data.entities[0];
                })
                .catch((err) => {
                    console.log(err.message)
                })
        },
        async updateDeptName() {
            this.recive = false;
            this.buttonretrieve = false;
            this.buttonrecive = true;
            this.recivedep = true;
            this.values.editDeptName = this.list.name;
        },

        async deleteDept() {
            if (
                confirm("Are you sure you want to delete this department ?")
            ) {
                await axios
                    .delete(
                        `${this.baseurl}department/delete?Id=${this.list.id}`,
                    )
                    .then(async (res) => {
                        alert("Department deleted successfully")
                        this.getDeptId();
                    });
            }
        },
        // async setFolderPrivileges(){
        //     // Folder PopUp Privileges
        //     let allFolderPriv = {
        //         "Departments Create Folder":"Add",
        //         "Departments Edit Folder" : "Edit",
        //         "Departments Move Folder" :"Move",
        //         "Departments Folder Permissions" :"Permissions",
        //         "Departments Folder File Format" :"File Format",
        //         "Departments Share Folder" :"Share",
        //         "Departments Delete Folder" :"Delete",
        //         "Departments Archive Folder":"Archive"
        //     }

        //     const privilegeArray = this.designationPrivileges.split(',').map((s) => s.trim());
        //     const keys = Object.keys(allFolderPriv);

        //     // Return Privileged Folder controlls.
        //     this.desigFolderPrivileges = keys
        //     .filter((key) => privilegeArray.includes(key))
        //     .map((key) => allFolderPriv[key]).join(',');
        // }
    },
};