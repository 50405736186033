/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import store from "../../store/index";
// import moment from "moment";
// import { ref } from 'vue'
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    patid: {
      type: String,
      default: "",
    },
    bolddept:{
      type: String,
      default: "",
    },
    opendepartmentlist: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      opendeptmnt:"",
      setofopendepartments: [],
      departmentswitch:{},
      subdeptlist: {},
      subdept:{},
      firstdeptlist: [],
      baseurl:`${process.env.VUE_APP_Service_URL}configuration/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      patientid: "",
      listdata: [],
      firstclassname: this.patid,
      folderbyid: "",
      showDetails:false,
      arrayfolders: [],
      deptBlockname: "",
      departments: {},
      foldertreelist:{},
      currentselectedfolder:""
    };
  },
  watch: {
    "$store.getters.getselectedFolder": function (val) {
        this.currentselectedfolder = val;
        },
  },
  async beforeMount() {
    this.getalldepartments();
  },
  async mounted() {  
    this.opendeptmnt = this.opendepartmentlist;
    this.getopendeptlist();
  },
  methods: {
    async getopendeptlist(){
      const idtoken = store.getters.getToken;
        await axios
            .get(
                `${this.mainapi}configuration/department/search?id=${this.opendepartmentlist}&PageSize=100`,
            {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              })
            .then((res) => {
              console.log("openfolderlist ", res);
                if (res.data.entities[0].name) {
                  console.log("setofdepartments = ", res.data.entities[0].ancestorIds)
                    this.setofopendepartments = res.data.entities[0].ancestorIds;
                }
            })
            .catch((err) => {
                console.log(err.response.data.messages)
            })
            this.opendeptartments();

    },
    async opendeptartments(){
      console.log("setofdepartments for subdept = ", this.setofopendepartments);
      (this.setofopendepartments).forEach(async (eachdept) => {
          this.departmentswitch[eachdept] = true;
          await this.getsubdepartments(eachdept);

      })
      await this.getsubdepartments(this.opendepartmentlist);
    },
    async getalldepartments(){    
      const deID=store.getters.getDepartment
      // store.dispatch("setDepartment", deID);
      console.log('department  id isSSS',deID);
      const orgId=store.getters.getOrganisationID;
      const idtoken = store.getters.getToken;
            await axios
                .get(
                    `${this.baseurl}department/getsubdepartments?departmentid=${deID}&PageSize=2000`, {

                    headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                  this.subdeptlist[deID] = res.data.entities;
                    console.log("folders arguments"+res.data.entities)
                    console.log("deID",this.subdeptlist[deID]);
                    res.data.entities.map((item)=>{
                    this.checksubdepartments(item.id)
                    })
                   
                })
                .catch((err) => {
                    console.log("ERROR",err.response.data.messages)
                })
      
  },

    async getsubdepartments(id){
      // store.dispatch("setDepartment", id);
      console.log(id);
      const idtoken = store.getters.getToken;
      await axios
          .get(
              `${this.baseurl}department/getsubdepartments?departmentid=${id}&PageSize=2000`, {
            headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
          })
          .then((res) => {
            this.subdeptlist[id] = res.data.entities;          
            
              
          })
          .catch((err) => {
              console.log("ERROR",err.response.data.messages)
          })
    },

    async getfolder(id, name) {
      store.dispatch("selectFolder",id); 
      this.foldertreelist[name] = [];  
      await axios  
          .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${id}`, {
          })
          .then((res) => {
              this.foldertreelist[name] = res.data;
              console.log("find me", this.foldertreelist);
          })
          .catch((err) => {
              console.log(err.message)
          })
  },

    async getDeptId(id, index, name) {
     this.opendeptmnt = "";
      if(this.departmentswitch[id]){
        this.departmentswitch[id] = !this.departmentswitch[id];
    }
    else{
     
        this.departmentswitch[id] = true;
        this.getsubdepartments(id);
    }
        store.dispatch("setDepartment",id);  
        this.departments[name] = [];

        await axios
            .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${id}`, {
            })
            .then((res) => {
                this.departments[name] = res.data;
                console.log("find me", this.departments);
            })
            .catch((err) => {
                console.log(err.message)
            })
    }
},
};