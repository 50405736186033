/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import VPagination from "@hennge/vue3-pagination";
import Multiselect from "@vueform/multiselect";
import { encrypt } from "../../common/encryption"
import { data } from "jquery";
export default {
  name: 'manageTrainingPlan',
  components: { VPagination, Multiselect },
  props: {
    refreshFlag: Boolean // Define a prop to trigger refresh
  },
  watch: {
    refreshFlag(newVal) {
      if (newVal) {
        this.refreshContent();
      }
    }
  },
  computed: {
    dateToday() {
      // Calculate the minimum allowed date (today's date) with time zone information
      const today = new Date();
      const year = today.getUTCFullYear();
      const month = String(today.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const day = String(today.getUTCDate()).padStart(2, '0');
      const hours = String(today.getUTCHours()).padStart(2, '0');
      const minutes = String(today.getUTCMinutes()).padStart(2, '0');
      const seconds = String(today.getUTCSeconds()).padStart(2, '0');
      const milliseconds = String(today.getUTCMilliseconds()).padStart(3, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    },
  },
  data() {
    return {
      encryptedPassword: "",
      designationPrivileges: [],
      showMoreStatesSkills: {},
      showMoreStates: {},
      searchActualstartdate: "",
      searchActualenddate: "",
      searchPlannedstartdate: "",
      searchPlannedenddate: "",
      searchStatus: "",
      baseurl: `${process.env.VUE_APP_Service_URL}`,
      fromDate: "",
      toDate: "",
      idtoken: "",
      totalPages: 1,
      pageIndex: 1,
      pageSize: 10,
      currentPage: 1,
      sortProperty: "createdAt",
      UserSortProperty: "firstName",
      SortOrderInt: 0,
      sortorder: 1,
      tableview_heading: "Training Plan and Record for",
      username: "username",
      data: [],
      showSidePopup: [false],
      showDateBlock: false,
      columnDefs: [
        {
          name: "select",
          header: "Select All"
        },
        {
          name: "userName",
          header: "Employee"
        },
        {
          name: "skills",
          header:
            "Required Knowledge Skills Abilities <br><small>(Managers populate the plan for an individual)</small>"
        },
        {
          name: "trainingDetails",
          header: "Training Details",
        },
        {
          name: "trainingMethod",
          header:
            "Training Activities Identified <br><small>(indicate method of training)</small>",
        },
        {
          name: "plannedDateRange",
          header: "Planned Training Date <br><small>( DD-MMM-YYYY)</small>",
        },
        {
          name: "actualDateRange",
          header: "Actual Training Date <br><small>( DD-MMM-YYYY)</small>",
        },
        {
          name: "trainedBy",
          header: "Trained by"
        },
        {
          name: "signature",
          header: "Training Completed Sign & Date <br><small>(Trainer or Line Manager)</small>",
        },
      ],
      createTestPlan: {
        userIds: [],
        trainingId: "",
        skills: "",
        details: "",
        plannedStartDate: null,
        plannedEndDate: null,
        actualStartDate: null,
        actualEndDate: null,
        trainingMethod: "Self",
        trainedBy: "",
        signerEmail: "",
        signerPassword: "",
      },
      signerReasons: "",
      otherReason: "",
      reason: "",
      reasonList: [],
      signDate: "",
      signerPassword: "",
      errors: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
      trainingId: [],
      userList: "",
      dropDownUserList: "",
      showModal: false,
      trainerList: [],
      designationList: [],
      designation: [],
      userName: "",
      searchDate: "",
      visibleOn: false,
      inputType: "password",
      selectedRows: [],
      selectAll: false,
      totalCount: "",
      storedValue: [],
      selectBoolean: false,
      selectedItemIds: [],
      removedItemIds: []
    }
  },
  async mounted() {
    this.idtoken = store.getters.getToken;
    this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    await this.getTrainingList();
    await this.listusers();
    await this.getalldesignation();
    await this.listTrainingUser([]);
    await this.getReasons();
  },

  methods: {
    refreshContent() {
      console.log("Refreshed addTraining")
      this.getTrainingList();
    },
    truncateTextSkills(text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    },
    toggleShowMoreSkills(index) {
      this.showMoreStatesSkills = {
        ...this.showMoreStatesSkills,
        [index]: !this.showMoreStatesSkills[index]
      };
    },
    truncateText(text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    },
    toggleShowMore(index) {
      this.showMoreStates = {
        ...this.showMoreStates,
        [index]: !this.showMoreStates[index]
      };
    },
    formatDateTime(dateTimeString) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const dateTime = new Date(dateTimeString);
      const day = dateTime.getDate().toString().padStart(2, '0');
      const month = months[dateTime.getMonth()];
      const year = dateTime.getFullYear();
      const hours = dateTime.getHours().toString().padStart(2, '0');
      const minutes = dateTime.getMinutes().toString().padStart(2, '0');

      return `${day}${month}${year} | ${hours}:${minutes}`;
    },


    NeededDateRange(startDate, endDate) {
      if (startDate === null || endDate === null) {
        return ''; // Return an empty string if either start or end date is null
      }

      const start = new Date(startDate);
      const end = new Date(endDate);

      const options = { day: '2-digit', month: 'short', year: 'numeric' };

      if (start.getTime() === end.getTime()) {
        return this.formatDate(start, options).toUpperCase(); // Return single date
      } else {
        const formattedStart = this.formatDate(start, options);
        const formattedEnd = this.formatDate(end, options);
        return `${formattedStart.toUpperCase()}\nto\n${formattedEnd.toUpperCase()}`;
      }
    },

    formatDate(date, options) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    dateBlockDisplay() {
      const cardBody = document.getElementById("cardtrainingBody")
      this.showDateBlock = !this.showDateBlock
      if (this.showDateBlock) {
        cardBody.style.height = '94px'
        this.listAllEmployees()
      }
      else {
        cardBody.style.height = '0px'
      }
    },
    async clearValues() {
      this.searchActualstartdate = "";
      this.searchActualenddate = "";
      this.searchPlannedstartdate = "";
      this.searchPlannedenddate = "";
      this.searchStatus = "";
      this.userName = "";
      await this.onSearchClicked()
    },
    eachRowSelection(id, index) {
      if (!this.selectedRows[index]) {
        this.selectedItemIds.push(id);
        this.removedItemIds = this.removedItemIds.filter(itemId => itemId !== id);
      }
      else {
        this.removedItemIds.push(id);
        this.selectedItemIds = this.selectedItemIds.filter(itemId => itemId !== id);
      }
    },
    selectAllRows() {
      if (this.selectAll) {
        this.removedItemIds = [];
        this.selectedRows = [];
        this.storedValue = [];
        this.data.forEach((element, index) => {
          this.storedValue[index] = element.id;
          this.selectedRows[index] = true;
        });
        this.selectBoolean = false;
      }
      else {
        this.selectedItemIds = [];
        for (var i = 0; i < this.totalCount; i++) {
          this.selectedRows[i] = false;
        }
        this.storedValue = [];
      }
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageIndex = page;
      this.getTrainingList();
    },
    async getReasons() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseurl}document/projectdevdocumenttemplate/getallreason`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.reasonList = res.data
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    async listAllEmployees() {
      await axios
        .get(
          `${this.baseurl}account-core/user/getlist?SortProperty=${this.UserSortProperty}&SortOrder=${this.SortOrderInt}&UserType=All`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.dropDownUserList = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.dropDownUserList = [];
        });
    },
    async getTrainingList() {
      await axios
        .get(
          `${this.baseurl}document/trainingplan/getalltraininglist?plannedstartdate=${this.searchPlannedstartdate}` +
          `&plannedenddate=${this.searchPlannedenddate}&actualstartdate=${this.searchActualstartdate}` +
          `&actualenddate=${this.searchActualenddate}&userName=${this.userName}&status=${this.searchStatus}` +
          `&SortProperty=CreatedAt&SortOrder=1`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          //  this.totalPages = parseInt(res.data.totalCount / this.pageSize);
          this.data = res.data.entities;
          this.totalCount = res.data.totalCount;
          this.selectedRows = [];
          for (var i = 0; i < this.totalCount; i++) {
            this.selectedRows[i] = false
          }
          // if (res.data.totalCount % this.pageSize != 0) {
          //   this.totalPages = this.totalPages + 1;
          // }
        }).catch((err) => {
          console.error(err);
          this.data = [];
        })
    },
    toDatePicker(isoDate) {
      if (isoDate !== null) {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      } else {
        return null;
      }
    },
    async closePopUp() {
      await this.clearCreatePopup();
      this.showModal = false;
      this.errors = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
    },
    async listusers() {
      await axios.post(`${this.baseurl}document/trainingplan/traininguser?exceptuserId=${this.createTestPlan.userIds}&Assign=true`, [], this.Head)
        .then((res) => {
          this.userList = res.data;
          console.log("list users", res)
        })
        .catch(err => console.log("error in list user", err))

    },
    async clearCreatePopup() {
      this.createTestPlan = {
        userIds: [],
        trainingId: "",
        skills: "",
        details: "",
        plannedStartDate: "",
        plannedEndDate: "",
        actualStartDate: "",
        actualEndDate: "",
        trainingMethod: "Self",
        trainedBy: "",
        signerEmail: "",
        signerPassword: "",
      }
      this.signDate = "",
        this.signerPassword = "";
      this.signerReasons = "";
      this.otherReason = "";
      this.designation = [];
    },
    async signTrainingPlan() {
      const idtoken = store.getters.getToken;
      if (this.signerReasons == "Others") {
        this.reason = this.otherReason
      }
      else {
        this.reason = this.signerReasons
      }
      await axios
        .post(
          `${this.baseurl}document/trainingplan/signmultipletraining?email=${this.createTestPlan.signerEmail}&password=${this.encryptedPassword}&reason=${this.reason}&date=${this.signDate}`, this.trainingId,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.$emit("signAdded", res.data);
          alert("Training plan created successfully.");
          this.closePopUp();
          this.getTrainingList();
        })
        .catch((err) => {
          console.log("Errrr", err)
          this.err = err.response.data.errors.DomainValidations[0];
          alert(this.err)
          this.createTestPlan.signerPassword = "";
          this.signerPassword = "";
        });
    },

    async createTrainingPlan() {
      await this.createValidation();
      this.encryptedPassword = await encrypt(this.signerPassword.trim());
      this.createTestPlan.signerPassword = this.encryptedPassword;
      if (!this.errors.includes(true)) {
        axios.post(`${this.baseurl}document/trainingplan/addtraining`, this.createTestPlan, this.Head)
          .then((res) => {
            this.trainingId = res.data;
            this.signTrainingPlan();
            console.log("create testplan", res.data);
          })
          .catch(err => {
            console.log("error in create test plan", err);
            alert(err.response.data.errors.DomainValidations[0]);
            this.signerPassword = "";
          });
      }
      console.log("date is ", this.errors);

    },
    async openPopup() {
      this.showModal = true;
      await this.clearCreatePopup();
    },
    async onSearchClicked() {
      this.currentPage = 1;
      this.pageIndex = 1;
      await this.getTrainingList();
    },
    async listTrainingUser(designations) {
      await axios.post(`${this.baseurl}document/trainingplan/traininguser?exceptuserId=${this.createTestPlan.trainedBy}&Assign=false`, designations, this.Head)
        .then((res) => {
          this.trainerList = res.data;
          console.log("User list", res.data);
        })
        .catch(err => console.log("error in list traing user", err));
    },
    async getalldesignation() {
      await axios
        .get(`${this.baseurl}configuration/designation/search?status=active&PageSize=10000&SortProperty=displayName`,
          this.Head)
        .then((res) => {
          this.designationList = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async trainerChange() {
      this.createTestPlan.userIds = [];
      await this.listTrainingUser(this.designation);
    },

    // -- Validations -- 
    async nullValidation(index, value) {
      if (value == "" || value == null) {
        this.errors[index] = true;
      }
    },
    async isDatePrior(index, firstDate, secondDate) {
      if (secondDate < firstDate) {
        this.errors[index] = true;
      }
    },
    async isListEmpty(index, list) {
      if (list.length == 0) {
        this.errors[index] = true;
      }
    },
    async createValidation() {
      this.errors = [false, false, false, false, false, false, false, false, false, false, false, false, false, false];
      await this.nullValidation(0, this.createTestPlan.skills);
      await this.nullValidation(1, this.createTestPlan.plannedStartDate);
      await this.nullValidation(2, this.createTestPlan.plannedEndDate);
      await this.isDatePrior(3, this.createTestPlan.plannedStartDate, this.createTestPlan.plannedEndDate);
      await this.nullValidation(4, this.createTestPlan.actualStartDate);
      await this.nullValidation(5, this.createTestPlan.actualEndDate);
      await this.isDatePrior(6, this.createTestPlan.actualStartDate, this.createTestPlan.actualEndDate);
      await this.nullValidation(7, this.createTestPlan.trainingMethod);
      await this.nullValidation(8, this.createTestPlan.trainedBy);
      await this.isListEmpty(9, this.createTestPlan.userIds);
      await this.nullValidation(10, this.signDate);
      await this.nullValidation(11, this.createTestPlan.signerEmail);
      await this.nullValidation(12, this.signerPassword);
      await this.signDateValidation(13, this.signDate);
      await this.nullValidation(14, this.signerReasons);
    },
    async signDateValidation(index, value) {
      if (value != "") {
        if (value < this.createTestPlan.actualEndDate) {
          this.errors[index] = true;
        }
      }
    },
    passwordVisible() {
      this.visibleOn = !this.visibleOn
      if (this.visibleOn == true) {
        this.inputType = "text"
      }
      else this.inputType = "password"

    },
    async exportTraining() {
      try {
        this.selectBoolean = this.selectAll == true ? true : false
        const response = await fetch(
          `${this.baseurl}document/trainingplan/trainingexportbyid?userId=${this.userName}` +
          `&plannedstartdate=${this.searchPlannedstartdate}&plannedenddate=${this.searchPlannedenddate}` +
          `&actualstartdate=${this.searchActualstartdate}&actualenddate=${this.searchActualenddate}` +
          `&status=${this.searchStatus}&selection=${this.selectAll}`,
          {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + this.idtoken,
              'Content-Type': 'application/json' // Assuming you're sending JSON data
            },
            body: this.selectAll ? JSON.stringify(this.removedItemIds) : JSON.stringify(this.selectedItemIds) // Assuming selectedRows is the data you want to send
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        var x = response.headers.get("Content-Disposition");
        var xx = x.split("filename=")[1];
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        let filename = xx.split(";")[0];
        const name = filename.replace(/"/g, "");
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // this.selectedRows = "";
        // this.selectAll = false;
        //this.selectedItemIds
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        alert("No data found !");
      }
    }

  }
};