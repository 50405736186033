<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-10 col-md-10">
      <h2 class="my-0 py-0 align-self-center">Document Summary</h2>
    </div>
    <div class="col-md-2 align-self-center">
      <i class="fa fa-times close cursor-pointer" @click="closebutton()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox-title style_2 d-flex justify-content-between px-3">
          <h5 class="my-0 py-0 align-self-center">{{ documentTitle }}</h5>
        </div>
        <div class="ibox-content">
          <div class="content-block">
            <div v-if="isLoading" class="loading-spinner">
              <div class="spinner"></div>
            </div>
            <div v-if="imgFormat.includes(format)">
              <img :src="source" alt="Italian Trulli" class="max-width" />
            </div>
            <div v-if="format == 'pdf'" class="pdfembeded">
              <vue-pdf-embed :source="source" />
            </div>
            <div v-if="office.includes(format)" class="outsideframe">
              <div
                id="hiddata"
                v-bind:style="
                  officeurl == '' ? 'display:block;' : 'display:none;'
                "
              >
                <span
                  v-if="!istherefiletoview"
                  style="font-weight: 500; padding-left: 30%"
                  >Blank document - Nothing to display
                </span>
              </div>
              <iframe
                id="printDiv"
                :key="officeurl"
                :src="officeurl != null ? officeurl : ''"
                width="100%"
                height="650px"
                frameborder="0"
                Cache-Control:
                no-cache
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <!--col-lg-8 close-->
    </div>
  </div>
  <adobe_iframe
    v-if="showAdobe_iframe"
    :iframeurl="adobeUrl"
    @iframeclosemodel="showAdobe_iframe = false"
  >
  </adobe_iframe>
</template>
<script>
import adobe_iframe from "../adobe_iframe/adobe_iframe.vue";
import axios from "axios";
import VuePdfEmbed from "vue-pdf-embed";
import print from "vue3-print-nb";
import store from "../../store/index";
import { encrypt } from "../../common/encryption"
export default {
  props: {
    documentid: String,
    version: String,
    versionType: String,
    storageId: String,
  },
  components: {
    VuePdfEmbed,
    adobe_iframe,
  },
  directives: {
    print,
  },
  data() {
    return {
      isLoading: true,
      errmsg: "",
      errmsgshow: false,
      showAdobe_iframe: false,
      signVersion: "",
      imgFormat: ["PNG", "png", "jpeg", "jpg"],
      setpreviouspath: "",
      versionfrom: "",
      prevRoute: null,
      sourcePage: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      documentUrl: `${process.env.VUE_APP_Service_URL}`,
      office: ["xls", "xlsx", "doc", "DOC", "docx", "DOCX"],
      format: "",
      source: "",
      officeurl: "",
      title: "",
      documentTitle: "",
      projectid: "",
      dropdownname: "",
      fromsource: "",
      updatedBy: "",
      uploadedBy: "",
      standard: "",
      owner: "",
      type: "",
      idtoken: "",
      apiresult: "",
      validation: false,
      errorMsg: false,
      error: [false, false],
      err: "",
      sourceRoute: "",
      istherefiletoview: true,
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log("From is", from);
    if (
      from.name == undefined &&
      localStorage.getItem("currenttab") == "development"
    ) {
      store.dispatch("setDocSign", true);
    }

    next((vm) => {
      vm.prevRoute = from;
    });
  },
  async mounted() {
    window.addEventListener("message", this.handleIframeMessage);
    this.sourcePage = await store.getters.getDocViewSource;
    console.log(this.sourcePage, "this.sourcePage");
    if (this.sourcePage == `Project` || this.sourcePage == "ProjectSummary") {
      this.sourceRoute = `Projects`;
      this.fromsource = "ProjectSummary";
      this.dropdownname = "ProjectRole";
      console.log(this.dropdownname, "PAGE NAME");
    } else if (this.sourcePage == `Department`) {
      this.sourceRoute = `Departments`;
      this.fromsource = "Department";
      this.dropdownname = "Department";
      console.log(this.dropdownname, "PAGE NAME");
    } else if (this.sourcePage == "generalFiles") {
      this.versionfrom = "generalFiles";
      this.dropdownname = "generalFiles";
    }
    if (this.prevRoute != null) {
      if (this.prevRoute.path != "/") {
        localStorage.setItem("patvalue", this.prevRoute.path);
      }
      this.setpreviouspath = localStorage.getItem("patvalue");
      console.log("this.setpreviouspath", this.setpreviouspath);
      if (this.setpreviouspath == "/department") {
        this.versionfrom = "fromdepartment";
      } else if (this.setpreviouspath == "/generalFiles") {
        this.versionfrom = "generalFiles";
      } else if (this.setpreviouspath == "/dashboard") {
        console.log(this.versionId);
        this.versionfrom = "dashboard";
      } else {
        this.versionfrom = "fromproject";
      }
      if (this.setpreviouspath == "/generalFiles") {
        this.versionfrom = "generalFiles";
      }
    }
      if (this.sourcePage == "Approval") {
        this.idtoken = store.getters.getToken;
        await this.versionDocType();
        await this.getDocumentdetails();
      } else {
        store.dispatch("setdocumenttodepartment", true);
        this.idtoken = store.getters.getToken;
        await this.getDocumentdetails();
        await this.settype();
        await this.setdoc();
      }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != "versioncodereview" && to.name != "versionunittest") {
      store.dispatch("setDocSign", false);
      localStorage.setItem("devDocType", "");
    }
    next();
  },
  methods: {
    async closebutton() {
    
        this.$router.push(`/sharedfolder`);

      },
    handleUrlReceived(url) {
      this.adobeUrl = url;
      this.showAdobe_iframe = true;
      console.log("Hellooooo Url", url);
    },
    handleIframeMessage(event) {
      if (event.origin == "https://secure.in1.adobesign.com") {
        // alert("signoption");
        console.log("event issss", event);
        if (event.data.includes('"type":"ESIGN"')) {
          alert("Successfully signed");
          this.showAdobe_iframe = false;
        }
      }
    },
    async getDocumentdetails(version) {
      await axios
        .get(
          `${this.baseUrl}document/document/getdocumentdetails?documentId=${
            this.documentid
          }${version ? `&version=${version}` : ""}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("res.data.storageVersionId", res.data.storageVersionId);
          this.signVersion = res.data.storageVersionId;
          (this.title = res.data.name),
            (this.documentTitle = res.data.documentTitle),
            (this.standard = res.data.standard),
            (this.uploadedBy = res.data.uploadedBy),
            (this.owner = res.data.owner),
            (this.type = res.data.type),
            (this.updatedBy = res.data.uploadedBy);
          if (
            this.setpreviouspath == "/dashboard" ||
            this.setpreviouspath == "/doclogin/"
          ) {
            console.log(res.data.folderId);
            store.dispatch("selectFolder", res.data.folderId);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async versionDocType() {
      await axios
        .get(
          `${this.baseUrl}document/document/file/${this.documentid}?version=${this.version}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let contentdispo = res.headers["content-disposition"];
          this.format = contentdispo.split(".").pop();
          console.log("fileformat", this.format);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    async settype() {
      console.log("Testttttt", this.source);
      await axios
        .get(
          `${this.baseUrl}document/document/getanonymous?documentId=${this.documentid}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let contentdispo = res.headers["content-disposition"];
          this.format = contentdispo.split(".").pop();
          console.log("fileformat", this.format);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },

    async setdoc() {
      console.log("this.idtoken", this.idtoken);
      console.log(this.referenceId + " " + this.version);
      this.isLoading = false;
      if (this.setpreviouspath == "/dashboard" && this.version != "0") {
        this.source = `${this.baseUrl}document/document/file/${this.documentid}?version=${this.version}`;
      } else if (this.setpreviouspath == "/doclogin/" && this.version != "0") {
        this.source = `${this.baseUrl}document/document/file/${this.documentid}?version=${this.version}`;
      } else {
        this.source = `${this.baseUrl}document/document/file/${this.documentid}?version=${this.signVersion}`;
      }
      console.log("Testttttt", this.source);
      console.log("data is", this.source);
      await axios
        .get(
          `${this.baseUrl}document/document/getanonymous?documentId=${this.documentid}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data != "") {
            this.istherefiletoview = true;
            this.officeurl = `https://view.officeapps.live.com/op/embed.aspx?src=${this.source}`;
            console.log("this.officeurl", this.source);
          } else {
            this.istherefiletoview = false;
          }
        })
        .catch((err) => {
          console.log("error response is", err.response.data);
        });
    },
  },
};
</script>
<style scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.pdfembeded {
  height: 700px;
  overflow: auto;
}

.max-width {
  max-width: 100%;
}

.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.table-bottom > thead > tr > th,
.table-bottom > tbody > tr > th,
.table-bottom > tfoot > tr > th,
.table-bottom > thead > tr > td,
.table-bottom > tbody > tr > td,
.table-bottom > tfoot > tr > td {
  border-top: 0px solid #e7eaec;
  line-height: 1;
  padding: 6px;
  vertical-align: middle;
}

.table-bottom {
  width: 100%;
  margin-top: 0px !important;
  color: #212529;
  border-collapse: collapse;
}

.hiddata {
  display: none;
}

.more {
  font-size: 11px;
  color: #618bd7;
}

.less {
  font-size: 11px;
  color: #618bd7;
}

.breakWord {
  word-break: break-word;
}

.graphbtncontainer {
  display: flex;
  gap: 5px;
}

.graphbutton {
  width: fit-content;
  padding: 3px;
  cursor: pointer;
}
.add_page_modal_wrapper,
.modal__wrapper__component {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}
.add_page_modal__containers {
  width: min(400px, 90%);
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.add-block-title {
  color: #ffffff;
  background: #768dd3;
}
.errorClass {
  color: red;
  font-size: 13px;
}
.errmsg {
  font-size: 12px;
  color: red;
  float: left;
}
.password-popup {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.password-popup-header {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.password-visible-block {
  right: 11px;
  top: 10px;
}
</style>
