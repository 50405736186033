<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="py-0 my-0">{{ organization }}</h2>
    </div>
    <div class="col-2 col-md-2"></div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-12 col-md-4 mr-0 pr-md-0 mb-1">
        <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between px-3">
            <h5 class="align-self-center my-0 py-0">Departments</h5>
            <div
              class="position-relative my-0 py-0 align-self-center"
              v-if="
                designationPrivileges.includes(`Departments Create Department`)
              "
            >
              <img
                class="cursor-pointer"
                src="../../assets/img/create.png"
                width="20"
                alt="create organization"
                @click="createDeptfunc('createDept', 'deptonly')"
              />
              <div class="tool-tip">Create Department</div>
            </div>
          </div>
          <div class="ibox-content scrollable-dept">
            <ul class="tree my-1 pl-0">
              <li
                v-for="(searchdept, index) in maindepartments"
                :for="searchdept.id"
                :key="searchdept.id"
              >
                <i
                  v-if="subdeptlist[searchdept.id]"
                  class="fs-4 mr-1 cursor-pointer"
                  :class="[
                    departmentswitch == searchdept.id
                      ? 'fa fa-caret-down'
                      : 'fa fa-caret-right',
                  ]"
                  @click.prevent="
                    getsubdepartments(searchdept.id, searchdept.name, index)
                  "
                ></i>
                <i v-else class="fa fa-caret-right text-white mr-1"></i>
                <input
                  type="checkbox"
                  :id="searchdept.id"
                  @change="
                    getsubdepartments(searchdept.id, searchdept.name, index)
                  "
                />
                <label
                  :for="searchdept.id"
                  :class="
                    bolddept == searchdept.id
                      ? 'tree_label_bold boldlabel'
                      : 'tree_labels'
                  "
                  >{{ searchdept.name }}
                </label>
                <TreeBrowser
                  v-if="departmentswitch == searchdept.id || currentancesterids.includes(searchdept.id) "
                  :list="subdeptlist[searchdept.id]"
                  :bolddept="maindeptbold"
                  :opendepartmentlist="opendepartmentlist"
                  patid=""
                >
                </TreeBrowser>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 px-md-1">
        <div class="ibox mb-2">
          <div class="ibox-title style_2 d-flex justify-content-between px-3">
            <h5 class="my-0 py-0 align-self-center">
              {{ selectedfoldername }}
            </h5>
           
            <div
              class="position-relative align-self-center"
              v-if="
                designationPrivileges.includes(`Departments Create Folder`) &&
                ( isusercanaddfolder === true)
              "
            >
              <img
                v-if="
                  currentselecteddepartment != 0 &&
                  currentselecteddepartment != null &&
                  currentselecteddepartment != ''
                "
                class="cursor-pointer"
                src="../../assets/img/create.png"
                alt="create department"
                width="20"
                @click="createfolderfunc()"
              />
              <div class="tool-tip">Create Folder</div>
            </div>
          </div>
          <div class="ibox-content scrollable-dept" id="primaryTree">
            <!-- Folder contents -->
            <ul class="tree my-1 pl-0">
              <li v-if="selectedfoldername">
                <input
                  type="checkbox"
                  :id="selectedfolderid"
                  @change="backtorootfolder(selectedfolderid)"
                />
                <label
                  :class="
                    mainfolderbold == ''
                      ? 'tree_labels tree_label_bold'
                      : 'tree_labels'
                  "
                  @click="backtorootfolder(selectedfolderid)"
                  >{{ selectedfoldername }}
                </label>
                <TreeFolder
                  projectId=""
                  :list="folderlist"
                  :boldfolder="mainfolderbold"
                  :openfolderlist="openfolderlist"
                  patid=""
                  SourcePage="department"
                  @reloadFolders="getalldepartmentfolders(selectedfolderid)"
                  @changePerm="getFolderUserpermission($store.getters.getselectedFolder)"
                >
                </TreeFolder>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 px-md-0">
        <div class="ibox mb-2">
          <div class="ibox-title style_2 d-flex justify-content-between px-3">
            <h5 class="my-0 py-0 align-self-center">Documents</h5>
            <div
              class="position-relative align-self-center"
              v-if="
                designationPrivileges.includes(`Departments Add Document`) &&
                isuserenabled === true
              "
            >
              <img
                v-if="folderfordocument"
                class="cursor-pointer"
                src="../../assets/img/create.png"
                alt="create department"
                width="20"
                @click="createdoc()"
              />
              <div class="tool-tip">Add Document</div>
            </div>
          </div>
          <div class="ibox-content scrollable-dept" id="primaryDocumentTree">
            <div class="table-responsive">
              <table
                v-if="documentlist" 
                class="table table-bordered dataTables"
              >
                <tbody>
                  <tr
                    v-for="(documents, index) in documentlist"
                    :key="documents.id"
                  >
                    <td @click="openDocs(documents.id,documents.latestVersion)">
                      <a class="cursor-pointer"> {{ documents.name }}</a>
                    </td>
                    <td class="text-center" width="40">
                      <div
                        class="more-option-icon cursor-pointer px-2"
                        @click.prevent="
                          sidePopup(
                            index,
                            documents.id,
                            documents.name,
                            'file',
                            event
                          )
                        "
                        v-on:clickout="showBlock = false"
                      >
                        <i class="fa fa-ellipsis-v"> </i>
                      </div>
                    </td>
                    <SidePopUp
                      v-if="showBlock && popupid == documents.id"
                      :blockid="popupid"
                      :index="arrayIndex"
                      :ev="event"
                      :dest="'docs'"
                      tab="department"
                      v-on:clickout="showBlock = false"

                    >
                     <div  v-if="this.anyuserpermissions" style="pointer-events: none; ">Nothing to show</div>
                      <!-- <div @click="NewDocVersion(documents.id)">
                        Add new version
                      </div> -->
                      <div v-if="sendtoValue">Clone</div>
                      <div v-if="editValue" @click="DocEditPopup(documents.id,documents.latestVersion)">Edit</div>
                      <!-- <div @click="openDocs(documents.id)">View</div> -->
                      <!-- <div @click="openViewResult(documents.id)">
                        View Exam Result
                      </div>  -->
                      <!-- <div @click.prevent="openExam(documents.id)">
                        Configure exam
                      </div> -->
                      <div v-if="permissionsValue" @click="FilePermissionPopup(documents.id)">
                        Permissions
                      </div>
                      <!-- <div
                        @click="
                          documentApproval(
                            documents.id,
                            documents.latestVersiond
                          )
                        "
                      >
                        Approval
                      </div> -->
                      <!-- <div @click="$store.dispatch('setsendfile', true)">Send File</div> -->
                      <div v-if="shareValue" @click="setfilesendpopup(documents.id)">Share</div>
                      <div v-if="deleteValue" @click="DeleteDocument(documents.id)">Delete</div>
                      <div v-if="archiveValue" @click="ArchievedDocument(documents.id)">
                        Archive
                      </div>
                    </SidePopUp>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Popup library -->
  <!-- create department starts -->
  <div class="create-folder-modal" v-if="showOrg == true">
    <div class="modal-dialog modal-width">
      <div class="modal-content">
        <div
          class="modal-header-department d-flex justify-content-bertween p-3"
        >
          <h5 class="my-0 align-self-center">Create Department</h5>
          <span
            type="button"
            class="align-self-center"
            data-dismiss="modal"
            @click="onclosed('createOrg')"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="input-group">
            <input
              type="text"
              placeholder="Enter Department Name"
              class="form-control"
              v-model="deptvalues.name"
              maxlength="75"
            />
            <span class="input-group-append">
              <button
                type="button"
                class="btn btn-primary"
                @click="createDept()"
              >
                Add
              </button>
            </span>
          </div>
          <span class="errorClass" v-if="errors[2] == false"
            >Please enter a department name
          </span>
        </div>
      </div>
    </div>
  </div>
  <PermissionPopUp
    v-if="$store.getters.getpermvalue"
    :filetype="$store.getters.getfileType"
    :blockname="$store.getters.getfileName"
    @closemodal="$store.dispatch('setpermvalue', false)"
  >
  </PermissionPopUp>
  <!-- <doc_Approval
    v-if="showDocumentApproval"
    :documentId="documentId"
    :version="version"
    :projectId="projectid"
    :dropdownName="dropdownname"
    @closeApproveModal="showDocumentApproval = false"
  >
  </doc_Approval> -->
  <!-- <fileSharePopup
    v-if="$store.getters.getsendfile"
    :filetype="$store.getters.getfileType"
    :blockname="$store.getters.getfileName"
    :folderid="$store.getters.getselectedFolder"
    :department="$store.getters.getDepartment"
    :docid="selecteddocid"
    @closemodal="$store.dispatch('setsendfile', false)"
  >
  </fileSharePopup> -->
  <teleport to="#app" >
  <fileSharePopup
    v-if="$store.getters.getsendfile"
    @closemodal="$store.dispatch('setsendfile', false)"
    :list="selecteddocid"
    :source="$store.getters.getfileType"
    :projectId="$store.getters.getDepartment"
  >
  </fileSharePopup>
  </teleport>
  <!-- <foldersendpopup
    v-if="$store.getters.getsendfolder"
    :filetype="$store.getters.getfileType"
    :blockname="$store.getters.getfileName"
    @closemodal="$store.dispatch('setsendfile', false)"
  >
  </foldersendpopup> -->
  <fileFormatPopUp
    v-if="$store.getters.getformatvalue"
    :filetype="$store.getters.getfileType"
    :blocknames="$store.getters.getfileName"
    @closemodal="$store.dispatch('setformatvalue', false)"
  >
  </fileFormatPopUp>
  <folderEditPopUp
    v-if="$store.getters.geteditvalue"
    :filetype="$store.getters.getfileType"
    :blocknamess="$store.getters.getfileName"
    :folderversion="$store.getters.getfolderVersion"
    @getFolder="getalldepartmentfolders(this.selectedfolderid)"
    @closemodal="$store.dispatch('seteditvalue', false)"
  >
  </folderEditPopUp>
  <!-- create department ends -->
  <!-- create folder starts -->
  <div class="create-folder-modal" v-if="createfolderpopup == true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div
          class="modal-header-department d-flex justify-content-bertween p-3"
        >
          <h4 class="my-0">Create Folder</h4>
          <span
            type="button"
            class="align-self-center"
            data-dismiss="modal"
            @click="onclosedfolderpopup()"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body">
          <label class="mb-0" for="designation"
            >Folder Name <sup><i class="fa fa-asterisk required"></i></sup
          ></label>
          <input
            type="text"
            class="form-control"
            v-model="foldervalues.name"
            maxlength="75"
          />
          <span class="errorClass" v-if="errors[3] == false"
            >Please enter a folder name
          </span>
          <div class="form-group pt-2">
            <div class="mb-2">
              <label class="mb-0" for="designation">Select File Format </label>
              <Multiselect
                name="format"
                valueProp="label"
                v-model="SelectedFormat"
                mode="multiple"
                placeholder="Select Format"
                :options="FormatList"
                :hideSelected="false"
                :closeOnSelect="false"
                class="multiselect-custom-theme"
              >
                <template v-slot:option="{ option }">
                  {{ option.label }}
                </template>
              </Multiselect>
            </div>
            <label class="col-form-label">Document version configuration</label>
            <div class="col-md-12 row">
              <div class="col-md-2">
                <div class="form-group">
                  <label><small>Prefix</small></label>
                  <input
                    maxlength="1"
                    id="password"
                    name="password"
                    type="text"
                    class="form-control required"
                    v-model="prefix"
                    aria-required="true"
                    v-on:keypress="isLetter($event)"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label><small>* Major ver.</small></label>
                  <input
                    maxlength="2"
                    id="password"
                    name="password"
                    type="text"
                    min="1"
                    max="99"
                    class="form-control required"
                    v-model="majorVerStart"
                    @keypress="isNumber($event, 'major')"
                    autocomplete="off"
                    aria-required="true"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label><small>Minor ver.</small></label>
                  <input
                    maxlength="2"
                    id="password"
                    name="password"
                    type="text"
                    class="form-control required"
                    v-model="minorVerStart"
                    @keypress="isNumber($event, '')"
                    autocomplete="off"
                    aria-required="true"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label><small>Minor Max.</small></label>
                  <input
                    maxlength="2"
                    id="password"
                    name="password"
                    type="text"
                    class="form-control required"
                    v-model="minorVermax"
                    @keypress="isNumber($event, '')"
                    autocomplete="off"
                    aria-required="true"
                  />
                </div>
              </div>
              <span class="errorClass" v-if="minverError == true"
                >Minor Max value must be greater than or equal to Minor version
                value
              </span>
              <span class="errorClass" v-if="majorverError == true"
                >Major version value must be greater than zero
              </span>
              <!-- <div class="col-md-1">
                
                <div class="mt-4" @click="saveVersion()">
                  <a href="#"><img src="../../assets/img/save_ver.png" /></a>
                </div>
              </div> -->
            </div>
            <button
              class="btn btn-primary btn-xs px-4 float-right"
              type="button"
              @click="createfolder()"
            >
              Save
            </button>
          </div>
          <strong> Document version :</strong>
          {{ prefix }} {{ majorVerStart
          }}<span v-if="majorVerStart && minorVerStart != ''">.</span
          >{{ minorVerStart }}
        </div>
      </div>
    </div>
  </div>
  <!-- create folder ends -->
  <!-- popup ends -->
  <!-- Popup library -->

  <DocumentPopup
    v-if="docupload == true"
    @uploadlibactive="uploadmethodactivity()"
    :department="selectedfoldername"
    :folderid="currentselectedfolder"
    :versionbyfolder="folderversion"
    :fromPage="'department'"
  >
  </DocumentPopup>

  <!-- popup ends -->

  <!-- permission popup -->
  <File_FolderPopup
    v-if="showPermPopup"
    @closemodal="showPermPopup = false"
    :list="popupid"
    :source="sourceType"
  >
  </File_FolderPopup>
  <!-- ends -->

  <!-- Doc Edit Popup -->
  <DocEditPopup
    v-if="editDocPopup"
    @editdocactive="uploadeditactivity()"
    :versionid="docversion"
    :department="selectedfoldername"
    :folderid="currentselectedfolder"
    :docId="popupid"
    :source="'department'"
  >
  </DocEditPopup>
  <!-- ends -->
  <NewDocVersion
    v-if="newdocversionPopup"
    @editdocactive="newversionuploadactivity()"
    :department="versionfrom"
    :folderid="currentselectedfolder"
    :docId="popupid"
  >
  </NewDocVersion>
</template>
<script src="./department.js"></script>
<style scoped>
@import "./treedept.css";

.fa-asterisk {
  color: #e56363 !important;
  font-size: 6px;
}
.more-option-icon:hover {
  background-color: #ededed;
}

.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.style_2 {
  background-color: #fff;
  border-radius: 0;
}

.errorClass {
  color: red;
  font-size: 13px;
}

.ibox-content {
  overflow-x: auto !important;
}

.ibox-title-org {
  background-color: #d9e4ff;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px;
  color: inherit;
  padding: 8px 15px 8px 15px;
  min-height: 48px;
  position: relative;
  clear: both;
  width: unset;
  border-radius: 5px 5px 0 0;
}

.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}

.title-class1 {
  background-color: #618bd7;
  color: #fff;
}

.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.gapbetween {
  margin-top: 5px;
  margin-left: 5px;
}

.groupupload {
  display: flex;
}

#fileLoader {
  display: none;
}

.tree_label_bold {
  font-weight: bold;
}

.fixedwidthrow {
  height: 10px;
}

.scrollable-dept {
  overflow-y: auto;
  height: 450px;
}

.modal-header-department {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-width {
  width: min(400px, 90%);
}
.fs-4 {
  font-size: 15px;
}
</style>