/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import store from "../../store/index";
import SidePopUp from "../side_pop_up/side_pop_up.vue";
import Multiselect from "@vueform/multiselect"
export default {
  name: "filesendpopup",
  components: {
    SidePopUp,
    Multiselect
  },
  props: {
    filetype: {
      type: String,
      default: ""
    },
    blockname: {
      type: String,
      default: ""
    },
    docid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      validationmsgs: [false, false],
      folderpermissions: [],
      userfilesendlist: [],
      userName: "",
      newUserList: [],
      newUserListtest: [],
      baseFileurl: ``,
      baseurl: `${process.env.VUE_APP_Service_URL}document/folderfilepermission/`,
      baseapi: `${process.env.VUE_APP_Service_URL}configuration/designation/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      userList: [],
      designationList: [],
      selectedDesignation: [],
      selectedUser: [],
      selectedPermission: [],
      permissionList: [],
      UserpermissionList: [],
      showerror: false,
      userPermissiontest: [
        {
          userId: "string",
          emailId: "",
          name: "string",
          permission: [
          ]
        },
      ],
      userPermission: [
        {
          userId: "string",
          emailId: "",
          username: "string",
          permissionType : "",
          permissionId : "",
          permission: [
          ]
        },
      ],
      newuserslist: []
    }
  },
  async mounted() {
    console.log("Name is", this.blockname)
    document.body.style.overflowY = "hidden"
    this.idtoken = store.getters.getToken;
    await this.getalldesignation();
    await this.checkFile();
    await this.getExistingData();
   await this.getDesignationUsers();
  },
  unmounted() {
    document.body.style.overflowY = "auto"
  },
  methods: {
    addUser() {
      if (this.selectedUser) {
        this.selectedUser.forEach((eachuser) => {
          this.userList.forEach((sortuserlist) => {
            if (sortuserlist.emailId == eachuser) {
              if (this.folderpermissions.length != 0) {               
                let userdataobj = {
                  userId: sortuserlist.userId,
                  emailId: sortuserlist.emailId,
                  username: sortuserlist.userName,
                  permissionType :"Designation",
                  permissionId :sortuserlist.desginationId,
                  permission: this.folderpermissions
                }
                if (!this.newuserslist.includes(userdataobj)) {

                  this.newuserslist.push(userdataobj);
                }
              }
            }
          })


        })
      }

    },
    async validatedocument() {

      this.validationmsgs = [false, false];
      if (this.selectedUser.length == 0) {
        this.validationmsgs[0] = true;
      }
      if (this.folderpermissions.length == 0) {
        this.validationmsgs[1] = true;
      }
    },
    async senddocument(){

      this.addUser();
      this.validatedocument();
      this.showerror = true;
      let idtoken = store.getters.getToken;
      if (!this.validationmsgs.includes(true)) {
        await axios
          .post(`${this.mainapi}document/document/share`,
            {
              documentId: this.docid,
              type: this.filetype,
              department: 
                store.getters.getDepartment,
              userlist: this.newuserslist
            }, {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }

          )
          .then((res) => {
            this.userList = res.data;
            console.log("userList......", this.userList);
            if (this.userList != null) {
              this.selectedUser = [];
              this.selectedPermission = [];
            }
            store.dispatch('setsendfile', false);
            alert("File shared successfully");
          })
          .catch((err) => {
            console.log(err);
            alert("Failed to send file");
          });
      }

    },
    async getalldesignation() {
      const idtoken = store.getters.getToken;
      console.log("inside getalldesignation")
      await axios
        .get(`${this.baseapi}search?PageSize=1000`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
        },
        })
        .then((res) => {
          this.designationList = res.data.entities;
          console.log("designationList......", this.designationList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDesignationUsers() {
      const idtoken = store.getters.getToken;
      this.userList = [];
      console.log("inside getDesignationUsers")
      await axios
        .post(`${this.baseurl}designationusers`, this.selectedDesignation,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
        },
        })
        .then((res) => {
          this.userList = res.data;
          console.log("userList......", this.userList);
          if (this.userList != null) {
            this.selectedUser = [];
            this.selectedPermission = [];
          }
        })
        .catch((err) => {
          console.log(err);
        });

    },

    async getallUsers() {
      const idtoken = store.getters.getToken;
      console.log("inside getallUsers")
      await axios
        .post(`${this.baseurl}designationusers`, this.selectedDesignation,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
        },
        })
        .then((res) => {
        //  this.userList = res.data;
          console.log("userList......", this.userList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkFile() {
      if (this.filetype == 'folder') {
        this.getfolderpermissions()
      }
      else this.getfilepermissions()
    },
    async getfolderpermissions() {
      const idtoken = store.getters.getToken;
      console.log("inside folder permission")
      await axios
        .get(`${this.baseurl}allfolderpermission`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
        },
        })
        .then((res) => {
          this.permissionList = res.data;
          console.log("permissionList......", this.permissionList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getfilepermissions() {
      const idtoken = store.getters.getToken;
      console.log("inside file permission")
      await axios
        .get(`${this.baseurl}allfilepermission`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
        },
        })
        .then((res) => {
          this.permissionList = res.data;
          console.log("permissionList......", this.permissionList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async SavePermission(id,type) {
      const idtoken = store.getters.getToken;
      console.log("in Save")
      console.log("selectedUser", this.selectedUser)
      console.log("selectedPermission", this.selectedPermission)
      if (type == 'folder') {
        console.log("selected folderid", id)
          await axios
            .post(`${this.baseurl}savefolderpermission`,
              {
                folderId: id,
                emailId: this.selectedUser,
                permission: this.selectedPermission
              },
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
              },
              })
            .then((res) => {
              alert("Folder permissions saved successfully")
              console.log("Save Result", res);
            })
            .catch((err) => {
              console.log(err);
            });
      }
      else {
        console.log("selected fileid", id)
          await axios
            .post(`${this.baseurl}savefilepermission`,
              {
                documentId: id,
                emailId: this.selectedUser,
                permission: this.selectedPermission
              },
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
              },
              }
            )
            .then((res) => {
              alert("File permissions saved successfully")
              console.log("Save Result", res);
            })
            .catch((err) => {
              console.log(err);
            });
      }
    },
    async getuserpermission() {
      const idtoken = store.getters.getToken;
      console.log("inside getuserpermission")
      const folderId = store.getters.getselectedid
      if (this.filetype == 'folder') {
        console.log("entered folder")
        await axios
        .post(`${this.baseurl}getfolderpermissions?FolderId=${folderId}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
        },
        })
        .then((res) => {
          this.UserpermissionList = res.data;
          console.log("UserpermissionList......", this.UserpermissionList);
        })
        .catch((err) => {
          console.log(err);
        });
      }
      else {
        await axios
          .post(`${this.baseurl}getfilepermission?FolderId=${folderId}`)
          .then((res) => {
            this.UserpermissionList = res.data;
            console.log("UserpermissionList......", this.UserpermissionList);
          })
          .catch((err) => {
            console.log(err);
          });
      }

    },

    async getExistingData() {
      //await this.getDesignationUsers();
      await this.getuserpermission();
      if (this.UserpermissionList != null) {
        this.selectedUser = this.UserpermissionList.emailId
        this.selectedPermission = this.UserpermissionList.permission
      }
    },
    close() {
      store.dispatch('setsendfile', false);
    }
  }
}
