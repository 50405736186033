/* eslint-disable */
import auth0 from "auth0-js";
import jwt_decode from "jwt-decode";
// import store from "../../store/index";
import { useStore } from "vuex";
import { watch, ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from "vue-router";
import { encryptUser } from "../../common/encryption"
import axios from "axios";


var webAuth = new auth0.WebAuth({
	domain: process.env.VUE_APP_Domain,
	clientID: process.env.VUE_APP_Client_ID,
	scope: "openid profile email offline_access",
	responseType: "token id_token",
	useRefreshTokens: true,
});

export default {
	name: "login",
	setup() {
		const store = useStore();
		const router = useRouter();
		const username = ref("");
		const docAssigndeUser = ref("");
		const password = ref("");
		const errr = ref("");
		const errorBlock = ref(false);
		let urlstring = ref("");
		let authResult = ref("")
		let slitstring = ref([]);
		let eachdata = ref({});
		let baseurl = ref(process.env.VUE_APP_Service_URL);

		onMounted(async () => {
			//await checkforfileorfolder();
			await urlCheck();
			let pagewrapper = document.querySelector("#page-wrapper");
			pagewrapper.style.width = "100%";
			await store.dispatch("setNavbar", false);
			await store.dispatch("setAuthorize", false)
		})
		onUnmounted(async () => {

			let pagewrapper = document.querySelector("#page-wrapper");
			pagewrapper.style.width = "";
			await store.dispatch("setNavbar", true);
			await store.dispatch("setAuthorize", true)
			store.dispatch("setApprovalLog", false)
		})

		// async function checkforfileorfolder() {
		// 	urlstring.value = location.href.substring(location.href.indexOf('?') + 1);

		// 	slitstring.value = urlstring.value.split("&");
		// 	slitstring.value.forEach((eachval) => {
		// 		let valarray = eachval.split("=");
		// 		eachdata.value[valarray[0]] = valarray[1]
		// 	})
		// 	username.value = eachdata.value["EmailId"]
		// }
		async function urlCheck() {
			urlstring.value = location.href.substring(location.href.indexOf('?') + 1);
			slitstring.value = urlstring.value.split("&");
			slitstring.value.forEach((eachval) => {
				let valarray = eachval.split("=");
				eachdata.value[valarray[0]] = valarray[1]
			})
			docAssigndeUser.value = eachdata.value["EmailId"]
		}
		async function Enablemenu() {
			await store.dispatch("setNavbar", true);
		}
		async function login() {
			errorBlock.value = false
			const encryptedPassword = await encryptUser(password.value.trim());
			await axios
				.post(
					`${baseurl.value}account-core/user/userauthorize`, {
					"userEmail": docAssigndeUser.value,
					"password": encryptedPassword
				},
				)
				.then(async (res) => {
					authResult.value = res.data
					store.dispatch("setToken", authResult.value.idToken)
					var decoded = jwt_decode(authResult.value.idToken);
					store.dispatch("setOrganizationId", decoded.organization_id)
					await setuserrole(authResult.value);
					if (docAssigndeUser.value) {
						store.dispatch("setDocViewSource", "Mail")
						await store.dispatch("setSideNavbar", true);
						router.push(`/documentview/${eachdata.value["DocumentId"]}/${eachdata.value["Version"]}`);
					}
					else {
						router.push(`/dashboard`);
					}

				})
				.catch((err) => {

					if (err) {
						if (err.response && err.response.data) {

							if (err.response.data && err.response.data == "An error occurred while authenticating.") {
								errr.value = "The password field is required";
							}
							if (err.response && err.response.data == "Invalid email or password.") {
								errorBlock.value = true
							}


						}
					}
				})
		}
		async function setuserrole(authresult) {
			console.log(authresult);
			const idtoken = authresult.idToken;
			const userid = (jwt_decode(idtoken).sub).trim();
			await axios
				//.get(`https://localhost:5001/user/getuserwithauthid?guid=`+userid, {
				.get(`${baseurl.value}account-core/user/getuserwithauthid?guid=` + userid, {
					headers: {
						Authorization: "Bearer " + idtoken,
						"Content-Type": "application/json",
					},
				})
				.then(async (res) => {
					console.log("rolls are", res.data);
					console.log(res.data.designation);
					//TODO Error
					// store.dispatch("setroles", res.data.designation);
					await getrollprevilage(res.data.designation, authresult);
				})
				.catch((err) => {
					console.log(err);
				});
		}
		async function getrollprevilage(designation, authresult) {
			const idtoken = authresult.idToken;
			// let array = (store.getters.getRolesData).split(',');

			console.log("rolldata is");
			// console.log(eachrole);
			await axios
				.get(`${baseurl.value}account-core/roles/getscreendesignationprivilegebyid?guid=${designation}`, {
					headers: {
						Authorization: "Bearer " + idtoken,
						"Content-Type": "application/json",
					},
				})
				.then(async (res) => {
					if (res.data != "No ScreenRolePrivilege found") {
						console.log("The privilages", res.data.screens);
						// store.dispatch("setrolesprivileges", res.data.screens);
						await setrolesprivileges(res.data.screens, authresult);
					}
					else {
						//await gotopages(authresult);
					}
				})
				.catch((err) => {
					console.log(err);
				});


		}
		async function setrolesprivileges(data, authresult) {

			console.log("rolll privileges are .............................");
			console.log(data);
			let privilegevalues = [];
			await (data).forEach((screen) => {
				for (let j = 0; j < screen.controls.length; j++) {
					if (!privilegevalues.includes(screen.name + " " + screen.controls[j])) {
						privilegevalues.push(screen.name + " " + screen.controls[j]);
					}
				}
			});
			console.log("rolll privileges array issssssssssss .............................");
			console.log(privilegevalues);
			let userprivilegestring = privilegevalues.join();
			console.log("rolll privileges string issssssssssss .............................");
			console.log(userprivilegestring);
			await store.dispatch("setrolesprivileges", `test test,${userprivilegestring}`);
			//await gotopages(authresult);
		}
		return {
			docAssigndeUser,
			password,
			errorBlock,
			errr,
			authResult,
			Enablemenu,
			login
		};

	},


}