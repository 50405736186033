<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="pl-md-10 col-10 col-md-10">
            <h2 class="py-0 my-0">My Files</h2>
        </div>
        <div class="col-2 col-md-2"></div>
    </div>
    <div class="wrapper wrapper-content animated fadeInRight">
        <div class="row">
            <div class="col-12 col-md-6 mb-1">
                <div class="ibox-body">
                    <div class="ibox-title style_2 d-flex justify-content-between px-3">
                        <h5 class="align-self-center my-0 py-0">Folders</h5>
                    </div>
                    <div class="ibox-content scrollable-dept" id="primaryTree">
                        <ul class="tree my-1 pl-0" v-on:clickout="clickedOut($event)">
                            <li>
                                <fileshareTreeFolder :list="folderList" :boldfolder="mainfolderbold" :openfolderlist="openfolderlist"
                                    patid="" :SourcePage="'generalFiles'">
                                </fileshareTreeFolder>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 px-md-0">
                <div class="ibody" v-if="$store.getters.getselectedFolder">
                    <div class="ibox-title style_2 d-flex justify-content-between px-3">
                        <h5 class="py-0 align-self-center">Documents</h5>
                    </div>
                    <div class="ibox-content scrollable-block" id="primaryDocumentTree">
                    <div class="table-responsive">                     
                        <table v-if="documentlist" class="table table-bordered ">
                            <tbody>
                                <tr v-for="documents in documentlist" :key="documents.id">
                                    <td @click.prevent="viewDoc(documents.id,documents.latestVersion)"><span class="cursor-pointer">{{
                                        documents.name }}</span></td>
                                    
                                </tr>
                            </tbody>
                        </table>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="create-folder-modal" v-if="createfolderpopup == true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div
              class="modal-header-projects d-flex justify-content-bertween p-3"
            >
              <h5 class="my-0 align-self-center">Create Folder</h5>
              <div class="cursor-pointer" @click="closeModelCreateFolder()">
                <i class="fa fa-times"></i>
              </div>
            </div>
            <div class="modal-body">
              <label class="mb-0" for="designation"
                >Folder Name <sup><i class="fa fa-asterisk required mandatoryField"></i></sup
              ></label>
              <input
              placeholder="Enter folder name"
                type="text"
                class="form-control"
                v-model="createFolder.folderName"
                maxlength="75"
              />
              <span class="errorClass" v-if="folderErrors[0] == true"
                >{{ folderErrorMessages[0] }}
              </span>
              <div class="my-2">
                <label class="mb-0" for="format">Select Format </label>
                <Multiselect
                  id="format"
                  valueProp="label"
                  v-model="SelectedFormat"
                  mode="multiple"
                  placeholder="Select Format"
                  :options="FormatList"
                  :hideSelected="false"
                  :closeOnSelect="false"
                  class="multiselect-custom-theme"
                >
                  <template v-slot:option="{ option }">
                    {{ option.label }}
                  </template>
                </Multiselect>
              </div>

              <label class="col-form-label"
                >Document version configuration</label
              >
              <div class="col-md-12 row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label><small>Prefix</small></label>
                    <input
                      maxlength="1"
                      id="password"
                      name="password"
                      type="text"
                      class="form-control required"
                      v-model="prefix"
                      aria-required="true"
                      v-on:keypress="isLetter($event)"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label><small>* Major ver.</small></label>
                    <input
                      maxlength="2"
                      id="password"
                      name="password"
                      type="text"
                      class="form-control required"
                      v-model="majorVerStart"
                      aria-required="true"
                      @keypress="isNumber($event)"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label><small>Minor ver.</small></label>
                    <input
                      maxlength="2"
                      id="password"
                      name="password"
                      type="text"
                      class="form-control required"
                      v-model="minorVerStart"
                      aria-required="true"
                      @keypress="isNumber($event)"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label><small>Minor Max.</small></label>
                    <input
                      maxlength="2"
                      id="password"
                      name="password"
                      type="text"
                      class="form-control required"
                      v-model="minorVermax"
                      @keypress="isNumber($event)"
                      autocomplete="off"
                      aria-required="true"
                    />
                  </div>
                </div>

                <span class="errorClass" v-if="minverError == true"
                  >Minor Max value must be greater than or equal to Minor
                  version value
                </span>
              </div>

              <div class="form-group pt-2">
                <button
                  type="button"
                  @click.prevent="createfolder()"
                  class="btn btn-primary btn-xs px-4 float-right"
                >
                  Save
                </button>
                <strong> Document version :</strong>
                {{ prefix }} {{ majorVerStart
                }}<span v-if="majorVerStart && minorVerStart != ''">.</span
                >{{ minorVerStart }}
              </div>
            </div>
          </div>
        </div>
      </div>
<teleport to="#app">
  <DocEditProjectPopup
      v-if="editDocPopup"
      @editdocactive="uploadeditactivity()"
      :folderid="docId"
      :docId="popupid"
      :versionid = "versionid"
      :source="'generalFiles'"
      @reloadDocs="$forceUpdate(getdocuments(docId))"
  >
  </DocEditProjectPopup>
  <fileSharePopup
    v-if="showProjectSharePopup"
    @closemodal="showProjectSharePopup = false"
    :list="popupid"
    :source="sourceType"
    :projectId="''"
  >
  </fileSharePopup>
   <File_FolderPopup
    v-if="showPermPopup"
    @closemodal="showPermPopup=!showPermPopup"
    :list="popupid"
    :source="sourceType"
  >
  </File_FolderPopup>
  <DocumentPopup
    v-if="docupload == true"
    @uploadlibactive="uploadmethodactivity()"
    :department="''"
    :folderid="$store.getters.getselectedFolder"
    :versionbyfolder="folderversion"
    :fromPage="'generalFiles'"
  >
  </DocumentPopup>
  <folderEditPopUp
    v-if="$store.getters.geteditvalue"
    :filetype="$store.getters.getfileType"
    :blocknamess="$store.getters.getfileName"
    @getFolder="getRootFolders(folderList)"
    @closemodal="$store.dispatch('seteditvalue', false)"
  >
  </folderEditPopUp>
  </teleport>
</template>
<script src="./shairedFolder.js">

</script>
<style scoped>
.add-file-block .tool-tip {
    visibility: hidden;
    width: max-content;
    background-color: #616161;
    color: #fff;
    text-align: center;
    border-radius: 0.3em;
    padding: 0.3rem 0.8rem;
    right: 0;
    top: -35px;
    font-size: 0.8rem;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    -webkit-transition: visibility 75ms ease-in;
    transition: visibility 75ms ease-in;
}

.add-file-block:hover .tool-tip {
    visibility: visible;
    -webkit-transition: visibility 30ms ease-in;
    transition: visibility 30ms ease-in;
}

.scrollable-dept {
    overflow-y: auto;
    height: 450px;
}
.add-doc-block .tool-tip {
    visibility: hidden;
    width: max-content;
    background-color: #616161;
    color: #fff;
    text-align: center;
    border-radius: 0.3em;
    padding: 0.3rem 0.8rem;
    right: 0;
    top: -35px;
    font-size: 0.8rem;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    -webkit-transition: visibility 75ms ease-in;
    transition: visibility 75ms ease-in;
}
.add-doc-block:hover .tool-tip {
    visibility: visible;
    -webkit-transition: visibility 30ms ease-in;
    transition: visibility 30ms ease-in;
}
.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.modal-header-projects {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.errorClass {
  color: hsl(0, 100%, 50%);
  font-size: 13px;
}
.mandatoryField {
  color: hsl(0, 100%, 50%);
  font-size:6px
}

</style>
