<template>
  <div class="create-user-modal1">
    <div class="modal-content width-max">
      <div class="modal-header1 d-flex justify-content-between align-items-center">         
        <h5 class="">Share Folder</h5>
        <div
          type="button"
          class="close"
          @click="close()"
        >
          <i class="fa fa-times modal-close align-self-center"></i>
        </div>
      </div>
      <div class="modal-body">
        <label
          v-if="fromPage == 'ProjectSummary' || fromPage == 'DocumentList'"
          class=""
          >Choose Role</label
        >
        <label v-if="fromPage == 'Department'|| fromPage == 'generalFiles'" class=""
          >Choose Designation</label
        >
        <select
          class="form-control m-b w-50"
          name="account"
          @change="onChange($event)"
        >
          <option value="" disabled selected>-Select-</option>
          <option v-for="role in roleList" :key="role.id" :value="role.id">
            <span
              v-if="fromPage == 'ProjectSummary' || fromPage == 'DocumentList'"
            >
              {{ role.name }}
            </span>
            <span v-if="fromPage == 'Department' || fromPage == 'generalFiles'">
              {{ role.displayName }}
            </span>
          </option>
        </select>
        <h5 class="tag-title">Users</h5>
        <div class="height-scroll">
          <table class="table fixed-header">
            <thead>
              <tr>
                <th>
                  <input type="checkbox" id="selectall" @change="selectall()" />
                  Name
                </th>
                <th v-for="permissions in permissionList" :key="permissions.id">
                  <input
                    type="checkbox"
                    :id="permissions.permission"
                    @change="setpermissions(permissions.permission)"
                  />
                  {{ permissions.permission }}
                </th>
                <th class="text-center">Save</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="options in userList" :key="options.id">
                <td>
                  <input
                    type="checkbox"
                    :value="options.userName"
                    :id="options.userId"
                    :checked="userobject[options.userId].permission.length != 0"
                    @change="setcontrols(options)"
                  />
                  <Span v-if="fromPage == 'Department' || fromPage == 'generalFiles'">{{
                    options.name
                  }}</Span>
                  <Span
                    v-if="
                      fromPage == 'ProjectSummary' || fromPage == 'DocumentList'
                    "
                    >{{ options.userName }}</Span
                  >
                </td>
                <td v-for="option in permissionList" :key="option.id">
                  <input
                    type="checkbox"
                    :value="option.permission"
                    :class="options.id"
                    v-model="userobject[options.userId].permission"
                    @change="selectuserwithpermission(options.userId)"
                  />
                  {{ option.permission }}
                </td>
                <td class="text-center">
                  <a href="#">
                    <img
                      src="../../assets/img/save_color.png"
                      class="cursor-pointer"
                      alt="create"
                      @click="folderMultiSave()"
                  /></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <label v-if="showSave">
          *Unsaved changes will be lost if switching designations.</label
        >
        <button
          v-if="showSave"
          type="button"
          class="btn btn-primary btn-xs px-4 mr-3"
          @click="folderMultiSave()"
        >
          Share
        </button>
      </div>
    </div>
  </div>
</template>
  <script src="./foldershare_popup.js">
</script>
  <style scoped>
@import "../../assets/css/style.css";
@import "../../assets/css/custom.css";
.create-user-modal1 {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.width-max {
  width: min(800px, 90%);
}
.modal-header1 {
  padding: 10px 29px;
  text-align: left;
  display: block;
  border-bottom: 1px solid #dee2e6;
}
.tree input {
  position: inherit !important;
}
.height-scroll {
  height: 250px;
  overflow-y: auto;
}
.fixed-header th {
  top: 0px;
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
}
</style>