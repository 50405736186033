import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect"
import DigitalSignPopUp from "../DigitalSignPopUp/DigitalSignPopUp.vue";
export default{
    name : "sop_acknoledgment",
    props: {
        ackId: {
          type: String,
          default: "",
        },
        SignPerson: {
            type: String,
            default: "",
          }
      },
      components: {
        DigitalSignPopUp,
      },
      computed: {
        dateToday() {
            // Calculate the minimum allowed date (today's date) with time zone information
            const today = new Date();
            const year = today.getUTCFullYear();
            const month = String(today.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
            const day = String(today.getUTCDate()).padStart(2, '0');
            const hours = String(today.getUTCHours()).padStart(2, '0');
            const minutes = String(today.getUTCMinutes()).padStart(2, '0');
            const seconds = String(today.getUTCSeconds()).padStart(2, '0');
            const milliseconds = String(today.getUTCMilliseconds()).padStart(3, '0');
            
            return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
          },        
      },
    data(){
        return{
            baseurl: `${process.env.VUE_APP_Service_URL}document/projectdevdocumenttemplate/`,
            idtoken: "",
            head :"",
            ackDetails :{
                traineeName: "",
                traineeUserID: "",
                traineeJobTitle: "",
                acknowmentFileName: "",
                storageID: "",
                version: "",
                plannedStartDate: "2023-09-12T10:43:36.444Z",
                plannedEndDate: "2023-09-12T10:43:36.444Z",
                asignedBy: "",
                asignedByName: "",
                asignedDate: "",
                trainerID: "",
                trainerName: "",
                soP_Details: [
                    {
                    title: "",
                    version: "",
                    date: "2023-09-12T10:43:36.444Z"
                    }
                ],
                status: "",
                actualStartDate: "",
                actualEndDate: "2023-09-12T10:43:36.444Z",
                traineeSignature: {
                    signedBy: "",
                    initals: "",
                    signedDate: "2023-09-12T10:43:36.444Z",
                    userID: "",
                    documentHash: "",
                    signatureHash: ""
                },
                isConfirmed: false,
                trainerSignature: {
                    signedBy: "",
                    initals: "",
                    signedDate: "2023-09-12T10:43:36.444Z",
                    userID: "",
                    documentHash: "",
                    signatureHash: ""
                },
                trainerComments: "",
                CreatedAt: "2023-09-12T10:44:47.848Z",
                CreatedUserId: "",
                CreatedUserEmail: "",
                CreatedUserName: "",
                LastModifiedAt: "",
                LastModifiedUserId: "",
                LastModifiedUserEmail: "",
                LastModifiedUserName: "",
                id: ""
            },
            showsign: false,
            signedIntial:"",
            errors: [false,false,false,false,false,false],
        };
    },
    async mounted(){
        this.head = {
            headers: {
              Authorization: "Bearer " + store.getters.getToken,
              "Content-Type": "application/json",
            },
          };
          this.idtoken = store.getters.getToken;
          await this.getAcknowledgement();
    },
    methods: {
        async getAcknowledgement(){
            await axios.get(
                //`https://localhost:5001/projectdevdocumenttemplate/getsopacknowledgement?id=${this.ackId}`,this.head
               `${this.baseurl}getsopacknowledgement?id=${this.ackId}`,this.head
            )
            .then(res =>{
                console.log("getACK result is", res.data.entities[0]);
                this.ackDetails = res.data.entities[0];

                this.ackDetails.actualStartDate = this.ackDetails.actualStartDate === null ? 
                    this.toDatePicker(this.ackDetails.plannedStartDate) :
                    this.toDatePicker(this.ackDetails.actualStartDate);

                if(this.ackDetails.actualEndDate === null){
                    this.ackDetails.actualEndDate = this.toDatePicker(this.dateToday);
                }
                else{
                    this.ackDetails.actualEndDate = this.toDatePicker(this.ackDetails.actualEndDate);
                }
             })
            .catch(err => {
                console.log("error in get ACK", err);
            })
        },
        async updateSOPAcknowledgment(){
            
            this.ackDetails.AcknowledgementId = this.ackId;
            let date = this.ackDetails.actualEndDate.split('-').reverse().join('-');
            this.ackDetails.AcknowmentFileName = "ITF-502-GL[1].00 Training Acknowledgment Form for SOP_" +this.ackDetails.traineeName + "_" +date;

            this.ackDetails.status = 
                this.SignPerson === "TraineeSignature" ? "Completed" :
                this.SignPerson === "TrainerSignature" ? "Approved"  : "";

            await axios.put(`${this.baseurl}updateacknowledgement`,this.ackDetails,this.head)
                .then(res =>{
                    console.log("update ACK result is", res);
                    alert("You have Digi-signed successfully");
                    this.closePopupfun();
                       
                })
                .catch(err => {
                    console.log("error in update ACK", err);
                    let errresult = err.response.data.errors.DomainValidations[0];
                    alert(errresult);
                    this.showsign=false;
             })
        },
        async openSignPopUp(){
            this.showsign = true;
            this.signedIntial = "";
        },
        async closePopupfun(){
            this.showsign = false;
        },
        async signAddedFun(result){
            this.signedIntial= result;
            if(this.signedIntial != ""){
                await this.updateSOPAcknowledgment();
            }
        },
        async changeIsConformed(){
            console.log("hi.....",this.ackDetails.isConfirmed);
            //this.ackDetails.IsConfirmed ==true ?  this.ackDetails.IsConfirmed = false : this.ackDetails.IsConfirmed=true;
        },
        async signDocClicked(role){
            if(role == 'trainee'){
                this.ackDetails.traineeSignature.signedDate = this.dateToday;
                await this.signValidation();
            }
            else if(role == 'trainer'){
                this.ackDetails.trainerSignature.signedDate = this.dateToday;
            }
            if(!this.errors.includes(true)){
                await this.openSignPopUp();                
            }
        },
        async signValidation(){
            this.errors= [false,false,false,false,false,false];
            if(this.ackDetails.isConfirmed == false)
            { this.errors[0] = true; }
            else{
                 this.errors[0] = false; 
            }
            if(this.ackDetails.actualStartDate == ""){
                this.errors[1] = true;
            }
            else{
                this.errors[1] = false;
            }
            if(this.ackDetails.actualEndDate == ""){
                this.errors[2] = true;
            }
            else{
                this.errors[2] = false;
            }
            if(this.ackDetails.actualStartDate > this.ackDetails.actualEndDate){
                this.errors[3] = true;
            }
            else{
                this.errors[3] = false;
            } 
            if(this.ackDetails.actualStartDate > this.dateToday.slice(0,10)){
                this.errors[6] = true
                this.errors[3]=false
                this.errors[5]=false
            }
            else{
                this.errors[6] = false;
            } 
            if(this.ackDetails.actualEndDate > this.dateToday.slice(0,10)){
                this.errors[5] = true;
            }
            else{
                this.errors[5] = false;
            }   
        },
        formatDate(isoDate) {
            if (isoDate !== null) {
                if (isoDate) {
                    const inputDate = new Date(isoDate);
                    const day = inputDate.getUTCDate();
                    const month = inputDate.getUTCMonth() + 1; // Months are 0-indexed, so add 1
                    const year = inputDate.getUTCFullYear();
                    
                    const formattedDate = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
                    
                    return formattedDate;
                  } else {
                    return null;
                  }
                }
        },
        toDatePicker(isoDate){
            if (isoDate !== null) {
                const date = new Date(isoDate);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            } else {
                return null;
            }
        },
        async close(){
            if(store.getters.getFromPage==='dashboard')
            {
                this.$router.push("/dashboard")
                store.dispatch("setFromPage","")
            }
            else{
                this.$router.push("/soplibrary");
            }
            
        }
    },
};