<template>
  <div class="h-100 d-flex align-items-center">
    <div
      class="tablebox-design middlebox-design text-center reset-password-block p-3"
    >
      <div>
        <div v-if="type == `user`">
          <div class="text-design">Set a new password for your account</div>
        </div>
        <div v-if="type == `digi-sign`">
          <div class="text-design">Create a new digi-sign password</div>
        </div>
      </div>
      <div class="text-two-design">
        User name: <strong>{{ userEmail }}</strong>
      </div>
      <div class="form-group position-relative">
        <input
          :type="inputType"
          class="form-control"
          placeholder="New Password"
          required=""
          maxlength="20"
          v-model="changepassword.newpassword"
        />
        <i
          class="password-visible-block cursor-pointer position-absolute"
          :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
          @click.prevent="passwordVisible('normal')"
        ></i>
        <div class="text-start">
          <span class="errormsg" v-if="errors[0] == false">
            Please enter a password</span
          >
        </div>
      </div>
      <div class="form-group position-relative">
        <input
          :type="confirmInputType"
          class="form-control"
          placeholder="Confirm Password"
          required=""
          maxlength="20"
          v-model="changepassword.confirmpassword"
        /><i
          class="password-visible-block cursor-pointer position-absolute"
          :class="[confirmVisibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
          @click.prevent="passwordVisible('confirm')"
        ></i>
        <div class="text-start">
          <span class="errormsg" v-if="errors[2] == false">
            Please enter confirm password</span
          >
          <span class="errormsg" v-if="errors[3] == false"
            >Passwords must contain minimum 8 characters and maximum 20
            characters <br />
            including uppercase, lowercase letters, special characters and
            numbers.</span
          >
          <span class="errormsg" v-if="errors[4] == false"
            >Password and confirm password mismatch!</span
          >
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-primary block full-width m-b"
        @click.prevent="confirmClicked()"
      >
        Submit
      </button>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import { encryptUser } from "../../common/encryption";
export default {
  props: {
    token: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  name: "ResetPassword",
  data() {
    return {
      inputType: "password",
      confirmInputType: "password",
      visibleOn: false,
      confirmVisibleOn: false,
      userEmail: "",
      baseapi: process.env.VUE_APP_Service_URL,
      accountBaseUrl: `${process.env.VUE_APP_Service_URL}account-core/user/`,
      ProfileData: "",
      changepassword: {
        newpassword: "",
        confirmpassword: "",
      },
      validations: [false, false, false, false, false],
      errors: [],
    };
  },
  async mounted() {
    await this.Disablemenu();
    await this.GetAndSetData(this.token);
  },

  async unmounted() {
    await this.Enablemenu();
  },

  methods: {
    async passwordVisible(type) {
      if (type === "normal") {
        this.visibleOn = !this.visibleOn;
      } else {
        this.confirmVisibleOn = !this.confirmVisibleOn;
      }
      if (this.confirmVisibleOn == true) {
        this.confirmInputType = "text";
      } else this.confirmInputType = "password";
      if (this.visibleOn == true) {
        this.inputType = "text";
      } else this.inputType = "password";
    },
    async Disablemenu() {
      store.dispatch("setNavbar", false);
      const navbardefault = document.querySelector("#page-wrapper");
      navbardefault.style.width = "100%";
      navbardefault.style.paddingRight = "0px";
    },
    async Enablemenu() {
      store.dispatch("setNavbar", true);
    },

    async GetAndSetData(tokenValue) {
      console.log("Helloo GetAndSetData");
      await axios
        .get(
          this.accountBaseUrl +
            "gettokenstatus?token=" +
            tokenValue +
            "&type=" +
            this.type
        )
        .then((res) => {
          this.ProfileData = res.data;
          this.userEmail = this.ProfileData.primaryEmail;
          console.log("Bind data is", this.ProfileData);
        })
        .catch((err) => {
          this.$router.push(`/InvalidSession`);
        });
    },

    async checkstatus() {
      if (this.type == `user`) {
        const encryptedPassword = await encryptUser(
          this.changepassword.newpassword.trim()
        );
        await axios
          .put(
            this.accountBaseUrl + "updateforgotuserpassword",
            {
              password: encryptedPassword,
              email: this.userEmail,
              token: this.token,
            },
            this.Headers
          )

          .then((res) => {
            console.log(res);
            alert(res.data);
            this.$router.push(`/PasswordResetSuccess`);
            console.log("stay");
          })
          .catch((err) => {
            console.log(err, "errr");
            alert(err.response.data.errors.DomainValidations[0]);
            this.$router.push(`/InvalidSession`);
          });
      } else if (this.type == `digi-sign`) {
        const encryptedPassword = await encryptUser(
          this.changepassword.newpassword.trim()
        );
        await axios
          .get(
            this.accountBaseUrl +
              "resetdigipassword?password=" +
              encryptedPassword +
              "&token=" +
              this.token
          )
          .then((res) => {
            alert("Digi-sign password updated successfully.");
            this.$router.push(`/PasswordResetSuccess`);
          })
          .catch((err) => {
            console.log(err);
            if (
              err.response &&
              err.response.data &&
              err.response.data.errors &&
              err.response.data.errors.DomainValidations &&
              err.response.data.errors.DomainValidations[0]
            ) {
              alert(err.response.data.errors.DomainValidations[0]);
              this.$router.push(`/`);
            }
          });
      }
    },

    async confirmClicked() {
      await this.validateallpassword();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      } else {
        this.checkstatus();
      }
    },

    async validateallpassword() {
      this.Validatepassword(this.changepassword.newpassword.trim());
      this.Validateconfirmpassword(this.changepassword.confirmpassword.trim());
      this.Validatematchpasswords(
        this.changepassword.newpassword.trim(),
        this.changepassword.confirmpassword.trim()
      );
    },

    async Oldpasswordused() {
      this.validations[5] = false;
    },

    async Validatepassword(passwd) {
      let reNum = /[0-9]/;
      let reLow = /[a-z]/;
      let reUpp = /[A-Z]/;
      let spcl = /(?=.*[!@#$%^&*])/;
      if (passwd == "" || passwd == "" || passwd == null) {
        this.validations[1] = true;
        this.validations[0] = false;
      } else if (
        passwd.length < 8 ||
        !reLow.test(passwd) ||
        !reNum.test(passwd) ||
        !spcl.test(passwd) ||
        !reUpp.test(passwd)
      ) {
        this.validations[0] = true;

        this.validations[1] = false;
      } else {
        this.validations[0] = true;
        this.validations[1] = true;
      }
    },

    async Validateconfirmpassword(passwd) {
      let reNum = /[0-9]/;
      let reLow = /[a-z]/;
      let reUpp = /[A-Z]/;
      let spcl = /(?=.*[!@#$%^&*])/;
      if (passwd == "" || passwd == "" || passwd == null) {
        this.validations[3] = true;
        this.validations[2] = false;
      } else if (
        passwd.length < 8 ||
        !reLow.test(passwd) ||
        !reNum.test(passwd) ||
        !spcl.test(passwd) ||
        !reUpp.test(passwd)
      ) {
        this.validations[2] = true;

        this.validations[3] = false;
      } else {
        this.validations[2] = true;
        this.validations[3] = true;
      }
    },

    async Validatematchpasswords(passwd, confirmpaswd) {
      if (
        this.validations[0] == true &&
        this.validations[1] == true &&
        this.validations[2] == true &&
        this.validations[3] == true
      ) {
        console.log("validate paswds");
        if (passwd == confirmpaswd) {
          this.validations[4] = true;
          console.log(" paswds ok");
        } else {
          this.validations[4] = false;
          console.log("paswds mismatch");
        }
      } else {
        console.log("final condtn paswds");
        this.validations[4] = true;
      }
    },
    async onclosed() {
      this.$router.push(`/`);
    },
  },
};
</script>

<style>
@import "../../assets/css/style.css";
.tablebox-design {
  width: 400px;
  margin-top: 100px;
}
.middlebox-design {
  margin: 0 auto;
}
.reset-password-block {
  width: min(90%, 400px);
}
.text-design {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #145faf;
}
.text-two-design {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #676a6c;
}
.small-text2 {
  font-size: 80%;
  font-weight: 400;
}
.msg {
  margin-bottom: 10px;
}
.textright {
  margin-left: 1750px;
  margin-top: 50px;
}
.password-visible-block {
  top: 11px;
}
.password-visible-digi-block {
  right: 28px;
  top: 43px;
}
</style>
