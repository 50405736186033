<template>
  <div
    class="move-folder-block-container d-flex justify-content-center align-items-center"
  >
    <div class="modal-dialog modal-width">
      <div class="modal-content">
        <div class="modal-header-move d-flex justify-content-between p-3">
          <h5 class="my-0 align-self-center">Clone Folder</h5>
          <span
            type="button"
            class="align-self-center"
            data-dismiss="modal"
            @click.prevent="close()"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body height-400 border-0 px-4">
          <div class="form-group">
            <div class="row">
              <div class="col-12 mb-2">
                <label class="mb-0 mr-1" for="owner"
                  >Choose action to clone</label
                >
                <span class="errorMsg">*</span>
                <select id="owner" class="form-control" v-model="cloneType">
                  <option selected value="">--Select--</option>
                  <option value="folderonly">Only Folder</option>
                  <option value="folderpermission">
                    Folders and permission
                  </option>
                  <option value="folderfilepermission">
                    Folder,permission and files
                  </option>
                </select>
                
              </div>
              <div class="col-12 mb-2">
                <label class="mb-0 mr-1" for="owner"
                  >Folder Name</label
                >
                
                <input class="form-control" 
                v-model="newFolderName"
                >

              </div>
              <div class="col-12">
                  <div class="mb-1 text-left">
                  <label class="mb-0">Select Location</label>
                  <select
                    v-model="location"
                    class="form-control m-b"
                    @change="onSelection($event)"
                  >
                    <option value="" disabled selected>-Select-</option>
                    <option value="General">My Files</option>
                    <option value="Department">Department</option>
                    <option value="Projects">Projects</option>
                  </select>
                </div>
                <div class="tree-content overflow-y-auto mt-3" v-if="location=='Projects'">

                        <ul
                          class="tree my-1 pl-0"
                          
                        >
                        
                            <TreeMergeFile
                                @documentListNotEmpty="handleDocumentListNotEmpty"
                                projectId=""
                                :list="projectlist"
                                selectedlocation="projects"
                              >
                              </TreeMergeFile>
                            <!-- <TreeFolder
                              :list="folderList"
                              :boldfolder="mainfolderbold"
                              :openfolderlist="openfolderlist"
                              patid=""
                              :SourcePage="'generalFiles'"
                            >
                            </TreeFolder> -->
                          
                        </ul>
                    
                      
                      
               
                
                </div>
                <div class="tree-content overflow-y-auto mt-3" v-if="location=='Department'">
                  <ul class="tree pl-3">
                    
                      <TreeMergeFile
                        @documentListNotEmpty="handleDocumentListNotEmpty"
                        projectId=""
                        :list="deptlist"
                        selectedlocation="department"
                      >
                      </TreeMergeFile>
                    
                  </ul>
                </div>
                <div class="tree-content overflow-y-auto mt-3" v-if="location == `General`">
                  <ul class="tree pl-3">
                    <li>
                      <input
                        type="checkbox"
                        :id="selectedfolderid"
                        @change="backtorootfolder(selectedfolderid)"
                      />
                      <label 
                        :class="
                          mainfolderbold == ''
                            ? 'tree_labels tree_label_bold'
                            : 'tree_labels'
                        "
                        @click="backtorootfolder(null)"
                        >My Files
                      </label>
                      <div >
                        <ul
                          class="tree my-1 pl-0"
                          
                        >
                          <li>
                            <TreeMergeFile
                        @documentListNotEmpty="handleDocumentListNotEmpty"
                        projectId=""
                        :list="folderlist"
                      >
                      </TreeMergeFile>
                          </li>
                        </ul>
                      </div>
                    
                      
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div
            class="d-flex justify-content-end"
            v-if="location == 'Department'"
          >
            <button
              @click="save()"
              type="button"
              class="btn btn-primary btn-xs px-4"
            >
              Clone
            </button>
          </div>
          <div
            class="d-flex justify-content-end"
            v-if="location == 'General'"
          >
            <button
              @click.prevent="saveinGenFiles()"
              type="button"
              class="btn btn-primary btn-xs px-4"
            >
              Clone
            </button>
          </div>
          <div
            class="d-flex justify-content-end"
            v-if="location == 'Projects'"
          >
            <button
              @click="saveInProject()"
              type="button"
              class="btn btn-primary btn-xs px-4"
            >
              Clone
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./move_folder.js">
</script>
<style scoped>
@import "./tree.css";

.move-folder-block-container {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  overflow-y: hidden;
  z-index: 3000;
}

.modal-header-move {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-width {
  width: min(400px, 90%);
}

.overflow-y-auto {
  height: 170px;
  overflow-y: auto;
}

.height-400 {
  height: 400px;
  overflow-y: hidden;
}

.error-block {
  color: #ff0000;
}

.tree_label_bold {
  font-weight: bold;
}
.errorMsg {
  color: hsl(0, 100%, 50%);
}
</style>