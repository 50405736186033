/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
export default {
  data() {
    return {
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/organization/`,
      idtoken: "",
      showDesig: false,
      orgList: "",
      searchField: "",
      //retrievebox: true,
      // designationId: "",
      currentPage: 1,
      pageindex: 1,
      pagesize: 10,
      totalPage: 1,
      SortProperty: "",
      SortOrder: false,
      SortOrderInt: 0,
      errors: [],
      validations: [],
      // update: {
      //   designationId: "",
      //   name: "",
      //   description: "",
      //   active: true,
      // },
      // values: {
      //   name: "",
      //   description: "",
      //   active: true,
      // },
      search: {
        createdby: "",
        status: "",
        name: "",
      },
      deleteOrglist: [],
    };
  },

  async mounted() {
    this.idtoken = store.getters.getIdToken
    this.ListOrgs();
  },

  methods: {

    async OnSearchButtonClicked() {
      this.pageindex = 1;
      this.currentPage = 1;
      this.searchField = this.search.name;
      this.ListOrgs()
    },


    async GoToManageUser(id) {
      this.$router.push("./create_organization/" + id)
    },
    async ListOrgs() {
      await axios
        .get(
          `${this.baseurl}search?OrgName=${this.search.name}&Active=${this.search.status}&pageindex=${this.pageindex}&pagesize=${this.pagesize}&SortProperty=${this.searchField}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrderInt}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.orgList = res.data.entities;
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.orgList = []
          alert("No data found!")
        })
    },
    async DeleteClicked(orgId) {
      if (
        confirm("Are you sure you want to delete this organization?")
      ) {
        this.deleteOrglist.push(orgId);
        await axios
          .delete(
            `${this.baseurl}delete`,
            {
              data: this.deleteOrglist,
            },
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }

          )
          .then(async (res) => {
            alert("Organization deleted successfully")
            this.ListOrgs();
          })
          // .catch((err) => {
          //   console.log(err);
          //   alert("No data found!")
          // })
      }

    },
  SortSelected(sortProp, id) {
    console.log('SortPropChanged');
    const selectedElement = document.getElementById(id)
    const selectedElementID = document.getElementById(id).id
    if (sortProp != null && id == selectedElementID) {
      this.sortDesc = !this.sortDesc
      if (this.SortOrder == 0) {
        if (selectedElement.classList.contains("sortAsc")) {
          selectedElement.classList.remove("sortAsc")
          selectedElement.classList.add("sortDesc")
        }
        else selectedElement.classList.add("sortDesc")
      }
      else {
        if (selectedElement.classList.contains("sortDesc")) {
          selectedElement.classList.remove("sortDesc")
          selectedElement.classList.add("sortAsc")
        }
        else selectedElement.classList.add("sortAsc")
      }
    }
    this.pageindex = 1;
    this.currentPage = 1;
    this.SortProperty = sortProp;
    this.SortOrder = this.SortOrder == true ? false : true;
    this.SortOrderInt = this.SortOrder == true ? 0 : 1;
    this.ListOrgs();
  },
  loadPage: function (page) {
    this.currentPage = page;
    this.pageindex = page;
    this.setfunctions();
  },
  async setfunctions() {
    if (this.search.status != '' || this.search.name != '') {
        this.searchFilter()
    }
    else this.ListOrgs()
},
async searchFilter()
{
  await axios
  .get(
    `${this.baseurl}search?OrgName=${this.search.name}&Active=${this.search.status}&pageindex=${this.pageindex}&pagesize=${this.pagesize}&Name=${this.searchField}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrderInt}`,
    {
      headers: {
        Authorization: "Bearer " + this.idtoken,
        "Content-Type": "application/json",
      },
    }
  )
  .then((res) => {
    this.orgList = res.data.entities;
    this.totalPage = parseInt(res.data.totalCount / this.pagesize);
    if (res.data.totalCount % this.pagesize != 0) {
      this.totalPage = this.totalPage + 1;
    }
  })
  .catch((err) => {
    console.log(err);
    this.orgList = []
    alert("No data found!")
  })
},



},
};