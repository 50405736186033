<template>
    <div class="permission-block-container">
      <div class="permission-block1">
        <div class="title-class1 d-flex justify-content-between py-2 px-3">
          <h4 class="my-0">File Format</h4>
          <div class="cursor-pointer" @click="closeconfig()">
            <i class="fa fa-times"></i>
          </div>
            
        </div>
        <div class="mb-2">
            <!-- <label class="mb-0" for="designation"
              >Select Format 
            </label> -->
            <Multiselect class="multiselect-custom-theme"
              name="format"
              valueProp="label"
              v-model="SelectedFormat"
              mode="multiple"
              placeholder="Select Format"
              :options="FormatList"
              :hideSelected="false"
              :closeOnSelect="false"
            >
              <template v-slot:option="{ option }">
                {{ option.label }}
              </template>
            </Multiselect>
          </div>
          <div></div>
          <span
            class="submit_btn mt-3 px-5 mx-3 float-right"
            type="button"
            @click="updatefolder()"
            >Save</span
          >   
      </div>
      
    </div>
  </template>
  <script src="./fileformatpopup.js"></script>
  <style scoped>
  .permission-block-container {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 3000;
  }
  .permission-block1 {
    background: #fff;
    width: min(500px, 90%);
    border-radius: 4px;
    position: fixed;
    overflow: hidden;
    pointer-events: auto;
    height:220px;
  }
  
  .title-class1 {
    background-color: #618bd7;
    color: #fff;
  }
  
  .overflow-y {
    overflow-y: scroll
  }
  
  .error-block {
    color: #ff0000;
  }
  .multi{
    width:466px;
    top:10px;
  }
  </style>
  