/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import VPagination from "@hennge/vue3-pagination";
import Multiselect from "@vueform/multiselect";
export default {
  name: 'addTrainingPlan',
  components: { VPagination, Multiselect },
  props: {
    refreshFlag: Boolean // Define a prop to trigger refresh
  },
  watch: {
    refreshFlag(newVal) {
      if (newVal) {
        this.refreshContent();
      }
    }
  },
  computed: {
    dateToday() {
      // Calculate the minimum allowed date (today's date) with time zone information
      const today = new Date();
      const year = today.getUTCFullYear();
      const month = String(today.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const day = String(today.getUTCDate()).padStart(2, '0');
      const hours = String(today.getUTCHours()).padStart(2, '0');
      const minutes = String(today.getUTCMinutes()).padStart(2, '0');
      const seconds = String(today.getUTCSeconds()).padStart(2, '0');
      const milliseconds = String(today.getUTCMilliseconds()).padStart(3, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    },
  },
  data() {
    return {
      designationPrivileges:[],
      showMoreStatesSkills: {},
      showMoreStates: {},
      searchActualstartdate: "",
      searchActualenddate: "",
      searchPlannedstartdate: "",
      searchPlannedenddate: "",
      searchStatus: "All",
      baseurl: `${process.env.VUE_APP_Service_URL}`,
      fromDate: "",
      toDate: "",
      idtoken: "",
      totalPages: 1,
      pageIndex: 1,
      pageSize: 10,
      currentPage: 1,
      sortProperty: "createdAt",
      sortorder: 1,
      tableview_heading: "Training Plan and Record for",
      username: "username",
      data: [],
      showSidePopup: [false],
      showDateBlock: false,
      columnDefs: [
        {
          name: "userName",
          header: "Employee"
        },
        {
          name: "skills",
          header:
            "Required Knowledge Skills Abilities <br><small>(Managers populate the plan for an individual)</small>"
        },
        {
          name: "trainingDetails",
          header: "Training Details",
        },
        {
          name: "trainingMethod",
          header:
            "Training Activities Identified <br><small>(indicate method of training)</small>",
        },
        {
          name: "plannedDateRange",
          header: "Planned Training Date <br><small>( DD-MMM-YYYY)</small>",
        },
        {
          name: "actualDateRange",
          header: "Actual Training Date <br><small>( DD-MMM-YYYY)</small>",
        },
        {
          name: "trainedBy",
          header: "Trained by"
        },
        {
          name: "signature",
          header: "Training Completed Sign & Date <br><small>(Trainer or Line Manager)</small>",
        },
      ],
      createTestPlan: {
        userIds: [],
        trainingId: "",
        skills: "",
        details: "",
        plannedStartDate: null,
        plannedEndDate: null,
        trainingMethod: "Self",
        trainedBy: "",
      },
      errors: [false, false, false, false, false, false, false],
      isEdit: false,
      userList: "",
      selectedTraining: "",
      showModal: false,
      trainerList: [],
      designationList: [],
      designation: [],
    }
  },
  async mounted() {
    this.idtoken = store.getters.getToken;
    this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    await this.getTrainingList();
    await this.listusers();
    await this.getalldesignation();
    await this.listTrainingUser([]);
  },

  methods: {refreshContent() {
    console.log("Refreshed addTraining")
    this.getTrainingList();
   },
    truncateTextSkills(text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    },
    toggleShowMoreSkills(index) {
      this.showMoreStatesSkills = {
        ...this.showMoreStatesSkills,
        [index]: !this.showMoreStatesSkills[index]
      };
    },
    truncateText(text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    },
    toggleShowMore(index) {
      this.showMoreStates = {
        ...this.showMoreStates,
        [index]: !this.showMoreStates[index]
      };
    },
    formatDateTime(dateTimeString) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const dateTime = new Date(dateTimeString);
      const day = dateTime.getDate().toString().padStart(2, '0');
      const month = months[dateTime.getMonth()];
      const year = dateTime.getFullYear();
      const hours = dateTime.getHours().toString().padStart(2, '0');
      const minutes = dateTime.getMinutes().toString().padStart(2, '0');

      return `${day}${month}${year} | ${hours}:${minutes}`;
    },
  

    NeededDateRange(startDate, endDate) {
      if (startDate === null || endDate === null) {
        return ''; // Return an empty string if either start or end date is null
      }

      const start = new Date(startDate);
      const end = new Date(endDate);

      const options = { day: '2-digit', month: 'short', year: 'numeric' };

      if (start.getTime() === end.getTime()) {
        return this.formatDate(start, options).toUpperCase(); // Return single date
      } else {
        const formattedStart = this.formatDate(start, options);
        const formattedEnd = this.formatDate(end, options);
        return `${formattedStart.toUpperCase()}\nto\n${formattedEnd.toUpperCase()}`;
      }
    },

    formatDate(date, options) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    dateBlockDisplay() {
      const cardBody = document.getElementById("cardtrainingBody")
      this.showDateBlock = !this.showDateBlock
      if (this.showDateBlock) {
        cardBody.style.height = '94px'
      }
      else {
        cardBody.style.height = '0px'
      }
    },
    async clearValues() {

      this.searchActualstartdate = "";
      this.searchActualenddate = "";
      this.searchPlannedstartdate = "";
      this.searchPlannedenddate = "";
      this.searchStatus = "All";
      await this.onSearchClicked()
    },
    exportToWord() {
      console.log("Export data", this.data);
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageIndex = page;
      this.getTrainingList();
    },
    async getTrainingList() {
      await axios
        .get(
          `${this.baseurl}document/trainingplan/gettraininglist?Tab=Assign&status=${this.searchStatus}&plannedstartdate=${this.searchPlannedstartdate}&plannedenddate=${this.searchPlannedenddate}&actualstartdate=${this.searchActualstartdate}&actualenddate=${this.searchActualenddate}&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=CreatedAt&SortOrder=1`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.totalPages = parseInt(res.data.totalCount / this.pageSize);
          this.data = res.data.entities;
          if (res.data.totalCount % this.pageSize != 0) {
            this.totalPages = this.totalPages + 1;
          }
        }).catch((err) => {
          console.error(err);
          this.data = [];
        })
    },
    toDatePicker(isoDate) {
      if (isoDate !== null) {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      } else {
        return null;
      }
    },
    async closePopUp() {
      await this.clearCreatePopup();
      this.showModal = false;
      this.errors = [false, false, false, false, false, false, false]
    },
    async listusers() {
      await axios.post(`${this.baseurl}document/trainingplan/traininguser?exceptuserId=${this.createTestPlan.userIds}&Assign=true`, [], this.Head)
        .then((res) => {
          this.userList = res.data;
          console.log("list users", res)
        })
        .catch(err => console.log("error in list user", err))

    },
    async clearCreatePopup() {
      this.createTestPlan = {
        userIds: [],
        trainingId: "",
        skills: "",
        details: "",
        plannedStartDate: this.isEdit ? this.createTestPlan.plannedStartDate : "",
        plannedEndDate: this.isEdit ? this.createTestPlan.plannedEndDate : "",
        trainingMethod: "Self",
        trainedBy: "",
      }
      this.designation = [];
    },
    async createTrainingPlan() {
      await this.createValidation();
      
      if (!this.errors.includes(true)) {
        axios.post(`${this.baseurl}document/trainingplan/assigntraining`, this.createTestPlan, this.Head)
          .then((res) => {
            alert("Training plan created successfully.");
            this.closePopUp();
            this.getTrainingList();
            console.log("create testplan", res);
          })
          .catch(err => {
            console.log("error in create test plan", err);
            alert("Something went wrong!");
          });
      }
      console.log("date is ", this.errors);

    },
    async updateTrainingPlan() {
      await this.updateValidation();
      if (!this.errors.includes(true)) {
        delete this.createTestPlan.userIds;
        await axios.put(`${this.baseurl}document/trainingplan/updateassigned`, this.createTestPlan,
          this.Head)
          .then((res) => {
            console.log("update training plan", res)
            alert("Training plan updated successfully.");
            this.getTrainingList();
            this.closePopUp();
          })
          .catch(err => {
            if(err.response.data.messages[0].includes("You are unable to edit a training plan that has already been signed.")){
              alert("You are unable to edit a training plan that has already been signed.");
            }
            else{
              alert("Something went wrong!");
            }
            this.closePopUp();
            this.showSidePopup= [false];
          });
      }
    },
    async deleteTrainingPlan(id) {
      if (confirm("Are you sure you want to delete this training plan?")) {
        await axios.delete(`${this.baseurl}document/trainingplan/deletetrainingplan?Id=${id}`, this.Head)
          .then((res) => {
            alert("Deleted successfully")
            console.log("training plan deleted.", res)
            this.getTrainingList();

          })
          .catch(err => {
            console.log("error in deleting training plan", err)
            if(err.response.data.messages[0].includes("You are unable to edit a training plan that has already been signed.")){
              alert("You are unable to delete a training plan that has already been signed.");
            }
            else{
              alert("Something went wrong!");
            }
            this.closePopUp();
            this.showSidePopup= [false];
          });
      }
    },
    async openPopup(id) {
      this.showModal = true;
      if (id != null) {
        this.isEdit = true;
        await this.clearCreatePopup();
        await this.getTrainingById(id);
        this.bindValue(this.selectedTraining);
      }
      else {
        this.isEdit = false;
        this.selectedTraining = "";
        await this.clearCreatePopup();
      }
    },
    async bindValue(item) {
      this.createTestPlan = {
        userIds: [],
        trainingId: item.id,
        skills: item.skills,
        details: item.trainingDetails,
        plannedStartDate: this.toDatePicker(item.plannedStartDate),
        plannedEndDate: this.toDatePicker(item.plannedEndDate),
        trainingMethod: item.trainingMethod,
        trainedBy: item.trainedBy,
      }
    },
    async saveButtonClicked() {
      if (this.isEdit == false) {
        await this.createTrainingPlan();
      }
      else {
        await this.updateTrainingPlan();
      }
    },
    async getTrainingById(id) {
      await axios.get(`${this.baseurl}document/trainingplan/gettrainingbyid?trainingId=${id}`, this.Head)
        .then((res) => {
          console.log("selected traing ", res.data);
          this.selectedTraining = res.data;
        })
        .catch(err => console.log("error in get trianing by id", err));
    },
    async onSearchClicked() {
      this.currentPage = 1;
      this.pageIndex = 1;
      await this.getTrainingList();
    },
    async listTrainingUser(designations) {
      await axios.post(`${this.baseurl}document/trainingplan/traininguser?exceptuserId=${this.createTestPlan.trainedBy}&Assign=false`, designations, this.Head)
        .then((res) => {
          this.trainerList = res.data;
          console.log("User list", res.data);
        })
        .catch(err => console.log("error in list traing user", err));
    },
    async getalldesignation() {
      await axios
        .get(`${this.baseurl}configuration/designation/search?status=active&PageSize=10000&SortProperty=displayName`,
          this.Head)
        .then((res) => {
          this.designationList = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async trainerChange() {
      this.createTestPlan.userIds = [];
      await this.listTrainingUser(this.designation);
    },

    // -- Validations -- 
    async nullValidation(index, value) {
      if (value == "" || value == null) {
        this.errors[index] = true;
      }
    },
    async isDatePrior(index, firstDate, secondDate) {
      if (secondDate < firstDate) {
        this.errors[index] = true;
      }
    },
    async isListEmpty(index, list) {
      if (list.length == 0) {
        this.errors[index] = true;
      }
    },
    async createValidation() {
      this.errors = [false, false, false, false, false, false, false];
      await this.nullValidation(0, this.createTestPlan.skills);
      await this.nullValidation(1, this.createTestPlan.plannedStartDate);
      await this.nullValidation(2, this.createTestPlan.plannedEndDate);
      await this.isDatePrior(3, this.createTestPlan.plannedStartDate, this.createTestPlan.plannedEndDate);
      await this.nullValidation(4, this.createTestPlan.trainingMethod);
      await this.nullValidation(5, this.createTestPlan.trainedBy);
      await this.isListEmpty(6, this.createTestPlan.userIds);
    },
    async updateValidation() {
      this.errors = [false, false, false, false, false, false, false];
      await this.nullValidation(0, this.createTestPlan.skills);
      await this.nullValidation(1, this.createTestPlan.plannedStartDate);
      await this.nullValidation(2, this.createTestPlan.plannedEndDate);
      await this.isDatePrior(3, this.createTestPlan.plannedStartDate, this.createTestPlan.plannedEndDate);
      await this.nullValidation(4, this.createTestPlan.trainingMethod);
      await this.nullValidation(5, this.createTestPlan.trainedBy);
    },

  }

};