/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import SidePopUp from "../side_pop_up/side_pop_up.vue"
import userconfig from "../userconfig/userconfig"
import Multiselect from "@vueform/multiselect"
import TreeFolder from "../TreeFolder/TreeFolder.vue"
import fileFormatPopUp from "../fileformatpopup/fileformatpopup.vue"
import folderEditPopUp from "../editfolderpopup/editfolderpopup.vue"
import PermissionPopUp from "../permission_popup/permission_popup.vue"
import ProjectPermission from "../project_permission/project_permission.vue"
// import doc_Approval from "../document_Approval_Popup/doc_Approval.vue"
import FileFolderProjectPopup from "../FileFolderProjectPopup/FileFolderProjectPopup.vue"
import DocEditProjectPopup from "../DocEditProjectPopup/DocEditProjectPopup.vue"
import NewDocVersion from "../NewDocVersion/NewDocVersion.vue"
import foldersendpopup from "../foldersend_popup/foldersend_popup.vue"
import fileSharePopup from "../fileSharePopup/fileSharePopup.vue"
import filesendpopup from "../filesend_popup/filesend_popup.vue";
import foldershare_popup from "../foldershare_popup/foldershare_popup.vue";
import moment from "moment";
export default {
    name: "",
    props: {
        projectId: { type: String, default: "" },
        // projectType: { type: String, default: "" },
        projectsName: { type: String, default: "" },
       
    },
    components: {

        NewDocVersion,
        fileSharePopup,
        DocEditProjectPopup,
        FileFolderProjectPopup,
        folderEditPopUp,
        fileFormatPopUp,
        SidePopUp,
        userconfig,
        PermissionPopUp,
        ProjectPermission,
        //doc_Approval,
        foldersendpopup,
        filesendpopup,
        Multiselect,
        TreeFolder,
        foldershare_popup,
    },
    data() {
        return {
            openfolderlist: "",
            arrayIndex: 0,
            isuserenabled: false,// add doc privilege
            isusercanaddfolder: false,
            userpermissions: [],
            minverError: false,
            newdocversionPopup: false,
            folderversion: "",
            editDocPopup: false,
            sourceType: "",
            showSharePopup: false,
            showProjectPermPopup: false,
            showDocumentApproval: false,
            fromPage: "",
            baseApi: `${process.env.VUE_APP_Service_URL}`,
            mainapi: `${process.env.VUE_APP_Service_URL}`,
            docurl: `${process.env.VUE_APP_Service_URL}document/document/`,
            Head: "",
            idtoken: "",
            docId: "",
            showDesig: false,
            VersionConfig: [],
            SelectedFormat: [],
            FormatList: [],
            roleList: [],
            rolesList: [],
            searchField: "",
            currentPage: 1,
            pageindex: 1,
            pagesize: 10,
            totalPage: 1,
            SortProperty: "",
            SortOrder: false,
            SortOrderInt: 0,
            errors: [],
            validations: [],
            documentlist: [],
            ItemID: "",
            showBlock: false,
            showProjectBlock: false,
            showOrg: false,
            showProject: false,
            showModal: false,
            pop: false,
            showDocBlock: false,
            selectedfolderid: "",
            selectedfoldername: "",
            values: {
                name: [],
                projectId: "",
                projectType: "",
                addToTemplate: true,
                names: []
            },
            valuestemp: {
                name: [],
            },
            tempName: [],
            search: {
                createdby: "",
                status: "",
                name: "",
            },
            documentVersion: {
                prefix: "",
                majorVerStart: "",
                minorVerStart: "",
                minorVermax: "",
            },
            prefix: "",
            majorVerStart: "",
            minorVerStart: "",
            minorVermax: "",
            deleteOrglist: [],
            showPopup: false,
            showSuggestion: false,
            showProjectSharePopup: false,
            errors: [],
            //folder
            createfolderpopup: false,
            createFolder: {
                folderName: "",
                role: [],
                user: [],
                permissions: [],
                fileConfiguration: [],
                versionConfiguration: [],
            },
            selectedUser: '',
            mainfolderbold: "",
            folderList: [],
            userType: "",
            role: "",
            permissionList: [],
            folderErrors: [false],
            folderErrorMessages: ["Please enter a folder name"],
            selectedFolder: "",
            version: "",
            documentId: "",
            //end Folder
            //dropdownname: "ProjectRole",
            desigFolderPrivileges: "",
            designationPrivileges: [],
            desigUserPrivileges: "",
            folderfordocument: '',
            prevRoute: null,
            sendtoValue: "",
            editValue: "",
            permissionsValue: "",
            shareValue: "",
            deleteValue: "",
            archiveValue: "",
            anyuserpermissions: false,
            showpopup: false,
            docValue: "",

        };
    },
    watch: {
        "$store.getters.getselectedFolder": function (val) {
            this.getFolderversion(val);
            this.showProject = true;
            this.mainfolderbold = val;
            this.folderversion = this.folderversion;
            this.folderfordocument = val;

            this.docId = store.getters.getselectedFolder
            this.getdocuments(store.getters.getselectedFolder)
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
    async mounted() {
        this.isusercanaddfolder = true,
        this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
        this.openfolderlist = store.getters.getopenfolder;
        await this.setuserPrivileges();
        store.dispatch("setSelectedProject", this.projectId)
        store.dispatch("setFromPage", "DocumentList");
        let folderfordocview = store.getters.getselectedFolder;

        this.idtoken = store.getters.getToken;
        this.Head = {
            headers: {
                Authorization: "Bearer " + store.getters.getToken,
                "Content-Type": "application/json",
            },
        };
        if (store.getters.getprvspath != '/doclogin/') {
            store.dispatch("selectFolder", "");
        }
        if (this.prevRoute != null) {
            if (this.prevRoute.path != "/") {
                localStorage.setItem("patvalue", this.prevRoute.path);
            }
            if (this.prevRoute.path == "/doclogin/") {
                this.checkDoc();
            }
        }
        if (store.getters.getdocumenttodepartment == true || store.getters.getprvspath == '/doclogin/') {
            this.setfirstdept(folderfordocview)
        }
        this.getRootFolders();
    },
    async unmounted() {
        store.dispatch("setopenfolder", "");
        if (store.getters.getdocumenttodepartment == true) {
            store.dispatch("setdocumenttodepartment", false)
        }
    },
    methods: {
        filter(data) {
            if (data) {
              return moment(data).format("DD-MM-YYYY");
            }
          },
        gotoEditPage(id,type,versionValue)        {
            if(versionValue)
            {
                if(type==='UnitTest')
                {
                    this.$router.push(`/versionunittest/${this.projectsName}/${store.getters.getselectedFolder}/${this.projectId}/${id}/1`)
                }
                else {
                    this.$router.push(`/versioncodereview/${this.projectsName}/${store.getters.getselectedFolder}/${this.projectId}/${id}/1`)
                }
            }
            else{
                if(type==='UnitTest')
                {
                    this.$router.push(`/editunittest/${this.projectsName}/${store.getters.getselectedFolder}/${this.projectId}/${id}`)
                }
                else {
                    this.$router.push(`/editcodereview/${this.projectsName}/${store.getters.getselectedFolder}/${this.projectId}/${id}`)
                }
            }
            
            
        },
        async cls() {
            this.showpopup = true
            let val = store.getters.getselectedFolder
            await this.getFolderversion(val);
            this.showProject = true;
            this.mainfolderbold = val;
            this.folderversion = this.folderversion;
            this.folderfordocument = val;
            this.docId = store.getters.getselectedFolder
            this.getdocuments(store.getters.getselectedFolder)
            this.showProjectBlock = true;

        },
        async newversionuploadactivity() {
            this.newdocversionPopup = false;
            this.getdocuments(this.folderfordocument);
        },
        async NewDocVersion() {
            this.newdocversionPopup = !this.newdocversionPopup
            this.showBlock = !this.showBlock
        },
        setfirstdept(folder) {
            console.log("Folder", folder)
            store.dispatch("selectFolder", folder);
            this.mainfolderbold = folder;
            this.folderfordocument = folder;
            this.currentselectedfolder = folder;
            this.getdocuments(folder);
        },
        isNumber: function (evt) {

            evt = (evt) ? evt : window.event;

            var charCode = (evt.which) ? evt.which : evt.keyCode;

            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 || charCode == 46) {

                evt.preventDefault();;

            } else {

                return true;

            }

        },
        isLetter(e) {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if (/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        },
        viewDoc(id,sign,unitTestId,docType) {
            if(sign)
            {
                store.dispatch("setDocSign",true)
                localStorage.setItem("currenttab", "development"); 
                localStorage.setItem('unitTestId', unitTestId);
                localStorage.setItem('devDocType', docType);
                store.dispatch("setDocViewSource", "Project")
                this.$router.push(`/documentview/` + id + "/" + 0 )
            }         
        },
        async deleteDoc(id)
        {
            if (confirm("Are you sure you want to delete this document?")) {
            await axios.delete(this.baseApi + `document/projectdevdocumenttemplate/deletedoc?id=${id}`,
            this.Head
            ).then((res)=>{ 
                alert("Document deleted successfully")})
            .catch((err)=>{
                console.log(err)
            })
            this.getdocuments(store.getters.getselectedFolder)
            }
        },
        openModelCreateFolder() {
            this.prefix = "";
            this.majorVerStart = "";
            this.minorVerStart = "";
            this.minorVermax = "";
            this.createfolderpopup = true;
            this.getallFormat();
            this.SelectedFormat = [];
            this.errors[3] = true;
            this.versionConfiguration = [];
            this.minverError = false;
        },
        closeModelCreateFolder() {
            this.createFolder.folderName = "";
            this.folderErrors[0] = false;
            this.createfolderpopup = false;
            this.SelectedFormat = [];
        },
        sidePopup(id, name, type, e) {
            e = window.event
            this.event = e
            this.popupid = id
            this.showBlock = !this.showBlock
            store.dispatch("setselectedid", id)
            store.dispatch("setfileType", type)
            store.dispatch("setfileName", name)
        },
        async getDocumentdetails(id) {
            this.sendtoValue = "";
            this.editValue = "";
            this.permissionsValue = "";
            this.shareValue = "";
            this.deleteValue = "";
            this.archiveValue = "";
            await axios
                .get(
                    `${this.docurl}getdocumentdetails?documentId=${id}`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {

                    if (res.data.permissions != null) {
                        this.anyuserpermissions = false;

                        res.data.permissions.forEach((item) => {
                            if (item == "Clone") this.sendtoValue = item;
                            if (item == "Permissions") this.permissionsValue = item;
                            if (item == "Share") this.shareValue = item;
                            if (item == "Edit") this.editValue = item;
                            if (item == "Delete") this.deleteValue = item;
                            if (item == "Archive") this.archiveValue = item;
                        });
                        if (
                            !this.sendtoValue &&
                            !this.permissionsValue &&
                            !this.shareValue &&
                            !this.editValue &&
                            !this.deleteValue &&
                            !this.archiveValue
                        ) {
                            this.anyuserpermissions = true;
                        }
                    }
                    else {
                        this.anyuserpermissions = true;
                    }
                    // this.getVersionHistory();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        GoToDocPage() {
            if (this.docValue == "Unit Test") {
                this.$router.push(`/createunittest/${this.projectsName}/${store.getters.getselectedFolder}/${this.projectId}`)
            }
            if (this.docValue == "Code Review") {
                this.$router.push(`/createcodereview/${this.projectsName}/${store.getters.getselectedFolder}/${this.projectId}`)
            }

        },
        sidedocPopup(index, id, name, type, e) {
            this.getDocumentdetails(id);
            e = window.event
            this.event = e
            this.popupid = id
            this.arrayIndex = index
            this.showDocBlock = !this.showDocBlock
            store.dispatch("setselectedid", id)
            store.dispatch("setfileType", type)
            store.dispatch("setfileName", name)
        },
        trimtext(value) {
            value = value.replace(
                /^\s+|\s+$/gm,
                ""
            );
            return value
        },
        saveVersion() {
            if (this.documentVersion.majorVerStart == "" | this.documentVersion.majorVerStart == null) {
                alert("Major version is mandatory");
            }
            else {
                this.VersionConfig = []
                this.VersionConfig.push(this.documentVersion);

            }
        },
        async createfolder() {
            this.validateFolder();
            this.minverError = false;
            if (this.minorVermax != "" && parseInt(this.minorVerStart) > this.minorVermax) {
                this.minverError = true;
            }
            else {
                if (!this.folderErrors.includes(true)) {
                    await axios
                        .post(this.baseApi + `document/folder/create`,
                            {
                                name: this.createFolder.folderName,
                                parentId: store.getters.getselectedFolder,
                                storageId: this.projectId,
                                storageType: "Project Documents",
                                fileConfiguration: this.SelectedFormat,
                                "versionConfiguration": [
                                    {
                                        "prefix": this.prefix,
                                        "majorVerStart": this.majorVerStart,
                                        "minorVerStart": this.minorVerStart,
                                        "minorVermax": this.minorVermax
                                    }
                                ]
                            },
                            this.Head
                        )
                        .then((res) => {
                            console.log(res);
                            alert("Folder created successfully.");
                            this.closeModelCreateFolder();
                            this.getRootFolders();
                            document.getElementById(store.getters.getselectedFolder + 'doc').click();
                            document.getElementById(store.getters.getselectedFolder + 'doc').click();
                        })
                        .catch((err) => {
                            console.log(err.response.data.detail)
                            if (err.response.data.detail == "Folder name already present in the parent folder.") {
                                alert("Folder name already exist.");


                            }
                            else if (err.response.data.detail == "Major version start field cannot be empty.") {
                                alert("Major version start field cannot be empty.");
                            }
                            else {
                                if (err.response.data.detail == "Folder name already present in the parent department.") {
                                    alert("Folder name already exist.");
                                }
                            }
                        });
                }
            }
        },
        async getFolderversion(folderid) {

            await axios
                .get(
                    `${this.mainapi}document/folder/getfolderversion?Id=${folderid}`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.folderversion = res.data;
                    if (this.folderversion.includes(':')) {
                        this.folderversion = (this.folderversion).replace(':', '.');
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            //get user privileged folder controls
            await axios
                .get(`${this.mainapi}document/folderfilepermission/getuserfolderpermission?FolderId=${folderid}`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then(async (res) => {
                    this.isuserenabled = false;
                    this.userpermissions = res.data != null ? res.data : null;
                    if (this.userpermissions != null) {
                        if (this.userpermissions.includes("Upload")) {
                            this.isuserenabled = true;
                        }
                        if (this.userpermissions.includes("Add")) {
                            this.isusercanaddfolder = true;
                        }
                        else {
                            this.isusercanaddfolder = false;
                        }
                    }
                    else {
                        this.isusercanaddfolder = false;
                    }
                    // this.ancestors = res.data.ancestorIds;         
                })
                .catch((err) => {
                    console.log(err);
                });

        },
        async DocEditPopup() {

            this.editDocPopup = !this.editDocPopup
            this.showDocBlock = false;

        },
        async uploadeditactivity() {
            this.editDocPopup = false;
            this.getdocuments(this.folderfordocument);
        },
        async ShareDocument() {
            this.showProjectSharePopup = !this.showProjectSharePopup
            this.sourceType = "File"
        },
        async FilePermissionPopup() {
            this.showProjectPermPopup = !this.showProjectPermPopup
            this.sourceType = "File"
            this.showDocBlock = false;
        },
        async getRootFolders() {
            console.log("hello", this.projectId)
            await axios
                .get(this.baseApi + `document/folder/getprivilegedroots?storageId=${this.projectId}&storageType=Project Documents`, this.Head
                )
                .then((res) => {
                    this.folderList = res.data;
                    console.log("project folder list is ", res);

                })
                .catch((err) => {
                    console.log(err.response.data.messages)
                })

        },
        async OnSearchButtonClicked() {
            this.pageindex = 1;
            this.currentPage = 1;
            this.searchField = this.search.name;
            this.ListOrgs()
        },
        async getdocuments(id) {
            this.fromPage = "ProjectSummary";
            this.documentlist = []
            await axios
                .get(
                    `${this.baseApi}document/projectdevdocumenttemplate/unittestdoclist?folderId=${id}`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    this.documentlist = res.data;
                    console.log("Docs", this.documentlist);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async DeleteDocument(id) {
            this.showDocBlock = false;
            console.log("Document Id.........", id)
            const documentId = id
            if (confirm("Are you sure you want to delete this document?")) {
                await axios.delete(this.docurl + "delete?documentId=" + documentId,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                    .then((res) => {
                        console.log(res)
                        alert("Document deleted successfully")
                    })
                    .catch((err) => {
                        console.log(err);
                        this.documentlist = []
                        alert("Somthing Went Wrong")
                    })
                this.pageindex = 1;
                this.currentPage = 1;
                this.getdocuments(store.getters.getselectedFolder);

            }
        },
        async sharefolder() {
            this.showSharePopup = !showSharePopup;
            this.sourceType = file;
            console.log("it works")
        },
        async ArchievedDocument(id) {
            this.showDocBlock = false;
            console.log("Document Id.........", this.idtoken)
            const documentId = id;

            if (
                confirm("Are you sure you want to archive this document ?")
            ) {
                //Api call  
                await axios
                    .put(`${this.mainapi}document/document/archive?id=${documentId}`, {}, {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        console.log(res)
                        alert("Document archived successfully")
                        //tree refresh
                    })
                    .catch((err) => {
                        console.log(err.message)
                    })
                this.pageindex = 1;
                this.currentPage = 1;
                this.getdocuments(store.getters.getselectedFolder);
            }
        },
        async getallProjectFolders(id) {
            console.log("in get project folders");
            console.log("hello", this.projectId)
            await axios
                // .get(`https://localhost:5001/folder/getroots?storageId=${id}`, this.Head
                .get(`${this.baseApi}document/folder/getprivilegedroots?storageId=${this.projectId}&storageType=Project Documents`, this.Head
                )
                .then((res) => {
                    this.folderList = res.data;
                    console.log("project folder list is ", res);

                })
                .catch((err) => {
                    console.log(err.response.data.messages)
                })

        },
        validateFolder() {
            this.createFolder.folderName = this.trimtext(this.createFolder.folderName);
            if (this.createFolder.folderName == "") {
                this.folderErrors[0] = true;
                this.folderErrorMessages[0] = "Please enter a folder name";
            }
            else if (this.createFolder.folderName.length > 75) {
                this.folderErrors[0] = true;
                this.folderErrorMessages[0] = "Maximum number of characher is 75";
            }
            else {
                this.folderErrors[0] = false;
            }
        },
        async backtorootfolder() {
            store.dispatch("selectFolder", "");
            this.mainfolderbold = "",
                this.currentselectedfolder = "";
            this.isusercanaddfolder = true,
                this.getRootFolders();
        },

        // end of create folder


        openModelrole() {
            document.body.style.overflowY = "hidden"
            this.showPopup = true;
            this.pop = true;
            this.create = true;
            this.edits = false;
            this.values.names = [];
        },
        editModelRole(id, name) {

            this.values.name = name;
            this.values.id = id;
            console.log("ROLE ID and NAME", id, name);
            this.showPopup = true;
            this.pop = false;
            this.create = true;
            this.edits = false;
        },
        async closemodal() {
            document.body.style.overflowY = "auto"
            this.showPopup = false;
            this.errors[0] = true;
            this.values.name = "";
        },
        deleteFromList(Roles, index) {
            this.rolesList.splice(index, 1);
        },
        async checkDoc() {
            const idtoken = store.getters.getToken;
            this.selectedfolder = store.getters.getselectedFolder
            console.log("FOLDER ID ISSSSSSSSSSSSSSSSSSSSSSSSSS", store.getters.getselectedFolder)
            await axios
                .get(
                    `${this.mainapi}document/folder/getarchive?Id=${this.selectedfolder}`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    console.log("RESSS", res);
                    if (res.data == "The folder you are trying to access is currently archived!") {
                        alert(res.data);
                        this.$router.push(`/dashboard`);
                    }
                })
                .catch((err) => {
                    console.log(err.response.data);
                });
        },
        async validateIsNull() {

            if (this.values.name == "") {
                this.validations[0] = false;
                this.errors = this.validations;
            } else if (this.values.name.trim("") == "") {
                this.validations[0] = false;
                this.errors = this.validations;
            } else {
                console.log("vvvvvvvv2")
                this.validations[0] = true;
                this.errors = this.validations;
            }
        },
        async getallFormat() {
            await axios
                .get(`${this.mainapi}document/documentformat/search`,
                    {
                        headers: {
                            Authorization: "Bearer " + this.idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                .then((res) => {
                    this.FormatList = res.data.entities[0].values;
                    console.log("Format List", this.FormatList)
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async setuserPrivileges() {
            let allFolderPriv = {
                "Projects Edit User": "Edit",
                "Projects Delete User": "Delete",
                "Projects Create User": "Create User"
            }

            const privilegeArray = store.getters.getRolesprivilegeData.split(',').map((s) => s.trim());
            const keys = Object.keys(allFolderPriv);
            // Return Privileged Folder controlls.
            this.desigUserPrivileges = keys
                .filter((key) => privilegeArray.includes(key))
                .map((key) => allFolderPriv[key]).join(',');
        }
    },
};