/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import VPagination from "@hennge/vue3-pagination";
import myTrainingPlan from "../myTrainingPlan/myTrainingPlan.vue"
import approveTrainingPlan from "../approveTrainingPlan/approveTrainingPlan.vue"
import addTrainingPlan from "../../components/addTrainingPlan/addTrainingPlan.vue"
export default {
    components:{
        myTrainingPlan,
        approveTrainingPlan,
        addTrainingPlan
    },
    data() {
        return {
          refreshFlags: {
            myTrainingPlan: false,
            approveTrainingPlan: false,
            addTrainingPlan: false,
            designationPrivileges:""
          }
        };
      },
      mounted(){
        this.designationPrivileges = store.getters.getRolesprivilegeData.split(",");
        console.log("Privileges are ", this.designationPrivileges)
      },
      methods: {
        handleTabClick(tab) {
          // Set the corresponding flag to true to trigger refresh
          this.refreshFlags[tab] = true;
    
          // Reset the flag after a short delay to allow the refresh to occur
          setTimeout(() => {
            this.refreshFlags[tab] = false;
          }, 100);
        },
        goBack() {
          this.$router.push(`/training`);
        }
      }
    };