/* eslint-disable */
import jwt_decode from "jwt-decode";
import store from "../../store/index";
import axios from "axios";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import VPagination from "@hennge/vue3-pagination";
export default {
  name: "myTrainingPlan",
  components: { VPagination },
  props: {
    refreshFlag: Boolean, // Define a prop to trigger refresh
  },
  watch: {
    refreshFlag(newVal) {
      if (newVal) {
        this.refreshContent();
      }
    },
  },
  computed: {
    dateToday() {
      // Calculate the minimum allowed date (today's date) with time zone information
      const today = new Date();
      const year = today.getUTCFullYear();
      const month = String(today.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
      const day = String(today.getUTCDate()).padStart(2, "0");
      const hours = String(today.getUTCHours()).padStart(2, "0");
      const minutes = String(today.getUTCMinutes()).padStart(2, "0");
      const seconds = String(today.getUTCSeconds()).padStart(2, "0");
      const milliseconds = String(today.getUTCMilliseconds()).padStart(3, "0");

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    },
  },
  data() {
    return {
      designationPrivileges:[],
      showMoreStatesSkills: {},
      showMoreStates: {},
      searchActualstartdate: "",
      searchActualenddate: "",
      searchPlannedstartdate: "",
      searchPlannedenddate: "",
      searchStatus: "All",
      baseurl: `${process.env.VUE_APP_Service_URL}`,
      //baseurl: `https://dmstest.datamatica.uk/v1/`,
      Head: "",
      idtoken: "",
      totalPages: 1,
      pageIndex: 1,
      pageSize: 10,
      currentPage: 1,
      sortProperty: "createdAt",
      sortorder: 1,
      username:
        jwt_decode(store.getters.getToken).given_name +
        " " +
        jwt_decode(store.getters.getToken).family_name,
      showSidePopup: [false],
      data: [],
      showDateBlock: false,
      showModal: false,
      columnDefs: [
        {
          name: "skills",
          header:
            "Required Knowledge Skills Abilities <br><small>(Managers populate the plan for an individual)</small>",
        },
        {
          name: "trainingDetails",
          header: "Training Details",
        },
        {
          name: "trainingMethod",
          header:
            "Training Activities Identified <br><small>(indicate method of training)</small>",
        },
        {
          name: "plannedDateRange",
          header: "Planned Training Date <br><small>( DD-MMM-YYYY)</small>",
        },
        {
          name: "actualDateRange",
          header: "Actual Training Date <br><small>( DD-MMM-YYYY)</small>",
        },
        { name: "trainedBy", header: "Trained by:" },
        {
          name: "signature",
          header:
            "Training Completed Sign & Date <br><small>(Trainer or Line Manager)</small>",
        },
      ],
      testPlan: {
        trainingId: "",
        skills: "",
        details: "",
        plannedStartDate: null,
        plannedEndDate: null,
        actualStartDate: null,
        actualEndDate: null,
        trainingMethod: "Self",
        trainedBy: "",
        sendForApproval: false,
      },
      errors: [false, false, false, false, false, false, false],
      isEdit: false,
      userList: "",
      selectedTraining: "",
    };
  },
  async mounted() {
    this.idtoken = store.getters.getToken;
    this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    this.getTrainingList();
    this.listusers();
  },
  methods: {
    refreshContent() {
      console.log("Refreshed MyTraining");
      this.getTrainingList();
    },
    truncateTextSkills(text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    },
    toggleShowMoreSkills(index) {
      this.showMoreStatesSkills = {
        ...this.showMoreStatesSkills,
        [index]: !this.showMoreStatesSkills[index],
      };
    },
    truncateText(text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    },
    toggleShowMore(index) {
      this.showMoreStates = {
        ...this.showMoreStates,
        [index]: !this.showMoreStates[index],
      };
    },
    formatDateTime(dateTimeString) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const dateTime = new Date(dateTimeString);
      const day = dateTime.getDate().toString().padStart(2, "0");
      const month = months[dateTime.getMonth()];
      const year = dateTime.getFullYear();
      const hours = dateTime.getHours().toString().padStart(2, "0");
      const minutes = dateTime.getMinutes().toString().padStart(2, "0");

      return `${day}${month}${year} | ${hours}:${minutes}`;
    },

    NeededDateRange(startDate, endDate) {
      if (startDate === null || endDate === null) {
        return ""; // Return an empty string if either start or end date is null
      }

      const start = new Date(startDate);
      const end = new Date(endDate);

      const options = { day: "2-digit", month: "short", year: "numeric" };

      if (start.getTime() === end.getTime()) {
        return this.formatDate(start, options).toUpperCase(); // Return single date
      } else {
        const formattedStart = this.formatDate(start, options);
        const formattedEnd = this.formatDate(end, options);
        return `${formattedStart.toUpperCase()}\nto\n${formattedEnd.toUpperCase()}`;
      }
    },

    formatDate(date, options) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    dateBlockDisplay() {
      const cardBody = document.getElementById("cardBody");
      this.showDateBlock = !this.showDateBlock;
      if (this.showDateBlock) {
        cardBody.style.height = "94px";
      } else {
        cardBody.style.height = "0px";
      }
    },
    async clearValues() {
      this.searchActualstartdate = "";
      this.searchActualenddate = "";
      this.searchPlannedstartdate = "";
      this.searchPlannedenddate = "";
      this.searchStatus = "All";
      await this.onSearchClicked();
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageIndex = page;
      this.getTrainingList();
    },

    async onSearchClicked() {
      this.currentPage = 1;
      this.pageIndex = 1;
      await this.getTrainingList();
    },
    async exportTraining() {
      try {
        const response = await fetch(
          `${this.baseurl}document/trainingplan/trainingexport?status=${this.searchStatus}&plannedstartdate=${this.searchPlannedstartdate}&plannedenddate=${this.searchPlannedenddate}&actualstartdate=${this.searchActualstartdate}&actualenddate=${this.searchActualenddate}&SortProperty=CreatedAt&SortOrder=1`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
            },
          }
        );
        var x = response.headers.get("Content-Disposition");
        var xx = x.split("filename=")[1];
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        let filename = xx.split(";")[0];
        const name = filename.replace(/"/g, "");
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch {
        alert("No data found !");
      }
    },
    async getTrainingList() {
      await axios
        .get(
          `${this.baseurl}document/trainingplan/gettraininglist?Tab=MyTraining&status=${this.searchStatus}&plannedstartdate=${this.searchPlannedstartdate}&plannedenddate=${this.searchPlannedenddate}&actualstartdate=${this.searchActualstartdate}&actualenddate=${this.searchActualenddate}&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=CreatedAt&SortOrder=1`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.totalPages = parseInt(res.data.totalCount / this.pageSize);
          this.data = res.data.entities;
          if (res.data.totalCount % this.pageSize != 0) {
            this.totalPages = this.totalPages + 1;
          }
        })
        .catch((err) => {
          console.error(err);
          this.data = [];
        });
    },

    toDatePicker(isoDate) {
      if (isoDate !== null) {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      } else {
        return null;
      }
    },
    async closePopUp() {
      await this.clearCreatePopup();
      this.showModal = false;
      this.errors = [false, false, false, false, false, false, false];
    },
    async listusers() {
      await axios
        .post(
          `${this.baseurl}document/trainingplan/traininguser?Assign=false`,
          [],
          this.Head
        )
        .then((res) => {
          this.userList = res.data;
          console.log("list users", res);
        })
        .catch((err) => console.log("error in list user", err));
    },
    async clearCreatePopup() {
      this.testPlan = {
        trainingId: this.testPlan.trainingId,
        skills: "",
        details: "",
        plannedStartDate: this.isEdit ? this.testPlan.plannedStartDate : "",
        plannedEndDate: this.isEdit ? this.testPlan.plannedEndDate : "",
        actualStartDate: "",
        actualEndDate: "",
        trainingMethod: "Self",
        trainedBy: "",
        sendForApproval: this.selectedTraining.sendForApproval
          ? this.testPlan.sendForApproval
          : false,
      };
    },
    async validateTestPlan() {
      this.errors = [false, false, false, false, false, false, false];
      if (this.testPlan.skills == "") {
        this.errors[0] = true;
      }
      if (this.testPlan.plannedStartDate && this.testPlan.plannedEndDate) {
        if (this.testPlan.plannedEndDate < this.testPlan.plannedStartDate) {
          this.errors[2] = true;
        }
      } else {
        this.errors[1] = true;
      }

      if (this.testPlan.sendForApproval == true) {
        if (this.testPlan.actualStartDate && this.testPlan.actualEndDate) {
          if (this.testPlan.actualEndDate < this.testPlan.actualStartDate) {
            this.errors[4] = true;
          }
        } else {
          this.errors[3] = true;
        }
      }

      if (this.testPlan.trainingMethod == "") {
        this.errors[5] = true;
      }
      if (this.testPlan.trainedBy == "") {
        this.errors[6] = true;
      }
    },
    async createTrainingPlan() {
      console.log("create test plan", this.testPlan);
      await this.validateTestPlan();
      if (!this.errors.includes(true)) {
        axios
          .post(
            `${this.baseurl}document/trainingplan/createtrainingplan`,
            this.testPlan,
            this.Head
          )
          .then((res) => {
            if (this.testPlan.sendForApproval == true) {
              // If sendForApproval is true, show an alert
              confirm(
                "Are you sure want to send the training plan for approval?"
              );
              alert("Training plan created successfully.");
            } else {
              alert("Training plan created successfully.");
            }
            this.closePopUp();
            this.getTrainingList();
            console.log("create testplan", res);
          })
          .catch((err) => {
            console.log("error in create test plan", err);
            alert("Something went wrong!");
          });
      }
      console.log("date is ", this.errors);
    },
    async updateTrainingPlan() {
      await this.validateTestPlan();
      if (!this.errors.includes(true)) {
        if ((this.selectedTraining.sendForApproval == true)){
          await this.updateAPI();
     }
           else if ((this.testPlan.sendForApproval == true)) {
            if (confirm("Are you sure want to send the training plan for approval?")) {
              await this.updateAPI();
            }
          }
          else{
            await this.updateAPI();
          }
         
        } 
    },
    async updateAPI(){
      await axios
      .put(
        `${this.baseurl}document/trainingplan/updatetrainingplan`,
        this.testPlan,
        this.Head
      )
      .then((res) => {
        console.log("update training plan", res);
        alert("Training plan updated successfully.");
        this.getTrainingList();
        this.closePopUp();
      })
      .catch((err) => {
        if (
          err.response.data.messages[0].includes(
            "You are unable to edit a training plan that has already been signed."
          )
        ) {
          alert(
            "You are unable to edit a training plan that has already been signed."
          );
          this.closePopUp();
        } else {
          alert("Something went wrong!");
        }
        this.closePopUp();
        this.showSidePopup = [false];
      });
  },
    
    async deleteTrainingPlan(id) {
      if (confirm("Are you sure you want to delete this training plan?")) {
        await axios
          .delete(
            `${this.baseurl}document/trainingplan/deletetrainingplan?Id=${id}`,
            this.Head
          )
          .then((res) => {
            alert("Deleted successfully");
            console.log("training plan deleted.", res);
            this.getTrainingList();
          })
          .catch((err) => {
            console.log("error in deleting training plan", err);
            if (
              err.response.data.messages[0].includes(
                "You are unable to edit a training plan that has already been signed."
              )
            ) {
              alert(
                "You are unable to delete a training plan that has already been signed."
              );
            } else {
              alert("Something went wrong!");
            }
            this.closePopUp();
            this.showSidePopup = [false];
          });
      }
    },
    async openPopup(id) {
      this.showModal = true;
      if (id != null) {
        this.isEdit = true;
        await this.clearCreatePopup();
        await this.getTrainingById(id);
        this.bindValue(this.selectedTraining);
      } else {
        this.isEdit = false;
        this.selectedTraining = "";
        await this.clearCreatePopup();
      }
    },
    async bindValue(item) {
      this.testPlan = {
        trainingId: item.id,
        skills: item.skills,
        details: item.trainingDetails,
        plannedStartDate: this.toDatePicker(item.plannedStartDate),
        plannedEndDate: this.toDatePicker(item.plannedEndDate),
        actualStartDate: this.toDatePicker(item.actualStartDate),
        actualEndDate: this.toDatePicker(item.actualEndDate),
        trainingMethod: item.trainingMethod,
        trainedBy: item.trainedBy,
        sendForApproval: item.sendForApproval,
      };
    },
    async saveButtonClicked() {
      if (this.isEdit == false) {
        await this.createTrainingPlan();
      }
        else{
          await this.updateTrainingPlan();
        }
      
    },
    async getTrainingById(id) {
      await axios
        .get(
          `${this.baseurl}document/trainingplan/gettrainingbyid?trainingId=${id}`,
          this.Head
        )
        .then((res) => {
          console.log("selected traing ", res.data);
          this.selectedTraining = res.data;
        })
        .catch((err) => console.log("error in get trianing by id", err));
    },
    async dateSet() {
      this.testPlan.sendForApproval = true;
    },
  }
  
}
