/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";

export default {
  data() {
    return {

      fullFilename: "",
      docurl: `${process.env.VUE_APP_Service_URL}document/documentquestion/`,
      idtoken: "",
      searchField: {
        employeename: "",
        testresult: "",
        status: "",
        fromdate: "",
        todate: ""
      },

      userExamList: [],
      currentPage: 1,
      pageindex: 1,
      pagesize: 10,
      totalPage: 1,
      SortOrder: 1,//desc
      
      fromtab: true,
      userRole: "Initiator",


      errors: [false, false, false, false, false, false],
    };
  },
  computed: {
    dateToday() {
      // Calculate the minimum allowed date (today's date) with time zone information
      const today = new Date();
      const year = today.getUTCFullYear();
      const month = String(today.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const day = String(today.getUTCDate()).padStart(2, '0');
      const hours = String(today.getUTCHours()).padStart(2, '0');
      const minutes = String(today.getUTCMinutes()).padStart(2, '0');
      const seconds = String(today.getUTCSeconds()).padStart(2, '0');
      const milliseconds = String(today.getUTCMilliseconds()).padStart(3, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    },
  },
  async mounted() {

    const currenttab = localStorage.getItem("currenttab");
    if (currenttab == '') {
      document.querySelector('.nav-link.active').classList.remove('active');
      document.getElementById('Initiator').classList.add('active');
      document.getElementById('tab-1').classList.add('active');
      this.userRole = 'Initiator'
    }
    if (currenttab == 'Employee') {
      document.querySelector('.nav-link.active').classList.remove('active');
      document.getElementById('Employee').classList.add('active');
      document.getElementById('tab-3').classList.add('active');
      this.userRole = 'Employee'
    }
    if (currenttab == 'Supervisor') {
      document.querySelector('.nav-link.active').classList.remove('active');
      document.getElementById('Supervisor').classList.add('active');
      document.getElementById('tab-2').classList.add('active');
      this.userRole = 'Supervisor'
    }
    if (currenttab == 'Initiator') {
      document.querySelector('.nav-link.active').classList.remove('active');
      document.getElementById('Initiator').classList.add('active');
      document.getElementById('tab-1').classList.add('active');
      this.userRole = 'Initiator'
    }
    this.designationPrivileges = store.getters.getRolesprivilegeData;
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    this.idtoken = store.getters.getToken
    this.ListUserExam();
    this.userEmail = store.getters.getUserEmail;
  },

  beforeRouteLeave(to, from, next) {
    console.log("to", to.name)
    if (to.name != "myQuestion") {
      localStorage.setItem("currenttab", '');
      document.querySelector('.nav-link.active').classList.remove('active');
      document.getElementById('Initiator').classList.add('active');
      document.getElementById('tab-1').classList.add('active');
      this.userRole = 'Initiator'
    }

    next()
  },
  methods: {
    filter(data) {
      if (data) {
        return moment(data).format("DD-MM-YYYY");
      }
    },
    toDatePicker(isoDate) {
      if (isoDate !== null) {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      } else {
        return null;
      }
    },
    async ListUserExam() {
      console.log("page number is ", this.pageindex);
      this.userEmail = store.getters.getUserEmail;
      await axios
        .get(
          `${this.docurl}gettestresult?userType=${this.userRole}&pageIndex=${this.pageindex}&examresult=${this.searchField.testresult}&employeeName=${this.searchField.employeename}&fromDate=${this.searchField.fromdate}&toDate=${this.searchField.todate}&PageSize=${this.pagesize}&status=${this.searchField.status}&SortOrder=${this.SortOrder}&SortProperty=dateConductedOn`,
          //`https://localhost:5001/documentquestion/gettestresult?userType=${this.userRole}&pageIndex=${this.pageindex}&examresult=${this.searchField.testresult}&employeeName=${this.searchField.employeename}&fromDate=${this.searchField.fromdate}&toDate=${this.searchField.todate}&PageSize=${this.pagesize}&status=${this.searchField.status}&SortOrder=${this.SortOrder}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.userExamList = res.data.entities;
          console.log("USER data ISSS", this.userExamList)
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.userExamList = [];
        });
    },
    async SwitchTab(role, fromtab, issearch) {
      localStorage.setItem("currenttab", role)
      this.validateDates()
      this.userRole = role;
      if (fromtab) {

        this.pageindex = 1;
        this.currentPage = 1;
      }
      if (issearch) {

      }
      else {
        this.searchField.employeename = "";
        this.searchField.fromdate = "";
        this.searchField.todate = "";
        this.searchField.testresult = "";
        this.searchField.status = "";
      }
      if (!this.errors[3]) {
        await this.ListUserExam()
      }



    },
    loadPage: function (page) {
      this.fromtab = false;
      this.currentPage = page;
      this.pageindex = page;
      this.SwitchTab(this.userRole, this.fromtab, true);

    },
    async ViewTest(configId, usersExamId) {

      this.$router.push("/myQuestion/" + configId + "/" + usersExamId);
    },
    async DownloadReport(userexamid) {
      let url = `${this.docurl}downloadsignedresult?ExamId=` + userexamid;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.idtoken}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log(" response.headers", response.headers.get("Content-Disposition"));
      let contentdispo = response.headers.get("Content-Disposition");
      let parts = contentdispo.split(/;\s/);
      let filenamePart = parts.find(part => part.startsWith('filename='));
      this.fullFilename = decodeURIComponent(filenamePart.split('=')[1].replace(/["']/g, ''));
      console.log(this.fullFilename);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.fullFilename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    validateDates() {
      if (new Date(this.searchField.todate) < new Date(this.searchField.fromdate)) {
        this.errors[3] = true;
      }
      else this.errors[3] = false
      // if (new Date(this.unitTestStartDate) > new Date(this.unitTestEndDate)) {
      //   this.unittestdateerror = true;
      // }
    },
    goBack() {
      this.$router.push(`/training`);
    }
  },
};
