<template>
  <div class="row wrapper border-bottom white-bg page-heading">
        <div class="pl-md-10 col-10 col-md-10">
        <h2 class="my-0 py-0">SOP Training Form</h2>
        </div>
        <div class="col-2 col-md-2 align-self-center">
        <i class="fa fa-times close cursor-pointer" @click="close()"></i>
        </div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <h3 class="mb-0" style="color: #618BD7;"> Trainee Information </h3>
            <hr class="text-warning mb-0" style="border-color: #618BD7; margin-top:5px;">
            <div class="form-group row p-0">
                <div class="col-lg-6 my-2">
                    <label class="col-form-label">Name : <b> {{ackDetails.traineeName  }}</b> </label>
                </div>
                <div class="col-lg-6 my-2">
                    <label class="col-form-label">Job Title/Position : <b> {{ackDetails.traineeJobTitle}} </b></label>
                </div>
                <div class="col-lg-6 my-2">
                    <label class="col-form-label">Planned Start Date of Training : <b> {{ formatDate(ackDetails.plannedStartDate)}}</b></label>
                </div>
                <div class="col-lg-6 my-2">
                    <label class="col-form-label">Planned End Date of Training : <b>{{ formatDate(ackDetails.plannedEndDate)}}</b> </label>
                </div>
            </div>

            <h3 class="mb-0" style="color: #618BD7;"> Standard Operating Procedure Details </h3>
            <hr class="text-warning mb-0" style="border-color: #618BD7; margin-top:5px;">
            <table class="table table-bordered mt-3 mb-3">
                                <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Version</th>
                                    <th>Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="sopDetail in ackDetails.soP_Details" :key="sopDetail.version">
                                    <td>{{ sopDetail.title }}</td>
                                    <td>{{ sopDetail.version }}</td>
                                    <td>{{ formatDate(sopDetail.date) }}</td>
                                </tr>
                                </tbody>
            </table>

            <h3 class="mb-0 mt-4" style="color: #618BD7;">Trainee Acknowledgement</h3>
			<hr class="text-warning mb-0" style="border-color: #618BD7; margin-top:5px;">
            <p class="mt-3">I acknowledge that I have received training on the above-listed Standard Operating Procedure (SOP). I have been provided with information, instruction, and guidance regarding the contents and procedures outlined in the SOP.</p>

            <h3 class="mb-0" style="color: #618BD7;">I Understand that:</h3>
            <hr class="text-warning mb-0" style="border-color: #618BD7; margin-top:5px;">
			<ol class="mt-3 pl-3">
				<li>I am responsible for adhering to the procedures outlined in the SOP.</li>
                <li>Any deviations from the SOP must be approved by the Supervisor or Manager.</li>
				<li>I must seek clarification or additional training if I do not fully understand any part of the SOP.</li>
				<li>Compliance with the SOP is essential for safety, standard quality, and efficiency.</li>
			</ol>

			<h3 class="mb-0" style="color:#618BD7;">I confirm that:</h3>
			<hr class="text-warning mb-0" style="border-color: #618BD7; margin-top:5px;">	
            <ul class="mt-3 pl-3">
				<li>I have received and reviewed a copy of the SOP.</li>
				<li>I understand the contents and procedures outlined in the SOP.</li>
				<li>I have completed any associated training and assessments.</li>		  	
            </ul> 
            <div class=" i-checks mr-3"> &nbsp; &nbsp;
                <input 
                    class="form-check-input" 
                    type="checkbox" 
                    :value= false
                    v-model="ackDetails.isConfirmed" 
                    id="agreement"
                    :disabled="SignPerson == 'TrainerSignature' || SignPerson == 'InitiatorSignature'"
                    
                /> &nbsp; &nbsp; &nbsp; 
                <label class="mr-6 pd-3" for="agreement"> I agree <sup><i class="fa fa-asterisk required"></i></sup> </label>
                <span class="errorClass" v-if="errors[0] == true">
                        Please agree to continue.
                </span>
            </div>
            
            <h3 class="mb-0" style="color: #618BD7;">Signature and Date</h3>
			<hr class="text-warning mb-0" style="border-color: #618BD7; margin-top:5px;">
            <div class="form-group row p-2 mb-0">
                <div class="col-lg-3 my-2">
                <label class="col-form-label">Actual Start Date <sup><i class="fa fa-asterisk required"></i></sup></label>
                <input 
                    type="date" 
                    v-model="ackDetails.actualStartDate" 
                    class="form-control my-md-0"
                    :max="toDatePicker(dateToday)"
                    :disabled="
                        SignPerson == 'TrainerSignature' ||
                        SignPerson == 'InitiatorSignature' || 
                        ackDetails.status == 'Completed'">
                    <span class="errorClass" v-if="errors[1] == true">
                        Invalid start date
                    </span>
                    <span class="errorClass" v-if="errors[4] == true">
                        Actual start date cannot precede the planned start date.
                    </span>
                    <span class="errorClass" v-if="errors[6] == true">
                        The actual start date cannot be a future date. Please enter a valid start date. 
                    </span>
                </div>
				<div class="col-lg-3 my-2">
                <label class="col-form-label">Actual End Date <sup><i class="fa fa-asterisk required"></i></sup> </label>
                <input 
                    type="date" 
                    v-model="ackDetails.actualEndDate " 
                    class="form-control my-md-0"
                    :max="toDatePicker(dateToday)"
                    :disabled="
                        SignPerson == 'TrainerSignature' ||
                        SignPerson == 'InitiatorSignature' || 
                        ackDetails.status == 'Completed' ">
                <span class="errorClass" v-if="errors[2] == true"> Invalid end date. </span>
                <span class="errorClass" v-if="errors[3] == true"> Actual end date must be greater than actual start date. </span>
                <span class="errorClass" v-if="errors[5] == true"> Actual end date cannot be in the future. </span>
                </div>
                <div class="col-lg-3 my-2">
                    <div class="py-2" v-if="ackDetails.traineeSignature.initals != null && ackDetails.traineeSignature.initals != '' ">
                        <label >Signed by : </label>
                        <div class="form-control my-md-0"> <b>{{ ackDetails.traineeSignature.initals }}  </b></div>
                    </div>
                    <div v-else-if="signedIntial=== null || signedIntial=== ''">
                        <label class="col-form-label">Digital Signature</label><br>
                        <button 
                            type="button" 
                            class="btn btn-default btn-lg w-100" 
                            style="font-size: 0.7rem;"
                            @click="signDocClicked('trainee')"
                            :disabled="SignPerson == 'TrainerSignature' || SignPerson == 'InitiatorSignature'"
                        > <b>Click here to sign </b>
                        </button>
                    </div>
                    <div v-else class="py-2">
                        <label >Signed by : </label><br>
                        <div class="form-control my-md-0" > {{ signedIntial }} </div>
                    </div>
                
                </div>
				<div class="col-lg-3 my-2 py-2" v-if="(ackDetails.traineeSignature.initals != null && ackDetails.traineeSignature.initals != '') || !(signedIntial=== null || signedIntial=== '')">
                    <label > Date  </label>
                    <div class="form-control my-md-0"> <b>{{ formatDate(ackDetails.traineeSignature.signedDate) }}</b> </div>
                </div>
            </div>

            <h3 class="mb-0" style="color: #618BD7;" v-if="SignPerson != 'TraineeSignature'" >Supervisor/Trainer Verification</h3>
			<hr class="text-warning mb-0" style="border-color: #618BD7; margin-top:5px;" v-if="SignPerson != 'TraineeSignature'">
            <p class="mt-3" v-if="SignPerson != 'TraineeSignature'">I confirm that the above-named trainee has completed the training on the specified SOP to the required standard and understanding. They have demonstrated the knowledge and skills required to perform the procedures outlined in the SOP.</p>
            <div class="form-group row px-2" v-if="SignPerson != 'TraineeSignature'">
                <div class="col-lg-12 my-2">
                    <label class="col-form-label">Comments or Additional Notes(If any):</label>
                    <textarea 
                        :disabled="
                            (ackDetails.trainerSignature.initals != null && ackDetails.trainerSignature.initals != '') || 
                            !(signedIntial=== null || signedIntial=== '') || 
                            ackDetails.status == 'Initiated' ||
                            SignPerson != 'TrainerSignature'" 
                        v-model="ackDetails.trainerComments" 
                        class="w-100" rows="4" cols="50">
                    </textarea>
                </div>
				<div class="col-lg-4 my-2 py-2">
                    <label>Supervisor/Trainer's Name </label>
                    <div class="form-control my-md-0"> <b> {{ ackDetails.trainerName }} </b></div>
                </div>
                <div class="col-lg-4 my-2"> 
                    <div class="py-2" v-if="ackDetails.trainerSignature.initals != null && ackDetails.trainerSignature.initals != '' ">
                        <label >Supervisor/Trainer's Signature:</label>
                        <div class="form-control my-md-0" >{{ ackDetails.trainerSignature.initals }} </div>
                    </div>
                    <div v-else-if="signedIntial=== null || signedIntial=== ''">
                        <label class="col-form-label">Supervisor/Trainer's Signature:</label>
                        <button 
                            type="button" 
                            class="btn btn-default btn-lg w-100" 
                            style="font-size: 0.7rem;"
                            @click="signDocClicked('trainer')"
                            :disabled="ackDetails.status == 'Initiated' || SignPerson == 'InitiatorSignature'"
                            ><b>Click here to sign </b>
                        </button>
                    </div>
                    <div class="py-2" v-else>
                        <label>Supervisor/Trainer's Signature:</label>
                        <div class="form-control my-md-0" >{{ signedIntial }} </div>
                    </div>
                </div>
				<div class="col-lg-4 my-2 py-2" v-if="(ackDetails.trainerSignature.initals != null && ackDetails.trainerSignature.initals != '') || !(signedIntial=== null || signedIntial=== '')">
                    <label>Date</label>
                    <div class="form-control my-md-0"> <b> {{ formatDate(ackDetails.trainerSignature.signedDate) }} </b></div>
                </div>	
            </div>
            <p class="text-danger text-center">This Training Acknowledgment Form confirms that the trainee has been properly trained on the specified SOP and understands their responsibilities. It should be retained as a record of training completion.</p>
        </div>
    </div>
  </div>
  <DigitalSignPopUp
    v-if="showsign"
    @closePopup="closePopupfun()"
    @signAdded = "signAddedFun"
    :acknId="ackId"
    :SigningPerson="SignPerson"
  >
  </DigitalSignPopUp>
</template>
<script src="./SOP_AcknowledgmentForm.js"></script>
<style scoped>
    .custom-signbox { 
        background-color: #e2edf3; /* Light grey background color */  
        display: inline-block;/* Ensures it only takes up as much width as necessary */ 
        padding: 10px; /* Add some padding for spacing */}
    .errorClass {
    color: red;
    font-size: 15px;
    }
    .fa-asterisk {
        color: #e56363 !important;
        font-size: 6px;
    }
</style>