/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { encrypt } from "../../common/encryption"
export default {
  name: "editunittest",
  props: {
    projectsName: {
      type: String,
      default: ""
    },
    folderId: {
      type: String,
      default: ""
    },
    projectId: {
      type: String,
      default: ""
    },
    unitTestId:{
      type: String,
      default: "" 
  },
  },
  data() {
    return {
      checklistcolumns: [],
      id: "42f08fef-8245-4578-8367-060fb75c72c8",
      baseurl: process.env.VUE_APP_Service_URL,
      element: [],
      templatePassword: "",
      idtoken: "",
      visibleOn: false,
      templateElements: {},
      storyDetails: [],
      temptableList: [],
      validationList: [],
      tableList: [],
      userList: [],
      checkListObj: {
        data: "",
        comment: "",
        suggestion: "",
        noOfBugs: ""
      },
      checkList: [],
      tabledata: {
        tableHeader: "",
        tableContents: []
      },
      tableRows: {
        tableColumns: []
      },
      tableContents: [],
      tableInnerContent: {
        tableHeading: "",
        tableData: [""
        ]
      },
      loadingBlock: false,
      documentType: "",
      projectName: "",
      storyTitle: "",
      storyId: "",
      moduleName: "",
      moduleLead: "",
      developerName: "",
      unitTesterName: "",
      developmentStartDate: "",
      developmentEndDate: "",
      unitTestStartDate: "",
      unitTestEndDate: "",
      hoursTaken: "",
      remarks: "",
      pageLink: "",
      emailId: "",
      showpopup: false,
      inputType: "password",
      errorMsg: false,
      showValidations: [],
      locationList: [],
      reasonList: [],
      exceptionList:[],
      error: [false, false],
      isvalid: false,
      devdateerror: false,
      unittestdateerror: false,
      acceptanceError:false,
      checkListTitleError:false,
      checkListAnswer:false,
      invalidpass: false,
      clicked: false,
      validation: false,
      signValue:false,
      loadingForm:false,
      loadingSaveBlock:false,
      err: "Invalid username or password.",
      reasons: "",
      location: "",
      otherReason: "",
      checklisttabledata:[]
    }
  },
  async mounted() {
    this.projectName = this.projectsName
    this.idtoken = store.getters.getToken;
    this.unitTesterName = jwt_decode(store.getters.getToken).given_name + " " + jwt_decode(store.getters.getToken).family_name;
    await this.templateFields();
    await this.ListUsers();
    await this.getDataException()
    await this.getData()  

  },
  methods: {
    toDatePicker(isoDate) {
      if (isoDate !== null) {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      } else {
        return null;
      }
    },
    async getDataException()
    {
      await axios
        .get(
          `${this.baseurl}document/projectdevdocumenttemplate/getunittestexception?unittestId=${this.unitTestId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
          ).then((res) => {
            this.exceptionList=res.data
          }).catch((err)=>{
            console.log(err)
          })
        
    },
    async getData(){
      await axios
        .get(
          `${this.baseurl}document/projectdevdocumenttemplate/getdevdoc?id=${this.unitTestId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.storyTitle=res.data.storyTitle
          this.storyId=res.data.storyId
          this.moduleName=res.data.storyDetailsTable.moduleName 
          this.moduleLead=res.data.storyDetailsTable.moduleLead 
          this.developerName=res.data.storyDetailsTable.developerName 
          this.unitTesterName=res.data.storyDetailsTable.unitTesterName
          this.developmentStartDate=res.data.storyDetailsTable.developmentStartDate==null?null:this.toDatePicker(res.data.storyDetailsTable.developmentStartDate.slice(0, 10))
          this.developmentEndDate=res.data.storyDetailsTable.developmentEndDate==null?null:this.toDatePicker(res.data.storyDetailsTable.developmentEndDate.slice(0, 10))
          this.unitTestStartDate=res.data.storyDetailsTable.unitTestStartDate==null?null:this.toDatePicker(res.data.storyDetailsTable.unitTestStartDate.slice(0, 10))                
          this.unitTestEndDate=res.data.storyDetailsTable.unitTestEndDate==null?null:this.toDatePicker(res.data.storyDetailsTable.unitTestEndDate.slice(0, 10)) 
          this.hoursTaken=res.data.storyDetailsTable.hoursTaken 
          this.remarks=res.data.storyDetailsTable.remarks
          this.pageLink=res.data.storyDetailsTable.pageLink          
            res.data.tableList.forEach((table,index)=>{
              this.temptableList[index].tableContents.forEach((tableContent,key)=>{                
                  this.tableList[index].tableContents[key].tableData=table.tableContents[key].tableData 
                  this.temptableList[index].tableData=table.tableContents[key].tableData           
              })              
            })
          
            this.temptableList.forEach((table, index) => {
                let tablecolumndata = [];
                let tablecolumnarray = [];
                table.tableData.forEach((tablearraydata) => {
                  table.tableContents.forEach((tablearraycontents)=>{
                    tablecolumnarray.push("");
    
                  });
                  tablecolumndata.push(tablecolumnarray);
                  tablecolumnarray = [];
                });  
                this.tableRows.tableColumns.push(tablecolumndata);
                tablecolumndata = [];             
             });
             console.log("this.tablelist",this.tableList)
             console.log("this.temptableList",this.temptableList)
        })
        .catch((err) => {
          console.log("Error", err);
        }); 
    },
    closePopup() {
      this.showpopup = false
      this.reasons = ""
      this.templatePassword = ""
      this.emailId = ""
      this.errorMsg = false
      this.validation = false
      this.errorMsg = false
      this.error = [false, false]
    },
    async getLocation() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseurl}document/projectdevdocumenttemplate/getalllocation`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.locationList = res.data
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    async getReasons() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.baseurl}document/projectdevdocumenttemplate/getallreason`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.reasonList = res.data
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    selectItem(option) {
      this.reasons = option;
      this.isListVisible = false;
    },
    onClosed() {
      if (confirm("Are you sure you want to close this page?")) {
        this.$router.go(-1)
        let selectfolder = store.getters.getselectedFolder;
        store.dispatch("setopenfolder", selectfolder);
      }
    },
    async templateFields() {
      await axios
        .get(
          `${this.baseurl}document/projectdevdocumenttemplate/gettemplate?Id=${this.id}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((res) => {
          this.storyDetails = res.data.storyDetailsTbl
          this.storyDetails.forEach((item, index) => {
            this.showValidations[index] = false
          })
          this.temptableList = res.data.tableList
          this.temptableList.forEach((item, index) => {
            if (item.header != 'Signature Table') {
              this.tableList[index] = { ...this.tabledata }
              if (item.tableHeader === 'Acceptance Criteria') {
                this.tableList[index].tableHeader = 'AcceptanceTable'
              }
              if (item.tableHeader === 'Check List') {
                this.tableList[index].tableHeader = 'CheckListTable'
                this.checklisttabledata = item.tableData;
              }
              if (item.tableHeader === 'Signature Table') {
                this.tableList[index].tableHeader = 'SignatureTable'
              }
              if (item.tableHeader === 'Exception/ErrorReport') {
                this.tableList[index].tableHeader = 'ExceptionTable'
              }
              this.tableList[index].tableContents = []
              item.tableContents.forEach((tableContent, key) => {

                this.tableList[index].tableContents.push({
                  "tableHeading": "",
                  "tableData": [
                  ]
                })
                this.tableList[index].tableContents[key].tableHeading = tableContent
              })

              item.tableData.forEach((eachtabledata, tabledataindex) => {
                this.addsingleRows(index, eachtabledata)
              })
            }
          })
          this.templateElements = res.data
          setTimeout(() => {
            this.loadingForm=true
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async ListUsers() {
      await axios
        .get(
          `${this.baseurl}configuration/userprojectrole/getprojectusers?projectId=${this.projectId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.userList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showPopupvalid(saveValue) {
      this.signValue=saveValue;
      this.validation = false;
      this.devdateerror = false;
      this.unittestdateerror = false;
      this.acceptanceError = false
      this.checkListTitleError=false,
      this.checkListAnswer=false,
      this.validationList = this.tableList
      this.showValidations.forEach((item, index) => {
        this.showValidations[index] = true
      })
      let showval = (
        this.storyTitle != "" &&
        this.storyId != "" &&
        this.moduleName != "" &&
        this.developerName != "" &&
        this.moduleLead!= ""&&
        this.unitTesterName != "" );
        this.validationList.forEach(async (validation) => {
          if(validation.tableHeader==='AcceptanceTable')
          {
            this.validationList[0].tableContents[0].tableData.forEach(async (data) => {
              if(data==='')
              {
                this.acceptanceError=true
              }
            })
            this.validationList[0].tableContents[1].tableData.forEach(async (data) => {
              if(data==='')
              {
                this.acceptanceError=true
              }
            })
          }
          if(validation.tableHeader==='CheckListTable')
          {
            this.validationList[1].tableContents[0].tableData.forEach(async (data) => {
              if(data==='')
              {
                this.checkListTitleError=true
              }
            })
            this.validationList[1].tableContents[1].tableData.forEach(async (data) => {
              if(data==='')
              {
                this.checkListAnswer=true
              }
            })
          }             

        });
      if (new Date(this.developmentStartDate) > new Date(this.developmentEndDate)) {
        this.devdateerror = true;
      }
      if (new Date(this.unitTestStartDate) > new Date(this.unitTestEndDate)) {
        this.unittestdateerror = true;
      }
      if (showval === false) {
        window.scroll(0, 0)
      }
      else if (this.devdateerror || this.unittestdateerror) {
        window.scroll(0, 200)
      }
      else if (this.acceptanceError) {
        alert("Please fill all mandatory fields");
        window.scroll(0, 300)
      }
      else if(this.checkListTitleError===true || this.checkListAnswer===true)
      {
        alert("Please fill all mandatory fields");
        window.scroll(0, 600)
      }
      if (showval && this.acceptanceError===false && this.checkListTitleError===false && this.checkListAnswer===false && (!this.devdateerror) && (!this.unittestdateerror) && saveValue!='Save') {
        this.showpopup = true
        this.getLocation()
        this.getReasons()        
      }
      if (showval && this.acceptanceError===false && this.checkListTitleError===false && this.checkListAnswer===false && (!this.devdateerror) && (!this.unittestdateerror) && saveValue==='Save'){
        this.submitTemplate()
      }
    },
    async numofbugsinput(event,index,dataindex,columnindex){
      this.tableList[index].tableContents[dataindex].tableData[columnindex] = event.target.value.toString();
    },
    async submitTemplate() {
      let apiResultValue = ''
      this.validation=false
      this.error[1] = false
      if (!this.clicked) {
        this.clicked = true
       
        if (this.reasons === 'Others' && this.signValue!='Save') {
          apiResultValue = this.otherReason
          if (apiResultValue === '')
            this.error[0] = true;
        }
        else apiResultValue = this.reasons
        if ((this.templatePassword === "" || this.reasons === '')  && this.signValue!='Save') {
          if (this.templatePassword === "") {
            this.errorMsg = true
          }
          if (this.reasons === '') {
            this.error[1] = true;
          }
          // if(this.location==='')
          // {
          //   this.error[0]=true;
          // }
          this.clicked = false
        }
        else {
          if(this.signValue==='Save'){
            this.loadingSaveBlock=true
          }else{
            this.loadingBlock = true
          }
          this.errorMsg = false
          this.error = [false, false]
          const encryptedPassword = await encrypt(this.templatePassword.trim());
          if(this.signValue!='Save')
          {
            
            await axios
            .put(
              `${this.baseurl}document/projectdevdocumenttemplate/updateunittest?id=${this.unitTestId}`, {
              "documentType": "Unit Test",
              "folderId": this.folderId,
              "projectName": this.projectName,
              "storyTitle": this.storyTitle,
              "storyId": this.storyId,
              "signerPassword": encryptedPassword,
              "email": this.emailId,
              "location": "India",
              "reason": apiResultValue,
              "storyDetailsTable": {
                "moduleName": this.moduleName,
                "moduleLead": this.moduleLead,
                "developerName": this.developerName,
                "unitTesterName": this.unitTesterName,
                "developmentStartDate": this.developmentStartDate === ""? null : this.developmentStartDate,
                "developmentEndDate": this.developmentEndDate === ""? null : this.developmentEndDate,
                "unitTestStartDate": this.unitTestStartDate === ""? null : this.unitTestStartDate,
                "unitTestEndDate": this.unitTestEndDate === ""? null : this.unitTestEndDate,
                "hoursTaken": this.hoursTaken==null? null : this.hoursTaken.toString(),
                "remarks": this.remarks,
                "pageLink": this.pageLink
              },
              "tableList": this.tableList,
              "InputType":this.signValue
            },
              {
                responseType: "blob",
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              this.validation = false;
              alert("Document generated successfully");
              if (res.data) {
                this.$router.go(-1)
                let selectfolder = store.getters.getselectedFolder;
                store.dispatch("setopenfolder", selectfolder);
              }

            })
            .catch((err) => {
              this.loadingBlock = false
              if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.DomainValidations && err.response.data.errors.DomainValidations[0]) {
                this.err = err.response.data.errors.DomainValidations[0];
              }
              else {
                this.err = "Invalid username or password."
              }
              this.validation = true;
              this.clicked = false
            });
          }
          else{
            
            await axios
            .put(
              `${this.baseurl}document/projectdevdocumenttemplate/updateunittest?id=${this.unitTestId}`, {
              "documentType": "Unit Test",
              "folderId": this.folderId,
              "projectName": this.projectName,
              "storyTitle": this.storyTitle,
              "storyId": this.storyId,
              "signerPassword": encryptedPassword,
              "email": this.emailId,
              "location": "India",
              "reason": apiResultValue,
              "storyDetailsTable": {
                "moduleName": this.moduleName,
                "moduleLead": this.moduleLead,
                "developerName": this.developerName,
                "unitTesterName": this.unitTesterName,
                "developmentStartDate": this.developmentStartDate === ""? null : this.developmentStartDate,
                "developmentEndDate": this.developmentEndDate === ""? null : this.developmentEndDate,
                "unitTestStartDate": this.unitTestStartDate === ""? null : this.unitTestStartDate,
                "unitTestEndDate": this.unitTestEndDate === ""? null : this.unitTestEndDate,
                "hoursTaken": this.hoursTaken==null? null : this.hoursTaken.toString(),
                "remarks": this.remarks,
                "pageLink": this.pageLink
              },
              "tableList": this.tableList,
              "InputType":this.signValue
            },
              {
                responseType: "blob",
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              this.validation = false;
              alert("Details saved successfully");
              if (res.data) {
                this.$router.go(-1)
                let selectfolder = store.getters.getselectedFolder;
                store.dispatch("setopenfolder", selectfolder);
              }

            })
            .catch((err) => {
              this.loadingBlock = false
              if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.DomainValidations && err.response.data.errors.DomainValidations[0]) {
                this.err = err.response.data.errors.DomainValidations[0];
              }
              else {
                this.err = "Invalid username or password."
              }
              this.validation = true;
              this.clicked = false
            });
          }
          
        }
      }
    },

    getFormattedDate() {
      const months = [
        "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
      ];
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = months[today.getMonth()];
      const year = today.getFullYear();

      return `${day}${month}${year}`;
    },



    downloadFile(data, fileName) {
      this.loadingBlock = false
      this.showpopup = false
      const blob = new Blob([data], { type: 'application/octet-stream' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = fileName
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

    },
    addsingleRows(key, tbdata) {
      this.tableList[key].tableContents.forEach((eachdata, dataindex) => {
        if (dataindex == 0) {
          if (tbdata === undefined) {
            this.checklistcolumns.push(this.tableList[key].tableContents[dataindex].tableData.length);
            this.tableList[key].tableContents[dataindex].tableData.push("")
          }
          else {
            this.tableList[key].tableContents[dataindex].tableData.push(tbdata);
          }
        }
        else {
          this.tableList[key].tableContents[dataindex].tableData.push("");
        }

      });
    },
    addRow(key, tbdata) {
      this.tableList[key].tableContents.forEach((eachdata, dataindex) => {
        if (dataindex == 0) {
          if (tbdata === undefined) {
            this.checklistcolumns.push(this.tableList[key].tableContents[dataindex].tableData.length);
            this.tableList[key].tableContents[dataindex].tableData.push("")
          }
          else {
            this.tableList[key].tableContents[dataindex].tableData.push(tbdata);
          }
        }
        else {
          this.tableList[key].tableContents[dataindex].tableData.push("");
        }

      });
      this.temptableList.forEach((table, index) => {
        if (table.tableHeader != 'Signature Table') {
          let tablearray = [];
          if (index === key) {
            for (let i = 0; i < table.tableContents.length; i++) {
              tablearray.push("")
            }
            if (this.tableRows.tableColumns[key] == null || this.tableRows.tableColumns[key] == "null") {
              this.tableRows.tableColumns[key] = [];
            }
            if (this.tableRows.tableColumns.length >= key + 1) {
              this.tableRows.tableColumns[key].push(tablearray);
            }
            else {
              this.tableRows.tableColumns[key] = [];
              this.tableRows.tableColumns[key].push(tablearray);
            }
          }
        }

      })
    },
    deleteRow(key, rowIndexToDelete) {
      if (confirm("Are you sure you want to delete this row?")) {
        this.tableList[key].tableContents.forEach((tabledatas, tabldataindex) => {
          this.tableList[key].tableContents[tabldataindex].tableData.splice(rowIndexToDelete, 1);
        })
        if (rowIndexToDelete == -1) {
          this.tableRows.tableColumns[key].splice(0, 1);
        }
        else {
          if (this.tableRows.tableColumns[key] && rowIndexToDelete >= 0 && rowIndexToDelete < this.tableRows.tableColumns[key].length) {
            this.tableRows.tableColumns[key].splice(rowIndexToDelete, 1);
          }
        }
      }

    },
    passwordVisible() {
      this.visibleOn = !this.visibleOn
      if (this.visibleOn == true) {
        this.inputType = "text"
      }
      else this.inputType = "password"
    },
    isNumber: function (evt,vtype) {
            
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      const getmaj = vtype;
      if(getmaj =='major'){
          if(this.hoursTaken.length==0 && charCode ==48){
              evt.preventDefault();
          }
      }
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 || charCode == 46) {
          evt.preventDefault();
      } else {
          return true;
      }

  },
  preventInput(event) {      
    const inputValue = String(this.hoursTaken);
    const maxLength = 3;
    if (inputValue.length >= maxLength && event.key.length === 1) {
      event.preventDefault();  
    }    
  },
  }
}