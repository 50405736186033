<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0 align-self-center">Project Summary</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i class="fa fa-times close cursor-pointer" @click="$router.push(`/projectList`)"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-12">
        <div class="tabs-container col-md-12">
          <ul class="nav nav-tabs" role="tablist">
            <li>
              <a class="nav-link active" @click="cleardocs('document')" id="document" data-toggle="tab" href="#tab-1">
                Document
              </a>
            </li>
            <li>
              <a class="nav-link" @click="cleardocs('profile')" data-toggle="tab" id="profile" href="#tab-2">Profile</a>
            </li>
            <li>
              <a class="nav-link" @click="cleardocs('development')" data-toggle="tab" id="development"
                href="#tab-3">Development</a>
            </li>
          </ul>
          <div class="tab-content">
            <div role="tabpanel" id="tab-1" class="tab-pane active">
              <div class="ibox-content">
                <div class="form-group row my-1">
                  <div class="col-lg-12 my-4">
                    <div class="style_2 d-flex px-3">
                      <!-- PROJECT FOLDER -->
                      <div class="col-12 col-md-6 px-md-1">
                        <div class="ibox mb-2">
                          <div class="ibox-title style_2 d-flex justify-content-between px-3">
                            <h5 class="align-self-center">Folder</h5>
                            <div class="position-relative align-self-center" v-if="designationPrivileges.includes(
                              `Projects Create Folder`
                            ) &&
                              (isusercanaddfolder === null ||
                                isusercanaddfolder === true)
                              ">
                              <img class="cursor-pointer" src="../../assets/img/create.png" alt="create department"
                                width="20" @click="openModelCreateFolder()" />
                              <div class="tool-tip">Create Folder</div>
                            </div>
                          </div>
                          <div class="ibox-content scrollable-dept" id="primaryTree">
                            <!-- Folder contents -->
                            <ul class="tree my-1 pl-0">
                              <li>
                                <input type="checkbox" :id="selectedfolderid"
                                  @change="backtorootfolder(selectedfolderid)" />
                                <label :class="mainfolderbold == ''
                                  ? 'tree_labels tree_label_bold'
                                  : 'tree_labels'
                                  " @click="backtorootfolder(selectedfolderid)">{{ projectName }}
                                </label>
                                <TreeFolder :projectId="id" :list="folderList" :boldfolder="mainfolderbold"
                                  :openfolderlist="openfolderlist"  patid=""
                                  :SourcePage="'projectlist'" @reloadFolders="getRootFolders()" @changePerm="
                                    getFolderversion(
                                      $store.getters.getselectedFolder
                                    )
                                    ">
                                </TreeFolder>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <!-- END OF PROJECT FOLDER -->
                      <!-- document starts -->
                      <div class="col-12 col-md-6 px-md-0">
                        <div class="ibody" v-if="$store.getters.getselectedFolder">
                          <div class="ibox-title style_2 d-flex justify-content-between px-3">
                            <h5 class="py-0 align-self-center">Documents</h5>
                            <div class="position-relative align-self-center" v-if="designationPrivileges.includes(
                              `Projects Add Document`
                            ) && isuserenabled == true
                              ">
                              <img class="cursor-pointer" src="../../assets/img/create.png" alt="Add a file" width="20"
                                height="20" @click.prevent="cls()" />
                              <span class="tool-tip">Add Document</span>
                            </div>
                          </div>
                          <div class="ibox-mod-content scrollable-block" id="primaryDocumentTree">
                            <table v-if="documentlist" class="table table-bordered">
                              <thead>
                                <tr>
                                  <th> Document File Name</th>
                                  <th> Document Title</th>
                                  <th> Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(documents, index) in documentlist" :key="documents.id">
                                  <td class="align-items-center">
                                    <div v-if="documents.name.length < 41"> 
                                       <a  @click.prevent="viewDoc(documents.id, documents.latestVersion)"> {{ documents.name }}</a></div>
                                    <div v-else>
                                      <div v-if="!readMore[index]" class="breakWord">
                                        <a @click.prevent="viewDoc(documents.id, documents.latestVersion)"> {{
                                          documents.name.substring(0, 40) }}</a>
                                        <span @click="showMore(index)" v-if="!readMore[index]" class="more">...More</span>
                                      </div>
                                      <div v-if="readMore[index]" class="breakWord">
                                        <a @click.prevent="viewDoc(documents.id, documents.latestVersion)"> {{
                                          documents.name }} </a>
                                        <span @click="showLess(index)" v-if="readMore[index]" class="less">...Less</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="align-items-center">
                                    <div v-if="documents.documentTitle.length < 41"> <a  @click.prevent="viewDoc(documents.id, documents.latestVersion)"> {{ documents.documentTitle }}</a> </div>
                                    <div v-else>
                                      <div v-if="!readTitleMore[index]" class="breakWord">
                                        <span @click.prevent=" viewDoc(documents.id, documents.latestVersion)"> {{
                                          documents.documentTitle.substring(0, 40)}}</span>
                                        <span @click="showTitleMore(index)" v-if="!readTitleMore[index]"
                                          class="more">...More</span>
                                      </div>
                                      <div v-if="readTitleMore[index]" class="breakWord">
                                        <span @click.prevent=" viewDoc(documents.id, documents.latestVersion)"> {{
                                          documents.documentTitle }} </span>
                                        <span @click="showTitleLess(index)" v-if="readTitleMore[index]"
                                          class="less">...Less</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="text-center" width="40">
                                    <div class="more-option-icon cursor-pointer px-2" v-on:clickout="showDocBlock = false"
                                      @click.prevent="
                                        sidedocPopup(
                                          index,
                                          documents.id,
                                          documents.name,
                                          'file',
                                          event
                                        )
                                        ">
                                      <i class="fa fa-ellipsis-v"> </i>
                                    </div>
                                  </td>
                                  <SidePopUp v-if="showDocBlock && popupid == documents.id
                                    " :index="arrayIndex" tab="projectDoc" :blockid="popupid" :ev="event" :dest="'docs'"
                                    v-on:clickout="showDocBlock = false">
                                    <div v-if="this.anyuserpermissions" style="pointer-events: none">
                                      Nothing to show
                                    </div>
                                    <div v-if="sendtoValue">Clone</div>
                                    <div v-if="editValue" @click="
                                      DocEditPopup(
                                        documents.id,
                                        documents.latestVersion
                                      )
                                      ">
                                      Edit
                                    </div>
                                    <div v-if="permissionsValue" @click="FilePermissionPopup(documents.id)">
                                      Permissions
                                    </div>
                                    <div v-if="shareValue" @click="ShareDocument(documents.id)">
                                      Share
                                    </div>
                                    <div v-if="deleteValue" @click="DeleteDocument(documents.id)">
                                      Delete
                                    </div>
                                    <div v-if="archiveValue" @click="ArchievedDocument(documents.id)">
                                      Archive
                                    </div>
                                  </SidePopUp>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <!-- document ends -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" id="tab-2" class="tab-pane">
              <div class="ibox-content">
                <div class="form-group row my-1">
                  <div class="col-lg-12 my-4">
                    <div class="style_2 d-flex px-2">
                      <div class="col-12 col-md-6 mr-0 pr-md-1 mb-1">
                        <div class="ibox-title style_2 d-flex justify-content-between px-3">
                          <h5 class="align-self-center">Project Roles</h5>
                          <div v-if="designationPrivileges.includes(
                            `Projects Add Roles`
                          )
                            " class="cursor-pointer position-relative align-self-center" @click="openModelrole()">
                            <img src="../../assets/img/create.png" alt="Create project role" width="20" height="20" />
                            <span class="tool-tip">Add Project Role</span>
                          </div>
                        </div>
                        <div class="ibox-content-project scrollable-block">
                          <div class="project-list">
                            <table class="table table-hover">
                              <tbody>
                                <tr v-for="role in roleList" :key="role.projectid">
                                  <td>
                                    {{ role.name }}
                                  </td>
                                  <td class="text-center">
                                    <span class="cursor-pointer"><i class="fa fa-ellipsis-v" @click="sidePopup(role.id, e,'roles')"></i></span>
                                  </td>
                                  <SidePopUp v-if="showBlock && popupid == role.id" :dest="destination" :blockid="popupid" :ev="event"
                                    v-on:clickout="showBlock = false">
                                    <div v-if="designationPrivileges.includes(
                                      `Projects Edit Role`
                                    )
                                      " @click="editModelRole(role.id, role.name)">
                                      Edit
                                    </div>
                                    <div v-if="designationPrivileges.includes(
                                      `Projects Delete Role`
                                    )
                                      " @click="deleteRole(role.id)">
                                      Delete
                                    </div>
                                  </SidePopUp>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <!-- ProjectUserRole -->
                      <div class="col-12 col-md-6 px-md-1">
                        <userconfig :projid="id" :desigUserPrivileges="desigUserPrivileges">
                        </userconfig>
                      </div>
                      <!-- ProjectUserRole -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" id="tab-3" class="tab-pane">
              <div class="ibox-content">
                <div class="form-group row">
                  <div class="col-lg-12 px-4">
                    <documentlist :projectId="id" :projectsName="projectName"></documentlist>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- pop up -->
      <!-- POPUP -->

      <!-- CREATE PROJECT FOLDER POPUP -->
      <div class="create-folder-modal" v-if="createfolderpopup == true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header-projects d-flex justify-content-bertween p-3">
              <h5 class="my-0 align-self-center">Create Folder</h5>
              <div class="cursor-pointer" @click="closeModelCreateFolder()">
                <i class="fa fa-times"></i>
              </div>
            </div>
            <div class="modal-body">
              <label class="mb-0" for="designation">Folder Name <sup><i class="fa fa-asterisk required"></i></sup></label>
              <input placeholder="Enter folder name" type="text" class="form-control" v-model="createFolder.folderName"
                maxlength="75" />
              <span class="errorClass" v-if="folderErrors[0] == true">{{ folderErrorMessages[0] }}
              </span>
              <div class="my-2">
                <label class="mb-0" for="format">Select Format </label>
                <Multiselect id="format" valueProp="label" v-model="SelectedFormat" mode="multiple"
                  placeholder="Select Format" :options="FormatList" :hideSelected="false" :closeOnSelect="false"
                  class="multiselect-custom-theme">
                  <template v-slot:option="{ option }">
                    {{ option.label }}
                  </template>
                </Multiselect>
              </div>

              <label class="col-form-label">Document version configuration</label>
              <div class="col-md-12 row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label><small>Prefix</small></label>
                    <input maxlength="1" id="password" name="password" type="text" class="form-control required"
                      v-model="prefix" aria-required="true" v-on:keypress="isLetter($event)" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label><small>* Major ver.</small></label>
                    <input maxlength="2" id="password" name="password" type="text" class="form-control required"
                      v-model="majorVerStart" aria-required="true" @keypress="isNumber($event)" autocomplete="off" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label><small>Minor ver.</small></label>
                    <input maxlength="2" id="password" name="password" type="text" class="form-control required"
                      v-model="minorVerStart" aria-required="true" @keypress="isNumber($event)" autocomplete="off" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label><small>Minor Max.</small></label>
                    <input maxlength="2" id="password" name="password" type="text" class="form-control required"
                      v-model="minorVermax" @keypress="isNumber($event)" autocomplete="off" aria-required="true" />
                  </div>
                </div>

                <span class="errorClass" v-if="minverError == true">Minor Max value must be greater than or equal to Minor
                  version value
                </span>
                <!-- <div class="col-md-1">
                  <div class="mt-4" @click="saveVersion()">
                    <a href="#"><img src="../../assets/img/save_ver.png" /></a>
                  </div>
                </div> -->
              </div>

              <div class="form-group pt-2">
                <button type="button" @click.prevent="createfolder()" class="btn btn-primary btn-xs px-4 float-right">
                  Save
                </button>
                <strong> Document version :</strong>
                {{ prefix }} {{ majorVerStart
                }}<span v-if="majorVerStart && minorVerStart != ''">.</span>{{ minorVerStart }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <fileFormatPopUp v-if="$store.getters.getformatvalue" :filetype="$store.getters.getfileType"
        :blocknames="$store.getters.getfileName" @closemodal="$store.dispatch('setformatvalue', false)">
      </fileFormatPopUp>
      <foldersendpopup v-if="$store.getters.getsendfolder" :filetype="$store.getters.getfileType"
        :blockname="$store.getters.getfileName" @closemodal="$store.dispatch('setsendfile', false)">
      </foldersendpopup>
      <filesendpopup v-if="$store.getters.getsendfile" :filetype="$store.getters.getfileType"
        :blockname="$store.getters.getfileName" :folderid="$store.getters.getselectedFolder"
        :department="$store.getters.getDepartment" :docid="selecteddocid"
        @closemodal="$store.dispatch('setsendfile', false)">
      </filesendpopup>
      <folderEditPopUp v-if="$store.getters.geteditvalue" :filetype="$store.getters.getfileType"
        :blocknamess="$store.getters.getfileName" @getFolder="getRootFolders(this.folderList)"
        @closemodal="$store.dispatch('seteditvalue', false)">
      </folderEditPopUp>
      <!-- END OF CREATE FOLDER POPUP -->
      <div class="create-user-modal" v-if="showPopup == true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header-projects d-flex justify-content-bertween p-3">
              <h5 v-if="pop" class="my-0 align-self-center">Add Role</h5>
              <h5 v-if="!pop" class="my-0 align-self-center">Edit Role</h5>
              <span type="button" class="lign-self-center" data-dismiss="modal" @click.prevent="closemodal()"
                aria-hidden="true"><i class="fa fa-times modal-close align-self-center"></i>
              </span>
            </div>
            <div class="modal-body">
              <label class="input-group mb-0">Role</label>
              <input type="text" class="form-control" maxlength="30" v-model="values.name" />
              <span class="errorClass" v-if="errors[0] == false">Please enter role name
              </span>
              <div v-if="count == 1" class="ml-5 mt-2">
                <strong>Want to import existing roles from the same project
                  type?</strong>
                <a href="#" @click="openRoleList()"> Yes </a>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <button v-if="pop" type="button" class="btn btn-primary btn-xs px-4" @click="createRole()">
                  Save
                </button>
                <button v-if="!pop" type="button" class="btn btn-primary btn-xs px-4" @click="edit()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="create-user-modal" v-if="showSuggestion">
        <div class="width-50">
          <div class="modal-content">
            <div class="modal-header1 d-flex justify-content-between p-3">
              <h5 class="my-0 align-self-center">All Role</h5>
              <span class="cursor-pointer" role="button" @click.prevent="closeRoleList()">
                <i class="fa fa-times modal-close align-self-center"></i>
              </span>
            </div>
            <div class="modal-body">
              <div class="scrollable-roleslist mb-2">
                <table class="table table-hover role">
                  <tbody>
                    <tr v-for="(Roles, index) in rolesList" :key="index" class="cursor-pointer">
                      <td>
                        {{ Roles }}
                        <span class="multiselect-tag-remove-icon" @click="deleteFromList(Roles, index)"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="fordiv">
                <div class="input-group">
                  <input type="text" placeholder="Enter role name" class="form-control" v-model="valuestemp.name"
                    maxlength="30" />
                  <span class="input-group-append">
                    <button type="button" class="btn btn-primary" @click="SaveRolesTemp()">
                      <i class="fa fa-check"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="text-success d-flex align-self-center">
                <span class="mr-2">Want to add these roles to template</span>
                <span class="i-checks">
                  <input type="checkbox" value="true" v-model="values.addToTemplate" />
                </span>
              </div>
              <button type="button" class="btn btn-primary" @click="AddListingRoles(rolesList)">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- END OF POPUP -->
    </div>
  </div>
  <!-- <PermissionPopUp
    v-if="$store.getters.getpermvalue"
    :filetype="$store.getters.getfileType"
    :blockname="$store.getters.getfileName"
    @closemodal="$store.dispatch('setpermvalue', false)"
  >
  </PermissionPopUp> -->
  <!-- <ProjectPermission
    v-if="showProjectBlock && docId"
    :documentId="docId"
    :versionbyfolder="folderversion"
    :blockname="projectName"
    @reloadDocs="getdocuments(docId)"
    @closemodal="showProjectBlock = false"
  >
  </ProjectPermission> -->
  <DocumentPopup v-if="showProjectBlock && docId" :department="selectedfoldername" :folderid="currentselectedfolder"
    :versionbyfolder="folderversion" :fromPage="'project'" @reloadDocs="getdocuments(docId)"
    @closemodal="showProjectBlock = false" @uploadlibactive="closedocmodal()">
  </DocumentPopup>

  <!-- <doc_Approval
    v-if="showDocumentApproval"
    :documentId="documentId"
    :version="version"
    :projectId="projectid"
    :dropdownName="dropdownname"
    @closeApproveModal="showDocumentApproval = false"
  >
  </doc_Approval> -->

  <FileFolderProjectPopup v-if="showProjectPermPopup" @closemodal="showProjectPermPopup = false" :list="popupid"
    :source="sourceType" :projectId="id">
  </FileFolderProjectPopup>

  <fileSharePopup v-if="showProjectSharePopup" @closemodal="showProjectSharePopup = false" :list="popupid"
    :source="sourceType" :projectId="id">
  </fileSharePopup>
  <!-- Doc Edit Popup -->
  <DocEditProjectPopup v-if="editDocPopup" @editdocactive="uploadeditactivity()" :versionid="versionid" :folderid="docId"
    :docId="popupid" @reloadDocs="$forceUpdate(getdocuments(docId))">
  </DocEditProjectPopup>
  <!-- ends -->
  <NewDocVersion v-if="newdocversionPopup" @editdocactive="newversionuploadactivity()" :department="projectName"
    :folderid="$store.getters.getselectedFolder" :docId="popupid">
  </NewDocVersion>
  <foldershare_popup v-if="showSharePopup" @closemodal="showSharePopup = false">
  </foldershare_popup>
</template>
<script src="./project_summary.js"></script>
<style scoped>
.modal-header1 {
  border-bottom: 1px solid #dee2e6;
}

.fa-asterisk {
  color: #e56363 !important;
  font-size: 6px;
}

.img {
  vertical-align: middle;
  border-style: none;
}

.modal-header-projects {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.errorClass {
  color: red;
  font-size: 13px;
}

.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.scrollable-dept {
  overflow-y: auto;
  height: 450px;
}

.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}

.title-class1 {
  background-color: #618bd7;
  color: #fff;
}

.ibox-content {
  overflow-x: auto !important;
}

.max-height {
  max-height: 500px;
  overflow-y: auto;
}

/* .d-block{
  color:black;
} */
.question {
  margin-top: 18px;
  margin-left: 33px;
  height: 1px;
}

.tree_label_bold {
  font-weight: bold;
}

.ibox-content-project {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 8px 20px 8px;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid;
  border-width: 1px;
}

.ibox-title-org {
  background-color: #d9e4ff;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px;
  color: inherit;
  padding: 8px 15px 8px 15px;
  min-height: 48px;
  position: relative;
  clear: both;
  width: unset;
  border-radius: 5px 5px 0 0;
}

.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.create-user-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}

.modal-body td {
  font-size: 12px !important;
}

.table.role tbody tr td {
  vertical-align: middle;
  height: 30px;
}

.scrollable-block {
  overflow-y: auto;
  height: 450px;
}

.fordiv {
  margin-top: 2px;
}

.project-list table tr td {
  border-top: none;
  border-bottom: 1px solid #e7eaec;
  vertical-align: middle;
}

.more-option-icon:hover {
  background-color: #ededed;
}

.modal-close {
  font-size: 15px;
}

.modal-width {
  width: min(400px, 90%);
}

.scrollable-roleslist {
  height: 200px;
  overflow-y: scroll;
}

.width-50 {
  width: min(500px, 90%);
}

.more,
.less {
  font-size: 11px;
  color: #618bd7;
}
.ibox-mod-content{
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid;
  border-width: 1px;
}
</style>
