import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import ApproveRejectPopUp from "../ApproveRejectPopUp/ApproveRejectPopUp.vue"
import ApprovalStatusPopUp from "../ApprovalStatusPopUp/ApprovalStatusPopUp.vue"
export default {
  components: {
    ApproveRejectPopUp,
    ApprovalStatusPopUp
  },
  data() {
    return {
      fileName: "",
      showstatuspop: false,
      docId: "",
      versionNo: "",
      showpop: false,
      baseurl: `${process.env.VUE_APP_Service_URL}account-core/user/`,
      designationUrl: `${process.env.VUE_APP_Service_URL}configuration/designation/`,
      departmentUrl: `${process.env.VUE_APP_Service_URL}configuration/department/`,
      documentUrl: `${process.env.VUE_APP_Service_URL}`,
      idtoken: "",
      showDesig: false,
      userList: "",
      approvalList: [],
      retrievebox: true,
      designationId: "",
      currentPage: 1,
      pageindex: 1,
      pagesize: 10,
      totalPage: 1,
      SortProperty: "assignedDateTime",
      SortOrder: false,
      SortOrderInt: 1,
      errors: [],
      validations: [],
      disignations: {},
      update: {
        designationId: "",
        name: "",
        description: "",
        active: true,
      },
      values: {
        name: "",
        description: "",
        active: true,
      },
      searchField: {
        documentTitle: "",
        createdAt: "",
        status: ""
      },
      designationPrivileges :"",
    };
  },
  async mounted() {
    this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
    window.addEventListener("ApprovalStatusChange", (event) => {
      console.log("ApprovalStatusChange == " + event.detail.storage);
      if (event.detail.storage == true) {
        this.RefreshedDocApprovalList();
        this.showpop = false;
      }
    });
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    this.idtoken = store.getters.getToken
    console.log(this.idtoken);
    this.DocApprovalList();
  },
  methods: {
    async ApproveReject(docId, versionNo) {
      console.log("Inside approve reject popup")
      this.showpop = true,
        this.docId = docId,
        this.versionNo = versionNo
    },
    async ApproveStatus(documentTitle, docId, versionNo) {
      console.log("Inside ApproveStatus popup")
      this.showstatuspop = true,
        this.fileName = documentTitle,
        this.docId = docId,
        this.versionNo = versionNo
    },
    async OnSearchButtonClicked() {
      console.log("Search button clicked");
      this.pageindex = 1;
      this.currentPage = 1;
      this.DocApprovalList()
    },
    async DocApprovalList() {
      console.log("DAS", this.searchField);
      console.log("page number is ", this.pageindex);
      await axios
        .get(
          `${this.documentUrl}document/document/getapprovallist?PageIndex=${this.pageindex}&PageSize=${this.pagesize}&title=${this.searchField.documentTitle}&uploadeddate=${this.searchField.createdAt}&status=${this.searchField.status}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrderInt}`,
          //`https://localhost:5001/document/getapprovallist?PageIndex=${this.pageindex}&PageSize=${this.pagesize}&title=${this.searchField.documentTitle}&uploadeddate=${this.searchField.createdAt}&status=${this.searchField.status}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrderInt}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.approvalList = res.data.entities;
          console.log("list approval", this.approvalList);
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }

        })
        .catch((err) => {

          console.log(err);
          this.userList = []
          alert("No data found!")

        })

    },
    async approvalScreen(id,version,versionId) {
      await store.dispatch("setDocViewSource","Approval")
      await store.dispatch("setFromPage","Approval")
      this.$router.push(`/documentview/` + id + "/"+ version);
    },
    async RefreshedDocApprovalList() {
      console.log("DAS", this.searchField);
      console.log("page number is ", this.pageindex);
      await axios
        .get(
          `${this.documentUrl}document/document/getapprovallist?PageIndex=${this.pageindex}&PageSize=${this.pagesize}&title=${this.searchField.documentTitle}&uploadeddate=${this.searchField.createdAt}&status=${this.searchField.status}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrderInt}`,
          //`https://localhost:5001/document/getapprovallist?PageIndex=${this.pageindex}&PageSize=${this.pagesize}&title=${this.searchField.documentTitle}&uploadeddate=${this.searchField.createdAt}&status=${this.searchField.status}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrderInt}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.approvalList = res.data.entities;
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.userList = []
          alert("No data found!")
        })
    },
    SortSelected(sortProp, id) {
      console.log('SortPropChanged');
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == true ? false : true;
      this.SortOrderInt = this.SortOrder == true ? 0 : 1;
      this.DocApprovalList();
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.pageindex = page;
      this.DocApprovalList();
    },


  },
};