/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import DigitalSignPopUp from "../DigitalSignPopUp/DigitalSignPopUp.vue";
import moment from "moment";
import { toHandlers } from "vue";
export default {
  name: "controlRecords",
  props: {
    id: { type: String, default: "" },
  },
  components: {
    Multiselect,
    DigitalSignPopUp
  },
  props:{
    id:{
      type:String,
      default:""
     },
     docId:{
      type:String,
      default:""
     }
  },
  data(){
    return{
        changeNumber:"",
        riskLevel:"",
        system:"",
        changeReason:"",
        changeDescription:"",
        systemsAffected:"",
        testingRequired:"",
        rollbackPlan:"",
        reviewer:"",
        reviewerJobTitle:"",
        completedJobTitle:"",
        completedBy:"",
        requestedBy:"",
        requestedEmail:"",
        reviewedEmail:"",
        completedEmail:"",
        requestedJobTitle:"",
        baseurl: process.env.VUE_APP_Service_URL,
        showValidations: false,
        loadingBlock:false,
        showSignBlock:false,
        returnApi:false,
        signed:false,
        showSign:[false,false,false],
        signedDoc:[false,false,false],
        finalSignCompleted:false,
        approval:"",
        idtoken: "",
        notes:"",
        reasonRefusded:"",
        dateCompleted:"",
        dateRefused:"",
        othersystem:"",
        dateReviewed:"",
        actionDate:"",
        requestedDate:"",
        fileName:"",
        reasonRefused:"",
        showPopup:false,
        getValueNumber:0,        
        userList:[],
        profileDetails:{},
        email:"",
        controlData:{},
        postData:{},
        presentBlock:"createBlock"
    }
  },
  computed: {
    minDate() {
      // Calculate the minimum allowed date (today's date)
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
  async mounted() {
    this.idtoken = store.getters.getToken;
    if(store.getters.getDocViewSource=="changeControl"){
      await this.getProfileData()
      await this.getData()
    }
    this.ListUsers()
  },
  methods:{
    closebutton() {
      if(this.presentBlock==='createBlock'){
        if (confirm("Are you sure you want to close this page?")) {
          this.$router.go(-1)
          let selectfolder = store.getters.getselectedFolder;
          store.dispatch("setopenfolder", selectfolder);
        }
      }
      else{
        if (confirm("Are you sure you want to close this page?")) {
          this.$router.push("/dashboard")
        }
      }
     
    },
    filter(data) {
      if (data) {
        return moment(data).format("DD-MM-YYYY HH:mm:ss");
      }
    },
    isNumber: function (evt,vtype) {
            
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      const getmaj = vtype;
      if(getmaj =='major'){
          if(this.changeNumber.length==0 && charCode ==48){
              evt.preventDefault();
          }
      }
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 || charCode == 46) {
          evt.preventDefault();
      } else {
          return true;
      }

  },
  handleInput(e) {    
    e.target.value=e.target.value.replace(/[^a-zA-Z0-9\s.]/g, "");
  },
  preventInput(event) {      
    const inputValue = String(this.changeNumber);
    const maxLength = 10;
    if (inputValue.length >= maxLength && event.key.length === 1) {
      event.preventDefault();  
    }    
  },
  formatDate(isoDate) {
    if (isoDate !== null) {
        if (isoDate) {
            const inputDate = new Date(isoDate);
            const day = inputDate.getUTCDate();
            const month = inputDate.getUTCMonth() + 1; // Months are 0-indexed, so add 1
            const year = inputDate.getUTCFullYear();
            
            const formattedDate = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
            
            return formattedDate;
          } else {
            return null;
          }
  }},
  async finalSign(){
    await this.getData();
    this.finalSignCompleted=true
  },
  async getProfileData() {
    await axios
      .get(`${this.baseurl}account-core/user/getprofile`, {
        headers: {
          Authorization: "Bearer " + this.idtoken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.profileDetails = res.data;
        this.email=res.data.primaryEmail
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async getData(){
    await axios
    .get(
      `${this.baseurl}document/projectdevdocumenttemplate/getchangecontrol?id=${this.docId}`,
      {
        headers: {
          Authorization: "Bearer " + this.idtoken,
          "Content-Type": "application/json",
        },
      }
      ).
      then((res) => {
        this.controlData = res.data
        console.log("Data is",this.controlData)
        this.fileName=res.data.fileName
        this.changeNumber=res.data.criticalChangeDetailsTable.number
        this.riskLevel=res.data.criticalChangeDetailsTable.level
        this.systemsAffected=res.data.criticalChangeDetailsTable.systemAffected
        this.changeReason=res.data.criticalChangeDetailsTable.reason
        this.changeDescription=res.data.criticalChangeDetailsTable.description
        this.approval=res.data.criticalChangeDetailsTable.approved
        this.othersystem=res.data.criticalChangeDetailsTable.affected
        this.testingRequired=res.data.criticalChangeDetailsTable.testingRequired
        this.rollbackPlan=res.data.criticalChangeDetailsTable.rollBack
        this.requestedBy=res.data.criticalChangeDetailsTable.requestedBy
        this.requestedJobTitle=res.data.criticalChangeDetailsTable.jobTitleRequested
        this.reviewer=res.data.criticalChangeDetailsTable.reviewedBy
        this.reviewerJobTitle=res.data.criticalChangeDetailsTable.jobTitleReviewed       
        this.completedBy=res.data.criticalChangeDetailsTable.completedBy
        this.completedJobTitle=res.data.criticalChangeDetailsTable.jobTitleCompleted
        this.requestedDate=res.data.criticalChangeDetailsTable.dateRequested
        this.dateReviewed=res.data.criticalChangeDetailsTable.dateReviewed
        this.dateCompleted=res.data.criticalChangeDetailsTable.completedDate
        this.notes=res.data.criticalChangeDetailsTable.notes
        this.reasonRefused=res.data.criticalChangeDetailsTable.reasonRefused
        this.actionDate=res.data.criticalChangeDetailsTable.actionDate
        this.requestedEmail=res.data.signers[0].userEmail
        this.reviewedEmail=res.data.signers[1].userEmail
        this.completedEmail=res.data.signers[2].userEmail     
        res.data.signers.forEach((item,index)=>{         
          if(this.email==item.userEmail){
            this.presentBlock=item.bookmark
            this.getValueNumber=index  
            this.showSign[this.getValueNumber]=item.isSigned          
          }
        })
        if(res.data.signers[2].isSigned==true)
        {
          this.finalSignCompleted=true
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async ListUsers() {
        await axios
          .get(
            `${this.baseurl}account-core/user/getlist`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.userList = res.data.entities;
            this.userList.sort((a, b) => a.fullName.localeCompare(b.fullName));
          })
          .catch((err) => {
            console.log(err);
          });
      },
      async selectEmail(name,type){
        this.userList.forEach((user)=>{
          
          if(name===user.fullName){
            if(type==='requested')
            {
              this.requestedEmail=user.primaryEmail;
              this.requestedJobTitle=user.designation
            }
            if(type==='reviewed')
            {
              this.reviewedEmail=user.primaryEmail;
              this.reviewerJobTitle=user.designation
            }
            if(type==='completed')
            {
              this.completedEmail=user.primaryEmail;
              this.completedJobTitle=user.designation
            }
          }

          
        })
      },
    async checkValidations(){
        this.showValidations=true
        if(this.presentBlock==="createBlock"){
        if(this.changeNumber != '' && this.riskLevel!='' && this.systemsAffected!='' && this.changeReason!='' && this.changeDescription!='' && this.requestedBy!='' && this.reviewer!='' && this.fileName!='' && this.completedBy!='' && this.requestedBy != this.reviewer && this.requestedBy != this.completedBy && this.reviewer!=this.completedBy )
        {
          this.changeNumber=this.changeNumber.toString()
          this.showValidations=false
          this.loadingBlock=false                  
          this.createForm()
          }
        }
          else
          {
            this.loadingBlock=false
            if(this.presentBlock==="RequestedBySign")
            {
              if(this.requestedDate!='' && this.requestedDate!=null)
              {
                this.showValidations=false
                this.showSignBlock=true
              }
            }
            else if(this.presentBlock==="ReviewedBySign"){
             
              if((this.dateReviewed!='' && this.dateReviewed!=null) && (this.approval!='' && this.approval!=null) && (this.actionDate!='' && this.actionDate!=null)){
               
                  this.showValidations=false
                  this.showSignBlock=true                
                
              }
            }
            else if(this.presentBlock==="CompletedBySign"){
              if(this.dateCompleted!=''&& this.dateCompleted!=null){
                this.showValidations=false
                this.showSignBlock=true
              }
            }
           
          }
          if(this.showSignBlock==true)
          this.postData={
            "formId": this.docId,
            "documentType": this.controlData.documentType,
            "projectName": this.controlData.projectName,
            "fileName":this.fileName,
            "folderId": this.id,
            "criticalChangeDetailsTable": {
              "number": this.changeNumber,
              "level": this.riskLevel,
              "systemAffected": this.systemsAffected,
              "reason": this.changeReason,
              "description": this.changeDescription,
              "affected": this.othersystem,
              "testingRequired": this.testingRequired,
              "rollBack": this.rollbackPlan,
              "requestedBy": this.requestedBy,
              "jobTitleRequested":this.requestedJobTitle,
              "reviewedBy":  this.reviewer,
              "jobTitleReviewed": this.reviewerJobTitle,
              "approved": this.approval,
              "reasonRefused": this.reasonRefused,
              "completedBy": this.completedBy,
              "jobTitleCompleted": this.completedJobTitle,
              "notes": this.notes,
              "dateRequested": this.requestedDate,
              "dateReviewed": this.dateReviewed!='' ? this.dateReviewed:null,
              "actionDate": this.actionDate!='' ? this.actionDate:null,
              "completedDate": this.dateCompleted!='' ? this.dateCompleted:null
            },
             "Signers" : [ 
                  {
                      "Bookmark" : "RequestedBySign",
                      "SignOrder" : "1",
                      "UserId" : this.controlData.signers[0].userId,
                      "UserEmail" : this.requestedEmail,
                      "Date" :this.controlData.signers[0].date,
                      "SignId" : this.controlData.signers[0].signId,
                      "Reason" : this.controlData.signers[0].reason,
                      "UserName" : this.controlData.signers[0].userName,
                      "IsSigned" : this.controlData.signers[0].isSigned,
                      "SignedDocId" : null
                  }, 
                  {
                      "Bookmark" : "ReviewedBySign",
                      "SignOrder" : "2",
                      "UserId" : this.controlData.signers[1].userId,
                      "UserEmail" : this.reviewedEmail,
                      "Date" :this.controlData.signers[1].date,
                      "SignId" : this.controlData.signers[1].signId,
                      "Reason" : this.controlData.signers[1].reason,
                      "UserName" : this.controlData.signers[1].userName,
                      "IsSigned" : this.controlData.signers[1].isSigned,
                      "SignedDocId" : null
                  }, 
                  {
                      "Bookmark" : "CompletedBySign",
                      "SignOrder" : "3",
                      "UserId" : this.controlData.signers[2].userId,
                      "UserEmail" : this.completedEmail,
                      "Date" :this.controlData.signers[2].date,
                      "SignId" : this.controlData.signers[2].signId,
                      "Reason" : this.controlData.signers[2].reason,
                      "UserName" : this.controlData.signers[2].userName,
                      "IsSigned" : this.controlData.signers[2].isSigned,
                      "SignedDocId" : null
                  }
              ],
            "documentId": null,
            "documentName": null
          }
    
        },
    async createForm(){
      this.loadingBlock=true    
      await axios
      .post(
        `${this.baseurl}document/projectdevdocumenttemplate/criticalchangecontrol`,
        {
          
            "documentType": "",
            "folderId": this.id,
            "projectName":localStorage.getItem("ProjectName"),
            "fileName": this.fileName+".pdf",
            "criticalChangeDetailsTable": {
              "number": this.changeNumber,
              "level": this.riskLevel,
              "systemAffected": this.systemsAffected,
              "reason": this.changeReason,
              "description": this.changeDescription,
              "affected": this.othersystem,
              "testingRequired": this.testingRequired,
              "rollBack": this.rollbackPlan,
              "requestedBy": this.requestedBy,
              "jobTitleRequested": this.requestedJobTitle,
              "reviewedBy": this.reviewer,
              "jobTitleReviewed": this.reviewerJobTitle,
              "reasonRefused": null,
              "completedBy": this.completedBy,
              "jobTitleCompleted": this.completedJobTitle,
              "notes": null,
              "dateRequested": null,
              "dateReviewed": null,
              "completedDate": null
            },
            "signers": [
              {
                "bookmark": "RequestedBySign",
                "signOrder": "1",
                "userId": "string",
                "userEmail": this.requestedEmail,
                "date": "2024-01-24T11:25:00.283Z",
                "signId": "string",
                "reason": "string",
                "userName": "string"
              },
              {
                "bookmark": "ReviewedBySign",
                "signOrder": "2",
                "userId": "string",
                "userEmail": this.reviewedEmail,
                "date": "2024-01-24T11:25:00.283Z",
                "signId": "string",
                "reason": "string",
                "userName": "string"
              },
              {
                "bookmark": "CompletedBySign",
                "signOrder": "3",
                "userId": "string",
                "userEmail": this.completedEmail,
                "date": "2024-01-24T11:25:00.283Z",
                "signId": "string",
                "reason": "string",
                "userName": "string"
              }
            ],
            "inputType": "string"
          
        },
        {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        alert("Critical change control form created successfully")
        this.loadingBlock=false
        this.$router.go(-1)
        let selectfolder = store.getters.getselectedFolder;
        store.dispatch("setopenfolder", selectfolder);
      })
      .catch((err) => {
        console.log(err);
       
      })
    
    }
       
    
  }
}