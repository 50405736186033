<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-10 col-md-10">
      <h2 class="my-0 py-0 align-self-center">SOP Acknowledgement Form</h2>
    </div>
    <div class="col-md-2 align-self-center">
      <i class="fa fa-times close cursor-pointer" @click="closebutton()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-8">
        <div class="ibox-title style_2 d-flex justify-content-between px-3">
          <h5 class="my-0 py-0 align-self-center">{{ documentTitle }}</h5>

        
        </div>
      
        <div class="ibox-content">
          <div class="action-block d-flex justify-content-end pr-3 mb-2">
            <div class="position-relative mr-2">
              <img
                class="cursor-pointer"
                width="20"
                height="20"
                src="../../assets/img/doc_download.png"
                alt="Edit document"
                @click="downloadFile()"
              />
              <span class="tool-tip">Download</span>
            </div>
           
          </div>

            <div class="content-block">
            <div v-if="isLoading" class="loading-spinner">
    <div class="spinner"></div>
  </div>
            <div v-if="format == 'pdf'" class="pdfembeded">
              <vue-pdf-embed :source="source" />
            </div>
            <div v-if="office.includes(format)" class="outsideframe">
              <div
                id="hiddata"
                v-bind:style="
                  officeurl == '' ? 'display:block;' : 'display:none;'
                "
              >
                <span style="font-weight: 500; padding-left: 30%"
                  >Blank document - Nothing to display
                </span>
              </div>
              <iframe
                id="printDiv"
                :src="officeurl != null ? officeurl : ''"
                width="100%"
                height="650px"
                frameborder="0"
              ></iframe>
            </div>
          </div>

        </div>
      </div>
    
    </div>
  </div>


</template>

<script>
import axios from "axios";
import VuePdfEmbed from "vue-pdf-embed";
import store from "../../store/index";
export default {
  props: {
    documentid: String,
  },
  components: {
    VuePdfEmbed,
    //adobe_iframe,
  },
  directives: {
    print,
  },
  data() {
    return {

      errmsg: "",
      errmsgshow: false,
     // showAdobe_iframe: false,
      isuserenabled: false, // add new version user permission
      signFormat: ["pdf", "PDF", "DOCX", "docx", "doc", "DOC"],
      isDocorPdf: false,
      signModal: false,
      imgFormat: ["PNG", "png", "jpeg", "jpg"],
      readMore: [false],
      setpreviouspath: "",
      versionfrom: "",
      prevRoute: null,
      loadVersion: "",
      sourcePage: "",
      newdocversionPopup: false,
      fileName: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      documentUrl: `${process.env.VUE_APP_Service_URL}`,
      office: ["xls", "xlsx", "doc", "DOC", "docx", "DOCX"],
      format: "",
      source: "",
      officeurl: "",
      NewFile: "",
      title: "",
      
      documentTitle: "",
      latestVersion: "",
     
     
      fromsource: "",
      
      updatedBy: "",
      uploadedBy: "",
      standard: "",
      owner: "",
      type: "",
      idtoken: "",
      readValue: "",
      downloadValue: "",
      viewValue: "",
      editValue: "",
      printValue: "",
      apiresult: "",
      docsId: "",
      shairedfolder: "",
      
      comments: "",
      showValue: "",
      approvalss: "",
      versionList: [],
      newVersion: "",
      verType: "",
   
   
      configItem: "",
      configid: "",
      xam: true,
    
      sourceRoute: "",
    };
  },


  async mounted() {
        
        this.idtoken = store.getters.getToken;
        this.NewFile = this.filename;
      
        await this.setdoc();
    
  },
  methods: {


    async setdoc() {
      this.source = 
      //`https://localhost:5001/projectdevdocumenttemplate/getsopfile?documentId=${this.documentid}`;
        `${this.baseUrl}document/projectdevdocumenttemplate/getsopfile?documentId=${this.documentid}`;
          
      await axios
        .get(
            //`https://localhost:5001/projectdevdocumenttemplate/getsopfile?documentId=${this.documentid}`,
            `${this.baseUrl}document/projectdevdocumenttemplate/getsopfile?documentId=${this.documentid}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data != "") {
            this.officeurl = `https://view.officeapps.live.com/op/embed.aspx?src=${this.source}`;
            console.log("this.officeurl", this.source);
            const contentDisposition = res.headers['content-disposition'];
            const matches = /filename="([^"]+)"/.exec(contentDisposition);
           
            console.log(matches)
             if (matches) {
            const dfilename = matches[1];
            this.documentTitle = matches[1];

        // Now, 'filename' contains the extracted filename
        console.log("Filename:", dfilename);

       this.format = contentDisposition.split(".").pop();
          console.log("fileformat", this.format);
             }
          }
        })
        .catch((err) => {
          console.log("error response is", err.response.data);
        });
    },

    async downloadFile() {
      this.downloadsource = `${this.baseUrl}document/projectdevdocumenttemplate/getsopfile?documentId=${this.documentid}`;
      //`https://localhost:5001/projectdevdocumenttemplate/getsopfile?documentId=${this.documentid}`;

      
      await axios
        .get(`${this.baseUrl}document/projectdevdocumenttemplate/getsopfile?documentId=${this.documentid}`,
           //`https://localhost:5001/projectdevdocumenttemplate/getsopfile?documentId=${this.documentid}`,

          
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data != "") {
            var a = document.createElement("a");
            a.href = this.downloadsource;
            a.download = "FILENAME";
            a.click();
          }
        })
        .catch((err) => {
          console.log("error response is", err.response.data);
        });
    },
  
    async closebutton() {
     
    this.$router.push("/soplibrary");
      
    },
 
  },
};
</script>
<style scoped>
.pdfembeded {
  height: 700px;
  overflow: auto;
}

.max-width {
  max-width: 100%;
}

.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.table-bottom > thead > tr > th,
.table-bottom > tbody > tr > th,
.table-bottom > tfoot > tr > th,
.table-bottom > thead > tr > td,
.table-bottom > tbody > tr > td,
.table-bottom > tfoot > tr > td {
  border-top: 0px solid #e7eaec;
  line-height: 1;
  padding: 6px;
  vertical-align: middle;
}

.table-bottom {
  width: 100%;
  margin-top: 0px !important;
  color: #212529;
  border-collapse: collapse;
}

.hiddata {
  display: none;
}

.more {
  font-size: 11px;
  color: #618bd7;
}

.less {
  font-size: 11px;
  color: #618bd7;
}

.breakWord {
  word-break: break-word;
}

.graphbtncontainer {
  display: flex;
  gap: 5px;
}

.graphbutton {
  width: fit-content;
  padding: 3px;
  cursor: pointer;
}
.add_page_modal_wrapper,
.modal__wrapper__component {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}
.add_page_modal__containers {
  width: min(400px, 90%);
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.add-block-title {
  color: #ffffff;
  background: #768dd3;
}
.errorClass {
  color: red;
  font-size: 13px;
}
</style>

