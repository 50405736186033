/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
export default {
  data() {
    return {
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/designation/`,
      idtoken: "",
      showDesig: false,
      designationList: "",
      designationName:"",
      designationDesc:"",
      retrievebox: true,
      designationId: "",
      update: {
        designationId: "",
        name: "",
        displayName:"",
        description: "",
        active: true,
      },
      values: {
        name: "",
        description: "",
        active: true,
      },
      search: {
        createdby: "",
        status: "",
        name: "",
      },
      currentPage: 1,
      pageindex: 1,
      pagesize: 10,
      totalPage: 1,
      SortProperty: "name",
      SortOrder: false,
      errors: [],
      validations: [],

      editableItem:[],
      designationPrivileges :"",

      
    };
  },

  async mounted() {
    this.designationPrivileges = store.getters.getRolesprivilegeData.split(",");
    this.idtoken = store.getters.getToken
    this.ListDesigation();
  },
  methods: {

    async OnSearchButtonClicked() {
      this.pageindex = 1;
      this.currentPage = 1;
      this.searchFilter();
    },
    updatename(value){      
      this.designationName=value;
      console.log("Name",this.designationName)
    },
    updatedesign(value){
      this.designationDesc=value;
      console.log("Desc",this.designationDesc)
    },
    async closebutton() {
      this.update={}
      this.retrievebox = true;
    },

    async deleteDesg(Id) {
      const designationId = Id;
      var confirmed = confirm("Are you sure you want to delete this designation?");
      if (confirmed == true) {
        await axios
          .delete(`${this.baseurl}delete?Id=${designationId}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            })
          .then((res) => {
            alert("Deleted successfully");
            console.log(res);
            this.ListDesigation();
          })
          .catch((err) => {
            console.log(err,"DESIGNATION ERR")
            alert(err.response.data.detail)
          });
      }
    },


    async save(Id) {
      // var confirmed = confirm("Are you sure you want to save?");
      var confirmed = confirm("Are you sure you want to update the details?");
      if (confirmed == true)
      {
        this.retrievebox = false;     
        this.update.designationId=Id;
        this.update.name=this.trim(this.update.name)
        this.update.description=this.trim(this.update.description)
        if (this.update.name == "" || this.update.name == null) {
          alert("Enter a valid designation name");
        }
        else {
          await axios
            .put(
              `${this.baseurl}update`,
              this.update,
              {
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              console.log("result", res)
              this.ListDesigation();
              alert("Updated successfully");
              this.closebutton();
            })
            .catch((err) => {
              alert(err.response.data.detail)
            });
        }
      }
    },
    async openEdit(Id) {
        this.retrievebox = false;
        this.designationId = Id;
        await axios
          .get(
            `${this.baseurl}search?id=${this.designationId}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.editableItem = res.data.entities[0];
            console.log("editableItem",this.editableItem)
            this.update.designationId = this.editableItem.Id;
            this.update.name = this.editableItem.displayName;            
            this.update.description = this.editableItem.description;
            this.update.active = this.editableItem.active;
            
        })
        .catch((err) => {
          console.log(err);
        })
    },
    trim(value) {
      value = value.replace(
          /^\s+|\s+$/gm,
          ""
      );
      return value
    },
    async setfunctions() {
      if (this.search.status != '' || this.search.name != '') {
        this.searchFilter()
      }
      else this.ListDesigation()
    },
    async ListDesigation() {
      await axios
        .get(
          `${this.baseurl}search?PageNumber=${this.pageindex}&PageSize=${this.pagesize}&SortProperty=${this.SortProperty}&IsDescending=${this.SortOrder}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.designationList = res.data.entities;
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.designationList =[]
          alert("No data found!")
        })
    },
    async searchFilter() {
      await axios
        .get(
          `${this.baseurl}search?PageNumber=${this.pageindex}&PageSize=${this.pagesize}&name=${this.search.name}&status=${this.search.status}&SortProperty=${this.SortProperty}&IsDescending=${this.SortOrder}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.designationList = []
          this.designationList = res.data.entities;
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
          console.log("designationList is", this.designationList);
          console.log(res.data.entities);
        })
        .catch((err) => {
          console.log(err)
          this.designationList =[]
          alert("No data found!")
        })
    },
    SortSelected(sortProp, id) {
      console.log('SortPropChanged');
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == true ? false : true;
      this.ListDesigation();
    },
    async createDesigfunc(value) {
      document.body.style.overflowY = "hidden"
      this.values.name="" 
      this.values.description=""
      if (value == "createDesig") {
        this.showDesig = !this.showDesig;
      }
    },
    async onclosed(value) {
      document.body.style.overflowY = "auto"
      if (value == "createDesig") {
        this.showDesig = false;
      }
    },
    async createDesig() {
      await this.validateIsNull();
      this.values.name=this.trim(this.values.name)
      this.values.description=this.trim(this.values.description)
      console.log("eeeeeeee")
      if (this.errors[0]) {
        console.log("eeeeeeee")
        await axios
          .post(`${this.baseurl}create`, this.values,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            })
          .then((res) => {
            console.log(res);
            alert("Designation created successfully");
            this.showDesig = false;
            document.body.style.overflowY = "auto"
            this.ListDesigation();
          })
          .catch((err) => {
            alert(err.response.data.detail);
          });
      }
    },
    async AddDesignationPrivilege(designationId,designationName){
      this.$router.push(`/designationScreenPrivileges/${designationId}/0`);
    },
    async validateIsNull() {

      if (this.values.name == "") {
        this.validations[0] = false;
        this.errors = this.validations;
      } else if (this.values.name.trim("") == "") {
        this.validations[0] = false;
        this.errors = this.validations;
      } else {
        console.log("vvvvvvvv2")
        this.validations[0] = true;
        this.errors = this.validations;
      }
    },
    async createFolderfunc(value) {
      document.body.style.overflowY = "hidden"
      if (value == "createDesig") {
        this.showDesig = !this.showDesig;
      } else this.uploadpopupmodal = true;
    },


    loadPage: function (page) {
      this.currentPage = page;
      this.pageindex = page;
      this.setfunctions();
    },
    goBack() {
      this.$router.push(`/administration`);
    }
  },
};