/* eslint-disable */
const state = () => ({
	openfolder:localStorage.getItem("openfolder"),
	docViewSource:localStorage.getItem("viewSource"),
	testsource:localStorage.getItem("testretest"),
	aprvlstatus:localStorage.getItem("aprvlstatus"),
	approvalListRefresh:localStorage.getItem("approvalRefresh"),
	selectedProject:localStorage.getItem("selectedProject"),
	fromPage:localStorage.getItem("fromPage"),
	selectedfoldermove:localStorage.getItem("selectedfoldermove"),
	selectedfolder:localStorage.getItem("selectedfolder"),
	setDepartment:localStorage.getItem("setDepartment"),
	setMainDepartment:localStorage.getItem("setMainDepartment"),
	setshaioredfolder:localStorage.getItem("setshaioredfolder"),
	setshaioreddept:localStorage.getItem("setshaioreddept"),
	IsDocumentRefresh:localStorage.getItem("documentRefresh"),
	//docaddpermission:localStorage.getItem("docaddpermission"),
	editfolderid:"",
	folderreload: true,
	departmentreload: true,
	docSign:false,
	selectedid:"",
	fileType:"",
	fileName:"",
	versionConfiguration:[
		{
			prefix:"",
			majorVerStart:"",
			minorVerStart:"",
			minorVermax:""
		}
	],
	formatvalue:false,
	editvalue:false,
	permblock:false,
	sendfileblock:false,
	foldersendblock:false,
	docaddpermission:false,
	documenttodepartment:false,
	showMoreBlock:true
});

const getters = {
	getDocViewSource(state: { docViewSource: any }) {
		return state.docViewSource;
	},
	getDocSign(state: { docSign: any }) {
		return state.docSign;
	},
	getRetest(state: { testsource: any }) {
		return state.testsource;
	},
	getTestApprovalstatus(state: { aprvlstatus: any }) {
		return state.aprvlstatus;
	},
	getopenfolder(state: { openfolder: any }) {
		return state.openfolder;
	},
	getApprovalListRefresh(state: { approvalRefresh: any }) {
		return state.approvalRefresh;
	},
	getSelectedProject(state: { selectedProject: any }) {
		return state.selectedProject;
	},
	getFromPage(state: { fromPage: any }) {
		return state.fromPage;
	},
	getdocumenttodepartment(state: { documenttodepartment: any }) {
		return state.documenttodepartment;
	},
	getDocumentRefresh(state: { IsDocumentRefresh: any }) {
		return state.IsDocumentRefresh;
	},
	getshaioreddept(state: { setshaioreddept: any }) {
		return state.setshaioreddept;
	},
	getshaioredfolder(state: { setshaioredfolder: any }) {
		return state.setshaioredfolder;
	},
	getDepartment(state: { setDepartment: any }) {
		return state.setDepartment;
	},
	getMainDepartment(state: { setMainDepartment: any }) {
		return state.setMainDepartment;
	},
	getselectedFolder(state: { selectedfolder: any }) {
		return state.selectedfolder;
	},
	getselectedFoldermove(state: { selectedfoldermove: any }) {
		return state.selectedfoldermove;
	},
	getfolderreload(state: { folderreload: any }) {
		return state.folderreload;
	},
	getdepartmentreload(state: { departmentreload: any }) {
		return state.departmentreload;
	},
	getselectedid(state: { selectedid: any }) {
		return state.selectedid;
	},
	getpermvalue(state: { permblock: boolean }) {
		return state.permblock;
	},
	getformatvalue(state: { formatvalue: boolean }) {
		return state.formatvalue;
	},
	geteditvalue(state:{editvalue:boolean}){
		return state.editvalue;
	},
	geteditfolderid(state:{editfolderid:boolean}){
		return state.editfolderid;
	},
	getsendfile(state: { sendfileblock: boolean }) {
		return state.sendfileblock;
	},
	getsendfolder(state: { foldersendblock: boolean }) {
		return state.foldersendblock;
	},
	getdocaddpermission(state: { docaddpermission: boolean }) {
		return state.docaddpermission;
	},
	getfileType(state: { fileType: any }) {
		return state.fileType;
	},
	getfileName(state: { fileName: any }) {
		return state.fileName;
	},
	getfolderVersion(state:{versionConfiguration:any}){
		return state.versionConfiguration;
	},
	getshowMoreBlock(state:{showMoreBlock:any}){
		return state.showMoreBlock;
	},
	
};

const actions = {
	async setopenfolder({ commit }: any, data: any) {
		commit("setopenfolder", data);
	},
	async setDocViewSource({ commit }: any, data: any) {
		commit("setDocViewSource", data);
	},
	async setRetest({ commit }: any, data: any) {
		commit("setRetest", data);
	},
	async setDocSign({ commit }: any, data: any) {
		commit("setDocSign", data);
	},
	async setTestApprovalstatus({ commit }: any, data: any) {
		commit("setTestApprovalstatus", data);
	},
	async setApprovalListRefresh({ commit }: any, data: any) {
		commit("setApprovalListRefresh", data);
	},
	async setSelectedProject({ commit }: any, data: any) {
		commit("setSelectedProject", data);
	},
	async setFromPage({ commit }: any, data: any) {
		commit("setFromPage", data);
	},
	async setdocumenttodepartment({ commit }: any, data: any) {
		commit("setdocumenttodepartment", data);
	},
	async setDocumentRefresh({ commit }: any, data: any) {
		commit("setDocumentRefresh", data);
	},
	async setshaioreddept({ commit }: any, data: any) {
		commit("setshaioreddept", data);
	},
	async setshaioredfolder({ commit }: any, data: any) {
		commit("setshaioredfolder", data);
	},
	async setDepartment({ commit }: any, data: any) {
		commit("setDepartment", data);
	},
	async setMainDepartment({ commit }: any, data: any) {
		commit("setMainDepartment", data);
	},
	async selectFolder({ commit }: any, data: any) {
		commit("selectFolder", data);
	},
	async selectFoldermove({ commit }: any, data: any) {
		commit("selectFoldermove", data);
	},
	async reloadfolder({ commit }: any, data: any) {
		commit("reloadfolder", data);
	},
	async reloaddepartment({ commit }: any, data: any) {
		commit("reloaddepartment", data);
	},
	async setselectedid({ commit }: any, data: any) {
		commit("setselectedid", data);
	},
	async setpermvalue({ commit }: any, data: any) {
		commit("setpermvalue", data);
	},
	async setformatvalue({ commit }: any, data: any) {
		commit("setformatvalue", data);
	},
	async seteditvalue({commit}:any,data:any){
		commit("seteditvalue",data)
	},
	async seteditfolderid({commit}:any,data:any){
		commit("seteditfolderid",data)
	},
	async setsendfile({ commit }: any, data: any) {
		commit("setsendfile", data);
	},
	async setsendfolder({ commit }: any, data: any) {
		commit("setsendfolder", data);
	},
	async setdocaddpermission({ commit }: any, data: any) {
		commit("setdocaddpermission", data);
	},
	async setfileType({ commit }: any, data: any) {
		commit("setfileType", data);
	},
	async setfileName({ commit }: any, data: any) {
		commit("setfileName", data);
	},
	async setshowMoreBlock({ commit }: any, data: any) {
		commit("setshowMoreBlock", data);
	},
};

const mutations = {
	setopenfolder(state: { openfolder: any }, openfolders: any) {
		state.openfolder = openfolders;
		localStorage.setItem("openfolder", openfolders);
	},
	setDocViewSource(state: { docViewSource: any }, viewSourceData: any) {
		state.docViewSource = viewSourceData;
		localStorage.setItem("viewSource", viewSourceData);
	},
	setRetest(state: { testsource: any }, viewSourceData: any) {
		state.testsource = viewSourceData;
		localStorage.setItem("testretest", viewSourceData);
		
	},
	setDocSign(state: { docSign: any }, newDocSign: any) {
		state.docSign = newDocSign;
		localStorage.setItem("docSign", newDocSign);
		
	},
	setTestApprovalstatus(state: { aprvlstatus: any }, showaprvlstatusData: any) {
		state.aprvlstatus = showaprvlstatusData;
		localStorage.setItem("setTestApprovalstatus", showaprvlstatusData);
	},
	setApprovalListRefresh(state: { approvalRefresh: any }, approvalRefreshData: any) {
		state.approvalRefresh = approvalRefreshData;
		localStorage.setItem("approvalRefresh", approvalRefreshData);
	},
	setSelectedProject(state: { selectedProject: any }, selectedProjectData: any) {
		state.selectedProject = selectedProjectData;
		localStorage.setItem("selectedProject", selectedProjectData);
	},
	setFromPage(state: { fromPage: any }, fromPageData: any) {
		state.fromPage = fromPageData;
		localStorage.setItem("fromPage", fromPageData);
	},
	setdocumenttodepartment(state: { documenttodepartment: any }, doctodept: any) {
		state.documenttodepartment = doctodept;
	},
	setDocumentRefresh(state: { IsDocumentRefresh: any }, docRefreshData: any) {
		state.IsDocumentRefresh = docRefreshData;
		localStorage.setItem("documentRefresh", docRefreshData);
	},
	setshaioreddept(state: { setshaioreddept: any }, shaireddeptid: any) {
		state.setshaioreddept = shaireddeptid;
		localStorage.setItem("setshaioreddept", shaireddeptid);
	},
	setshaioredfolder(state: { setshaioredfolder: any }, shairedfolderid: any) {
		state.setshaioredfolder = shairedfolderid;
		localStorage.setItem("setshaioredfolder", shairedfolderid);
	},
	setDepartment(state: { setDepartment: any }, folderid: any) {
		state.setDepartment = folderid;
		localStorage.setItem("setDepartment", folderid);
	},
	setMainDepartment(state: { setMainDepartment: any }, folderid: any) {
		state.setMainDepartment = folderid;
		localStorage.setItem("setMainDepartment", folderid);
	},
	selectFolder(state: { selectedfolder: any }, folderid: any) {
		state.selectedfolder = folderid;
		localStorage.setItem("selectedfolder", folderid);
	},
	selectFoldermove(state: { selectedfoldermove: any }, movefolderid: any) {
		state.selectedfoldermove = movefolderid;
		localStorage.setItem("selectedfoldermove", movefolderid);
	},
	reloadfolder(state: { folderreload: any }, folderreload: any) {
		state.folderreload = !state.folderreload
	},
	reloaddepartment(state: { departmentreload: any }, departmentreload: any) {
		state.departmentreload = !state.departmentreload;
	},
	setselectedid(state: { selectedid: any }, selectid: any) {
		state.selectedid = selectid;
		localStorage.setItem("selectedid", selectid);
	},
	setpermvalue(state: { permblock: any }, selectvalue: any) {
		state.permblock = selectvalue;
		localStorage.setItem("permblock", selectvalue);
	},
	setformatvalue(state: { formatvalue: any }, selectformat: any) {
		state.formatvalue = selectformat;
		localStorage.setItem("formatvalue", selectformat);
	},
	seteditvalue(state:{editvalue:any},editfolder:any){
		state.editvalue = editfolder;
		localStorage.setItem("editvalue",editfolder)
	},
	seteditfolderid(state:{editfolderid:any},editfolderidd:any){
		state.editfolderid = editfolderidd;
	},
	
	setsendfile(state: { sendfileblock: any }, selectvalue: any) {
		state.sendfileblock = selectvalue;
	},
	setsendfolder(state: { foldersendblock: any }, selectvalue: any) {
		state.foldersendblock = selectvalue;
	},
	setdocaddpermission(state: { docaddpermission: any }, selectvalue: any) {
		state.docaddpermission = selectvalue;
		localStorage.setItem("docaddpermission", selectvalue);
	},
	setfileType(state: { fileType: any }, selectedType: any) {
		state.fileType = selectedType;
		localStorage.setItem("fileType", selectedType);
	},
	setfileName(state: { fileName: any }, selectedName: any) {
		state.fileName = selectedName;
		localStorage.setItem("fileName", selectedName);
	},
	setfolderVersion(state: { versionConfiguration: any }, selectedVerison: any) {
		state.versionConfiguration = selectedVerison;
		localStorage.setItem("versionConfiguration", selectedVerison);
	},
	setshowMoreBlock(state: { showMoreBlock: any }, showMore: any) {
		state.showMoreBlock = showMore;
		localStorage.setItem("showMoreBlock", showMore);
	},
};

export default {
	state,
    getters,
    actions,
    mutations
};