import axios from "axios";
import store from "../../store/index";
export default {
  name: "MoveProjectFolder",
  props: {
    treeId: {
      type: String,
      default: ""
    },
    folderBold: {
      type: String,
      default: ""
    },
    movableFolder: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/`,
      validations: [false, false, false],
      folderlist: [],
      projectlist: [],
      subFolders: [],
      selectedfoldername: "",
      selectedfolderid: "",
      mainfolderbold: "",
      idtoken: "",
      mainprojbold: "",
      //maindeptbold:"",
      folder: "",
      openfolder: "",
      selectedProject: "",
      //selectedDepartment:"",
      folderName: ""
    }
  },
  watch: {
    "$store.getters.getSelectedProject": function (val) {
      if (val) {
        this.mainprojbold = val;
        console.log("THEE PROJJ ISSSS", val)
        //this.maindeptbold = val;
        this.getselectedprojects(val);
        //this.getselecteddepartments(val);
        this.getallprojectfolders(val);
        //this.getalldepartmentfolders(val);
      }
      else {
        this.selectedfoldername = null;
      }
    },
    "$store.getters.getselectedFolder": function (val) {
      if (val) {
        this.mainfolderbold = val;
        this.currentselectedfolder = val;
        this.openfolder = val

      }
      else {
        console.log("Null")
      }

    },
  },
  async mounted() {
    this.mainfolderbold = this.folderBold
    this.selectedProject = ""
    document.body.style.overflowY = "hidden"
    this.idtoken = store.getters.getToken
    store.dispatch("setshowMoreBlock", false)
    this.selectedProject = this.treeId
    this.folder = this.movableFolder
    this.openfolder = this.folderBold
    await this.getName();
    this.getproject();
    this.getselectedprojects(this.treeId);
    this.getallprojectfolders(this.treeId);
    //this.getselecteddepartments(this.treeId);
    //this.getalldepartmentfolders(this.treeId);
  },
  async unmounted() {
    store.dispatch("setshowMoreBlock", true)
    document.body.style.overflowY = "auto"
  },
  methods: {
    async getName() {
      await axios
        .get(`${this.mainapi}document/folder/get?Id=${this.folder}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          this.folderName = res.data.name
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async checkSubFolders() {
      await axios
        .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${this.targetFolder}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          res.data.forEach((item) => {
            if (item.id == this.folder) {
              this.validations[1] = true
            }
            if (item.name == this.folderName) {
              this.validations[2] = true
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onProjectchange(event) {
      this.projectSelected = true
      this.mainfolderbold = event.target.value;
      // this.getselecteddepartments(event.target.value)
      this.getselectedprojects(event.target.value)
      this.getallprojectfolders(event.target.value)
      // this.getalldepartmentfolders(event.target.value)
    },
    // async getdepartment() {
    //   const orgId=store.getters.getOrganisationID;
    //   await axios
    //     .get(`${this.baseurl}department/searchbyprivilege?orgid=${orgId}&PageSize=10000`,
    //         {
    //             headers: {
    //                 Authorization: "Bearer " + this.idtoken,
    //                 "Content-Type": "application/json",
    //             },
    //         })
    //     .then((res) => {
    //        // this.deptlist=res.data.entities 
    //     })
    //     .catch((err) => {
    //         console.log(err)
    //     })
    // },
    async getproject() {
      await axios
        .get(
          `${this.baseurl}project/listproject?SortProperty=ProjectName&SortOrder=0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.projectlist = res.data.entities; //deptlist changed to projectlist

        })
        .catch((err) => {
          console.log(err);
        });
    },
    // async getselecteddepartments(id) {
    // if (id != "") {
    //   await axios
    //     .get(`${this.mainapi}configuration/department/searchbyprivilege?id=${id}&PageSize=100`,
    //         {
    //             headers: {
    //                 Authorization: "Bearer " + this.idtoken,
    //                 "Content-Type": "application/json",
    //             },
    //         })
    //     .then((res) => {
    //         if (res.data.entities[0].name) {

    //             console.log("selected folders are " + res);
    //             this.selectedfoldername = res.data.entities[0].name;
    //             this.selectedfolderid = res.data.entities[0].id;
    //         }
    //     })
    //     .catch((err) => {
    //         console.log(err.response.data.messages)
    //     })
    //   }
    // },
    async getselectedprojects(id) {
      if (id != "") {
        await axios
          .get(`${this.mainapi}configuration/project/get?id=${id}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            })
          .then((res) => {
            this.selectedfolderid = res.data.id;
            this.selectedfoldername = res.data.projectName;        
          })
          .catch((err) => {
            console.log(err.response.data.messages)
          })
      }
    },
    async getallprojectfolders(id) {
      await axios
        .get(`${this.mainapi}document/folder/getprivilegedroots?storageId=${id}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.folderlist = res.data;

        })
        .catch((err) => {
          console.log(err.response.data.messages)
        })
    },
    async backtorootfolder(id) {
      this.mainfolderbold = "",
        this.currentselectedfolder = "";
      this.projectSelected = true
      // this.departmentSelected=true
      store.dispatch("selectFoldermove", id)
      this.getallprojectfolders(id);
      //this.getalldepartmentfolders(id);
    },
    async save() {
      this.targetFolder = store.getters.getselectedFoldermove;
      if (this.targetFolder == "" || this.targetFolder == null) {
        alert("Please select a folder");
      }
      else {
        //alert("folder is " + this.targetFolder)
        this.validations[1] = false
        this.validations[2] = false
        if (this.projectSelected == true) {
          let confirmMessage = confirm("Are you sure you want to move the folder?")
          if (confirmMessage) {
            console.log("Api integrated")
            await axios
              .get(`${this.mainapi}document/folder/copy?FolderId=${this.folder}&TargetId=${this.targetFolder}&TargetType=Project`, {           
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                alert("Folder cloned successfully")
                store.dispatch("reloadfolder", true);
                // this.getalldepartmentfolders(this.selectedDepartment)
                this.getselectedprojects(this.selectedProject)
                //this.getselecteddepartments(this.selectedDepartment)
                this.getallprojectfolders(this.selectedProject)
                // this.getalldepartmentfolders(this.selectedDepartment)
                this.validations[1] = false
                this.$emit("closemodal")

              })
              .catch((err) => {            
                if (err.response.data=="Subfolder with same name exist in targeted folder") {
                  alert("The main folder contains the same folder");
                }
                if (err.response.data=="Subfolder with new name exist in targeted folder") {
                  alert("The folder with entered name exist in targeted folder");
                }
                if (err.response.data=="Cannot move foldewith new name exist in targeted folderr inside itself") {
                  alert("The target and source folder is same");
                }          
              })
            // alert(11);  
          }
        }
        else {
          await this.checkSubFolders()
          if (this.selectedProject == "All") {
            this.selectedProject = ""
          }
          else if (this.selectedProject == "") {
            this.validations[0] = true
          }
          else if (this.folder == this.targetFolder) {
            alert("The target and source folder is same")
          }
          else if (this.validations[1] == true) {
            alert("The main folder contains the same folder")
          }
          else if (this.validations[2] == true) {
            alert("The main folder contains the folder of the same name")
          }
          else {
            this.validations[0] = false
            let confirmMessage = confirm("Are you sure you want to move the folder?")
            if (confirmMessage) {
              console.log("Api integrated")
              await axios
                .get(`${this.mainapi}document/folder/copy?FolderId=${this.folder}&TargetId=${this.targetFolder}&TargetType=Folder`, {                
                  headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                  },
                })
                .then((res) => {
                  alert("Folder cloned successfully")
                  store.dispatch("reloadfolder", true);
                  //this.getalldepartmentfolders(this.selectedDepartment)
                  this.getallprojectfolders(this.selectedProject)
                  this.getselectedprojects(this.selectedProject)
                  // this.getselecteddepartments(this.selectedDepartment)
                  //  this.getalldepartmentfolders(this.selectedDepartment)
                  this.validations[1] = false
                  this.$emit("closemodal")

                })
                .catch((err) => {
                  console.log(err.response.data.messages)
                })
            }
            else {
              console.log("Move failed")
            }

          }
        }
      }
    },
    close() {
      this.$emit("closemodal")
    }
  }
}