<template>
  <span>
 
    <ul v-for="list in list" :for="list.name" :key="list.name">
      <!-- {{ folderOpenIcon[list.id] }} -->
      <li class="d-flex justify-content-between">
        <div class="d-flex">
          <i v-if="list.childFolders.length!=0" class="fs-4 mr-1 cursor-pointer fa fa-caret-down"
             :aria-expanded="folderswitch[list.id]"
            @click.prevent="getfolder(list.id, false);"></i>
          <i v-else class="fa fa-caret-right text-white mr-1 opacity-0" aria-hidden="true"></i>
          <input type="checkbox" :id="list.id + treeid" @click.prevent="getfolder(list.id, false);" />
          <label :for="list.id + treeid" :class="
            selectedboldfolder == list.id? 'tree_label tree_label_bold' : 'tree_label'
          " class="tree_label">
           {{ list.name }}</label>
        </div>
      </li>
      <fileshareTreeFolder v-if="list.childFolders.length!=0" projectId="" :list="list.childFolders" :boldfolder="boldfolder"
        :openfolderlist="openfolder" :SourcePage="destination" patid="" :treeid="treeid" clasification="folderlists"
        @changePerm="close()">
      </fileshareTreeFolder>
    </ul>
  </span>

  
</template>

<script src="./fileshareTreeFolder.js">
</script>

<style  scoped>
@import "./tree.css";

.tree_label_bold {
  font-weight: bold;
}

.more-option-icon {
  display: none;
}

span ul li:hover,
span ul li:focus-visible,
span ul li:hover .tree_label {
  background-color: #e4eaf9;
}

span ul li:hover .more-option-icon {
  display: block;
}

.folder_tree_label {
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 5px 0 5px 1rem;
  position: relative;
}

.folder_tree_label:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.folder_tree_label:before {
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  margin: 0 0.5em 0 -2em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  content: url("../../assets/img/folder-close.png");
  text-align: center;
  line-height: 0.8em;
}

.myStyle {
  color: blue;
}

.innertemplate {
  display: block;
}

.bold-font {
  font-weight: bold;
}

.vtl.vtl-drag-disabled {
  background-color: #d0cfcf;
}

.vtl.vtl-disabled {
  background-color: #d0cfcf;
}

.icon:hover {
  cursor: pointer;
}

.muted {
  color: gray;
  font-size: 80%;
}

.hideandseek {
  display: none !important;
}

.popupbtn {
  color: black;
  float: right;
}

.oneline {
  display: flex;
  justify-content: space-between;
}
.opacity-0{
  opacity:0;
}
</style>
