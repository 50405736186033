<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10 mb-0 mt-1">
            <h2>View Config</h2>
        </div>
        <div class="col-2 col-md-2 align-self-center">
            <i class="fa fa-times close cursor-pointer" @click="closebutton()"></i>
        </div>
    </div>
    <div class="wrapper wrapper-content animated fadeInRight pb-0">
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-title style_2">
                        <h5>View Config</h5>
                    </div>
                    <div class="ibox-content">
                        <div class="form-group row">
                            <div class="col-lg-3 my-2">
                                <label class="col-form-label">Test name

                                </label>
                                <input v-bind:readonly="!isEditable" id="textBox" maxlength="25" type="text"
                                    autocomplete="off" class="form-control" v-model="config.examName" />

                                <ul class="dangeralign">
                                    <li class="errorClass dangeralign" v-if="errorMessage[0] == true">
                                        Please enter the number of questions
                                    </li>
                                    <li class="errorClass dangeralign" v-if="errorMessage[2] == true">
                                        Please enter a value that is a multiple of 10
                                    </li>
                                </ul>
                            </div>

                            <div class="col-lg-3 my-2">
                                <label class="col-form-label">Total number of questions

                                </label>
                                <input v-bind:readonly="!isEditable" id="textBox" maxlength="3" type="text"
                                    autocomplete="off" class="form-control" v-model="config.questionNo"
                                    @keypress="isNumber($event)" />

                                <ul class="dangeralign">
                                    <li class="errorClass dangeralign" v-if="errorMessage[0] == true">
                                        Please enter the number of questions
                                    </li>
                                    <li class="errorClass dangeralign" v-if="errorMessage[2] == true">
                                        Please enter a value that is a multiple of 10
                                    </li>
                                </ul>
                            </div>

                            <div class="col-lg-3 my-2">
                                <label class="col-form-label">Percentage required to pass</label>
                                <input v-bind:readonly="!isEditable" maxlength="3" type="text" class="form-control"
                                    placeholder="" v-model="config.percentage" @keypress="isNumber($event)" />
                                <ul class="dangeralign">
                                    <li class="errorClass dangeralign" v-if="errorMessage[1] == true">
                                        Please enter the number of percentage
                                    </li>
                                    <li class="errorClass dangeralign" v-if="errorMessage[3] == true">
                                        Please enter a value that is a multiple of 10
                                    </li>
                                    <li class="errorClass dangeralign" v-if="errorMessage[4] == true">
                                        The pass percentage cannot be greater than 100%
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-3 my-2 d-flex flex-column">
                                <label class="col-form-label d-block">Total time<sup></sup></label>
                                <div style="display: flex; flex-direction: row">
                                    <input v-bind:readonly="!isEditable" type="text" class="form-control mr-1"
                                        placeholder="Hours" v-model="config.timeinHours" @keypress="isNumber($event)"
                                        maxlength="2" />

                                    <label class="col-form-label"><sup></sup></label>
                                    <input v-bind:readonly="!isEditable" type="text" class="form-control ml-1"
                                        placeholder="Minutes " v-model="config.timeinMins" @keypress="isNumber($event)"
                                        maxlength="2" />
                                    <ul class="dangeralign">
                                        <li class="errorClass dangeralign" v-if="errorMessage[5] == true">
                                            Enter valid time in minutes
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-3 my-2">
                                <label class="col-form-label">Exam date<sup></sup></label>
                                <input v-bind:readonly="!isEditable" class="form-control my-md-0"
                                    v-model="config.endDate" />
                                <span class="d-block errorClass" v-if="validationmsgs == true">
                                    Please select a date in the present or future
                                </span>
                            </div>
                            <div class="col-lg-3 my-2">
                                <label class="col-form-label">Supervisor</label>
                                <input 
                                    v-bind:readonly="!isEditable" 
                                    class="form-control my-md-0"
                                    v-model="config.supervisor.name" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="config-popup" v-if="showpopup == true">
        <div class="modal-width width-100">
            <div class="modal-content">
                <div class="config-popup-header d-flex justify-content-between p-3">
                    <h5 class="my-0 align-self-center">Add Questions</h5>
                    <span type="button" class="align-self-center" @click="onClosed()" aria-hidden="true"><i
                            class="fa fa-times modal-close align-self-center"></i>
                    </span>
                </div>
                <div class="modal-body modal-overflow">
                    <div class="form-group row">
                        <div class="col-lg-12 my-2">
                            <label class="col-form-label">Question
                            </label>
                            <input type="text" class="form-control" v-model="UserDto.question" placeholder="Question" />
                            <div class="errorClass" v-if="errors[0] == false">
                                Please add a question
                            </div>
                            <button type="button" class="btn btn-default py-0 mt-2" @click.prevent="showOption">
                                + Add
                            </button>
                            <div class="hr-line-dashed mb-0"></div>
                        </div>

                        <div class="col-lg-12" v-if="retrieve">
                            <div class="optgroup mt-3">
                                <input type="text" class="form-control opttext" v-model="UserDto.option"
                                    placeholder="options" />
                                <span class="errorClass" v-if="errors[1] == false">
                                    Please add an option
                                </span>
                                <label class="ml-2 mt-2">
                                    <input type="checkbox" v-model="checkoption" value="UserDto.option"
                                        @change="setOptionValue" />
                                    Set this as an answer<sup><i class="fa fa-asterisk required"></i></sup>
                                    <div class="errorClass" v-if="errors[2] == false">
                                        Please select answer
                                    </div>
                                </label>
                            </div>
                            <button type="button" class="btn btn-default py-0 mt-2" @click.prevent="saveOption">
                                + Add
                            </button>
                            <div class="hr-line-dashed mb-0"></div>
                        </div>

                        <div class="col-lg-12 mt-3">
                            <ol class="pl-3">
                                <li class="mb-2" v-for="(optionlist, index) in option" :key="index">
                                    <span v-if="option.includes(UserDto.answer)" class="opt-ans"
                                        :class="{ 'text-green': optionlist == UserDto.answer }">
                                        {{ optionlist }}</span>
                                    <span v-else class="opt-ans"> {{ optionlist }}</span>
                                    <span data-role="remove" @click="deleteFromList(optionlist, index)"><i
                                            class="fa fa-times ml-1"></i></span>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="col-sm-12 text-center mt-5">
                        <button class="btn btn-white btn-sm px-3 py-1 mr-2" @click.prevent="Clear">
                            Reset
                        </button>
                        <button v-if="showSave" class="btn btn-primary btn-sm px-3 py-1" @click.prevent="SaveButtonClicked">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="config-popup" v-if="showpopupDoc == true">
        <div class="modal-dialog width-100">
            <div class="modal-content">
                <div class="config-popup-header d-flex justify-content-between p-3">
                    <h5 class="my-0 align-self-center">Add Related/Reference Documents</h5>
                    <span type="button" class="align-self-center" @click="onClosedDoc()" aria-hidden="true"><i
                            class="fa fa-times modal-close align-self-center"></i>
                    </span>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 col-md-6 pr-md-1 mt-2">
                            <label class="mb-0 mr-1" for="trainer">Document Type <sup><i
                                        class="fa fa-asterisk required"></i></sup></label>
                            <Multiselect name="designation" valueProp="id" mode="multiple" :object="true"
                                placeholder="Select designation" :options="doctypelist" :hideSelected="false"
                                :closeOnSelect="false" class="multiselect-custom-theme" @change="DocumentList($event)"
                                v-model="documentType">
                                <template v-slot:option="{ option }">
                                    {{ option.documentType }}
                                </template>
                            </Multiselect>
                        </div>
                        <div class="col-12 col-md-6 pr-md-1 mt-2">
                            <label class="mb-0 mr-1" for="trainer">Document<sup><i
                                        class="fa fa-asterisk required"></i></sup></label>
                            <Multiselect name="Documents" valueProp="documentId" mode="multiple" :object="true"
                                placeholder="Select Documents" :options="documentList" :hideSelected="false"
                                :closeOnSelect="false" class="multiselect-custom-theme" v-model="docDetails"
                                @change="DocChangeList($event)">
                                <template v-slot:option="{ option }">
                                    {{ option.title }}
                                </template>
                            </Multiselect>
                        </div>
                    </div>
                    <div class="col-sm-12 text-center mt-5">
                        <button class="btn btn-white btn-sm px-3 py-1 mr-2" @click.prevent="Clear">
                            Reset
                        </button>
                        <button class="btn btn-primary btn-sm px-3 py-1" @click.prevent="updateConfig()">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12 px-0">
        <div class="tabs-container" v-if="isConfigued == true">
            <ul class="nav nav-tabs" role="tablist">
                <li>
                    <a class="nav-link active" @click="SwitchTab('Employee', true, false)" data-toggle="tab" href="#tab-1">
                        Users</a>
                </li>
                <li>
                    <a class="nav-link" data-toggle="tab" href="#tab-2">Reference Documents</a>
                </li>
                <li>
                    <a class="nav-link " @click="questionslist()" data-toggle="tab" href="#tab-3">Questions</a>
                </li>
            </ul>
            <div class="tab-content">
                <div role="tabpanel" id="tab-1" class="tab-pane active">
                    <div class="panel-body">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th class="sort_block" id="1">
                                        <div class="d-flex flex-row justify-content-between">
                                            Employee Name
                                        </div>
                                    </th>

                                    <th class="sort_block" id="2">
                                        <div class="d-flex flex-row justify-content-between">
                                            Test Name

                                        </div>
                                    </th>
                                    <th>

                                        <div class="d-flex flex-row justify-content-between">

                                            Supervisor Name

                                        </div>

                                    </th>

                                    <th>

                                        <div class="d-flex flex-row justify-content-between">

                                            Exam Date

                                        </div>


                                    </th>
                                    <th class="sort_block" id="3">
                                        <div class="d-flex flex-row justify-content-between">
                                            Result

                                        </div>
                                    </th>
                                    <th class="sort_block" id="3">
                                        <div class="d-flex flex-row justify-content-between">
                                            Status

                                        </div>
                                    </th>

                                    <th class="text-center">View</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="usrlst in userExamList" :key="usrlst.Id">
                                    <td>
                                        {{ usrlst.employeeName }}
                                    </td>

                                    <td>{{ usrlst.testName }}</td>
                                    <td>{{ usrlst.supervisorName }}</td>

                                    <td>{{ filter(usrlst.dateConductedOn) }}</td>

                                    <td>
                                        {{ usrlst.result }}
                                    </td>
                                    <td>
                                        {{ usrlst.status }}
                                    </td>
                                    <td class="text-center">
                                        <i class="fa fa-eye edit-delete" v-if="usrlst.status != 'Initiated'"
                                            @click="ViewTest(usrlst.configId, usrlst.examId)"></i>
                                    </td>

                                </tr>

                            </tbody>

                        </table>

                    </div>

                </div>

                <div role="tabpanel" id="tab-2" class="tab-pane">
                    <div class="panel-body">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Document Name</th>
                                    <th>Version</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="config in configDocumentList" :key="config.Id">
                                    <td>{{ config.title }}</td>
                                    <td>{{ config.version }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div role="tabpanel" id="tab-3" class="tab-pane ">
                    <div class="panel-body table-responsive">
                        <table class="table table-bordered question-table">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th>Questions</th>
                                    <th>Options</th>
                                    <th>Answer</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="question in questionlist" :key="question.Id">
                                    <td class="text-center">1.</td>
                                    <td>{{ question.question }}</td>
                                    <td>
                                        <ul class="pl-3 mb-0">
                                            <li v-for="(optn, index) in question.option" :key="index" class="mb-2">
                                                <span class="mb-2 opt-ans">{{ optn }}</span>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>{{ question.answer }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-md-12 d-flex justify-content-md-end px-0">
                            <v-pagination v-model="currentPage" :pages="totalPage" :range-size="1" active-color="#ededed"
                                @update:modelValue="loadQuestionPage" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script src="./finalReport.js"></script>
<style scoped>
@import "../../assets/css/savepages.css";

.fa-asterisk {
    color: #e56363 !important;
    font-size: 6px;
}

.config-popup {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0/18%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 3000;
}

.config-popup-header {
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.errorClass {
    color: red;
    font-size: 15px;
}

.submit_btn {
    align-self: center;
    text-align: center;
    cursor: pointer;
    background-color: #7b8cea !important;
    color: rgb(255, 255, 255) !important;
    padding: 0.55rem 0.4rem;
    border-radius: 0.25rem;
    border-color: #7b8cea !important;
    transition: background-color 0.3s ease-in-out 0s !important;
}

.dangeralign {
    margin: 0;
    padding: 0;
}

.width-100 {
    width: min(500px, 90%);
}

.question-table tr td:nth-child(2),
.question-table tr td:nth-child(3),
.question-table tr td:nth-child(4) {
    word-wrap: break-word;
    max-width: 60ch;
    white-space: break-spaces;
}
</style>