<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0 align-self-center">Manage User</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i class="fa fa-times close cursor-pointer" @click="$router.go(-1)"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-3 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="col-12 col-md-4 my-md-2">
          <div class="form-group mb-md-0 pr-1">
            <label class="mb-0">First Name <sup><i class="fa fa-asterisk required"></i></sup></label>
            <input type="text" class="form-control my-md-0" v-model="UserDto.firstName" maxlength="20" />
          </div>
          <span class="errorClass" v-if="errors[0] == false">
            {{ errorMessage[0] }}
          </span>
        </div>
        <div class="col-12 col-md-4 my-md-2">
          <div class="form-group mb-md-0 pr-1">
            <label class="mb-0">Last Name <sup><i class="fa fa-asterisk required"></i></sup></label>
            <input type="text" class="form-control my-md-0" v-model="UserDto.lastName" maxlength="20" />
          </div>
          <span class="errorClass" v-if="errors[1] == false">
            {{ errorMessage[1] }}</span>
        </div>
        <div class="col-12 col-md-4 my-md-2">
          <div class="form-group mb-md-0 pr-1">
            <label class="mb-0">Username<sup><i class="fa fa-asterisk required"></i></sup></label>
            <input :disabled="IsUpdate" type="text" class="form-control my-md-0" v-model="UserDto.username"
              maxlength="20" />
          </div>
          <span class="errorClass" v-if="errors[3] == false">
            {{ errorMessage[3] }}</span>
        </div>
        <div class="col-12 col-md-4 my-md-2">
          <div class="form-group form-required mb-md-0 pr-1">
            <label class="mb-0">Primary Email <sup><i class="fa fa-asterisk required"></i></sup></label>
            <input :disabled="IsUpdate" type="text" maxlength="100" required class="form-control my-md-0"
              v-model="UserDto.primaryEmail" />
          </div>
          <span class="errorClass" v-if="errors[2] == false">
            {{ errorMessage[2] }}</span>
        </div>
        <div class="col-12 col-md-4 my-md-2">
          <div class="form-group mb-md-0 pr-1">
            <label class="mb-0">Secondary Email</label>
            <input type="text" maxlength="100" class="form-control my-md-0" v-model="UserDto.secondaryEmail" />
          </div>
          <span class="errorClass" v-if="errors[5] == false">
            {{ errorMessage[5] }}</span>
        </div>
        <div class="col-12 col-md-4 my-md-2">
          <div class="form-group mb-md-0 pr-1">
            <label class="mb-0">Phone Number</label>
            <input type="text" class="form-control my-md-0" v-model="UserDto.phoneNumber" maxlength="10" oninput="javascript: if (this.value.length > this.maxLength) 
                                   this.value = this.value.slice(0, this.maxLength);" />
          </div>
          <span class="errorClass" v-if="errors[4] == false">
            {{ errorMessage[4] }}</span>
        </div>        
        <div class="col-12 col-md-4 my-md-2">
          <div class="form-group mb-md-0 pr-1">
            <label class="mb-0">Designation <sup><i class="fa fa-asterisk required"></i></sup>
            </label>
            <select class="form-select my-md-0" name="Designation" placeholder="Designation"
              v-model="UserDto.designation">
              <option v-for="disignation in disignations" :key="disignation.id" :value="disignation.id">
                {{ disignation.displayName }}
              </option>
            </select>
          </div>
          <span class="errorClass" v-if="errors[6] == false">
            {{ errorMessage[6] }}</span>
        </div>
        <div class="col-12 col-md-4 my-md-2">
          <div class="form-group mb-md-0 pr-1">
            <label class="mb-0">Department <sup><i class="fa fa-asterisk required"></i></sup>
            </label>
            <Multiselect v-model="UserDto.department" mode="multiple" valueProp="id" label="name"
              placeholder="Select Department" :options="departmentlist" :hideSelected="false" :closeOnSelect="false"
              class="multiselect-custom-theme my-md-0">
            </Multiselect>
          </div>
          <span class="errorClass" v-if="validationmsgs[0]">
            Please choose at least one department
          </span>
        </div>
        <div class="col-12 col-md-4 my-md-2">
          <div class="form-group my-md-0 pr-1">
            <label class="mb-0">User Type <sup><i class="fa fa-asterisk required"></i></sup>
            </label>
            <select class="form-select my-md-0" name="Country" placeholder="Country" v-model="UserDto.userType">
              <option value="Internal">Internal</option>
              <option value="External">External</option>
            </select>
          </div>
          <span class="errorClass" v-if="errors[8] == false">
            {{ errorMessage[8] }}</span>
        </div>
        <div class="col-12 col-md-4 my-md-2">
          <div class="form-group mb-md-0 pr-1">
            <label class="mb-0">Date of Birth</label>
            <input type="date" required class="form-control my-md-0" v-model="UserDto.dateOfBirth" />
          </div>
          <span class="errorClass" v-if="errors[9] == false">
            {{ errorMessage[9] }}</span>
        </div>
        <div class="col-12 col-md-4 my-md-2">
          <div class="form-group mb-md-0 pr-1">
            <label class="mb-0">Country</label>
            <select class="form-select my-md-0" name="Country" placeholder="Country" v-model="UserDto.country">
              <option v-for="c in countries" :key="c.name" :value="c.name">
                {{ c.name }}
              </option>
            </select>
          </div>
          <span class="errorClass" v-if="errors[7] == false">
            {{ errorMessage[7] }}</span>
        </div>
        <div class="col-12 col-md-4 my-md-2">
          <div class="form-group mb-md-0 pr-1">
            <label class="mb-0">Address Line One</label>
            <input type="text" class="form-control my-md-0" v-model="UserDto.addressLineOne" maxlength="50" />
          </div>
          <span class="errorClass" v-if="errors[10] == false">
            {{ errorMessage[10] }}</span>
        </div>
        <div class="col-12 my-md-2">
          <div class="form-group mb-md-0 pr-1">
            <label class="mb-0">Address Line Two</label>
            <input type="text" class="form-control my-md-0" v-model="UserDto.addressLineTwo" maxlength="50" />
          </div>
          <span class="errorClass" v-if="errors[11] == false">
            {{ errorMessage[11] }}</span>
        </div>
      </div>
      <div class="row my-md-2">
        <div class="col-12 col-md-6 d-flex jsutify-contetn-start">
            <div class="i-checks mr-3">
              <label class="mr-2">Active</label>
              <input type="checkbox" value="" v-model="UserDto.active" />
            </div>
            <div v-if="showlock" class="i-checks">
              <label class="mr-2">Lock</label><input type="checkbox" value="" v-model="UserDto.lock" />
            </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-md-end">
          <button  :hidden="IsUpdate" class="btn btn-primary cancel_btn py-2 px-5 mx-3" @click.prevent="Clear">
            Clear
          </button>
          <button class="btn btn-primary py-2 px-5" type="submit" @click.prevent="SaveButtonClicked">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
export default {
  name: "manageuser",
  props: { userId: String },
  components: {
    Multiselect,
  },
  data() {
    return {
      SaveButtonText: "",
      IsUpdate: false,
      showlock: false,
      validationmsgs: [false],
      showerror: false,
      errors: [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
      errorMessage: ["", "", "", "", "", "", "", "", "", "", "", ""],
      Head: {},
      baseapi: process.env.VUE_APP_Service_URL,
      baseUrl: `${process.env.VUE_APP_Service_URL}account-core/user/`,
      designationUrl: `${process.env.VUE_APP_Service_URL}configuration/designation/`,
      OrgUrl: `${process.env.VUE_APP_Service_URL}configuration/organization/`,
      departmentUrl: `${process.env.VUE_APP_Service_URL}configuration/department/`,
      CreateUpdateCommnand: {
        firstName: "",
        lastName: "",
        primaryEmail: "",
        secondaryEmail: "",
        username: "",
        userType: "",
        designation: "",
        departments: [],
        country: "",
        dateOfBirth: "",
        phoneNumber: "",
        addressLineOne: "",
        addressLineTwo: "",
        active: true,
        lock: false,
      },
      departmentlist: [],
      countries: {},
      disignations: {},
      organisations: {},
      UserDto: {
        id: "",
        firstName: "",
        lastName: "",
        primaryEmail: "",
        secondaryEmail: "",
        username: "",
        userType: "Internal",
        designation: "",
        department: [],
        country: "India",
        dateOfBirth: "",
        phoneNumber: "",
        addressLineOne: "",
        addressLineTwo: "",
        active: true,
        lock: false,
      },
    };
  },
  async mounted() {
    const defaultErrMsg = [
      "Invalid First name",
      "Invalid Last name",
      "Invalid Primary email",
      "Invalid Username",
      "Invalid phone number",
      "Invalid secondary email",
      "Please choose a designation",
      "invalid country",
      "Invalid user type",
      "Invalid date of birth",
      "Invalid address line one",
      "Invalid address line two",
    ];
    this.errorMessage = defaultErrMsg;
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    await this.GetAndSetDesignation();
    await this.LoadCountyList();
    await this.departmentslist();
    this.SaveButtonText = "Save";
    this.IsUpdate = false;
  
    if (this.userId != 0) {
      await this.GetAndSetData(this.userId);
      this.SaveButtonText = "Update";
      this.IsUpdate = true;
    }
  },
  methods: {
    Clear() {
      this.UserDto.id = "";
      this.UserDto.firstName = "";
      this.UserDto.lastName = "";
      this.UserDto.primaryEmail = "";
      this.UserDto.secondaryEmail = "";
      this.UserDto.username = "";
      this.UserDto.userType = "";
      this.UserDto.designation = "";
      this.UserDto.country = "";
      this.UserDto.dateOfBirth = "";
      this.UserDto.phoneNumber = "";
      this.UserDto.addressLineOne = "";
      this.UserDto.addressLineTwo = "";
      this.UserDto.department = [];
      this.UserDto.active = true;
      this.UserDto.lock = false;
    },
    async GetAndSetData(userId) {
      await axios
        .get(this.baseUrl + "get?id=" + userId, this.Head)
        .then((res) => {
          res.data.dateOfBirth = res.data.dateOfBirth.slice(0, 10);
          this.UserDto = res.data;
          this.showlock = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async GetAndSetDesignation() {
      await axios
        .get(this.designationUrl + "search?PageSize=100&SortProperty=name", this.Head)
        .then((res) => {
          this.disignations = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async LoadCountyList() {
      console.log("sdfvsd", this.Head);
      await axios
        .get(this.baseUrl + "getcountries", this.Head)
        .then((res) => {
          this.countries = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async SaveButtonClicked() {
      this.validate();
      this.validatedepartment();
      this.showerror = true;

      if (!this.errors.includes(false)) {
        this.SetCommandAsync();
        if (this.userId != 0) {
          await this.UpdateAsync();
        } else {
          await this.CreateAsync();
        }
      }
    },
    async UpdateAsync() {
      this.showlock = true;
      if (!this.validationmsgs.includes(true)) {
        await axios
          .put(
            this.baseUrl + "update?id=" + this.userId,
            this.CreateUpdateCommnand,
            this.Head
          )
          .then((res) => {
            alert("User details updated successfully");
            this.$router.go(-1);
          })
          .catch((err) => {
            alert(err.response.data.errors.DomainValidations[0]);
            if (
              err.response.data.errors.DomainValidations[0].includes(
                "PrimaryEmail"
              )
            ) {
              this.errors[2] = false;
            } else {
              this.errors[2] = true;
            }

            if (
              err.response.data.errors.DomainValidations[0].includes("FirstName")
            ) {
              this.errors[0] = false;
            } else {
              this.errors[0] = true;
            }
          });
      }
    },

    async departmentslist() {
      this.departmentlist = [];
      await axios
        .get(this.departmentUrl + "search?PageSize=10000&SortProperty=name", this.Head)
        .then((res) => {
          res.data.entities.forEach((data) => {
            if (data.parentID == "" || data.parentID == null) {
              if (!this.departmentlist.includes(data)) {
                this.departmentlist.push(data);
              }
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async CreateAsync() {
      if (!this.validationmsgs.includes(true)) {
        await axios
          .post(this.baseUrl + "create", this.CreateUpdateCommnand, this.Head)
          .then((res) => {
            alert("User created successfully");
            this.$router.go(-1);
          })
          .catch((err) => {
            alert(err.response.data.errors.DomainValidations[0]);
            if (
              err.response.data.errors.DomainValidations[0].includes(
                "PrimaryEmail"
              )
            ) {
              this.errors[2] = false;
            } else {
              this.errors[2] = true;
            }

            if (
              err.response.data.errors.DomainValidations[0].includes(
                "FirstName"
              )
            ) {
              this.errors[0] = false;
            } else {
              this.errors[0] = true;
            }

            if (
              err.response.data.errors.DomainValidations[0].includes("Username")
            ) {
              this.errors[3] = false;
            } else {
              this.errors[3] = true;
            }
          });
      }
    },
    SetCommandAsync() {
      this.CreateUpdateCommnand.firstName = this.UserDto.firstName;
      this.CreateUpdateCommnand.lastName = this.UserDto.lastName;
      this.CreateUpdateCommnand.username = this.UserDto.username;
      this.CreateUpdateCommnand.secondaryEmail = this.UserDto.secondaryEmail;
      this.CreateUpdateCommnand.country = this.UserDto.country;
      this.CreateUpdateCommnand.primaryEmail = this.UserDto.primaryEmail;
      this.CreateUpdateCommnand.designation = this.UserDto.designation;
      this.CreateUpdateCommnand.departments = this.UserDto.department;
      this.CreateUpdateCommnand.userType = this.UserDto.userType;
      this.CreateUpdateCommnand.dateOfBirth = this.UserDto.dateOfBirth;
      this.CreateUpdateCommnand.phoneNumber = this.UserDto.phoneNumber;
      this.CreateUpdateCommnand.addressLineOne = this.UserDto.addressLineOne;
      this.CreateUpdateCommnand.addressLineTwo = this.UserDto.addressLineTwo;
      this.CreateUpdateCommnand.active = this.UserDto.active;
      this.CreateUpdateCommnand.lock = this.UserDto.lock;
    },
    async validatedepartment() {
      this.validationmsgs = [false];
      if (this.UserDto.department == "") {
        this.validationmsgs[0] = true;
      }
    },
    async validate() {
      const defaultErrMsg = [
        "Invalid First name",
        "Invalid Last name",
        "Invalid Primary email",
        "Invalid Username",
        "Invalid phone number",
        "Invalid secondary email",
        "Please choose a designation",
        "invalid country",
        "Invalid user type",
        "Invalid date of birth",
        "Invalid address line one",
        "Invalid address line two",
      ];
      this.errors = [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ];
      this.errorMessage = defaultErrMsg;

      this.validatenull(this.UserDto.firstName, 0);
      this.validatenull(this.UserDto.lastName, 1);
      this.validatenull(this.UserDto.username, 3);
      this.validatenull(this.UserDto.designation, 6);
      this.validatenull(this.UserDto.userType, 8);

      // this.validatenull(this.UserDto.department, 12);
      console.log(this.errorMessage);
      if (this.UserDto.phoneNumber != null && this.UserDto.phoneNumber != "") {
        this.validatePhoneNumber(this.UserDto.phoneNumber, 4);
      }
      this.validateEmail(this.UserDto.primaryEmail, 2);
      if (
        this.UserDto.secondaryEmail != null &&
        this.UserDto.secondaryEmail != ""
      ) {
        this.validateEmail(this.UserDto.secondaryEmail, 5);
      }

      if (this.errors[0] != false) {
        this.validateLength(this.UserDto.firstName, 0, 3, 20);
      }
      if (this.errors[1] != false) {
        this.validateLength(this.UserDto.lastName, 1, 1, 20);
      }
      if (this.errors[3] != false) {
        this.validateLength(this.UserDto.username, 3, 3, 20);
      }
      if (this.errors[10] != false) {
        this.validateLength(this.UserDto.addressLineOne, 10, 0, 50);
      }
      if (this.errors[11] != false) {
        this.validateLength(this.UserDto.addressLineTwo, 11, 0, 50);
      }
      this.validDob(this.UserDto.dateOfBirth);
    },
    async validDob(dob) {
      if (dob === "") {
        dob = null;
      }
      let myDate = new Date(dob);
      let today = new Date();
      if (dob == "") {
        this.errors[9] = true;
      } else if (myDate > today) {
        this.errors[9] = false;
      } else {
        this.errors[9] = true;
      }
    },
    validatenull(value, errorIndex) {
      const defaultErrMsg = [
        "Invalid First name",
        "Invalid Last name",
        "Invalid Primary email",
        "Invalid Username",
        "Invalid phone number",
        "Invalid secondary email",
        "Please choose a designation",
        "invalid country",
        "Invalid user type",
        "Invalid date of birth",
        "Invalid address line one",
        "Invalid address line two",
      ];
      value = this.trimtext(value);
      console.log("validate null, value", value, "index", errorIndex);
      if (value != "" && value != null) {
        this.errors[errorIndex] = true;
        this.errorMessage[errorIndex] = defaultErrMsg[errorIndex];
      } else {
        this.errors[errorIndex] = false;
        this.errorMessage[errorIndex] = defaultErrMsg[errorIndex];
      }
    },
    validateLength(value, errorIndex, min, max) {
      const defaultErrMsg = [
        "Invalid First name",
        "Invalid Last name",
        "Invalid Primary email",
        "Invalid Username",
        "Invalid phone number",
        "Invalid secondary email",
        "Please choose a designation",
        "invalid country",
        "Invalid user type",
        "Invalid date of birth",
        "Invalid address line one",
        "Invalid address line two",
      ];
      value = this.trimtext(value);
      if (value.length < min) {
        this.errorMessage[errorIndex] = "Minimum number of character is " + min;
        this.errors[errorIndex] = false;
      } else if (value.length > max) {
        this.errorMessage[errorIndex] = "Maximum number of character is " + max;
        this.errors[errorIndex] = false;
      } else {
        this.errorMessage[errorIndex] = defaultErrMsg[errorIndex];
        this.errors[errorIndex] = true;
      }
    },
    validatePhoneNumber(value, errorIndex) {
      const defaultErrMsg = [
        "Invalid First name",
        "Invalid Last name",
        "Invalid Primary email",
        "Invalid Username",
        "Invalid phone number",
        "Invalid secondary email",
        "Please choose a designation",
        "invalid country",
        "Invalid user type",
        "Invalid date of birth",
        "Invalid address line one",
        "Invalid address line two",
      ];
      value = this.trimtext(value);
      const validationRegex = /^\d{10}$/;
      if (value.match(validationRegex)) {
        this.errors[errorIndex] = true;
        this.errorMessage[errorIndex] = defaultErrMsg[errorIndex];
      } else {
        this.errors[errorIndex] = false;
        this.errorMessage[errorIndex] = "Invalid phone number";
      }
    },
    validateEmail(value, errorIndex) {
      const validationRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
      value = this.trimtext(value);
      if (value.match(validationRegex)) {
        this.errors[errorIndex] = true;
      } else {
        this.errors[errorIndex] = false;
        this.errorMessage[errorIndex] = "Invalid email";
      }
    },
    trimtext(value) {
      value = value.replace(/^\s+|\s+$/gm, "");
      return value;
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css">

</style>
<style scoped>
@import "../../assets/css/savepages.css";

.fa-asterisk {
  color: #e56363 !important;
  font-size: 6px;
}

.errorClass {
  color: red;
  font-size: 15px;
}

.submit_btn {
  align-self: center;
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
</style>