<template>
  <div class="row wrapper border-bottom white-bg page-heading px-4">
    <div class="col-md-10 py-0 px-0 mx-0">
      <h2 class="my-0">Dashboard</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="text-center" v-if="showdiv">
      <h1 class="text-center">No privilege found</h1>
    </div>
    <div v-else>
      <div class="row">
      <div class="col-12 col-md-6 mb-1">
        <div class="ibox-body">
        <div class="ibox-title"><h5 class="my-0">Approve Training Plan </h5></div>
        <div class="ibox-content">
          <table class="table table-bordered table-">
            <thead>
              <th>Employee</th>
              <th>Required Skills</th>
              <th>Training Date</th>
              <th>Training Completed Sign &amp; Date</th>
            </thead>
            <tbody>
              <tr v-for="traininplanItem in approveTrainingPlan" :key="traininplanItem.id">
                <td>
                  {{ traininplanItem.userName}}
                </td>
                <td>
                  {{ traininplanItem.skills }}
                </td>
                <td>
                  {{ filter(traininplanItem.actualEndDate) }}
                </td>
                <td class="text-center">
                  <button class="btn btn-outline btn-success px-4 py-0 " type="button" @click.prevent="openModal(traininplanItem.trainingId)"><i
                        class="fa fa-check"  ></i>&nbsp;Sign</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="col-md-12 d-flex justify-content-md-end px-0">
            <v-pagination
              v-model="currentPage"
              :pages="totalPages"
              :range-size="1"
              active-color="#ededed"
              @update:modelValue="loadPage"
            />
          </div>
        </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-1">
        <div class="ibox-body">
        <div class="ibox-title"><h5 class="my-0">Sign Document</h5></div>
        <div class="ibox-content">
          <div class="table-responsive mb-3">
          <table class="table table-bordered">
            <thead>
              <th>Document Name</th>
              <th>Created at</th>
              <th class="text-center">View</th>
            </thead>
            <tbody>
              <tr v-for="item in signersList" :key="item.id">
                <td>
                  {{ item.versionFileName}}
                </td>
                <td>
                  {{ filter(item.CreatedAt) }}
                </td>
                <td class="text-center">
                  <i class="fa fa-eye edit-delete"  @click="docview(item.id,item.versionNumber,item.versionId)"></i>
                </td>
               
              </tr>
            </tbody>
          </table>
        </div>
          
          <div class="col-md-12 d-flex justify-content-md-end px-0">
            <v-pagination
              v-model="signerCurrentPage"
              :pages="signertotalPages"
              :range-size="1"
              active-color="#ededed"
              @update:modelValue="loadSignerList"
            />
          </div>
        </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="ibox-body">
        <div class="ibox-title"><h5 class="my-0">Sign Process Training Acknowledgement</h5></div>
        <div class="ibox-content">
        <div class="table-responsive mb-3">
          <table class="table table-bordered">
            <thead>
              <th>Training Name</th>
              <th>Employee</th>
              <th class="text-center">View</th>
            </thead>
            <tbody>
              <tr v-for="item in sopList" :key="item.id">
                <td>
                  {{ item.trainingName}}
                </td>
                <td>
                  {{ item.employee }}
                </td>
                <td class="text-center">
                  <i class="fa fa-eye edit-delete"  @click="viewSop(item.id,item.userType)"></i>

                </td>
               
              </tr>
            </tbody>
          </table>
        </div>
          
          <div class="col-md-12 d-flex justify-content-md-end px-0">
            <v-pagination
              v-model="signercurrentPage"
              :pages="signertotalPages"
              :range-size="1"
              active-color="#ededed"
              @update:modelValue="loadSignerList"
            />
          </div>
        </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="ibox-body">
        <div class="ibox-title"><h5 class="my-0">Sign Online Test Report</h5></div>
        <div class="ibox-content">
          <div class="table-responsive mb-3">
          <table class="table table-bordered">
            <thead>
              <th>Employee</th>
              <th>Test Name</th>
              <th class="text-center">View</th>
            </thead>
            <tbody>
              <tr v-for="item in testResultList" :key="item.id">
                <td>
                  {{ item.employeeName}}
                </td>
                <td>
                  {{ item.testName }}
                </td>
                <td class="text-center">
                  <i class="fa fa-eye edit-delete"  @click="ViewTest(item.configId,item.examId)"></i>

                </td>
               
              </tr>
            </tbody>
          </table>
        </div>
          
          <div class="col-md-12 d-flex justify-content-md-end px-0">
            <v-pagination
              v-model="testResultcurrentPage"
              :pages="testResulttotalPages"
              :range-size="1"
              active-color="#ededed"
              @update:modelValue="loadTestResultPage"
            />
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  </div>
  <DigitalSignPopUp
    v-if="showSignBlock"
    @closePopup="showSignBlock = false"
    @signAdded="getapproveTrainingPlan()"
    :acknId="trainingId"
  >
  </DigitalSignPopUp>
</template>

<script src="./dashboard.js"></script>
<style scoped>
.menuitem {
  background-color: #c6c6c6;
  width: 100%;
  height: 48px;
  text-align: center;
  padding-top: 6%;
  margin-bottom: 1px;
}

.menuitem:hover {
  background-color: #b2c5f4;
}

.fullwidth {
  width: 95.666667%;
}

.rowdiv {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.boxtitle {
  height: 40px;
  background-color: #618bd7;
  margin: 10px;
  position: relative;
  text-align: initial;
  color: white;
  font-size: 14px;
  padding: 10px;
}

.boxcontents {
  min-height: 300px;
  background-color: white;
  height: 0px;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.maincontents {
  /* background-color: red; */
  width: 100%;
  position: inherit;
  margin-bottom: 80px;
}

.topmenubar {
  background-color: #efefef;
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  display: flex;
}

.title-bg-light {
  background-color: #d9e4fb;
}

.dashboard-widget {
  cursor: pointer;
  box-shadow: 0 0.15rem 0.15rem rgb(0 0 0 / 8%);
}

.dashboard-widget .title {
  background-color: #ffffff;
}

.primary-background {
  background-color: #dcfee4;
  height: 90px;
}

.secondary-background {
  background-color: #bdf5be;
}

.neutral-background {
  background-color: #ebf5c0;
}

.roles-background {
  background-color: #f5d6d1;
}

.sop-background {
  background-color: #dce1ff;
}

.myfiles-background {
  background-color: hsl(65, 100%, 91%);
}
.examevidence-background {
  background-color: #dce1ff;
}

.forms-block {
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 0 0.15rem 0.15rem rgb(0 0 0 / 8%);
}

.btn-form {
  border-radius: 4px;
  background-color: #618bd7;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

.btn-form i {
  font-size: 12px;
}

.btn-form:hover {
  color: #ffffff;
}

.margin-top {
  margin-top: 0.5rem;
}

.padding-block {
  padding-block: 3.2rem;
}
.training-background {
  background-color: #dbf0ff;
}
@media (min-width: 992px) {
  .margin-top {
    margin-top: auto;
  }
}
</style>