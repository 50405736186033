<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10"></div>
    <div class="col-md-2 align-self-center">
      <i
        class="fa fa-times close cursor-pointer"
        v-if="!submitbutton"
        @click.prevent="closebutton()"
      ></i>
    </div>
  </div>
  <div class="row border-bottom white-bg dashboard-header">
    <div class="ibox-content col-lg-12 mb-2 p-0">
      <div class="row px-3 mt-2">
        <div class="col-2">
          <small class="stats-label">Total Questions</small>
          <h4 v-if="viewanswer">{{ configDetails.totalConfigQuestion }}</h4>
          <h4 v-else>{{ testResult.examQstnCount }}</h4>
        </div>
        <div class="col-2">
          <small class="stats-label">Pass Grade</small>
          <h4 v-if="viewanswer">
            {{ configDetails.configPercentage }} <small> %</small>
          </h4>
          <h4 v-else>{{ testResult.configPercentage }}</h4>
        </div>
        <div class="col-2" v-if="showAnswer">
          <small class="stats-label"> Mark</small>
          <h4>{{ testResult.mark }}</h4>
        </div>

        <div class="col-2">
          <small class="stats-label">Total Time(Hr : Min)</small>
          <h4>
            {{
              time.timeinHours != null
                ? time.timeinHours
                : time.timeinMins == null
                ? "__"
                : "00"
            }}
            <small> :</small>
            {{
              time.timeinMins != null
                ? time.timeinMins
                : time.timeinHours == null
                ? "__"
                : "00"
            }}
          </h4>
        </div>
        <div
          class="col-2"
          v-if="TestStatus == `Initiated` && startbutton == false"
        >
          <small class="stats-label">Time Remaining</small>
          <h4 id="countdown" class="normal"></h4>
        </div>

        <div class="col-2 align-self-center" v-if="showAnswer">
          <small>Exam Result :</small>
          <h4
            :class="{
              textDanger: testResult.result == 'Failed',
              textSucess: testResult.result == 'Passed',
            }"
          >
            {{ testResult.result }}
          </h4>
        </div>

        <div class="col-2" v-if="UserType == `Supervisor`&& TestStatus!=`Signed`">
          <small class="stats-label"> Sign</small>
          <h4>
            <img 
              src="../../assets/img/sign_dms.png"
              alt="create version"
              @click="ShowSignModal()"
            />
          </h4>
        </div>

        <div v-if="startbutton == true" class="col-2 align-self-center">
          <button
            type="button"
            class="btn submit_btn btn-xs p-1 px-2 w-50"
            @click="timer(time.timeinHours, time.timeinMins)"
          >
            Start Test
          </button>
        </div>
      </div>
    </div>

    <div v-if="startbutton == false" class="ibox-content col-lg-12 pl-0 py-2">
      <ol type="1">
        <li v-for="(qstn, i) in questionList" :key="qstn.id">
          <p class="p" v-if="showquestion == true">
            {{ qstn.question }}
          </p>
          <p v-if="showquestion == false">
            {{ qstn.qusetionName }}
          </p>
          <div
            v-for="(optn, index) in qstn.option"
            :key="index"
            class="form-check abc-checkbox"
          >
            <input
              :name="optn + qstn.id"
              :value="optn"
              :id="qstn.id + index"
              :disabled="showAnswer"
              type="radio"
              v-model="answerValue[i]"
            />           
            
            <label
              :class="{
                'form-check-label-sucess':
                  showAnswer &&
                  optn == qstn.answer &&
                  answerValue[i] == qstn.answer,
                'form-check-label-danger':
                  showAnswer &&
                  optn == answerValue[i] &&
                  answerValue[i] != qstn.answer,
                'form-check-label-sucess': showAnswer && optn == qstn.answer,                
                'form-check-label': !showAnswer,
                'nullAnswer':qstn.enteredAnswer===null,
                'correctAnswer':qstn.answer!='' && qstn.enteredAnswer!=null
              }"
              :for="qstn.id + index"
            >
              <span class="mt-1" :class="{'font-weight-bold': qstn.enteredAnswer===optn}"> {{ optn }} </span>
            </label>
          </div>
        </li>
      </ol>
    </div>
    <div v-if="startbutton == false" class="ibox-content col-lg-12 mb-2 p-0">
      <div class="col-lg-12 align-self-end my-1 text-right">
        <span
          type="button"
          class="submit_btn px-3 py-1"
          v-if="!showAnswer && submitbutton"
          @click.prevent="submit()"
          >Submit</span
        >
      </div>
    </div>
  </div>

  <!-- popup -->
  <div class="password-popup" v-if="showModal">
    <div class="modal-width">
      <div class="modal-content">
        <div class="password-popup-header d-flex justify-content-between p-3">
          <h5 class="my-0 align-self-center">Sign test result</h5>
          <span
            type="button"
            class="align-self-center"
            @click="Closedownload()"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>
        <div class="modal-body modal-overflow">
          <div class="form-group mb-4">
            <h5>
              !!!On signing, this is equivalent to the wet ink signature!!!
            </h5>
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              required=""
              v-model="email"
            />
          </div>
          <form>
            <div class="form-group position-relative mb-4">
              <input
                placeholder="Please enter your Digi-sign password"
                class="form-control"
                :type="inputType"
                id="templatePassword"
                name="password"
                ref=""
                v-model="password"
              />
              <i
                class="password-visible-block cursor-pointer position-absolute"
                :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
                @click.prevent="passwordVisible()"
              ></i>
              <span v-if="validation" class="errmsg">{{ err }}</span>
              <span v-if="errorMsg" class="errmsg"
                >Please enter a password</span
              >
            </div>
            <div class="form-group mb-4">
              <label class="mb-0" for="reasons">Reason</label>
              <select
                class="form-control"
                name="account"
                v-model="reasons"
                id="reasons"
              >
                <option value="" disabled selected>-Select Reason-</option>
                <option
                  v-for="reason in reasonList"
                  :key="reason.id"
                  :value="reason.reason"
                >
                  {{ reason.reason }}
                </option>
              </select>
              <input
                class="form-control mt-2"
                v-if="reasons === 'Others'"
                v-model="otherReason"
                type="text"
                maxlength="50"
                name="reason"
              />
              <div v-if="error[1]" class="errmsg">Please enter a reason</div>
              <div v-if="error[0]" class="errmsg">Please enter a reason</div>
            </div>
            <div class="text-center">
              <span
                type="button"
                tabindex="0"
                class="submit_btn px-5 py-2"
                :disabled="loadingBlock"
                @click.prevent="DownloadReport()"
              >
                <span v-if="loadingBlock && !errorMsg"
                  ><img
                    src="../../assets/img/loading.webp"
                    width="20"
                    class="loading-img"
                    alt="Login buffering"
                  />
                </span>
                <span v-else>Submit</span></span
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./myQuestion.js">
</script>

<style scoped>
@import "../../assets/css/awesome-bootstrap-checkbox.css";

.fa-asterisk {
  color: #e56363 !important;
  font-size: 6px;
}

.errorClass {
  color: red;
  font-size: 15px;
}
.abc-checkbox input[type="checkbox"]:checked + label::after,
.abc-checkbox input[type="radio"]:checked + label::after {
  font-family: "FontAwesome";
  content: "";
}
.submit_btn {
  align-self: center;
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}

.optgroup {
  display: flex;
  gap: 5px;
  width: 100%;
}

.opttext {
  width: 80%;
}

.text-green {
  color: #1ab394 !important;
}
.input {
  margin: 4px -16px 0;
}
.form-check-label-danger::before {
  cursor: pointer;
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 2px;
  left: 0;
  margin-left: -1.25rem;
  border: 1px solid #ced4da;
  border-radius: 3px;
  background-color: #ed5565;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
 .form-check-label-danger::after {
  cursor: pointer;
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 2px;
  left: 1px;
  color: white;
  margin-left: -1.25rem;
  border-radius: 3px;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; 
} 
.form-check-label-sucess::before {
  cursor: pointer;
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 2px;
  left: 0;
  margin-left: -1.25rem;
  border: 1px solid #ced4da;
  border-radius: 3px;
  background-color: #1ab394;
  color: #1ab394;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
/* .form-check-label-sucess::after {
  font-family: "FontAwesome";
  content:"\f00c";
  cursor: pointer;  
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 2px;
  left: 0;
  color: white;
  margin-left: -1.25rem;
  border-radius: 3px;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
} */

.abc-checkbox input[type="checkbox"]:checked +.form-check-label-danger::after, .abc-checkbox input[type="radio"]:checked +.form-check-label-danger::after
{
font-family: "FontAwesome";
content:"\f00d"
}
.abc-checkbox input[type="checkbox"]:checked +.form-check-label-success::after, .abc-checkbox input[type="radio"]:checked +.form-check-label-success::after
{
font-family: "FontAwesome";
content:"\f00c"
}
.abc-checkbox input[type="checkbox"]:unchecked +.form-check-label-success::after, .abc-checkbox input[type="radio"]:unchecked +.form-check-label-success::after
{
font-family: "FontAwesome";
content:"\f00c"
}
.form-check-label-sucess.nullAnswer::after {
  cursor: pointer;
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 3px;
  top: 9px;
  left: 4px;
  color: white;
  margin-left: -1.25rem;
  border: 1px solid #ced4da;
  border-radius: 3px;
  background-color: #1ab394;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.form-check-label-sucess.correctAnswer::after
{
  font-family: "FontAwesome";
  content:"\f00c";
  cursor: pointer;  
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 2px;
  left: 0;
  color: white;
  margin-left: -1.25rem;
  border-radius: 3px;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.textDanger {
  color: red;
  font-weight: bold;
}
.textSucess {
  color: green;
  font-weight: bold;
}
.p {
  margin-top: 1rem;
  margin-bottom: 0.2rem !important;
}
/* Disable password visibility icon in Microsoft Edge */
input[type="password"]::-ms-reveal {
  display: none;
}
.errmsg {
  font-size: 12px;
  color: red;
  float: left;
}
.password-popup {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.password-popup-header {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.password-visible-block {
  right: 11px;
  top: 10px;
}

.errorMsg {
  color: hsl(0, 100%, 50%);
}
.loading-img {
  mix-blend-mode: lighten;
}
</style>