<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h3 class="my-0 py-0">User Project Role Map</h3>
    </div>
    <div class="col-2 col-md-2">
      <i class="fa fa-times close my-2" @click="$router.go(-1)"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-3 p-2">
      <div class="form-group row my-md-2">
        <div class="col-md-12 mb-1">
          <label class="col-form-label">
            Role Name <sup><i class="fa fa-asterisk required"></i></sup>
          </label>
          <select class="form-control" name="role" v-model="values.roleId">
            <option value="" disabled selected>-Select-</option>
            <option v-for="role in roles" :key="role.id" :value="role.id">
              {{ role.roleName }}
            </option>
          </select>
          <span class="errorClass" v-if="errors[0] == false"
            >Select a Role</span
          >
          <!-- <span class="errorClass" v-if="!errors[0]">Please enter role name</span> -->
        </div>

        <div class="col-md-12 mb-1">
          <label class="col-form-label"
            >Project <sup><i class="fa fa-asterisk required"></i></sup
          ></label>
          <select
            class="form-control"
            name="project"
            v-model="values.projectId"
          >
            <option value="" disabled selected>-Select-</option>
            <option
              v-for="project in projects"
              :key="project.id"
              :value="project.id"
            >
              {{ project.projectName }}
            </option>
          </select>
          <span class="errorClass" v-if="errors[1] == false"
            >Select a Project</span
          >
        </div>

        <div class="col-md-12 mb-1">
          <label class="col-form-label"
            >Users <sup><i class="fa fa-asterisk required"></i></sup
          ></label>
          <select class="form-control" name="user" v-model="values.userId">
            <option value="" disabled selected>-Select-</option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </select>
          <span class="errorClass" v-if="errors[2] == false"
            >Select a User</span
          >
        </div>

        <div class="col-md-6 my-2 d-flex">
          <button
            class="btn btn-primary save_btn py-2 px-5"
            type="submit"
            @click="saveOrEditPatientUser()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
//import "@vueform/multiselect/themes/default.css";
export default {
  name: "allocateprojectusers",
  props: { projectuserid: String },

  data() {
    return {
      idtoken: "",
      baseapi: process.env.VUE_APP_Service_URL,
      baseUrl: `${process.env.VUE_APP_Service_URL}`,
      roles: [],
      users: [],
      projects: [],
      values: {
        id: "",
        roleId: "",
        projectId: "",
        userId: "",
      },
      editValue: {
        id: "",
        roleId: "",
        projectId: "",
        userId: "",
      },
      validations: [false, false, false],
      errors: [],
      //validations: [false],
      // errors: [true],
      // headerValues : "",
      // idToken : "",
    };
  },
  async mounted() {
    // this.projectuserid ="3e530f3e-8a04-4156-b710-a675daabf8c3";
    // this.idToken = store.getters.getIdToken;
    // this.headerValues = {
    //     headers: {
    //         Authorization: "Bearer " + this.idToken,
    //         "Content-Type": "application/json",
    //         },
    // };

    // alert("check");
    this.idtoken = store.getters.getToken;
    await this.getRoles();
    await this.getProjects();
    await this.getUsers();
    if (this.projectuserid !== "0") {
      await this.getProjectUser(this.projectuserid);
      // this.disableStatus = true;
      // await this.setValues();
      console.log("IF BINDED dATA IS", this.values);
    } else {
    }
  },
  methods: {
    async getProjects() {
      await axios
        .get(`${this.baseUrl}configuration/project/getall`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        //(`https://localhost:5001/project/getall`)
        .then((res) => {
          console.log("Get projects ", res.data);
          this.projects = res.data;
        })
        .catch((err) => console.log(err));
    },
    async getRoles() {
      await axios
        .get(`${this.baseUrl}account-core/roles/getallroles`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        //(`https://localhost:5001/roles/getallroles`)
        .then((res) => {
          console.log("Get roles ", res.data);
          this.roles = res.data;
        })
        .catch((err) => console.log(err));
    },
    async getUsers() {
      await axios
        .get(`${this.baseUrl}account-core/user/getall`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        //(`https://localhost:5001/user/getall`)
        .then((res) => {
          console.log("Get users ", res.data);
          this.users = res.data;
        })
        .catch((err) => console.log(err));
    },
    async saveOrEditPatientUser() {
      await this.validateIsNull();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      } else {
        if (this.projectuserid !== "0") {
          console.log(this.values);
          //this.setEditValues();
          await axios
            .put(
              `${this.baseUrl}configuration/userprojectrole/update`,
              this.values,
              {
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              console.log("Get users ", res.data);
              this.users = res.data;
            })
            .catch((err) => console.log(err));
        } else {
          await axios
            .post(
              `${this.baseUrl}configuration/userprojectrole/create`,
              this.values,
              {
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              console.log("Create response ", res.message);
              this.users = res.data;
              this.$router.push(`/projectusers`);
            })
            .catch((err) => {
              if (
                err.response.data.messages[0].includes(
                  "Subject already allocated in this project"
                )
              ) {
                alert("Subject already assigned to this project");
              }
              console.log("err is", err.message);
              // alert(err.message);
            });
        }
      }
    },
    async validateIsNull() {
      if (this.values.roleId != "") {
        this.validations[0] = true;
      }
      if (this.values.projectId != "") {
        this.validations[1] = true;
      }
      if (this.values.userId != "") {
        this.validations[2] = true;
      }
    },
    async setValues() {
      this.values.roleId = this.editValue.roleId;
      this.values.projectId = this.editValue.projectId;
      this.values.userId = this.editValue.userId;
    },
    setEditValues() {
      this.editValue.roleId = this.values.roleId;
      this.editValue.projectId = this.values.projectId;
      this.editValue.userId = this.values.userId;
      this.editValue.id = this.projectuserid;
    },
    async getProjectUser(id) {
      await axios
        // .get(this.baseUrl + "getbyid?id=" + id, this.headerValues)
        .get(
          `${this.baseUrl}configuration/userprojectrole/getbyid?id=` +
            this.projectuserid,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Bind data is", res.data);
          this.values = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.fa-asterisk {
  color: #e56363 !important;
  font-size: 6px;
}
.errorClass {
  color: red;
  font-size: 15px;
}
</style>


