
<template>
  <div class="ibox-title style_2 d-flex justify-content-between">
    <!-- <img src="../../assets/img/dm_logo.png" alt="Icon" class="icon-tableview" /> -->
    <h5>Manage Training plan</h5>
    <div class="configure-block">
      <span
        class="position-relative filter-block mr-3"
        @click.prevent="dateBlockDisplay()"
      >
        <img
          class="cursor-pointer"
          src="../../assets/img/filter_grey.png"
          alt="Filter table icon"
        />
        <span class="tool-tip">Filter</span>
      </span>
      <span class="position-relative filter-block mr-3">
        <img
          class="cursor-pointer"
          src="../../assets/img/export_icon.png"
          @click.prevent="exportTraining()"
          alt="Export table icon"
        />
        <span class="tool-tip">Export Training Plan</span>
      </span>
      <span
        v-if="designationPrivileges.includes('Training Assign Training Plan')"
        class="position-relative filter-block mr-3"
        @click.prevent="openPopup()"
      >
        <img
          class="cursor-pointer"
          src="../../assets/img/add.png"
          alt="Create row icon"
        />
        <span class="tool-tip">Add Training Plan</span>
      </span>
    </div>
  </div>
  <div id="cardtrainingBody">
    <div class="card-body ibox-title style_2 row py-1">
      <div class="col-lg-3 pr-0">
        <form>
          <div class="mb-1 text-left">
            <label class="col-form-label">Planned date</label>
            <div class="input-daterange input-group" id="datepicker">
              <input
                v-model="searchPlannedstartdate"
                type="date"
                class="form-control"
                name="start"
              />
              <span class="input-group-addon">to</span>
              <input
                v-model="searchPlannedenddate"
                type="date"
                class="form-control"
                name="end"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-3 pr-0">
        <form>
          <div class="mb-1 text-left">
            <label class="col-form-label">Actual date</label>
            <div class="input-daterange input-group" id="datepicker">
              <input
                v-model="searchActualstartdate"
                type="date"
                class="form-control"
                name="start"
              />
              <span class="input-group-addon">to</span>
              <input
                v-model="searchActualenddate"
                type="date"
                class="form-control"
                name="end"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-2">
        <form>
          <div class="mb-1 text-left">
            <label class="col-form-label">Status</label>
            <select
              v-model="searchStatus"
              class="form-control m-b"
              name="Status"
            >
              <option value="" disabled selected>-Select-</option>
              <option selected value="All">All</option>
              <option value="Completed">Completed</option>
              <option value="Planned">Planned</option>
            </select>
          </div>
        </form>
      </div>
      <div class="col-lg-2">
        <form>
          <div class="mb-1 text-left">
            <label class="col-form-label">Employee</label>
            <select v-model="userName" class="form-control m-b" name="account">
              <option value="" selected>-- Select Employee --</option>
              <option
                v-for="user in dropDownUserList"
                :key="user.id"
                :value="user.id"
              >
                {{ user.fullName }}
              </option>
            </select>
          </div>
        </form>
      </div>
      <div class="col-lg-1 text-center align-self-center">
        <span
          type="button"
          class="submit_btn mt-3 px-4 py-2 w-20"
          @click="onSearchClicked"
          >Search</span
        >
      </div>
      <div class="col-lg-1 text-center align-self-center">
        <span
          type="button"
          class="submit_btn mt-3 px-4 py-2 w-20"
          @click="clearValues"
          >Clear</span
        >
      </div>
    </div>
  </div>
  <div class="form-table-view-container">
    <div
      class="table-responsive"
      :class="{ 'scrollable-training': data.length > 10 }"
    >
      <table class="table table-bordered training-plan">
        <thead>
          <tr>
            <th v-for="column in columnDefs" :key="column.name">
              <span v-html="column.header"></span>
              <span>
                <input
                  v-if="column.header == 'Select All'"
                  type="checkbox"
                  id="selectall"
                  v-model="selectAll"
                  @change="selectAllRows"
                />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="item.id">
            <td
              v-for="column in columnDefs"
              :key="column.name"
              :class="{ 'limit-word': column.name === 'trainingDetails' }"
            >
              <slot :name="`col.${column.name}`" :item="item">
                <template v-if="column.name === 'select'">
                  <input
                    type="checkbox"
                    v-model="selectedRows[index]"
                    :value="item.id"
                    @click="eachRowSelection(item.id, index)"
                  />
                </template>
                <template v-if="column.name === 'skills'">
                  <div v-if="item[column.name]">
                    <div
                      v-if="
                        !showMoreStatesSkills[index] &&
                        item[column.name].length > 150
                      "
                    >
                      {{ truncateTextSkills(item[column.name], 120) }}
                      <span class="more" @click="toggleShowMoreSkills(index)"
                        >more</span
                      >
                    </div>
                    <div v-else>
                      {{ item[column.name] }}
                      <span
                        v-if="showMoreStatesSkills[index]"
                        class="less"
                        @click="toggleShowMoreSkills(index)"
                        >less</span
                      >
                    </div>
                  </div>
                  <div v-else>&nbsp;</div>
                </template>

                <template v-else-if="column.name === 'trainingDetails'">
                  <div v-if="item[column.name]">
                    <div
                      v-if="
                        !showMoreStates[index] && item[column.name].length > 150
                      "
                    >
                      {{ truncateText(item[column.name], 120) }}
                      <span
                        class="more"
                        style="word-wrap: normal; word-break: break-all"
                        @click="toggleShowMore(index)"
                        >more</span
                      >
                    </div>
                    <div v-else>
                      {{ item[column.name] }}
                      <span
                        v-if="showMoreStates[index]"
                        style="word-wrap: normal; word-break: break-all"
                        class="less"
                        @click="toggleShowMore(index)"
                        >less</span
                      >
                    </div>
                  </div>
                  <div v-else>&nbsp;</div>
                </template>

                <template v-else-if="column.name === 'plannedDateRange'">
                  <div class="date-range">
                    {{
                      NeededDateRange(
                        item.plannedStartDate,
                        item.plannedEndDate
                      )
                    }}
                  </div>
                </template>

                <template v-else-if="column.name === 'actualDateRange'">
                  <div class="date-range">
                    {{
                      NeededDateRange(item.actualStartDate, item.actualEndDate)
                    }}
                  </div>
                </template>

                <template v-else>
                  <template v-if="column.name === 'signature'">
                    <div v-if="item[column.name]">
                      <div>
                        <strong>Signed By</strong> :
                        <span class="signed-by">{{
                          item[column.name].signedBy
                        }}</span>
                      </div>
                      <div>
                        <strong>Name</strong> :
                        <strong>{{ item[column.name].name }}</strong>
                      </div>
                      <div>
                        <strong>Reason</strong> :
                        <strong>{{ item[column.name].reason }}</strong>
                      </div>
                      <div>
                        <strong>Date and Time</strong> :
                        <strong>{{
                          formatDateTime(item[column.name].signedDate)
                        }}</strong>
                      </div>
                      <div>
                        <span class="ref-label"
                          ><strong
                            >Ref : {{ item[column.name].referenceId }}</strong
                          ></span
                        >
                      </div>
                    </div>
                    <div v-else></div>
                  </template>
                  <template v-else>
                    {{ item[column.name] }}
                  </template>
                </template>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- <div class="row mt-3 mx-0">
    <div class="col-md-12 mx-0 px-0">
      <div
        class="dataTables_paginate paging_simple_numbers pagination float-right"
      >
        <div class="col-md-12 d-flex justify-content-md-end px-0">
          <v-pagination
            v-model="currentPage"
            :pages="totalPages"
            :range-size="1"
            active-color="#ededed"
            @update:modelValue="loadPage"
          />
        </div>
      </div>
    </div>
  </div> -->
  <div class="create-training-modal" v-if="showModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div
          class="modal-header d-flex justify-content-between align-items-center"
        >
          <h5 class="my-0">Add Training Plan & Records</h5>
          <span type="button" @click.prevent="closePopUp()"
            ><i class="fa fa-times"></i
          ></span>
        </div>
        <div class="modal-body adjustable">
          <div class="form-group row mb-0">
            <div class="col-lg-12">
              <label class="col-form-label"
                >Required Knowledge Skills Abilities
                <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                v-model="createTestPlan.skills"
                type="text"
                maxlength="100"
                class="form-control"
                placeholder=""
              />
              <span class="d-block errorClass" v-if="errors[0] == true">
                Please enter the skills abilities
              </span>
            </div>
            <div class="col-lg-12">
              <label class="col-form-label">Details</label>
              <input
                v-model="createTestPlan.details"
                type="text"
                maxlength="500"
                class="form-control"
                placeholder=""
              />
            </div>
            <div class="col-lg-6">
              <label class="col-form-label"
                >Planned Start Date <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                v-model="createTestPlan.plannedStartDate"
                type="date"
                class="form-control"
                placeholder=""
              />
              <span class="d-block errorClass" v-if="errors[1] == true">
                Please enter planned start date
              </span>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label"
                >Planned End Date <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                v-model="createTestPlan.plannedEndDate"
                type="date"
                class="form-control"
                placeholder=""
              />
              <span class="d-block errorClass" v-if="errors[2] == true">
                Please enter planned end date
              </span>
              <span class="d-block errorClass" v-if="errors[3] == true">
                Planned end date cannot be prior to planned start date
              </span>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label"
                >Actual Start Date <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                v-model="createTestPlan.actualStartDate"
                type="date"
                class="form-control"
                placeholder=""
              />
              <span class="d-block errorClass" v-if="errors[4] == true">
                Please enter actual start date
              </span>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label"
                >Actual End Date <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                v-model="createTestPlan.actualEndDate"
                type="date"
                class="form-control"
                placeholder=""
              />
              <span class="d-block errorClass" v-if="errors[5] == true">
                Please enter actual end date
              </span>
              <span class="d-block errorClass" v-if="errors[6] == true">
                Actual end date cannot be prior to actual start date
              </span>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label"
                >Method of Training <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <select
                v-model="createTestPlan.trainingMethod"
                class="form-control m-b"
                name="account"
              >
                <option value="Self">Self</option>
                <option value="Trainer Led">Trainer Led</option>
              </select>
              <span class="d-block errorClass" v-if="errors[7] == true">
                Please select the method of training
              </span>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label"
                >Trained by <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <select
                v-model="createTestPlan.trainedBy"
                class="form-control"
                placeholder="Select Training by"
                name="account"
                @change="trainerChange()"
              >
                <option value="" selected>-- Select Training by --</option>
                <option
                  v-for="user in userList"
                  :key="user.userId"
                  :value="user.userId"
                >
                  {{ user.name }}
                </option>
              </select>
              <span class="d-block errorClass" v-if="errors[8] == true">
                Please select the trained by user</span
              >
            </div>
            <div class="col-lg-6">
              <label class="col-form-label"> Designation </label>
              <Multiselect
                name="designation"
                valueProp="id"
                mode="multiple"
                placeholder="Select Designations"
                :options="designationList"
                :hideSelected="false"
                :closeOnSelect="false"
                class="multiselect-custom-theme"
                @change="listTrainingUser($event)"
                v-model="designation"
              >
                <template v-slot:option="{ option }">
                  {{ option.displayName }}
                </template>
              </Multiselect>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label">
                Users <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <Multiselect
                name="users"
                valueProp="userId"
                mode="multiple"
                placeholder="Select Users"
                :options="trainerList"
                :hideSelected="false"
                :closeOnSelect="false"
                class="multiselect-custom-theme"
                v-model="createTestPlan.userIds"
              >
                <template v-slot:option="{ option }">
                  {{ option.name }}
                </template>
              </Multiselect>
              <span class="d-block errorClass" v-if="errors[9] == true">
                Please select the user(s)</span
              >
            </div>
            <div class="col-lg-6">
              <label class="col-form-label"
                >Sign Date <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                v-model="signDate"
                type="date"
                class="form-control"
                placeholder=""
                :max="toDatePicker(dateToday)"
              />
              <span class="d-block errorClass" v-if="errors[10] == true">
                Please select a sign date
              </span>
              <span class="d-block errorClass" v-if="errors[13] == true">
                The sign date must be the same or after the actual end date
              </span>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label"
                >Email <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                v-model="createTestPlan.signerEmail"
                type="email"
                id="email"
                class="form-control"
                placeholder="Email"
                required=""
              />
              <span class="d-block errorClass" v-if="errors[11] == true">
                Please enter your email id
              </span>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label"
                >Password <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <div class="form-group position-relative">
                <input
                  :type="inputType"
                  v-model="signerPassword"
                  maxlength="20"
                  class="form-control"
                  placeholder="Digi-sign password"
                />
                <i
                  class="password-visible-block-training cursor-pointer position-absolute"
                  :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']"
                  @click.prevent="passwordVisible()"
                ></i>
                <span class="d-block errorClass" v-if="errors[12] == true">
                  Please enter your password
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label" for="reasons">Reason <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <select
                class="form-control"
                name="account"
                v-model="signerReasons"
                id="reasons"
              >
                <option value="" disabled selected>-Select Reason-</option>
                <option
                  v-for="reason in reasonList"
                  :key="reason.id"
                  :value="reason.reason"
                >
                  {{ reason.reason }}
                </option>
              </select>
              <input
                class="form-control mt-2"
                v-if="signerReasons === 'Others'"
                v-model="otherReason"
                type="text"
                maxlength="50"
                name="reason"
              />
              <span class="d-block errorClass" v-if="errors[14] == true"
                >Please enter a reason</span
              >
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button
            type="button"
            @click="clearCreatePopup()"
            class="btn btn-primary text-white btn-xs px-4 mr-3"
          >
            Clear
          </button>
          <button
            type="button"
            @click.prevent="createTrainingPlan()"
            class="btn btn-primary btn-xs px-4 mr-3"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./manageTrainingPlan"></script>
<style scoped>
.ref-label {
  font-size: 0.85em;
}

.signed-by {
  font-family: "Brush Script MT", cursive;
  font-size: 1.5em;
}

.training-table-view {
  display: inline-block;
  text-align: left;
  width: auto;
  color: gray;
  border-collapse: collapse;
  background-color: white;
  margin: 0 auto;
}

.training-table-view td,
th {
  text-align: left;
  border: 1px solid black;
  padding: 10px;
}

.icon-tableview {
  width: 12rem;
  height: 4rem;
  margin-right: 10px;
}

.heading-tableview h2 {
  text-align: center;
  flex: 1;
  font-weight: bold !important;
}

.date-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.date-search input {
  flex: 1;
  margin-right: 10px;
}

.date-search button {
  flex: 1;
  margin-right: 10px;
}

#cardtrainingBody {
  position: relative;
  height: 0px;
  overflow: hidden;
  transition: height 0.35s ease;
}

.plan-side-popup {
  position: absolute;
  text-align: left;
  right: 56px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(109px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.plan-side-popup > div {
  cursor: pointer;
  padding: 0.5rem;
}

.plan-side-popup > div:hover {
  background-color: #e4eaf9;
}

.training-plan thead tr th {
  text-wrap: balance !important;
  vertical-align: middle;
}

.training-plan thead tr th {
  background-color: hsl(0, 0%, 100%) !important;
}
.training-plan tr td:nth-child(2),
.training-plan tr td:nth-child(3) {
  max-width: 40ch;
  word-wrap: break-word;
  white-space: break-spaces;
}
.create-training-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.adjustable {
  height: 400px;
  overflow-y: scroll;
}
.scrollable-training {
  overflow-y: auto;
  height: calc(100svh - calc(48px + 60.727px));
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.imp {
  color: #ff000094;
  font-size: 7px;
}

.errorClass {
  color: red;
  font-size: 13px;
}
.filter-block .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.filter-block:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.more {
  font-size: 11px;
  color: #618bd7;
  cursor: pointer;
}

.less {
  font-size: 11px;
  color: #618bd7;
  cursor: pointer;
}
.date-range {
  white-space: pre-line;
}
.password-visible-block-training {
  right: 11px;
  top: 43px;
}
.cursor-pointer {
  cursor: pointer;
}
.limit-word {
  max-width: 70ch;
  white-space: break-spaces;
}
</style>