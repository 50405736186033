/* eslint-disable */
const state = () => ({
	idToken:localStorage.getItem("idtoken"),
	organizationId:localStorage.getItem("organizationId"),
    authorize:localStorage.getItem("auth_ref"),
	showNavbar: true,
	showSideBar: localStorage.getItem("showSideBar"),
	showDocLog:false,
	showUserlog : false,
	showUserToken : '',
	showApprovalLog:false,
	showUserEmail : localStorage.getItem("showUserEmail"),
	Isexternaluser:localStorage.getItem("isexternaluser"),
	prvspath:"",
});

const getters = {
	getNavbarstatus(state: { showNavbar: any }) {
		 return state.showNavbar;
         
	},
	getIsexternaluser(state: { Isexternaluser: any }) {
		return state.Isexternaluser;
		
   },
	getSideNavbarstatus(state: { showSideBar: any }) {
		return state.showSideBar;
	},
    getAuthorize(state: { authorize: boolean }) {
		return state.authorize;
	},
	getToken(state: { idToken: any }) {
		return state.idToken;
	},
	getOrganisationID(state: { organizationId: any }) {
		return state.organizationId;
	},
	getDocLog(state: { showDocLog: any }) {
		return state.showDocLog;
	},
	getUserLog(state: { showUserlog: any}){
		return state.showUserlog;
	},
	getUserToken(state: { showUserToken: any}){
		return state.showUserToken;
	},
	getApprovalLog(state: { showApprovalLog: any }) {
		return state.showApprovalLog;
	},
	getUserEmail(state: { showUserEmail: any }) {
		return state.showUserEmail;
	},
	getprvspath(state: { prvspath: any }) {
		return state.prvspath;
	},
};

const actions = {
	async setNavbar({ commit }: any, data: any) {
		commit("setNavbar", data);
	},
	async setIsexternaluser({ commit }: any, data: any) {
		commit("setIsexternaluser", data);
	},
	async setSideNavbar({ commit }: any, data: any) {
		commit("setSideNavbar", data);
	},
    async setAuthorize({ commit }: any, data: boolean) {
		commit("setAuthorize", data);
	},
	async setOrganizationId({ commit }: any, data: any) {
		commit("setOrganizationId", data);
	},
	async setToken({ commit }: any, data: any) {
		commit("setToken", data);
	},
	async setDocLog({ commit }: any, data: any) {
		commit("setDocLog", data);
	},
	async setUserLog({ commit }: any, data: any) {
		commit("setUserLog", data);
	},
	async setUserToken({ commit }: any, data: any) {
		commit("setUserToken", data);
	},
	async setApprovalLog({ commit }: any, data: any) {
		commit("setApprovalLog", data);
	},
	async setUserEmail({ commit }: any, data: any) {
		commit("setUserEmail", data);
	},
	async setprvspath({ commit }: any, data: any) {
		commit("setprvspath", data);
	}
};

const mutations = {
	setNavbar(state: { showNavbar: any }, shownavstatus: any) {
		
		state.showNavbar = shownavstatus;
		localStorage.setItem("showNavbar", shownavstatus);
		// state.showNavbar = shownavstatus;
		state.showNavbar = shownavstatus;
	},
	setIsexternaluser(state: { Isexternaluser: any }, showIsexternaluser: any) {
		state.Isexternaluser = showIsexternaluser;
		localStorage.setItem("isexternaluser", showIsexternaluser);
	},
	setSideNavbar(state: { showSideBar: any }, showsidenavstatus: any) {
		localStorage.setItem("showSideBar", showsidenavstatus);
		state.showSideBar = showsidenavstatus;
	},
    setAuthorize(state: { authorize: boolean }, authorizePage: any) {
		state.authorize = authorizePage;
		localStorage.setItem("auth_ref", authorizePage);
	},
	setToken(state: { idToken: any }, idTokenLog: any) {
		state.idToken = idTokenLog;
		localStorage.setItem("idtoken", idTokenLog);
	},
	setOrganizationId(state: { organizationId: any }, orgid: any) {
		state.organizationId = orgid;
		localStorage.setItem("organizationId", orgid);
	},
	setDocLog(state: { showDocLog: any }, doclog: any) {
		state.showDocLog = doclog;
		localStorage.setItem("showDocLog",doclog);
	},
	setUserLog(state: { showUserlog: any }, userlog: any) {
		state.showUserlog = userlog;
		localStorage.setItem("showUserLog",userlog);
	},
	setUserToken(state: { showUserToken: any }, usertoken: any) {
		state.showUserToken = usertoken;
		localStorage.setItem("showUserToken",usertoken);
	},
	setApprovalLog(state: { showApprovalLog: any }, doclog: any) {
		state.showApprovalLog = doclog;
		localStorage.setItem("showApprovalLog",doclog);
	},	
	setUserEmail(state: { showUserEmail: any }, email: any) {
		state.showUserEmail = email;
		localStorage.setItem("showUserEmail",email.toLowerCase());
	},
	setprvspath(state: { prvspath: any }, prvspath: any) {
		state.prvspath = prvspath;
		localStorage.setItem("prvspath",prvspath);
	},

};

export default {
	state,
    getters,
    actions,
    mutations
};