<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0 align-self-center">Manage Training Plan & Record</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i
        class="fa fa-times close cursor-pointer"
        @click="goBack"
      ></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="tabs-container col-md-12">
    <ul class="nav nav-tabs" role="tablist">
  <li>
    <a class="nav-link active" data-toggle="tab" href="#tab-1" @click="handleTabClick('myTrainingPlan')">
      My Training Plan
    </a>
  </li>
  <li>
    <a class="nav-link" data-toggle="tab" href="#tab-2" @click="handleTabClick('approveTrainingPlan')">
      Training Plan for Approval
    </a>
  </li>
  <li>
    <a class="nav-link" data-toggle="tab" href="#tab-3" @click="handleTabClick('addTrainingPlan')">
      Assign Training Plan
    </a>
  </li>
</ul>

      <div class="tab-content background-white">
        <div role="tabpanel" id="tab-1" class="tab-pane active" :key="1">
          <myTrainingPlan
            ref="myTrainingPlanRef"
            :refreshFlag="refreshFlags.myTrainingPlan"
          />
        </div>
        <div role="tabpanel" id="tab-2" class="tab-pane">
          <approveTrainingPlan
          ref="approveTrainingPlanRef"
            :refreshFlag="refreshFlags.approveTrainingPlan" />
        </div>
        <div role="tabpanel" id="tab-3" class="tab-pane">
          <addTrainingPlan ref="addTrainingPlanRef"
            :refreshFlag="refreshFlags.addTrainingPlan"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./trainingPlan.js">
</script>
    
<style>
.background-white {
  background-color: hsl(0, 0%, 100%);
}
</style>