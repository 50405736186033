<template>
   <div class="margin-left-20 mt-5" v-if="$store.getters.getFromPage!='doclogin' && $store.getters.getFromPage!='approvallogin'">
    <span class="text-right"
      ><a href="/"><i class="fa fa-angle-left"> </i> Back to login</a>
    </span>
  </div>
  <div
    class="tablebox-design middlebox-design  d-flex flex-column justify-content-center text-center resetscreen-design p-3"
  > 
    <div>
      <div class="text-design">Reset Your Password</div>
      <div class="text-two-design">
        Please enter your email address. We'll send you a link to reset your
        password.
      </div>
    </div>
    <form class="m-t" role="form" action="index.html">
      <div class="form-group">
        <input
          type="email"
          class="form-control"
          placeholder="Account Email"
          v-model="check.emailId"
          required=""
        />
      </div>
      <div class="msg">
        <span class="errormsg" v-if="errors[0] == false"
          >Please enter a valid email address.</span
        >
      </div>
      <button
        type="submit"
        class="btn btn-primary block full-width m-b"
        @click.prevent="CheckEmailId()"
      >
        Submit
      </button>
    </form>
    <div class="small-text2">
      ** If you do not receive this mail, Please contact us.
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";

export default {
  name: "CheckEmail",
  data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      accountBaseUrl: `${process.env.VUE_APP_Service_URL}account-core/user/`,
      check: {
        emailId: "",
      },
      username: "",
      firstname: "",
      lastname: "",
      data: [],
      validations: [false],
      errors: [],
    };
  },
  async mounted() {
    await this.Disablemenu();
  },
  async unmounted() {
    await this.Enablemenu();
  },

  methods: {
    async Disablemenu() {
      store.dispatch("setNavbar", false);
      const navbardefault = document.querySelector("#page-wrapper");
      navbardefault.style.width = "100%";
      navbardefault.style.paddingRight = "0px";
    },
    async Enablemenu() {
      store.dispatch("setNavbar", true);
    },
    async CheckEmailId() {
      await this.validate();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      } else {
        this.getAndSetProfileData();
      }
    },

    async getAndSetProfileData() {
      await axios
        .get(
          this.accountBaseUrl + "isuserfound?emailId=" + this.check.emailId,
          this.Headers
        )

        .then((res) => {
          this.data = res.data;
          if (res.data != null) {
            alert(
              "We have sent you a password reset link. Please check your email."
            );
            this.firstname = this.data.firstName;
            this.lastname = this.data.lastName;
            this.username = this.firstname + " " + this.lastname;
            this.emailId = this.data.emailIdPrimary;
            this.SendPassResetMail()
            if(store.getters.getFromPage!='doclogin' && store.getters.getFromPage!='approvallogin')
            {
              this.$router.push(`/`);
            }
            
          }
        })
        .catch((err) => {
          console.log(err);
          alert("No user found");
        });
    },

    async SendPassResetMail() {
      await axios
        .post(this.accountBaseUrl + "forgotpasswordemail", {
          emailId: this.check.emailId,
          username: this.username,
        })

        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async validate() {
      this.ValidatefirstEmail(this.check.emailId);
    },
    async ValidatefirstEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        this.validations[0] = true;
      } else {
        this.validations[0] = false;
      }
    },
  },
};
</script>

<style scoped>
.tablebox-design {
  width: 400px;
}
.middlebox-design {
  margin: 0 auto;
  height:calc(100dvh - (19.5px + 3rem))
}
.resetscreen-design {
  width: min(90%, 400px);
}
.text-design {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #145faf;
}
.text-two-design {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #676a6c;
}
.small-text2 {
  font-size: 80%;
  font-weight: 400;
}
.msg {
  margin-bottom: 10px;
}
.margin-left-20 {
  margin-left: calc(100% - 200px);
}
</style>
