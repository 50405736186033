<template>
<div class="row">
  <div class="col-12 col-md-12 right-side p-4 ">
      <div class="middle-box loginscreen py-0 px-3 d-flex flex-column justify-content-center">
        <h2 class="fw-normal text-left text-muted mb-4">Password reset successful. Kindly close this tab.</h2>
      </div>
  </div>
</div> 
</template>

<script>
import store from "../../store/index";
export default {
  beforeRouteLeave(to, from, next) {
    // Prevent navigating to other pages
    next(false);
  },
  async mounted() {
    await this.Disablemenu();
  },

  async unmounted() {
    await this.Enablemenu();
  },
  methods: {
    async Disablemenu() {
      store.dispatch("setNavbar", false);
      const navbardefault = document.querySelector("#page-wrapper");
      navbardefault.style.width = "100%";
      navbardefault.style.paddingRight = "0px";
    },
    async Enablemenu() {
      store.dispatch("setNavbar", true);
    },
  },
};
</script>

<style scoped>
.me-2 {
    margin-right: 0.5rem!important;
}

.btn-primary:hover {
background-color: #17A2FD !important;
border-color: #17A2FD !important;
}

.btn-primary{
    background-color: #7B8CEA !important;
    border-color: #7B8CEA !important;
}
.full-width{
  width: 100%!important;
}
.middle-box {
    max-width: 400px;
    z-index: 100;
    margin: 0 auto;
}
.left-side
{
  height:100vh;
  color:#ffffff;
  background: linear-gradient(180deg, #7B8CEA 0%, #16A3FE 100%);
}
.sign-up-btn{
  background-color:#7B8CEA;
  border:none;
  border-radius:5px;
  text-transform: uppercase;
  color:#ffffff;
  font-size:1rem;
}
.right-side{
  height:100vh;
  overflow:hidden
}
.loginscreen.middle-box{
height:100%;
}
.condition-message{
  font-size:12px !important;
}
.other-block{
  position: relative;
}
.other-block::after,
.other-block::before{
  content:"";
  position: absolute;
  display:block;
  width:45%;
  background-color:#949494;
  height:1px;
  top:50%;
}
.other-block::after{
  right:0;
}
.middle-box a{
  display: block;
  text-align: right;
  text-decoration: none;
}
.mail-block{
  display:grid;
  grid-template-columns:repeat(12,1fr);
  grid-template-rows:1fr;
  grid-gap:10px;
}
.mail-block__google{
  grid-column: 1/7;
}
.mail-block__microsoft{
  grid-column:7/13;
}
.mail-block >*{
  background: #FFFFFF;
  border: 1px solid #C6C6C6;
  box-sizing: border-box;
  border-radius: 5px;
  color:#6c6c6c;
  min-width:max-content
}
.mail-block>*>img{
  max-width:100%;
  width:14px;
}
.error-message{
  color:#ff0000;
}
</style>
