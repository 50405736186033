/* eslint-disable */
import axios from "axios";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import store from "../../store/index";

export default {
  name: "documentTypeList",
  components: {
    VPagination,
  },
  data() {
    return {
      designationPrivileges: "",
      createdoctypepopup: false,
      showDoctype: false,
      baseUrl: `${process.env.VUE_APP_Service_URL}document/bookmark/`,
      Url: `${process.env.VUE_APP_Service_URL}document/document/`,
      mainapi: process.env.VUE_APP_Service_URL,
      currentPage: 1,
      pageindex: 1,
      pagesize: 10,
      totalPage: 1,
      SortProperty: "",
      SortOrder: false,
      SortOrderInt: 0,
      doctypval:"",
      doctypelist: "",
      docTypeId: "",
     
      search: {
        documenttype: "",
      },
      BookmarkUpdateCommnand: {
        bookmarkName: "",
        doctypeId: "",
        sortOrder: 0,
      },
      BookmarkInsertCommand: {
        bookmarkName: "",
        doctypeId: "",
        sortOrder: 0,
      },
      doctype: {
        documentType: "",
        // organisationId: "",
        // active: "",
      },
      idtoken: "",

      head: "",
      bookmarklist: "",
      bookmark: [
        {
          bookmarkname: "",
          order: "",
        },
      ],
      showsave: false,
      savebtn: false,
      retrievebox: "",
      bookmarkid: "",
      newBookmark: "",
      showInsertSpan: false,
      errors: [
        true,true,
      ],
      errorMessage: ["",""],
    };
  },
  async mounted() {
    this.designationPrivileges = store.getters.getRolesprivilegeData;
    this.idtoken = store.getters.getToken;
    this.head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };

    await this.getsearchdata();
  },
  methods: {
    addbookmark() {
      const container = document.getElementById("tablemaxheight");
      container.scrollTop = container.scrollHeight;
      this.showInsertSpan = true;
      //savebtn =true;
      //showsave =true;
    },
    savebookmark() {},
    async insertBookmark(index, bookmark) {
      if (bookmark.trim() != "") {
        this.BookmarkInsertCommand = {
          bookmarkName: bookmark.trim(),
          doctypeId: this.docTypeId,
          sortOrder: index,
        };
        this.CreateAsync();
      } else {
        alert("Please enter bookmark name");
      }
    },
    async clearBookmark() {
      this.newBookmark = "";
      this.showInsertSpan = false;
      await this.getbookmarkdata(this.docTypeId);
    },
    deletebookmark(index, bookmarkid) {
      this.deleteBookmark(bookmarkid);
      this.bookmarklist.splice(index, 1);
    },
    openModelCreatedoctype(id) {
     
      this.createdoctypepopup = true;
      this.docTypeId = id;
      this.getbookmarkdata(id);
    },
    openModelbookmark(bkid, indx) {
      this.bookmarkid = bkid;

      this.retrievebox = bkid;
      this.createdoctypepopup = true;
      // this.docTypeId = bkid;
      //  this.getbookmarkdata(id);
    },
    async closebutton() {
      this.retrievebox = "";
      console.log(this.docTypeId);
      await this.getbookmarkdata(this.docTypeId);
      await this.getsearchdata();
    },
    async createDoctypepopup(value) {
     // document.body.style.overflowY = "hidden"
      this.doctype.documentType="" 
        this.showDoctype = true;  
       
    },
    async onclosedpopup(value) {
      this.errors[0] = true;
    //  document.body.style.overflowY = "auto"
      if (value == "createdoctype") {
        this.showDoctype = false;
      }
    },
    closeModelCreateFolder() {
      this.retrievebox = "";
      this.showInsertSpan = false;
      this.createdoctypepopup = false;
      this.bookmarklist = [
        {
          bookmarkname: "",
        },
      ];
      this.newBookmark = "";
    },

    async OnSearchButtonClicked() {
      this.pageindex = 1;
      this.currentPage = 1;
      await this.getsearchdata();
    },

    async GoToEditBookmark(id) {
      console.log("id", id);
      this.$router.push("./editproject/" + id);
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.pageindex = page;
      this.getsearchdata();
    },

    async getsearchdata() {
      console.log(this.head);
      await axios
        .get(
          this.mainapi +
            `document/document/getdoctypelistwithbookmarks?searchvalue=${this.search.documenttype}&pageindex=${this.pageindex}&pagesize=${this.pagesize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrderInt}`,
          this.head
        )
        .then((res) => {
          console.log(" get document type", res);
          this.doctypelist = res.data.entities;
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getbookmarkdata(id) {
      console.log("hh", id);
      console.log(this.head);
      await axios
        .get(`${this.baseUrl}getbookmarksbydoctype?id=` + id, this.head)
        .then((res) => {
          console.log(" get bookmark", res);
          this.bookmarklist = res.data;
          console.log("book", this.bookmarklist);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async deleteBookmark(id) {
      let idtoken = store.getters.getToken;
      if (confirm("Are you sure you want to delete this bookmark?")) {
        console.log("header :", idtoken);

        await axios
          .delete(`${this.baseUrl}deletebookmarkinorder?Id=` + id, {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log("delete bookmark", res);
            alert("Bookmark deleted successfully");
          })
          .catch((err) => {
            console.log("error in deleting bookmark", err);
          });
      }
      await this.getbookmarkdata(this.docTypeId);
      await this.getsearchdata();
    },
    async UpdateAsync(bookr, indx) {
      this.BookmarkUpdateCommnand.bookmarkName =this.bookmarklist[indx].bookmarkName.trim()
        this.BookmarkUpdateCommnand.doctypeId = bookr.doctypeId
        this.BookmarkUpdateCommnand.sortOrder = indx + 1
        await axios
          .put(
            `${this.mainapi}document/bookmark/updatebookmark?id=` + bookr.id,
            this.BookmarkUpdateCommnand,
            this.head
          )
          .then((res) => {
            alert("Bookmark details updated successfully");
            //this.getbookmarkdata(this.docTypeId);
            this.getsearchdata();
            this.getbookmarkdata(this.docTypeId);
            this.retrievebox = "";
          })
          .catch((err) => {
            if (err.response.data.errors.DomainValidations && err.response.data.errors.DomainValidations[0]) {
              alert(err.response.data.errors.DomainValidations[0]);
            } else if (err.response.data.errors.BookmarkName[0]){
              alert("Bookmark name only accepts characters, digits, and underscores (_)");
            }
            });
    },
    trim(value) {
      return value.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' ');
    },
    async CreateAsync() {
      // alert("INserdata"+this.BookmarkInsertCommand)
      await axios
        .post(
          `${this.mainapi}document/bookmark/createbookmark`,
          this.BookmarkInsertCommand,
          this.head
        )
        .then((res) => {
          this.showInsertSpan = false;
          this.newBookmark = "";
          alert("Bookmark details added successfully");
          this.getbookmarkdata(this.docTypeId);
          this.getsearchdata();
          this.retrievebox = "";
        })
        .catch((err) => {
          if (err.response.data.errors.DomainValidations && err.response.data.errors.DomainValidations[0]) {
            alert(err.response.data.errors.DomainValidations[0]);
          } else if (err.response.data.errors.BookmarkName[0]){
            alert("Bookmark name only accepts characters, digits, and underscores (_)");
          }
          });
    },
    async EditDocType(id,val)
    {
          if(val =='doctype')
          {
              this.doctypval ='doctype';
              this.docTypeId = id;
          }
    },
    async closebuttonpop() {
      this.retrievebox = "";
      console.log("Close button clicked")
       this.getsearchdata();
    },
    async inlineClose(){
      this.doctypval="";
      this.errorMessage[1]=""
      this.getsearchdata();
    },
    async updatebutton(id,type) {
      // alert("DOC ID"+organisationId);
      // alert("DOC ID"+active);
     var confirmed = confirm("Are you sure you want to update the details?");
      if (confirmed == true)
      {
        // alert(type);
        // this.doctype.active=active;
        // this.doctype.organisationId =organisationId

        this.doctype.documentType = type.replace(/ +/g, ' ').trim();
        this.doctype.documentType = this.trim(this.doctype.documentType);
        console.log( "Doc type",this.doctype);
       
        //this.doctype.documentType=this.trim(type)
        // if ( this.doctype.documentType == "" ||  this.doctype.documentType == null) {
        //   alert("Enter a valid document type");
        // }
        // else {
          //https://dmsdev.datamatica.uk/v1/document/document/updatetype?id=4e936122-cbed-408b-a0b0-6069ddf94d5b
          await axios
            .put(
              `${this.Url}updatetype?id=`+id,
              this.doctype,
              {
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
           
              console.log("result", res)
              this.getsearchdata();
              alert("Updated successfully");
              this.errors[1] = true;
              this.doctypval="";
              // this.closebuttonpop();
            })
            .catch((err) => {
             
              this.errors[1] = false;
              console.log(err);
              if(err.response.data.detail)
              {
                this.errorMessage[1]=err.response.data.detail;
              }
              else if(err.response.data.errors.DocumentType[0] && err.response.data.errors.DocumentType)
              {
                this.errorMessage[1]=err.response.data.errors.DocumentType[0];
              }
              else{
                alert('something went wrong !');
              }
             
            });
        //}
      }
    },
    async deleteDoctype(id)
    {
      console.log(id);
      if (confirm("Are you sure you want to delete this Document Type?")) {
        await axios
          .delete(this.Url + "deletetype?typeId=" + id, {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            this.getsearchdata();
            alert("Document Type Deleted");
          })
          .catch((err) => {
            console.log(err);
            if(err.response.data.detail)
            {
              alert(err.response.data.detail);
            }
            else{
              alert("Somthing Went Wrong");
            }
           
          });
        this.pageindex = 1;
        this.currentPage = 1;
       // this.ListUsers();
      }
    },
  
   
    async CreateDoctypeFunction() {
      this.doctype.documentType.replace(/ +/g, ' ').trim();
      this.doctype.documentType=this.trim(this.doctype.documentType);
       
          await axios
            .post(
              `${this.Url}createtype`,
              this.doctype,
              {
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
           
              console.log("result", res)
              this.getsearchdata();
              alert("Document Type added successfully");
              this.doctype.documentType="";
              this.onclosedpopup('createdoctype');
            })
            .catch((err) => {
              console.log(err);
              this.errors[0] = false;
              if(err.response.data.detail)
              {
              
                this.errorMessage[0]=err.response.data.detail;
                
              }
              else if(err.response.data.errors.DocumentType[0] && err.response.data.errors.DocumentType)
              {
               
                this.errorMessage[0]=err.response.data.errors.DocumentType[0];
               
              }
              else{
                alert('something went wrong !');
              }
             
             
            });
        //}
      
    },
    goBack() {
      this.$router.push(`/administration`);
    }
  },
};
