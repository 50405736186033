<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Document Templates</h2>
    </div>
    <div class="col-lg-2">
      <i class="fa fa-times close my-2" @click="$router.go(-1)"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 maincontents py-3">
            <div
              class="ibox ibox-content-edit-toolbar d-flex justify-content-between"
              @click.prevent="columnselected = false"
            >
              <h4>{{ formname }}</h4>
              <div class="text-right d-flex">
                <div class="position-relative mr-2">
                  <img
                    class="cursor-pointer me-3"
                    src="../../assets/save_fd.svg"
                    alt="Save template"
                    @click.prevent="opensavemodal()"
                  />
                  <div class="tool-tip">Save Template</div>
                </div>
                <!-- <div  class="position-relative mr-2" >
                 

                  <img
                    class="cursor-pointer   me-3"
                    src="../../assets/view_temp_fd.svg"
                    alt="Add Table"
                    @click.prevent="viewtemplatefun()"
                  />
                  <div class="tool-tip">View Template</div>
                </div> -->

                <!-- view template -->
                <div class="position-relative">
                  <i
                    class="fa fa-table iconsize"
                    aria-hidden="true"
                    @click.prevent="opentablemodal()"
                  ></i>
                  <div class="tool-tip">Add Table</div>
                </div>
              </div>
            </div>
            <div class="maincontainer">
              <div>
                <!-- {{ tables }} -->
                <!-- {{ tabletemplatelist }} -->
                <table
                  v-for="(
                    tabledatasub, subindex
                  ) in tabletemplatelist.tableList"
                  :key="subindex"
                  class="table table-striped"
                >
                  <thead>
                    <tr>
                      <th
                        class="curserpont"
                        @click.prevent="edittable(subindex)"
                        v-for="tableheadders in tabledatasub.tableContents"
                        :key="tableheadders"
                        scope="col"
                      >
                        {{ tableheadders }}
                      </th>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click.prevent="addrows(subindex)"
                      >
                        Add rows
                      </button>
                      <i
                        class="fa fa-trash deleteicon ml-2"
                        @click.prevent="deletetable(subindex)"
                        aria-hidden="true"
                      ></i>
                    </tr>
                  </thead>
                  <!-- {{ tabletemplateobj.TableList }} -->
                  <tbody>
                    <tr
                      v-for="(
                        tabledatavalues, outerIndex
                      ) in tabledatasub.tableData"
                      :key="outerIndex"
                    >
                      <th
                        v-for="(
                          tableheadders, innerIndex
                        ) in tabledatasub.tableContents"
                        :key="tableheadders"
                      >
                        <!-- {{ tabletemplatelist.tableList }} -->
                        <span v-if="innerIndex == '0'">
                          <textarea
                            class="form-control"
                            v-model="
                              tabletemplatelist.tableList[subindex].tableData[
                                outerIndex
                              ]
                            "
                            id="exampleFormControlTextarea1"
                            rows="2"
                          ></textarea>
                        </span>
                      </th>
                      <th>
                        <i
                          class="fa fa-trash deleteicon"
                          @click.prevent="deleterow(subindex, outerIndex)"
                          aria-hidden="true"
                        ></i>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- test pdf ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal starts -->
  <!-- modal starts -->
  <teleport to="#app">
    <div class="add_page_modal_wrapper" v-if="createtablemodal">
      <div class="add_page_modal__containers">
        <div
          class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
        >
          <h4 class="my-0">Add Table</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closemodall()"></i>
          </a>
        </div>
        <div class="row my-4 mx-auto">
          <div class="col-lg-12 text-center py-3">
            <div class="form-group textgroup">
              <label class="float-left" for="optionscheckbox"
                >Table Header</label
              >
              <div class="optgroup col-lg-12">
                <select v-model="tabledata.tableHeader" class="form-select">
                  <option value="AcceptanceTable">Acceptance Table</option>
                  <option value="CheckListTable">CheckList Table</option>
                  <option value="SignatureTable">Signature Table</option>
                  <option value="ExceptionTable">Exception Table</option>
                </select>
              </div>
            </div>
            <div class="form-group textgroup">
              <label class="float-left" for="optionscheckbox"
                >Add column name</label
              >
              <div class="optgroup col-lg-12">
                <input
                  type="text"
                  class="form-control opttext"
                  v-model="columnnames"
                  id="optionscheckbox"
                  placeholder=""
                />
                <button
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="addcolumnnames()"
                >
                  Add
                </button>
              </div>
            </div>
            <div class="form-group textgroup">
              <label class="float-left" for="optionslistcheckbox"
                >Column Headers</label
              >
              <div
                multiple
                class="horizondalscroll optionboxdiv"
                id="exampleFormControlSelect2"
              >
                <div
                  class="d-flex columsarraydiv"
                  v-for="(options, optionsindex) in columnnamesarray"
                  :key="options"
                  disabled
                >
                  {{ options }}
                  <i
                    class="fa fa-minus-circle removeopts ml-4"
                    @click.prevent="removeoptions(optionsindex)"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
            <div class="my-2 row">
              <div class="col-md-12 d-flex justify-content-end">
                <div v-if="edittablevar" class="position-relative mr-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click.prevent="updatetable()"
                  >
                    Update
                  </button>
                </div>
                <div v-if="!edittablevar" class="position-relative mr-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click.prevent="savetable()"
                  >
                    Save
                  </button>
                </div>
                <div class="position-relative">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click.prevent="closemodall()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- modal ends -->
  <!-- save modal starts -->
  <teleport to="#app">
    <div class="add_page_modal_wrapper" v-if="savetablemodal">
      <div class="add_page_modal__containers">
        <div
          class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
        >
          <h4 class="my-0">Save Template</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closesavemodall()"></i>
          </a>
        </div>
        <div class="row my-4 mx-auto">
          <div class="col-lg-12">
            <div class="form-group">
              <label for="optionscheckbox"
                >Document Type</label
              >
              <div class="optgroup">
                <select
                  v-model="reldtmp"
                  class="form-select"
                >
                  <option value="SOP">SOP</option>
                  <option value="Unit Test">Unit Testing</option>
                  <option value="Testing">Testing</option>
                  <option value="Code Review">Code Review</option>
                </select>
              </div>
                <span class="errorMsg" v-if="errorMsg">Please select any document type</span>
            </div>
            <div class="form-group">
              <label for="optionscheckbox"
                >Project Name</label
              >
              <div class="optgroup">
                <input
                  type="text"
                  class="form-control"
                  v-model="rlddoc"
                  id="optionscheckbox"
                  placeholder=""
                />
              </div>
                <span class="errorMsg" v-if="errorMsgNew">Please enter the project name</span>
            </div>

            <div class="my-4 row">
              <div class="col-md-12 d-flex justify-content-end">
                <div class="position-relative mr-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click.prevent="savetabletemplate()"
                  >
                    Save
                  </button>
                </div>
                <div class="position-relative">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click.prevent="closesavemodall()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- save modal ends -->
</template>

<script src="./doctemplatecreate.js"></script>
<style scoped>
/* @import "./style1.css";
@import "./modal.css"; */
.error-message {
  color: #ff0000;
}
.columsarraydiv {
  width: 100%;
}
.optionboxdiv {
  height: 100px;
  overflow: auto;
  border-width: 0.5px;
  border: solid;
  width: 100%;
  padding: 3px;
}
.removeopts:hover {
  color: red;
}
.deleteicon:hover {
  color: #ff0000;
  cursor: pointer;
}
.curserpont {
  cursor: pointer;
}
.element-block {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.drag-component-block {
  border: 1px dashed #c2b6b6;
  font-size: 20px;
  color: #ada9a9;
  background-color: #ffffff;
}
.multiselect-theme {
  --ms-radius: 0;
  --ms-py: 0.2rem;
  --ms-option-font-size: 0.8rem;
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
}
.form-control {
  font-size: 0.8rem;
}
.activeBackground {
  background-color: #dddddd;
}
.bgblue {
  background-color: #f0f0ff;
}
.max-height-500 {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.side-popup {
  text-align: left;
  left: 14px;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  -moz-box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  -webkit-box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.side-popup > *:is(:hover, :focus) {
  background-color: #e4eaf9;
}

.expand-delete-block:is(:hover, :focus) {
  border-radius: 50%;
  background-color: #dddddd;
}

.max-height-0 {
  max-height: 0;
}

.max-height-30 {
  max-height: 300px;
}

.template-list li {
  overflow: hidden;
  transition: max-height 0.2s ease-out, padding-block 0.2s ease-out;
}

.add_page_modal_wrapper,
.modal__wrapper__component {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  height: 100pc;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}

.add_page_modal__containers {
  width: min(600px, 90%);
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    position: fixed;
    top: 130px;
}

.component__modal__containers {
  width: min(500px, 90%);
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}

.maincontainer {
  min-height: 500px;
  background-color: #f3f6f9;
  border: solid;
  border-width: 0.5px;
  border-color: #dee1e6;
}

.ibox-content-elements {
  background-color: #d5e9e7;
  padding: 0px;
  border-color: #dedede;
  border-image: none;
  border-radius: 0px;
  border-style: solid;
  border-width: 0px;
  padding-top: 3px;
  padding-bottom: 5px;
}

.ibox-modal-title {
  background-color: #d5e9e7;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px;
  color: inherit;
  padding: 10px 15px 10px 15px;
  min-height: 48px;
  position: relative;
  clear: both;
  width: unset;
}

.ibox-modal-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  color: #676a6c;
}

.ibox-content-elements h5 {
  font-size: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.updatebutton {
  width: 100%;
  padding: 20px;
}

.updatebtn {
  float: right;
}

.deletepagebtn {
  float: left;
}

.btncenter {
  margin: auto;
}

.groupcontents {
  width: 100%;
  padding: 20px;
}

.opttext {
  width: 80%;
}

.optgroup {
  display: flex;
  gap: 5px;
}

.addcontainer {
  width: 100%;
}

.outline-add {
  color: green;
  font-size: 14px;
  font-weight: bold;
  margin-left: 25px;
}

.outline-add:hover,
.outline-delete:hover {
  cursor: pointer;
}

.textflex {
  display: flex;
}

.outline-delete {
  color: red;
  font-size: 14px;
  font-weight: bold;
}

.fieldentry {
  background-color: #ffffff;
}

.textgroup {
  margin-top: 10px;
}

a {
  text-decoration: none !important;
}

.dropdown {
  height: 100%;
}

.maincontents {
  background-color: white;
  height: 100%;
}

.home {
  background-color: #f3f3f4;
  min-height: 100vh;
  display: flex;
}

.navbar-static-side {
  background-color: #e6e5ea;
}

.metismenu {
  width: 220px;
}

.text-right {
  text-align: end;
}

.ibox-content-edit-toolbar {
  position: relative;
  background-color: #fff;
  padding: 10px 20px 10px 20px;
  border-color: #dedede;
  border-image: none;
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
}

.ibox-content-edit {
  background-color: #ffffff;
  padding: 15px 20px 0px 20px;
  border-color: #80ced7;
  border-image: none;
  border-style: solid;
  border-width: 0px;
}

.icon-layout {
  float: right;
  line-height: 1.5;
}

.nav-label {
  font-weight: 600;
}

.checkboxcontainer {
  width: fit-content;
  min-width: 15%;
}
.rowcolmn {
  margin-top: 0px;
  display: flex;
  gap: 5px;
  margin: 10px;
}
.labelclass {
  text-align: left;
  font-size: 14px;
}
.errorMsg {
    color: hsl(0, 100%, 50%)
}

.dummydata:is(:active, :focus) {
  background-color: #cbd2eb;
}
.dummydataenter {
  background-color: #c5c5e2;
}
.columtable {
  border: 1px solid #c2b6b6;
  border-collapse: collapse;
  height: 30px;
  margin-bottom: 10px;
}

.btnwidth {
  min-width: 50px;
  background-color: #5f64a3;
  border-color: #5f64a3;
}

.btnwidth:hover {
  min-width: 50px;
  background-color: #5f64a3;
  border-color: #5f64a3;
}

.titleclass {
  color: #ffffff;
  background: #138a7d;
  z-index: 2000;
}
.radiolabelclass {
  width: 100%;
}
.add-block-title {
  color: #ffffff;
  background: #4c78c5;
}

.firstmodalheight {
  height: 38% !important;
}

.ibox-title {
  background-color: #eeedff;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px;
  color: inherit;
  padding: 10px 15px 10px 15px;
  min-height: 48px;
  position: relative;
  clear: both;
  width: unset;
}

.ibox-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  color: #676a6c;
}

.savebutton {
  font-size: 20px;
  color: black !important;
  margin-right: 16px !important;
}

.savebutton :hover {
  color: #7aa6ff !important;
}

.deletebutton {
  font-size: 20px;
  color: black !important;
  margin-right: 16px !important;
}

.deletebutton :hover {
  color: red !important;
}

.resizemodel {
  width: min(200px, 90%);
}
.iconsize {
  font-size: 26px;
  cursor: pointer;
  color: #898989;
}
.rowlabelgrid {
  margin-top: auto;
}
.addpage {
  width: 100% !important;
}
.gridbackground {
  background-color: #f0f0ff !important;
}
.addpagewrapper {
  margin-top: 30px !important;
}

.headerheight {
  min-height: 50px;
}

.tooldisplay {
  display: flex !important;
}

.versionbox {
  margin-top: 5px;
}

.pointer {
  visibility: hidden;
  width: max-content;
  background-color: #ec4758;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.ibox-title-elements {
  color: #ffffff !important;
  text-transform: uppercase;
  background-color: #15a798;
  border-color: #dedede;
  border-image: none;
  border-style: solid solid none;
  border-width: 0px;
  margin-bottom: 0;
  position: relative;
  clear: both;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 0px;
}

.ibox-title-elements h5 {
  font-size: 0.8rem !important;
}

.elements-tools-bg {
  width: 100px;
  background-color: #ffffff;
  color: #676a6c;
  border: 1px solid #e7eaec;
  cursor: pointer;
}

.element-tools-title {
  font-size: 0.75rem;
}

.elements-margins {
  margin-top: 3px;
  margin-bottom: 3px;
}

.elements-margins-large {
  margin: 0px -9px 0px 1px !important;
  padding: 0px 12px 0px 8px !important;
}

.close-link {
  color: white;
}

.modalcontents {
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}
.rangeclass {
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}
.dropdownbackground {
  background-color: #fff !important;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

img {
  max-width: 100%;
}

.navpadding {
  padding-bottom: 15px;
}

.elementsmenu {
  margin-left: 5px;
  width: 100px;
}

.elements_container {
  height: 460px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.elements_container {
  overflow-y: scroll; /* add a vertical scroll bar */
  scrollbar-width: thin; /* set the width of the scrollbar */
  scrollbar-color: #333 #fff; /* set the color of the scrollbar */
}

/* Customize the appearance of the scrollbar */
.rowcolmn {
  margin-top: 0px;
  display: flex;
  gap: 5px;
  margin: 10px;
}
.elements_container::-webkit-scrollbar {
  width: 8px;
}

.elements_container::-webkit-scrollbar-thumb {
  background-color: #15a798;
  border-radius: 8px;
}
.qrcodeframe {
  width: 150px;
}
.hiddenfile {
  display: none;
}
/* .twoitemflex{
  display:flex;
} */
.elements_container::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}
@media (max-width: 768px) {
  .md-block > * {
    display: block;
    margin: auto;
  }

  .elements-margins {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .elements-margins-large {
    margin: 0px !important;
    padding: 0px 5px 0px 8px !important;
  }

  .addcontainer {
    width: 79%;
  }

  .navbar.navbar-static-top {
    padding: 0 1rem !important;
  }
  iframe {
    position: absolute;
    float: left;
    clear: both;
    width: 100%;
    height: 350px;
    z-index: 0;
    left: no-repeat;
  }
  .navbar-header {
    align-self: center;
  }
  .flex-item {
    background-color: #f0f0ff !important;
  }
  .minimalize-styl-2 {
    margin: 0 !important;
  }

  .nav > li > a {
    font-size: 1.2em;
    padding: 1rem;
  }

  .nav > li > a i {
    font-size: 1em;
  }
  #wrapper {
    position: relative !important;
  }

  .pace-done #page-wrapper,
  .body.mini-navbar #page-wrapper {
    width: 100% !important;
  }

  .mini-navbar #mobilemenu > div {
    width: 0px;
    transition: width ease-in-out 400ms !important;
  }
}
.padding-inline {
  padding-inline: 1.875rem;
}
.radiohorizondalclass {
  display: flex;
  gap: 2;
  flex-wrap: wrap;
}
</style>
