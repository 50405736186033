/* eslint-disable */
import axios from "axios";

export default {
	name: "createProject",
    props: { projectid: String },
	data() {
		return {
            selectedprojectid: "",
            projectname: "",
            description: "",
            active: true,
            locked: true,
            mainapi: `${process.env.VUE_APP_Service_URL}`,
			
		};
	},
    async mounted() {
        this.loadproject();
    },
    methods: {
        async loadproject(){
            if(this.projectid != "0" && this.projectid != ""){
                await axios
                .get(
                    `${this.mainapi}configuration/project/get?id=${this.projectid}`,
                    {
                        headers: {
                            "Content-Type": "application/json",

                        },
                    }
                )
                .then((res) => {
                    console.log(" get patients", res);
                    this.selectedprojectid = res.data.id,
                    this.projectname = res.data.projectName,
                    this.description = res.data.description,
                    this.active = res.data.active,
                    this.locked = res.data.lock
                })
                .catch((err) => {
                    console.log(err);
                });

            }
        },
        async saveoredit(){
            if(this.projectid != "0" && this.projectid != ""){
                this.editproject();
            }
            else{
                this.addproject();

            }

        },
        async onclosed() {
            this.$router.push(`/listproject`);
          },
        async clear(){
            this.projectname = "",
            this.description = "",
            this.active = false,
            this.locked = false
        },
        async editproject(){
            await axios.put(
                `${this.mainapi}configuration/project/update?Id=${this.projectid}`,{
  
                    projectName: this.projectname,
                    description: this.description,
                    active: this.active,
                    lock: this.locked
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    console.log("successfully edited");
                    alert("Project Updated Successfully");
                    this.onclosed();
                })
                .catch((err) => {
                    console.log(err);
                });

        },
        async addproject(){
            await axios
        .post(
          `${this.mainapi}configuration/project/create`,
          {
            projectName: this.projectname,
            description: this.description,
            active: this.active,
            lock: this.locked
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
            console.log(res);
            alert("Project Created Successfully");
            this.onclosed();
        })
        .catch((err) => {

          console.log(err);
        });
        }
    }
}