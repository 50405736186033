<template>
  <div class="create-user-modal1">
    <div class="modal-content width-max">
      <div class="modal-header1 d-flex justify-content-between align-items-center">         
        <h5 class="">Share Folder</h5>
        <div
          type="button"
          class="close"
          @click="close()"
        >
          <i class="fa fa-times modal-close align-self-center"></i>
        </div>
      </div>
      <div class="modal-body">
        <h5 class="tag-title">Users</h5>
        <div class="height-scroll">
          <table class="table fixed-header">
            <thead>
              <tr>
                <th>
                  <input type="checkbox" id="selectall" @change="selectall()" />
                  Name
                </th>
                <th v-for="(per,index) in permissions" :key="index">
                  <input
                    type="checkbox"
                    :id="per"
                    @change="setviewpermissions()"
                  />
                  {{ per }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="options in userList" :key="options.id">
                <td>
                  <input
                    type="checkbox"
                    :value="options.username"
                    :id="options.id"
                    class="mr-2"
                    :checked="userobject[options.id].permissions.length!=0"
                    @change="setcontrols(options)"
                  />
                  <Span v-if="fromPage == 'Department' || fromPage == 'generalFiles'">{{
                    options.name
                  }}</Span>
                  <Span
                    v-if="
                      fromPage == 'ProjectSummary' || fromPage == 'DocumentList'
                    "
                    >{{ options.fullName }}</Span
                  >
                </td> 
             
                <td v-for="option in permissions" :key="option.id">
                  <input
                  v-if="userobject[options.id]"
                    type="checkbox"
                    :id="options.id+'permissions'"
                    :checked="userobject[options.id].permissions.length!=0"
                    @change="selectuserwithpermission(options.id)"
                  />
                  {{ option}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">        
          <div class="form-group w-100">
            <label class="mb-0" for="expiry-date"><b>Expiry Date</b></label>
            <input
              class="form-control"
              type="datetime-local"
              id="expiry-date"
               v-model="expiryDateInput.expireDate"
            />
          </div>
        
        <button
          type="button"
          class="btn btn-primary share-button py-1 px-4"
          @click="folderMultiSave()"
          :disabled="loadingBlock"
        >
          <span v-if="loadingBlock "
            ><img
                src="../../assets/img/loading.webp"
                width="20"
                class="loading-img mr-2"
                alt="Login buffering"
            />
            </span>
          <span v-else> Share</span>
         
        </button>
      </div>
    </div>
  </div>
</template>
  <script src="./externalfoldershare.js">
  </script>
  
  <style scoped>
@import "../../assets/css/style.css";
@import "../../assets/css/custom.css";
.create-user-modal1 {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.width-max {
  width: min(800px, 90%);
}
.modal-header1 {
  padding: 10px 29px;
  text-align: left;
  display: block;
  border-bottom: 1px solid #dee2e6;
}
.tree input {
  position: inherit !important;
}
.height-scroll {
  height: 250px;
  overflow-y: auto;
}
.fixed-header th {
  top: 0px;
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
}
.loading-img {
  mix-blend-mode: lighten;
}

.share-button[disabled="true"]{
    opacity:0.5;
    cursor: not-allowed;
}
</style>