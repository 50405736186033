<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0">Question Management</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i class="fa fa-times close cursor-pointer" @click="onclosed()"></i>
    </div>
  </div>

  <div class="input-group mb-2 mt-3">
    <input
      type="text"
      v-model="searchField.name"
      class="form-control"
      placeholder="Enter your question here..."
      aria-label="Admin Theme"
      aria-describedby="button-addon2"
    />
    <div class="input-group-append">
      <button
        class="btn btn-default py-0"
        type="button"
        id="button-addon2"
        @click="OnSearchButtonClicked"
      >
        <i class="fa fa-search text-success"></i>
      </button>
    </div>
  </div>
  <div class="ibox-title style_2">
    <div class="input-group">
      <h4 class="pl-2">Questions</h4>
      <div class="ibox-tools" style="top: 5px">
        <a
          href="#"
          class="mr-3 position-relative"
          data-toggle=""
          data-target=""
          v-if= "designationPrivileges.includes(`Departments Upload Question`)"
        >
          <input
            class="d-none"
            accept=".xls,.xlsx"
            id="InPutFile"
            type="file"
            @change="previewFiles"
          />
          <img
            src="../../assets/img/export_quest.png"
            alt="export_icon"
            @click="onFileChange()"
          />
          <div class="tool-tip">Upload question</div>
        </a>
        <a 
          href="#" 
          class="position-relative" 
          data-toggle="" data-target=""
          v-if= "designationPrivileges.includes(`Departments Create Question`)"
          ><img
            src="../../assets/img/create_quest.png"
            alt="export_icon"
            @click="GoToManageUser(0)"
          />
          <div class="tool-tip">Create Question</div>
        </a>
      </div>
    </div>
  </div>

  <div class="ibox-content">
    <div class="wrapper wrapper-content animated fadeInRight pt-0">
      <!-- <div class="ibox-content"> -->
      <div class="row">
        <div
          class="col-lg-12 px-0"
          v-for="question in questionlist"
          :key="question.Id"
        >
          <div class="vote-item">
            <div class="row">
              <div class="col-md-10">
                <a href="#" class="vote-title"> {{ question.question }}</a>
                <div class="vote-info">
                  <p>{{ question.answer }}</p>
                </div>
              </div>
              <div class="col-md-2">
                <div class="vote-icon">
                  <button class="btn btn-white btn-sm py-1" v-if= "designationPrivileges.includes(`Departments Edit Question`)">
                    <i
                      class="fa fa-pencil-square-o"
                      @click="GoToManageUser(question.id)"
                    ></i>
                  </button>
                  <button class="btn btn-white btn-sm py-1" v-if= "designationPrivileges.includes(`Departments Delete Question`)">
                    <i
                      class="fa fa-trash-o"
                      @click="DeleteClicked(question.id)"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 d-flex justify-content-md-end px-0">
        <v-pagination
          v-model="currentPage"
          :pages="totalPage"
          :range-size="1"
          active-color="#ededed"
          @update:modelValue="loadPage"
        />
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script src="./question.js">
</script>

<style scoped>
.submit_btn {
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.position-relative img {
  width: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.headingunderline {
  text-decoration-line: underline;
}
.title-class1 {
  background-color: #618bd7;
  color: #fff;
}
.errorClass {
  color: red;
  font-size: 13px;
}
.button-class {
  float: right;
}
.vote-title-qstn {
  display: block;
  color: inherit;
  font-size: 13px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 2px;
}
.vote-info-qstn {
  color: #686b6d;
  font-size: 12px;
  margin-left: 4px;
}
.vote-icon-qstn {
  text-align: right;
  font-size: 16px;
  color: #145faf;
  margin-top: 10px;
  display: block;
}
.vote-item-qstn {
  padding: 0px 0px;
  background: #ffffff;
  border-top: 1px solid #e7eaec;
}
.vote-actions-qstn {
  float: left;
  width: 30px;
  margin-right: 0px;
  margin-top: 6px;
  text-align: center;
  font-weight: 600;
}
</style>
