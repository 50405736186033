<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0 align-self-center">Manage Question</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i class="fa fa-times close cursor-pointer" @click="onClosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title style_2 px-3">
            <h5 class="mt-1">Manage Questions</h5>
          </div>
          <div class="ibox-content">
            <div class="form-group row">
              <div class="col-lg-12 my-2">
                <label class="col-form-label"
                  >Question <sup><i class="fa fa-asterisk imp"></i></sup>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="UserDto.question"
                  placeholder="Question"
                />
                <div class="errorClass" v-if="errors[0] == false">
                  Please add a question
                </div>
                <button
                  type="button"
                  class="btn btn-default py-0 mt-2"
                  @click.prevent="showOption"
                >
                  + Add
                </button>
                <div class="hr-line-dashed mb-0"></div>
              </div>

              <div class="col-lg-12" v-if="retrieve">
                <div class="optgroup mt-3">
                  <input
                    type="text"
                    class="form-control opttext"
                    v-model="UserDto.option"
                    placeholder="options"
                  />
                  <span class="errorClass" v-if="errors[1] == false">
                    Please add an option
                  </span>
                  <label class="ml-2 mt-2">
                    <input
                      type="checkbox"
                      v-model="checkoption"
                      value="UserDto.option"
                      @change="setOptionValue"
                    />
                    Set this as an answer<sup
                      ><i class="fa fa-asterisk required"></i
                    ></sup>
                    <div class="errorClass" v-if="errors[2] == false">
                      Please select answer
                    </div>
                  </label>
                </div>
                <button
                  type="button"
                  class="btn btn-default py-0 mt-2"
                  @click.prevent="saveOption"
                >
                  + Add
                </button>
                <div class="hr-line-dashed mb-0"></div>
              </div>

              <div class="col-lg-12 mt-3">
                <ol class="pl-3">
                  <li
                    class="mb-2"
                    v-for="(optionlist, index) in option"
                    :key="index"
                  >
                    <span
                      v-if="option.includes(UserDto.answer)"
                      class="opt-ans"
                      :class="{ 'text-green': optionlist == UserDto.answer }"
                    >
                      {{ optionlist }}</span
                    >
                    <span v-else class="opt-ans"> {{ optionlist }}</span>
                    <span
                      data-role="remove"
                      @click="deleteFromList(optionlist, index)"
                      ><i class="fa fa-times ml-1"></i
                    ></span>
                  </li>
                </ol>
              </div>
            </div>
            <div class="col-sm-12 text-right">
              <button
                class="btn btn-white btn-sm px-3 py-1 mr-2"
                @click.prevent="Clear"
              >
                Reset
              </button>
              <button
                v-if="showSave"
                class="btn btn-primary btn-sm px-3 py-1"
                @click.prevent="SaveButtonClicked"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store/index";
export default {
  name: "CreateQuestion",
  props: { documentid: String, id: String },
  data() {
    return {
      SaveButtonText: "",
      IsUpdate: false,
      retrieve: false,
      showSave: false,
      checkoption: false,
      showadd: false,
      showAnswer: false,
      duplicate: false,
      errors: [true, { option: [] }, true],
      Head: {},
      baseUrl: `${process.env.VUE_APP_Service_URL}document/documentquestion/`,
      CreateUpdateCommnand: {
        documentId: "",
        question: "",
        option: [],
        answer: "",
      },
      UserDto: {
        id: "",
        question: "",
        answer: "",
        option: [],
      },
      option: [],
    };
  },
  async mounted() {
    this.Head = {
      headers: {
        Authorization: "Bearer " + store.getters.getToken,
        "Content-Type": "application/json",
      },
    };
    this.SaveButtonText = "Save";
    this.IsUpdate = false;
    this.showadd = true;
    if (this.id != 0) {
      await this.GetAndSetData(this.id);
      this.SaveButtonText = "Update";
      this.IsUpdate = true;
      this.retrieve = true;
    }
  },
  methods: {
    showOption() {
      if (this.UserDto.question == "") {
        this.errors[0] = false;
      } else {
        this.retrieve = true;
        this.errors[0] = true;
        this.showadd = false;
      }
    },
    setOptionValue() {
      if (this.checkoption != true) {
        // alert("Option not selected");
        this.UserDto.answer = "";
      } else {
        if (this.checkoption == true) {
          if (this.UserDto.option == "") {
            alert("Option not selected");
            this.checkoption = false
          } else {
            const newexists = this.option.find(
              (optionlist) => optionlist === this.UserDto.option
            );
            if (!newexists) {
              this.UserDto.answer = this.UserDto.option;
              this.showSave = false;
            } else if (newexists == this.UserDto.option) {
              alert("Option already exist");
              this.checkoption = false;
            }
          }
        }
      }
    },
    deleteFromList(optionlist, index) {
      this.option.splice(index, 1);
      if (this.UserDto.answer == optionlist) {
        this.UserDto.answer = "";
      }
       if (this.option =="") {
        this.showSave = false;
      }
      this.checkoption = false;
    },
    Clear() {
      this.UserDto.question = "";
      this.UserDto.answer = "";
      this.UserDto.option = [];
      this.option = [];
      this.checkoption = false;
      this.errors[2] = true;
      this.retrieve = false;
      this.showSave = false;
    },
    async GetAndSetData(Id) {
      await axios
        .get(this.baseUrl + "getquestion?Id=" + Id, this.Head)
        .then((res) => {
          this.UserDto = res.data;
          this.option = this.UserDto.option;
          this.UserDto.option = "";
          this.showSave = true;
          this.showadd = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveOption() {
      let flag = true;
      if (this.UserDto.option == "" || this.UserDto.option == null) {
        alert("Please add an option");
      } else {
        const exists = this.option.find(
          (optionlist) => optionlist === this.UserDto.option
        );
        if (!exists) {
          if (flag) {
            this.option.push(this.UserDto.option);
            this.UserDto.option = [];
            this.showSave = true;
            if (this.checkoption == true) {
              this.checkoption = false;
            }
            if (this.UserDto.answer != "") {
              this.errors[2] = true;
            }
          }
        } else {
          alert("Option already exist");
          this.checkoption = false;
        }
      }
    },
    onClosed() {
      this.$router.push(`/question/` + this.documentid);
    },
    async SaveButtonClicked() {
      if (this.UserDto.answer == "") {
        this.errors[2] = false;
      } else {
        this.SetCommandAsync();
        if (this.id != 0) {
          if (this.UserDto.option != "") {
            alert("Option not added");
          } else await this.UpdateAsync();
        } else {
          if (this.UserDto.option != "") {
            alert("Option not added");
          } else await this.CreateAsync();
        }
      }
    },
    async UpdateAsync() {
      await axios
        .put(
          this.baseUrl + "update?Id=" + this.id,
          this.CreateUpdateCommnand,
          this.Head
        )
        .then((res) => {
          alert("Question updated successfully");
          this.$router.go(-1);
        })
        .catch((err) => {
          alert(err.response.data.errors.DomainValidations[0]);
        });
    },

    async CreateAsync() {
      await axios
        .post(this.baseUrl + "create", this.CreateUpdateCommnand, this.Head)
        .then((res) => {
          alert("Question created successfully");
          this.onClosed();
        })
        .catch((err) => {
          alert(err.response.data.errors.DomainValidations[0]);
        });
    },
    SetCommandAsync() {
      this.CreateUpdateCommnand.documentId = this.documentid;
      this.CreateUpdateCommnand.question = this.UserDto.question
        .replace(/\s+/g, " ")
        .trim();
      this.CreateUpdateCommnand.answer = this.UserDto.answer
        .replace(/\s+/g, " ")
        .trim();
      this.option = this.option.map((str) =>
        str
          .split(" ")
          .filter((word) => word !== "")
          .join(" ")
      );

      this.CreateUpdateCommnand.option = this.option;
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/style.css";

.fa-asterisk {
  color: #e56363 !important;
  font-size: 6px;
}

.errorClass {
  color: red;
  font-size: 15px;
}

.submit_btn {
  align-self: center;
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
.optgroup {
  display: flex;
  gap: 5px;
  width: 100%;
}
.opttext {
  width: 80%;
}
.text-green {
  color: #1ab394 !important;
}
</style>