<template>
    <div class="move-folder-block-container d-flex justify-content-center align-items-center">
        <div class="modal-dialog modal-width">
            <div class="modal-content">
                <div class="modal-header-move d-flex justify-content-between p-3">
                    <h5 class="my-0 align-self-center">Move Folder</h5>
                    <span type="button" class="align-self-center" data-dismiss="modal" @click.prevent="close()"
                        aria-hidden="true"><i class="fa fa-times modal-close align-self-center"></i>
                    </span>
                </div>
                <div class="modal-body height-400 border-0 px-4">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <label class="mb-0 mr-1" for="owner">Select Project</label><span
                                    aria-label="mandatory field" class="error-block">*</span>
                                <select id="owner" class="form-control" v-model="selectedProject" @change="onProjectchange($event)">
                                    <!-- <option value="All">
                                        All
                                    </option> -->
                                    <option v-for="project in projectlist" :key="project.id" :value="project.id">
                                        {{ project.projectName }}
                                    </option>
                                </select>
                                <span class="error-block" v-if="validations[0]">Please select a project</span>
                                <div class="tree-content overflow-y-auto mt-3">
                                    <ul class="tree pl-3">
                                        <li v-if="selectedfoldername">
                                            <input type="checkbox" :id="selectedfolderid"
                                                @change="backtorootfolder(selectedfolderid)" />
                                            <label :class="
                                                mainfolderbold == ''
                                                    ? 'tree_labels tree_label_bold'
                                                    : 'tree_labels'
                                            " @click="backtorootfolder(selectedfolderid)">{{ selectedfoldername }}
                                            </label>
                                            <TreeFoldermove projectId="" 
                                            :list="folderlist">
                                            </TreeFoldermove>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="d-flex justify-content-end">
                        <button @click="save()" type="button" class="btn btn-primary btn-xs px-4">Move</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script src="./move_projectfolder.js">

</script>
<style scoped>
@import "./tree.css";

.move-folder-block-container {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    overflow-y: hidden;
    z-index: 3000;
}

.modal-header-move {
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.modal-width {
    width: min(400px, 90%)
}

.overflow-y-auto {
    height: 300px;
    overflow-y: auto;
}

.height-400 {
    height: 400px;
    overflow-y: hidden;
}

.error-block {
    color: #ff0000;
}

.tree_label_bold {
    font-weight: bold;
}
</style>