/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import router from "@/router";
export default {
  data() {
    return {
      baseurl: `${process.env.VUE_APP_Service_URL}document/documentquestion/`,
      idtoken: "",
      showDesig: false,
      designationList: "",
      designationName:"",
      designationDesc:"",
      retrievebox: true,
      designationId: "",
      search: {
        examName: "",
        status: "",
        name: "",
      },
      currentPage: 1,
      pageindex: 1,
      pagesize: 10,
      totalPage: 1,
      SortProperty: "createdAt",
      SortOrder: 1,
      errors: [],
      validations: [],

      editableItem:[],
      designationPrivileges :[],

      
    };
  },

  async mounted() {
    this.designationPrivileges = store.getters.getRolesprivilegeData.split(',');
    this.idtoken = store.getters.getToken
    this.ListTest();
  },
  methods: {

    async OnSearchButtonClicked() {
      this.pageindex = 1;
      this.currentPage = 1;
      this.searchFilter();
    },
    updatename(value){      
      this.designationName=value;
      console.log("Name",this.designationName)
    },
    updatedesign(value){
      this.designationDesc=value;
      console.log("Desc",this.designationDesc)
    },
    async closebutton() {
      this.update={}
      this.retrievebox = true;
    },



    trim(value) {
      value = value.replace(
          /^\s+|\s+$/gm,
          ""
      );
      return value
    },
    async setfunctions() {
      if (this.search.status != '' || this.search.name != '') {
        this.searchFilter()
      }
      else this.ListTest()
    },
    async ListTest() {
      await axios
        .get(
          `${this.baseurl}listconfig?pageIndex=${this.pageindex}&PageSize=${this.pagesize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.configList = res.data.entities;
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
          console.log("Config list",this.configList)
        })
        .catch((err) => {
          console.log(err);
          this.designationList =[]
          alert("No data found!")
        })
    },
    async searchFilter() {
      await axios
        .get(
          `${this.baseurl}listconfig?pageIndex=${this.pageindex}&PageSize=${this.pagesize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}&examName=${this.search.examName}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.configList = res.data.entities;
          this.totalPage = parseInt(res.data.totalCount / this.pagesize);
          if (res.data.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
          console.log("Config list",this.configList)
        })
        .catch((err) => {
          console.log(err);
          this.designationList =[]
          alert("No data found!")
        })
    },
    SortSelected(sortProp, id) {
      console.log('SortPropChanged');
      const selectedElement = document.getElementById(id)
      const selectedElementID = document.getElementById(id).id
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }
          else selectedElement.classList.add("sortDesc")
        }
        else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 1 ? 0 : 1;
      this.ListTest();
    },
    
    async onclosed(value) {
      document.body.style.overflowY = "auto"
      if (value == "createDesig") {
        this.showDesig = false;
      }
    },
    async goToManageConfig(id){
      this.$router.push("./configure_exam/"+id)
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.pageindex = page;
      this.setfunctions();
    },
    async gotoReport(id){
     
      router.push(`/finalReport/${id}`);
    },
    async gotoConfiExam(id){
     
      router.push(`/configure_exam/${id}`);
    },
    goBack() {
      this.$router.push(`/training`);
    }
  },
};