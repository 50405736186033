/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import store from "../../store/index";
import mergefilepopup from "../mergefilepopup/mergefilepopup.vue"
// import moment from "moment";
// import { ref } from 'vue'
export default {
  components: {
    mergefilepopup
  },
  props: {
    projectId: {
      type: String,
      default: "",
    },
    idprop: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => [],
    },
    boldfolder: {
      type: String,
      default: "",
    },
    patid: {
      type: String,
      default: "",
    },
    openfolderlist: {
      type: String,
      default: "",
    },
    treeid: {
      type: String,
      default: "doc"
    },
    selectedlocation: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      folderbold: "",
      openfolder: "",
      currentProjectId: "",
      sourceType: "",
      showBlock: false,
      showPermBlock: false,
      moveBlock: false,
      popupid: "",
      folderswitch: {},
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      patientid: "",
      listdata: [],
      firstclassname: this.patid,
      folderbyid: "",
      showDetails: false,
      arrayfolders: [],
      subdeptfolder:[],
      deptBlockname: "",
      departments: {},
      foldertreelist: {},
      currentselectedfolder: "",
      showPermPopup: false,
      showProjectPermPopup: false,
      leftValue: 0,
      topValue: 0,
      movedFolder: "",
      documentlist: "",
    };
  },
  watch: {
    "$store.getters.getselectedFolder": function (val) {
      this.currentselectedfolder = val;
    },
    "$store.getters.getfolderreload": function (val) {
      this.currentselectedfolder = val;
      this.reloadallfolders();
    },
    "$store.getters.getselectedFoldermove": function (val) {
      this.currentselectedfolder = val;
      this.folderbold = val
      this.reloadallfolders();
    },
  },
  async mounted() {
    this.idtoken = store.getters.getToken;
    this.currentselectedfolder = this.folderbold;
    store.dispatch("selectFoldermove", "");
  },
  methods: {
    async getalldepartmentfolders(id, name,type) {
      await axios
        .get(
          `${this.baseurl}department/getsubdepartments?departmentid=${id}&PageSize=2000`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
            this.foldertreelist[id]=res.data.entities  
            this.getdocuments(id,name)
            if(type==='subDepartment'){
              this.foldertreelist[id] = this.foldertreelist[id].map(item => {
                return { ...item, type: 'subDepartment' };
              });
              
            }
           
            else{
              this.getallsubdepartmentfolders(id,name)
            }         
        })
        .catch((err) => {
          if(type === undefined){
            this.getdocuments(id,name)
            this.getallsubdepartmentfolders(id,name)            
          }
        });
        if (name) {
          store.dispatch("selectFoldermove", id);
  
        }
    },
    async getallprojectfolders(id, name) {
      if (this.folderswitch[id]) {
        this.folderswitch[id] = !this.folderswitch[id];
      }
      else {
        this.folderswitch[id] = true;
      }
      await axios
        .get(
          `${this.mainapi}document/folder/getprivilegedroots?storageId=${id}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.foldertreelist[id] = res.data;
          this.getdocuments(id, name)
        })
        .catch((err) => {
          console.log(err.response.data.messages);
        });
        if (name) {
          store.dispatch("selectFoldermove", id);
  
        }
    },
    async getdocuments(id, foldername) {
      
      this.documentlist = "";
      await axios
        .get(
          `${this.mainapi}document/folder/getprivilegedcontent?Id=${id}&fileType=pdf`, {

          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },

        })
        .then((res) => {
          this.documentlist = res.data;
          if (this.documentlist && this.documentlist.length > 0) {
            this.$emit("documentListNotEmpty", { folderId: id, folderName: foldername, isFilesExist: `true` });
          }
          else {
            this.$emit("documentListNotEmpty", { folderId: "id", folderName: "foldername", isFilesExist: `false` });
          }
        })
        .catch((err) => {
          console.log(err);
          if(err){
            this.$emit("documentListNotEmpty", { folderId: "id", folderName: "foldername", isFilesExist: `false` });
          }
        });
    },
    async handleDocumentListNotEmpty({ folderId, folderName, isFilesExist }){
      this.$emit("documentListNotEmpty", { folderId: folderId, folderName: folderName, isFilesExist: isFilesExist });
    },
    move(id) {
      this.moveBlock = !this.moveBlock
      this.movedFolder = id
    },
    async reloadallfolders() {
      await this.list.forEach(element => {
        if (this.folderswitch[element.id]) {
          this.refreshfolder(element.id, element.name)
        }
      });
    },
    async refreshfolder(id, name) {
      this.foldertreelist[name] = [];
      await axios
        .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${id}`, {

          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },

        })
        .then((res) => {
          this.foldertreelist[name] = res.data;
        })
        .catch((err) => {
          console.log(err.message)
        })
    },
    async getdeptfolder(id, name,type){
      if (this.folderswitch[id]) {
        this.folderswitch[id] = !this.folderswitch[id];
      }
      else {
        this.folderswitch[id] = true;
      }
      this.foldertreelist[id] = []
      if(type){
        if(type==='subDepartment')
        {
          await this.getalldepartmentfolders(id, name,type)
        }
        else{
          await this.getfolder(id, name,type);
        }
      }
      else{
          this.getalldepartmentfolders(id, name,type)
          this.getfolder(id, name,type);
      }
     
    },
    async getfolder(id, name) {
      await axios
        .get(`${this.mainapi}document/folder/getprivilegedchildren?Id=${id}`, {

          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },

        })
        .then((res) => {          
            this.foldertreelist[id]=res.data
        })
        .catch((err) => {
          console.log(err.message)
        })
      if (name) {
        store.dispatch("selectFoldermove", id);

      }

      this.reloadallfolders();
      await this.getdocuments(id, name)
    },
    async getallsubdepartmentfolders(id,name) {
      await axios
          .get(`${this.mainapi}document/folder/getprivilegedroots?storageId=${id}`, {
              headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json"
              },
          })
          .then((res) => {
              this.subdeptfolder=res.data
              this.subdeptfolder = this.subdeptfolder.map(item => {
                return { ...item, type: 'folder' };
              });
              this.foldertreelist[id].push(...this.subdeptfolder);
              this.getdocuments(id,name)
              
          })
          .catch((err) => {
              console.log(err.response.data.messages)
              if(err.response.data.messages){
                this.getsubfolder(id);
              }
          })
          
    },
    async getsubfolder(id) {
      await axios
        .get(`${this.mainapi}document/folder/getprivilegedroots?storageId=${id}`, {

          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },

        })
        .then((res) => {          
            this.foldertreelist[id]=res.data
        })
        .catch((err) => {
          console.log(err.message)
        })
  
      this.reloadallfolders();
    },
    async RefreshDocument() {
      await store.dispatch("setDocumentRefresh", true);
      window.dispatchEvent(
        new CustomEvent("folderArchived", {
          detail: {
            storage: store.getters.getDocumentRefresh,
          },
        })
      );
    }
  },
};