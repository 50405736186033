import axios from "axios";
import { data } from "jquery";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";

export default {
    name: "Exam_Approver",
    props: {
        documentId: { type: String, default: "" },
        projectId: { type: String, default: "" },
        dropdownName: { type: String, default: "" },
        userExamId: { type: String, default: "" },

    },
    components: {
        Multiselect
    },
    data() {
        return {
            baseApi: `${process.env.VUE_APP_Service_URL}`,
            baseurl: `${process.env.VUE_APP_Service_URL}configuration/`,
            idtoken: "",
            designationList: [],
            designation: "",
            approvalType: "",
            userList: [],
            email: [],
            isSelectedUser: [false],
            approver: {},
            roleList: [],
            showSave: false,
            previousPath: "",
            setpreviouspath: "",
            dropdownheading: "",
            prevRoute: null,
            versionfrom: "",

        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
    async mounted() {
        this.Head = {
            headers: {
                Authorization: "Bearer " + store.getters.getToken,
                "Content-Type": "application/json",
            },
        };
        this.previousPath = store.getters.getDocViewSource
        console.log("path is ", store.getters.getDocViewSource);
        if (this.previousPath == "Department") {
            this.dropdownheading = "Choose Designation"
        }
        else if (this.previousPath == "ProjectSummary") {
            this.dropdownheading = "Choose Role"
        }
        this.getalldesignation();
        if (this.prevRoute != null) {
            if (this.prevRoute.path != "/") {
                localStorage.setItem("patvalue", this.prevRoute.path);
            }
            this.setpreviouspath = localStorage.getItem("patvalue");
            if (this.setpreviouspath == "/department") {
                this.versionfrom = "fromdepartment";
            } else {
                this.versionfrom = "fromproject";
            }
        }
        this.idtoken = store.getters.getToken;
        this.getalldesignation();
    },
    methods: {
        close() {
            this.$emit('closeApproveModal');
        },
        async getalldesignation() {
            if (this.previousPath == "Department") {
                await axios
                    .get(`${this.baseurl}designation/search?status=active&PageSize=10000&SortProperty=displayName`,
                        this.Head)
                    .then((res) => {
                        this.designationList = res.data.entities;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            if (this.previousPath == "ProjectSummary") {
                let projId = store.getters.getSelectedProject;
                await axios
                    .get(
                        `${this.baseurl}projectrole/getall?projectId=${projId}`,
                        this.Head
                    )
                    .then((res) => {
                        this.roleList = res.data;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.roleList = []
                    })
            }
        },
        async onChange(event) {
            this.showSave = false;
            this.email=[];
            this.isSelectedUser = [false];
            let selectedDesignation = event.target.value;
            await this.getDesignationUsers(selectedDesignation);

        },
        async getDesignationUsers(designation) {
           // this.designation = designation;
            let departmentId = "";
            let from = "";
            if (this.previousPath == "Department") {
              departmentId = store.getters.getMainDepartment;
              from = "Department";
            }
            else if (this.previousPath == "ProjectSummary") {
              departmentId = store.getters.getSelectedProject;
              from = "ProjectSummary";
            }

                await axios
                    .get(`${this.baseApi}document/documentquestion/getunassignedusers?designationId=${designation}&departmentId=${departmentId}&examId=${this.userExamId}&from=${from}`, this.Head)
                    .then((res) => {
                        this.userList = res.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
        },
        async OnUserChnage(){
            await this.$nextTick();
            console.log("in OnuserChange ", this.email, this.email.length)
            if(this.email.length > 0){
                this.showSave = true;
            }
            else{
                this.showSave = false;
            }
        },
        async multipleSaveReviewer() { 
            let data = {
                userExamId: this.userExamId,
                approvals: []
            }
            this.email.forEach(item => {
                data.approvals.push({ approvedByEmail: item })
            })

            await axios
                .post(
                    `${this.baseApi}document/documentquestion/addapprover`,
                    data,
                    this.Head)
                .then(res => {

                    console.log("multiple save result", res);
                    alert("Reviewer added successfully");
                    this.close();
                })
                .catch(err => {
                    console.log("error in multiple approve", err);
                })
        },

    },
};