<template>
  <div class="create-user-modal1">
    <div class="modal-dialog">
      <div class="modal-content animated bounceInTop">
        <div class="modal-header">
          <h5 class="float-left mb-0">{{ this.fileName }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="close()"
          >
            <span aria-hidden="true">×</span><span class="sr-only">Close</span>
          </button>
        </div>
        <div class="modal-body height-scroll">
          <ul class="folder-list px-0">
            <li class="py-2" v-for="(data, index) in getbyId" :key="data.id">
              <div class="ibox mb-0">
                <a
                  class="collapse-link d-flex justify-content-between"
                  @click.prevent="showCollapseFunc(index)"
                  :aria-expanded="[showCollapse[index] ? true : false]"
                >
                  <span>{{ data.approvedByUsername }}</span>
                  <span v-if="data.approvedStatus == 'Approved'"
                    ><span class="text-green"
                      >{{ data.approvedStatus
                      }}<span class="small-text">
                        - {{ data.approvedDatetime.slice(0, 10) }}</span
                      ><i
                        class="fa fa-chevron-down ml-2"
                        :class="[showCollapse[index] ? 'rotate' : '']"
                      ></i></span
                  ></span>
                  <span v-if="data.approvedStatus == 'Rejected'"
                    ><span class="text-danger"
                      >{{ data.approvedStatus
                      }}<span class="small-text">
                        - {{ data.approvedDatetime.slice(0, 10) }}</span
                      ><i
                        class="fa fa-chevron-down ml-2"
                        :class="[showCollapse[index] ? 'rotate' : '']"
                      ></i></span
                  ></span>
                  <span v-if="data.approvedStatus == 'Pending'"
                    ><span class="text-warning"
                      >{{ data.approvedStatus
                      }}<i
                        class="fa fa-chevron-down ml-2"
                        :class="[showCollapse[index] ? 'rotate' : '']"
                      ></i></span
                  ></span>
                </a>
                <div class="ibox-content" v-if="showCollapse[index]">
                  <p class="text-break">
                    {{ data.comments }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";

export default {
  name: "ApprovalStatusPopUp",
  props: {
    fileName: {
      type: String,
      default: "",
    },
    docId: {
      type: String,
      default: "",
    },
    version: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      getbyId: "",
      idtoken: "",
      collapseId: "",
      showCollapse: [false],
      documentUrl: `${process.env.VUE_APP_Service_URL}`,
    };
  },
  components: {},

  async mounted() {
    this.getApprovalStatus();
  },
  methods: {
    close() {
      this.$emit("closemodal");
    },
    showCollapseFunc(index) {
      this.showCollapse[index] = !this.showCollapse[index];
    },
    async getApprovalStatus() {
      this.idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.documentUrl}document/document/approvalstatus?documentId=${this.docId}&version=${this.version}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.getbyId = res.data;
          console.log("getBYId", this.getbyId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/style.css";
@import "../../assets/css/custom.css";
.create-user-modal1 {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
  align-items: flex-start !important;
}
.text-green {
  color: #1ab394 !important;
}
.folder-list li i {
  transition: all 0.3s;
}
.rotate {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}
.height-scroll {
  height: 250px;
  overflow-y: auto;
}
.small-text {
  font-size: 80%;
  font-weight: 400;
  color: #6b6b6b;
}
</style>