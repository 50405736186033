import axios from "axios";
import store from "../../store/index";
import SidePopUp from "../side_pop_up/side_pop_up.vue";
import Multiselect from "@vueform/multiselect"
export default {
  name: "folderEditPopUp",
  components: {
    SidePopUp,
    Multiselect
  },
  props: {
    filetype: {
      type: String,
      default: ""
    },
    blocknamess: {
      type: String,
      default: ""
    },
    folderversion: {
      type: Array,
      default: ""
    }
  },
  data() {
    return {
      currentselectedfolder: "",
      minverError:false,
      majorverError:false,
      majorverEmptyError:false,
      idtoken: "",
      errorBlock: false,
      userName: "",
      user: "",
      foldervalues: {
        name: "",
        fileConfiguration: []
      },
      errors: [],
      SelectedFormat: [],
      foldertestlist: [],
      newUserList: [],
      newList: [],
      FormatList: [],
      permissionlist: [],
      List: [],
      newPerm: [],
      baseFileurl: ``,
      baseurl: `${process.env.VUE_APP_Service_URL}document/folderfilepermission/`,
      baseapi: `${process.env.VUE_APP_Service_URL}configuration/designation/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      userList: [],
      designationList: [],
      selectedDesignation: [],
      selectedUser: [],
      selectedPermission: [],
      permissionList: [],
      UserpermissionList: [],
      getfolderbyids: {},
     versionConfig:{
          prefix: "",
          majorVerStart: "",
          minorVerStart: "",
          minorVermax: "",
        },
        prefix: "",
        majorVerStart: "",
        minorVerStart: "",
        minorVermax: "",
      version: {
      },


    }
  },
  
  async mounted() {
    this.idtoken = store.getters.getToken
    document.body.style.overflowY = "hidden"
    await this.getallFormat();
    console.log("MADESSSSSSS")
    await this.getbyfolderid();
    console.log("Folder NAME is", this.blocknamess);
    console.log("version configuration are", this.folderversion)
  },
  unmounted() {
    document.body.style.overflowY = "auto"
  },
  methods: {
    
    async getallFormat() {
      await axios
        .get(`${this.mainapi}document/documentformat/search`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          this.FormatList = res.data.entities[0].values;
          console.log("Format List", this.FormatList)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    isNumber: function (evt,vtype) {

      evt = (evt) ? evt : window.event;

      var charCode = (evt.which) ? evt.which : evt.keyCode;
      const getmaj = vtype;
            if(getmaj =='major'){
                if(this.majorVerStart.length==0 && charCode ==48){
                    evt.preventDefault();
                }
            }

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46|| charCode == 46) {

        evt.preventDefault();

      } else {

        return true;

      }
      
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    },
    closeconfig() {
      this.minverError = false;
      this.majorverError=false;
      this.majorverEmptyError=false;
      this.errors[3] = true;
      this.$emit('closemodal')
      store.dispatch("setfileType", '')
    },
    async updatefolder() {
      
      const folderId = store.getters.getselectedid
      store.dispatch("seteditfolderid",folderId);
   //   console.log("added format", this.versionConfig);
      let folderflag = true;
      
      if (this.getfolderbyids.name == null || this.getfolderbyids.name.trim() == "") {
        this.errors[3] = false;
      }
      else if(this.minorVermax !="" && parseInt(this.minorVerStart) > this.minorVermax)
      {
          this.minverError = true;
      }
      else if(this.majorVerStart=="0")
      {
          this.majorverError=true;
      }
      else if((this.prefix?.trim()!="" || this.minorVerStart?.trim()!="" || this.minorVermax?.trim()!="")&&(this.majorVerStart.trim() ==""))
      {
          this.majorverEmptyError=true;
      }
      else {
        this.foldertestlist.forEach((folderdata) => {
          if (folderdata.name == this.getfolderbyids.name.trim("") == "") {
            folderflag = false;
          }
        });
      console.log("VERSION CONFIG", this.minorVermax);

        await axios
          .put(`${this.mainapi}document/folder/update?Id=${folderId}`,
          //.put(`https://localhost:5001/folder/update?Id=${folderId}`,
            {
              "name": this.getfolderbyids.name.trim(),
              "storageId": "string",
              "storageType": "string",
              "fileConfiguration": this.SelectedFormat,
              "versionConfiguration":  [{
                "prefix": this.prefix,
                "majorVerStart": this.majorVerStart,
                "minorVerStart": this.minorVerStart,
                "minorVermax": this.minorVermax
              }],
            },
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
          
            console.log("file configuration", this.SelectedFormat);
            alert("The folder details updated successfully");
            this.close();
            this.$emit('getFolder')
            console.log(res);
            this.createdFolderId = res.data;
            this.showOrg = false;
            document.body.style.overflowY = "auto"
            this.createfolderpopup = false;
          })
          .catch((err) => {
            // {
            //   console.log(err,"create error");
            //   if(this.versionConfig.majorVerStart== ""){
            //     alert(err.response.data.detail);
                
            // }
            //  else{
             //console.log(err);
            //  } 
              //alert(err.response.data.detail,"message")
            // }
            if (err.response.data.messages[0]) {
              console.log("REACHED IF")
              alert("Folder name already exist.");
          }
          });
        store.dispatch("reloadfolder", true);
        this.foldervalues.name = "";
        this.selectedDesignation = [];
        this.selectedPermission = [];
        this.SelectedFormat = [];
        this.selectedUser = [];
      }
    },
    async getuserpermission() {
      const folderId = store.getters.getselectedid
      if (this.filetype == 'folder') {
        await axios
          .post(`${this.baseurl}getfolderpermissions?FolderId=${folderId}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            })
          .then((res) => {
            this.newUserList = res.data;
          })
          .catch((err) => {
            alert("Folder name already exist")
          });
      }
    },
    //saveVersion() {
      // if (this.versionConfig.majorVerStart == "" | this.versionConfig.majorVerStart == null) {
      //   alert("Major version is mandatory")
      // }
      // else {
      //   //this.version = []
      //   //this.versionConfig.push(this.version);
      //   console.log("Version configuration are", this.versionConfig);

      // }
    //},
    async getbyfolderid() {
      const folderId = store.getters.getselectedid
      await axios
        .get(`${this.mainapi}document/folder/get?Id=` + folderId,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }

        )

        .then((res) => {
          this.getfolderbyids = res.data;
          this.SelectedFormat = this.getfolderbyids.fileConfiguration;
          this.versionConfig = this.getfolderbyids.versionConfiguration == null ? [] : this.getfolderbyids.versionConfiguration[0];
          this.prefix=this.versionConfig.prefix == null ? "" : this.versionConfig.prefix;
          this.majorVerStart=this.versionConfig.majorVerStart == null ? "" : this.versionConfig.majorVerStart;
          this.minorVerStart=this.versionConfig.minorVerStart == null ? "" : this.versionConfig.minorVerStart; 
          this.minorVermax=this.versionConfig.minorVermax  == null ? "" : this.versionConfig.minorVermax;
          console.log("get by version CONFIG", this.versionConfig);
          console.log("get by id", this.getfolderbyids);
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    close() {

      this.$emit('closemodal')
      store.dispatch("setfileType", '')
    },

  }
}