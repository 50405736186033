<template>
  <div class="create-user-modal1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content animated bounceInTop">
        <div class="modal-header1">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="close()"
          >
            <span aria-hidden="true">×</span><span class="sr-only">Close</span>
          </button>
          <h5 class="">Test Reviewer</h5>
        </div>
        <div class="modal-body">
          <div class="form-group row mb-3">
            <div class="col-12 col-md-5 my-2">
              <label class=""> {{ dropdownheading }} </label>
              <select
                class="form-control m-b"
                name="account"
                @change="onChange($event)"
                v-model="designation"
              >
                <option value="" disabled selected>-Select-</option>

                <option
                  :v-if="previousPath == 'Department'"
                  v-for="role in designationList"
                  :key="role.id"
                  :value="role.id"
                >
                  {{ role.displayName }}
                </option>

                <option
                  :v-if="previousPath == 'ProjectRole'"
                  v-for="role in roleList"
                  :key="role.id"
                  :value="role.id"
                >
                  {{ role.name }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-5 my-1 pr-md-1">
              <label class="col-form-label">Users </label>
              <Multiselect
                name="format"
                valueProp="emailId"
                v-model="email"
                mode="multiple"
                placeholder="Select user"
                :options="userList"
                :hideSelected="false"
                :closeOnSelect="false"
                class="multiselect-custom-theme"
                @change = "OnUserChnage()"
              >
                <template v-slot:option="{ option }">
                  {{ option.name }}
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <label v-if="showSave">
            *Unsaved changes will be lost if switching designations.</label
          >

          <button
            v-if="showSave"
            type="button"
            class="btn btn-primary btn-xs px-4 mr-3"
            @click="multipleSaveReviewer()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./Exam_Approver.js"></script>

<style scoped>
.create-user-modal1 {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
  align-items: flex-start !important;
}
.modal-header1 {
  padding: 10px 29px;
  text-align: left;
  display: block;
  border-bottom: 1px solid #dee2e6;
}
.tree input {
  position: inherit !important;
}
.height-scroll {
  height: 250px;
  overflow-y: auto;
}
.fixed-header th {
  top: 0px;
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
}
</style>