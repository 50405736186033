/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect"
import { textChangeRangeIsUnchanged } from "typescript";
export default {
  name: "DocEditProjectPopup",
  components: {
    Multiselect
  },
  props: {
    documentId: {
      type: String,
      default: ""
    },
    folderId: {
      type: String,
      default: ""
    },
    source:{
      type: String,
      default: ""
    },
    versionId:{
      type: String,
      default: ""
    }
  },
  data() {
    return {
      addsigner: false,
      signuserList: [
        {
            "bookmark": "QA",
            "signOrder": "1",
            "userId": null,
            "userEmail": null,
            "userName": null,
            "date": null,
            "signId": null,
            "reason": null,
            "isSigned": false
        }],
      latestver:"",
      getlatestvermsg:"",
      docFolder:"",
      ApprovalStatus: "",
      currentVersion: "",
      NotApproved: "",
      getdocbyId: "",
      removeIcon: false,
      errorBlock: false,
      showModal: false,
      idtoken: "",
      userName: "",
      user: "",
      baseurl: `${process.env.VUE_APP_Service_URL}`,
      baseapi: `${process.env.VUE_APP_Service_URL}configuration/`,
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      userList: [],
      categorylist: [],
      doctypelist: [],
      departmentlist: [],
      list: [],
      uploadedStatus: [],
      standards: [],
      validations: [false, false, false, false, false, false],
      azuredata: {
        DocumentId: "",
        DocumentVersionId: "",
        DocumentTypeName: "",
        DocumentTitle: "",
        DocumentDescription: "",
        Document: "",
        OrganizationId: 2,
      },
      docuplooadfields: {
        folderid: "",
        title: "",
        description: "",
        Owner: "",
        department: "",
        Category: "",
        Standard: "",
        documenttype: "",
        version: "",
        expiry: "",
        changesHistory: ""
      },
      loadingBlock:false
    }
  },
  async mounted() {
    document.body.style.overflowY = "hidden"
    this.idtoken = store.getters.getToken
    await this.gettypelist();
    await this.getstandard();
    await this.getcategory();
    await this.getsignusers();
    await this.getusers();
    await this.getdepartment();
    await this.getdocdetails();
  },
  async unmounted() {
    document.body.style.overflowY = "auto"
  },
  methods: {
    async getdocdetails() {
      await axios
        .get(
          `${this.mainapi}document/document/getdocumentitemdetails?documentId=${this.documentId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => { 
          this.getdocbyId = res.data;
          console.log("Details",this.getdocbyId)
          this.currentVersion = this.getdocbyId.currentVersion;
          this.getlatestvermsg = res.data.documentVersions[res.data.documentVersions.length-1].versionMessage;
          this.latestver = res.data.documentVersions[res.data.documentVersions.length-1].version;
          this.approvedOrNot();
          this.setData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async setData() {
      this.getdocbyId.expiryDate != null ? this.getdocbyId.expiryDate = this.getdocbyId.expiryDate.slice(0, 10) : this.getdocbyId.expiryDate;
      this.docuplooadfields.title = this.getdocbyId.documentTitle;
      this.docFolder = this.getdocbyId.folderId;
      this.docuplooadfields.description = this.getdocbyId.documentDescription;
      this.docuplooadfields.Owner = this.getdocbyId.owner;
      this.docuplooadfields.department = this.getdocbyId.department;
      this.docuplooadfields.Category = this.getdocbyId.catagory;
      this.docuplooadfields.Standard = this.getdocbyId.standard;
      this.docuplooadfields.documenttype = this.getdocbyId.documentType;
      this.docuplooadfields.version = this.latestver;
      this.docuplooadfields.expiry = this.getdocbyId.expiryDate;
      this.docuplooadfields.changesHistory = this.getlatestvermsg;
      //this.getdocbyId.documentVersions[0].versionMessage;
    },
    async approvedOrNot() {
      const idtoken = store.getters.getToken;
      console.log("inside approved or not")
      await axios
        .get(
          `${this.mainapi}document/document/getdocumentapproveornot?documentId=${this.documentId}&CurrentVersion=${this.currentVersion}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("approved or not status==", res.data);
          if (res.data == "NotApproved") {
            this.ApprovalStatus = "NotApproved";
          } else if (res.data == "Approved") {
            this.ApprovalStatus = "Approved";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async loadfile() {
      let fileload = document.getElementById("selectedFile");
      fileload.click();
    },
    // async previewFiles(event) {
    //   this.azuredata.Document = [];
    //   this.azuredata.Document.push(event.target.files);
    //   this.list = [...this.list, ...this.azuredata.Document[0]]
    //   console.log(this.list);
    // },
    // async removefile(index) {
    //   this.list.splice(index, 1);
    // },
    // Get apis
    async gettypelist() {
      await axios
        .get(`${this.baseurl}document/document/gettypelist?SortProperty=DocumentType&SortOrder=0`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.doctypelist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.doctypelist = [];
        });
    },
    async getstandard() {
      await axios
        .get(
          `${this.baseapi}documentstandard/search?PageSize=1000&SortProperty=name&IsDescending=false`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.standards = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.standards = [];
        });
    },
    async getcategory() {
      const idtoken = store.getters.getToken;
      await axios
        .get(
          `${this.mainapi}configuration/documentcategory/search?PageSize=1000&SortProperty=category&IsDescending=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.categorylist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
          this.categorylist = [];
        });
    },
    async getusers() {
      if(this.source=="generalFiles")
      {
        await axios
        .post(`${this.baseurl}document/folderfilepermission/designationusers?departmentId=`,[],{
        headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
              },
          })
          .then((res) => {
            this.userList = res.data;
          })
          .catch((err) => {
            console.log(err);
            this.userList = [];
          });
      }
      else{
        await axios
        .get(`${this.baseapi}userprojectrole/getprojectusers?projectId=`+store.getters.getSelectedProject,{
        headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
              },
          })
          .then((res) => {
            this.userList = res.data;
          })
          .catch((err) => {
            console.log(err);
            this.userList = [];
          });
      }
      
    },
    async replaceFile() {
      console.log("Inside ReplaceFile", this.azuredata.Document);
      const idtoken = store.getters.getToken;
      if (this.azuredata.Document != "") {
        await axios
          .post(
            `${this.mainapi}document/document/updatefile`,
            {
              File: this.azuredata.Document,
              DocumentId: this.documentId,
              ExpiryDate: this.docuplooadfields.expiry,
              VersionMessage: this.docuplooadfields.changesHistory,
              Version: this.docuplooadfields.version.trim(),
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            console.log("replace response", response);
          })
          .catch((err) => {
            console.log(err.data);
          });
      }
    },

    async getdepartment() {
      const orgId = store.getters.getOrganisationID;
      await axios
        .get(`${this.baseapi}department/search?orgid=${orgId}&PageSize=10000&SortProperty=name&IsDescending=false`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          res.data.entities.forEach((data) => {
            if (data.parentID == "" || data.parentID == null) {
              if (!this.departmentlist.includes(data)) {
                this.departmentlist.push(data)
              }
            }
          });
        })
        .catch((err) => {
          console.log(err.response.data.messages)
        })
    },
    // Validations
    async validatedocument() {
      this.validations = [
        false,
        false,
        false,
        false,
        false,
        false
      ];
      if (this.docuplooadfields.title.trim() == "") {
        this.validations[0] = true;
      }
      if (this.docuplooadfields.Owner.trim() == "") {
        this.validations[1] = true;
      }
      // if (this.docuplooadfields.documenttype.trim() == "") {
      //   this.validations[2] = true;
      // }
      if (this.docuplooadfields.version.trim() == "") {
        this.validations[3] = true;
      }
      // if (this.azuredata.Document.length === 0 &&
      //   (this.ApprovalStatus != "Approved" ||
      //     this.ApprovalStatus == ""
      //   )) {
      //   this.validations[4] = true;
      // }
      if (this.docuplooadfields.expiry != null) {
        if (new Date(this.docuplooadfields.expiry) < new Date()) {
          this.validations[5] = true;
        }
      }
    },
    async previewFiles(event) {
      this.azuredata.Document = event.target.files[0];
      console.log("Preview", this.azuredata.Document);
      this.removeIcon = true;
    },
    async removefile() {
      this.azuredata.Document = "";
      this.removeIcon = false;
    },
    async getsignusers() {
      const idtoken = store.getters.getToken;
      await axios
        .get(`${this.mainapi}document/document/getsignerlist?docId=${this.documentId}&versionnum=${this.versionId}`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.signuserList = res.data;
          console.log("userlist isssss", this.signuserList);
          this.signuserList.forEach((eachsignuser) => {
            this.signusersmodel.push(eachsignuser.userId);
          });
          console.log("this.signusersmodel",this.signuserList);
          // this.createbookmarkuserarray();
          // this.bookmarklist = this.signuserList;
        })
        .catch((err) => {
          console.log(err);
          this.signuserList = [];
        });
    },
    // Api Integration
    async submitFile() {
      if (this.azuredata.Document != "") {
        if (confirm("Are you sure you want to replace the existing file?")) {
          await this.save();
        }
        else
        {
          console.log("Return")
          return
        }
      }
      else {
        await this.save();
      }
    },
    async save() {
      const idtoken = store.getters.getToken;
      console.log("this.signuserList", this.signuserList);
      this.signusersmodel.forEach((signmodelusersid, index) => {
        console.log(
          "usersssss",
          this.userList.find((u) => u.userId === signmodelusersid)
        );
        let eachusersmodels = {};
        eachusersmodels = this.userList.find((u) => u.userId === signmodelusersid);
        // if()
        console.log("eachusersmodels", eachusersmodels);
        if (eachusersmodels != undefined) {
          this.signuserList[index].userEmail = eachusersmodels.emailId;
          this.signuserList[index].userId = eachusersmodels.userId;
          this.signuserList[index].userName = eachusersmodels.userName;
          this.signuserList[index].date = null;
        }
      });
      this.uploadedStatus = []
      await this.validatedocument();
      if (!this.validations.includes(true)) {
        // this.list.forEach(async (item) => {
          this.loadingBlock=true
        await axios
          .put(
            `${this.mainapi}document/document/edit`,
            //`https://localhost:5001/document/edit`,
            {
              documentId: this.documentId,
              documentDescription: this.docuplooadfields.description,
              folderId: this.docFolder,
              expiryDate: this.docuplooadfields.expiry,
              department: this.docuplooadfields.department,
              owner: this.docuplooadfields.Owner.trim(),
              documentType: this.docuplooadfields.documenttype,
              currentVersion: this.currentVersion,
              catagory: this.docuplooadfields.Category,
              documentTitle: this.docuplooadfields.title.trim(),
              standard: this.docuplooadfields.Standard,
              versionMessage: this.docuplooadfields.changesHistory,
              signers:this.signuserList
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if (
              this.ApprovalStatus == "NotApproved" ||
              this.ApprovalStatus == ""
            ) {
              this.replaceFile();
            }
            if (res.status == 200) {
              this.uploadedStatus.push(true)
            }
          })
          .catch((err) => {
            this.loadingBlock=false
            console.log(err.data);
          });
        if (!this.uploadedStatus.includes(false)) {
          this.loadingBlock=false
          alert("Document updated successfully")
          this.$emit("reloadDocs")
          this.$emit("closemodal")
        }
      }
    },
    close() {
      this.$emit("closemodal")
    }
  }
}
