<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-2 col-12 col-md-10">
      <h2 class="my-0 py-0">Document Templates</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i
        class="fa fa-times close cursor-pointer"
        @click="goBack"
      ></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
      <div class="ibox-content">
        <div
          class="ibox-title style_2 d-flex justify-content-between"
        >
          <h5 class="my-0 py-0 align-self-center">Document Template</h5>
          <div
            v-if="designationPrivileges.includes(`Document Template Create Document Template`)"
            class="position-relative align-self-center"
            aria-label="Create Designation"
            tabindex="0"
          >
            <img

              class="cursor-pointer"
              src="../../assets/img/create.png"
              alt="Create Designation"
              @click="createdoctemplate()"
            />
            <div class="tool-tip">Create Document Template</div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>

                <th class="sort_block" @click="SortSelected('name', 1)" id="1">
                  <div class="d-flex flex-row justify-content-between">
                    Document name
                    <span>
                      <!-- <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i> -->
                    </span>
                  </div>
                </th>
                <th>
                  <div class="d-flex flex-row justify-content-between">
                    Project name
                  </div>
                </th>
                <th class="text-center" v-if="designationPrivileges.includes(`Document Template Edit Document Template`)">View</th>
                <th class="text-center" v-if="designationPrivileges.includes(`Document Template Delete Document Template`)">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="doctemplate in doctemplatearray" :key="doctemplate.Id">
                <td>

                    {{ doctemplate.documentType }}
                </td>
                <td>
                    {{ doctemplate.projectName }}
                </td>
                <td class="text-center" v-if="designationPrivileges.includes(`Document Template Edit Document Template`)"> 
                  <i class="fa fa-eye viewhand" aria-hidden="true" @click.prevent="edittemplate(doctemplate.id)"></i>

                </td>
                <td class="text-center" v-if="designationPrivileges.includes(`Document Template Delete Document Template`)"> 
                  <i class="fa fa-trash deletehand" aria-hidden="true" @click.prevent="deletetemplate(doctemplate.id)"></i>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 d-flex justify-content-md-end px-0">
          <v-pagination
            v-model="currentPage"
            :pages="totalPage"
            :range-size="1"
            active-color="#ededed"
            @update:modelValue="loadPage"
          />
        </div>
      </div>
    </div>

</template>

<script src="./doctemplate.js">
</script>

<style scoped>
.viewhand{
  cursor: pointer; 
}
.deletehand{
  cursor: pointer; 
}
.deletehand:hover{
  color:red;
}
.viewhand:hover{
  color:blue;
}
.submit_btn {
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;

  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.position-relative img {
  width: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.headingunderline {
  text-decoration-line: underline;
}

.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}
.title-class1 {
  background-color: #618bd7;
  color: #fff;
}
.errorClass {
  color: red;
  font-size: 13px;
}
.button-class {
  float: right;
}
.modal-header-designation{
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-width{
  width:min(400px,90%)
}
.error-block{
color:#ff0000;
}
</style>
