import axios from "axios";
import store from "../../store/index";

export default {
	name: "administration",
	data() {
		return {
			privilegedata:[],
			designationPrivileges: "",
			showdiv: false
		};

	},
	mounted() {
		localStorage.setItem("currenttab", '');
		this.designationPrivileges = store.getters.getRolesprivilegeData;
		this.privilegedata = store.getters.getRolesprivilegeData.split(',');
		this.Enablemenu();
		console.log("from dashboard", this.designationPrivileges);
		if (this.designationPrivileges == 'null,' || this.designationPrivileges == null || this.designationPrivileges === '') {
			this.showdiv = true
		}
		else {
			this.showdiv = false
		}
	},
	methods: {
		async Enablemenu() {
			await store.dispatch("setNavbar", true);
			await store.dispatch("setSideNavbar", true);
		},
		gotoUrl(value) {
			if (value == 'soplibrary') {
				this.$router.push("./soplibrary")
			}
			if (value == 'trainingplan') {
			this.$router.push("./trainingPlan")
			}
			if (value == 'config') {
				this.$router.push("./testList")
			}
			if (value == 'examevidence') {
			this.$router.push("./examevidence")
			}
			if (value == 'configprocess'){
				this.$router.push("./configProcessTraining")
			}
			if (value == 'manageTrainingPlan'){
				this.$router.push("./manageTrainingPlan")
			}
		},
		
    }
}