<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0">Project List</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">   
      <div class="form-group row">   
        <div class="col-12 col-md-10">
          <div class="form-group mb-1">
            <!-- <label class="mb-0">{{ $t("navigations.site code") }}</label> -->
            <label class="mb-0">Project Name</label>
              
            <input
              type="search"
              v-model="selectedprojectname"
              class="form-control my-md-2 my-md-0 "
            />
          </div>
                
        </div>
        <div class="col-12 col-md-2 align-self-end my-1">
          <span type="button" class="submit_btn px-5 w-100" @click.prevent="getsearchdata()">Search</span>
        </div>   
      </div>
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="my-0 py-0 align-self-center">Project List</h5>
        <div
          class="position-relative align-self-center"
          aria-label="Add visit"
          tabindex="0"
        >
          <img
            class="cursor-pointer"
            src="../../assets/img/create.png"
            alt="Create project"
            @click.prevent="createproject('0')"
            width="20"
          />
          <div class="tool-tip">Create Project</div>
        </div>
      
      </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>
                 Project name
                </th>
                <th>
                 Description
                </th>
                <th class="text-center">
                    Active
                </th>
                <th class="text-center">
                    Edit
                </th>
                <th class="text-center">
                    Delete
                </th>
         
              </tr>
            </thead>
            <tbody>
              <tr v-for="projects in projectlist" :key="projects.id">
                <td>{{ projects.projectName }}</td>
                <td>{{ projects.description }}</td>
                <td class="text-center">
                    <input
                      type="checkbox"
                      v-model="projects.active"
                      onclick="return false"
                    />
                </td>
                <td
                  class="text-center"
                >
                <img
                  class="cursor-pointer"
                  src="../../assets/img/Edit.png"
                  alt="Edit"
                    @click.prevent="createproject(projects.id)"
                  >
                </td>
                <td
                  class="text-center"
                >
                <img
                  class="cursor-pointer"
                  src="../../assets/img/delete.png"
                  alt="Delete"
                    @click.prevent="deleteproject(projects.id)"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!------ pagination -------->
          <div class="row mx-0">
            <div class="col-md-12 mx-0 px-0">
              <div class="dataTables_paginate paging_simple_numbers pagination float-right">
                <div class="col-md-12 d-flex justify-content-md-end px-0">
                <v-pagination
                    v-model="currentPageNumber"
                    :pages="totalLists"
                    :range-size="1"
                    active-color="#ededed"
                    @update:modelValue="getsearchdata"
                  /> 
                </div>
              </div>
            </div>             
          </div>

        <!-- pagin -->
      </div>
      <!-- Audit trail details modal starts -->
     </div>
      <!--  popup -->
 
  <!--history modal starts -->

  <!--  popup -->
</template>
<script src="./project_list.js"></script>
<style scoped>
  .position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip{
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.edit{
    color: blue;
}  .edit-delete:hover
  {
    color:var(--primary-color)!important;
    cursor:pointer !important;
    font-size: 15px;
  }

</style>