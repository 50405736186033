<!-- Popup library -->
<template>
  <div class="create-folder-modal">
    <div class="modal-content width-300">
      <div
        class="modal-header d-flex justify-content-between align-items-center"
      >
        <h5 class="float-left mb-0">Select File From</h5>
        <i class="fa fa-times close cursor-pointer" @click="onclosed()"
          ><span class="sr-only">Close</span></i
        >
      </div>

      <div class="modal-body makeitshort">
        <div class="col-12 mt-2">
          <div class="text-center">
            <div class="mb-2">
              <button
                @click="showtreePopupFun()"
                type="button"
                class="btn btn-block btn-outline btn-default"
              >
                <img src="../../assets/img/dmsupload.png" alt="DMS option" />
                Dms
              </button>
              <button
                @click="showLocalSelectFun()"
                type="button"
                class="btn btn-block btn-outline btn-default"
              >
                <img
                  src="../../assets/img/localupload.png"
                  alt="Local option"
                />
                Local
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Local file select -->
  <div class="create-folder-modal" v-if="showLocalSelectPopup">
    <div class="modal-content width-800 height-250">
      <div
        class="modal-header d-flex justify-content-between align-items-center"
      >
        <h5 class="float-left mb-0">Insert File</h5>
        <i class="fa fa-times close cursor-pointer" @click="onLocalclosed()"
          ><span class="sr-only">Close</span></i
        >
      </div>
      <div class="modal-body">
        <div class="row mt-3">
          <div class="col-md-12 mt-2">
            <input
              class="form-control"
              @change="handleFileChange"
              type="file"
              id="formFile"
              accept=".pdf"
            />
          </div>
        </div>
      </div>

      <div
        class="modal-footer d-flex justify-content-between"
        v-if="LocalSelectedfile != null && LocalSelectedfile != ''"
      >
        <div class="form-check d-flex">
          <input
            type="radio"
            class="mt-0"
            name="attachmentOption"
            id="flexRadioDefault1"
            v-model="mergePosition"
            value="Top"
            checked
          />
          <label class="form-check-label ml-2" for="flexRadioDefault1">
            Merge the selected file above "{{ fileName }}"
          </label>
          <input
            class="ml-3 mt-0"
            type="radio"
            name="attachmentOption"
            id="flexRadioDefault2"
            v-model="mergePosition"
            value="Bottom"
          />
          <label class="form-check-label ml-2" for="flexRadioDefault2">
            Merge the selected file below "{{ fileName }}"
          </label>
        </div>

        <div class="justify-content-end">
          <button
            @click="mergefileLOCAL()"
            type="button"
            class="btn btn-primary btn-xs px-4"
          >
            Merge
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- treeStructure popup -->
  <div
    class="move-folder-block-container d-flex justify-content-center align-items-center"
    v-if="showtreePopup"
  >
    <div class="modal-width">
      <div class="modal-content">
        <div class="modal-header-move d-flex justify-content-between p-3">
          <h5 class="my-0 align-self-center">Choose File</h5>
          <span
            type="button"
            class="align-self-center"
            data-dismiss="modal"
            @click.prevent="closeTree()"
            aria-hidden="true"
            ><i class="fa fa-times modal-close align-self-center"></i>
          </span>
        </div>

        <div class="modal-body height-400 border-0 px-4">
          <div class="form-group">
            <div class="row">
              <div class="col-6">
                <div class="mb-1 text-left">
                  <label>Select Location</label>
                  <select
                    v-model="location"
                    class="form-control m-b"
                    @change="onSelection($event)"
                  >
                    <option value="" disabled selected>-Select-</option>
                    <option
                      v-for="location in locations"
                      :key="location.id"
                      :value="location.locationValue"
                    >
                      {{ location.locationName }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- <div class="col-12" v-if="location == `Department`">
                <div class="mb-1 text-left">
                  <label>Select Department</label>
                  <span aria-label="mandatory field" class="error-block"
                    >*</span
                  >
                  <select
                    id="owner"
                    class="form-control"
                    v-model="selectedDepartment"
                    @change="onDeptchange($event)"
                  >
                    <option
                      v-for="department in deptlist"
                      :key="department.id"
                      :value="department.id"
                    >
                      {{ department.name }}
                    </option>
                  </select>
                  <span class="error-block" v-if="validations[0]"
                    >Please select a department</span
                  >
                </div>
              </div> -->
              <!-- <div class="col-6" v-if="location == `Project`">
                <div class="mb-1 text-left">
                  <label>Select Project</label
                  ><span aria-label="mandatory field" class="error-block"
                    >*</span
                  >
                  <select
                    id="owner"
                    class="form-control"
                    v-model="selectedProject"
                    @change="onProjectchange($event)"
                  >
                    <option
                      v-for="project in projectlist"
                      :key="project.id"
                      :value="project.id"
                    >
                      {{ project.projectName }}
                    </option>
                  </select>
                  <span class="error-block" v-if="validations[0]"
                    >Please select a project</span
                  >
                </div>
              </div> -->
            </div>

            <!-- Folder structure -->
            <!-- MyFiles -->
            <div
              class="ibox-content"
              v-if="
                location == `General` ||
                location == `Department` ||
                location == `Project`
              "
            >
              <div class="row">
                <div
                  class="col-6 mb-2 tree-content overflow-y-auto"
                  v-if="location == `General`"
                >
                  <ul class="tree pl-1">
                    <li>
                      <input
                        type="checkbox"
                        :id="selectedfolderid"
                        @change="backtomyFilesrootfolder(selectedfolderid)"
                      />
                      <label
                        :class="
                          mainfolderbold == ''
                            ? 'tree_labels tree_label_bold'
                            : 'tree_labels'
                        " 
                        @click="backtomyFilesrootfolder(selectedfolderid)"
                        >My Files
                      </label>
                      <TreeMergeFile
                        @documentListNotEmpty="handleDocumentListNotEmpty"
                        projectId=""
                        :list="folderlist"
                      >
                      </TreeMergeFile>
                    </li>
                  </ul>
                </div>

                <!-- Department -->
                <div
                  v-if="location == `Department`"
                  class="col-6 mb-2 tree-content overflow-y-auto"
                >
                  <ul class="tree ml-0 pl-0">
                    
                   
                      <TreeMergeFile
                        @documentListNotEmpty="handleDocumentListNotEmpty"
                        projectId=""
                        :list="deptlist"
                        selectedlocation="department"
                      >
                      </TreeMergeFile>
                  </ul>
                </div>

                <!-- End department -->
                <!-- Project -->

                <div
                  v-if="location == `Project`"
                  class="col-6 mb-2 tree-content overflow-y-auto"
                >
                  <ul class="tree ml-0 pl-0">
                    
                      <TreeMergeFile
                        @documentListNotEmpty="handleDocumentListNotEmpty"
                        projectId=""
                        :list="projectlist"
                        selectedlocation="projects"
                      >
                      </TreeMergeFile>
                  </ul>
                </div>

                <!-- End project-->
                <!-- End Folder structure -->

                <!-- start Document list -->
                <div
                  class="col-lg-6 table-responsive mb-3"
                  :class="{ 'align-self-center': showFolderEmpty,
                  'overflow-y-auto': !showFolderEmpty}"
                >
                  <table class="table table-bordered" v-if="showDocList">
                    <thead>
                      <th>{{ openedFolderName }}</th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in documentlist"
                        :key="item.id"
                        @click="handleItemClick(item)"
                      >
                        <td
                          :style="{
                            fontWeight:
                              selectedItem === item ? 'bold' : 'normal',
                          }"
                        >
                          <span class="cursor-pointer">{{ item.name }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    v-if="showFolderEmpty"
                    class="d-flex flex-column align-items-center"
                  >
                    <img
                      src="../../assets/img/empty_folder.png"
                      alt="empty folder"
                      width="70"
                    />
                    <span>This folder is empty at the moment</span>
                  </div>
                </div>

                <!-- End document list -->
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer d-flex justify-content-between">
          <div
            v-if="selectedItem != null && selectedItem != ''"
            class="form-check d-flex"
          >
            <input
              type="radio"
              class="mt-0"
              name="attachmentOption"
              id="flexRadioDefault1"
              v-model="mergePosition"
              value="Top"
              checked
            />
            <label class="form-check-label ml-2" for="flexRadioDefault1">
              Merge the selected file above "{{ fileName }}"
            </label>
            <input
              class="ml-3 mt-0"
              type="radio"
              name="attachmentOption"
              id="flexRadioDefault2"
              v-model="mergePosition"
              value="Bottom"
            />
            <label class="form-check-label ml-2" for="flexRadioDefault2">
              Merge the selected file below "{{ fileName }}"
            </label>
            <div class="ml-5 justify-content-end">
              <button
                @click="mergefileDMS()"
                type="button"
                class="btn btn-primary btn-xs px-4"
              >
                Merge
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
/* eslint-disable */
import TreeMergeFile from "../TreeMergeFile/TreeMergeFile.vue";
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import router from "@/router";

export default {
  name: "mergefilepopup",
  components: {
    Multiselect,
    TreeMergeFile,
  },
  props: { docId: String, version: String, fileName: String },
  watch: {
    "$store.getters.getDepartment": function (val) {
      if (val) {
        this.maindeptbold = val;
        this.getselecteddepartments(val);
        this.getalldepartmentfolders(val);
      } else {
        this.selectedfoldername = null;
      }
    },
    "$store.getters.getselectedFolder": function (val) {
      if (val) {
        this.mainfolderbold = val;
        this.currentselectedfolder = val;
        this.openfolder = val;
      } else {
        console.log("Null");
      }
    },
    "$store.getters.getSelectedProject": function (val) {
      if (val) {
        this.mainprojbold = val;
        console.log("THEE PROJJ ISSSS", val);
        this.getselectedprojects(val);
        this.getallprojectfolders(val);
      } else {
        this.selectedfoldername = null;
      }
    },
  },
  data() {
    return {
      showLocalSelectPopup: false,
      location: "",
      mainapi: `${process.env.VUE_APP_Service_URL}`,
      baseurl: `${process.env.VUE_APP_Service_URL}configuration/`,
      validations: [false, false, false],
      folderlist: [],
      deptlist: [],
      subFolders: [],
      selectedfoldername: "",
      selectedfolderid: "",
      mainfolderbold: "",
      idtoken: "",
      maindeptbold: "",
      folder: "",
      openfolder: "",
      selectedDepartment: "",
      folderName: "",
      selectedProject: "",
      mainprojbold: "",
      showtreePopup: false,
      LocalSelectedfile: "",
      showDocList: false,
      showFolderEmpty: false,
      mergePosition: "Top",
      openedFolderName: "",
      selectedItem: null,
      documentlist: "",
      locations: [],
      mergedDocId: "",
    };
  },
  async mounted() {
    this.mainfolderbold = this.folderBold;
    this.selectedDepartment = "";
    document.body.style.overflowY = "hidden";
    this.idtoken = store.getters.getToken;
    store.dispatch("setshowMoreBlock", false);
    this.selectedDepartment = this.treeId;
    this.folder = this.movableFolder;
    this.openfolder = this.folderBold;
    await this.getName();
    this.getdepartment();
    this.getselecteddepartments(this.treeId);
    this.getalldepartmentfolders(this.treeId);
    this.selectedProject = "";
    this.idtoken = store.getters.getToken;
    store.dispatch("setshowMoreBlock", false);
    this.selectedProject = this.treeId;
    this.folder = this.movableFolder;
    this.openfolder = this.folderBold;
    await this.getName();
    this.getproject();
    this.getselectedprojects(this.treeId);
    this.getallprojectfolders(this.treeId);
    this.getallgeneralfolders();
    document.body.style.overflowY = "hidden";
  },
  async unmounted() {
    store.dispatch("setshowMoreBlock", true);
    document.body.style.overflowY = "auto";
  },
  methods: {
    handleItemClick(item) {
      console.log("Slected Itemm", item);
      this.selectedItem = item;
    },
    async handleDocumentListNotEmpty({ folderId, folderName, isFilesExist }) {
      this.selectedItem = null;
      console.log("Show isFilesExist", isFilesExist);
      if (isFilesExist == `true`) {
        this.showFolderEmpty = false;
        this.showDocList = true;
        this.openedFolderName = folderName;
        await this.getdocuments(folderId);
      } else {
        this.documentlist = "";
        this.showDocList = false;
        this.showFolderEmpty = true;
        this.openedFolderName = "";
      }
    },
    async getdocuments(folderId) {
      this.documentlist = "";
      await axios
        .get(
          `${this.mainapi}document/folder/getprivilegedcontent?Id=${folderId}&fileType=pdf`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.documentlist = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleFileChange(event) {
      this.LocalSelectedfile = event.target.files[0];
      console.log("Selected file:", this.LocalSelectedfile);
    },
    async onclosed() {
      this.$emit("mergetreeclosemodel");
      this.documentlist = "";
      this.showDocList = false;
    },
    async onLocalclosed() {
      this.LocalSelectedfile = null;
      this.showLocalSelectPopup = false;
    },
    async showtreePopupFun() {
      this.showtreePopup = true;
      await this.ListDMSLocations();
    },
    async showLocalSelectFun() {
      this.showLocalSelectPopup = true;
    },
    async getName() {
      await axios
        .get(`${this.mainapi}document/folder/get?Id=${this.folder}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("Name is", res.data.name);
          this.folderName = res.data.name;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async checkSubFolders() {
      await axios
        .get(
          `${this.mainapi}document/folder/getprivilegedchildren?Id=${this.targetFolder}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          res.data.forEach((item) => {
            if (item.id == this.folder) {
              this.validations[1] = true;
            }
            if (item.name == this.folderName) {
              this.validations[2] = true;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onProjectchange(event) {
      this.projectSelected = true;
      this.mainfolderbold = event.target.value;
      this.getselectedprojects(event.target.value);
      this.getallprojectfolders(event.target.value);
      (this.LocalSelectedfile = ""),
        (this.showFolderEmpty = false),
        (this.openedFolderName = ""),
        (this.selectedItem = null),
        (this.documentlist = "");
      this.showDocList = false;
    },

    async getproject() {
      await axios
        .get(
          `${this.baseurl}project/listproject?SortProperty=ProjectName&SortOrder=0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.projectlist = res.data.entities;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getselectedprojects(id) {
      if (id != "") {
        await axios
          .get(`${this.mainapi}configuration/project/get?id=${id}`, {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            this.selectedfolderid = res.data.id;
            this.selectedfoldername = res.data.projectName;
          })
          .catch((err) => {
            console.log(err.response.data.messages);
          });
      }
    },
    async getallgeneralfolders() {
      await axios
        .get(
          `${this.mainapi}document/folder/getprivilegedroots?storageType=General`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.folderlist = res.data;
        })
        .catch((err) => {
          console.log(err.response.data.messages);
        });
    },
    async getallprojectfolders(id) {
      await axios
        .get(
          `${this.mainapi}document/folder/getprivilegedroots?storageId=${id}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.folderlist = res.data;
        })
        .catch((err) => {
          console.log(err.response.data.messages);
        });
    },
    async backtoprojectrootfolder(id) {
      (this.mainfolderbold = ""), (this.currentselectedfolder = "");
      this.projectSelected = true;
      // this.departmentSelected=true
      store.dispatch("selectFoldermove", id);
      this.getallprojectfolders(id);
      //this.getalldepartmentfolders(id);
    },

    close() {
      this.$emit("closemodal");
    },
    async checkSubFolders() {
      await axios
        .get(
          `${this.mainapi}document/folder/getprivilegedchildren?Id=${this.targetFolder}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          res.data.forEach((item) => {
            if (item.id == this.folder) {
              this.validations[1] = true;
            }
            if (item.name == this.folderName) {
              this.validations[2] = true;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSelection(event) {
      (this.LocalSelectedfile = ""),
        (this.showFolderEmpty = false),
        (this.openedFolderName = ""),
        (this.selectedItem = null),
        (this.documentlist = "");
      this.showDocList = false;
      if (event.target.value == "General") {
        this.getallgeneralfolders();
      }
      this.selectedProject = "";
      this.selectedDepartment = "";
      this.folderlist = "";
      this.selectedfolderid = "";
      this.selectedfoldername = "";
    },
    onDeptchange(event) {
      (this.LocalSelectedfile = ""),
        (this.showFolderEmpty = false),
        (this.openedFolderName = ""),
        (this.selectedItem = null),
        (this.documentlist = "");
      this.showDocList = false;
      this.departmentSelected = true;
      this.mainfolderbold = event.target.value;
      this.getselecteddepartments(event.target.value);
      this.getalldepartmentfolders(event.target.value);
    },
    async getdepartment() {
      const orgId = store.getters.getOrganisationID;
      await axios
        .get(
          `${this.baseurl}department/searchbyprivilege?orgid=${orgId}&PageSize=10000`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.deptlist = res.data.entities;
          console.log("departments",res.data.entities);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getselecteddepartments(id) {
      if (id != "") {
        await axios
          .get(
            `${this.mainapi}configuration/department/searchbyprivilege?id=${id}&PageSize=100`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if (res.data.entities[0].name) {
              console.log("selected folders are " + res);
              this.selectedfoldername = res.data.entities[0].name;
              this.selectedfolderid = res.data.entities[0].id;
            }
          })
          .catch((err) => {
            console.log(err.response.data.messages);
          });
      }
    },
    async getalldepartmentfolders(id) {
      await axios
        .get(
          `${this.mainapi}document/folder/getprivilegedroots?storageId=${id}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.folderlist = res.data;
        })
        .catch((err) => {
          console.log(err.response.data.messages);
        });
    },
    async backtomyFilesrootfolder(id) {
      (this.mainfolderbold = ""), (this.currentselectedfolder = "");
      this.departmentSelected = true;
      store.dispatch("selectFoldermove", id);
      this.getallgeneralfolders();
    },
    async backtodeptrootfolder(id) {
      (this.mainfolderbold = ""), (this.currentselectedfolder = "");
      this.departmentSelected = true;
      store.dispatch("selectFoldermove", id);
      this.getalldepartmentfolders(id);
    },

    closeTree() {
      this.showtreePopup = false;
    },
    async mergefileLOCAL() {
      console.log("picked File", this.LocalSelectedfile);
      await axios
        .post(
          this.mainapi + "document/document/mergepdf",
          {
            FirstDocumentId: this.docId,
            FirstDocumentVersion: this.version,
            SecondDocumentFile: this.LocalSelectedfile,
            Position: this.mergePosition,
          },
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          alert("Files merged successfully");

          (this.FirstDocumentId = ""),
            (this.FirstDocumentVersion = ""),
            (this.LocalSelectedfile = ""),
            this.close();
          this.closeTree();
          this.onLocalclosed();
          this.onclosed();
          this.$router.push(
            `/documentview/` + res.data.documentId + "/" + res.data.version
          );
          this.$emit("custom-event", res.data);
        })
        .catch((err) => {
          if (err.response) {
            alert(err.response.data.errors.DomainValidations[0]);
          }
        });
    },
    async mergefileDMS() {
      await axios
        .post(
          this.mainapi + "document/document/mergepdf",
          {
            FirstDocumentId: this.docId,
            FirstDocumentVersion: this.version,
            SecondDocumentId: this.selectedItem.id,
            SecondDocumentVersion: this.selectedItem.latestVersion,
            Position: this.mergePosition,
          },
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          (this.FirstDocumentId = ""),
            (this.FirstDocumentVersion = ""),
            (this.LocalSelectedfile = ""),
            alert("Files merged successfully");
          this.close();
          this.closeTree();
          this.onLocalclosed();
          this.onclosed();
          this.$router.push(
            `/documentview/` + res.data.documentId + "/" + res.data.version
          );
          this.$emit("custom-event", res.data);
        })
        .catch((err) => {
          if (err.response) {
            alert(err.response.data.errors.DomainValidations[0]);
          }
        });
    },
    async ListDMSLocations() {
      await axios
        .get(`${this.mainapi}document/document/listdmslocation`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("list location", res.data);
          this.locations = res.data;
        })
        .catch((err) => {
          console.log("error in list location", err);
        });
    },
  },
};
</script>
<style scoped>
@import "./tree.css";
.move-folder-block-container {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  overflow-y: hidden;
  z-index: 3000;
}

.modal-header-move {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-width {
  width: min(400px, 90%);
}

.overflow-y-auto {
  height: 300px;
  overflow-y: auto;
}

.height-400 {
  height: 400px;
  overflow-y: hidden;
}

.error-block {
  color: #ff0000;
}

.tree_label_bold {
  font-weight: bold;
}
.submit_btn {
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;

  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.position-relative img {
  width: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.headingunderline {
  text-decoration-line: underline;
}

.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}
.title-class1 {
  background-color: #618bd7;
  color: #fff;
}
.errorClass {
  color: red;
  font-size: 13px;
}
.button-class {
  float: right;
}
.modal-header-designation {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-width {
  width: min(800px, 90%);
}
.error-block {
  color: #ff0000;
}
.errmsg {
  font-size: 12px;
  color: red;
  float: left;
}
.width-300 {
  width: min(300px, 90%);
}
.width-800 {
  width: min(800px, 90%);
}
.height-250 {
  height: 250px;
}
.loading-img {
  mix-blend-mode: lighten;
}
.makeitshort {
  max-height: 400px;
  overflow: auto;
}
</style>
