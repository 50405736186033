<template>
  <div class="row">
    <div class="col-0 col-md-6 left-side d-flex flex-column justify-content-center align-items-center">
        <h1 class="font-weight-bold">Welcome to DMS</h1>
        <h2 class="mb-3"><small>Centralized Document Management Solution</small></h2>
        <img class="login-img"  src="../../assets/img/login_page_img.png"
            alt="Centralized Document Management Solution">
    </div>
    <div class="col-12 col-md-6 right-side p-4 ">
      <div class="middle-box loginscreen py-0 px-3 d-flex flex-column justify-content-center">
        <h2 class="fw-normal text-left text-muted mb-4">Login</h2>
        <form class="m-t" role="form" autocomplete="on">
          <div class="form-group">
            <input type="email" class="form-control mb-4" placeholder="Email" v-model="username" required>
          </div>
          <div class="form-group position-relative mb-4">
            <input :type="inputType" class="form-control" placeholder="Password" v-model="password" required>
            <i class="password-visible-block cursor-pointer position-absolute"
              :class="[visibleOn ? 'fa fa-eye' : 'fa fa-eye-slash']" @click.prevent="passwordVisible()"></i>
              <div class="text-right">
                <span class="cursor-pointer text-muted" @click.prevent="$router.push(`/CheckEmail`)"><small>Forgot password?</small></span>
              </div>
            
          </div>
          <div class="mb-2 text-center">
            <span class="error-message" v-if="errorBlock">Invalid username or password</span>
            <span class="error-message">{{ errr }}</span>
          </div>
          <button class="btn btn-primary block  full-width m-b" @click.prevent="login">Login</button>
        </form>
      </div>
    </div>
  </div>
  <footer class="login-footer row py-1 px-3">
    <div class="col-12 col-md-6 text-center text-md-left">
      <p class="mb-0"><span class="mr-1">&copy;</span>{{ currentYear }} Datamatica - All rights reserved.</p>
    </div>
    <div class="col-12 col-md-6 text-center text-md-right">
      <p class="mb-0">Confidential Information: Authorized Users Only</p>
    </div>
    
  </footer>
</template>

<script src="./login.js">
</script>

<style>
.me-2 {
  margin-right: 0.5rem !important;
}

.btn-primary:hover {
  background-color: #17A2FD !important;
  border-color: #17A2FD !important;
}

.btn-primary {
  background-color: #7B8CEA !important;
  border-color: #7B8CEA !important;
}

.full-width {
  width: 100% !important;
}

.middle-box {
  max-width: 400px;
  z-index: 100;
  margin: 0 auto;
}

.left-side {
  height: 100vh;
  color: #ffffff;
  background: linear-gradient(180deg, #7B8CEA 0%, #16A3FE 100%);
}

.sign-up-btn {
  background-color: #7B8CEA;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 1rem;
}

.right-side {
  height: 100vh;
  overflow: hidden
}

.loginscreen.middle-box {
  height: 100%;
}

.condition-message {
  font-size: 12px !important;
}

.other-block {
  position: relative;
}

.other-block::after,
.other-block::before {
  content: "";
  position: absolute;
  display: block;
  width: 45%;
  background-color: #949494;
  height: 1px;
  top: 50%;
}

.other-block::after {
  right: 0;
}

.middle-box a {
  display: block;
  text-align: right;
  text-decoration: none;
}

.mail-block {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 10px;
}

.mail-block__google {
  grid-column: 1/7;
}

.mail-block__microsoft {
  grid-column: 7/13;
}

.mail-block>* {
  background: #FFFFFF;
  border: 1px solid #C6C6C6;
  box-sizing: border-box;
  border-radius: 5px;
  color: #6c6c6c;
  min-width: max-content
}

.mail-block>*>img {
  max-width: 100%;
  width: 14px;
}

.error-message {
  color: #ff0000;
}

.password-visible-block {
  right: 11px;
  top: 11px
}
.login-img{
  width:min(400px,90%);
  aspect-ratio: 3/2;
  object-fit: contain;
}
.login-footer{
  position:fixed;
  bottom:0;
  left:0;
  right:0;
  background-color: hsl(219, 100%, 90%);
}
</style>
