<template>
  <div class="popup-container">
    <div class="popup-content">
      <div class="d-flex justify-content-end mb-1 mt-1">
        <span
          ><i
            class="fa fa-times modal-close align-self-center"
            @click="onclosed"
          ></i
        ></span>
      </div>

      <iframe
        :src="iframeurl"
        frameborder="0"
        width="100%"
        height="90%"
      ></iframe>
    </div>
  </div>
</template>

<style scoped>
.popup-container {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  overflow: hidden;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  z-index: 2;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.close-button {
  padding: 5px 10px;
  background-color: #ddd;
  border: none;
  border-radius: 3px;
}
.fa-times {
  font-size: 20px; /* Increase or decrease the size as desired */
}
</style>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
export default {
  name: "adobe_iframe",
  components: {},
  props: {
    iframeurl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  async mounted() {},
  async unmounted() {},
  methods: {
    async onclosed() {
      const confirmClose = confirm(
        "Are you sure you want to close the screen?"
      );
      if (confirmClose) {
        this.$emit("iframeclosemodel");
        this.emailId = "";
        this.password = "";
        this.reason = "";
      }
    },
  },
};
</script>