import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle';
import VPagination from "@hennge/vue3-pagination";
import TreeFolder from "./components/TreeFolder/TreeFolder.vue";
import TreeFoldermove from "./components/Treefoldermove/TreeFoldermove.vue";
import TreeMergeFile from "./components/TreeMergeFile/TreeMergeFile.vue";
import 'clickout-event';
import "@vueform/multiselect/themes/default.css"
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import "../src/assets/font-awesome/css/font-awesome.css"
import "../src/assets/css/style.css";
import "../src/assets/css/animate.css";
import "../src/assets/css/custom.css";
import 'clickout-event';

const app = createApp(App);
app.component("VPagination", VPagination);
app.component("TreeFolder", TreeFolder);
app.component("TreeFoldermove", TreeFoldermove);
app.component("TreeMergeFile", TreeMergeFile);
app.use(store).use(router).mount('#app')
