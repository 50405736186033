<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="pl-md-10 col-10 col-md-10">
      <h2 class="my-0 py-0">User Management</h2>
    </div>
    <div class="col-2 col-md-2 align-self-center">
      <i
        class="fa fa-times close cursor-pointer"
        @click="goBack"
      ></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content">
      <div class="form-group row mb-3">
        <div class="col-12 col-md-3 my-1 pr-md-1">
          <div class="form-group mb-md-0 pr-md-1">
            <label class="mb-0">Name</label>
            <input type="text" class="form-control my-md-0" v-model="searchField.name" />
          </div>
        </div>
        <div class="col-12 col-md-3 my-1 pr-md-1">
          <div class="form-group mb-md-0">
            <label class="mb-0">Designation</label>
            <select class="form-select my-md-0" name="Designation" placeholder="Designation"
              v-model="searchField.Designation">
              <option value="All">All</option>
              <option v-for="disignation in disignations" :key="disignation.id" :value="disignation.id">
                {{ disignation.displayName }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-12 col-md-3 my-1 pr-md-1">
          <div class="form-group mb-md-0">
            <label class="mb-0">Department</label>
            <select class="form-select my-md-0" name="Department" placeholder="Department"
              v-model="searchField.Department">
              <option value="All">All</option>
              <option v-for="department in departmentlist" :key="department.id" :value="department.id">
                {{ department.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-12 col-md-3 align-self-end my-1">
          <span type="button" class="submit_btn px-5 py-2 w-100" @click="OnSearchButtonClicked">Search</span>
        </div>
      </div>
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="my-0 py-0 align-self-center">Users</h5>
        <div v-if="designationPrivileges.includes(`Users Create User`)" class="uploadbtncontainer"
          aria-label="Create Designation" tabindex="0">
          <div class="position-relative
          ">
            <img class="cursor-pointer position-relative" src="../../assets/img/create.png" alt="Create Designation"
              @click="GoToManageUser(0)" />
            <div class="tool-tip">Create User</div>
          </div>

          <div>
            <a href="#" class="mr-3 position-relative uploadbtn" data-toggle="" data-target="">
              <input class="d-none" accept=".xls,.xlsx" id="InPutFile" type="file" @change="previewFiles" />
              <img src="../../assets/img/export_quest.png" alt="export_icon" @click="onFileChange()" />
              <div class="tool-tip">Upload user</div>
            </a>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-bordered dataTables">
          <thead>
            <tr>
              <th class="sort_block" @click="SortSelected('firstName', 1)" id="1">
                <div class="d-flex flex-row justify-content-between">
                  Name
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>

              <th class="sort_block" @click="SortSelected('primaryEmail', 2)" id="2">
                <div class="d-flex flex-row justify-content-between">
                  Email
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>

              <th class="sort_block" @click="SortSelected('designation', 3)" id="3">
                <div class="d-flex flex-row justify-content-between">
                  Designation
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>

              <th class="sort_block" @click="SortSelected('department', 4)" id="4">
                <div class="d-flex flex-row justify-content-between">
                  Department
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>

              <th class="sort_block" @click="SortSelected('createdAt', 5)" id="5">
                <div class="d-flex flex-row justify-content-between">
                  Created Date
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
              </th>
              <th class="text-center">Active</th>
              <th class="text-center" v-if="designationPrivileges.includes(`Users Edit User`)">Edit</th>
              <th class="text-center" v-if="designationPrivileges.includes(`Users Delete User`)">Delete</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="user in userList" :key="user.Id">
              <td>
                {{ user.firstName + " " + user.lastName }}
              </td>

              <td>{{ user.primaryEmail }}</td>
              <td>{{ user.designation }}</td>
              <td>
                <span v-for="(departments, index) in user.department" :key="index">
                  <span v-if="index == user.department.length - 1">
                    {{ departments }}&nbsp;</span>
                  <span v-else>
                    {{ departments }},&nbsp;</span>
                  <br v-if="index % 6 == 0 && index != 0" />
                </span>
                <!-- {{ user.department }}</td> -->
              </td>
              <td>{{ user.createdAt.slice(0, 10) }}</td>
              <td class="text-center">
                <input v-model="user.active" type="checkbox" onclick="return false" />
              </td>
              <td class="text-center" v-if="designationPrivileges.includes(`Users Edit User`)">
                <img class="cursor-pointer" src="../../assets/img/Edit.png" alt="Edit" @click="GoToManageUser(user.id)" />
              </td>

              <td class="text-center" v-if="designationPrivileges.includes(`Users Delete User`)">
                <img class="cursor-pointer" src="../../assets/img/delete.png" alt="Delete"
                  @click="DeleteClicked(user.id)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end px-0">
        <v-pagination v-model="currentPage" :pages="totalPage" :range-size="1" active-color="#ededed"
          @update:modelValue="loadPage" />
      </div>
    </div>
  </div>
  <!-- <div class="assignSOP-modal" v-if="showPopup">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-bertween p-3">
          <h5 class="my-0 align-self-center">Assign SOP</h5>
          <div class="cursor-pointer" @click="closeModel()">
            <i class="fa fa-times"></i>
          </div>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="trainee">Trainee </label>
              <input type="text" id="trainee" class="form-control" maxlength="30" disabled v-model="user.primaryEmail" />
            </div>
            <div class="col-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="trainer">Trainer  <sup><i class="fa fa-asterisk required"></i></sup
              ></label>
              <select id="trainer" class="form-select form-select-lg" aria-label=".form-select-lg example"
              @change="trainer()" v-model="trainerDetails"
              >

                <option v-for="trainers in modtrainerLists" :key="trainers.id" :value="trainers">
                  {{ trainers.firstName + " " + trainers.lastName }}
                </option>
              </select>
              <span class="errorClass" v-if="traineralert.show">{{ traineralert.message }}</span>
            </div>
            <div class="col-lg-12 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="document title">Select SOP documents  <sup><i class="fa fa-asterisk required"></i></sup
              ></label>
              <Multiselect name="sopDetails" valueProp="title" mode="multiple" :object="true" placeholder="Select SOPs"
                :options="sopDetails" :hideSelected="false" :closeOnSelect="false" class="multiselect-custom-theme"
                v-model="assignsop.soP_Details" 
               
                >
                <template v-slot:option="{ option }">
                  {{ option.title }}
                </template>
              </Multiselect>
              <span class="errorClass" v-if="traineealert.show">{{ traineealert.message }}</span>
            </div>
            <div class="col-lg-6 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="document title">Start date  <sup><i class="fa fa-asterisk required"></i></sup
              ></label>
              <input :min="minDate" type="date" class="form-control my-md-0" v-model="assignsop.plannedStartDate" />
              <span class="errorClass" v-if="datealert.show">{{ datealert.message }}</span>
            </div>
            <div class="col-lg-6 col-md-6 pr-md-1 mt-2">
              <label class="mb-0 mr-1" for="document title">End date  <sup><i class="fa fa-asterisk required"></i></sup
              ></label>
              <input :min="minDate" type="date" class="form-control my-md-0" v-model="assignsop.plannedEndDate" />
              <span class="errorClass" v-if="enddatealert.show">{{ enddatealert.message }}</span>
            </div>
            <div class="col-lg-12 mt-2 pr-1 text-right">
              
            </div>
          </div>
        </div>
        <div class="modal-footer"><button type="button" class="btn btn-primary btn-xs px-4 align-self-center" @click="AssignSop()">
                Save
              </button></div>
      </div>
    </div>

  </div> -->
</template>

<script src="./userlisting.js"></script>

<style scoped>
.assignSOP-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.modal-header {
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-overflow {
  overflow-x: hidden;
  height: 400px;
  overflow-y: auto;
}

.uploadbtncontainer {
  display: flex;
  gap: 15px;
}

.uploadbtn {
  margin-left: 5px;
}

.submit_btn {
  text-align: center;
  cursor: pointer;
  background-color: #7b8cea !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.55rem 0.4rem;
  border-radius: 0.25rem;
  border-color: #7b8cea !important;
  transition: background-color 0.3s ease-in-out 0s !important;
}

.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}

.page-links {
  border-radius: 0px;
}

/*pagination styles*/
.pagination {
  margin: 0;
}

.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}

.Page-active {
  padding: 1rem;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}

.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}

.PaginationControl {
  padding: 0.42rem;
}

.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}

.position-relative img {
  width: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.headingunderline {
  text-decoration-line: underline;
}

.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}

.title-class1 {
  background-color: #618bd7;
  color: #fff;
}

.errorClass {
  color: red;
  font-size: 13px;
}

.button-class {
  float: right;
}
.fa-asterisk {
  color: #e56363 !important;
  font-size: 6px;
}
</style>
