import axios from "axios";
import forge from 'node-forge';

// Function to get the public key (PEM format)
async function getKey() {
   const baseurl= process.env.VUE_APP_Service_URL
    try {
        const response = await axios.get(`${baseurl}document/security/getkey`, {
            headers: {
                "Content-Type": "text/plain",
            },
        });

        return response.data;  // Access the data property
    } catch (error) {
        console.error(error);
        throw error;
    }
}

// Function to encrypt plain text using a PEM-formatted public key
export async function encrypt(plainText) {
    try {
        let pemKey = await getKey();  // Await the result of getKey
        console.log("pem key is ", pemKey);
        const publicKey = forge.pki.publicKeyFromPem(pemKey);

        const encrypted = publicKey.encrypt(plainText, 'RSAES-PKCS1-V1_5', {
            md: forge.md.sha256.create(),
            mgf1: {
                md: forge.md.sha1.create(),
            },
        });

        return forge.util.encode64(encrypted);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

async function getAccountKey(){
    const baseurl= process.env.VUE_APP_Service_URL
    try {
        const response = await axios.get(`${baseurl}account-core/security/getkey`, {
            headers: {
                "Content-Type": "text/plain",
            },
        });

        return response.data;  // Access the data property
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export async function encryptUser(plainText) {
    try {
        let pemKey = await getAccountKey();  // Await the result of getKey
        console.log("pem key is ", pemKey);
        const publicKey = forge.pki.publicKeyFromPem(pemKey);

        const encrypted = publicKey.encrypt(plainText, 'RSAES-PKCS1-V1_5', {
            md: forge.md.sha256.create(),
            mgf1: {
                md: forge.md.sha1.create(),
            },
        });

        return forge.util.encode64(encrypted);
    } catch (error) {
        console.error(error);
        throw error;
    }
}
